import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import SandboxesActions from '@/modules/sandboxes/Actions';
import { SANDBOX_TYPE } from '@/modules/sandboxes/Constants';

const deployApp = (
  config: Map<string, any>,
  sandbox: Map<string, any>,
  paramaters: Map<string, any>,
  options?: { notify: boolean },
) => {
  return SandboxesActions.createSandboxSimple(
    config.toJS(),
    SANDBOX_TYPE.STREAMLIT,
    Map({ shared: true }),
    paramaters,
    KEBOOLA_DATA_APPS,
    sandbox.isEmpty() ? 'create' : 're-create',
    options ? options.notify : true,
  );
};

export { deployApp };
