import { List, Map, OrderedMap } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { features } from '@/modules/components/Constants';
import { SORT } from '@/react/common/constants';
import matchByWords from '@/utils/matchByWords';
import { topComponents } from './constants';

const hasSampleDataAvailable = (allComponents, component) => {
  return (
    allComponents.has(KEBOOLA_EX_SAMPLE_DATA) &&
    component.get('features', List()).includes(features.HAS_SAMPLE_DATA)
  );
};

const mergeSampleDataToConfigurations = (installedComponents, allComponents) => {
  return installedComponents.withMutations((components) => {
    components.getIn([KEBOOLA_EX_SAMPLE_DATA, 'configurations'], Map()).forEach((config) => {
      const componentId = config.getIn(['configuration', 'parameters', 'componentId']);
      if (!componentId || !allComponents.has(componentId)) {
        return;
      }

      if (!components.hasIn([componentId, 'configurations'])) {
        components.set(
          componentId,
          allComponents.get(componentId, Map()).set('configurations', OrderedMap()),
        );
      }

      components.setIn(
        [componentId, 'configurations', config.get('id')],
        config.set('isSample', true),
      );
    });

    components.delete(KEBOOLA_EX_SAMPLE_DATA);
  });
};

const getComponentsFiltered = (
  components,
  query,
  categories,
  types,
  fields = ['name', 'description'],
) => {
  if (categories?.count() > 0) {
    components = components.filter((component) =>
      component.get('categories', List()).some((category) => categories.includes(category)),
    );
  }

  if (types?.count() > 0) {
    components = components.filter((component) => types.includes(component.get('type')));
  }

  if (query.length < 2) {
    return components;
  }

  return components.filter((component) => {
    return (
      component.get('id') === query ||
      matchByWords(
        fields.map((field) => component.get(field)),
        query,
      )
    );
  });
};

const sortDataSourceFirst = (a, b) => {
  if (a.get('name') !== b.get('name')) {
    return 0;
  }

  return b.get('type') === componentTypes.EXTRACTOR ? 1 : -1;
};

const sortComponents = (components, activeSort = SORT.POPULAR, latestJobs) => {
  return components
    .sortBy((component) => component.get('name').toLowerCase())
    .sortBy((component) => topComponents.get(component.get('id'), topComponents.count() + 1))
    .sort((a, b) => {
      if (activeSort === SORT.POPULAR) {
        return 0;
      }

      if (activeSort === SORT.RECENTLY_ADDED) {
        return a
          .get('configurations', Map())
          .maxBy((config) => config.get('created'))
          ?.get('created') >
          b
            .get('configurations', Map())
            .maxBy((config) => config.get('created'))
            ?.get('created')
          ? -1
          : 1;
      }

      if (activeSort === SORT.RECENTLY_USED && latestJobs) {
        const lastJobA = latestJobs.get(a.get('id'), Map()).flatten(2).get('createdTime', 0);
        const lastJobB = latestJobs.get(b.get('id'), Map()).flatten(2).get('createdTime', 0);

        if (!lastJobA) return 1;
        return !lastJobB || lastJobA > lastJobB ? -1 : 1;
      }

      const desc = activeSort === SORT.A_Z ? 1 : -1;

      return a.get('name').localeCompare(b.get('name')) * desc;
    })
    .sort(sortDataSourceFirst);
};

export {
  hasSampleDataAvailable,
  mergeSampleDataToConfigurations,
  getComponentsFiltered,
  sortDataSourceFirst,
  sortComponents,
};
