import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal as ReactModal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import contactSupport from '@/utils/contactSupport';

const Modal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    availableVersions: PropTypes.array.isRequired,
    imageTag: PropTypes.string,
  },

  getInitialState() {
    return {
      imageTag: this.props.imageTag,
      isSaving: false,
    };
  },

  render() {
    return (
      <ReactModal onHide={this.props.onClose} show={this.props.show}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>Transformation Backend Version</ReactModal.Title>
          <ModalIcon icon="right-left" color="green" bold />
        </ReactModal.Header>
        <ReactModal.Body>
          <p>
            Most of the time, there&apos;s no need to change versions of the transformation backend.
          </p>
          <p>
            Change versions only if you have problems with the <code>Latest</code> version. And{' '}
            <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
              let us know
            </Button>{' '}
            please.
          </p>
          <Select
            clearable={false}
            options={this.props.availableVersions}
            value={this.state.imageTag}
            onChange={(selected) => this.setState({ imageTag: selected })}
          />
        </ReactModal.Body>
        <ReactModal.Footer>
          <ConfirmButtons
            block
            onSave={this.onSave}
            isSaving={this.state.isSaving}
            isDisabled={
              this.props.imageTag === this.state.imageTag ||
              (!this.props.imageTag && this.state.imageTag === '')
            }
          />
        </ReactModal.Footer>
      </ReactModal>
    );
  },

  onSave() {
    this.setState({
      isSaving: true,
    });
    this.props.onSave(this.state.imageTag).finally(() => {
      this.setState(
        {
          isSaving: false,
        },
        this.props.onClose,
      );
    });
  },
});

export default Modal;
