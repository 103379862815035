import { SERVICE_AI } from '@/constants/serviceIds';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import { constructFQID, FQID_TYPES } from './fqid';
import type { ProjectsLineage, TablesLineage } from './types';

const createLineageRequest = (
  path: string,
  query?: Record<string, string | number | boolean | undefined>,
) => {
  return request('GET', `${ServicesStore.getServiceUrl(SERVICE_AI)}/omnisearch/v1/lineage/${path}`)
    .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
    .query(query ?? {})
    .promise()
    .then((response) => response.body);
};

export const getProjectsLineage = () => {
  return createLineageRequest('projects').then((response: { graph: ProjectsLineage }) => response);
};

export const getTablesLineage = ({
  columns = true,
  fqid,
  project,
}: {
  columns?: boolean;
  fqid?: string;
  project?: string;
}) => {
  const projectId = ApplicationStore.getCurrentProjectId();

  return createLineageRequest('tables', {
    project: project ?? constructFQID({ type: FQID_TYPES.project, data: { projectId } }),
    startingPoint: fqid,
    columns,
  }).then((response: { graph: TablesLineage }) => response);
};
