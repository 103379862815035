import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { Map } from 'immutable';

import ServiceAccountModal from '@/modules/wr-google-bigquery-v2/react/components/ServiceAccountModal';
import SaveButtons from '@/react/common/SaveButtons';

class BigQueryServiceAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.handleServiceAccountSubmit = this.handleServiceAccountSubmit.bind(this);
  }

  render() {
    return (
      <div className="form-horizontal">
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Google BigQuery credentials</h2>
          </div>
          <div className="box-content">
            {this.renderSaveButtons()}
            {this.renderCredentials()}
          </div>
        </div>
        <ServiceAccountModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onSubmit={this.handleServiceAccountSubmit}
          documentationLink={`${URLS.USER_DOCUMENTATION}/components/writers/database/bigquery/#create-service-account`}
        />
      </div>
    );
  }

  renderSaveButtons() {
    if (this.props.readOnly) {
      return null;
    }
    return (
      <div className="save-buttons">
        <SaveButtons
          isSaving={this.props.isSaving}
          isChanged={!this.props.savedCredentials.equals(this.props.credentials)}
          disabled={this.props.isSaving || !this.hasValidCredentials()}
          onReset={this.props.cancelEditingFn}
          onSave={this.props.saveCredentialsFn}
        />
      </div>
    );
  }

  renderCredentials() {
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Dataset name</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              disabled={this.props.isSaving || this.props.readOnly}
              value={this.props.credentials.get('dataset', '')}
              onChange={(event) =>
                this.props.onChangeFn(this.props.credentials.set('dataset', event.target.value))
              }
            />
          </div>
        </FormGroup>
        {this.props.credentials.hasIn(['service_account', 'project_id']) ? (
          <>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Project</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  {this.props.credentials.getIn(['service_account', 'project_id'])}
                </FormControl.Static>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Service Account Email</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  {this.props.credentials.getIn(['service_account', 'client_email'])}
                </FormControl.Static>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Service Account Key ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  {this.props.credentials.getIn(['service_account', 'private_key_id'])}
                </FormControl.Static>
              </div>
            </FormGroup>
            {!this.props.readOnly && (
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Button bsStyle="success" onClick={() => this.setState({ showModal: true })}>
                    Set New Service Account Key
                  </Button>
                </div>
              </FormGroup>
            )}
          </>
        ) : (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Service Account Key</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Button bsStyle="success" onClick={() => this.setState({ showModal: true })}>
                Set Service Account Key
              </Button>
            </div>
          </FormGroup>
        )}
      </>
    );
  }

  handleServiceAccountSubmit(value) {
    this.setState({ showModal: false });
    this.props.onChangeFn(
      this.props.credentials.set(
        'service_account',
        value.set('#private_key', value.get('private_key', '')).delete('private_key'),
      ),
    );
  }

  hasValidCredentials() {
    return (
      !!this.props.credentials.get('dataset', '').trim() &&
      !!this.props.credentials.has('service_account')
    );
  }
}

BigQueryServiceAccountForm.propTypes = {
  credentials: PropTypes.instanceOf(Map).isRequired,
  savedCredentials: PropTypes.instanceOf(Map).isRequired,
  saveCredentialsFn: PropTypes.func.isRequired,
  cancelEditingFn: PropTypes.func.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default BigQueryServiceAccountForm;
