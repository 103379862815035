import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Tooltip } from 'design';
import { Map } from 'immutable';

import { deployApp } from '@/modules/data-apps/actions';
import { isAppConfigured } from '@/modules/data-apps/helpers';
import WorkspaceExpirationControl from '@/modules/sandboxes/components/WorkspaceExpirationControl';
import WorkspaceSizeControl from '@/modules/sandboxes/components/WorkspaceSizeControl';
import { AUTO_SLEEP_DEFAULT, SANDBOX_SIZES, SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

const DeployDataApp = (props: {
  mode: 'sidebar' | 'menuitem';
  config: Map<string, any>;
  sandbox: Map<string, any>;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const [size, setSize] = React.useState(SANDBOX_SIZES.TINY);
  const [expiration, setExpiration] = React.useState(AUTO_SLEEP_DEFAULT);

  const [deploying, setDeploying] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const isConfigured = isAppConfigured(props.config);
  const isDisabled = props.readOnly || !isConfigured || deploying;

  const handleDeploy = () => {
    const parameters = props.config
      .getIn(['configuration', 'parameters'], Map())
      .set('size', size)
      .set('expirationAfterHours', expiration);

    setDeploying(true);
    return deployApp(props.config, props.sandbox, parameters).finally(() => setDeploying(false));
  };

  const handleClick = () => setShowConfirmModal(true);

  const label = `${props.sandbox.isEmpty() ? 'Deploy' : 'Redeploy'} data app`;

  const renderDeployAction = () => {
    if (props.mode === 'sidebar') {
      return (
        <Button block bsStyle="success" disabled={isDisabled} onClick={handleClick}>
          {deploying ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="play-circle" className="icon-addon-right" />
          )}
          {label}
        </Button>
      );
    }

    return (
      <RowActionMenuItem onSelect={handleClick} disabled={isDisabled} onKeyDown={props.onKeyDown}>
        {deploying ? <Loader /> : <FontAwesomeIcon icon="play-circle" fixedWidth />}
        {label}
      </RowActionMenuItem>
    );
  };

  return (
    <>
      {isConfigured ? (
        renderDeployAction()
      ) : (
        <Tooltip tooltip="Data App is not configured" placement="top">
          {renderDeployAction()}
        </Tooltip>
      )}
      <ConfirmModal
        closeAfterResolve
        isLoading={deploying}
        show={showConfirmModal}
        onEnter={() => {
          setSize(props.sandbox.get('size', SANDBOX_SIZES.TINY));
          setExpiration(props.sandbox.get('expirationAfterHours', AUTO_SLEEP_DEFAULT));
        }}
        onHide={() => setShowConfirmModal(false)}
        icon="play-circle"
        title={label}
        buttonLabel={label}
        text={
          <>
            {props.sandbox.get('active') && (
              <Alert className="tw-mb-5">
                App is currently running. Are you sure you want to deploy a new version?
              </Alert>
            )}
            <WorkspaceSizeControl
              type={SANDBOX_TYPE.STREAMLIT}
              value={size}
              onChange={setSize}
              isDisabled={isDisabled}
              hasPayAsYouGo={props.hasPayAsYouGo}
            />
            <WorkspaceExpirationControl
              type={SANDBOX_TYPE.STREAMLIT}
              value={expiration}
              onChange={setExpiration}
              isDisabled={isDisabled}
            />
          </>
        }
        onConfirm={handleDeploy}
      />
    </>
  );
};

export default DeployDataApp;
