import dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import VersionsActions from '@/modules/components/VersionsActionCreators';
import { ProvisioningActionTypes } from '@/modules/gooddata-writer-v3/constants';
import SimpleError from '@/utils/errors/SimpleError';
import jobPoller from '@/utils/jobPoller';

export default {
  readModel(parameters) {
    dispatcher.handleViewAction({
      type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_START,
    });
    const componentId = 'keboola.gooddata-writer';
    const plainJobParams = {
      component: componentId,
      method: 'run',
      data: {
        config: parameters.configurationId,
        configData: {
          parameters: {
            action: 'readModel',
            configurationId: parameters.configurationId,
            user: parameters.user,
            project: parameters.project,
            bucket: parameters.bucket,
          },
        },
      },
    };
    return InstalledComponentsApi.encryptConfiguration(componentId, plainJobParams)
      .then(InstalledComponentsActionCreators.runComponent)
      .then(({ url }) => jobPoller.poll(url))
      .catch((error) => {
        throw new SimpleError('Loading LDM model failed', error.message);
      })
      .then(() =>
        InstalledComponentsActionCreators.loadComponentConfigDataForce(
          componentId,
          parameters.configurationId,
        ),
      )
      .then(() => {
        VersionsActions.loadVersionsForce(componentId, parameters.configurationId);
        dispatcher.handleViewAction({
          type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_SUCCESS,
        });
        return null;
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_ERROR,
        });
        throw error;
      });
  },
};
