import React from 'react';
import { HelpBlock } from 'design';

const EncryptedPropertiesHelpBlock = () => {
  return (
    <HelpBlock>
      Properties prefixed with <code>#</code> sign will be encrypted on save. Already encrypted
      strings will persist.
    </HelpBlock>
  );
};

export default EncryptedPropertiesHelpBlock;
