import Immutable, { List } from 'immutable';

const createConfiguration = function (localState) {
  return Immutable.fromJS({
    parameters: {
      customerId: localState.get('customerId', []),
      onlyEnabledCustomers: localState.get('onlyEnabledCustomers', true),
      getAccountChildren: localState.get('getAccountChildren', false),
    },
  });
};

const parseConfiguration = function (configuration) {
  return Immutable.fromJS({
    customerId: configuration.getIn(['parameters', 'customerId'], []),
    onlyEnabledCustomers: configuration.getIn(['parameters', 'onlyEnabledCustomers'], true),
    getAccountChildren: configuration.getIn(['parameters', 'getAccountChildren'], false),
  });
};

const isComplete = function (configuration) {
  return !configuration.getIn(['parameters', 'customerId'], List()).isEmpty();
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  isComplete: isComplete,
};
