import { fromJS, Map } from 'immutable';

const KEY = 'pagination';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());

  return fromJS({ [KEY]: { scroller: job.get('scroller') } });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  return editing.getIn([KEY, 'scroller'])
    ? job.set('scroller', editing.getIn([KEY, 'scroller']))
    : job.delete('scroller');
};

export { KEY, mapToState, mapToJob };
