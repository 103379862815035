import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlButton, useReactFlow } from '@xyflow/react';
import { Tooltip } from 'design';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';
import { zoomOptions } from './initConfig';

const FitViewButton = () => {
  const { fitView } = useReactFlow<CustomNode, CustomEdge>();

  return (
    <Tooltip placement="left" tooltip="Fit view" type="action">
      <ControlButton onClick={() => fitView(zoomOptions)}>
        <FontAwesomeIcon icon="up-right-and-down-left-from-center" />
      </ControlButton>
    </Tooltip>
  );
};

export default FitViewButton;
