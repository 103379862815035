import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from 'design';
import type { Map } from 'immutable';
import { parse, stringify } from 'smol-toml';
import _ from 'underscore';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { THEME_DEFAULT_FONT, themes } from '@/modules/data-apps/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';

const CUSTOM_THEME = 'custom';
const HEX_REGEX = /^#([0-9A-F]{6}|[0-9A-F]{3})$/i;

const themeOptions = [
  { value: 'keboola', label: 'Keboola' },
  { value: 'light-red', label: 'Light Red' },
  { value: 'light-purple', label: 'Light Purple' },
  { value: 'light-blue', label: 'Light Blue' },
  { value: 'dark-green', label: 'Dark Green' },
  { value: 'dark-amber', label: 'Dark Amber' },
  { value: 'dark-orange', label: 'Dark Orange' },
  { value: CUSTOM_THEME, label: 'Custom' },
];

const fontOptions = [
  { value: THEME_DEFAULT_FONT, label: 'Sans Serif' },
  { value: 'serif', label: 'Serif' },
  { value: 'monospace', label: 'Monospace' },
];

type ThemeName = keyof typeof themes | typeof CUSTOM_THEME;

const CONFIG_PATH = ['parameters', 'dataApp', 'streamlit', 'config.toml'];

type ThemeValues = { [key in string]: string };

const ThemeSettings = (props: {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  sandbox: Map<string, any>;
}) => {
  const parsedConfig = parse(props.configData.getIn(CONFIG_PATH, ''));
  const parsedTheme = parsedConfig?.theme as ThemeValues;
  const savedThemeValues = parsedTheme || {
    font: THEME_DEFAULT_FONT,
  };

  const [saving, setSaving] = React.useState(false);
  const [themeValues, setThemeValues] = React.useState<ThemeValues>(savedThemeValues);

  const handleSetThemeValues = (updatedThemeValues: { [key in string]: string }) => {
    setThemeValues({
      ...themeValues,
      ...updatedThemeValues,
    });
  };

  const isThemeValid = () => {
    return (
      themeValues.font &&
      themeValues.textColor &&
      themeValues.backgroundColor &&
      themeValues.secondaryBackgroundColor &&
      themeValues.primaryColor
    );
  };

  const renderColorInput = (themeKey: string, label: string) => {
    const hasHexValue = !!HEX_REGEX.test(themeValues[themeKey]);

    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <div className="tw-relative">
          <FormControl
            className={cn(hasHexValue && 'tw-pl-11')}
            type="text"
            value={themeValues[themeKey]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              handleSetThemeValues({
                [themeKey]: !value.includes('#') && value.length > 0 ? `#${value}` : value,
              });
            }}
          />

          {hasHexValue && (
            <div
              className="tw-absolute tw-left-4 tw-top-2.5 tw-h-5 tw-w-5 tw-rounded-sm tw-border tw-border-solid tw-border-neutral-300"
              style={{ background: themeValues[themeKey] || 'transparent' }}
            />
          )}

          <input
            type="color"
            className="tw-absolute tw-right-1 tw-top-0.5 tw-h-9 tw-w-9 tw-cursor-pointer tw-opacity-0"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSetThemeValues({
                [themeKey]: event.target.value,
              })
            }
          />

          <FontAwesomeIcon
            icon="eye-dropper"
            className="tw-pointer-events-none tw-absolute tw-right-3.5 tw-top-3 tw-text-base tw-text-neutral-400"
            fixedWidth
          />
        </div>
      </FormGroup>
    );
  };

  return (
    <CollapsibleBox
      title="Theme"
      additionalActions={() => {
        return (
          <SaveButtons
            disabled={!isThemeValid()}
            isSaving={saving}
            isChanged={!_.isEqual(savedThemeValues, themeValues)}
            onSave={() => {
              setSaving(true);

              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                props.configData.setIn(
                  CONFIG_PATH,
                  stringify({ ...parsedConfig, theme: themeValues }),
                ),
                'Theme changed',
              ).finally(() => setSaving(false));
            }}
            onReset={() => setThemeValues(savedThemeValues)}
          />
        );
      }}
    >
      <FormGroup>
        <ControlLabel>Color Palette</ControlLabel>
        <Select
          clearable={false}
          searchable={false}
          value={themeValues.themeName}
          placeholder="Select a palette"
          onChange={(name: ThemeName) => {
            if (name !== CUSTOM_THEME) {
              handleSetThemeValues(themes[name]);
            } else {
              // Remove values set by previously selected themes and start with empty values
              setThemeValues({ themeName: CUSTOM_THEME, font: THEME_DEFAULT_FONT });
            }
          }}
          options={themeOptions}
        />
      </FormGroup>

      {themeValues.themeName === CUSTOM_THEME && (
        <>
          <FormGroup className="tw-mt-4">
            <ControlLabel>Typeface</ControlLabel>
            <Select
              clearable={false}
              searchable={false}
              value={themeValues.font}
              onChange={(font: string) => handleSetThemeValues({ font })}
              options={fontOptions}
            />
          </FormGroup>

          {renderColorInput('primaryColor', 'Primary Color')}
          {renderColorInput('textColor', 'Text Color')}
          {renderColorInput('backgroundColor', 'Background Color')}
          {renderColorInput('secondaryBackgroundColor', 'Secondary Background Color')}
        </>
      )}
    </CollapsibleBox>
  );
};

export default ThemeSettings;
