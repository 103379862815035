import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Alert } from 'design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';

const DeleteTableModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    sapiToken: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired,
    tableAliases: PropTypes.array.isRequired,
    tableLinks: PropTypes.array.isRequired,
    deleting: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Table</Modal.Title>
          <ModalIcon.Trash />
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete the table <b>{this.props.table.get('displayName')}</b>?
          </p>
          <DevBranchStorageWarning
            message="The table will also be deleted in production."
            hasProductionEntity={
              !this.props.table.isEmpty() && !isCreatedInDevBranch(this.props.table.get('bucket'))
            }
          />
          {this.hasAliasesOrLinks() && (
            <>
              <Alert variant="warning" className="tw-mb-5">
                All alias tables and links will also be deleted.
              </Alert>
              <TableAliasesAndLinks
                sapiToken={this.props.sapiToken}
                urlTemplates={this.props.urlTemplates}
                tableAliases={this.props.tableAliases}
                tableLinks={this.props.tableLinks}
                onLinkClick={this.props.onHide}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.deleting}
            isDisabled={this.props.deleting}
            saveLabel={this.props.deleting ? 'Deleting...' : 'Delete'}
            saveStyle="danger"
            onSave={this.handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  handleSubmit() {
    this.props.onConfirm().then(this.props.onHide);
  },

  hasAliasesOrLinks() {
    return this.props.tableAliases.length > 0 || this.props.tableLinks.length > 0;
  },
});

export default DeleteTableModal;
