import React from 'react';
import { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '@/constants/componentIds';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { prepareQueryToSave } from '@/modules/ex-mongodb/helpers';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const RowDetailHeader = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const rowId = RoutesStore.getCurrentRouteParam('row');
      const editing =
        ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId) || Map();
      const configData = ConfigurationRowsStore.getConfiguration(componentId, configId, rowId);

      return {
        rowId,
        configId,
        editing,
        isChanged: !configData.equals(editing),
        pendingActions: ConfigurationRowsStore.getPendingActions(componentId, configId, rowId),
      };
    },
    [],
    [RoutesStore, ConfigurationRowsStore],
  );

  const onSave = () => {
    return ConfigurationRowsActionCreators.saveConfigurationSimple(
      componentId,
      state.configId,
      state.rowId,
      state.editing.update('parameters', Map(), prepareQueryToSave),
      'Parameters updated',
    );
  };

  const onReset = () => {
    return ConfigurationRowsActionCreators.resetConfiguration(
      componentId,
      state.configId,
      state.rowId,
    );
  };

  return (
    <CatchUnsavedChanges isDirty={state.isChanged} onSave={onSave} onDirtyLeave={onReset}>
      <SaveButtons
        isSaving={state.pendingActions.has('save-configuration')}
        isChanged={state.isChanged}
        onSave={onSave}
        onReset={onReset}
      />
    </CatchUnsavedChanges>
  );
};

export default RowDetailHeader;
