import React from 'react';
import PropTypes from 'prop-types';
import { ProductFruits as ProductFruitsSource } from 'react-product-fruits';
import { Map } from 'immutable';

import { DEMO_PROJECT_USER_ID } from '@/constants/localStorageKeys';
import dayjs from '@/date';
import loadJiraWidget from '@/utils/external/jiraWidget';
import { getCustomData, getProjectCode } from '@/utils/external/productFruits';
import generateId from '@/utils/generateId';
import { getItem, setItem } from '@/utils/localStorage';

class ProductFruits extends React.PureComponent {
  constructor(props) {
    super(props);

    if (props.isDemoPreview) {
      this.state = { user: { username: getItem(DEMO_PROJECT_USER_ID, generateId()) } };
    } else {
      this.state = {
        user: {
          email: props.user.get('email'),
          username: props.user.get('email'),
          signUpAt: dayjs(props.sapiToken.get('created')).toISOString(),
        },
      };
    }
  }

  componentDidMount() {
    loadJiraWidget();

    if (this.props.isDemoPreview && !getItem(DEMO_PROJECT_USER_ID)) {
      setItem(DEMO_PROJECT_USER_ID, this.state.user.username);
    }
  }

  componentWillUnmount() {
    window.productFruits?.services?.destroyAsync();
  }

  render() {
    return (
      <ProductFruitsSource
        workspaceCode={getProjectCode()}
        language="en"
        user={{
          ...this.state.user,
          props: getCustomData(this.props.location),
        }}
        config={{ disableLocationChangeDetection: true }}
      />
    );
  }
}

ProductFruits.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  location: PropTypes.object.isRequired,
  isDemoPreview: PropTypes.bool.isRequired,
};

export default ProductFruits;
