const DatasetLocations = [
  {
    label: 'Multi-regional',
    options: [
      {
        label: 'United States',
        value: 'US',
      },
      {
        label: 'European Union',
        value: 'EU',
      },
    ],
  },
  {
    label: 'America',
    options: [
      {
        label: 'Iowa',
        value: 'us-central1',
      },
      {
        label: 'Las Vegas',
        value: 'us-west4',
      },
      {
        label: 'Los Angeles',
        value: 'us-west2',
      },
      {
        label: 'Montréal',
        value: 'northamerica-northeast1',
      },
      {
        label: 'Northern Virginia',
        value: 'us-east4',
      },
      {
        label: 'Oregon',
        value: 'us-west1',
      },
      {
        label: 'Salt Lake City',
        value: 'us-west3',
      },
      {
        label: 'São Paulo',
        value: 'southamerica-east1',
      },
      {
        label: 'Santiago',
        value: 'southamerica-west1',
      },
      {
        label: 'South Carolina',
        value: 'us-east1',
      },
      {
        label: 'Toronto',
        value: 'northamerica-northeast2',
      },
    ],
  },
  {
    label: 'Europe',
    options: [
      {
        label: 'Belgium',
        value: 'europe-west1',
      },
      {
        label: 'Finland',
        value: 'europe-north1',
      },
      {
        label: 'Frankfurt',
        value: 'europe-west3',
      },
      {
        label: 'London',
        value: 'europe-west2',
      },
      {
        label: 'Netherlands',
        value: 'europe-west4',
      },
      {
        label: 'Warsaw',
        value: 'europe-central2',
      },
      {
        label: 'Zürich',
        value: 'europe-west6',
      },
    ],
  },
  {
    label: 'Asia Pacific',
    options: [
      {
        label: 'Delhi',
        value: 'asia-south2',
      },
      {
        label: 'Hong Kong',
        value: 'asia-east2',
      },
      {
        label: 'Jakarta',
        value: 'asia-southeast2',
      },
      {
        label: 'Melbourne',
        value: 'australia-southeast2',
      },
      {
        label: 'Mumbai',
        value: 'asia-south1',
      },
      {
        label: 'Osaka',
        value: 'asia-northeast2',
      },
      {
        label: 'Seoul',
        value: 'asia-northeast3',
      },
      {
        label: 'Singapore',
        value: 'asia-southeast1',
      },
      {
        label: 'Sydney',
        value: 'australia-southeast1',
      },
      {
        label: 'Taiwan',
        value: 'asia-east1',
      },
      {
        label: 'Tokyo',
        value: 'asia-northeast1',
      },
    ],
  },
];

const DefaultDatasetLocation = 'US';

const IncrementalFetchingAllowedTypes = [
  'INTEGER',
  'FLOAT',
  'NUMERIC',
  'BIGNUMERIC',
  'TIMESTAMP',
  'DATE',
  'TIME',
  'DATETIME',
];

export { DatasetLocations, DefaultDatasetLocation, IncrementalFetchingAllowedTypes };
