import React, { useState } from 'react';
import { Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { COLLAPSED_PULL_CONFIGURATIONS } from '@/constants/localStorageKeys';
import CollapseButton from '@/react/common/CollapseButton';
import ComponentDetailLink from '@/react/common/ComponentDetailLink';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ComponentType from '@/react/common/ComponentType';
import SortByName, { type SortState } from '@/react/common/SortByName';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import nextTick from '@/utils/nextTick';
import string from '@/utils/string';
import { getConfigurationStatus } from './helpers';
import PullFromProductionConfigurationRow from './PullFromProductionConfigurationRow';

export type ActionType = 'compare' | 'reset';

type Props = {
  component: Map<string, any>;
  productionComponents: Map<string, any>;
  devComponents: Map<string, any>;
  configurations: Map<string, any>;
  admins: Map<string, any>;
  isLoadingDiff: boolean;
  readOnly: boolean;
  hasFlows: boolean;
  onClose?: () => void;
  onAction?: (type: ActionType, detail: Map<string, any>) => void;
};

const PullFromProductionComponentRow = ({
  component,
  productionComponents,
  devComponents,
  configurations,
  isLoadingDiff,
  readOnly,
  hasFlows,
  admins,
  onAction,
  onClose,
}: Props) => {
  const [sort, setSort] = useState<SortState>('asc');
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_PULL_CONFIGURATIONS}-${component?.get('id')}`,
    false,
  );

  const renderConfiguration = (configuration: Map<string, any>) => {
    const realComponentId = configuration.get('isSample')
      ? KEBOOLA_EX_SAMPLE_DATA
      : component.get('id');
    const realComponent = productionComponents.get(realComponentId);

    const handleAction = (type: ActionType) => () => {
      const detail = Map({
        name: configuration.get('name'),
        componentId: component.get('id'),
        configId: configuration.get('id'),
      });

      onAction && onAction(type, detail);
    };

    return (
      <PullFromProductionConfigurationRow
        key={configuration.get('id')}
        config={configuration}
        component={realComponent}
        readOnly={readOnly}
        admins={admins}
        status={getConfigurationStatus(
          component.get('id'),
          configuration.get('id'),
          productionComponents,
          devComponents,
        )}
        onCompare={handleAction('compare')}
        onReset={handleAction('reset')}
        isLoadingDiff={isLoadingDiff}
      />
    );
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSort = (entityA: Map<string, any>, entityB: Map<string, any>) => {
    const sortBy = sort === 'asc' ? 1 : -1;

    return entityA.get('name').localeCompare(entityB.get('name')) * sortBy;
  };

  const componentType = component.get('type');

  return (
    <div className="box">
      <div
        onClick={toggleCollapse}
        className="flex-container component-name-above-table btn-collapse-area"
      >
        <span className="flex-container flex-start">
          <ComponentIcon component={component} size="48" className="mrp-4" />
          <div className="flex-container flex-column align-top">
            <ComponentDetailLink
              componentId={component.get('id')}
              onClick={() => nextTick(onClose)}
              hasFlows={hasFlows}
            >
              <ComponentName component={component} />
            </ComponentDetailLink>
            {componentType !== 'other' && (
              <ComponentType
                noIcon
                type={componentType}
                className="f-14 font-medium line-height-20"
              />
            )}
          </div>
        </span>

        <CollapseButton isCollapsed={isCollapsed} onToggle={toggleCollapse} />
      </div>
      {isCollapsed ? (
        <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
          <span className="font-bold">{configurations.size} </span>
          <span className="text-muted">
            {string.pluralize(configurations.size, 'Configuration')}
          </span>
        </div>
      ) : (
        <div className="table table-hover fixed">
          <div className="thead">
            <div className="tr">
              <span className="th">
                <SortByName sortBy={sort} onClick={setSort} />
              </span>
              <span className="th w-300 text-right">Last change</span>
              <span className="th w-300 text-right">Status</span>
            </div>
          </div>
          <div className="tbody">
            {configurations.sort(handleSort).map(renderConfiguration).toArray()}
          </div>
        </div>
      )}
    </div>
  );
};

export default PullFromProductionComponentRow;
