import { fromJS } from 'immutable';

const createConfiguration = function (localState) {
  return fromJS({
    parameters: {
      account_name: localState.get('account_name', ''),
      '#account_key': localState.get('account_key', ''),
      container_name: localState.get('container_name', ''),
    },
  });
};

const parseConfiguration = function (configuration) {
  return fromJS({
    account_name: configuration.getIn(['parameters', 'account_name'], ''),
    account_key: configuration.getIn(['parameters', '#account_key'], ''),
    container_name: configuration.getIn(['parameters', 'container_name'], ''),
  });
};

const isComplete = function (configuration) {
  return (
    configuration.getIn(['parameters', 'account_name'], '') !== '' &&
    configuration.getIn(['parameters', '#account_key'], '') !== '' &&
    configuration.getIn(['parameters', 'container_name'], '') !== ''
  );
};

export default { createConfiguration, parseConfiguration, isComplete };
