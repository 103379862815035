import Promise from 'bluebird';
import type { List } from 'immutable';

import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';

const saveMetadataDescription = (description: string) => {
  if (!description) {
    const descriptionMetadata = (DevBranchesStore.getAllMetadata() as List<any>).find(
      (row) => row.get('key') === MetadataKeys.PROJECT_DESCRIPTION,
    );

    if (!descriptionMetadata) {
      return Promise.resolve();
    }

    return MetadataActionCreators.deleteMetadata(
      ObjectTypes.BRANCH,
      DevBranchesStore.getDefaultBranchId(),
      descriptionMetadata.get('id'),
    );
  }

  return MetadataActionCreators.saveBranchesMetadata(
    DevBranchesStore.getDefaultBranchId(),
    MetadataKeys.PROJECT_DESCRIPTION,
    description,
  );
};

export { saveMetadataDescription };
