export const ActionTypes = {
  OAUTHV2_LOAD_ALL_CREDENTIALS_SUCCESS: 'OAUTHV2_LOAD_ALL_CREDENTIALS_SUCCESS',
  OAUTHV2_LOAD_CREDENTIALS_SUCCESS: 'OAUTHV2_LOAD_CREDENTIALS_SUCCESS',
  OAUTHV2_DELETE_CREDENTIALS_SUCCESS: 'OAUTHV2_DELETE_CREDENTIALS_SUCCESS',
};

export const Constants = {
  OAUTH_VERSION_3: 3,
  ROUTE_NAME: 'oauth-redirect',
  EXTERNAL_OAUTH_URL: 'https://external.keboola.com/oauth/index.html',
};
