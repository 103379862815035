import { List, Map } from 'immutable';

import { BETA, EXCLUDE_FROM_NEW_LIST, EXPERIMENTAL } from '@/constants/componentFlags';
import dayjs, {
  DATE_FORMAT,
  DATE_FORMAT_WITHOUT_YEAR,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS,
} from '@/date';

const isUsedInFlow = (flow: Map<string, any>, componentId: string, configId: string) => {
  return flow.getIn(['configuration', 'tasks'], List()).some((task: Map<string, any>) => {
    return (
      task.getIn(['task', 'componentId']) === componentId &&
      task.getIn(['task', 'configId']) === configId
    );
  });
};

const disableTasksWithDeletedConfigurations = (
  flow: Map<string, any>,
  componentId: string,
  configId: string,
) => {
  return flow.get('configuration', Map()).update('tasks', List(), (tasks: any) => {
    return tasks.map((task: Map<string, any>) => {
      if (
        task.getIn(['task', 'componentId']) === componentId &&
        task.getIn(['task', 'configId']) === configId
      ) {
        return task.set('enabled', false);
      }

      return task;
    });
  });
};

const usedFlows = (flows: Map<string, any>, componentId: string, config: Map<string, any>) => {
  return flows
    .filter((flow: Map<string, any>) => isUsedInFlow(flow, componentId, config.get('id')))
    .toArray();
};

const hasStageFlag = (flags: Map<string, any>) => {
  return (
    flags.includes(BETA) || flags.includes(EXPERIMENTAL) || flags.includes(EXCLUDE_FROM_NEW_LIST)
  );
};

const copyToClipboard = (text: string) => {
  return navigator.clipboard.writeText(text);
};

const formatAbsolute = (createdTime: string, withSeconds?: boolean) => {
  const date = dayjs(createdTime);
  const timeFormat = withSeconds ? TIME_FORMAT_WITH_SECONDS : TIME_FORMAT;

  if (date.year() === dayjs().year()) {
    return date.format(`${DATE_FORMAT_WITHOUT_YEAR} ${timeFormat}`);
  }

  return date.format(`${DATE_FORMAT} ${timeFormat}`);
};

const byteConverter = (bytes: number, precision = 2) => {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(Math.log(bytes) / Math.log(1000));

  return parseFloat((bytes / Math.pow(1000, exponent)).toFixed(precision)) + ' ' + sizes[exponent];
};

const isValidName = (value: string) => /^[a-zA-Z0-9_-]*$/.test(value);

export {
  isUsedInFlow,
  disableTasksWithDeletedConfigurations,
  usedFlows,
  hasStageFlag,
  copyToClipboard,
  formatAbsolute,
  byteConverter,
  isValidName,
};
