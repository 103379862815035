import { SERVICE_SYRUP, SERVICE_SYRUP_PROVISIONING } from '@/constants/serviceIds';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import JobPoller from '@/utils/jobPoller';
import request from '@/utils/request';

const createUrl = function (path) {
  return ServicesStore.getServiceUrl(SERVICE_SYRUP) + '/' + SERVICE_SYRUP_PROVISIONING + '/' + path;
};

const createRequest = function (method, path, token) {
  var sapiToken = token;
  if (!sapiToken) {
    sapiToken = ApplicationStore.getSapiTokenString();
  }
  return request(method, createUrl(path)).set('X-StorageApi-Token', sapiToken);
};

const ProvisioningApi = {
  getCredentials: function (backend, credentialsType, token) {
    return createRequest('GET', backend, token)
      .query({
        type: credentialsType,
      })
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  createCredentials: function (backend, credentialsType, token) {
    const requestData = {
      type: credentialsType,
    };
    return createRequest('POST', backend, token)
      .send(requestData)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  dropCredentials: function (backend, credentialsId, token) {
    return createRequest('DELETE', backend + '/' + credentialsId, token)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  extendCredentials: function (backend, credentialsId, token) {
    return createRequest('POST', backend + '/' + credentialsId + '/extend', token)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  createCredentialsAsync: function (backend, credentialsType, data, options) {
    var requestData = data;
    if (!requestData) {
      requestData = {};
    }
    const pathSuffix = options && options.source ? '/' + options.source : '';
    if (!options) {
      requestData.type = credentialsType;
    }
    return createRequest('POST', 'async/' + backend + pathSuffix)
      .send(requestData)
      .promise()
      .then((response) => JobPoller.poll(response.body.url, 2000))
      .then(() => ProvisioningApi.getCredentials(backend, credentialsType));
  },

  dropCredentialsAsync: function (backend, credentialsId) {
    return createRequest('DELETE', 'async/' + backend + '/' + credentialsId)
      .promise()
      .then((response) => JobPoller.poll(response.body.url, 2000))
      .then((response) => response.result);
  },
};

export default ProvisioningApi;
