import { Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import { ProvisioningActionTypes } from '@/modules/gooddata-writer-v3/constants';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'GooddataProvisioningStore',
  Map({
    isLoadingModel: false,
  }),
);

const ProvisioningStore = StoreUtils.createStore({
  getIsLoadingModel: () => _store.get('isLoadingModel', false),
});

dispatcher.register((payload) => {
  const { action } = payload;
  switch (action.type) {
    case ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_START:
      _store = _store.set('isLoadingModel', true);
      return ProvisioningStore.emitChange();

    case ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_SUCCESS:
    case ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_ERROR:
      _store = _store.remove('isLoadingModel');
      return ProvisioningStore.emitChange();

    default:
  }
});
export default ProvisioningStore;
