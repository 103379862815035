import React from 'react';
import createReactClass from 'create-react-class';

import { routeNames as transformationRouteNames } from '@/modules/transformations-v2/constants';
import Link from '@/react/common/RouterLink';

const migrateTransformationNotification = (component, config) => {
  return createReactClass({
    render() {
      return (
        <>
          <Link
            to={transformationRouteNames.ROOT}
            params={{
              component,
              config,
            }}
          >
            Transformation
          </Link>{' '}
          has been successfully migrated.
        </>
      );
    },
  });
};

export default migrateTransformationNotification;
