import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { HelpBlock, PanelWithDetails } from 'design';
import Immutable from 'immutable';
import _ from 'underscore';

import { isInputMappingSourceBucket } from '@/modules/legacy-transformation/helpers';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import { prepareSourceMapping } from './InputMappingRowDockerEditorHelper';
import InputMappingSource from './InputMappingSource';

const InputMappingRowDockerEditor = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    initialShowDetails: PropTypes.bool.isRequired,
    isDestinationDuplicate: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
  },

  _handleChangeSource(selected) {
    return this.props.onChange(prepareSourceMapping(selected, this.props.value, this.props.tables));
  },

  _handleChangeDestination(e) {
    const value = this.props.value.set('destination', e.target.value.trim());
    return this.props.onChange(value);
  },

  _handleChangeChangedSince(changedSince) {
    let { value } = this.props;
    if (this.props.value.has('days')) {
      value = value.delete('days');
    }
    value = value.set('changedSince', changedSince);
    return this.props.onChange(value);
  },

  _handleChangeColumns(newValue) {
    const immutable = this.props.value.withMutations((mapping) => {
      mapping.set('columns', newValue);
    });
    return this.props.onChange(immutable);
  },

  _handleChangeWhereColumn(string) {
    const value = this.props.value.set('whereColumn', string);
    return this.props.onChange(value);
  },

  _handleChangeWhereOperator(newValue) {
    const value = this.props.value.set('whereOperator', newValue);
    return this.props.onChange(value);
  },

  _handleChangeWhereValues(newValue) {
    const value = this.props.value.set('whereValues', newValue);
    return this.props.onChange(value);
  },

  _getColumns() {
    if (!this.props.value.get('source')) {
      return [];
    }
    const table = this.props.tables.find((t) => t.get('id') === this.props.value.get('source'));
    if (!table) {
      return [];
    }
    return table.get('columns', Immutable.List()).toJS();
  },

  _getColumnsOptions() {
    const columns = this._getColumns();
    return _.map(columns, (column) => ({
      label: column,
      value: column,
    }));
  },

  _getFilteredColumnsOptions() {
    let columns;
    if (this.props.value.get('columns').count()) {
      columns = this.props.value.get('columns').toJS();
    } else {
      columns = this._getColumns();
    }
    return _.map(columns, (column) => ({
      label: column,
      value: column,
    }));
  },

  _getFileName() {
    if (this.props.value.get('destination') && this.props.value.get('destination') !== '') {
      return this.props.value.get('destination');
    }
    if (this.props.value.get('source') && this.props.value.get('source') !== '') {
      return this.props.value.get('source');
    }
    return '';
  },

  render() {
    const isSelectedBucket = isInputMappingSourceBucket(this.props.value);
    const isDisabled = this.props.disabled || !this.props.value.get('source');

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Source</ControlLabel>
          </div>
          <div className="col-xs-10">
            <InputMappingSource
              disableMultiSelect
              mode={this.props.mode}
              tables={this.props.tables}
              buckets={this.props.buckets}
              value={this.props.value}
              onChange={this._handleChangeSource}
              disabled={this.props.disabled}
              isDestinationDuplicate={this.props.isDestinationDuplicate}
            />
          </div>
        </FormGroup>
        {!isSelectedBucket && (
          <FormGroup validationState={this.props.isDestinationDuplicate ? 'error' : null}>
            <div className="col-xs-2">
              <ControlLabel>File name</ControlLabel>
            </div>
            <div className="col-xs-10">
              <FormControl
                type="text"
                value={this.props.value.get('destination', '')}
                disabled={this.props.disabled}
                placeholder="File name"
                onChange={this._handleChangeDestination}
              />
              {this.props.isDestinationDuplicate ? (
                <HelpBlock variant="danger">
                  {'Duplicate destination '}
                  <code>{this.props.value.get('destination')}</code>.
                </HelpBlock>
              ) : (
                <HelpBlock>
                  The file will be available at
                  <code>{`in/tables/${this._getFileName()}`}</code>
                </HelpBlock>
              )}
            </div>
          </FormGroup>
        )}
        {isSelectedBucket && this.renderChangeInLast(isDisabled)}
        {!isSelectedBucket && (
          <PanelWithDetails defaultExpanded={this.props.initialShowDetails}>
            <FormGroup>
              <div className="col-xs-2">
                <ControlLabel>Columns</ControlLabel>
              </div>
              <div className="col-xs-10">
                <Select
                  multi
                  value={this.props.value.get('columns', Immutable.List()).toJS()}
                  disabled={isDisabled}
                  placeholder="All columns will be imported"
                  onChange={this._handleChangeColumns}
                  options={this._getColumnsOptions()}
                />
                <HelpBlock>Import only the specified columns</HelpBlock>
              </div>
            </FormGroup>
            {this.renderChangeInLast(isDisabled)}
            <FormGroup>
              <div className="col-xs-2">
                <ControlLabel>Data filter</ControlLabel>
              </div>
              <div className="col-xs-3 pr-0">
                <Select
                  value={this.props.value.get('whereColumn')}
                  disabled={isDisabled}
                  placeholder="Select a column"
                  onChange={this._handleChangeWhereColumn}
                  options={this._getColumnsOptions()}
                />
              </div>
              <div className="col-xs-2">
                <Select
                  clearable={false}
                  disabled={isDisabled}
                  value={this.props.value.get('whereOperator', '')}
                  onChange={this._handleChangeWhereOperator}
                  options={[
                    {
                      label: whereOperatorConstants.EQ_LABEL,
                      value: whereOperatorConstants.EQ_VALUE,
                    },
                    {
                      label: whereOperatorConstants.NOT_EQ_LABEL,
                      value: whereOperatorConstants.NOT_EQ_VALUE,
                    },
                  ]}
                />
              </div>
              <div className="col-xs-5 pl-0">
                <Select
                  value={this.props.value.get('whereValues')}
                  multi
                  disabled={isDisabled}
                  allowCreate
                  placeholder="Add a value"
                  emptyStrings
                  onChange={this._handleChangeWhereValues}
                />
              </div>
            </FormGroup>
          </PanelWithDetails>
        )}
      </Form>
    );
  },

  renderChangeInLast(isDisabled) {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Changed in last</ControlLabel>
        </div>
        <div className="col-xs-10">
          <ChangedSinceInput
            value={this.props.value.get(
              'changedSince',
              this.props.value.get('days') > 0 ? `-${this.props.value.get('days')} days` : null,
            )}
            disabled={isDisabled}
            onChange={this._handleChangeChangedSince}
          />
        </div>
      </FormGroup>
    );
  },
});

export default InputMappingRowDockerEditor;
