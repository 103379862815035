import {
  KDS_TEAM_PROCESSOR_CREATE_FILE_MANIFEST,
  KDS_TEAM_PROCESSOR_CSV2XLS,
  KEBOOLA_PROCESSOR_MOVE_FILES,
  KEBOOLA_SAPI_MERGED_EXPORT,
} from '@/constants/componentIds';
import componentRunner from '@/modules/components/ComponentRunner';
import { createUrl } from '@/modules/transformations/TransformationsApiAdapter';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import { EXPORT_TYPES } from './constants';

export default {
  exportTables: (tablesIds, type) => {
    return componentRunner.run({
      method: 'run',
      component: KEBOOLA_SAPI_MERGED_EXPORT,
      data: {
        configData: {
          storage: {
            input: {
              tables: tablesIds
                .map((tableId) => ({ source: tableId, destination: tableId + '.csv' }))
                .toArray(),
            },
          },
          parameters: {
            oneCompressFile: tablesIds.count() > 1,
            doCompression: tablesIds.count() > 1 || EXPORT_TYPES.GZIP === type,
          },
          ...(tablesIds.count() === 1 &&
            EXPORT_TYPES.XLSX === type && {
              processors: {
                after: [
                  {
                    definition: { component: KEBOOLA_PROCESSOR_MOVE_FILES },
                    parameters: { direction: 'tables' },
                  },
                  { definition: { component: KDS_TEAM_PROCESSOR_CSV2XLS } },
                  {
                    definition: { component: KEBOOLA_PROCESSOR_MOVE_FILES },
                    parameters: { direction: 'files' },
                  },
                  {
                    definition: { component: KDS_TEAM_PROCESSOR_CREATE_FILE_MANIFEST },
                    parameters: { encrypted: true, tags: ['storage-merged-export'] },
                  },
                ],
              },
            }),
        },
      },
    });
  },

  loadGraph(tableId, direction) {
    return request('GET', createUrl('graph'))
      .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
      .query({ table: tableId, direction })
      .promise()
      .then(({ body }) => body);
  },
};
