import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';

import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { truncateTable } from '@/modules/storage/actions';
import TruncateTableModal from '@/modules/storage/components/TruncateTableModal';
import streamActions from '@/modules/stream/actions';
import { getLatestImport, getTotalStatistics } from '@/modules/stream/helpers';
import type { StoreSource } from '@/modules/stream/store';
import CreatedDate from '@/react/common/CreatedDate';
import InfoTooltip from '@/react/common/InfoTooltip';
import tableIdParser from '@/utils/tableIdParser';
import RecordResults from './RecordResults';
import { TotalDataStatistics, TotalRowsStatistics } from './TotalStatistics';

type Props = {
  tables: Map<string, any>;
  source: StoreSource;
  readOnly: boolean;
};

const LineSeparator = () => <div className="tw-ml-[1px] tw-h-8 tw-w-[1px] tw-bg-neutral-200" />;

const Overview = (props: Props) => {
  const [showTruncateTableModal, setShowTruncateTableModal] = useState(false);
  const sink = props.source.sinks?.[0];
  const tableId = sink?.table?.tableId;

  if (!tableId) return;

  const { stage, bucket } = tableIdParser.parse(tableId).parts;
  const totalStatistics = getTotalStatistics(props.source);

  return (
    <>
      <div className="box-header big-padding">
        <div className="flex-container f-16 line-height-24 font-medium">
          <StorageApiBucketLinkEx
            bucketId={`${stage}.${bucket}`}
            className="f-16 line-height-24 font-medium color-main"
          />
          <span className="mlp-1 mrp-1">/</span>
          <StorageApiTableLinkEx
            tableId={tableId}
            className="f-16 line-height-24 font-medium color-main"
            showOnlyDisplayName
          />
          <InfoTooltip tooltip="Destination table where data will be streamed to." />
        </div>
        {!props.readOnly && props.tables.get(tableId) && (
          <>
            <Button onClick={() => setShowTruncateTableModal(true)} className="ml-auto mr-0 mtp-5">
              <FontAwesomeIcon icon="trash" className="icon-addon-right" />
              Clear All Data
            </Button>
            <TruncateTableModal
              show={showTruncateTableModal}
              onConfirm={() =>
                Promise.all([
                  truncateTable(tableId),
                  streamActions.clearSinkStatistics(sink.sourceId, sink.sinkId),
                ])
              }
              onHide={() => setShowTruncateTableModal(false)}
              table={props.tables.get(tableId)}
            />
          </>
        )}
      </div>
      <div className="box-content pt-0">
        {totalStatistics ? (
          <div>
            <h4>
              Table Statistics
              <InfoTooltip tooltip="Statistics refresh every 15 seconds." />
            </h4>
            <div className="tw-mt-4 tw-flex tw-items-center tw-gap-12">
              <div>
                <TotalDataStatistics
                  totalStatistics={totalStatistics}
                  className="tw-mb-0.5 tw-text-base tw-font-medium"
                />
                <span className="tw-text-neutral-400">
                  Waiting <span className="tw-text-neutral-200">/</span> Imported Data
                </span>
              </div>
              <LineSeparator />
              <div>
                <TotalRowsStatistics
                  totalStatistics={totalStatistics}
                  className="tw-mb-0.5 tw-text-base tw-font-medium"
                />
                <span className="tw-text-neutral-400">
                  Waiting <span className="tw-text-neutral-200">/</span> Imported Rows
                </span>
              </div>
              <LineSeparator />
              <div>
                <CreatedDate
                  createdTime={getLatestImport(props.source)}
                  className="tw-mb-0.5 tw-block tw-text-base tw-font-medium"
                />
                <span className="tw-text-neutral-400">Last Import</span>
              </div>
              <RecordResults source={props.source} />
            </div>
          </div>
        ) : (
          <div className="mtp-10 text-center">
            <h3 className="line-height-24">There are no data yet</h3>
            <p className="text-muted">
              Explore Payload Test or Sample Codes for Integration to learn how to efficiently
              stream data into Keboola.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Overview;
