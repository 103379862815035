import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import { Map } from 'immutable';

import FilePicker from '@/modules/ex-onedrive/react/FilePicker';
import Checkbox from '@/react/common/Checkbox';
import CreateWorkbook from './CreateWorkbook';
import CreateWorksheet from './CreateWorksheet';
import LoadWorksheets from './LoadWorksheets';

class Destination extends React.Component {
  render() {
    const { value, onChange, disabled, context } = this.props;

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Workbook Configuration</h2>
            <ButtonToolbar>
              <FilePicker
                disabled={disabled}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
              <CreateWorkbook
                disabled={disabled}
                configId={context.get('configurationId')}
                onCreate={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
            </ButtonToolbar>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>File Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.workbookName || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ workbookName: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Drive ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.driveId || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ driveId: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>File ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  value={value.fileId || ''}
                  onChange={(e) => onChange({ fileId: e.target.value })}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Worksheet Configuration</h2>
            <ButtonToolbar>
              <LoadWorksheets
                configId={context.get('configurationId')}
                fileId={value.fileId}
                driveId={value.driveId}
                onSelect={({ name, id }) => onChange({ worksheetName: name, id })}
                disabled={disabled}
              />
              <CreateWorksheet
                configId={context.get('configurationId')}
                fileId={value.fileId}
                driveId={value.driveId}
                onCreate={({ name, id }) => onChange({ worksheetName: name, id })}
                disabled={disabled}
              />
            </ButtonToolbar>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Worksheet Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.worksheetName || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ worksheetName: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Worksheet ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.id || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ id: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-8 col-xs-offset-4">
                <Checkbox
                  disabled={disabled}
                  checked={value.append}
                  onChange={(checked) => onChange({ append: checked })}
                >
                  Append
                </Checkbox>
                <HelpBlock>The new rows are inserted after the existing ones.</HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
}

Destination.propTypes = {
  context: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Destination;
