import { useCallback, useEffect, useState } from 'react';

import { CHANGE_EVENT_KEY, getItem, setItem } from '@/utils/localStorage';

const useLocalStorage = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => getItem(key, defaultValue));
  const update = useCallback(
    (value: T) => {
      setItem(key, value);
    },
    [key],
  );

  useEffect(() => {
    const localStorageEventListener = () => {
      setState(getItem(key, defaultValue));
    };

    window.addEventListener(CHANGE_EVENT_KEY, localStorageEventListener);

    return () => window.removeEventListener(CHANGE_EVENT_KEY, localStorageEventListener);
  }, [key, defaultValue]);

  return [state, update];
};

export default useLocalStorage;
