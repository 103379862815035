import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

class PhaseEditModal extends React.Component {
  state = {
    name: '',
    description: '',
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          this.setState({
            name: this.props.phase.get('name', ''),
            description: this.props.phase.get('description', ''),
          });
        }}
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            this.props.onSubmit(
              this.props.phase.get('id'),
              this.state.name,
              this.state.description,
            );
            this.props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Phase</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                placeholder="Type a name"
                value={this.state.name}
                onChange={(event) => this.setState({ name: event.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Note</ControlLabel>
              <Textarea
                minRows={3}
                className="form-control"
                placeholder="Describe the step"
                value={this.state.description}
                onChange={(event) => this.setState({ description: event.target.value })}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel="Save changes"
              isDisabled={this.state.name.trim() === ''}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

PhaseEditModal.propTypes = {
  phase: PropTypes.instanceOf(Map).isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default PhaseEditModal;
