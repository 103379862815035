import React from 'react';

import TableIconAction from '@/react/common/TableIconActions/TableIconAction';

const BulkActions = ({ onReset }: { onReset: () => void }) => {
  return (
    <div className="table-action-buttons no-wrap">
      <TableIconAction tooltip="Reset changes" onClick={onReset} icon="arrow-rotate-left" />
    </div>
  );
};

export default BulkActions;
