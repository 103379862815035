import React from 'react';

const AnimationBar = () => {
  return (
    <div className="animation-bar-box">
      <div className="animation-bar" />
      <div className="animation-bar" />
    </div>
  );
};

export default AnimationBar;
