import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import { List } from 'immutable';
import _ from 'underscore';

import editorMode from '@/modules/ex-db-generic/templates/editorMode';
import normalizeNewlines from '@/modules/transformations/utils/normalizeNewlines';
import parseQueries from '@/modules/transformations/utils/parseQueries';
import CodeEditor from '@/react/common/CodeEditor';

const InitQueriesForm = ({
  readOnly,
  isEditing,
  componentId,
  onChange,
  queries,
}: {
  readOnly: boolean;
  isEditing: boolean;
  componentId: string;
  queries: List<string>;
  onChange: (queries: List<string>) => void;
}) => {
  const [value, setValue] = React.useState(queries.join('\n\n'));

  React.useEffect(() => {
    if (!isEditing) {
      setValue(queries.join('\n\n'));
    }
  }, [queries, isEditing]);

  const debouncedOnChange = React.useMemo(() => {
    return _.debounce((queries: string) => {
      return parseQueries(queries)
        .then((queries: string[]) => List(queries))
        .catch(() => List([queries]))
        .then((queries) => onChange(queries.map(normalizeNewlines) as List<string>));
    }, 200);
  }, [onChange]);

  return (
    <FormGroup>
      <div className="col-xs-4">
        <ControlLabel>Initial SQL</ControlLabel>
      </div>
      <div className="col-xs-8">
        <CodeEditor
          value={value}
          onChange={(value: string) => {
            setValue(value);
            debouncedOnChange(value);
          }}
          options={{
            mode: editorMode(componentId),
            placeholder: '-- Your SQL goes here...',
            readOnly,
          }}
        />
        <HelpBlock>
          Entered SQL will be executed after the connection is successfully established. It can be
          used to prepare the environment for subsequent operations.
        </HelpBlock>
      </div>
    </FormGroup>
  );
};

export default InitQueriesForm;
