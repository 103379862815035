export const FQID_TYPES = {
  organization: 'ORG',
  maintainer: 'MTR',
  project: 'PRJ',
  bucket: 'BCT',
  table: 'TBL',
  column: 'COL',
  config: 'CFG',
  component: 'CMP',
  externalTable: 'EX_TBL',
  externalColumn: 'EX_COL',
} as const;

type FQIDTypes = typeof FQID_TYPES;

export type ParsedFQID = {
  type?: (typeof FQID_TYPES)[keyof typeof FQID_TYPES];
  organizationId?: string;
  maintainerId?: string;
  projectId?: string;
  bucketId?: string;
  tableId?: string;
  componentId?: string;
  configId?: string;
  column?: string;
};

const parseFQID = (fqid?: string): ParsedFQID | Record<string, never> => {
  if (!fqid?.trim()) return {};

  const [type, ...rest] = fqid.split(' ')[0].split('/') as [
    FQIDTypes[keyof FQIDTypes],
    ...string[],
  ];

  if (!Object.values(FQID_TYPES).includes(type)) return {};

  switch (type) {
    case 'ORG':
      return { type, organizationId: rest[0] };
    case 'MTR':
      return { type, maintainerId: rest[0] };
    case 'PRJ':
      return { type, projectId: rest[0] };
    case 'BCT':
      return { type, projectId: rest[0], bucketId: rest[1] };
    case 'TBL':
      return { type, projectId: rest[0], tableId: rest[1] };
    case 'COL':
      return { type, projectId: rest[0], tableId: rest[1], column: rest[2] };
    case 'CMP':
      return { type, projectId: rest[0], componentId: rest[1] };
    case 'CFG':
      return { type, projectId: rest[0], componentId: rest[1], configId: rest[2] };
    default:
      return {};
  }
};

export type FQIDData =
  | {
      type: FQIDTypes['organization'];
      data: { organizationId: string };
    }
  | {
      type: FQIDTypes['maintainer'];
      data: { maintainerId: string };
    }
  | {
      type: FQIDTypes['project'];
      data: { projectId: string };
    }
  | {
      type: FQIDTypes['bucket'];
      data: { projectId: string; bucketId: string };
    }
  | {
      type: FQIDTypes['table'];
      data: { projectId: string; tableId: string };
    }
  | {
      type: FQIDTypes['column'];
      data: { projectId: string; tableId: string; column: string };
    }
  | {
      type: FQIDTypes['component'];
      data: { projectId: string; componentId: string };
    }
  | {
      type: FQIDTypes['config'];
      data: { projectId: string; componentId: string; configId: string };
    };

const constructFQID = ({ type, data }: FQIDData) => {
  switch (type) {
    case FQID_TYPES.organization:
      return `ORG/${data.organizationId}`;
    case FQID_TYPES.maintainer:
      return `MTR/${data.maintainerId}`;
    case FQID_TYPES.project:
      return `PRJ/${data.projectId}`;
    case FQID_TYPES.bucket:
      return `BCT/${data.projectId}/${data.bucketId}`;
    case FQID_TYPES.table:
      return `TBL/${data.projectId}/${data.tableId}`;
    case FQID_TYPES.column:
      return `COL/${data.projectId}/${data.tableId}/${data.column}`;
    case FQID_TYPES.component:
      return `CMP/${data.projectId}/${data.componentId}`;
    case FQID_TYPES.config:
      return `CFG/${data.projectId}/${data.componentId}/${data.configId}`;
  }
};

export { parseFQID, constructFQID };
