import React from 'react';
import classnames, { type Argument } from 'classnames';

const ProductionIcon = ({
  width = 20,
  height = 20,
  className,
}: {
  width?: number;
  height?: number;
  className?: Argument;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames('color-primary', className)}
    >
      <path
        d="M12.7461 8.54688C12.7461 9.34766 12.0938 10 11.293 10H8.54688V7.09375H11.293C12.0938 7.09375 12.7461 7.74609 12.7461 8.54688ZM19.6875 10C19.6875 15.3516 15.3516 19.6875 10 19.6875C4.64844 19.6875 0.3125 15.3516 0.3125 10C0.3125 4.64844 4.64844 0.3125 10 0.3125C15.3516 0.3125 19.6875 4.64844 19.6875 10ZM14.6836 8.54688C14.6836 6.67578 13.1641 5.15625 11.293 5.15625H6.60938V14.8438H8.54688V11.9375H11.293C13.1641 11.9375 14.6836 10.418 14.6836 8.54688Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProductionIcon;
