import { JSONEditor } from '@json-editor/json-editor';
import _ from 'underscore';

export default class TrimStringPlugin extends JSONEditor.defaults.editors.string {
  getValue() {
    const value = super.getValue();

    return _.isUndefined(value) ? value : value.trim();
  }
}
