import React from 'react';
import { FormControl } from 'react-bootstrap';
import { HelpBlock } from 'design';

const SshPrivateKey = (props: {
  value: string;
  onChange: (value: string) => void;
  readOnly: boolean;
}) => {
  const fileInputId = React.useId();

  const prepareValue = (value: string) => {
    // if user paste content without new line at the end, add it
    props.onChange(value.trim().endsWith('-----') ? `${value.trim()}\n` : value.trim());
  };

  const onFileChange = (file?: File) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => prepareValue((e?.target?.result as string) ?? '');
    reader.readAsText(file);
  };

  return (
    <>
      <FormControl
        rows={2}
        componentClass="textarea"
        value={props.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          prepareValue(event.target.value);
        }}
        onDrop={(event: React.DragEvent<HTMLTextAreaElement>) => {
          event.preventDefault();
          onFileChange(event.dataTransfer.files?.[0]);
        }}
        disabled={props.readOnly}
      />
      <HelpBlock>
        You can drag and drop the key file here or{' '}
        <label htmlFor={fileInputId} className="btn btn-link btn-link-inline">
          select file
        </label>{' '}
        from your computer.
        <input
          type="file"
          id={fileInputId}
          className="!tw-hidden"
          onChange={(e) => onFileChange(e.target.files?.[0])}
        />
      </HelpBlock>
    </>
  );
};

export default SshPrivateKey;
