import { KEBOOLA_SCHEDULER, KEBOOLA_VARIABLES } from '@/constants/componentIds';

export const DASHBOARD_JOBS_LIMIT = 50;
export const DASHBOARD_EVENTS_LIMIT = 200;
export const BOX_ROWS_LIMITS = 40;

export const ACTIVITY_TYPES = {
  JOB: 'JOB',
  CONFIGURATION: 'CONFIGURATION',
  BRANCH: 'BRANCH',
};

export const DEV_BRANCH_EVENTS = {
  CREATED: 'storage.devBranchCreated',
  DELETED: 'storage.devBranchDeleted',
};

export const STORAGE_EVENTS = {
  CREATED: 'storage.componentConfigurationCreated',
  EDITED: 'storage.componentConfigurationChanged',
  DELETED: 'storage.componentConfigurationDeleted',
  PURGED: 'storage.componentConfigurationPurged',
  RESTORED: 'storage.componentConfigurationRestored',
  ROLLBACK: 'storage.componentConfigurationRolledBack',
  COPIED: 'storage.componentConfigurationCopied',
  ROW_CREATED: 'storage.componentConfigurationRowCreated',
  ROW_EDITED: 'storage.componentConfigurationRowChanged',
  ROW_DELETED: 'storage.componentConfigurationRowDeleted',
  ROW_ROLLBACK: 'storage.componentConfigurationRowRolledBack',
  ROW_COPIED: 'storage.componentConfigurationRowCopied',
};

export const ActionTypes = {
  DASHBOARD_JOBS_LOAD_SUCCESS: 'DASHBOARD_JOBS_LOAD_SUCCESS',
  DASHBOARD_ACTIVITIES_LOAD_SUCCESS: 'DASHBOARD_ACTIVITIES_LOAD_SUCCESS',
  DASHBOARD_LATEST_GIT_EVENT_LOAD_SUCCESS: 'DASHBOARD_LATEST_GIT_EVENT_LOAD_SUCCESS',
  DASHBOARD_TOGGLE_IS_PROJECT_DESCRIPTION_HIDDEN: 'DASHBOARD_TOGGLE_IS_PROJECT_DESCRIPTION_HIDDEN',
};

export const ACTIVITY_OMITTED_COMPONENTS = [KEBOOLA_VARIABLES, KEBOOLA_SCHEDULER];
