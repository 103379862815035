import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import StorageTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';

const ConflictItem = createReactClass({
  propTypes: {
    destination: PropTypes.string.isRequired,
    transformations: PropTypes.object.isRequired,
    conflicts: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
  },

  render() {
    return (
      <li>
        <StorageTableLinkEx tableId={this.props.destination} /> used in{' '}
        {this.renderTransformationsList()}
      </li>
    );
  },

  renderTransformationsList() {
    return this.props.conflicts
      .map((transformationId, index) => {
        const transformationName = this.props.transformations.get(transformationId).get('name');
        return (
          <span key={index}>
            {index > 0 && ', '}
            <ComponentConfigurationLink
              componentId="transformation"
              configId={this.props.bucketId}
              rowId={transformationId}
            >
              {transformationName}
            </ComponentConfigurationLink>
          </span>
        );
      })
      .toList();
  },
});

export default ConflictItem;
