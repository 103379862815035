const EXPERIMENTAL_TEXT =
  'Functional but experimental component, excluded from the standard SLA. Usually, developed for specific scenarios and not widely tested. May contain unhandled bugs or have various limitations.';

const READ_ONLY_TOOLTIP_MESSAGE = 'You cannot make changes in read only mode.';

const GOOGLE_CALENDAR_APPOINTMENT_IDS = {
  engineering: 'AcZssZ3cr_Ke_rxR1z2a9Hqz1Rz_-SHlfmikRA_CCEhaZZSSyolpfbLp8gU5clc1xGCvtzeYYUItuwI0',
  sales: 'AcZssZ07F-tVeTuTUTovU8d3rgfmTjF2xADdq2AqyC8tF236gG9mWUtphcFowt5ahwQ-P9f0WlhY6vRy',
} as const;

const SEARCH_TYPES = { KEY: 'KEY', VALUE: 'VALUE' };

const HIVE_AUTH_TYPES = {
  PASSWORD: 'password',
  KERBEROS: 'kerberos',
};

const SORT = {
  RECENTLY_ADDED: 'recently_added',
  RECENTLY_USED: 'recently_used',
  POPULAR: 'popular',
  A_Z: 'A_Z',
  Z_A: 'Z_A',
} as const;

const CODE_EDITOR_MODAL_MODES = {
  FULL_SCREEN: 'full-screen',
  HALF_SCREEN: 'half-screen',
} as const;

export {
  EXPERIMENTAL_TEXT,
  READ_ONLY_TOOLTIP_MESSAGE,
  GOOGLE_CALENDAR_APPOINTMENT_IDS,
  SEARCH_TYPES,
  HIVE_AUTH_TYPES,
  SORT,
  CODE_EDITOR_MODAL_MODES,
};
