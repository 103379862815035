import React from 'react';
import { Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import ConfirmButtons from './ConfirmButtons';

const ChangeDescriptionModal = (props: {
  show: boolean;
  value: string;
  isSaving: boolean;
  onChange: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onHide: () => void;
}) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Form onSubmit={props.onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Describe Changes You Made</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              componentClass="textarea"
              rows={4}
              autoFocus
              value={props.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
              disabled={props.isSaving}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons block saveButtonType="submit" isSaving={props.isSaving} />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeDescriptionModal;
