import Promise from 'bluebird';
import { List, Map } from 'immutable';
import _ from 'underscore';

import {
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_SISENSE,
} from '@/constants/componentIds';
import callDockerAction from '@/modules/components/DockerActionsApi';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import RowsActions from '@/modules/configurations/ConfigurationRowsActionCreators';
import {
  createConfigRows,
  updateConfigurationAfterMigration,
  updateStateAfterMigration,
} from './helpers';
import WritersApi from './WritersApi';

const tablesPath = ['storage', 'input', 'tables'];

export default function (configId, componentId) {
  function localState() {
    return InstalledComponentsStore.getLocalState(componentId, configId) || Map();
  }

  function updateLocalState(path, data) {
    const ls = localState();
    const newLocalState = ls.setIn([].concat(path), data);
    componentsActions.updateLocalState(componentId, configId, newLocalState, path);
  }

  function getconfigData() {
    return InstalledComponentsStore.getConfigData(componentId, configId) || Map();
  }

  const tablesMappings = getconfigData().getIn(tablesPath, List());
  return {
    getTableMapping: (tableId) => tablesMappings.find((t) => t.get('source') === tableId),
    editingFilterPath: ['editingFilter'],
    editingPkPath: ['editingPk'],
    updateV2State: (path, data) => updateLocalState(['v2'].concat(path), data),
    configTables: getconfigData().getIn(['parameters', 'tables'], List()),
    setTableInfo(tableId, newTableInfo) {
      updateLocalState(['v2', 'saving'], true);
      return WritersApi(componentId)
        .setTableV2(configId, tableId, newTableInfo)
        .then(() => updateLocalState(['v2', 'saving'], false));
    },
    setTableMapping(newMapping) {
      updateLocalState(['v2', 'saving'], true);
      return WritersApi(componentId)
        .mergeTableMappingV2(configId, newMapping)
        .then(() => updateLocalState(['v2', 'saving'], false));
    },
    testCredentials(credentials) {
      let params = getconfigData()
        .setIn(['parameters', 'tables'], List())
        .setIn(
          ['parameters', 'db'],
          credentials.filter((value) => _.isBoolean(value) || !!value),
        )
        .delete('storage');

      // SiSense and Synapse are new and more strict, so we have to remove tables
      if ([KEBOOLA_WR_SISENSE, KEBOOLA_WR_DB_SYNAPSE].includes(componentId)) {
        params = params.deleteIn(['parameters', 'tables']);
      }

      // Looker Writer is new and more strict, so we have to remove looker
      if (componentId === KEBOOLA_WR_LOOKER_V2) {
        params = params.deleteIn(['parameters', 'looker']);
      }

      return callDockerAction(componentId, 'testConnection', { configData: params.toJS() });
    },
    migrateConfig(configId) {
      const config = InstalledComponentsStore.getConfig(componentId, configId);

      return Promise.each(config.get('rows', List()), (row) => {
        return InstalledComponentsApi.deleteConfigurationRow(
          componentId,
          configId,
          row.get('id'),
          `Row ${row.get('name')} deleted during migration`,
        );
      })
        .then(() => {
          return Promise.each(createConfigRows(config), (row) => {
            return RowsActions.createSimple(
              componentId,
              configId,
              row,
              `Migrating table ${row.name} to configuration row`,
            );
          });
        })
        .then(() =>
          componentsActions.updateComponentState(
            componentId,
            configId,
            updateStateAfterMigration(config.get('state')),
          ),
        )
        .then(() => {
          return componentsActions.saveComponentConfigData(
            componentId,
            configId,
            updateConfigurationAfterMigration(config.get('configuration')),
            'Migrating configuration to rows.',
          );
        });
    },
  };
}
