import { DEFAULT_CHUNK_SIZE } from '@/modules/wr-google-bigquery-v2/helpers/constants';

const conform = (configData) => {
  if (!configData.hasIn(['parameters', 'tables', 0, 'chunkSize'])) {
    configData = configData.setIn(['parameters', 'tables', 0, 'chunkSize'], DEFAULT_CHUNK_SIZE);
  }

  return configData;
};

export default conform;
