import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { HelpBlock } from 'design';
import { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import { backends } from '@/modules/storage/constants';
import { prepareBackendLabel } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

class UpdateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tempData: Map(),
      isSaving: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() =>
          this.setState({
            tempData: Map({
              defaultBackend: this.props.project.get('defaultBackend'),
              dataRetentionTimeInDays: this.props.project.get('dataRetentionTimeInDays'),
            }),
          })
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Change Storage Settings</Modal.Title>
            <ModalIcon icon="pen" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Default backend</ControlLabel>
              <Select
                clearable={false}
                value={this.state.tempData.get('defaultBackend') || ''}
                onChange={(selected) =>
                  this.setState({
                    tempData: this.state.tempData.set('defaultBackend', selected),
                  })
                }
                options={Object.values(backends)
                  .filter((backend) => this.props.project.get(`has${capitalize(backend)}`, false))
                  .map((backend) => ({
                    value: backend,
                    label: prepareBackendLabel(backend),
                  }))}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Data retention in days</ControlLabel>
              <FormControl
                type="text"
                value={this.state.tempData.get('dataRetentionTimeInDays') || ''}
                onChange={(e) =>
                  this.setState({
                    tempData: this.state.tempData.set('dataRetentionTimeInDays', e.target.value),
                  })
                }
              />
              <HelpBlock>Max 90 days.</HelpBlock>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isSaving ? 'Saving changes...' : 'Save changes'}
              isSaving={this.state.isSaving}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  isDisabled() {
    return (
      this.state.tempData.get('defaultBackend') === this.props.project.get('defaultBackend') &&
      this.state.tempData.get('dataRetentionTimeInDays') ===
        this.props.project.get('dataRetentionTimeInDays')
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    this.props
      .onSubmit({
        defaultBackend: this.state.tempData.get('defaultBackend'),
        dataRetentionTimeInDays:
          parseInt(this.state.tempData.get('dataRetentionTimeInDays'), 10) || null,
      })
      .then(this.props.onHide)
      .finally(() => this.setState({ isSaving: false }));
  }
}

UpdateProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateProjectModal;
