import React from 'react';
import { Modal, Well } from 'react-bootstrap';
import classNames from 'classnames';
import { Alert, CollapsiblePanel, Link } from 'design';
import { List, Map } from 'immutable';

import { getComponent, shoudUseMonospaceFont } from '@/modules/sapi-events/helpers';
import ComponentName from '@/react/common/ComponentName';
import CreatedDate from '@/react/common/CreatedDate';
import FileLink from '@/react/common/FileLink';
import ModalIcon from '@/react/common/ModalIcon';
import Tree from '@/react/common/Tree';
import UrlsToLinks from '@/react/common/UrlsToLinks';

const EventDetailModal = (props: { event: Map<string, any>; onHide: () => void }) => {
  const renderTitle = () => {
    if (!props.event.get('component')) {
      return 'Event detail';
    }

    return (
      <>
        <ComponentName component={getComponent(props.event)} capitalize /> event
      </>
    );
  };

  const renderDeprecatedAuthorization = () => {
    if (props.event.getIn(['context', 'authorization']) !== 'deprecated') {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        <p>
          Used authorization method is deprecated and will be disabled soon.
          <br />
          Please move your tokens from query string parameters to &quot;X-StorageApi-Token&quot;
          http header. See more in{' '}
          <Link href="http://docs.keboola.apiary.io/">API documentation</Link>
        </p>
      </Alert>
    );
  };

  const renderMessage = () => {
    return (
      <div>
        <p
          className={classNames({
            'font-mono': shoudUseMonospaceFont(props.event.get('message')),
          })}
        >
          <UrlsToLinks text={props.event.get('message')} />
        </p>
        {props.event.get('description') && (
          <p>
            <UrlsToLinks text={props.event.get('description')} />
          </p>
        )}
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <Well>
        <p className="flex-container">
          <strong>ID</strong>
          <span className="text-muted">{props.event.get('id')}</span>
        </p>
        <p className="flex-container">
          <strong>Event</strong>
          <span className="text-muted">{props.event.get('event')}</span>
        </p>
        <p className="flex-container">
          <strong>Component</strong>
          <span className="text-muted">{props.event.get('component')}</span>
        </p>
        <p className="flex-container">
          <strong>Run ID</strong>
          <span className="text-muted">{props.event.get('runId') || '-'}</span>
        </p>
        <p className="flex-container">
          <strong>Created</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={props.event.get('created')}
          />
        </p>
        <p className="flex-container">
          <strong>Created by</strong>
          <span className="text-muted">{props.event.getIn(['token', 'name'])}</span>
        </p>
      </Well>
    );
  };

  const renderAttachments = () => {
    const attachments = props.event.get('attachments', List());

    if (attachments.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel header="Attachments" className="tw-mb-3">
        <ul>
          {attachments
            .map((attachment: Map<string, any>) => (
              <li key={attachment.get('id')}>
                <FileLink file={attachment} />
              </li>
            ))
            .toArray()}
        </ul>
      </CollapsiblePanel>
    );
  };

  const renderDetail = (header: string, property: string) => {
    const data = props.event.get(property, Map());

    if (data.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel header={header} className="tw-mb-3">
        <Tree data={data} />
      </CollapsiblePanel>
    );
  };

  const renderBody = () => {
    if (props.event.isEmpty()) {
      return null;
    }

    return (
      <>
        {renderDeprecatedAuthorization()}
        {renderMessage()}
        {renderInfo()}
        {renderAttachments()}
        {renderDetail('Results', 'results')}
        {renderDetail('Parameters', 'params')}
        {renderDetail('Performance', 'performance')}
        {renderDetail('Context', 'context')}
      </>
    );
  };

  return (
    <Modal bsSize="large" show={!props.event.isEmpty()} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{renderTitle()}</Modal.Title>
        <ModalIcon icon="magnifying-glass" color="blue" />
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
    </Modal>
  );
};

export default EventDetailModal;
