import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';
import { capitalize } from 'underscore.string';

import Loader from '@/react/common/Loader';
import { RunActionLinkStatus } from './RunActionLinkStatus';

class RunActionLink extends React.Component {
  render() {
    const isDisabled =
      this.props.status === RunActionLinkStatus.RUNNING ||
      this.props.status === RunActionLinkStatus.DISABLED;

    return (
      <Tooltip
        type="auto"
        placement="top"
        tooltip={this.props.tooltip}
        forceHide={!isDisabled || !this.props.tooltip}
      >
        <Button
          block
          bsStyle="success"
          className={classnames({ disabled: isDisabled })}
          onClick={(e) => !isDisabled && this.props.onClick(e)}
        >
          {this.props.status === RunActionLinkStatus.RUNNING ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
          )}
          {capitalize(this.props.label?.toLowerCase())}
        </Button>
      </Tooltip>
    );
  }
}

RunActionLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  status: PropTypes.oneOf(Object.values(RunActionLinkStatus)),
};

RunActionLink.defaultProps = {
  label: 'Run',
  tooltip: 'Run',
  status: RunActionLinkStatus.READY,
};

export default RunActionLink;
