import { fromJS, List, Map } from 'immutable';

import { FEATURE_ALLOW_AI } from '@/constants/features';
import dispatcher from '@/Dispatcher';
import ApplicationStore from '@/stores/ApplicationStore';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import constants from './Constants';

let store = initStore(
  'StackFeaturesStore',
  Map({
    features: List(),
  }),
);

const StackFeaturesStore = StoreUtils.createStore({
  getAll() {
    return store.get('features');
  },

  hasStackFeature(featureName) {
    return store.get('features').includes(featureName);
  },

  hasAllowedAi() {
    return ApplicationStore.hasNewQueue() && store.get('features').includes(FEATURE_ALLOW_AI);
  },
});

dispatcher.register(function ({ action }) {
  switch (action.type) {
    case constants.ActionTypes.STACK_FEATURES_LOAD_SUCCESS:
      store = store.set('features', fromJS(action.features));
      return StackFeaturesStore.emitChange();
    default:
  }
});

export default StackFeaturesStore;
