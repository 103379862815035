import React from 'react';
import CodeMirrorMerge from 'react-codemirror-merge';
import { autocompletion } from '@codemirror/autocomplete';
import { json } from '@codemirror/lang-json';
import { python } from '@codemirror/lang-python';
import { PLSQL, sql } from '@codemirror/lang-sql';
import { StreamLanguage } from '@codemirror/language';
import { julia } from '@codemirror/legacy-modes/mode/julia';
import { r } from '@codemirror/legacy-modes/mode/r';
import { highlightSelectionMatches } from '@codemirror/search';
import { EditorView, keymap } from '@codemirror/view';
import { URLS } from '@keboola/constants';
import { githubLightInit } from '@uiw/codemirror-theme-github';
import CodeMirror, { type Extension } from '@uiw/react-codemirror';
import { EditorState, placeholder as cmPlaceholder } from '@uiw/react-codemirror';

import colors from '../colors';
import { cn } from '../utils/classNames';

import { Clipboard } from './Clipboard';
import { HelpBlock } from './HelpBlock';
import { KeyCode } from './KeyCode';
import Link from './Link';

const BASIS_SETUP = { drawSelection: false };

type Props = {
  mode: (typeof LANGUAGE_MODES)[number];
  onChange: (value: string) => void;
  value: string;
  previousValue?: string;
  placeholder?: string;
  showDiff?: boolean;
  readOnly?: boolean;
  autocompletions?: string[];
  extraKeys?: Record<string, () => void>;
  withAutocomplete?: boolean;
  withToggleCommentHint?: boolean;
  help?: React.ReactNode;
  renderAdditionalButtons?: (value?: string) => React.ReactNode;
  className?: string;
};

const CodeEditor = ({
  mode,
  onChange,
  value,
  previousValue,
  placeholder,
  showDiff,
  readOnly,
  autocompletions,
  extraKeys,
  withAutocomplete,
  withToggleCommentHint,
  help,
  renderAdditionalButtons,
  className,
}: Props) => {
  const commonExtensions: Extension[] = React.useMemo(() => {
    return [
      EditorView.theme(getBaseThemeStyles(showDiff)),
      EditorView.lineWrapping,
      EditorState.languageData.of(() => [{ autocomplete: autocompletions }]),
      keymap.of(
        Object.entries(extraKeys ?? {}).map(([key, run]) => ({
          key,
          run: () => {
            if (typeof run === 'string') return run;

            run();
            return true;
          },
        })),
      ),
      getLanguageMode(mode),
      autocompletion({ activateOnTyping: false }),
    ];
  }, [showDiff, autocompletions, extraKeys, mode]);

  const originalExtensions = React.useMemo(() => {
    return [
      ...commonExtensions,
      EditorView.editable.of(false),
      EditorState.readOnly.of(true),
      highlightSelectionMatches({ maxMatches: 0 }),
    ];
  }, [commonExtensions]);

  const modifiedExtensions = React.useMemo(() => {
    return [
      ...commonExtensions,
      cmPlaceholder(placeholder ?? ''),
      EditorView.editable.of(!readOnly),
      EditorState.readOnly.of(!!readOnly),
    ];
  }, [commonExtensions, placeholder, readOnly]);

  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-min-h-0 tw-flex-col [&_.cm-mergeViewEditor]:tw-overflow-visible [&_.cm-mergeViewEditors]:tw-h-full [&_.cm-mergeView]:tw-h-full [&_>div]:tw-h-full [&_>div]:tw-overflow-auto [&_>div]:tw-bg-neutral-50',
        className,
      )}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
        }
      }}
    >
      <div className="tw-pointer-events-none tw-absolute tw-z-10 tw-flex !tw-h-auto tw-w-full -tw-translate-y-1/2 tw-overflow-visible !tw-bg-transparent">
        {showDiff && (
          <div className="tw-flex-1">
            <ButtonToolbar value={previousValue}>
              {renderAdditionalButtons?.(previousValue)}
            </ButtonToolbar>
          </div>
        )}
        <div className="tw-flex-1">
          <ButtonToolbar value={value}>{renderAdditionalButtons?.(value)}</ButtonToolbar>
        </div>
      </div>
      {showDiff ? (
        <CodeMirrorMerge theme={customTheme} highlightChanges={false}>
          <CodeMirrorMerge.Original
            value={previousValue ?? ''}
            extensions={originalExtensions}
            basicSetup={BASIS_SETUP}
          />
          <CodeMirrorMerge.Modified
            value={value}
            extensions={modifiedExtensions}
            onChange={onChange}
            basicSetup={BASIS_SETUP}
          />
        </CodeMirrorMerge>
      ) : (
        <CodeMirror
          theme={customTheme}
          value={value}
          onChange={onChange}
          extensions={modifiedExtensions}
        />
      )}
      {(withAutocomplete || withToggleCommentHint || help) && !readOnly && (
        <HelpBlock className="tw-mt-2">
          {help}
          {withAutocomplete && (
            <>
              {!!help ? ' ' : ''}
              Use <KeyCode>Ctrl+Space</KeyCode> to trigger{' '}
              <Link href={`${URLS.USER_DOCUMENTATION}/transformations/#autocompletion`}>
                autocomplete
              </Link>
              .
            </>
          )}
          {withToggleCommentHint && (
            <>
              {!!help || withAutocomplete ? ' ' : ''}
              Use <KeyCode>Ctrl+/</KeyCode> or <KeyCode>Command+/</KeyCode> to toggle comments.
            </>
          )}
        </HelpBlock>
      )}
    </div>
  );
};

const getBaseThemeStyles = (showDiff?: boolean) => ({
  '*': { lineHeight: '20px' },
  '*::selection': {
    backgroundColor: colors.secondary[500],
    color: `${colors.white} !important`,
  },
  '&.cm-editor:has(.cm-content[contenteditable="false"])': { backgroundColor: colors.neutral[150] },
  '&.cm-editor:has(.cm-content[contenteditable="false"]) .cm-gutters': {
    backgroundColor: colors.neutral[200],
  },
  '&.cm-editor .cm-scroller': { overflowAnchor: 'none' },
  '&.cm-editor, &.cm-editor .cm-scroller': {
    minHeight: '100%',
    flexGrow: 1,
    alignItems: 'stretch !important',
    outline: 'none',
  },
  '.cm-gutter.cm-foldGutter': { display: 'none !important' },
  '.cm-gutter.cm-lineNumbers': { paddingLeft: '20px', paddingRight: '6px' },
  '.cm-gutters': { height: 'auto' },
  ...(showDiff && {
    '&.cm-merge-a .cm-changedLine': { backgroundColor: colors.error[100] },
    '&.cm-merge-b .cm-changedLine': { backgroundColor: colors.primary[100] },
    '&.cm-merge-a .cm-changedLineGutter': { backgroundColor: colors.error[200] },
    '&.cm-merge-b .cm-changedLineGutter': { backgroundColor: colors.primary[200] },
    '.cm-changeGutter': { position: 'absolute', zIndex: '-1', width: '100%', paddingLeft: 0 },
  }),
});

const customTheme = githubLightInit({
  settings: {
    fontFamily: `'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New'`,
    background: colors.neutral[50],
    gutterBackground: colors.neutral[100],
    gutterBorder: 'transparent',
    lineHighlight: 'transparent',
    selection: colors.secondary[500],
  },
});

const LANGUAGE_MODES = [
  'application/json',
  'text/x-python',
  'text/x-sql',
  'text/x-sfsql',
  'text/x-plsql',
  'text/x-rsrc',
  'text/x-julia',
] as const;

const getLanguageMode = (mode: (typeof LANGUAGE_MODES)[number]) => {
  switch (mode) {
    case 'application/json':
      return json();
    case 'text/x-python':
      return python();
    case 'text/x-sql':
    case 'text/x-sfsql':
      return sql();
    case 'text/x-plsql':
      return sql({ dialect: PLSQL });
    case 'text/x-rsrc':
      return StreamLanguage.define(r);
    case 'text/x-julia':
      return StreamLanguage.define(julia);
  }
};

const ButtonToolbar = ({ value, children }: { value?: string; children?: React.ReactNode }) => {
  if (!value && !children) return null;

  return (
    <div className="tw-pointer-events-auto tw-ml-auto tw-mr-2 tw-flex tw-w-fit tw-gap-1 tw-overflow-visible tw-rounded-lg tw-border tw-border-solid tw-border-neutral-150 !tw-bg-white tw-p-[3px] tw-shadow">
      {!!value && (
        <Clipboard
          text={value}
          btnClassName="!tw-px-[9px] !tw-py-[7px] btn-link text-muted hover|focus:tw-bg-neutral-100"
          inline={false}
          tooltipPlacement="top"
        />
      )}
      {children}
    </div>
  );
};

export default CodeEditor;
