import { List, Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import SharedCodesActions from '@/modules/shared-codes/Actions';
import { cleanupSharedCodes, getNewBlock, getNewCode, getSharedCode } from './helpers';

const fillEmptyBlocks = (blocks) => (!blocks ? List([getNewBlock('Block 1')]) : blocks);
const fillEmptyCodes = (codes) => (!codes ? List([getNewCode()]) : codes);
const fillEmptyCode = (code) => (!code ? getNewCode() : code);
const addNewBlock = (blocks) =>
  !blocks
    ? List([getNewBlock('Block 1'), getNewBlock('Block 2')])
    : blocks.push(getNewBlock(`Block ${blocks.count() + 1}`));

const updateConfiguration = (componentId, configId, configuration, changeDescription) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    componentId,
    configId,
    { configuration: JSON.stringify(configuration) },
    changeDescription,
  );
};

const addNewScript = (componentId, configId, configData, blockIndex, codeIndex, name, value) => {
  const configuration = configData
    .updateIn(['parameters', 'blocks'], fillEmptyBlocks)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes'], fillEmptyCodes)
    .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'], name)
    .setIn(
      ['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'script'],
      List.isList(value) ? value : [value],
    )
    .toJS();

  return updateConfiguration(componentId, configId, configuration, 'Add new script');
};

const addNewScriptAsSharedCode = (
  componentId,
  configId,
  configData,
  blockIndex,
  codeIndex,
  configurationId,
  configurationRowId,
) => {
  const configuration = configData
    .set('shared_code_id', configurationId)
    .update('shared_code_row_ids', List(), (rows) => rows.push(configurationRowId).toSet().toList())
    .updateIn(['parameters', 'blocks'], fillEmptyBlocks)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes'], fillEmptyCodes)
    .setIn(
      ['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'],
      `Shared Code (${configurationId}-${configurationRowId})`,
    )
    .setIn(
      ['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'script'],
      [`{{${configurationRowId}}}`],
    )
    .toJS();

  return updateConfiguration(componentId, configId, configuration, 'Add new shared script');
};

const convertCodeBlockToSharedCode = (
  componentId,
  configId,
  configData,
  blockIndex,
  codeIndex,
  name,
  code,
  variables,
) =>
  SharedCodesActions.createSharedCode(componentId, name, code, variables).then(
    ({ sharedCodesConfigurationId, sharedCodesConfigurationRowId }) => {
      const configuration = configData
        .set('shared_code_id', sharedCodesConfigurationId)
        .update('shared_code_row_ids', List(), (rows) => rows.push(sharedCodesConfigurationRowId))
        .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'], name)
        .setIn(
          ['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'script'],
          [`{{${sharedCodesConfigurationRowId}}}`],
        )
        .toJS();

      return updateConfiguration(componentId, configId, configuration, `Share ${name} code`);
    },
  );

const convertSharedCodeToCodeBlock = (
  componentId,
  configId,
  configData,
  sharedCodes,
  blockIndex,
  codeIndex,
) => {
  const sharedCode = getSharedCode(
    configData.getIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex], Map()),
    sharedCodes,
  );
  const sharedCodeContent = sharedCode.getIn(['configuration', 'code_content']);

  const configuration = configData
    .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'], sharedCode.get('name'))
    .setIn(
      ['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'script'],
      List.isList(sharedCodeContent) ? sharedCodeContent : [sharedCodeContent],
    )
    .update((configData) => cleanupSharedCodes(configData, sharedCodes))
    .toJS();

  return updateConfiguration(
    componentId,
    configId,
    configuration,
    `Convert shared ${sharedCode.get('name')} code to non-shared`,
  );
};

const updateCode = (
  componentId,
  configId,
  configData,
  blockIndex,
  codeIndex,
  name,
  scripts,
  changeDescription,
) => {
  const configuration = configData
    .updateIn(['parameters', 'blocks'], fillEmptyBlocks)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes'], fillEmptyCodes)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex], fillEmptyCode)
    .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'], name)
    .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'script'], scripts);

  return updateConfiguration(
    componentId,
    configId,
    configuration.toJS(),
    changeDescription || `Change ${name}`,
  );
};

const updateBlockName = (componentId, configId, configData, blockIndex, newName) => {
  const configuration = configData
    .updateIn(['parameters', 'blocks'], fillEmptyBlocks)
    .setIn(['parameters', 'blocks', blockIndex, 'name'], newName)
    .toJS();

  return updateConfiguration(componentId, configId, configuration, 'Change block name');
};

const updateCodeName = (componentId, configId, configData, blockIndex, codeIndex, newName) => {
  const configuration = configData
    .updateIn(['parameters', 'blocks'], fillEmptyBlocks)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes'], fillEmptyCodes)
    .updateIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex], fillEmptyCode)
    .setIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name'], newName)
    .toJS();

  return updateConfiguration(componentId, configId, configuration, 'Change code name');
};

const deleteCode = (componentId, configId, configData, blockIndex, codeIndex, sharedCodes) => {
  const name = configData.getIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex, 'name']);
  const configuration = configData
    .deleteIn(['parameters', 'blocks', blockIndex, 'codes', codeIndex])
    .update((configData) => cleanupSharedCodes(configData, sharedCodes));

  return updateConfiguration(componentId, configId, configuration.toJS(), `Delete Code ${name}`);
};

const copyCode = (componentId, configId, configData, blockIndex, codeIndex, sharedCodes) => {
  let codes = configData.getIn(['parameters', 'blocks', blockIndex, 'codes']);
  const code = codes.get(codeIndex);
  const name = code.get('name');
  const configuration = configData
    .setIn(['parameters', 'blocks', blockIndex, 'codes'], codes.splice(codeIndex, 0, code))
    .update((configData) => cleanupSharedCodes(configData, sharedCodes));

  return updateConfiguration(componentId, configId, configuration.toJS(), `Copy Code ${name}`);
};

const createBlock = (componentId, configId, configData) => {
  const configuration = configData.updateIn(['parameters', 'blocks'], addNewBlock);

  return updateConfiguration(componentId, configId, configuration, 'Add new block');
};

const deleteBlock = (componentId, configId, configData, blockIndex, sharedCodes) => {
  const name = configData.getIn(['parameters', 'blocks', blockIndex, 'name']);
  const configuration = configData
    .deleteIn(['parameters', 'blocks', blockIndex])
    .update((configData) => cleanupSharedCodes(configData, sharedCodes));

  return updateConfiguration(componentId, configId, configuration.toJS(), `Delete block ${name}`);
};

const reorder = (componentId, configId, configData, newBlocks, changeDescription) => {
  const configuration = configData.setIn(['parameters', 'blocks'], newBlocks).toJS();

  return updateConfiguration(componentId, configId, configuration, changeDescription);
};

export {
  updateConfiguration,
  addNewScript,
  addNewScriptAsSharedCode,
  updateCode,
  convertCodeBlockToSharedCode,
  convertSharedCodeToCodeBlock,
  reorder,
  updateBlockName,
  updateCodeName,
  createBlock,
  deleteBlock,
  deleteCode,
  copyCode,
};
