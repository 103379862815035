export type FilterType = (typeof VARIABLE_TYPE)[keyof typeof VARIABLE_TYPE];

const ActionTypes = {
  LOAD_VARIABLES: 'LOAD_VARIABLES',
  LOAD_VARIABLES_SUCCESS: 'LOAD_VARIABLES_SUCCESS',
  LOAD_VARIABLES_ERROR: 'LOAD_VARIABLES_ERROR',
};

const VARIABLE_TYPE = {
  ALL: 'all',
  WIDE: 'wide',
  SCOPED: 'scoped',
  PRODUCTION: 'production',
} as const;

export { ActionTypes, VARIABLE_TYPE };
