import 'codemirror/addon/mode/simple';

import CodeMirror from 'codemirror';

const commands = [
  'run-operation',
  'source freshness',
  'docs generate',
  'run',
  'test',
  'seed',
  'snapshot',
  'list',
  'ls',
  'compile',
  'build',
  'deps',
];
const args = ['--select', '--exclude', '--selector', '--defer', '--resource-type', '-s'];
const methods = [
  'path:',
  'source:',
  'tag:',
  'package:',
  'config:',
  'test_type:',
  'test_name:',
  'state:',
  'exposure:',
  'metric:',
  'result:',
  'source_status:',
  'column_name:',
];
const flags = [
  '--fail-fast',
  '--warn-error',
  '--favor-state',
  '--vars',
  '--models',
  '--threads',
  '--profiles-dir',
  '--profile',
  '--full-refresh',
];

CodeMirror.defineSimpleMode('dbt', {
  start: [
    { regex: /"(?:[^\\]|\\.)*?(?:"|$)/, token: 'string' },
    { regex: /dbt/, token: 'keyword', sol: true },
    { regex: new RegExp(`^\\s+(?:${commands.join('|')})\\b`, 'i'), token: 'keyword' },
    { regex: new RegExp(`^\\s+(?:${args.join('|')})\\b`, 'i'), token: 'variable' },
    { regex: new RegExp(`^\\s+(?:${methods.join('|')})`, 'i'), token: 'variable-2' },
    { regex: new RegExp(`^\\s+(?:${flags.join('|')})`, 'i'), token: 'variable-3' },
    { regex: /(?:@|\*|,|\+\d+|\d+\+|\+)/, token: 'operator' },
    { regex: /'.*'/, token: 'string' },
    { regex: /true|false/, token: 'atom' },
  ],
});

const hint = (cm) => {
  const cur = cm.getCursor();
  let token = cm.getTokenAt(cur);
  const keywords = ['dbt', ...commands, ...args, ...methods, ...flags];

  let search = token.string;
  let start = token.start;
  const end = token.end;

  while (start > 0 && !token.type && token.string !== ' ') {
    token = cm.getTokenAt(CodeMirror.Pos(cur.line, start));
    search = token.string + search;
    start = token.start;
  }

  if (search.startsWith(' ')) {
    start = start + 1;
    search = search.slice(1);
  }

  return {
    list: keywords.filter((keyword) => keyword.startsWith(search)),
    from: CodeMirror.Pos(cur.line, start),
    to: CodeMirror.Pos(cur.line, end),
  };
};

CodeMirror.registerHelper('hint', 'dbt', hint);
