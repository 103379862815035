import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import useStores from '@/react/hooks/useStores';
import NotificationsStore from '@/stores/NotificationsStore';
import Notification from './Notification';

const FloatingNotifications = () => {
  const { notifications } = useStores(
    () => {
      return { notifications: NotificationsStore.getNotifications() };
    },
    [],
    [NotificationsStore],
    { ignoreRouteChanges: true },
  );

  const onCancel = React.useCallback((id) => {
    ApplicationActionCreators.deleteNotification(id);
  }, []);

  const handlePause = React.useCallback(() => {
    ApplicationActionCreators.pauseNotificationAging();
  }, []);

  const resetPause = React.useCallback(() => {
    ApplicationActionCreators.resetNotificationAging();
  }, []);

  return (
    <TransitionGroup className="floating-notifications tw-fixed tw-bottom-4 tw-left-1/2 tw-z-[9999] tw-min-w-[320px] tw-max-w-[600px]">
      {notifications.map((notification) => (
        <CSSTransition key={notification.get('id')} timeout={300} classNames="notify">
          <Notification
            notification={notification}
            onCancel={onCancel}
            onMouseEnter={handlePause}
            onMouseLeave={resetPause}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default FloatingNotifications;
