import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import {
  getTableColumnMetadata,
  hasTableColumnMetadataDatatypes,
} from '@/modules/components/utils/tableMetadataHelper';
import { canBeTableCloned, isTableBiggerThat100MB } from '@/modules/legacy-transformation/helpers';
import { getMetadataDataTypes as prepareMetadata } from '@/modules/wr-db/templates/columnsMetadata';

const preferableTypes = {
  TIMESTAMP: 'TIMESTAMP_NTZ',
};

const getMappingFromDatatypes = (datatypes) => {
  return fromJS(datatypes)
    .map((type) => {
      if (_.isString(type)) {
        return Map({ name: type.toUpperCase(), size: false });
      }

      const size = type.first().get('maxSize');

      return Map({
        name: type.keySeq().first().toUpperCase(),
        size: true,
        maxLength: size.includes(',') ? size : Number(size),
      });
    })
    .toMap()
    .mapKeys((key, mapping) => mapping.get('name'));
};

const getMetadataDataTypes = (columnMetadata, tablePrimaryKey) => {
  const columnData = prepareMetadata('keboola.wr-db-snowflake', columnMetadata);

  return columnMetadata.map((metadata, colname) => {
    if (!columnData.get(colname)) {
      return null;
    }

    let type = columnData.getIn([colname, 'type']).toUpperCase();

    if (preferableTypes[type]) {
      type = preferableTypes[type];
    }

    return fromJS({
      type,
      column: colname,
      length: columnData.getIn([colname, 'size']),
      convertEmptyValuesToNull: tablePrimaryKey.includes(colname)
        ? false
        : columnData.getIn([colname, 'nullable']),
    });
  });
};

const buildDatatypes = (columns, datatypes = Map(), selectedTable = Map()) => {
  const primaryKeys = selectedTable.get('primaryKey', List());

  return columns.reduce((memo, column) => {
    return memo.set(
      column,
      datatypes.get(
        column,
        fromJS({
          column: column,
          type: 'VARCHAR',
          length: primaryKeys.includes(column) ? '255' : null,
          convertEmptyValuesToNull: false,
        }),
      ),
    );
  }, Map());
};

const prepareSourceMapping = (selected, currentMapping, tables) => {
  const table = tables.get(selected);

  if (table) {
    return currentMapping.withMutations((mapping) => {
      mapping.set('source', table.get('id'));
      mapping.set('destination', table.get('displayName'));
      mapping.set('datatypes', getInitialDatatypes(table));
      mapping.delete('tables');

      if (canBeTableCloned(table) && isTableBiggerThat100MB(table)) {
        mapping.set('loadType', 'clone');
      } else {
        mapping.delete('loadType');
      }

      mapping.delete('changedSince');
      mapping.set('whereColumn', '');
      mapping.set('whereValues', List());
      mapping.set('whereOperator', 'eq');
      mapping.set('columns', List());
    });
  }

  return currentMapping.withMutations((mapping) => {
    mapping.set('source', selected);
    mapping.set(
      'tables',
      tables
        .filter((table) => table.getIn(['bucket', 'id']) === selected)
        .map((table) =>
          Map({
            source: table.get('id'),
            destination: table.get('displayName'),
            datatypes: getInitialDatatypes(table),
          }),
        ),
    );
    mapping.delete('loadType');
    mapping.delete('changedSince');
    mapping.set('whereColumn', '');
    mapping.set('whereValues', List());
    mapping.set('whereOperator', 'eq');
    mapping.set('columns', List());
  });
};

const getInitialDatatypes = (selectedTable) => {
  if (!selectedTable) {
    return Map();
  }

  if (hasTableColumnMetadataDatatypes(selectedTable)) {
    const datatypes = getMetadataDataTypes(
      getTableColumnMetadata(selectedTable),
      selectedTable.get('primaryKey', List()),
    );
    return buildDatatypes(selectedTable.get('columns', List()), datatypes, selectedTable);
  }

  return buildDatatypes(selectedTable.get('columns', List()));
};

export {
  getMetadataDataTypes,
  getMappingFromDatatypes,
  prepareSourceMapping,
  getInitialDatatypes,
  buildDatatypes,
};
