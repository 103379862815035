import React from 'react';
import classnames from 'classnames';
import type { Map } from 'immutable';

import { getComponent, shoudUseMonospaceFont } from '@/modules/sapi-events/helpers';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import TimeAndUser from '@/react/common/TimeAndUser';
import UrlsToLinks from '@/react/common/UrlsToLinks';

type EventType = 'info' | 'error' | 'warn' | 'success';

const classmap = {
  info: '',
  error: 'bg-danger',
  warn: 'bg-warning',
  success: 'bg-success',
};

const EventsTableRow = (props: {
  event: Map<string, any>;
  admins: Map<string, any>;
  onClick: (event: Map<string, any>) => void;
  searchQuery: string;
}) => {
  const component = getComponent(props.event);
  const status = classmap[props.event.get('type') as EventType];

  const filteredPartial = props.searchQuery.replace(/(?<!\\)\*/g, '').replace(/\\(?=\*)/, '');

  return (
    <div className="tr clickable" onClick={() => props.onClick(props.event)}>
      <div className={classnames('td', 'text-nowrap', status)}>
        <ComponentIcon component={component} size="28" className="icon-addon-right" />
        <ComponentName component={component} capitalize />
      </div>
      <div
        className={classnames('td overflow-break-anywhere', status, {
          'font-mono': shoudUseMonospaceFont(props.event.get('message')),
        })}
      >
        <UrlsToLinks text={props.event.get('message')} searchQuery={filteredPartial} />
      </div>
      <div className={classnames('td text-nowrap text-right text-muted', status)}>
        <TimeAndUser
          absolute
          withSeconds
          admin={props.admins.get(props.event.getIn(['token', 'name']))}
          time={props.event.get('created')}
          fallbackName={props.event.getIn(['token', 'name'])}
        />
      </div>
    </div>
  );
};

const MemoizedEventTableRow = React.memo(EventsTableRow);

export default MemoizedEventTableRow;
