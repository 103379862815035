import React from 'react';

import type { WorkspaceData } from './api';

// used only in the Vercel preview as placeholder for the GoodData dashboard (via Webpack)
const DashboardPlaceholder = (props: { credentials: WorkspaceData }) => {
  return (
    <div className="box">
      <div className="box-content">
        <h3 className="tw-mt-0">Loaded credentials</h3>
        <pre>{JSON.stringify(props.credentials, null, 2)}</pre>
      </div>
    </div>
  );
};

export default DashboardPlaceholder;
