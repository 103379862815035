import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Image,
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import classnames from 'classnames';
import { Alert, Link } from 'design';
import { fromJS, List, Map } from 'immutable';

import { BETA } from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { canManageReadOnlyStorageForProvisionedCredentials } from '@/modules/admin/privileges';
import {
  findCreatedFromMetadata,
  getDatabricksParametersFromCluster,
  getPredefinedCluster,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import DatabricksParametersFormContent from '@/modules/components/react/components/DatabricksParametersFormContent';
import ConfigurationsActionCreators from '@/modules/configurations/ConfigurationsActionCreators';
import { prepareRuntimesForSandboxes } from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import SandboxesActions from '@/modules/sandboxes/Actions';
import {
  AUTO_SLEEP_DEFAULT,
  CONTAINER_BASED,
  FORCE_READONLY_STORAGE_ACCESS,
  FORM_STEPS,
  NEED_CLIENT_TO_CONNECT,
  ONLY_READONLY_STORAGE,
  SANDBOX_SIZES,
  SANDBOX_TYPE,
  SUPPORT_SIZES,
} from '@/modules/sandboxes/Constants';
import {
  prepareCredentialsData,
  prepareSandboxTypeLabel,
  prepareSandboxUrl,
  resolveComponentIdFromSandboxType,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { BadgeBeta } from '@/react/common/Badges';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import CredentialsBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import { getComponentIconUrl } from '@/utils/componentIconFinder';
import jobPoller from '@/utils/jobPoller';
import matchByWords from '@/utils/matchByWords';
import ClientInfo from './ClientInfo';
import LoadOptions from './LoadOptions';
import ReadOnlyAccessBigQuery from './ReadOnlyAccessBigQuery';
import ReadOnlyStorageCheckbox from './ReadOnlyStorageCheckbox';
import WorkspaceExpirationControl from './WorkspaceExpirationControl';
import WorkspaceSizeControl from './WorkspaceSizeControl';

const INITIAL_STATE = {
  step: FORM_STEPS.SANDBOX_LIST,
  error: null,
  sandbox: null,
  isLoaded: false,
  isLoading: false,
  tempData: Map(),
  isLoadingWorkspaces: false,
  isLoadingWorkspace: false,
  createdWorkspace: null,
  isLoadingDatabricksClusters: false,
};

class AddSandboxModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.cancelablePromise = null;

    this.checkSandbox = this.checkSandbox.bind(this);
    this.loadDatabricksClusters = this.loadDatabricksClusters.bind(this);
    this.handleSubmitCreateWorkspace = this.handleSubmitCreateWorkspace.bind(this);
    this.handleSubmitUpdateWorkspace = this.handleSubmitUpdateWorkspace.bind(this);
  }

  componentDidUpdate() {
    const workspace = this.findWorkspaceDetail(this.state.tempData.get('workspace'));

    if (workspace && !workspace.get('password') && !this.state.isLoadingWorkspace) {
      this.setState({ isLoadingWorkspace: true });
      SandboxesActions.loadSandboxForce(workspace.get('id')).then(() => {
        this.setState({ isLoadingWorkspace: false });
      });
    }
  }

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          onEnter={this.checkSandbox}
          onExit={() => this.cancelablePromise?.cancel()}
        >
          {this.renderBody()}
        </Modal>
      </span>
    );
  }

  renderBody() {
    if (this.state.step === FORM_STEPS.SANDBOX_CREATE) {
      return this.renderSandboxCreateModal();
    }

    if (this.state.step === FORM_STEPS.SANDBOX_UPDATE) {
      return this.renderSandboxUpdateModal();
    }

    return this.renderSandboxListModal();
  }

  renderSandboxListModal() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.forceStep === FORM_STEPS.SANDBOX_UPDATE
              ? 'Select Workspace Backend'
              : 'New Workspace'}
          </Modal.Title>
          <ModalIcon icon="box" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.prepareWorkspaces()
            .sortBy((sandbox) => sandbox.get('name'))
            .sortBy((item) => item.get('beta'))
            .sortBy((sandbox) => {
              return (
                this.props.hasTableInputMapping &&
                ONLY_READONLY_STORAGE.includes(sandbox.get('type'))
              );
            })
            .map((sandbox, index) => {
              return (
                <ModalActionButton
                  key={index}
                  icon={
                    <Image
                      width={48}
                      src={sandbox.get('imageSrc')}
                      alt={prepareSandboxTypeLabel(sandbox.get('type'))}
                    />
                  }
                  title={
                    <div className="tw-flex tw-items-center tw-gap-2">
                      {sandbox.get('name')} Workspace
                      {sandbox.get('beta') && <BadgeBeta />}
                    </div>
                  }
                  description={sandbox.get('description')}
                  onClick={() =>
                    this.handleSelectSandbox(
                      sandbox,
                      this.props.forceStep || FORM_STEPS.SANDBOX_CREATE,
                    )
                  }
                  {...(this.props.hasTableInputMapping &&
                    ONLY_READONLY_STORAGE.includes(sandbox.get('type')) && {
                      isDisabled: true,
                      disabledReason:
                        'This workspace does not support input mapping. All tables are available automatically.',
                    })}
                />
              );
            })}
        </Modal.Body>
      </>
    );
  }

  renderSandboxUpdateModal() {
    const options = this.props.workspaces
      .filter((workspace) => {
        return (
          workspace.get('type') === this.props.forceType ||
          workspace.get('type') === this.state.sandbox?.get('type')
        );
      })
      .map((workspace) => ({
        value: workspace.getIn(['configuration', 'id']),
        label:
          CONTAINER_BASED.includes(workspace.get('type')) && !workspace.get('active') ? (
            <>
              <FontAwesomeIcon
                icon="moon"
                color="#fff"
                transform="shrink-5"
                className="bg-color-purple align-bottom f-16 img-rounded"
              />{' '}
              {workspace.getIn(['configuration', 'name'])}
            </>
          ) : (
            workspace.getIn(['configuration', 'name'])
          ),
        name: workspace.getIn(['configuration', 'name']),
      }))
      .toArray();

    const isDisabled = this.state.isLoadingWorkspaces || this.state.isLoading || !options.length;
    const placeholder = this.state.isLoadingWorkspaces
      ? 'Loading existing workspaces...'
      : options.length > 0
      ? 'Select workspace'
      : 'No workspace created yet';

    const workspace = this.findWorkspaceDetail(this.state.tempData.get('workspace'));

    return (
      <Form onSubmit={this.handleSubmitUpdateWorkspace}>
        <Modal.Header closeButton>
          <Modal.Title>Select Existing Workspace</Modal.Title>
          <ModalIcon icon="box" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Workspaces</ControlLabel>
            <Select
              options={options}
              value={this.state.tempData.get('workspace', '')}
              onChange={(value) => this.changeData('workspace', value)}
              placeholder={placeholder}
              isLoading={this.state.isLoadingWorkspaces}
              disabled={isDisabled}
              filterOption={({ name, value }, filter) => matchByWords([name, value], filter)}
            />
          </FormGroup>
          {CONTAINER_BASED.includes(this.state.sandbox.get('type')) ? (
            <FormGroup>
              <Checkbox
                checked={this.state.tempData.get('preserve', false)}
                onChange={(checked) => this.changeData('preserve', checked)}
                disabled={isDisabled}
              >
                {this.props.sourceTransformation.isEmpty()
                  ? 'Preserve input and output mappings in the workspace'
                  : 'Merge input and output mappings from the transformation to the workspace'}
              </Checkbox>
            </FormGroup>
          ) : (
            <LoadOptions
              preserve={this.state.tempData.get('preserve', false)}
              onChange={(preserve) => this.changeData('preserve', preserve)}
            />
          )}
          {this.renderWorkspaceCredentials(workspace)}
          {this.state.error && (
            <Alert variant="error" className="tw-mb-5">
              {this.state.error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            <Button
              type="submit"
              bsStyle="success"
              disabled={
                this.state.isLoadingWorkspaces ||
                !options.length ||
                !workspace ||
                this.state.isLoaded
              }
            >
              {this.state.isLoading ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="circle-check" className="icon-addon-right" fixedWidth />
              )}
              {this.state.isLoaded
                ? 'Loaded'
                : this.state.isLoading
                ? 'Loading data...'
                : 'Load data'}
            </Button>
            <Link
              href={workspace ? prepareSandboxUrl(workspace) : ''}
              className={classnames('btn btn-success', {
                'disabled btn-disabled': isDisabled || !workspace || !this.state.isLoaded,
              })}
            >
              <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
              Connect
            </Link>
          </ButtonToolbar>
        </Modal.Footer>
      </Form>
    );
  }

  renderSandboxCreateModal() {
    const runtimes = RuntimesStore.getRuntimes(
      resolveComponentIdFromSandboxType(this.state.sandbox.get('type')),
    );
    const isDisabled = this.state.isLoading || !!this.state.createdWorkspace;
    const databricksPredefinedCluster = getPredefinedCluster(
      this.props.availableDatabricksClusters,
      this.state.tempData.getIn(['databricks', 'clusterId']),
    );
    const isSubmitDisabled =
      !this.state.tempData.get('name', '').trim() || !!this.state.createdWorkspace;

    const sandboxType = this.state.sandbox.get('type');

    return (
      <Form onSubmit={this.handleSubmitCreateWorkspace}>
        <Modal.Header closeButton>
          <Modal.Title>New {this.state.sandbox.get('name')} Workspace</Modal.Title>
          <ModalIcon icon="box" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.props.sourceTransformation.get('name') && (
            <p>
              You are about to create a new workspace from the transformation{' '}
              <strong>{this.props.sourceTransformation.get('name')}</strong>.
            </p>
          )}
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Name your workspace"
              value={this.state.tempData.get('name', '')}
              onChange={(event) => this.changeData('name', event.target.value)}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder="Describe your workspace"
              value={this.state.tempData.get('description', '')}
              onChange={(event) => this.changeData('description', event.target.value)}
              disabled={isDisabled}
            />
          </FormGroup>
          {!runtimes.isEmpty() && (
            <FormGroup>
              <ControlLabel>Backend Version</ControlLabel>
              <Select
                clearable={false}
                options={prepareRuntimesForSandboxes(runtimes)}
                value={this.state.tempData.get('imageVersion', '')}
                onChange={(value) => this.changeData('imageVersion', value)}
                disabled={isDisabled}
              />
            </FormGroup>
          )}
          <WorkspaceSizeControl
            type={sandboxType}
            value={this.state.tempData.get('size', SANDBOX_SIZES.SMALL)}
            onChange={(value) => this.changeData('size', value)}
            isDisabled={isDisabled}
            hasPayAsYouGo={this.props.hasPayAsYouGo}
          />
          <WorkspaceExpirationControl
            type={sandboxType}
            value={this.state.tempData.get('expirationAfterHours', AUTO_SLEEP_DEFAULT)}
            onChange={(value) => this.changeData('expirationAfterHours', value)}
            isDisabled={isDisabled}
          />
          {this.isDatabricksWorkspace(this.state.sandbox) && (
            <>
              <DatabricksParametersFormContent
                savedValues={
                  !this.state.tempData.getIn(['databricks', 'clusterId'])
                    ? this.state.tempData.get('databricks')
                    : Map({
                        clusterId: this.state.tempData.getIn(['databricks', 'clusterId']),
                      }).merge(getDatabricksParametersFromCluster(databricksPredefinedCluster))
                }
                defaultValues={this.props.sandboxComponent.getIn(
                  ['data', 'image_parameters', 'databricks'],
                  Map(),
                )}
                availableClusters={this.props.availableDatabricksClusters}
                isLoading={this.state.isLoadingDatabricksClusters}
                loadClustersHandler={this.loadDatabricksClusters}
                onChange={(newValues) => {
                  const { clusterId, nodeType, numberOfNodes, sparkVersion } = newValues.toJS();

                  this.setState({
                    tempData: this.state.tempData.mergeDeep({
                      databricks: {
                        clusterId,
                        nodeType,
                        numberOfNodes,
                        sparkVersion,
                      },
                    }),
                  });
                }}
                isDisabled={isDisabled}
              />
            </>
          )}
          <FormGroup>
            <Checkbox
              checked={this.state.tempData.get('shared', false)}
              onChange={(checked) => this.changeData('shared', checked)}
              disabled={isDisabled}
            >
              Share with all project users
            </Checkbox>
          </FormGroup>
          {canManageReadOnlyStorageForProvisionedCredentials(sandboxType) && (
            <ReadOnlyStorageCheckbox
              value={this.state.tempData.get('readOnlyStorageAccess', false)}
              onChange={(checked) => this.changeData('readOnlyStorageAccess', checked)}
              isDisabled={isDisabled}
              alertClassName="tw-mb-5"
            />
          )}
          {sandboxType === SANDBOX_TYPE.BIGQUERY && <ReadOnlyAccessBigQuery />}
          {this.renderWorkspaceCredentials(this.state.createdWorkspace)}
          {this.state.error && <Alert variant="error">{this.state.error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          {this.props.simple || NEED_CLIENT_TO_CONNECT.includes(sandboxType) ? (
            <ButtonToolbar className="block">
              {!this.props.forceStep && (
                <Button onClick={() => this.setState(INITIAL_STATE)}>
                  <FontAwesomeIcon icon="arrow-left" fixedWidth />
                </Button>
              )}
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={
                  !!this.state.createdWorkspace
                    ? 'Created'
                    : this.state.isLoading
                    ? 'Creating Workspace...'
                    : 'Create Workspace'
                }
                isSaving={this.state.isLoading}
                isDisabled={isSubmitDisabled}
              />
            </ButtonToolbar>
          ) : (
            <ButtonToolbar className="block">
              {!this.props.forceStep && (
                <Button onClick={() => this.setState(INITIAL_STATE)}>
                  <FontAwesomeIcon icon="arrow-left" fixedWidth />
                </Button>
              )}
              <Button type="submit" bsStyle="success" disabled={isSubmitDisabled}>
                {this.state.isLoading ? (
                  <Loader className="icon-addon-right" />
                ) : (
                  <FontAwesomeIcon icon="circle-check" className="icon-addon-right" fixedWidth />
                )}
                {!!this.state.createdWorkspace
                  ? 'Created'
                  : this.state.isLoading
                  ? 'Creating Workspace...'
                  : 'Create Workspace'}
              </Button>
              <Link
                href={
                  this.state.createdWorkspace ? prepareSandboxUrl(this.state.createdWorkspace) : ''
                }
                className={classnames('btn btn-success', {
                  'disabled btn-disabled': !this.state.createdWorkspace,
                })}
              >
                <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
                Connect
              </Link>
            </ButtonToolbar>
          )}
        </Modal.Footer>
      </Form>
    );
  }

  renderWorkspaceCredentials(workspace) {
    if (!workspace || this.props.simple) {
      return null;
    }

    return (
      <>
        <FormGroup>
          <ControlLabel className="mb-1">Workspace Credentials</ControlLabel>
          {this.state.isLoadingWorkspace ? (
            <div>
              <Loader /> Loading data...
            </div>
          ) : (
            <CredentialsBox
              rows={prepareCredentialsData(workspace)}
              disabled={!workspace.get('active')}
              noBorder
            />
          )}
        </FormGroup>
        {NEED_CLIENT_TO_CONNECT.includes(workspace.get('type')) && <ClientInfo />}
      </>
    );
  }

  changeData(key, value) {
    this.setState({
      tempData: this.state.tempData.setIn([].concat(key), value),
      isLoaded: false,
      error: null,
    });
  }

  findWorkspaceDetail(configId) {
    if (!this.props.workspaces) {
      return null;
    }

    return this.props.workspaces.find((workspace) => {
      return workspace.getIn(['configuration', 'id']) === configId;
    });
  }

  prepareWorkspaces() {
    return fromJS(Object.values(SANDBOX_TYPE))
      .filter((type) => this.props.allowedComponents.has(resolveComponentIdFromSandboxType(type)))
      .map((type) => {
        const component = this.props.allowedComponents.get(resolveComponentIdFromSandboxType(type));

        return Map({
          type,
          name: component.get('name'),
          description: component.get('description'),
          imageSrc: getComponentIconUrl(component),
          beta: component.get('flags', List()).includes(BETA),
        });
      });
  }

  checkSandbox() {
    if (this.props.forceType && this.props.forceStep) {
      const data = findCreatedFromMetadata(
        resolveComponentIdFromSandboxType(this.props.forceType),
        this.props.sourceTransformation.get('id'),
        this.props.metadata,
      );
      const optionExists = this.props.workspaces.some((workspace) => {
        return workspace.get('configurationId') === data?.configurationId;
      });

      return this.handleSelectSandbox(
        this.prepareWorkspaces().find((sandbox) => sandbox.get('type') === this.props.forceType),
        this.props.forceStep,
        this.props.sourceTransformation.get('name'),
        optionExists ? data.configurationId : '',
      );
    }

    this.setState(INITIAL_STATE);
  }

  loadWorkspaces() {
    this.setState({ isLoadingWorkspaces: true });
    return Promise.all([
      InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SANDBOXES),
      SandboxesActions.loadSandboxes(),
    ]).then(() => {
      this.setState({ isLoadingWorkspaces: false });
    });
  }

  handleSubmitUpdateWorkspace(e) {
    e.preventDefault();

    if (this.state.isLoading) {
      return;
    }

    const workspace = this.props.workspaces.find((workspace) => {
      return workspace.get('configurationId') === this.state.tempData.get('workspace');
    });

    this.setState({ isLoading: true, isLoaded: false, error: null });
    return this.props.onUpdate(workspace, this.state.tempData.get('preserve')).then(({ url }) => {
      this.cancelablePromise = jobPoller
        .poll(url)
        .then(() => SandboxesActions.loadSandboxForce(workspace.get('id')))
        .then(() => this.setState({ isLoading: false, isLoaded: true, error: null }))
        .catch((error) => {
          return this.setState({
            isLoading: false,
            isLoaded: false,
            error: error?.message || 'An unknown error occurred when loading data.',
          });
        });
    });
  }

  handleSubmitCreateWorkspace(e) {
    e.preventDefault();

    if (this.state.isLoading) {
      return;
    }

    let options = Map({
      shared: this.state.tempData.get('shared', false),
      readOnlyStorageAccess: FORCE_READONLY_STORAGE_ACCESS.includes(this.state.sandbox.get('type'))
        ? true
        : this.state.tempData.get('readOnlyStorageAccess', false),
    });

    let params = Map();

    if (CONTAINER_BASED.includes(this.state.sandbox.get('type'))) {
      params = params.set(
        'expirationAfterHours',
        this.state.tempData.get('expirationAfterHours', AUTO_SLEEP_DEFAULT),
      );
    }

    if (SUPPORT_SIZES.includes(this.state.sandbox.get('type'))) {
      params = params.set('size', this.state.tempData.get('size', SANDBOX_SIZES.SMALL));
    }

    if (this.isDatabricksWorkspace(this.state.sandbox)) {
      params = params.set(
        'databricks',
        !this.state.tempData.getIn(['databricks', 'clusterId'])
          ? this.state.tempData.get('databricks', Map()).delete('clusterId')
          : Map({ clusterId: this.state.tempData.getIn(['databricks', 'clusterId']) }),
      );
    }

    if (this.state.tempData.get('imageVersion')) {
      params = params.set('imageVersion', this.state.tempData.get('imageVersion'));
    }

    this.setState({ isLoading: true, createdWorkspace: null, error: null });
    this.cancelablePromise = this.props
      .onSubmit(
        this.state.tempData.get('name'),
        this.state.sandbox.get('type'),
        options,
        params,
        this.state.tempData.get('description', ''),
      )
      .then(({ config, job }) => {
        if (this.props.simple) {
          return this.props.onHide();
        }

        this.cancelablePromise = jobPoller
          .poll(job.url)
          .then(() => {
            return InstalledComponentsActionCreators.loadComponentConfigDataForce(
              KEBOOLA_SANDBOXES,
              config.id,
            );
          })
          .then((configData) => SandboxesActions.loadSandboxForce(configData.parameters?.id))
          .then(() => {
            const workspace = SandboxesStore.getSandboxes().find(
              (workspace) => workspace.get('configurationId') === config.id,
            );
            this.setState({
              isLoading: false,
              createdWorkspace: workspace,
              error: null,
            });
          })
          .catch((error) => {
            return this.setState({
              isLoading: false,
              createdWorkspace: null,
              error: error?.message || 'An unknown error occurred when creating the workspace.',
            });
          });
      });
  }

  handleSelectSandbox(sandbox, step, name = '', workspace = '') {
    if (step === FORM_STEPS.SANDBOX_UPDATE) {
      this.loadWorkspaces();
    }

    let data = Map({
      name,
      workspace,
      shared: false,
      description: '',
      size: SANDBOX_SIZES.SMALL,
      preserve: false,
    });

    if (this.isDatabricksWorkspace(sandbox)) {
      const defaultDatabricksParameters = this.props.sandboxComponent.getIn(
        ['data', 'image_parameters', 'databricks'],
        Map(),
      );
      const sourceTransformationParameters = this.props.sourceTransformation
        .getIn(['configuration', 'parameters'], Map())
        .filter((value, key) =>
          ['clusterId', 'nodeType', 'numberOfNodes', 'sparkVersion'].includes(key),
        );

      data = data.set(
        'databricks',
        sourceTransformationParameters.isEmpty()
          ? Map({
              nodeType: defaultDatabricksParameters.get('defaultNodeType'),
              numberOfNodes: defaultDatabricksParameters.get('defaultNumberOfNodes'),
              sparkVersion: defaultDatabricksParameters.get('defaultSparkVersion'),
            })
          : sourceTransformationParameters,
      );
    }

    this.setState({ sandbox, step, tempData: data, isLoading: false });
  }

  isDatabricksWorkspace(sandbox) {
    return sandbox.get('type') === SANDBOX_TYPE.PYTHON_DATABRICKS;
  }

  loadDatabricksClusters() {
    this.setState({ isLoadingDatabricksClusters: true });

    return ConfigurationsActionCreators.loadDatabricksClusters(KEBOOLA_SANDBOXES).finally(() =>
      this.setState({ isLoadingDatabricksClusters: false }),
    );
  }
}

AddSandboxModal.propTypes = {
  sandboxComponent: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  forceType: PropTypes.string,
  forceStep: PropTypes.oneOf(Object.values(FORM_STEPS)),
  onUpdate: PropTypes.func,
  workspaces: PropTypes.instanceOf(Map),
  metadata: PropTypes.instanceOf(Map),
  sourceTransformation: PropTypes.instanceOf(Map),
  hasTableInputMapping: PropTypes.bool,
  simple: PropTypes.bool,
};

AddSandboxModal.defaultProps = {
  hasTableInputMapping: false,
  sourceTransformation: Map(),
  metadata: Map(),
};

export default AddSandboxModal;
