import type { Map } from 'immutable';
import { fromJS } from 'immutable';

const KEY = 'destination';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({
    [KEY]: {
      outputBucket: parameters.getIn(['config', 'outputBucket'], ''),
      incrementalOutput: parameters.getIn(['config', 'incrementalOutput'], false),
    },
  });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters
    .setIn(['config', 'outputBucket'], editing.getIn([KEY, 'outputBucket']))
    .setIn(['config', 'incrementalOutput'], editing.getIn([KEY, 'incrementalOutput']));
};

export { KEY, mapToState, mapToApi };
