import React from 'react';
import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { prepareUserParameters } from '@/modules/ex-generic/helpers';
import { KEY } from '@/modules/ex-generic/mapping/endpoint';
import CodeEditor from '@/react/common/CodeEditor';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';
import GenericPairs from './GenericPairs';

const JobParameters = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  parameters: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
  rawEditingData: Map<string, any>;
}) => {
  const formMethod = props.rawEditingData.getIn([KEY, 'method']);
  const params = props.editing.get('params', List());

  if (formMethod === 'POST') {
    const value = List.isList(params)
      ? JSON.stringify(
          params
            .toMap()
            .mapKeys((_: number, value: Map<string, any>) => value.get('key'))
            .map((value: Map<string, any>) => value.get('value'))
            .toJSON(),
          null,
          2,
        )
      : params;

    return (
      <CollapsibleBox
        title="JSON Body"
        defaultOpen={!!value}
        titleSuffix={<DocumentationLink path="configuration/config/jobs/#post" />}
      >
        <CodeEditor
          value={value}
          onChange={(value: string) => {
            props.setEditing(props.editing.set('params', value));
          }}
          options={{ readOnly: props.readOnly }}
        />
      </CollapsibleBox>
    );
  }

  const entity = formMethod === 'FORM' ? 'Form' : 'Query';
  const value = List.isList(params) ? params : List();

  return (
    <CollapsibleBox
      title={`${entity} Parameters`}
      defaultOpen={!value.isEmpty()}
      titleSuffix={<DocumentationLink path="configuration/config/jobs/#request-parameters" />}
    >
      <GenericPairs
        entity={`${entity} Parameter`}
        rows={value}
        onSave={props.onSave}
        readOnly={props.readOnly}
        parameters={props.parameters}
        onChange={(params) => props.setEditing(props.editing.set('params', params))}
        userParameters={prepareUserParameters(props.parameters)}
      />
    </CollapsibleBox>
  );
};

export default JobParameters;
