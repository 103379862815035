import Promise from 'bluebird';
import Immutable from 'immutable';

import dispatcher from '@/Dispatcher';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import * as variablesActions from '@/modules/vault/actions';
import ApplicationStore from '@/stores/ApplicationStore';
import oauthApi from './Api';
import * as Constants from './Constants';
import oauthStore from './Store';

const handleLoadErrors = (error) => {
  /*
    TODO: fix when new oauth service is deployed on all stacks
    404 - credentials or route (old auth service) not found
    crossDomain - workaound for broken CORS headers on oauth server in AWS US
  */
  if (error?.response?.statusCode === 404 || error?.crossDomain) {
    return;
  }
  throw error;
};

export default {
  loadAllCredentials() {
    return Promise.props({
      credentials: Promise.props({
        projectWide: oauthApi.getAllCredentialsForBranch(),
        production: oauthApi.getAllCredentialsForBranch(
          DevBranchesStore.getDefaultBranchId()?.toString(),
        ),
        current: oauthApi.getAllCredentialsForBranch(DevBranchesStore.getCurrentId()?.toString()),
      }),
      variables: variablesActions.loadVariables(),
    })
      .then((data) => {
        dispatcher.handleViewAction({
          type: Constants.ActionTypes.OAUTHV2_LOAD_ALL_CREDENTIALS_SUCCESS,
          credentials: Immutable.fromJS(data.credentials),
        });
      })
      .catch(handleLoadErrors);
  },

  loadCredentials(componentId, id) {
    if (componentId && id && oauthStore.hasCredentials(componentId, id)) {
      return Promise.resolve();
    }
    return this.loadCredentialsForce(componentId, id);
  },

  loadCredentialsForce(componentId, id) {
    return oauthApi
      .getCredentials(componentId, id)
      .tap(() => ApplicationStore.hasProtectedDefaultBranch() && variablesActions.loadVariables())
      .then((result) => {
        dispatcher.handleViewAction({
          type: Constants.ActionTypes.OAUTHV2_LOAD_CREDENTIALS_SUCCESS,
          componentId,
          id,
          credentials: Immutable.fromJS(result),
        });
        return result;
      })
      .catch(handleLoadErrors);
  },

  postCredentials(componentId, params, data) {
    return oauthApi.postCredentials(componentId, params, data).then((result) =>
      dispatcher.handleViewAction({
        type: Constants.ActionTypes.OAUTHV2_LOAD_CREDENTIALS_SUCCESS,
        componentId,
        id: params.id,
        credentials: Immutable.fromJS(result),
      }),
    );
  },

  deleteCredentials(componentId, id) {
    return oauthApi.deleteCredentials(componentId, id).then(() =>
      dispatcher.handleViewAction({
        type: Constants.ActionTypes.OAUTHV2_DELETE_CREDENTIALS_SUCCESS,
        componentId,
        id,
      }),
    );
  },
};
