import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareCreatingSandboxes, prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import FilterPanel from '@/react/common/FilterPanel';
import NoEntityCreated from '@/react/common/NoEntityCreated';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import Sandboxes from './Sandboxes';

const SandboxesIndex = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      InstalledComponentsStore,
      ComponentsStore,
      SandboxesStore,
      DevBranchesStore,
    ),
  ],

  getStateFromStores() {
    const hasNewQueue = ApplicationStore.hasNewQueue();
    const sandboxes = SandboxesStore.getSandboxes();
    const configurations = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES);

    return {
      hasNewQueue,
      sapiToken: ApplicationStore.getSapiToken(),
      isLoadingSandboxes: SandboxesStore.getIsLoading(),
      sandboxesPendingActions: SandboxesStore.getPendingActions(),
      sandboxes: prepareSandboxes(sandboxes, configurations),
      creatingSandboxes: prepareCreatingSandboxes(
        SandboxesStore.getProcessingJobs(),
        sandboxes,
        configurations,
        hasNewQueue,
      ),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      allConfigurations: InstalledComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      transformationComponents: ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      mlflowInstanceUrl: SandboxesStore.getMlflowInstanceUrl(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    return (
      <>
        <FilterPanel
          placeholder={this.getPlaceholder}
          query={this.state.filterQuery}
          onChange={(query) => {
            this.setState({ filterQuery: query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        {this.renderSandboxes()}
      </>
    );
  },

  renderSandboxes() {
    const sandboxes = this.getSandboxesFiltered();

    if (this.state.filterQuery && sandboxes.isEmpty()) {
      return <NoResultsFound entityName="workspaces" />;
    }

    if (sandboxes.isEmpty() && this.state.creatingSandboxes.isEmpty()) {
      return <NoEntityCreated entityName="workspaces" />;
    }

    return (
      <Sandboxes
        sandboxes={sandboxes}
        admins={this.state.admins}
        currentAdmin={this.state.currentAdmin}
        creatingSandboxes={this.state.creatingSandboxes}
        sapiToken={this.state.sapiToken}
        isLoading={this.state.isLoadingSandboxes}
        sandboxComponent={this.state.sandboxComponent}
        transformationComponents={this.state.transformationComponents}
        pendingActions={this.state.sandboxesPendingActions}
        allConfigurations={this.state.allConfigurations}
        componentsMetadata={this.state.componentsMetadata}
        isDevModeActive={this.state.isDevModeActive}
        mlflowInstanceUrl={this.state.mlflowInstanceUrl}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        hasNewQueue={this.state.hasNewQueue}
        hasFlows={this.state.hasFlows}
        readOnly={this.state.readOnly}
      />
    );
  },

  getPlaceholder() {
    return `Search workspaces (${this.state.sandboxes.count()})`;
  },

  getSandboxesFiltered() {
    return this.state.sandboxes.filter((sandbox) => {
      return matchByWords(
        [sandbox.getIn(['configuration', 'name']), sandbox.getIn(['configuration', 'description'])],
        this.state.filterQuery,
      );
    });
  },
});

export default SandboxesIndex;
