let _baseUrl = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, StorageToken: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': StorageToken };
  _fetch = fetch;
}
export type Variable = {
  key: string;
  value: string;
  flags?: ('encrypted' | 'oauthCredentialsId')[] | undefined;
  group?: string | undefined;
  attributes?: Record<string, any> | undefined;
};
export type VariableWithHash = Variable & { hash: string };
/**
 * Checks the service is up & running.
 */
export function healthCheck(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/health-check';
  return _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
}
/**
 * Lists variables.
 */
export function listVariables(params?: {
  key: string | undefined;
  attributes: Record<string, any> | undefined;
  offset: number | undefined;
  limit: number | undefined;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/variables';
  url.search = new URLSearchParams({
    ...(params?.['key'] && { key: params['key'].toString() }),
    ...(params?.['attributes'] && { attributes: params['attributes'].toString() }),
    ...(params?.['offset'] && { offset: params['offset'].toString() }),
    ...(params?.['limit'] && { limit: params['limit'].toString() }),
  }).toString();
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Creates a new variable.
 */
export function createVariable(body: Variable): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/variables';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Deletes a variable.
 */
export function deleteVariable(params: { hash: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/variables/{hash}'.replace('{hash}', params['hash']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * List variables variables available in scope of the branch.
 */
export function listBranchVariables(params: { branchId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/variables/scoped/branch/{branchId}'.replace('{branchId}', params['branchId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
