import React, { useState } from 'react';
import { Alert, Badge, cn, Collapse } from 'design';
import type { Map } from 'immutable';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import CreatedDate from '@/react/common/CreatedDate';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Link from '@/react/common/RouterLink';
import Tree from '@/react/common/Tree';

const Task = ({
  task,
  component,
  hasFlows,
}: {
  task: Map<string, any>;
  component: Map<string, any>;
  hasFlows: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderSpecificErrorMessage = (task: Map<string, any>) => {
    const message = task.getIn(['response', 'message'], '');
    if (message === 'Orchestrations can be started only 2 times for the current ID.') {
      return (
        <Alert variant="error" className="tw-mb-5">
          Maximum {hasFlows ? 'flow' : 'orchestration'} nesting level (2) was exceeded.
        </Alert>
      );
    }
    return null;
  };

  return (
    <Collapse
      open={isOpen}
      className="tw-mb-2 tw-rounded tw-border tw-border-solid tw-border-neutral-200 last:tw-mb-0"
      header={
        <div
          className={cn(
            'tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-bg-neutral-50 tw-px-5 tw-py-3 tw-text-sm tw-text-neutral-400',
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="table">
            <span className="tbody">
              <span className="tr">
                <span className="td col-xs-6 !tw-p-0">
                  {component ? (
                    <span>
                      <ComponentIcon component={component} className="icon-addon-right" size="32" />
                      <ComponentName component={component} />
                    </span>
                  ) : (
                    task.get('componentUrl')
                  )}{' '}
                  {task.has('config') && ` - ${task.getIn(['config', 'name'])}`}
                </span>
                <span className="td col-xs-2 text-right !tw-p-0">
                  <Badge text={task.get('type')} />
                </span>
                <span className="td col-xs-2 text-right !tw-p-0">
                  <JobDuration
                    status={task.get('status')}
                    startTime={task.get('startTime')}
                    endTime={task.get('endTime')}
                  />
                </span>
                <span className="td col-xs-2 text-right !tw-p-0">
                  {task.get('status', false) && <JobStatusLabel status={task.get('status')} />}
                </span>
              </span>
            </span>
          </span>
        </div>
      }
    >
      <div className="tw-p-4">
        {task.get('startTime') && (
          <p>
            <strong>{'Start time '}</strong>
            <CreatedDate createdTime={task.get('startTime')} absolute />
          </p>
        )}
        {task.get('endTime') && (
          <p>
            <strong>{'End time '}</strong>
            <CreatedDate createdTime={task.get('endTime')} absolute />
          </p>
        )}
        {task.has('config') && (
          <p>
            <strong>{'Configuration '}</strong>
            <ComponentConfigurationLink
              componentId={task.get('component')}
              configId={task.getIn(['config', 'id'])}
              hasFlows={false}
            >
              {task.getIn(['config', 'name'])}
            </ComponentConfigurationLink>
          </p>
        )}
        {task.get('runParameters') && task.get('runParameters').size && (
          <div>
            <p>
              <strong>Parameters</strong>
            </p>
            <Tree data={task.get('runParameters')} />
          </div>
        )}
        {task.getIn(['response', 'id']) && (
          <Link to={jobsRouteNames.DETAIL} params={{ jobId: task.getIn(['response', 'id']) }}>
            Go to job detail
          </Link>
        )}
        {renderSpecificErrorMessage(task)}
      </div>
    </Collapse>
  );
};

const JobTasks = ({
  tasks,
  allComponents,
  hasFlows,
}: {
  tasks: Map<string, any>;
  allComponents: Map<string, any>;
  hasFlows: boolean;
}) => {
  const renderTask = (task: Map<string, any>) => {
    const component = allComponents.get(task.get('component'));

    return <Task task={task} component={component} hasFlows={hasFlows} />;
  };

  return (
    <div className="box">
      <div className="box-content">
        {tasks
          .filter((task) => task.get('active'))
          .map(renderTask)
          .toArray()}
      </div>
    </div>
  );
};

export default JobTasks;
