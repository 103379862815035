import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Alert, HelpBlock } from 'design';

import LastFetchedValue from '@/modules/ex-db-generic/react/components/LastFetchedValue';
import { exportModes } from '@/modules/ex-mongodb/constants';
import { nameWarning } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import CodeEditor from '@/react/common/CodeEditor';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const QueryEditor = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    outTableExist: PropTypes.bool,
    configId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    configState: PropTypes.object.isRequired,
    onResetState: PropTypes.func.isRequired,
    isRow: PropTypes.bool,
  },

  handleNameChange(event) {
    return this.props.onChange(
      this.props.query.set(
        this.props.isRow ? 'tableName' : 'name',
        string.sanitizeKbcTableIdString(event.target.value),
      ),
    );
  },

  handleIncrementalChange(checked) {
    return this.props.onChange(this.props.query.set('incremental', checked));
  },

  handleQueryChange(value) {
    return this.props.onChange(this.props.query.set('query', value));
  },

  handleSortChange(value) {
    return this.props.onChange(this.props.query.set('sort', value));
  },

  handleIncrementalFetchingChange(event) {
    return this.props.onChange(
      this.props.query.set('incrementalFetchingColumn', event.target.value),
    );
  },

  handleLimitChange(event) {
    return this.props.onChange(this.props.query.set('limit', event.target.value));
  },

  handleMappingChange(value) {
    return this.props.onChange(this.props.query.set('mapping', value));
  },

  handleCollectionChange(event) {
    return this.props.onChange(this.props.query.set('collection', event.target.value));
  },

  handleModeChange(value) {
    let newQuery = this.props.query.set('mode', value);
    if (newQuery.get('mode') === exportModes.MODE_MAPPING && !newQuery.has('includeParentInPK')) {
      newQuery = newQuery.set('includeParentInPK', true);
    }
    this.props.onChange(newQuery);
  },

  handleIncludeParentInPkChange(checked) {
    return this.props.onChange(this.props.query.set('includeParentInPK', checked));
  },

  render() {
    return (
      <div className="form-horizontal">
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">General Settings</h3>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>{this.props.isRow ? 'Storage table name' : 'Name'}</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  autoFocus
                  type="text"
                  placeholder="e.g., last-100-articles"
                  onChange={this.handleNameChange}
                  value={this.props.query.get(this.props.isRow ? 'tableName' : 'name', '')}
                  disabled={this.props.readOnly}
                />
                <HelpBlock>
                  {this.props.outTableExist && (
                    <span className="text-danger">Export with this name already exists. </span>
                  )}
                  Names have to be unique across all exports in the current configuration.{' '}
                  {nameWarning}
                </HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Collection</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="text"
                  placeholder="e.g., Article"
                  onChange={this.handleCollectionChange}
                  value={this.props.query.get('collection', '')}
                  disabled={this.props.readOnly}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Query</ControlLabel>
              </div>
              <div className="col-xs-9">
                {this.props.query.get('incrementalFetchingColumn') ? (
                  <HelpBlock className="mt-1">
                    Query is available for this component but only for exports without incremental
                    fetching setting.
                  </HelpBlock>
                ) : (
                  <>
                    <CodeEditor
                      value={this.props.query.get('query', '')}
                      onChange={this.handleQueryChange}
                      options={{
                        placeholder: 'optional, e.g., {"isActive": 1, "isDeleted": 0}',
                        readOnly: this.props.readOnly,
                      }}
                    />
                    <HelpBlock>Query to filter documents. It has to be valid JSON.</HelpBlock>
                  </>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Sort</ControlLabel>
              </div>
              <div className="col-xs-9">
                {this.props.query.get('incrementalFetchingColumn') ? (
                  <HelpBlock className="mt-1">
                    Sort is available for this component but only for exports without incremental
                    fetching settings.
                  </HelpBlock>
                ) : (
                  <>
                    <CodeEditor
                      value={this.props.query.get('sort', '')}
                      onChange={this.handleSortChange}
                      options={{
                        placeholder: 'optional, e.g., {"creationDate": -1}',
                        readOnly: this.props.readOnly,
                      }}
                    />
                    <HelpBlock>Sort results by specified keys. It has to be valid JSON.</HelpBlock>
                  </>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Limit</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="number"
                  placeholder="optional, e.g., 100"
                  onChange={this.handleLimitChange}
                  value={this.props.query.get('limit', '')}
                  disabled={this.props.readOnly}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Mode</h3>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Mode</ControlLabel>
              </div>
              <div className="col-xs-9">
                <Select
                  clearable={false}
                  onChange={this.handleModeChange}
                  disabled={this.props.readOnly}
                  value={this.props.query.get('mode') || 'mapping'}
                  options={[
                    { value: 'mapping', label: 'Mapping' },
                    { value: 'raw', label: 'Raw' },
                  ]}
                />
                <HelpBlock>
                  Mapping mode allows you to define a more precise structure. In raw mode, only JSON
                  objects are exported.
                </HelpBlock>
              </div>
            </FormGroup>
            {this.renderMapping()}
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Incremental Fetching</h3>
          </div>
          <div className="box-content">
            {!this.props.query.get('query', '') && !this.props.query.get('sort', '') ? (
              <>
                <FormGroup controlId="QueryEditor-incremental-fetching">
                  <div className="col-xs-3">
                    <ControlLabel>Property</ControlLabel>
                  </div>
                  <div className="col-xs-9">
                    <FormControl
                      type="text"
                      placeholder="e.g., id"
                      disabled={this.props.readOnly}
                      onChange={this.handleIncrementalFetchingChange}
                      value={this.props.query.get('incrementalFetchingColumn', '')}
                    />
                    <HelpBlock>
                      If enabled, only newly created or updated records since the last run will be
                      fetched.
                    </HelpBlock>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="col-xs-3">
                    <ControlLabel>Last Fetched Value</ControlLabel>
                  </div>
                  <div className="col-xs-9">
                    <LastFetchedValue
                      readOnly={this.props.readOnly}
                      value={this.props.configState.getIn(
                        this.props.isRow
                          ? ['component', 'lastFetchedRow']
                          : ['component', 'lastFetchedRow', this.props.query.get('id').toString()],
                      )}
                      incrementalFetchingColumn={this.props.query.get('incrementalFetchingColumn')}
                      onReset={this.props.onResetState}
                    />
                  </div>
                </FormGroup>
              </>
            ) : (
              <FormGroup controlId="QueryEditor-incremental-fetching">
                <div className="col-xs-9 col-xs-offset-3">
                  <HelpBlock>
                    Incremental fetching is available for this component but only for exports
                    without query and sort settings.
                  </HelpBlock>
                </div>
              </FormGroup>
            )}
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Loading Options</h3>
          </div>
          <div className="box-content">
            <FormGroup controlId="QueryEditor-incremental">
              <div className="col-xs-9 col-xs-offset-3">
                <Checkbox
                  checked={this.props.query.get('incremental', false)}
                  onChange={this.handleIncrementalChange}
                  disabled={this.props.readOnly}
                >
                  Incremental loading
                </Checkbox>
                <HelpBlock>
                  If incremental load is turned on, the destination table will be updated instead of
                  rewritten.
                </HelpBlock>
                {this.props.query.get('incrementalFetchingColumn') &&
                  !this.props.query.get('incremental') && (
                    <Alert variant="warning" className="tw-mb-5">
                      It is recommended to enable incremental loading if using incremental fetching.
                      If incremental loading is <strong>not</strong> enabled, the storage table will
                      always contain only the most recently fetched results.
                    </Alert>
                  )}
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    );
  },

  renderMapping() {
    const { query } = this.props;

    if (!query.has('mode') || query.get('mode') === 'mapping') {
      const mappingValueType = typeof query.get('mapping');
      let mappingValue;
      if (mappingValueType === 'undefined') {
        mappingValue = '';
      } else if (mappingValueType === 'object') {
        mappingValue = JSON.stringify(query.get('mapping'), null, 2);
      } else {
        mappingValue = query.get('mapping').toString();
      }
      return (
        <>
          <FormGroup controlId="QueryEditor-mapping">
            <div className="col-xs-3">
              <ControlLabel>Mapping</ControlLabel>
            </div>
            <div className="col-xs-9">
              <CodeEditor
                value={mappingValue}
                onChange={this.handleMappingChange}
                options={{
                  placeholder: 'e.g., {"_id.$oid": "id", "name": "name"}',
                  readOnly: this.props.readOnly,
                }}
              />
              <HelpBlock>
                Mapping to define the structure of exported tables. It has to be valid JSON.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-9 col-xs-offset-3">
              <Checkbox
                checked={this.props.query.get('includeParentInPK', false)}
                onChange={this.handleIncludeParentInPkChange}
                disabled={this.props.readOnly}
              >
                Include parent in primary key
              </Checkbox>
              <HelpBlock>
                If selected, the primary keys of the same sub-documents from different parents will
                differ. Otherwise, the primary keys will be based only on the sub-document content
                (legacy behavior).
              </HelpBlock>
            </div>
          </FormGroup>
        </>
      );
    }

    return null;
  },
});

export default QueryEditor;
