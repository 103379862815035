import Promise from 'bluebird';
import { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_DATA_APPS, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import jobScheduledNotification from '@/modules/components/react/components/notifications/jobScheduled';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import JobsApi from '@/modules/jobs/JobsApi';
import QueueApi from '@/modules/queue/api';
import { JOBS_STATUS } from '@/modules/queue/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import SimpleError from '@/utils/errors/SimpleError';
import jobPoller from '@/utils/jobPoller';
import { ActionTypes, PENDING_JOBS_QUERY } from './Constants';
import { prepareInputMappingForWorkspaceLoad } from './helpers';
import SandboxesApi from './SandboxesApi';
import Store from './SandboxesStore';

const loadSandboxProjectSettings = () => {
  if (Store.isSettingsLoaded()) {
    return Promise.resolve();
  }
  return loadSandboxProjectSettingsForce();
};

const loadSandboxProjectSettingsForce = () => {
  return SandboxesApi.loadProjectSettings().then((settings) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PROJECT_SETTINGS_LOAD_SUCCESS,
      settings,
    });
    return null;
  });
};

const loadSandboxes = () => {
  if (Store.getIsLoaded()) {
    loadSandboxesForce();
    return Promise.resolve();
  }
  return loadSandboxesForce();
};

const loadSandboxesForce = () => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_LOAD });
  return SandboxesApi.getSandboxes()
    .then((result) => {
      dispatcher.handleViewAction({
        type: ActionTypes.SANDBOXES_LOAD_SUCCESS,
        sandboxes: result,
      });
      return null;
    })
    .catch((error) => {
      dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_LOAD_ERROR });
      throw error;
    });
};

const loadSandbox = (id) => {
  if (Store.isDetailLoaded(id)) {
    loadSandboxForce(id);
    return Promise.resolve();
  }
  return loadSandboxForce(id);
};

const loadSandboxForce = (id) => {
  return SandboxesApi.getSandbox(id).then((result) => {
    dispatcher.handleViewAction({
      type: ActionTypes.SANDBOX_LOAD_SUCCESS,
      sandbox: result,
    });
  });
};

const loadSandboxesPendingJobs = (component = KEBOOLA_SANDBOXES) => {
  if (ApplicationStore.hasNewQueue()) {
    return QueueApi.getJobs({
      component,
      status: [JOBS_STATUS.CREATED, JOBS_STATUS.PROCESSING, JOBS_STATUS.WAITING],
    }).then((jobs) => {
      dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_PROCESSING_JOBS_LOADED, jobs });
      return null;
    });
  }

  return JobsApi.getJobsByQuery(PENDING_JOBS_QUERY).then((jobs) => {
    dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_PROCESSING_JOBS_LOADED, jobs });
    return null;
  });
};

const createSandboxSimple = (
  config,
  type,
  options,
  params,
  component = KEBOOLA_SANDBOXES,
  task = 'create',
  notify = true,
) => {
  return componentsActions
    .runComponent({
      component,
      data: {
        config: config.id,
        configData: {
          parameters: {
            task,
            type,
            shared: options.get('shared', false),
            ...params
              .withMutations((params) => {
                if (options.has('readOnlyStorageAccess')) {
                  params.setIn(
                    ['storage', 'input', 'read_only_storage_access'],
                    options.get('readOnlyStorageAccess'),
                  );
                }
              })
              .toJS(),
          },
          ...(component === KEBOOLA_DATA_APPS && {
            storage: config.configuration?.storage ?? {},
            authorization: config.configuration?.authorization ?? {},
          }),
        },
      },
      notify,
    })
    .then((response) => {
      reloadWhenJobFinished(response, component);
      return { config, job: response };
    });
};

const createSandbox = (sandboxConfig, type, options, params) => {
  return componentsActions.createConfiguration(KEBOOLA_SANDBOXES, sandboxConfig).then((config) => {
    return createSandboxSimple(config, type, options, params);
  });
};

const deleteSandbox = (
  id,
  configurationId,
  component = KEBOOLA_SANDBOXES,
  options = { notification: true },
) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_DELETE, id });
  return componentsActions
    .runComponent({
      component,
      data: {
        config: configurationId,
        configData: { parameters: { task: 'delete', id } },
      },
      notify: false,
    })
    .then((response) => {
      reloadWhenJobFinished(response, component).finally(() => {
        dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_DELETE_SUCCESS, id });
      });

      if (options?.notification) {
        ApplicationActionCreators.sendNotification({
          type: 'info',
          message: jobScheduledNotification(ComponentsStore.getComponent(component), response),
        });
      }

      return null;
    });
};

const resetPassword = (componentId, sandboxId, configurationId, additionalData = {}) => {
  return componentsActions
    .runComponent({
      component: componentId,
      data: {
        config: configurationId,
        configData: { parameters: { task: 'reset-password', id: sandboxId }, ...additionalData },
      },
      notify: false,
    })
    .then((response) => reloadWhenJobFinished(response))
    .then(() => loadSandboxForce(sandboxId))
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: `Your password has been successfully reset.${
          componentId === KEBOOLA_DATA_APPS
            ? ' Please note that this change may take effect within the next minute.'
            : ''
        }`,
      });
    });
};

const restoreSandboxSimple = (id, configurationId, inputMapping, params = Map()) => {
  return componentsActions.runComponent({
    component: KEBOOLA_SANDBOXES,
    data: {
      config: configurationId,
      configData: {
        parameters: { task: 'restore', id, ...params.toJS(), storage: { input: inputMapping } },
      },
    },
  });
};

const restoreSandbox = (id, configurationId, inputMapping, params) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_RESTORE, id });
  return restoreSandboxSimple(id, configurationId, inputMapping, params)
    .then((response) => reloadWhenJobFinished(response))
    .then(() => loadSandboxForce(id))
    .then(() => {
      dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_RESTORE_SUCCESS, id });
      return null;
    });
};

const terminateSandbox = (
  id,
  configurationId,
  component = KEBOOLA_SANDBOXES,
  options = { waitForFinish: true },
) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_TERMINATE, id });
  return componentsActions
    .runComponent({
      component,
      data: {
        config: configurationId,
        configData: { parameters: { task: 'terminate', id } },
      },
    })
    .then((response) => {
      const finishPromise = reloadWhenJobFinished(response, component).then(() => {
        dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_TERMINATE_SUCCESS, id });
        return null;
      });

      return options.waitForFinish ? finishPromise : Promise.resolve();
    });
};

const restartSandbox = (id, configurationId, componentId, inputMapping) => {
  return terminateSandbox(id, configurationId, componentId, { waitForFinish: false }).then(() =>
    restoreSandbox(id, configurationId, inputMapping),
  );
};

const shareSandbox = (id) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_SHARE, id });
  return SandboxesApi.updateSandboxes(id, { shared: true }).then((sandbox) => {
    dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_SHARE_SUCCESS, id, sandbox });
    return null;
  });
};

const unshareSandbox = (id) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_SHARE, id });
  return SandboxesApi.updateSandboxes(id, { shared: false }).then((sandbox) => {
    dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_SHARE_SUCCESS, id, sandbox });
    return null;
  });
};

const updateSandboxParameters = (id, inputMapping, parameters) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOX_UPDATE_PARAMETERS, id });
  return SandboxesApi.updateSandboxes(id, parameters).then((sandbox) => {
    dispatcher.handleViewAction({
      type: ActionTypes.SANDBOX_UPDATE_PARAMETERS_SUCCESS,
      id,
      sandbox,
    });

    // After changing parameters sandbox needs to be restarted in order to apply changes
    if (sandbox.active) {
      restartSandbox(id, sandbox.configurationId, KEBOOLA_SANDBOXES, inputMapping.toJS());
    }

    return null;
  });
};

const loadDataSimple = (id, configurationId, inputMapping, options) => {
  return componentsActions.runComponent({
    component: KEBOOLA_SANDBOXES,
    data: {
      config: configurationId,
      configData: {
        parameters: {
          task: 'load-data',
          id,
          storage: { input: prepareInputMappingForWorkspaceLoad(inputMapping, options) },
        },
      },
    },
  });
};

const loadData = (id, configurationId, inputMapping, options) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_LOAD_DATA, id });
  return loadDataSimple(id, configurationId, inputMapping, options).then(() => {
    dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_LOAD_DATA_SUCCESS, id });
    return null;
  });
};

const unloadData = (id, configurationId, outputMapping) => {
  dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_UNLOAD_DATA, id });
  return componentsActions
    .runComponent({
      component: KEBOOLA_SANDBOXES,
      data: {
        config: configurationId,
        configData: { parameters: { task: 'unload-data', id, storage: { output: outputMapping } } },
      },
    })
    .then(() => {
      dispatcher.handleViewAction({ type: ActionTypes.SANDBOXES_UNLOAD_DATA_SUCCESS, id });
      return null;
    });
};

const reloadWhenJobFinished = ({ url }, component = KEBOOLA_SANDBOXES) => {
  Promise.delay(1000).then(() => loadSandboxesPendingJobs(component));
  return jobPoller
    .poll(url)
    .catch((error) => {
      throw new SimpleError(
        `${component === KEBOOLA_DATA_APPS ? 'Data App' : 'Workspace'} job failed`,
        error.message,
      );
    })
    .delay(5000)
    .then(() => componentsActions.loadComponentConfigsDataForce(component))
    .then(() => loadSandboxesForce())
    .finally(() => loadSandboxesPendingJobs(component));
};

export default {
  loadSandboxProjectSettings,
  loadSandbox,
  loadSandboxForce,
  loadSandboxes,
  loadSandboxesForce,
  createSandbox,
  createSandboxSimple,
  deleteSandbox,
  resetPassword,
  restoreSandboxSimple,
  restoreSandbox,
  terminateSandbox,
  shareSandbox,
  unshareSandbox,
  updateSandboxParameters,
  loadDataSimple,
  loadData,
  unloadData,
};
