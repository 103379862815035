import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { List } from 'immutable';

import { FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import ExternalBucketLink from '@/modules/components/react/components/ExternalBucketLink';
import ExternalProjectLink from '@/modules/components/react/components/ExternalProjectLink';
import SyncActionSimpleValue from '@/modules/configurations/react/components/SyncActionSimpleValue';
import ProjectStackSelector from '@/modules/ex-storage/react/components/ProjectStackSelector';
import PasswordControl from '@/react/common/PasswordControl';

const TargetProject = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    stackFeatures: PropTypes.instanceOf(List).isRequired,
    value: PropTypes.shape({
      url: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
  },

  render() {
    const infoAction = this.props.actions.get('info');

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Project stack</ControlLabel>
          </div>
          <div className="col-xs-8">
            <ProjectStackSelector
              value={this.props.value.url}
              onChange={(url) => this.props.onChange({ url })}
              isDisabled={this.props.disabled}
              isSingleTenantStack={this.props.stackFeatures.includes(FEATURE_IS_SINGLE_TENANT)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Storage API Token</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              value={this.props.value.token}
              onChange={(event) => this.props.onChange({ token: event.target.value })}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              Use a token with permissions limited only to write to a single target bucket.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Project</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static className="overflow-break-anywhere">
              <ExternalProjectLink
                stackUrl={infoAction.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={infoAction.getIn(['data', 'projectId'])}
              >
                <SyncActionSimpleValue action={infoAction} valueKey="projectName" />
              </ExternalProjectLink>
            </FormControl.Static>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Bucket</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static className="overflow-break-anywhere">
              <ExternalBucketLink
                stackUrl={infoAction.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={infoAction.getIn(['data', 'projectId'])}
                bucketId={infoAction.getIn(['data', 'bucket'])}
              >
                <SyncActionSimpleValue action={infoAction} valueKey="bucket" />
              </ExternalBucketLink>
            </FormControl.Static>
          </div>
        </FormGroup>
      </Form>
    );
  },
});

export default TargetProject;
