import React from 'react';

const RowWrapper = ({ title, children }: React.PropsWithChildren<{ title: string }>) => (
  <div className="tw-flex tw-min-h-10 tw-items-center tw-justify-between tw-gap-8">
    <span className="tw-text-neutral-800">{title}</span>
    {children}
  </div>
);

export default RowWrapper;
