import Promise from 'bluebird';
import { Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import date from '@/date';
import dispatcher from '@/Dispatcher';
import { getLargerBackendSize } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_BAD_REQUEST } from '@/utils/errors/helpers';
import api from './api';
import {
  ActionTypes,
  JOB_FINISHED_STATUSES,
  JOB_RUNNING_STATUSES,
  JOBS_LIMIT,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_TYPES,
  routeNames,
} from './constants';
import { getCurrentBackendSize, prepareQueryParams } from './helpers';
import JobsStore from './store';

export default {
  loadJobs(params, options) {
    if (JobsStore.getIsLoaded()) {
      this.loadJobsForce(params, options);
      return Promise.resolve();
    }
    return this.loadJobsForce(params, options);
  },

  loadJobsForce(params = {}, options) {
    const preparedParams = prepareQueryParams(params);

    dispatcher.handleViewAction({
      type: ActionTypes.JOBS_LOAD_START,
      params: preparedParams,
      options,
    });
    return api
      .getJobs(preparedParams)
      .then((jobs) => {
        dispatcher.handleViewAction({ type: ActionTypes.JOBS_LOAD_SUCCESS, jobs, options });
      })
      .catch((error) => {
        dispatcher.handleViewAction({ type: ActionTypes.JOBS_LOAD_ERROR });
        throw error;
      });
  },

  loadChildJobsForce(parentJob) {
    return api.getChildJobs(parentJob).then((jobs) => {
      dispatcher.handleViewAction({
        type: ActionTypes.JOBS_LOAD_SUCCESS,
        withoutLoading: true,
        jobs,
      });
    });
  },

  loadLatestJobsForConfigurationsForce(componentId) {
    const configIds = [
      ...InstalledComponentsStore.getComponentConfigurations(componentId).keySeq().toArray(),
      ...InstalledComponentsStore.getComponentConfigurations(KEBOOLA_EX_SAMPLE_DATA)
        .filter((config) => {
          return config.getIn(['configuration', 'parameters', 'componentId']) === componentId;
        })
        .keySeq()
        .toArray(),
    ];

    if (configIds.length === 0) {
      return Promise.resolve();
    }

    dispatcher.handleViewAction({
      type: ActionTypes.CONFIG_LATEST_JOB_LOAD_START,
      componentId,
    });
    return api
      .getConfigurationsJobs(componentId, configIds)
      .then((jobs) => {
        dispatcher.handleViewAction({
          type: ActionTypes.CONFIG_LATEST_JOB_LOAD_SUCCESS,
          componentId,
          jobs,
        });
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: ActionTypes.CONFIG_LATEST_JOB_LOAD_ERROR,
          componentId,
        });

        throw error;
      });
  },

  loadLatestJobsForConfigurations(componentId) {
    if (JobsStore.getIsLoadingComponentJobs(componentId)) {
      return Promise.resolve();
    }

    if (!JobsStore.getLatestConfigJobs(componentId).isEmpty()) {
      this.loadLatestJobsForConfigurationsForce(componentId);
      return Promise.resolve();
    }

    return this.loadLatestJobsForConfigurationsForce(componentId);
  },

  reloadLatestJobForAllConfigurations(type) {
    if (!JobsStore.getLatestConfigJobs().isEmpty()) {
      this.reloadLatestJobForAllConfigurationsForce(type);
      return Promise.resolve();
    }

    return this.reloadLatestJobForAllConfigurationsForce(type);
  },

  reloadLatestJobForAllConfigurationsForce(type) {
    return api.getLatestJobForAllConfigurations(type).then((jobs) => {
      dispatcher.handleViewAction({
        type: ActionTypes.ALL_CONFIG_LATEST_JOB_LOAD_SUCCESS,
        jobs,
      });
    });
  },

  loadJob(id) {
    if (JobsStore.getIsJobLoaded(id)) {
      this.loadJobForce(id);
      return Promise.resolve();
    }
    return this.loadJobForce(id);
  },

  loadJobForce(id) {
    dispatcher.handleViewAction({ type: ActionTypes.JOB_LOAD, id });
    return api.getJobDetail(id).then((data) => {
      dispatcher.handleViewAction({ type: ActionTypes.JOB_LOAD_SUCCESS, job: data });
    });
  },

  createJob(componentId, params, mode) {
    return api.createJob(componentId, params, mode);
  },

  createProductionJob(componentId, params, mode) {
    return api.createProductionJob(componentId, params, mode);
  },

  terminateJob(id) {
    const waitForTermination = () => {
      return Promise.delay(2000)
        .then(() => api.getJobDetail(id))
        .then((job) => {
          if (JOB_RUNNING_STATUSES.includes(job.status)) {
            return waitForTermination();
          }

          return Promise.resolve();
        });
    };

    dispatcher.handleViewAction({ type: ActionTypes.JOB_TERMINATE, id });
    return api
      .terminateJob(id)
      .then(waitForTermination)
      .then(() => {
        dispatcher.handleViewAction({ type: ActionTypes.JOB_TERMINATE_SUCCESS, id });
      })
      .catch((error) => {
        dispatcher.handleViewAction({ type: ActionTypes.JOB_TERMINATE_ERROR, id });

        if (error?.response?.status !== HTTP_STATUS_CODE_BAD_REQUEST) {
          throw error;
        }
      });
  },

  retryJobWithLargerBackend(job, realConfigData) {
    const configData = job.get('configData', Map()).isEmpty()
      ? realConfigData
      : job.get('configData');

    return InstalledComponentsActionCreators.runComponent({
      component: job.get('component'),
      configRowIds: job.get('configRowIds'),
      method: job.get('mode'),
      tag: job.get('tag'),
      branchId: job.get('branchId'),
      variableValuesData: job.get('variableValuesData'),
      variableValuesId: job.get('variableValuesId'),
      data: {
        config: job.get('config'),
        configData: configData.setIn(
          ['runtime', 'backend', 'type'],
          getLargerBackendSize(job.get('component'), getCurrentBackendSize(job)),
        ),
      },
      notify: false,
    }).then(({ id }) => {
      RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, {
        jobId: id,
      });
    });
  },

  loadComponentConfigurationLatestJobs(componentId, configurationId, rowId) {
    dispatcher.handleViewAction({
      type: ActionTypes.JOBS_LATEST_LOAD_START,
      componentId,
      configurationId,
    });
    return Promise.resolve()
      .then(() => {
        const params = {
          component: componentId,
          config: configurationId,
          ...(componentId === KEBOOLA_ORCHESTRATOR
            ? { limit: JOBS_LIMIT_FOR_GRAPH, type: JOBS_TYPES.ORCHESTRATION_CONTAINER }
            : { limit: JOBS_LIMIT }),
        };

        if (!rowId) {
          return api.getJobs(params);
        }

        // we can not reliably load last 3 job for specific row with single api call, so we have to do two
        return Promise.all([
          api.getJobs(params),
          api.getJobs({ ...params, configRowIds: [rowId] }),
        ]).then(([jobs, rowJobs]) =>
          [
            ...rowJobs,
            ...jobs.filter((job) => !rowJobs.some((rowJob) => rowJob.id === job.id)),
          ].sort((jobA, jobB) => Number(jobB.id) - Number(jobA.id)),
        );
      })
      .then((jobs) => {
        const anyJobJustFinished = jobs
          .filter((job) => JOB_FINISHED_STATUSES.includes(job.status))
          .some((job) => date().diff(date(job.endTime), 'seconds') < 15);

        if (anyJobJustFinished) {
          StorageActionCreators.loadBucketsAndTablesForce();
        }

        dispatcher.handleViewAction({
          type: ActionTypes.JOBS_LATEST_LOAD_SUCCESS,
          componentId,
          configurationId,
          jobs,
        });
        return null;
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: ActionTypes.JOBS_LATEST_LOAD_ERROR,
          componentId,
          configurationId,
        });
        throw e;
      });
  },
};
