import React from 'react';
import { Button, ButtonToolbar, MenuItem, SplitButton } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames } from '@/modules/data-catalog/constants';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import DisableSharingModal from './DisableSharingModal';
import EditSharingModal from './EditSharingModal';
import ForceUnlinkModal from './ForceUnlinkModal';

const MySharedBucketHeaderButton = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    return {
      router: RoutesStore.getRouter(),
      sapiToken: ApplicationStore.getSapiToken(),
      bucket: StorageBucketsStore.getBucket(RoutesStore.getCurrentRouteParam('bucketId')),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
    };
  },

  getInitialState() {
    return {
      showDisableSharingModal: false,
      showForceUnlinkModal: false,
      showEditSharingModal: false,
    };
  },

  render() {
    if (!this.state.bucket || !canManageSharedBucket(this.state.sapiToken)) {
      return null;
    }

    return (
      <ButtonToolbar>
        <Button
          onClick={() => {
            this.setState({ showDisableSharingModal: true });
          }}
          bsStyle="danger"
        >
          Disable sharing
        </Button>
        <SplitButton
          id="shared-bucket-detail-edit-sharing-button"
          title="Edit sharing"
          pullRight
          onClick={() => {
            this.setState({ showEditSharingModal: true });
          }}
        >
          <MenuItem
            onSelect={() => {
              this.setState({ showForceUnlinkModal: true });
            }}
            disabled={this.state.bucket.get('linkedBy', List()).count() === 0}
          >
            Force unlink
          </MenuItem>
        </SplitButton>
        <EditSharingModal
          show={this.state.showEditSharingModal}
          closeModalFn={() => {
            this.setState({ showEditSharingModal: false });
          }}
          bucket={this.state.bucket}
          sapiToken={this.state.sapiToken}
          availableUsersOptions={this.state.availableUsersOptions}
          availableProjectsOptions={this.state.availableProjectsOptions}
        />
        <DisableSharingModal
          show={this.state.showDisableSharingModal}
          closeModalFn={() => {
            this.setState({ showDisableSharingModal: false });
          }}
          onUnshare={() => {
            this.state.router.transitionTo(routeNames.SHARED_FROM_THIS_PROJECT);
            return null;
          }}
          sapiToken={this.state.sapiToken}
          bucket={this.state.bucket}
          urlTemplates={this.state.urlTemplates}
        />
        <ForceUnlinkModal
          show={this.state.showForceUnlinkModal}
          closeModalFn={() => {
            this.setState({ showForceUnlinkModal: false });
          }}
          bucket={this.state.bucket}
          urlTemplates={this.state.urlTemplates}
          sapiToken={this.state.sapiToken}
        />
      </ButtonToolbar>
    );
  },
});

export default MySharedBucketHeaderButton;
