import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position, useUpdateNodeInternals } from '@xyflow/react';
import classNames from 'classnames';

import { COLUMNS_CHUNK_SIZE } from '@/modules/lineage/components/constants';
import type { OnSetShownHandles } from '@/modules/lineage/rfTypes';
import FilterPanel from '@/react/common/FilterPanel';
import MarkedText from '@/react/common/MarkedText';
import Truncated from '@/react/common/Truncated';
import matchByWords from '@/utils/matchByWords';
import Pagination from './Pagination';

type Props = {
  nodeId: string;
  columns: string[];
  onSetShownHandles: OnSetShownHandles;
};

const columnHandleClassName = 'tw-relative tw-float-right tw-self-center tw-invisible';

const Columns = ({ nodeId, onSetShownHandles, columns }: Props) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const [show, setShow] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>([]);

  const updateVisibleColumns = React.useCallback(
    (columns: string[], search: string, page: number) => {
      const newVisibleColumns = columns
        .filter((column) => matchByWords(column, search))
        .slice((page - 1) * COLUMNS_CHUNK_SIZE, page * COLUMNS_CHUNK_SIZE);

      setVisibleColumns(newVisibleColumns);
      onSetShownHandles(nodeId, newVisibleColumns);
      updateNodeInternals(nodeId);
    },
    [nodeId, setVisibleColumns, onSetShownHandles, updateNodeInternals],
  );

  const filteredColumns = React.useMemo(() => {
    return columns.filter((column) => matchByWords(column, searchValue));
  }, [columns, searchValue]);

  const otherColumns = React.useMemo(() => {
    return columns.filter((column) => !visibleColumns.includes(column));
  }, [columns, visibleColumns]);

  if (!columns.length) {
    return null;
  }

  return (
    <>
      <Button
        bsStyle="link"
        className={classNames(
          'tw-group/columnsToggle tw-flex tw-w-full tw-justify-between tw-rounded-none !tw-bg-white tw-px-2 tw-py-3 tw-text-xs tw-font-normal tw-normal-case tw-leading-4 tw-tracking-normal hover:!tw-bg-neutral-100 hover:tw-no-underline focus:tw-no-underline',
          { 'tw-rounded-b-md': !show },
        )}
        onClick={() => {
          setShow(!show);
          updateVisibleColumns(show ? [] : columns, searchValue, currentPage);
        }}
      >
        {columns.length} columns
        <FontAwesomeIcon
          icon={show ? 'chevron-up' : 'chevron-down'}
          fixedWidth
          className="tw-text-neutral-400 group-hover/columnsToggle:tw-text-secondary-600"
        />
      </Button>
      {show && (
        <div data-bottom-content className="nowheel tw-relative tw-rounded-b-md tw-bg-white">
          <FilterPanel
            asBox={false}
            searchBarClassName="as-input condensed nodrag tw-mb-0 tw-p-1"
            query={searchValue}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchValue(value);
              updateVisibleColumns(columns, value, 1);
            }}
          />
          {visibleColumns.length === 0 ? (
            <p className="tw-m-0 tw-px-2 tw-pb-2">No columns found</p>
          ) : (
            <>
              {visibleColumns.map((column) => {
                return (
                  <div key={column} className="tw-flex tw-text-neutral-400">
                    <Handle
                      id={`${column}-target`}
                      className={classNames(columnHandleClassName, '-tw-left-0.5')}
                      type="target"
                      position={Position.Left}
                    />
                    <div className="tw-flex tw-w-full tw-flex-col">
                      <Truncated text={<MarkedText source={column} mark={searchValue} />} />
                      <div className="separator tw-mx-0 tw-my-1" />
                    </div>
                    <Handle
                      id={`${column}-source`}
                      className={classNames(columnHandleClassName, '-tw-right-0.5')}
                      type="source"
                      position={Position.Right}
                    />
                  </div>
                );
              })}
              <Pagination
                onClick={(page) => {
                  setCurrentPage(page);
                  updateVisibleColumns(columns, searchValue, page);
                }}
                currentPage={currentPage}
                allPagesNum={Math.ceil(filteredColumns.length / COLUMNS_CHUNK_SIZE)}
                showPagination={filteredColumns.length > COLUMNS_CHUNK_SIZE}
              />
            </>
          )}
        </div>
      )}
      {otherColumns.map((column) => {
        return (
          <div key={column} className="tw-invisible">
            <Handle id={`${column}-target`} type="target" position={Position.Left} />
            <Handle id={`${column}-source`} type="source" position={Position.Right} />
          </div>
        );
      })}
    </>
  );
};

export default Columns;
