import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';

type Props = {
  isCollapsed: boolean;
  isDisabled?: boolean;
  onToggle?: () => void;
  entity?: string;
};

const CollapseButton = ({ isCollapsed, isDisabled, onToggle, entity }: Props) => {
  let tooltip = isCollapsed ? 'More Details' : 'Less Details';

  if (entity) {
    tooltip = `${isCollapsed ? 'Expand' : 'Collapse'} ${entity}`;
  }

  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      forceHide={isDisabled}
      triggerClassName="btn-collapse-trigger"
    >
      <Button
        bsStyle="link"
        className={classnames('btn-collapse btn-link-inline no-underline f-16 text-muted', {
          'btn-disabled not-allowed': isDisabled,
        })}
        onClick={() => !isDisabled && onToggle?.()}
      >
        <FontAwesomeIcon icon={['far', isCollapsed ? 'angle-down' : 'angle-up']} />
      </Button>
    </Tooltip>
  );
};

export default CollapseButton;
