import React from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import { shouldAllowRunFlow } from '@/modules/flows/helpers';
import { getRealComponentId } from './helpers';

type Props = {
  configuration: Map<string, any>;
  component: Map<string, any>;
  hasFlows: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const RunButton = (props: Props) => {
  if (props.component.get('flags', List()).includes('excludeRun')) {
    return null;
  }

  const runTitle = (component: Map<string, any>): string => {
    if (component.get('type') === componentTypes.TRANSFORMATION) {
      return 'Run Transformation';
    }

    if (component.get('id') === KEBOOLA_ORCHESTRATOR) {
      return props.hasFlows ? 'Run Flow' : 'Run Orchestration';
    }

    return 'Run Component';
  };

  let additionalProps = {};

  if (
    props.component.get('id') === KEBOOLA_ORCHESTRATOR &&
    !shouldAllowRunFlow(props.configuration.getIn(['configuration', 'tasks'], List()))
  ) {
    additionalProps = {
      disabled: true,
      disabledReason: `${
        props.hasFlows ? 'Flow' : 'Orchestration'
      } cannot run without any configured and enabled tasks.`,
    };
  }

  return (
    <RunComponentButton
      mode="menuitem"
      title={runTitle(props.component)}
      componentId={getRealComponentId(props.configuration, props.component)}
      runParams={() => ({ config: props.configuration.get('id') })}
      onKeyDown={props.onKeyDown}
      {...additionalProps}
    >
      You are about to run the configuration <strong>{props.configuration.get('name')}</strong>.
    </RunComponentButton>
  );
};

export default RunButton;
