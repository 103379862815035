import React from 'react';

import { JOBS_STATUS } from '@/modules/queue/constants';
import Duration from '@/react/common/Duration';

const JobDuration = ({
  status,
  startTime,
  endTime,
  showIcon = false,
}: {
  status?: string;
  startTime?: string;
  endTime?: string;
  showIcon?: boolean;
}) => {
  // sometimes there's no endTime for job which ended with error
  // https://keboola.atlassian.net/browse/KBC-147
  if (status === JOBS_STATUS.ERROR && !endTime) {
    return <span>N/A</span>;
  }

  return <Duration startTime={startTime} endTime={endTime} showIcon={showIcon} />;
};

export default JobDuration;
