import { fromJS } from 'immutable';

export default fromJS({
  NUMERIC: {
    name: 'NUMERIC',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '38,37',
  },
  INT: {
    name: 'INT',
    basetype: 'INTEGER',
    size: false,
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  NVARCHAR: {
    name: 'NVARCHAR',
    basetype: 'STRING',
    size: true,
    maxLength: 4000,
  },
  BIT: {
    name: 'BIT',
    basetype: 'BOOLEAN',
    size: false,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  DATETIME2: {
    name: 'DATETIME2',
    basetype: 'TIMESTAMP',
    size: false,
  },
});
