import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { features as componentFeatures } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareBlocks } from '@/modules/components/react/components/generic/code-blocks/helpers';
import { linkConfiguration } from '@/modules/model-services/Actions';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import Select from '@/react/common/Select';

const INITIAL_STATE = {
  showModal: false,
  isLoading: false,
  newTransformation: '',
  newTransformationType: '',
  selectedTransformation: '',
};

class LinkConfigurationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    if (!this.props.model.hasIn(['deployment', 'url']) || this.props.transformations.isEmpty()) {
      return null;
    }

    return (
      <>
        <RowActionMenuItem onSelect={() => this.setState({ showModal: true })}>
          <FontAwesomeIcon icon="link" fixedWidth />
          Link transformation
        </RowActionMenuItem>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Link New or Existing Transformation</Modal.Title>
            <ModalIcon icon="link" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Select existing transformation</ControlLabel>
              <Select
                autoFocus
                options={this.getExistingTransformationOptions()}
                value={this.state.selectedTransformation}
                onChange={(value) =>
                  this.setState({
                    selectedTransformation: value,
                    newTransformation: '',
                    newTransformationType: '',
                  })
                }
                placeholder="Select transformation"
              />
            </FormGroup>
            <div className="separator">Or</div>
            <FormGroup>
              <ControlLabel>New transformation name</ControlLabel>
              <FormControl
                type="text"
                value={this.state.newTransformation}
                onChange={({ target }) =>
                  this.setState({ newTransformation: target.value, selectedTransformation: '' })
                }
                placeholder="Type name"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Transformation type</ControlLabel>
              <Select
                options={this.getTypeOptions()}
                value={this.state.newTransformationType}
                onChange={(value) =>
                  this.setState({ newTransformationType: value, selectedTransformation: '' })
                }
                placeholder="Select type"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.state.isLoading ? 'Linking transformation...' : 'Link transformation'}
              isSaving={this.state.isLoading}
              onSave={this.handleSubmit}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  getExistingTransformationOptions() {
    const linkedConfigurations = this.props.model
      .getIn(['deployment', 'linkedConfigurations'], List())
      .toMap()
      .mapKeys((key, link) => `${link.get('componentId')}.${link.get('configurationId')}`);

    return this.props.transformations
      .filter((component) =>
        component.get('features', List()).includes(componentFeatures.MLFLOW_ARTIFACTS_ACCESS),
      )
      .map((component) => {
        return component.update('configurations', Map(), (configurations) => {
          return configurations.filter((config) => {
            return !linkedConfigurations.has(`${component.get('id')}.${config.get('id')}`);
          });
        });
      })
      .filter((component) => !component.get('configurations', Map()).isEmpty())
      .map((component) => {
        return {
          label: component.get('name'),
          options: component
            .get('configurations')
            .map((config) => ({
              value: JSON.stringify({
                componentId: component.get('id'),
                configurationId: config.get('id'),
              }),
              label: config.get('name'),
            }))
            .toArray(),
        };
      })
      .toArray();
  }

  getTypeOptions() {
    return this.props.allowedTransformationComponents
      .filter((component) =>
        component.get('features', List()).includes(componentFeatures.MLFLOW_ARTIFACTS_ACCESS),
      )
      .map((component) => ({ value: component.get('id'), label: component.get('name') }))
      .toArray();
  }

  handleSubmit() {
    if (this.state.selectedTransformation) {
      return this.linkExistingTransformation();
    }

    return this.createAndLinkNewTransformation();
  }

  linkExistingTransformation() {
    this.setState({ isLoading: true });
    return linkConfiguration(
      this.props.model.getIn(['deployment', 'id']),
      JSON.parse(this.state.selectedTransformation),
    ).finally(() => this.setState(INITIAL_STATE));
  }

  createAndLinkNewTransformation() {
    this.setState({ isLoading: true });
    return InstalledComponentsActionCreators.createConfiguration(this.state.newTransformationType, {
      name: this.state.newTransformation,
      configuration: JSON.stringify({ parameters: { blocks: prepareBlocks() } }),
    })
      .then((config) => {
        return linkConfiguration(this.props.model.getIn(['deployment', 'id']), {
          componentId: this.state.newTransformationType,
          configurationId: config.id,
        });
      })
      .finally(() => this.setState(INITIAL_STATE));
  }

  isDisabled() {
    if (this.state.isLoading) {
      return true;
    }

    if (this.state.selectedTransformation) {
      return false;
    }

    return !this.state.newTransformation || !this.state.newTransformationType;
  }

  hideModal() {
    this.setState(INITIAL_STATE);
  }
}

LinkConfigurationButton.propTypes = {
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  transformations: PropTypes.instanceOf(Map).isRequired,
  model: PropTypes.instanceOf(Map).isRequired,
};

export default LinkConfigurationButton;
