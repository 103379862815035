import React from 'react';
import { URLS } from '@keboola/constants';
import { Badge } from 'design';
import { List, Map } from 'immutable';

import { KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES } from '@/constants/componentIds';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import createRoute from '@/modules/configurations/utils/createRoute';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import serviceAccountAdapter from '@/modules/wr-google-bigquery-v2/adapters/serviceAccount';
import ServiceAccountSection from '@/modules/wr-google-bigquery-v2/react/components/ServiceAccountSection';
import getColumns from './actions/getColumns';
import getTables from './actions/getTables';
import fileOutputSettingsAdapter from './adapters/fileOutputSettings';
import incrementalFetchingAdapter from './adapters/incrementalFetching';
import loadingOptionsAdapter from './adapters/loadingOptions';
import queryAdapter from './adapters/query';
import rowConform from './adapters/rowConform';
import rowOnSave from './adapters/rowOnSave';
import sourceAdapter from './adapters/source';
import tableOutputSettingsAdapter from './adapters/tableOutputSettings';
import unloadAdapter from './adapters/unload';
import DataSourceSection from './react/components/DataSource';
import FileOutputSettingsSection from './react/components/FileOutputSettings';
import IncrementalFetchingSection from './react/components/IncrementalFetching';
import LoadingOptionsSection from './react/components/LoadingOptions';
import QuerySection from './react/components/Query';
import TableOutputSection from './react/components/TableOutput';
import UnloadSection from './react/components/Unload';

const routeSettings = (componentId) => ({
  componentId,
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'Google Service Account Key',
          contentComponent: ServiceAccountSection,
          options: {
            includeSaveButtons: true,
            additionalProps: {
              documentationLink: `${URLS.USER_DOCUMENTATION}/components/extractors/database/bigquery/#service-account`,
            },
          },
        }),
        onSave: serviceAccountAdapter.createConfiguration,
        onLoad: serviceAccountAdapter.parseConfiguration,
        isComplete: serviceAccountAdapter.isComplete,
      },
      {
        render: CollapsibleSection({
          title: 'Unload Configuration',
          contentComponent: UnloadSection,
          options: { includeSaveButtons: true },
        }),
        onSave: unloadAdapter.createConfiguration,
        onLoad: unloadAdapter.parseConfiguration,
        isComplete: unloadAdapter.isComplete,
      },
    ],
  },
  row: {
    hasState: true,
    onSave: rowOnSave,
    onConform: rowConform,
    actions: [getTables, getColumns],
    sections: [
      {
        render: DataSourceSection,
        onSave: sourceAdapter.createConfiguration,
        onLoad: sourceAdapter.parseConfiguration,
        onCreate: sourceAdapter.createEmptyConfiguration,
      },
      componentId === KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES
        ? {
            render: FileOutputSettingsSection,
            onSave: fileOutputSettingsAdapter.createConfiguration,
            onLoad: fileOutputSettingsAdapter.parseConfiguration,
            onCreate: fileOutputSettingsAdapter.createEmptyConfiguration,
          }
        : {
            render: TableOutputSection,
            onSave: tableOutputSettingsAdapter.createConfiguration,
            onLoad: tableOutputSettingsAdapter.parseConfiguration,
            onCreate: tableOutputSettingsAdapter.createEmptyConfiguration,
          },
      {
        render: IncrementalFetchingSection,
        onSave: incrementalFetchingAdapter.createConfiguration,
        onLoad: incrementalFetchingAdapter.parseConfiguration,
        onCreate: incrementalFetchingAdapter.createEmptyConfiguration,
      },
      componentId !== KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES && {
        render: LoadingOptionsSection,
        onSave: loadingOptionsAdapter.createConfiguration,
        onLoad: loadingOptionsAdapter.parseConfiguration,
        onCreate: loadingOptionsAdapter.createEmptyConfiguration,
      },
      {
        render: QuerySection,
        onSave: queryAdapter.createConfiguration,
        onLoad: queryAdapter.parseConfiguration,
        onCreate: queryAdapter.createEmptyConfiguration,
      },
    ].filter(Boolean),
    columns:
      componentId === KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES
        ? [
            {
              name: 'File Name',
              type: columnTypes.VALUE,
              value: (row) => row.getIn(['parameters', 'query', 'fileName'], ''),
            },
          ]
        : [
            {
              name: 'Source',
              type: columnTypes.VALUE,
              value: function (row) {
                const query = row.getIn(['parameters', 'query'], Map());

                if (query.get('query')) {
                  return <Badge text="SQL" />;
                }

                if (query.get('datasetId') && query.get('tableId')) {
                  return `${query.get('datasetId')}.${query.get('tableId')}`;
                }

                return '';
              },
            },
            {
              name: 'Destination',
              type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
              value: function (row) {
                return row.getIn(['parameters', 'query', 'tableName'], '');
              },
            },
            {
              name: 'Load Options',
              type: columnTypes.VALUE,
              value: function (row) {
                const primaryKeys = row.getIn(['parameters', 'query', 'primaryKey'], List());

                return (
                  <>
                    {!primaryKeys.isEmpty() && (
                      <>
                        <small>Primary Key:</small> {primaryKeys.join(', ')}
                        <br />
                      </>
                    )}
                    {row.getIn(['parameters', 'query', 'incremental']) && (
                      <Badge text="Incremental" />
                    )}
                  </>
                );
              },
            },
          ],
  },
});

export default function routes(componentsIds) {
  return componentsIds.map((componentId) => {
    return createRoute(routeSettings(componentId));
  });
}
