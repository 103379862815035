import React from 'react';

import DataLineageReactFlow from '@/modules/lineage/components/DataLineageReactFlow';
import type { EdgeData, OnSelectProject, ProjectData } from '@/modules/lineage/rfTypes';
import type { ProjectsLineage } from '@/modules/lineage/types';

const mapDataToGraph = (data: ProjectsLineage) => {
  const nodes: ProjectData[] = Object.values(data.projects).map((value) => {
    return { ...value, type: 'project' };
  });

  const edges: EdgeData[] = data.relations.map((relation) => {
    return {
      id: `${relation.sourceFqid}-${relation.targetFqid}`,
      source: relation.sourceFqid,
      target: relation.targetFqid,
      sourceHandle: 'main',
      targetHandle: 'main',
    };
  });

  return { nodes, edges };
};

const OrganizationDetailLineage = ({
  onSelectProject,
  data,
}: {
  onSelectProject: OnSelectProject;
  data: ProjectsLineage;
}) => {
  const graph = React.useMemo(() => mapDataToGraph(data), [data]);

  return (
    <DataLineageReactFlow
      inModal
      onSelectProject={onSelectProject}
      nodesData={graph.nodes}
      edgesData={graph.edges}
    />
  );
};

export default OrganizationDetailLineage;
