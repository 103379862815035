import React from 'react';
import { Map } from 'immutable';

import { type ACTIVE_MENU, MENUS } from '@/modules/ex-generic/constants';
import Endpoints from './Endpoints';
import MenuItem from './MenuItem';

const SidebarMenu = (props: {
  menu: ACTIVE_MENU;
  setMenu: (menu: ACTIVE_MENU) => void;
  parameters: Map<string, any>;
  readOnly: boolean;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const noEndpoint = props.parameters.getIn(['config', 'jobs'], Map()).isEmpty();

  const routeMenuLink = (menu: ACTIVE_MENU, label: string, disabledReason?: string) => {
    return (
      <MenuItem
        onClick={() => props.setMenu(menu)}
        isActive={props.menu === menu}
        disabledReason={disabledReason}
      >
        {label}
      </MenuItem>
    );
  };

  return (
    <div className="box tw-p-4 tw-pb-3">
      <h4 className="tw-pl-2 tw-text-xs tw-font-bold tw-uppercase tw-tracking-widest">
        API Settings
      </h4>
      <div className="tw-flex tw-flex-col tw-gap-1">
        {routeMenuLink(MENUS.BASE, 'Base Configuration')}
        {routeMenuLink(MENUS.ADVANCED, 'Advanced HTTP Settings')}
        {routeMenuLink(MENUS.PAGINATIONS, 'Paginations', noEndpoint ? 'No endpoint defined' : '')}
      </div>
      <hr />
      <Endpoints
        menu={props.menu}
        setMenu={props.setMenu}
        parameters={props.parameters}
        readOnly={props.readOnly}
        onSave={props.onSave}
      />
      <hr />
      <h4 className="tw-pl-2 tw-text-xs tw-font-bold tw-uppercase tw-tracking-widest">
        User Configuration
      </h4>
      <div className="tw-flex tw-flex-col tw-gap-1">
        {routeMenuLink(MENUS.PARAMETERS, 'User Parameters')}
        {routeMenuLink(MENUS.USER_DATA, 'User Data')}
      </div>
    </div>
  );
};

export default SidebarMenu;
