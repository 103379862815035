import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Pagination = ({
  onClick,
  currentPage,
  showPagination,
  allPagesNum,
}: {
  onClick: (newPage: number) => void;
  currentPage: number;
  showPagination: boolean;
  allPagesNum: number;
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-rounded-b-md tw-px-2 tw-font-medium tw-text-neutral-400">
      {showPagination ? (
        <>
          <Button
            bsStyle="link"
            className={classNames('btn-link-inline text-muted !tw-my-4 !tw-pr-4', {
              'disabled btn-disabled': currentPage === 1,
            })}
            onClick={() => {
              if (currentPage !== 1) {
                onClick(currentPage - 1);
              }
            }}
          >
            <FontAwesomeIcon icon="chevron-left" fontSize={16} />
          </Button>
          <span>
            {currentPage} of {allPagesNum}
          </span>
          <Button
            bsStyle="link"
            className={classNames('btn-link-inline text-muted !tw-py-4 !tw-pl-4', {
              'disabled btn-disabled': currentPage === allPagesNum,
            })}
            onClick={() => {
              if (currentPage !== allPagesNum) {
                onClick(currentPage + 1);
              }
            }}
          >
            <FontAwesomeIcon icon="chevron-right" fontSize={16} />
          </Button>
        </>
      ) : (
        <div className="tw-p-4" />
      )}
    </div>
  );
};

export default Pagination;
