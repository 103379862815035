import React from 'react';
import type { EdgeProps } from '@xyflow/react';
import { BaseEdge, BezierEdge, useStore } from '@xyflow/react';

import { zoomOutSelector } from '@/modules/lineage/helpers';

export default function CommonEdge(props: EdgeProps) {
  const simpleView = useStore(zoomOutSelector);

  if (simpleView) {
    return null;
  }

  const { sourceX, sourceY, targetX, targetY, markerEnd } = props;

  // self connected node
  if (props.source === props.target) {
    const radiusX = (sourceX - targetX) * 0.6;
    const radiusY = 80;
    const path = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
      targetX + 2
    } ${targetY}`;
    return <BaseEdge {...props} path={path} markerEnd={markerEnd} />;
  }

  const radiusX = (sourceX - targetX) * 0.6;
  const radiusY = 80;

  // If we find that source is more right to target on x axis, then we make eliptic line to not show
  // it behind the nodes.
  const newRadius = targetY > sourceY ? targetY - sourceY : radiusY;

  const edgePath =
    sourceX > targetX
      ? `M ${sourceX - 5} ${sourceY} A ${radiusX} ${newRadius < 200 ? 200 : newRadius} 0 1 0 ${
          targetX + 2
        } ${targetY}`
      : null;

  return edgePath ? (
    <BaseEdge {...props} path={edgePath} markerEnd={markerEnd} />
  ) : (
    <BezierEdge {...props} />
  );
}
