import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { getComponentFields } from '@/modules/wr-db/templates/credentialsFields';
import CredentialsBox from '@/react/common/CredentialsBox';
import OnetimePasswordWarning from './OnetimePasswordWarning';

class ProvisionedCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resettingPassword: false,
    };

    this.resetPassword = this.resetPassword.bind(this);
  }

  render() {
    let rows = Map();
    getComponentFields(this.props.componentId).forEach((field) => {
      if (field.name === '#password') {
        if (this.props.credentials.has('password')) {
          rows = rows.set(field.label, {
            protected: true,
            text: this.props.credentials.get('password'),
          });
        }
      } else {
        rows = rows.set(
          field.label,
          field.protected
            ? {
                protected: true,
                text: this.props.credentials.get(field.name),
              }
            : this.props.credentials.get(field.name),
        );
      }
    });

    return (
      <>
        <CredentialsBox rows={rows} />
        {!this.props.readOnly && this.props.isWorkspaceCredentials && (
          <OnetimePasswordWarning
            hasPassword={this.props.credentials.has('password')}
            forgetPasswordFn={this.props.forgetPasswordFn}
            resetPasswordFn={this.resetPassword}
            isResetting={this.state.resettingPassword}
          />
        )}
      </>
    );
  }

  resetPassword() {
    this.setState({ resettingPassword: true });

    return this.props
      .resetWorkspacePasswordFn()
      .finally(() => this.setState({ resettingPassword: false }));
  }
}

ProvisionedCredentials.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  componentId: PropTypes.string.isRequired,
  credentials: PropTypes.instanceOf(Map).isRequired,
  isWorkspaceCredentials: PropTypes.bool.isRequired,
  resetWorkspacePasswordFn: PropTypes.func.isRequired,
  forgetPasswordFn: PropTypes.func.isRequired,
};

export default ProvisionedCredentials;
