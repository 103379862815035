import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import { APP_ROUTE } from '@/constants/routeNames';
import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import { routeNames as componentRoutes } from '@/modules/components-directory/constants';
import { routeNames as dataAppsRoutes } from '@/modules/data-apps/constants';
import { routeNames as dataCatalogRouteNames } from '@/modules/data-catalog/constants';
import { routeNames as flowRoutes } from '@/modules/flows/constants';
import { routeNames as legacyOrchestrationRoutes } from '@/modules/orchestrations/Constants';
import { routeNames as orchestrationRoutes } from '@/modules/orchestrations-v2/constants';
import { routeNames as queueRoutes } from '@/modules/queue/constants';
import { routeNames as workspacesRouteNames } from '@/modules/sandboxes/Constants';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import { routeNames as storageRoutes } from '@/modules/storage/constants';
import { routeNames as templateRoutes } from '@/modules/templates/constants';
import { routeNames as legacyTransformationsRouteNames } from '@/modules/transformations/Constants';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import Link from '@/react/common/RouterLink';
import RoutesStore from '@/stores/RoutesStore';
import LinkMenuItem from './LinkMenuItem';

const Navigation = createReactClass({
  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    showNewTransformations: PropTypes.bool.isRequired,
    hideOldTransformations: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasPayAsYouGo: PropTypes.bool.isRequired,
    isDevModeActive: PropTypes.bool.isRequired,
    hasConfigurations: PropTypes.bool.isRequired,
    hasFlowsOnly: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    hasTemplates: PropTypes.bool.isRequired,
    hasInvalidCustomBackend: PropTypes.bool.isRequired,
    isDemoPreview: PropTypes.bool.isRequired,
    hasDataApps: PropTypes.bool.isRequired,
  },

  render() {
    const router = RoutesStore.getRouter();

    return (
      <div className="navbar-collapse collapse">
        <div className="navbar">
          <ul className="nav navbar-nav">
            <li>
              <Link to={APP_ROUTE} className="navbar-link" onlyActiveOnIndex>
                <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="gauge" />
                <span>Dashboard</span>
              </Link>
            </li>
            {this.renderFlows(router)}
            {this.renderComponents(router)}
            {this.renderUseCases(router)}
            {this.renderDataCatalog(router)}
            <li>
              <Link
                to={storageRoutes.ROOT}
                className={classnames('navbar-link', {
                  'tw-opacity-60': this.props.hasInvalidCustomBackend,
                })}
                disabled={this.props.hasInvalidCustomBackend}
              >
                <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="warehouse" />
                <span>Storage</span>
              </Link>
            </li>
            {this.renderOrchestrations()}
            {this.renderTransformationsMenu(router)}
            {this.renderWorkspaces(router)}
            <li>
              <Link
                to={this.props.hasNewQueue ? queueRoutes.JOBS : 'jobs'}
                className={classnames('navbar-link', {
                  'tw-opacity-60': this.props.hasInvalidCustomBackend,
                })}
                disabled={this.props.hasInvalidCustomBackend}
              >
                <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="circle-play" />
                <span>Jobs</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  },

  renderComponents(router) {
    if (this.props.hasDataApps && !this.props.isDevModeActive) {
      return (
        <Dropdown
          id="navbar-components"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={this.props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={classnames('navbar-link', {
              active: router.isActive(componentRoutes.ROOT) || router.isActive(dataAppsRoutes.ROOT),
              'btn-disabled': this.props.hasInvalidCustomBackend,
            })}
          >
            <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="layer-group" />
            Components
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={
                this.props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT
              }
              active={router.isActive(componentRoutes.ROOT)}
            >
              <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="layer-group" />
              Components
            </LinkMenuItem>
            <LinkMenuItem to={dataAppsRoutes.ROOT} active={router.isActive(dataAppsRoutes.ROOT)}>
              <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="browser" />
              Data Apps
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <li>
        <Link
          to={this.props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT}
          disabled={this.props.hasInvalidCustomBackend}
          className={classnames('navbar-link', {
            active: router.isActive(componentRoutes.ROOT),
            'tw-opacity-60': this.props.hasInvalidCustomBackend,
          })}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="layer-group" />
          <span>Components</span>
        </Link>
      </li>
    );
  },

  renderFlows(router) {
    if (!this.props.hasFlows && !this.props.hasFlowsOnly) {
      return null;
    }

    if (this.props.hasFlowsOnly || this.props.isDemoPreview) {
      return (
        <li>
          <Link
            to={flowRoutes.ROOT}
            disabled={this.props.hasInvalidCustomBackend}
            className={classnames('navbar-link', {
              'tw-opacity-60': this.props.hasInvalidCustomBackend,
            })}
          >
            <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="bars-staggered" />
            <span>Flows</span>
          </Link>
        </li>
      );
    }

    return (
      <Dropdown
        id="navbar-flows"
        componentClass="li"
        rootCloseEvent="mousedown"
        disabled={this.props.hasInvalidCustomBackend}
      >
        <Dropdown.Toggle
          noCaret
          bsStyle="link"
          className={classnames('navbar-link', {
            active: router.isActive(flowRoutes.ROOT) || router.isActive(orchestrationRoutes.ROOT),
            'btn-disabled': this.props.hasInvalidCustomBackend,
          })}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          Flows
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LinkMenuItem to={flowRoutes.ROOT} active={router.isActive(flowRoutes.ROOT)}>
            <FontAwesomeIcon className="icon-addon-right" icon="bars-staggered" fixedWidth />
            Flows
          </LinkMenuItem>
          <LinkMenuItem
            to={orchestrationRoutes.ROOT}
            active={router.isActive(orchestrationRoutes.ROOT)}
          >
            <FontAwesomeIcon className="icon-addon-right" icon="window-restore" fixedWidth />
            Orchestrations
          </LinkMenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  },

  renderUseCases(router) {
    if (!this.props.hasTemplates || this.props.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <Link
          to={templateRoutes.ROOT}
          disabled={this.props.hasInvalidCustomBackend}
          className={classnames('navbar-link', {
            active:
              router.isActive(templateRoutes.ROOT) ||
              (router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
                [templateRoutes.ROOT, templateRoutes.TEMPLATES].includes(
                  RoutesStore.getRouterState().getIn(['location', 'query', 'd']),
                )),
            'tw-opacity-60': this.props.hasInvalidCustomBackend,
          })}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="book-open" />
          Templates
        </Link>
      </li>
    );
  },

  renderDataCatalog(router) {
    if (
      this.props.sapiToken.getIn(['owner', 'hasExasol'], false) ||
      this.props.sapiToken.getIn(['owner', 'hasTeradata'], false) ||
      this.props.hasPayAsYouGo ||
      this.props.isDevModeActive ||
      this.props.isDemoPreview
    ) {
      return null;
    }

    return (
      <li>
        <Link
          to={
            !canLoadSharedBuckets(this.props.sapiToken)
              ? dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT
              : dataCatalogRouteNames.SHARED_WITH_YOU
          }
          className={classnames('navbar-link', {
            active:
              router.isActive(dataCatalogRouteNames.SHARED_WITH_YOU) ||
              router.isActive(dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT),
            'tw-opacity-60': this.props.hasInvalidCustomBackend,
          })}
          disabled={this.props.hasInvalidCustomBackend}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="book-blank" />
          <span>Data Catalog</span>
        </Link>
      </li>
    );
  },

  renderOrchestrations() {
    if (
      this.props.hasFlows ||
      this.props.hasFlowsOnly ||
      (this.props.isDevModeActive && !this.props.hasNewQueue)
    ) {
      return null;
    }

    return (
      <li>
        <Link
          to={this.props.hasNewQueue ? orchestrationRoutes.ROOT : legacyOrchestrationRoutes.ROOT}
          className={classnames('navbar-link', {
            'tw-opacity-60': this.props.hasInvalidCustomBackend,
          })}
          disabled={this.props.hasInvalidCustomBackend}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          <span>Orchestrations</span>
        </Link>
      </li>
    );
  },

  renderTransformationsMenu(router) {
    if (this.props.showNewTransformations && !this.props.hideOldTransformations) {
      return (
        <Dropdown
          id="navbar-transformations"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={this.props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={classnames('navbar-link', {
              active:
                router.isActive('transformations') ||
                router.isActive(transformationsRouteNames.ROOT),
            })}
          >
            <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="window-restore" />
            Transformations
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={legacyTransformationsRouteNames.ROOT}
              active={router.isActive('transformations')}
            >
              <FontAwesomeIcon className="icon-addon-right" icon="window-restore" fixedWidth />
              Legacy Transformations
            </LinkMenuItem>
            <LinkMenuItem
              to={transformationsRouteNames.ROOT}
              active={router.isActive(transformationsRouteNames.ROOT)}
            >
              <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="window-restore" />
              Transformations
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (this.props.showNewTransformations) {
      return (
        <li>
          <Link
            to={transformationsRouteNames.ROOT}
            disabled={this.props.hasInvalidCustomBackend}
            className={classnames('navbar-link', {
              active:
                router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
                RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
                  transformationsRouteNames.ROOT,
              'tw-opacity-60': this.props.hasInvalidCustomBackend,
            })}
          >
            <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="window-restore" />
            <span>Transformations</span>
          </Link>
        </li>
      );
    }
  },

  renderWorkspaces(router) {
    if (!this.props.showNewTransformations) {
      return null;
    }

    return (
      <li>
        <Link
          to={workspacesRouteNames.WORKSPACES}
          disabled={this.props.hasInvalidCustomBackend}
          className={classnames('navbar-link', {
            active:
              router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
              RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
                workspacesRouteNames.WORKSPACES,
            'tw-opacity-60': this.props.hasInvalidCustomBackend,
          })}
        >
          <FontAwesomeIcon className="icon-addon-right" fixedWidth icon="box" />
          <span>Workspaces</span>
        </Link>
      </li>
    );
  },
});

export default Navigation;
