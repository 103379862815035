import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputGroup, Table, Well } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';

import SapiTableLink from '@/modules/components/react/components/StorageApiTableLink';
import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP } from '@/modules/storage/constants';
import { tableDisplayNameWithBucketAndStage, tableName } from '@/modules/storage/helpers';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import CreatedDate from '@/react/common/CreatedDate';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';
import { tableLabel } from '@/react/common/selectLabels';

class EventTrigger extends React.Component {
  render() {
    const selectedTables = this._getSelectedTables(this.props.selected);

    return (
      <>
        <div className="form-group flex-container flex-start">{this.renderAddTableForm()}</div>
        {!selectedTables.isEmpty() && (
          <>
            {this.renderTables(selectedTables)}
            {this.renderPeriodSelect()}
          </>
        )}
      </>
    );
  }

  renderAddTableForm() {
    return (
      <Select
        clearable={false}
        placeholder="Add tables"
        className="fill-space schedule-table-select"
        options={this._getTables()}
        onChange={this.props.onAddTable}
      />
    );
  }

  renderTables(tables) {
    return (
      <Well className="with-table">
        <Table>
          <thead>
            <tr>
              <th>Selected Tables</th>
              <th className="no-wrap">Last Import</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tables.valueSeq().map((table) => (
              <tr key={table.get('id')}>
                <td className="overflow-break-anywhere">
                  <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                  <SapiTableLink tableId={table.get('id')}>
                    <BucketStageLabel placement="left" stage={table.getIn(['bucket', 'stage'])} />
                    <DevBranchLabel bucket={table.get('bucket')} />
                    {tableName(table)}
                  </SapiTableLink>
                </td>
                <td className="no-wrap text-muted f-13">
                  <CreatedDate
                    createdTime={table.get('lastImportDate')}
                    fallback="Not yet imported"
                  />
                </td>
                <td className="pl-0 pr-0">
                  <Tooltip placement="top" tooltip="Remove table">
                    <Button
                      bsStyle="link"
                      className="text-muted"
                      onClick={() => this.props.onRemoveTable(table.get('id'))}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Well>
    );
  }

  renderPeriodSelect() {
    return (
      <div className="form-horizontal">
        <InputGroup className="input-period">
          <InputGroup.Addon>
            <InfoTooltip
              className="tw-ml-0 tw-mr-1.5"
              tooltip={
                <>
                  <p className="tooltip-title">Cooldown period</p>
                  <p>
                    If an event was triggered, it could only be triggered again after this period.
                  </p>
                </>
              }
            />
            Run max once per
          </InputGroup.Addon>
          <FormControl
            min="1"
            type="number"
            value={this.props.period}
            onChange={this.props.onChangePeriod}
          />
          <InputGroup.Addon>Mins</InputGroup.Addon>
        </InputGroup>
      </div>
    );
  }

  _getTables() {
    return filterProductionAndCurrentDevBranchTables(this.props.tables, this.props.buckets)
      .filter((table) => !this.props.selected.includes(table.get('id')))
      .sortBy((table) => tableDisplayNameWithBucketAndStage(table))
      .map((table) => {
        const isExternal = this.props.buckets.getIn(
          [table.getIn(['bucket', 'id']), 'hasExternalSchema'],
          false,
        );

        return {
          value: table.get('id'),
          label: tableLabel(table),
          name: tableName(table),
          isDisabled: isExternal,
          disabledReason: EXTERNAL_DATASET_DISABLE_TOOLTIP.TRIGGER,
        };
      })
      .toArray();
  }

  _getSelectedTables(selectedOptions) {
    return this.props.tables.filter((table) => selectedOptions.includes(table.get('id')));
  }
}

EventTrigger.propTypes = {
  tables: PropTypes.object.isRequired,
  buckets: PropTypes.object.isRequired,
  selected: PropTypes.array,
  onAddTable: PropTypes.func.isRequired,
  onRemoveTable: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
  onChangePeriod: PropTypes.func.isRequired,
};

export default EventTrigger;
