import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, Form, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { List, Map } from 'immutable';

import DockerActionsActionCreators from '@/modules/configurations/DockerActionsActionCreators';
import { listAccounts } from '@/modules/ex-google-ads/adapters/actions';
import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const AccountsSelect = ({ value, availableAccounts, onChange, isLoading, isDisabled }) => {
  return (
    <Select
      multi
      options={availableAccounts
        .map((account, id) => {
          const name = account.getIn(['info', 'descriptiveName']);
          const childrenString = account
            .get('children', List())
            .map((childAccount) =>
              childAccount.getIn(['info', 'descriptiveName'], childAccount.getIn(['info', 'id'])),
            )
            .filter(Boolean)
            .join(', ');

          return {
            label: `${name ? `${name} (${id})` : id}${
              childrenString ? ` - ${childrenString}` : ''
            }`,
            value: id,
          };
        })
        .toArray()}
      value={[].concat(value.customerId)}
      onChange={(values) => onChange({ customerId: values })}
      disabled={isDisabled}
      isLoading={isLoading}
      noResultsText="No accounts available"
    />
  );
};

export const OnlyEnabledCustomersCheckbox = ({ value, onChange, isDisabled }) => {
  return (
    <Checkbox
      checked={!!value?.onlyEnabledCustomers}
      onChange={(checked) => onChange({ onlyEnabledCustomers: checked })}
      disabled={isDisabled}
    >
      Downloads only enabled customers
    </Checkbox>
  );
};

const AccountsSettingsConfigRowsWrapper = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    context: PropTypes.instanceOf(Map).isRequired,
    value: PropTypes.shape({
      customerId: PropTypes.array.isRequired,
      onlyEnabledCustomers: PropTypes.bool.isRequired,
      getAccountChildren: PropTypes.bool.isRequired,
    }),
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  render() {
    const availableAccounts = this.props.actions.getIn(['listAccounts', 'data'], Map());
    const isLoading = this.props.actions.getIn(['listAccounts', 'status']) === 'pending';
    const isDisabled = this.props.isDisabled || (isLoading && availableAccounts.isEmpty());

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Ads Customer ID</ControlLabel>
          </div>
          <div className="col-xs-9">
            <AccountsSelect
              value={this.props.value}
              availableAccounts={availableAccounts}
              onChange={this.props.onChange}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
            {!this.props.readOnly && (
              <HelpBlock>
                Not seeing your newest tables?{' '}
                <Button
                  bsStyle="link"
                  className="btn-link-inline"
                  disabled={isLoading}
                  onClick={() =>
                    DockerActionsActionCreators.callAction(
                      this.props.context.get('componentId'),
                      'listAccounts',
                      listAccounts(this.props.context.get('configuration')),
                    )
                  }
                >
                  Reload
                </Button>{' '}
                the list of tables.
              </HelpBlock>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">
            <OnlyEnabledCustomersCheckbox
              value={this.props.value}
              onChange={this.props.onChange}
              isDisabled={isDisabled}
            />
            <Checkbox
              className="tw-mt-2"
              checked={this.props.value.getAccountChildren}
              onChange={(checked) => this.props.onChange({ getAccountChildren: checked })}
              disabled={isDisabled}
            >
              List individual subaccounts
            </Checkbox>
          </div>
        </FormGroup>
      </Form>
    );
  },
});

export default AccountsSettingsConfigRowsWrapper;
