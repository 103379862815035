import { fromJS, List, Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes } from './Constants';
import { getModelIdentifier } from './helper';

let _store = initStore(
  'ModelsStore',
  Map({
    models: List(),
    pendingActions: Map(),
    processingJobs: List(),
    isLoading: false,
    isLoaded: false,
  }),
);

const ModelsStore = StoreUtils.createStore({
  getAll() {
    return _store
      .get('models')
      .map((model) => {
        return model.set(
          'id', // generate ID manually, since there's no ID returned in API response
          getModelIdentifier(model),
        );
      })
      .toMap()
      .mapKeys((key, model) => model.get('id'));
  },

  getPendingActions() {
    return _store.get('pendingActions');
  },

  getIsLoading() {
    return _store.get('isLoading', false);
  },

  getIsLoaded() {
    return _store.get('isLoaded', false);
  },

  getProcessingJobs() {
    return _store.get('processingJobs');
  },
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case ActionTypes.MODELS_LOAD:
      _store = _store.set('isLoading', true);
      return ModelsStore.emitChange();

    case ActionTypes.MODELS_LOAD_SUCCESS:
      _store = _store
        .set('isLoading', false)
        .set('isLoaded', true)
        .set('models', fromJS(action.models));
      return ModelsStore.emitChange();

    case ActionTypes.MODELS_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return ModelsStore.emitChange();

    case ActionTypes.MODEL_UPDATE_SUCCESS:
      _store = _store.update('models', (models) => {
        return models.map((model) => {
          if (
            model.get('name') === action.model.name &&
            model.get('version') === action.model.version
          ) {
            return model.merge(action.model);
          }

          return model;
        });
      });
      return ModelsStore.emitChange();

    case ActionTypes.MODEL_START_DEPLOYMENT:
      _store = _store.setIn(['pendingActions', 'deploy', action.id], true);
      return ModelsStore.emitChange();

    case ActionTypes.MODEL_START_DEPLOYMENT_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'deploy', action.id]);
      return ModelsStore.emitChange();

    case ActionTypes.MODEL_STOP_DEPLOYMENT:
      _store = _store.setIn(['pendingActions', 'stop', action.id], true);
      return ModelsStore.emitChange();

    case ActionTypes.MODEL_STOP_DEPLOYMENT_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'stop', action.id]);
      return ModelsStore.emitChange();

    case ActionTypes.MODELS_PROCESSING_JOBS_LOADED:
      _store = _store.set('processingJobs', fromJS(action.jobs));
      return ModelsStore.emitChange();

    default:
      break;
  }
});

export default ModelsStore;
