import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledApi from '@/modules/components/InstalledComponentsApi';
import { USED_METADATA_KEYS } from '@/modules/components/MetadataConstants';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OauthActions from '@/modules/oauth-v2/ActionCreators';
import { applySchedulerChanges } from '@/modules/scheduler/actions';
import SchedulerApi from '@/modules/scheduler/api';
import {
  createVariable,
  deleteVariable,
  loadVariables,
  updateVariable,
} from '@/modules/vault/actions';
import { filterCurrentBranchVariables } from '@/modules/vault/helpers';
import VariablesStore from '@/modules/vault/store';
import ApplicationStore from '@/stores/ApplicationStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import nextTick from '@/utils/nextTick';
import { actionTypes, WAIT_AFTER_MERGE } from './constants';
import DevBranchesStore from './DevBranchesStore';
import {
  createdOrUpdatedInBranch,
  findUpdatedConfigurations,
  findUpdatedMetadata,
  parseCurrentDevBranchIdFromUrl,
} from './helpers';

const setCurrentDevBranchId = () => {
  Dispatcher.handleViewAction({
    type: actionTypes.SET_CURRENT_DEV_BRANCH_ID,
    branchId: parseCurrentDevBranchIdFromUrl(),
  });
};

const loadAll = () => {
  Dispatcher.handleViewAction({ type: actionTypes.LOAD_DATA_START });
  return Promise.all([
    loadProductionComponentsForce(),
    loadProductionDeletedComponentsForce(),
    loadProductionComponentsMetadataForce(),
    InstalledComponentsActionCreators.loadComponentsMetadataForce(),
    InstalledComponentsActionCreators.loadInstalledComponentsForce(),
    InstalledComponentsActionCreators.loadDeletedComponentsForce(),
    ...(ApplicationStore.hasProtectedDefaultBranch()
      ? [loadVariables(), loadMergeRequests(), OauthActions.loadAllCredentials()]
      : []),
  ]).finally(() => {
    Dispatcher.handleViewAction({ type: actionTypes.LOAD_DATA_FINISH });
  });
};

const loadAllAndInitialize = () => {
  return loadAll().then(() => {
    nextTick(() => {
      const updatedMetadata = findUpdatedMetadata(
        InstalledComponentsStore.getAllMetadata(),
        DevBranchesStore.getProductionComponentsMetadata(),
      );
      const updatedInstalledComponents = findUpdatedConfigurations(
        InstalledComponentsStore.getAll(),
        DevBranchesStore.getProductionComponents(),
        InstalledComponentsStore.getAllDeleted(),
        DevBranchesStore.getProductionDeletedComponents(),
        updatedMetadata,
      );

      initializeSelectedChanges(
        updatedInstalledComponents,
        filterCurrentBranchVariables(VariablesStore.getStore().variables),
      );
    });
  });
};

const loadProductionComponentsForce = () => {
  return InstalledApi.getProductionComponents().then((components) => {
    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_PRODUCTION_COMPONENTS_SUCCESS,
      components,
    });
  });
};

const loadProductionDeletedComponentsForce = () => {
  return InstalledApi.getProductionDeletedComponents().then((components) => {
    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_PRODUCTION_DELETED_COMPONENTS_SUCCESS,
      components,
    });
  });
};

const loadProductionComponentsMetadataForce = () => {
  return InstalledApi.searchComponents(
    {
      metadataKeys: USED_METADATA_KEYS,
      include: 'filteredMetadata',
    },
    { forceProduction: true },
  ).then((metadata) => {
    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_PRODUCTION_COMPONENTS_METADATA_SUCCESS,
      result: metadata,
    });
  });
};

const initializeSelectedChanges = (updatedComponents, createdVariables) => {
  Dispatcher.handleViewAction({
    type: actionTypes.INITIALIZE_SELECTED_CHANGES,
    updatedComponents,
    createdVariables,
  });
};

const toggleConfigurationChange = (componentId, configurationId, select) => {
  Dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_CONFIGURATION_CHANGE,
    componentId,
    configurationId,
    select,
  });
};

const toggleAllConfigurations = (components) => {
  Dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_ALL_CONFIGURATIONS,
    components,
  });
};

const toggleVariable = (variable, checked) => {
  Dispatcher.handleViewAction({ type: actionTypes.TOGGLE_VARIABLE_CHANGE, variable, checked });
};

const merge = (
  branchName,
  components,
  productionComponents,
  metadata,
  onProgress,
  removeBranchAfterMerge,
  changeDescription,
) => {
  return Promise.each(components.toArray(), (component) => {
    return Promise.each(component.get('configurations').toArray(), (config) => {
      return InstalledApi.getComponentConfiguration(component.get('id'), config.get('id'))
        .catch((error) => {
          if (error.response.status === 404) {
            return Promise.resolve();
          }

          throw error;
        })
        .then((configData) => {
          if (!configData) {
            // check if configuration is already deleted in the production, if so do not call again
            if (
              !productionComponents.hasIn([component.get('id'), 'configurations', config.get('id')])
            ) {
              return Promise.resolve();
            }

            return InstalledApi.deleteProductionConfiguration(
              component.get('id'),
              config.get('id'),
            ).catch((error) => {
              if (error.response.status === 404) {
                return Promise.resolve();
              }
              throw error;
            });
          }

          const dataToSave = {
            name: configData.name,
            description: configData.description,
            configuration: JSON.stringify(
              fromJS(configData.configuration).removeIn(['runtime', 'safe']).toJS(),
            ),
            changeDescription,
          };
          let updateRowsSortOrder = false;

          return InstalledApi.getProductionComponentConfiguration(
            component.get('id'),
            config.get('id'),
          )
            .then((production) => {
              if (
                production.name === dataToSave.name &&
                production.description === dataToSave.description &&
                JSON.stringify(production.configuration) === dataToSave.configuration
              ) {
                return production;
              }

              return InstalledApi.updateProductionComponentConfiguration(
                component.get('id'),
                config.get('id'),
                dataToSave,
              );
            })
            .catch((error) => {
              if (error.response.status === 404) {
                return InstalledApi.createProductionConfiguration(component.get('id'), {
                  ...dataToSave,
                  configurationId: config.get('id'),
                });
              }
              throw error;
            })
            .then((production) => {
              updateRowsSortOrder = !_.isEqual(production.rowsSortOrder, configData.rowsSortOrder);

              const rows = fromJS(configData.rows);
              const deletedRows = fromJS(production.rows || [])
                .filterNot((prodRow) => rows.some((row) => row.get('id') === prodRow.get('id')))
                .map((row) => row.set('isDeleted', true));

              return rows.filter(createdOrUpdatedInBranch).concat(deletedRows);
            })
            .each((row) => {
              if (row.get('isDeleted')) {
                return InstalledApi.deleteProductionConfigurationRow(
                  component.get('id'),
                  config.get('id'),
                  row.get('id'),
                  changeDescription,
                ).catch((error) => {
                  if (error.response.status === 404) {
                    return Promise.resolve();
                  }
                  throw error;
                });
              }

              const dataToSave = {
                name: row.get('name'),
                description: row.get('description'),
                isDisabled: row.get('isDisabled'),
                configuration: JSON.stringify(row.get('configuration').toJS()),
                changeDescription,
              };

              return InstalledApi.getProductionConfigurationRow(
                component.get('id'),
                config.get('id'),
                row.get('id'),
              )
                .then((productionRow) => {
                  if (
                    productionRow.name === dataToSave.name &&
                    productionRow.description === dataToSave.description &&
                    productionRow.isDisabled === dataToSave.isDisabled &&
                    JSON.stringify(productionRow.configuration) === dataToSave.configuration
                  ) {
                    return productionRow;
                  }

                  return InstalledApi.updateProductionConfigurationRow(
                    component.get('id'),
                    config.get('id'),
                    row.get('id'),
                    dataToSave,
                  );
                })
                .catch((error) => {
                  if (error.response.status === 404) {
                    return InstalledApi.createProductionConfigurationRow(
                      component.get('id'),
                      config.get('id'),
                      {
                        ...dataToSave,
                        rowId: row.get('id'),
                      },
                    );
                  }
                  throw error;
                });
            })
            .then(() => {
              if (updateRowsSortOrder) {
                return InstalledApi.updateProductionComponentConfiguration(
                  component.get('id'),
                  config.get('id'),
                  {
                    name: configData.name,
                    rowsSortOrder: configData.rowsSortOrder,
                    changeDescription,
                  },
                );
              }
            })
            .then(() => {
              if (metadata.hasIn([component.get('id'), config.get('id')])) {
                return InstalledApi.saveProductionConfigurationMetadata(
                  component.get('id'),
                  config.get('id'),
                  metadata
                    .getIn([component.get('id'), config.get('id')])
                    .map((row) => ({ key: row.get('key'), value: row.get('value') }))
                    .toArray(),
                );
              }
            });
        })
        .then(() => {
          if (!removeBranchAfterMerge) {
            return InstalledComponentsActionCreators.resetConfigurationToProductionVersion(
              component.get('id'),
              config.get('id'),
            );
          }
        });
    }).then(onProgress);
  });
};

const deleteBranch = (branchId) => {
  return StorageApi.deleteDevBranch(branchId);
};

const loadMergeRequests = () => {
  if (!DevBranchesStore.getMergeRequests().isEmpty()) {
    loadMergeRequestsForce();
    return Promise.resolve();
  }

  return loadMergeRequestsForce();
};

const loadMergeRequestsForce = () => {
  return StorageApi.loadMergeRequests().then((mergeRequests) => {
    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_MERGE_REQUESTS_SUCCESS,
      mergeRequests,
    });
  });
};

const sendDevBranchToReview = (
  title,
  description,
  unselectedConfigurations,
  unselectedVariables,
) => {
  return Promise.all([
    Promise.each(unselectedConfigurations, ([componentId, component]) => {
      return Promise.each(component.get('configurations', Map()).keySeq().toArray(), (configId) => {
        return InstalledComponentsActionCreators.resetConfigurationToProductionVersion(
          componentId,
          configId,
        );
      });
    }),
    Promise.each(unselectedVariables, deleteVariable),
  ])
    .then(() => {
      return DevBranchesStore.getCurrentDevBranchMergeRequest().isEmpty()
        ? StorageApi.createDevBranchMergeRequest(title, description)
        : StorageApi.updateDevBranchMergeRequest(
            DevBranchesStore.getCurrentDevBranchMergeRequest().get('id'),
            title,
            description,
          );
    })
    .then((mergeRequest) => StorageApi.submitDevBranchChanges(mergeRequest.id))
    .then(loadAll)
    .then(loadMergeRequestsForce);
};

const approveDevBranchChanges = (id) => {
  return StorageApi.approveDevBranchChanges(id).then(loadMergeRequestsForce);
};

const rejectDevBranchChanges = (id) => {
  return StorageApi.rejectDevBranchChanges(id).then(loadMergeRequestsForce);
};

const mergeDevBranchChanges = (
  id,
  selectedConfigurations,
  variablesToCreate = [],
  tapWhenDone = _.noop,
) => {
  return Promise.each(variablesToCreate, ({ isExistingVariable, ...variable }) => {
    delete variable.originalValue;

    if (!variable.value.length) return;
    if (isExistingVariable) return updateVariable(variable);
    return createVariable(variable);
  })
    .then(() => StorageApi.mergeDevBranchChanges(id))
    .then(StorageActionCreators.waitForFinishedStorageJob)
    .then(() => {
      return Promise.each(
        selectedConfigurations.get(KEBOOLA_SCHEDULER, List()),
        (configurationId) => {
          return applySchedulerChanges(configurationId).catch((error) => {
            // scheduler configuration was deleted, so it is not possible to apply changes, so we delete whole scheduler
            if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
              return SchedulerApi.removeSchedule(configurationId);
            }

            throw error;
          });
        },
      );
    })
    .tap(tapWhenDone)
    .delay(WAIT_AFTER_MERGE) // wait to show success message
    .then(() => {
      window.location.replace(ApplicationStore.getProjectBaseUrl());
    });
};

export {
  setCurrentDevBranchId,
  loadAll,
  loadAllAndInitialize,
  loadProductionComponentsMetadataForce,
  initializeSelectedChanges,
  toggleConfigurationChange,
  toggleVariable,
  merge,
  deleteBranch,
  loadMergeRequests,
  loadMergeRequestsForce,
  sendDevBranchToReview,
  approveDevBranchChanges,
  rejectDevBranchChanges,
  mergeDevBranchChanges,
  toggleAllConfigurations,
};
