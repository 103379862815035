import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import {
  JOB_FAST_TEXT,
  JOB_IS_FASTER_PERCENTAGE,
  JOB_IS_SLOWER_PERCENTAGE,
  JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS,
  JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS,
  JOB_RUNNING_STATUSES,
  JOB_SLOW_TEXT,
  JOBS_STATUS,
} from '@/modules/queue/constants';
import { calculateAverage, isContainerJob, isPartialJob } from '@/modules/queue/helpers';
import CircleIcon from '@/react/common/CircleIcon';
import InfoTooltip from '@/react/common/InfoTooltip';
import JobDuration from '@/react/common/JobDuration';
import { timeInWords } from '@/utils/duration';
import JobErrorPanel from './JobErrorPanel';
import JobsParametersResultsPanel from './JobParametersResultsPanel';
import JobStatsContainer from './JobStatsContainer';

class PanelsRows extends React.Component {
  render() {
    return (
      <div className="box-panels-row">
        {this.props.job.get('status') === JOBS_STATUS.ERROR ? (
          <JobErrorPanel
            job={this.props.job}
            sapiToken={this.props.sapiToken}
            configData={this.props.configData}
            allComponents={this.props.allComponents}
            hasAllowedAi={this.props.hasAllowedAi}
            hasSnowflakeDynamicBackendSize={this.props.hasSnowflakeDynamicBackendSize}
            hasJobsDynamicBackendSize={this.props.hasJobsDynamicBackendSize}
          />
        ) : (
          this.renderDurationBox()
        )}
        <div className="job-parameters-results-panel box box-panel">
          <div className="box-header">
            <h3 className="box-title">Parameters &amp; Results</h3>
            <CircleIcon icon="brackets-curly" color="blue" />
          </div>
          <JobsParametersResultsPanel job={this.props.job} />
        </div>
        <JobStatsContainer
          key={this.props.job.get('runId')}
          runId={this.props.job.get('id')}
          autoRefresh={JOB_RUNNING_STATUSES.includes(this.props.job.get('status'))}
        />
      </div>
    );
  }

  renderDurationBox() {
    const duration = this.props.job.get('durationSeconds');
    const average = calculateAverage(this.props.graphData);
    const isPartial = isPartialJob(this.props.job);

    if (
      !isPartial &&
      [JOBS_STATUS.SUCCESS, JOBS_STATUS.WARNING].includes(this.props.job.get('status')) &&
      duration > JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS &&
      average > 0
    ) {
      const is10percentSlowerThanAverage =
        parseInt((duration * 100) / average, 10) >= JOB_IS_SLOWER_PERCENTAGE;
      const is10percentFasterThanAverage =
        parseInt((duration * 100) / average, 10) <= JOB_IS_FASTER_PERCENTAGE;
      const difference = Math.abs(parseInt(duration - average, 10));

      if (
        difference > JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS &&
        is10percentSlowerThanAverage
      ) {
        return (
          <div className="job-total-duration-panel box box-panel">
            <div className="box-header">
              <h3 className="box-title">
                Duration{this.renderInfoTooltip()}{' '}
                <span className="color-danger">(+{timeInWords(difference)})</span>
              </h3>
              <CircleIcon icon="circle-caret-up" color="red" />
            </div>
            <div className="box-panel-content mt-auto">
              {this.renderDuration()}
              <p className="summary-text mb-0">{JOB_SLOW_TEXT}</p>
            </div>
          </div>
        );
      }

      if (
        !isPartial &&
        difference > JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS &&
        is10percentFasterThanAverage
      ) {
        return (
          <div className="job-total-duration-panel box box-panel">
            <div className="box-header">
              <h3 className="box-title">
                Duration{this.renderInfoTooltip()}{' '}
                <span className="color-success">(-{timeInWords(difference)})</span>
              </h3>
              <CircleIcon icon="circle-caret-down" color="green" />
            </div>
            <div className="box-panel-content mt-auto">
              {this.renderDuration()}
              <p className="summary-text mb-0">
                {`${JOB_FAST_TEXT} `}
                <span className="font-medium text-success">Huraaaaayyyyyy!</span>
              </p>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="job-total-duration-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title">Duration{this.renderInfoTooltip()}</h3>
        </div>
        <div className="box-panel-content mt-auto">
          {this.renderDuration()}
          {[JOBS_STATUS.SUCCESS, JOBS_STATUS.WARNING].includes(this.props.job.get('status')) &&
            this.props.job.get('endTime') &&
            !isPartial && <p className="summary-text mb-0">{JOB_FAST_TEXT}</p>}
        </div>
      </div>
    );
  }

  renderDuration() {
    return (
      <p className="summary-title">
        <JobDuration
          status={this.props.job.get('status')}
          startTime={this.props.job.get('startTime')}
          endTime={this.props.job.get('endTime')}
        />
      </p>
    );
  }

  renderInfoTooltip() {
    if (isContainerJob(this.props.job)) {
      return (
        <InfoTooltip tooltip="The total time from the moment the first child configuration starts to the moment the last one finishes in a serial or parallel run. It doesn't have to be an exact billable time." />
      );
    }

    if (this.props.hasPayAsYouGo) {
      return <InfoTooltip tooltip="It doesn't have to be an exact billable time." />;
    }

    return null;
  }
}

PanelsRows.propTypes = {
  hasPayAsYouGo: PropTypes.bool.isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  hasAllowedAi: PropTypes.bool.isRequired,
  graphData: PropTypes.instanceOf(Map),
};

export default PanelsRows;
