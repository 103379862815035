import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import type { Map } from 'immutable';

import DocumentationLink from './DocumentationLink';

const General = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <div className="box">
      <div className="box-header big-padding">
        <h2 className="box-title">Base URL</h2>
      </div>
      <div className="box-content pt-0">
        <FormGroup>
          <FormControl
            type="text"
            disabled={props.readOnly}
            value={props.editing.get('baseUrl')}
            placeholder="https://my-api.com/v1/"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setEditing(props.editing.set('baseUrl', e.target.value));
            }}
          />
          <HelpBlock>
            This defines the URL to which the API requests should be sent. For easier setup, we
            recommend that the URL ends with a slash.{' '}
            <DocumentationLink format="text" path="configuration/api/#base-url" />
          </HelpBlock>
        </FormGroup>
      </div>
    </div>
  );
};

export default General;
