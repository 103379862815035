import { fromJS } from 'immutable';

import { findBaseType } from '@/modules/components/utils/columnMetadataHelper';
import { getTableColumnMetadata } from '@/modules/components/utils/tableMetadataHelper';

const basetypeMapping = fromJS({
  STRING: {
    type: 'string',
  },
  INTEGER: {
    type: 'number',
  },
  NUMERIC: {
    type: 'decimal',
  },
  FLOAT: {
    type: 'decimal',
  },
  BOOLEAN: {
    type: 'boolean',
  },
  DATE: {
    type: 'date',
    format: '%Y-%m-%d',
  },
  TIMESTAMP: {
    type: 'datetime',
    format: '%Y-%m-%d %H:%M:%S',
  },
});

export function prepareColumnsTypes(table) {
  const columnMetadata = getTableColumnMetadata(table);
  const metadata = getMetadata(columnMetadata);

  return table
    .get('columns')
    .map((column) =>
      fromJS({
        column,
        type: metadata.getIn([column, 'type'], 'IGNORE'),
        format: metadata.getIn([column, 'format']),
      }).filter(Boolean),
    )
    .toMap()
    .mapKeys((key, column) => column.get('column'))
    .map((column) => column.delete('column'));
}

function getMetadata(columnMetadata) {
  return columnMetadata.map((data) => {
    const baseType = findBaseType(data);

    if (!baseType) {
      return null;
    }

    const mapping = basetypeMapping.get(baseType.get('value'));

    if (!mapping) {
      return null;
    }

    return fromJS({ type: mapping.get('type'), format: mapping.get('format') });
  });
}
