import React from 'react';
import { Link } from 'react-router-named-routes';
import classnames from 'classnames';

import keyCodes from '@/constants/keyCodes';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareProductionHref } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  to?: string;
  params?: Record<string, any>;
  query?: Record<string, any>;
  hash?: string;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  activeClassName?: string;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  onlyActiveOnIndex?: boolean;
  role?: string;
  state?: Record<string, any>;
  tabIndex?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  forceProduction?: boolean;
  openInNewTab?: boolean;
};
const RouterLink = (props: Props) => {
  if (props.disabled) {
    return (
      <span
        className={classnames('btn-disabled not-allowed', props.className)}
        title={props.title}
        style={props.style}
      >
        {props.children}
      </span>
    );
  }

  const commonProps = {
    title: props.title,
    style: props.style,
    className: props.className,
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();

      if (props.onClick) {
        props.onClick(e);
      }
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === keyCodes.ENTER) {
        e.stopPropagation();
      }

      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    },
    role: props.role,
    tabIndex: props.tabIndex,
  };
  const blankPageProps = { target: '_blank', rel: 'noreferrer' };

  if (props.forceProduction && DevBranchesStore.isDevModeActive()) {
    return (
      <a
        {...commonProps}
        {...blankPageProps}
        href={prepareProductionHref(
          ApplicationStore.getProjectBaseUrl(),
          RoutesStore.getRouter().createHref(props.to, props.params, props.query),
          DevBranchesStore.getCurrentId(),
        )}
      >
        {props.children}
      </a>
    );
  }

  return (
    <Link
      {...commonProps}
      to={{
        name: props.to,
        query: props.query,
        state: props.state,
        hash: props.hash,
      }}
      params={props.params}
      activeClassName={props.activeClassName ?? 'active'}
      onlyActiveOnIndex={props.onlyActiveOnIndex ?? false}
      {...(props.openInNewTab && blankPageProps)}
    >
      {props.children}
    </Link>
  );
};

export default RouterLink;
