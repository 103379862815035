import BackLink from './react/BackLink';
import BillingCredits from './react/Credits';
import BillingOverview from './react/Overview';
import {
  getBillingConfiguration,
  getBillingInformation,
  getCredits,
  getPurchases,
  getTopUpSetting,
} from './actions';
import { routeNames } from './constants';

const loadCommonData = () => {
  return Promise.all([
    getPurchases(),
    getBillingConfiguration(),
    getBillingInformation(),
    getTopUpSetting(),
  ]);
};

const routes = [
  {
    name: routeNames.BILLING_OVERVIEW,
    title: 'Overview',
    subtitle: 'Billing',
    defaultRouteHandler: BillingOverview,
    requireData: [() => loadCommonData(), () => getCredits()],
  },
  {
    name: routeNames.BILLING_MINUTES,
    title: 'Add more minutes',
    breadcrumbHandler: BackLink,
    defaultRouteHandler: BillingCredits,
    requireData: [loadCommonData],
  },
];

export { BackLink };

export default routes;
