import React, { useState } from 'react';
import { Button, ButtonToolbar, MenuItem, SplitButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';

import ChangeDescriptionModal from './ChangeDescriptionModal';
import Loader from './Loader';

type Props = {
  isSaving: boolean;
  isChanged: boolean;
  onReset: () => void;
  onSave: () => void;
  disabled: boolean;
  onDescriptionChange: (value: string) => void;
  changeDescription: string;
  disabledReason?: string;
};

const SaveButtonWithDescription = ({
  isSaving,
  isChanged,
  onReset,
  onSave,
  disabled,
  onDescriptionChange,
  changeDescription,
  disabledReason,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModal(false);
    onSave();
  };

  return (
    <>
      {isChanged && (
        <ButtonToolbar>
          <Button onClick={onReset} disabled={isSaving}>
            <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
            Reset
          </Button>
          {disabled && disabledReason ? (
            <Tooltip placement="bottom" tooltip={disabledReason} type="explanatory">
              <Button bsStyle="success" className="save-button btn-disabled">
                {isSaving ? <Loader /> : 'Save'}
              </Button>
            </Tooltip>
          ) : (
            <SplitButton
              id="save-button-with-description"
              className="save-button"
              bsStyle="success"
              disabled={disabled || isSaving}
              title={isSaving ? <Loader /> : 'Save'}
              onClick={onSave}
              pullRight
            >
              <MenuItem onSelect={() => setOpenModal(true)}>Save with description</MenuItem>
            </SplitButton>
          )}
        </ButtonToolbar>
      )}
      <ChangeDescriptionModal
        show={openModal}
        value={changeDescription}
        isSaving={isSaving}
        onChange={onDescriptionChange}
        onSubmit={handleSubmit}
        onHide={() => setOpenModal(false)}
      />
    </>
  );
};

export default SaveButtonWithDescription;
