import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { routeNames as billingRouteNames } from '@/modules/billing/constants';
import { convertCreditsToMinutes } from '@/modules/billing/helpers';
import { routeNames as telemetryRouteNames } from '@/modules/telemetry/routes';
import CircleIcon from '@/react/common/CircleIcon';
import InfoTooltip from '@/react/common/InfoTooltip';
import Link from '@/react/common/RouterLink';
import { SkeletonBar } from '@/react/common/Skeleton/Skeleton';
import CreditsLoadingTooltip from './CreditsLoadingTooltip';
import MarketplacePortalLink from './MarketplacePortalLink';

type Props = {
  creditsCount: number | null;
  isLoadingCredits: boolean;
  simple: boolean;
  isMarketplaceProject?: boolean;
  marketplaceSubscriptionLink?: string;
};

const CreditsBalanceBox = ({
  creditsCount,
  isLoadingCredits,
  simple,
  isMarketplaceProject = false,
  marketplaceSubscriptionLink,
}: Props) => {
  const isEmpty = typeof creditsCount === 'number' && creditsCount <= 0;
  const initialLoading = isLoadingCredits && creditsCount === null;

  const renderButtons = () => {
    if (simple) {
      return (
        <>
          <Link to={billingRouteNames.BILLING_MINUTES} className="btn btn-success fill-space">
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Top up minutes
          </Link>
          <Link to={telemetryRouteNames.BILLING_CONSUMPTION} className="btn btn-default fill-space">
            Project Consumption
          </Link>
        </>
      );
    }

    if (isEmpty) {
      if (isMarketplaceProject && marketplaceSubscriptionLink) {
        return <MarketplacePortalLink href={marketplaceSubscriptionLink} className="mt-1" />;
      }

      return (
        <Link to={billingRouteNames.BILLING_MINUTES} className="btn btn-success fill-space">
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Top up minutes
        </Link>
      );
    }

    return (
      <Link to={billingRouteNames.BILLING_OVERVIEW} className="btn btn-success fill-space">
        Billing Overview
      </Link>
    );
  };

  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Your Minutes Balance</h2>
        {!initialLoading && !isEmpty && <CircleIcon icon="clock" color="blue" bold />}
        {!initialLoading && isEmpty && <CircleIcon icon="warning" color="red" bold />}
      </div>

      {initialLoading ? (
        <div className="tw-mt-5 tw-flex tw-flex-col tw-gap-4 tw-px-3">
          <SkeletonBar size="lg" className="tw-max-w-52" />
          <SkeletonBar size="md" />
        </div>
      ) : (
        <div className="box-panel-content mt-auto">
          <p className={classNames('summary-title', { 'f-32': !!simple })}>
            {convertCreditsToMinutes(creditsCount)} minutes
            <InfoTooltip tooltip="Current balance, including workspace run time" />
            <CreditsLoadingTooltip show={isLoadingCredits} />
          </p>
          <p className="flex-container mt-1 gap-16">{renderButtons()}</p>
        </div>
      )}
    </div>
  );
};

export default CreditsBalanceBox;
