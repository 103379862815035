import React from 'react';
import type { BadgeProps } from 'design';
import { Badge } from 'design';

const ExternalBucketLabel = ({
  hasExternalSchema,
  className,
  ...badgeProps
}: { hasExternalSchema: boolean; className?: string } & Partial<BadgeProps>) => {
  if (!hasExternalSchema) {
    return null;
  }

  return <Badge {...badgeProps} text="External" variant="blue" className={className} />;
};

export default ExternalBucketLabel;
