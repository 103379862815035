import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import CreateFolderButton from '@/react/common/ConfigurationsTable/CreateFolderButton';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import NewFlowButton from './components/NewFlowButton';

const IndexHeader = () => {
  const state = useStores(
    () => {
      return {
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
      };
    },
    [],
    [ApplicationStore, InstalledComponentsStore, ComponentsStore],
  );

  if (state.readOnly) {
    return null;
  }

  return (
    <ButtonToolbar>
      <CreateFolderButton
        entity="flow"
        configurations={state.flows}
        componentsMetadata={state.componentsMetadata}
        componentId={KEBOOLA_ORCHESTRATOR}
      />
      {!state.flows.isEmpty() && (
        <NewFlowButton
          component={state.component}
          readOnly={state.readOnly}
          componentMetadata={state.componentsMetadata.get(KEBOOLA_ORCHESTRATOR, Map())}
        />
      )}
    </ButtonToolbar>
  );
};

export default IndexHeader;
