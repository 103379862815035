/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { Badge, Tooltip } from 'design';
import { List, Map, OrderedMap } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import Checkbox from '@/react/common/Checkbox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ConnectorIcon from '@/react/common/ConnectorIcon';
import MarkedText from '@/react/common/MarkedText';
import fromJSOrdered from '@/utils/fromJSOrdered';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import { getTagsForComponentConfiguration, getTagsForComponentConfigurationRow } from './helpers';

const getTableInitialState = (data, searchQuery) => {
  if (!searchQuery) {
    return;
  }

  const expanded = data.reduce((all, component, componentIndex) => {
    const configs = component.subRows.reduce((acc, config, configIndex) => {
      return { ...acc, [`${componentIndex}.${configIndex}`]: config.matchOnlyName !== true };
    }, {});

    return { ...all, [componentIndex]: component.matchOnlyName !== true, ...configs };
  }, {});

  return { expanded };
};

const ComponentRow = ({ row, searchQuery }) => {
  const componentTypeLabel = getNewComponentTypeLabel(row.original.item.get('type'));

  return (
    <div className="flex-container flex-start">
      <ComponentName component={row.original.item}>
        {(name) => <MarkedText source={name} mark={searchQuery} />}
      </ComponentName>
      <Badge text={componentTypeLabel} variant="blue" placement="right" />
    </div>
  );
};

const Config = ({ row, searchQuery, handleAdd }) => {
  return (
    <span className="flex-container flex-start">
      <FontAwesomeIcon
        fixedWidth
        icon={row.getIsExpanded() || !row.getCanExpand() ? 'folder-open' : 'folder'}
        className={classnames({
          'text-muted-light': !row.getIsExpanded(),
          'text-muted': row.getIsExpanded(),
        })}
      />
      <MarkedText source={row.original.item.get('name')} mark={searchQuery} />
      {row.original.item.get('fileTags').map((tag, index) => (
        <FileTag key={index} tag={tag} row={row} handleAdd={handleAdd} searchQuery={searchQuery} />
      ))}
    </span>
  );
};

const ConfigRow = ({ row, searchQuery, handleAdd }) => {
  return (
    <span className="flex-container flex-start">
      <ConnectorIcon />
      <FontAwesomeIcon icon="cabinet-filing" className="text-muted-light" />
      <MarkedText
        source={row.original.item.get('name')}
        mark={searchQuery}
        className="icon-addon-right"
      />
      <div>
        {row.original.item.get('fileTags').map((tag, index) => (
          <FileTag
            key={index}
            tag={tag}
            row={row}
            handleAdd={handleAdd}
            searchQuery={searchQuery}
          />
        ))}
      </div>
    </span>
  );
};

const FileTag = ({ tag, row, searchQuery, handleAdd }) => {
  return (
    <Tooltip placement="top" tooltip="Row with tag will be used." type="explanatory">
      <Badge
        placement="right"
        variant="green-inverse"
        className="tw-normal-case"
        onClick={(e) => {
          e.stopPropagation();

          handleAdd(
            fromJSOrdered([
              { source: { tags: row.original.item.get('tags').push(OrderedMap({ name: tag })) } },
            ]),
          );
        }}
      >
        <MarkedText source={tag} mark={searchQuery} />
      </Badge>
    </Tooltip>
  );
};

const Table = ({ columns, data, searchQuery }) => {
  const initialState = React.useMemo(() => {
    return getTableInitialState(data, searchQuery);
  }, [data, searchQuery]);

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="table table-hover react-table">
      <div className="thead">
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="tr">
            {headerGroup.headers.map((header) => {
              return (
                <div
                  key={header.column.id}
                  className={classnames('th', {
                    'w-52': header.column.id === 'selection',
                    'pt-0 pb-0 pr-1': header.column.id === 'action',
                  })}
                  {...(header.column.id === 'selection' && {
                    onClick: onClickSelectionCell,
                  })}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className="tbody">
        {tableInstance.getRowModel().rows.map((row) => {
          return (
            <div
              key={row.id}
              className={classnames('tr hoverable-actions', { clickable: row.canExpand })}
              {...(row.getCanExpand() && {
                onClick: (event) => {
                  event.stopPropagation();
                  row.toggleExpanded();
                },
              })}
            >
              {row.getAllCells().map((cell) => {
                return (
                  <div
                    key={cell.id}
                    className={classnames('td', {
                      'w-52': cell.column.id === 'selection',
                      'pt-0 pb-0 pr-1': cell.column.id === 'action',
                    })}
                    {...(cell.column.id === 'selection' && {
                      onClick: onClickSelectionCell,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const IndexTable = ({ allConfigurations, allComponents, searchQuery, handleAdd }) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        header: ({ table }) => {
          const rows = table.getRowModel().flatRows.filter((row) => row.depth === 2);
          const isAllSelected = rows.every((row) => row.getIsSelected());
          const isSomeSelected = rows.some((row) => row.getIsSelected());

          return (
            <Checkbox
              checked={isAllSelected}
              indeterminate={!isAllSelected && isSomeSelected}
              onChange={() => {
                const toggleState = !(isAllSelected || isSomeSelected);
                rows.forEach((row) => row.toggleSelected(toggleState));
              }}
              tooltip="Toggle all queries"
              className="tw-relative tw-top-0.5"
            />
          );
        },
        cell: ({ row }) => {
          switch (row.depth) {
            case 0:
              return <ComponentIcon component={row.original.item} size="32" />;

            case 2:
              return (
                <Checkbox
                  checked={row.getIsSelected()}
                  onChange={row.toggleSelected}
                  tooltip={row.getIsSelected() ? 'Deselect row' : 'Select row'}
                />
              );

            default:
              return null;
          }
        },
      },
      {
        header: ({ table }) => {
          const selected = table
            .getSelectedRowModel()
            .flatRows.filter((row) => row.depth === 2).length;

          if (selected === 0) {
            return <span className="text-muted">No queries selected</span>;
          }

          return <>{selected} queries selected</>;
        },
        cell: ({ row }) => {
          switch (row.depth) {
            case 0:
              return <ComponentRow row={row} searchQuery={searchQuery} />;

            case 1:
              return <Config row={row} searchQuery={searchQuery} handleAdd={handleAdd} />;

            case 2:
              return <ConfigRow row={row} searchQuery={searchQuery} handleAdd={handleAdd} />;

            default:
              return null;
          }
        },
        accessorKey: 'item',
      },
      {
        header: ({ table }) => {
          const selected = table
            .getSelectedRowModel()
            .flatRows.filter((row) => row.depth === 2)
            .map((row) => ({ source: { tags: row.original.item.get('tags') } }));

          return (
            <Button
              bsSize="sm"
              bsStyle="success"
              disabled={selected.length === 0}
              onClick={() => {
                handleAdd(fromJSOrdered(selected));
                table.toggleAllRowsSelected(false);
              }}
            >
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              Add selected
            </Button>
          );
        },
        cell: ({ row }) => {
          switch (row.depth) {
            case 0:
              return null;

            case 1:
              return (
                <Tooltip
                  placement="top"
                  type="explanatory"
                  tooltip={
                    row.original.item.get('isTransformation')
                      ? 'Configuration will be used.'
                      : 'All rows from configuration will be used.'
                  }
                >
                  <Button
                    bsSize="sm"
                    bsStyle="success"
                    onClick={(e) => {
                      e.stopPropagation();

                      handleAdd(
                        fromJSOrdered([{ source: { tags: row.original.item.get('tags') } }]),
                      );
                    }}
                  >
                    <FontAwesomeIcon icon="plus" style={{ fontSize: '13px' }} />
                  </Button>
                </Tooltip>
              );

            case 2:
              return (
                <Tooltip placement="top" tooltip="Row will be used." type="explanatory">
                  <Button
                    bsSize="sm"
                    bsStyle="success"
                    onClick={() => {
                      handleAdd(
                        fromJSOrdered([{ source: { tags: row.original.item.get('tags') } }]),
                      );
                    }}
                  >
                    <FontAwesomeIcon icon="plus" style={{ fontSize: '13px' }} />
                  </Button>
                </Tooltip>
              );

            default:
              return null;
          }
        },
        accessorKey: 'action',
      },
    ],
    [searchQuery, handleAdd],
  );

  const memoizedRows = React.useMemo(
    () =>
      allConfigurations
        .map((component, componentId) => {
          return {
            item: allComponents.get(componentId),
            action: null,
            matchOnlyName: component.get('matchOnlyName', false),
            subRows: component
              .get('configurations')
              .sortBy((config) => config.get('name').toLowerCase())
              .map((config, configId) => {
                return {
                  item: fromJSOrdered({
                    tags: getTagsForComponentConfiguration(componentId, configId),
                    name: config.get('name'),
                    isTransformation: component.get('type') === componentTypes.TRANSFORMATION,
                    fileTags: config
                      .getIn(['configuration', 'storage', 'output', 'files'], List())
                      .reduce((tags, mapping) => tags.concat(mapping.get('tags')), List())
                      .filter(Boolean),
                  }),
                  action: null,
                  matchOnlyName: config.get('matchOnlyName', false),
                  subRows: config
                    .get('rows', List())
                    .sortBy((row) => row.get('name').toLowerCase())
                    .map((row) => {
                      return {
                        item: fromJSOrdered({
                          tags: getTagsForComponentConfigurationRow(
                            componentId,
                            configId,
                            row.get('id'),
                          ),
                          name: row.get('name'),
                          fileTags: row
                            .getIn(
                              ['configuration', 'storage', 'output', 'table_files', 'tags'],
                              List(),
                            )
                            .concat(
                              row
                                .getIn(['configuration', 'storage', 'output', 'files'], List())
                                .reduce(
                                  (tags, mapping) => tags.concat(mapping.get('tags')),
                                  List(),
                                ),
                            )
                            .filter(Boolean),
                        }),
                        action: null,
                      };
                    })
                    .toArray(),
                };
              })
              .toArray(),
          };
        })
        .toArray(),
    [allConfigurations, allComponents],
  );

  return (
    <div className="box">
      <Table columns={columns} data={memoizedRows} searchQuery={searchQuery} />
    </div>
  );
};

IndexTable.propTypes = {
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  handleAdd: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

const MemoizedTable = React.memo(IndexTable, shallowEqualImmutable);

export default MemoizedTable;
