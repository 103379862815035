import { fromJS, Map } from 'immutable';
import { rtrim } from 'underscore.string';

import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import Constants from './Constants';

var store = initStore(
  'ServicesStore',
  Map({
    services: Map(),
  }),
);

const ServicesStore = StoreUtils.createStore({
  getService(serviceId) {
    return store.getIn(['services', serviceId]);
  },

  getServiceUrl(serviceId) {
    return rtrim(store.getIn(['services', serviceId, 'url'], ''), '/');
  },
});

dispatcher.register(function (payload) {
  var action = payload.action;

  switch (action.type) {
    case Constants.ActionTypes.SERVICES_LOAD_SUCCESS:
      action.services.forEach(function (service) {
        store = store.setIn(['services', service.id], fromJS(service));
      });
      return ServicesStore.emitChange();
    default:
  }
});

export default ServicesStore;
