import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { prepareCodeString, resolveEditorMode } from '@/modules/shared-codes/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const INITIAL_STATE = {
  selectedCode: Map(),
  isCreating: false,
  isCreatingInline: false,
};

class PickerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onHide = this.onHide.bind(this);
    this.handleSubmitAsInline = this.handleSubmitAsInline.bind(this);
    this.handleSubmitAsSharedCode = this.handleSubmitAsSharedCode.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Shared Code</Modal.Title>
          <ModalIcon icon="code" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Select Code</ControlLabel>
            <Select
              autoFocus
              clearable={false}
              placeholder="Select code"
              value={this.state.selectedCode.get('id')}
              options={this.props.sharedCodes
                .sortBy((code) => code.get('name').toLowerCase())
                .map((code) => {
                  return { label: code.get('name'), value: code.get('id') };
                })
                .toArray()}
              onChange={(selected) =>
                this.setState({
                  selectedCode: this.props.sharedCodes.find((code) => code.get('id') === selected),
                })
              }
            />
          </FormGroup>
          {this.state.selectedCode.count() > 0 && (
            <FormGroup>
              <ControlLabel>Preview</ControlLabel>
              <CodeEditor
                value={prepareCodeString(
                  this.state.selectedCode.getIn(['configuration', 'code_content'], ''),
                )}
                options={{
                  mode: resolveEditorMode(this.props.componentId),
                  lineNumbers: false,
                  cursorHeight: 0,
                  readOnly: true,
                }}
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            <Button
              bsStyle="success"
              onClick={this.handleSubmitAsInline}
              disabled={this.state.isCreatingInline || !this.state.selectedCode.count()}
            >
              {this.state.isCreatingInline ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
              )}
              Use Inline
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.handleSubmitAsSharedCode}
              disabled={this.state.isCreating || !this.state.selectedCode.count()}
            >
              {this.state.isCreating ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
              )}
              Use as Shared Code
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }

  handleSubmitAsInline() {
    this.setState({ isCreatingInline: true });
    return this.props
      .onSubmitAsInline(
        this.state.selectedCode.get('name'),
        this.state.selectedCode.getIn(['configuration', 'code_content'], ''),
      )
      .then(this.onHide);
  }

  handleSubmitAsSharedCode() {
    this.setState({ isCreating: true });
    return this.props.onSubmitAsSharedCode(this.state.selectedCode.get('id')).then(this.onHide);
  }
}

PickerModal.propTypes = {
  sharedCodes: PropTypes.instanceOf(List).isRequired,
  onSubmitAsInline: PropTypes.func.isRequired,
  onSubmitAsSharedCode: PropTypes.func.isRequired,
  componentId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default PickerModal;
