import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import RefreshIcon from '@/react/common/RefreshIcon';
import Select from '@/react/common/Select';

type Props = {
  objectName: string;
  objectLabelKey: string;
  object: Map<string, any>;
  selectedValue?: string;
  objectIdKey?: string;
  onLoadObjectsList: (e: React.SyntheticEvent) => void;
  onSelect: (id: unknown) => void;
};

const ApifyObjectSelector = (props: Props) => {
  const { objectIdKey = 'id' } = props;

  const renderRefresh = () => {
    const isLoading = props.object.get('loading', false);
    return (
      <span>
        {isLoading ? 'Loading list of ' + props.objectName + 's... ' : null}
        <RefreshIcon isLoading={isLoading} onClick={props.onLoadObjectsList} />
      </span>
    );
  };

  const renderSelectControl = () => {
    const value = props.selectedValue;
    return (
      <InputGroup>
        <Select
          placeholder={'Select ' + props.objectName}
          name="ids"
          key="ids"
          clearable={false}
          options={getOptions()}
          value={value}
          onChange={(selectedId: unknown) => props.onSelect(selectedId)}
        />
        <InputGroup.Addon>{renderRefresh()}</InputGroup.Addon>
      </InputGroup>
    );
  };

  const getOptions = () => {
    const objectsList = props.object.get('data', List()) || List();
    return objectsList
      .sortBy((c: Map<string, any>) => c.get(props.objectLabelKey).toLowerCase())
      .map((c: Map<string, any>) => {
        return { value: c.get(objectIdKey), label: c.get(props.objectLabelKey) };
      })
      .toArray();
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isLoading = props.object.get('loading', false);
  const error = props.object.get('error');

  return (
    <div className={error ? 'form-group has-error' : 'form-group'}>
      <label className="col-xs-2 control-label">{capitalizeFirstLetter(props.objectName)}</label>
      <div className="col-xs-10">
        {isLoading || error ? (
          <FormControl.Static>
            {error} {renderRefresh()}
          </FormControl.Static>
        ) : (
          renderSelectControl()
        )}
      </div>
    </div>
  );
};

export default ApifyObjectSelector;
