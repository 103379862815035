import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { Map } from 'immutable';

import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import WorkspacesStore from '@/modules/workspaces/WorkspacesStore';
import ActionCreators from '@/modules/wr-db/actionCreators';
import { isProvisioningCredentials } from '@/modules/wr-db/helpers';
import { States } from '@/modules/wr-db/react/pages/credentials/StateConstants';
import WrDbStore from '@/modules/wr-db/store';
import Confirm from '@/react/common/Confirm';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const renderForStates = [
  States.SHOW_STORED_CREDS,
  States.CREATE_NEW_CREDS,
  States.SAVING_NEW_CREDS,
];

const ResetCredentialsButton = (componentId, driver, isProvisioning) => {
  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        WrDbStore,
        InstalledComponentsStore,
        WorkspacesStore,
        DevBranchesStore,
      ),
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const config = InstalledComponentsStore.getConfigData(componentId, configId);
      let workspace = Map();
      if (config.hasIn(['parameters', 'db', 'user'])) {
        workspace = WorkspacesStore.getConfigurationWorkspaceByConnectionUser(
          componentId,
          configId,
          config.getIn(['parameters', 'db', 'user']),
        );
      }
      const credentials = WrDbStore.getCredentials(componentId, configId);

      return {
        configId,
        credentials,
        isProvisioningCredentials: isProvisioningCredentials(credentials),
        localState: InstalledComponentsStore.getLocalState(componentId, configId),
        readOnly: ApplicationStore.isReadOnly(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        workspace,
      };
    },

    getInitialState() {
      return {
        isLoading: false,
      };
    },

    render() {
      if (
        !isProvisioning ||
        !renderForStates.includes(this.state.localState.get('credentialsState')) ||
        this.state.readOnly ||
        (this.isProvidedByKeboola() && this.state.isDevModeActive)
      ) {
        return null;
      }

      if (!this.state.credentials.count() && !this.state.workspace.count()) {
        return (
          <Button onClick={this.setInitStateAndRedirect} disabled={this.state.isLoading}>
            {this.state.isLoading ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="xmark" fixedWidth className="icon-addon-right" />
            )}
            Delete Credentials
          </Button>
        );
      }

      return (
        <Confirm
          title="Delete credentials"
          text={
            <>
              <p>Are you sure you want to delete database credentials from the configuration?</p>
              {this.isProvidedByKeboola() && (
                <Alert variant="warning" className="tw-mb-5">
                  If the database was provided by Keboola, the credentials will become invalid and
                  the database will be deleted.
                </Alert>
              )}
            </>
          }
          buttonLabel="Delete"
          onConfirm={this.handleReset}
        >
          <Button disabled={this.state.isLoading}>
            {this.state.isLoading ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="xmark" fixedWidth className="icon-addon-right" />
            )}
            Delete Credentials
          </Button>
        </Confirm>
      );
    },

    handleReset() {
      this.setState({ isLoading: true });
      ActionCreators.resetCredentials(
        componentId,
        this.state.configId,
        driver,
        this.state.isProvisioningCredentials,
        this.state.workspace,
      ).finally(this.setInitStateAndRedirect);
    },

    setInitStateAndRedirect() {
      RoutesStore.getRouter().transitionTo(componentId, { config: this.state.configId });
      InstalledComponentsActions.updateLocalState(
        componentId,
        this.state.configId,
        this.state.localState.set('credentialsState', States.INIT),
      );
    },

    isProvidedByKeboola() {
      return this.state.isProvisioningCredentials || this.state.workspace.count() > 0;
    },
  });
};

export default ResetCredentialsButton;
