import { fromJS, List, Map } from 'immutable';
import { strRight } from 'underscore.string';

import { hasOldProfiles, isOldProfile } from '@/modules/google-utils/helpers';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';
import { DEFAULT_DATE_RANGES, PREDEFINED_QUERIES } from './constants';

const prepareProfilesData = (configData, profiles) => {
  const purgedConfigData = configData
    .deleteIn(['parameters', 'profiles'])
    .deleteIn(['parameters', 'properties']);

  if (profiles.isEmpty()) {
    return purgedConfigData;
  }

  return purgedConfigData.update('parameters', Map(), (parameters) => {
    const newProfiles = profiles.reduce(
      (categorizedProfiles, profile) => {
        const key = isOldProfile(profile) ? 'profiles' : 'properties';

        return {
          ...categorizedProfiles,
          [key]: [...categorizedProfiles[key], profile],
        };
      },
      { profiles: [], properties: [] },
    );

    return parameters.merge(fromJS(newProfiles).filter((profiles) => !profiles.isEmpty()));
  });
};

const prepareQuery = (parameters, rowName, endpoint) => {
  let query = parameters.set('endpoint', endpoint);

  if (!query.hasIn(['query', 'dateRanges'])) {
    query = query.setIn(['query', 'dateRanges'], DEFAULT_DATE_RANGES);
  }

  if (!query.get('outputTable')) {
    query = query.set('outputTable', string.sanitizeKbcTableIdString(rowName));
  }

  return query;
};

const getProfileName = (profile) => {
  if (isOldProfile(profile)) {
    return `${profile.get('name')} (${profile.get('webPropertyId')}/${profile.get('id')})`;
  }

  return `GA4 - Data API (${strRight(profile.get('propertyKey'), 'properties/')})`;
};

const getProfileFullName = (profile) => {
  const name = getProfileName(profile);

  if (isOldProfile(profile)) {
    return `${profile.get('accountName')} / ${profile.get('webPropertyName')} / ${name}`;
  }

  return `${profile.get('accountName')} / ${profile.get('propertyName')} / ${name}`;
};

const getPredefinedQueries = (config) => {
  if (hasOldProfiles(config.get('configuration', Map()))) {
    return PREDEFINED_QUERIES.OLD_API;
  }

  return PREDEFINED_QUERIES.NEW_API;
};

const getSelectedPredefinedQuery = (config) => {
  const predefinedQueries = getPredefinedQueries(config);

  return Object.keys(predefinedQueries).find((queryKey) => {
    return config
      .get('rows', List())
      .map((row) => row.getIn(['configuration', 'parameters'], Map()))
      .equals(fromJSOrdered(predefinedQueries[queryKey]));
  });
};

const authorizeConfiguration = (configuration, oauthCredentials) => {
  return configuration.setIn(['authorization', 'oauth_api'], {
    credentials: {
      appKey: oauthCredentials.get('appKey'),
      '#data': oauthCredentials.get('#data'),
      '#appSecret': oauthCredentials.get('#appSecret'),
    },
  });
};

export {
  prepareProfilesData,
  prepareQuery,
  getProfileName,
  getProfileFullName,
  getPredefinedQueries,
  getSelectedPredefinedQuery,
  authorizeConfiguration,
};
