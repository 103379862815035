import {
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KEBOOLA_EX_DB_DB_2,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KEBOOLA_EX_DB_IMPALA,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_TERADATA,
} from '@/constants/componentIds';

const ports = {
  [KEBOOLA_EX_DB_PGSQL]: 5432,
  [KEBOOLA_EX_DB_REDSHIFT]: 5439,
  [KEBOOLA_EX_DB_DB_2]: 50000,
  [KEBOOLA_EX_DB_INFORMIX]: 9088,
  [KEBOOLA_EX_DB_IMPALA]: 21050,
  [KEBOOLA_EX_DB_HIVE]: 10000,
  [KEBOOLA_EX_DB_HIVE_CSAS]: 10004,
  [KEBOOLA_EX_DB_HIVE_CSAS_TEST]: 10004,
  [KEBOOLA_EX_DB_MYSQL]: 3306,
  [KEBOOLA_EX_DB_ORACLE]: 1521,
  [KEBOOLA_EX_DB_MSSQL]: 1433,
  [KEBOOLA_EX_DB_MSSQL_CDATA]: 1433,
  [KEBOOLA_EX_DB_SNOWFLAKE]: 443,
  [KDS_TEAM_EX_NETSUITE]: 1708,
  [KDS_TEAM_EX_PROGRESS_DB]: 1708,
  [KEBOOLA_EX_TERADATA]: 1025,
};

export default function (componentId) {
  return ports[componentId];
}
