import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';

const createConfiguration = (componentId, name, description) => {
  return InstalledComponentsActionCreators.createConfiguration(componentId, { name, description });
};

const createSampleDataConfiguration = (componentId, name, description) => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_EX_SAMPLE_DATA, {
    name,
    description,
    configuration: JSON.stringify({ parameters: { componentId } }),
  });
};

export { createConfiguration, createSampleDataConfiguration };
