import React from 'react';
import { Button } from 'react-bootstrap';

import LoadingBlock from '@/react/common/LoadingBlock';
import contactSupport from '@/utils/contactSupport';

const LoadingData = (props: { isLoading: boolean; usageBoardIsNotAvailable?: boolean }) => {
  if (props.isLoading) {
    return <LoadingBlock label="Preparing data..." className="tw-h-12" />;
  }

  if (props.usageBoardIsNotAvailable) {
    return (
      <div className="box">
        <div className="tw-px-4 tw-py-8 tw-text-center">
          <h3 className="m-0 tw-text-base tw-font-medium">
            The Organization Usage dashboard is not available.
          </h3>
          <p className="tw-mx-auto tw-my-4 tw-max-w-[550px] tw-text-neutral-400">
            Organization Usage is available only to our customers with a contract. Your organization
            is not linked to any customer account. Please contact your Keboola account manager or
            our support.
          </p>
          <Button bsStyle="success" onClick={() => contactSupport()}>
            Contact support
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="box">
      <div className="box-content">
        <p className="m-0">Dashboard is not available.</p>
      </div>
    </div>
  );
};

export default LoadingData;
