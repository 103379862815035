import React from 'react';

type Props = {
  show?: boolean;
  children?: React.ReactNode;
};

const Spinner = ({ show, children }: Props) => {
  return <div className={show ? 'loading-spinner' : ''}>{children}</div>;
};

export default Spinner;
