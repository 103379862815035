import { GENEEA_NLP_ANALYSIS_V_2 as componentId } from '@/constants/componentIds';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import HeaderButtons from './react/HeaderButtons';
import Index from './react/Index';

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: Index,
  headerButtonsHandler: HeaderButtons,
  requireData: [(params) => configRequiredData(componentId, params.config)],
  poll: configPoll(componentId),
};
