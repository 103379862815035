import { fromJS, Map } from 'immutable';

import Dispatcher from '@/Dispatcher';
import { actionTypes } from '@/modules/orchestrations-v2/constants';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'StorageTriggersStore',
  Map({
    triggers: Map(),
    isLoaded: false,
  }),
);

const StorageTriggersStore = StoreUtils.createStore({
  get(component, configurationId) {
    return _store.getIn(['triggers', component, configurationId], Map());
  },

  getForComponent(component) {
    return _store.getIn(['triggers', component], Map());
  },

  getAll() {
    return _store.get('triggers', Map());
  },

  getIsLoaded() {
    return _store.get('isLoaded', false);
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case actionTypes.TRIGGERS_LOAD_SUCCESS: {
      const triggers = fromJS(action.triggers)
        .toMap()
        .mapKeys((key, trigger) => trigger.get('configurationId'))
        .groupBy((trigger) => trigger.get('component'));

      _store = _store.set('triggers', triggers);
      _store = _store.set('isLoaded', true);
      return StorageTriggersStore.emitChange();
    }

    case actionTypes.TRIGGERS_DELETE_SUCCESS:
      _store = _store.update('triggers', (components) => {
        return components.map((triggers) =>
          triggers.filter((trigger) => trigger.get('id') !== action.id),
        );
      });
      return StorageTriggersStore.emitChange();

    case actionTypes.TRIGGERS_CREATE_SUCCESS:
    case actionTypes.TRIGGERS_UPDATE_SUCCESS:
      _store = _store.setIn(
        ['triggers', action.trigger.component, action.trigger.configurationId],
        fromJS(action.trigger),
      );
      return StorageTriggersStore.emitChange();

    default:
  }
});

export default StorageTriggersStore;
