const isValidJsonConfig = (value) => {
  try {
    const json = JSON.parse(value);
    return !!(json && typeof json === 'object');
  } catch (error) {
    return false;
  }
};

export { isValidJsonConfig };
