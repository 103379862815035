import React, { useMemo, useState } from 'react';
import type { List, Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import BlankComponentsPage from '@/modules/components/react/pages/BlankComponentsPage';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import FilterPanel from '@/react/common/FilterPanel';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import DataApps from './components/DataApps';

const Index = () => {
  const store = useStores(
    () => {
      return {
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(KEBOOLA_DATA_APPS),
        sandboxes: SandboxesStore.getSandboxes() as Map<string, any>,
        processingJobs: SandboxesStore.getProcessingJobs() as List<any>,
        pendingActions: SandboxesStore.getPendingActions() as Map<string, any>,
        dataApps: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_DATA_APPS),
        allConfigurations: InstalledComponentsStore.getAll(),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        expandedFolders: InstalledComponentsStore.getExpandedFolders('data app'),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasFlows: ApplicationStore.hasFlows(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
      };
    },
    [],
    [SandboxesStore, InstalledComponentsStore, ComponentsStore, ApplicationStore],
  );
  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const filteredDataApps = useMemo(
    () =>
      store.dataApps.filter((dataApp: Map<string, any>) =>
        matchByWords([dataApp.get('name'), dataApp.get('description')], filterQuery),
      ),
    [store.dataApps, filterQuery],
  );

  if (store.dataApps.isEmpty()) {
    return <BlankComponentsPage type="data-app" />;
  }

  return (
    <>
      <FilterPanel
        placeholder={`Search data apps (${filteredDataApps.count()})`}
        query={filterQuery}
        onChange={(query) => {
          setFilterQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
      />
      {filterQuery && filteredDataApps.isEmpty() ? (
        <NoResultsFound entityName="data app" />
      ) : (
        <DataApps
          dataApps={filteredDataApps}
          filterQuery={filterQuery}
          component={store.component}
          allConfigurations={store.allConfigurations}
          componentsMetadata={store.componentsMetadata}
          readOnly={store.readOnly}
          hasNewQueue={store.hasNewQueue}
          hasFlows={store.hasFlows}
          hasPayAsYouGo={store.hasPayAsYouGo}
          admins={store.admins}
          currentAdmin={store.currentAdmin}
          sandboxes={store.sandboxes}
          pendingActions={store.pendingActions}
          processingJobs={store.processingJobs}
        />
      )}
    </>
  );
};

export default Index;
