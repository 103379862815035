import React from 'react';
import PropTypes from 'prop-types';

import { getProjectStackOptions } from '@/modules/ex-storage/helpers';
import Select from '@/react/common/Select';

class ProjectStackSelector extends React.Component {
  render() {
    return (
      <Select
        clearable={false}
        placeholder="Select stack"
        value={this.props.value}
        onChange={(selected) => this.props.onChange(selected)}
        disabled={this.props.isDisabled}
        options={getProjectStackOptions(this.props.isSingleTenantStack)}
      />
    );
  }
}

ProjectStackSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSingleTenantStack: PropTypes.bool.isRequired,
};

export default ProjectStackSelector;
