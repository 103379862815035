import React from 'react';
import type { List, Map } from 'immutable';

import VersionsStore from '@/modules/components/stores/VersionsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import RowVersionsStore from '@/modules/configurations/RowVersionsStore';
import nextTick from '@/utils/nextTick';
import { SIDE } from './SplitDiff/constants';
import type { SIDE_OPTIONS } from './SplitDiff/SplitDiff';
import SplitDiff, { SplitDiffTitle } from './SplitDiff/SplitDiff';
import Checkbox from './Checkbox';
import FullScreenEditor from './FullScreenEditor';
import {
  haveConfigurationQueriesChanged,
  haveConfigurationsOnlyQueriesChanged,
  prepareQueriesDiffData,
} from './VersionsDiffModalHelpers';

type Props = {
  show: boolean;
  component: Map<string, any>;
  admins: Map<string, any>;
  referentialVersion: Map<string, any>;
  compareVersion: Map<string, any>;
  sharedCodes: List<any>;
  config?: Map<string, any>;
  row?: Map<string, any>;
  isBranchDiff?: boolean;
  hideRollback?: boolean;
  additionalButtons?: () => React.ReactNode;
  noChangesPlaceholder?: React.ReactNode;
  helpText?: React.ReactNode;
  title?: React.ReactNode;
  onClose: () => void;
};

const VersionsDiffModal = (props: Props) => {
  const [leftVersion, setLeftVersion] = React.useState(props.compareVersion);
  const [rightVersion, setRightVersion] = React.useState(props.referentialVersion);
  const [detailedDiff, setDetailedDiff] = React.useState(false);
  const [hasQueriesChanges, setQueriesChanges] = React.useState(false);

  const setQueries = React.useCallback(() => {
    setLeftVersion(props.compareVersion);
    setRightVersion(props.referentialVersion);
  }, [props.compareVersion, props.referentialVersion]);

  const checkQueries = React.useCallback(() => {
    const hasQueriesChanges = haveConfigurationQueriesChanged(
      props.component.get('id'),
      leftVersion,
      rightVersion,
    );

    const hasOnlyQueriesChanges =
      hasQueriesChanges &&
      haveConfigurationsOnlyQueriesChanged(props.component.get('id'), leftVersion, rightVersion);

    setQueriesChanges(hasQueriesChanges);
    setDetailedDiff(hasOnlyQueriesChanges);
  }, [leftVersion, rightVersion, props.component]);

  React.useEffect(() => {
    if (props.show) {
      nextTick(checkQueries);
    }
  }, [props.show, checkQueries]);

  const handleChangeVersion = React.useCallback(
    (version: number, side: SIDE_OPTIONS) => {
      if (!props.config || !version) {
        return Promise.resolve();
      }

      return Promise.resolve()
        .then(() => {
          if (!!props.row) {
            return RowVersionsActionCreators.loadComponentConfigByVersion(
              props.component.get('id'),
              props.config?.get('id'),
              props.row.get('id'),
              version,
            ).then(() => {
              return RowVersionsStore.getConfigByVersion(
                props.component.get('id'),
                props.config?.get('id'),
                props.row?.get('id'),
                version,
              );
            });
          }

          return VersionsActionCreators.loadComponentConfigByVersion(
            props.component.get('id'),
            props.config?.get('id'),
            version,
          ).then(() => {
            return VersionsStore.getConfigByVersion(
              props.component.get('id'),
              props.config?.get('id'),
              version,
            );
          });
        })
        .then(side === SIDE.LEFT ? setLeftVersion : setRightVersion);
    },
    [props.component, props.config, props.row],
  );

  const renderEditor = () => {
    return (
      <SplitDiff
        componentId={props.component.get('id')}
        config={props.config}
        row={props.row}
        branchDiff={props.isBranchDiff || false}
        rightVersion={rightVersion}
        leftVersion={leftVersion}
        noChangesPlaceholder={props.noChangesPlaceholder}
        hideRollback={props.hideRollback}
        admins={props.admins}
        handleChangeVersion={handleChangeVersion}
        {...(detailedDiff && {
          prepareData: (version) => {
            return prepareQueriesDiffData(props.component.get('id'), version, props.sharedCodes);
          },
        })}
      />
    );
  };

  const renderTitle = () => {
    return (
      <SplitDiffTitle
        component={props.component}
        name={props.title || props.referentialVersion.get('name')}
        helpText={props.helpText}
      />
    );
  };

  const renderButtons = () => {
    return (
      <>
        {hasQueriesChanges && (
          <>
            <Checkbox checked={detailedDiff} onChange={setDetailedDiff}>
              Detailed diff for queries or scripts
            </Checkbox>
            <span className="btn-separator" />
          </>
        )}
        {props.additionalButtons?.()}
      </>
    );
  };

  if (!props.show) {
    return null;
  }

  return (
    <FullScreenEditor
      onEnter={setQueries}
      onClose={props.onClose}
      renderEditor={renderEditor}
      renderTitle={renderTitle}
      renderButtons={renderButtons}
      className="with-scroll"
    />
  );
};

export default VersionsDiffModal;
