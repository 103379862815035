import { URLS } from '@keboola/constants';

import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import createColumnsEditorSection from '@/modules/configurations/utils/createColumnsEditorSection';
import createRoute from '@/modules/configurations/utils/createRoute';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import loadType from './adapters/loadType';
import rowConform from './adapters/rowConform';
import serviceAccount from './adapters/serviceAccount';
import targetDataset from './adapters/targetDataset';
import targetTable from './adapters/targetTable';
import columnsEditorDefinition from './helpers/columnsEditorDefinition';
import LoadTypeSection from './react/components/LoadTypeSection';
import ServiceAccountSection from './react/components/ServiceAccountSection';
import TargetDatasetSection from './react/components/TargetDatasetSection';
import TargetTableSection from './react/components/TargetTableSection';

const routeSettings = {
  componentId: 'keboola.wr-google-bigquery-v2',
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'Google Service Account Key',
          contentComponent: ServiceAccountSection,
          options: {
            includeSaveButtons: true,
            additionalProps: {
              documentationLink: `${URLS.USER_DOCUMENTATION}/components/writers/database/bigquery/#create-service-account`,
            },
          },
        }),
        onSave: serviceAccount.createConfiguration,
        onLoad: serviceAccount.parseConfiguration,
        isComplete: serviceAccount.isComplete,
      },
      {
        render: CollapsibleSection({
          title: 'Google BigQuery Dataset',
          contentComponent: TargetDatasetSection,
          options: { includeSaveButtons: true },
        }),
        onSave: targetDataset.createConfiguration,
        onLoad: targetDataset.parseConfiguration,
        isComplete: targetDataset.isComplete,
      },
    ],
  },
  row: {
    onConform: rowConform,
    parseTableId: (row) => row.getIn(['storage', 'input', 'tables', 0, 'source']),
    hasState: false,
    sections: [
      {
        render: TargetTableSection,
        onSave: targetTable.createConfiguration,
        onLoad: targetTable.parseConfiguration,
        onCreate: targetTable.createEmptyConfiguration,
      },
      {
        render: LoadTypeSection,
        onSave: loadType.createConfiguration,
        onLoad: loadType.parseConfiguration,
        onCreate: loadType.createEmptyConfiguration,
      },
      createColumnsEditorSection(columnsEditorDefinition),
    ],
    columns: [
      {
        name: 'Source Table',
        type: columnTypes.TABLE_LINK,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'source'], 'Unknown');
        },
      },
      {
        name: 'BigQuery Table',
        type: columnTypes.VALUE,
        value: function (row) {
          return row.getIn(['parameters', 'tables', 0, 'dbName'], 'Unknown');
        },
      },
    ],
  },
};

const result = createRoute(routeSettings);

export default result;
