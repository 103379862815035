import { Map } from 'immutable';

import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { loadBucketDetail, tokenVerify } from '@/modules/storage/actions';
import { routeNames } from './constants';
import Index from './Index';

export default {
  name: routeNames.BUCKET_PREVIEW,
  title: (routerState) => {
    const bucketId = routerState.getIn(['params', 'bucketId']);
    const bucket = StorageBucketsStore.getBucket(bucketId, Map());
    return `Bucket ${bucket.get('displayName', bucketId)}`;
  },
  requireData: [() => tokenVerify(), (params) => loadBucketDetail(params.bucketId)],
  path: 'bucket-preview/:bucketId(/tab/:bucketTab)',
  defaultRouteHandler: Index,
};
