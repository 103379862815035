import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import { updateWorkspaceParameters } from '@/modules/workspaces/helpers';
import WorkspacesActions from '@/modules/workspaces/WorkspacesActions';
import constants from './constants';
import provisioningUtils from './provisioningUtils';
import store from './store';
import WritersApi from './WritersApi';

export default {
  getProvisioningCredentials(componentId, configId, driver) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_START,
      componentId,
      configId,
    });
    return provisioningUtils
      .getCredentialsWithoutTokenCreation(driver, componentId, configId)
      .then((result) => {
        const credentials = fromJS(result);
        if (credentials.count() > 0) {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_SUCCESS,
            componentId,
            configId,
            credentials,
          });
        } else {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_NO_CREDENTIALS,
            componentId,
            configId,
          });
        }
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  createWorkspaceCredentials(componentId, configId, backend, readOnlyStorageAccess) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_START,
      componentId,
      configId,
    });
    return WorkspacesActions.createConfigurationWorkspace(componentId, configId, {
      backend,
      readOnlyStorageAccess,
    })
      .then((workspace) => {
        const connection = updateWorkspaceParameters(workspace).get('connection');
        const credentials = connection
          .set('#password', connection.get('password'))
          .delete('password');

        return this.saveCredentials(componentId, configId, credentials).then(() =>
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_SUCCESS,
            componentId,
            configId,
            credentials,
          }),
        );
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  resetWorkspacePassword(componentId, configId, oldCredentials, workspaceId) {
    return WorkspacesActions.resetWorkspacePassword(componentId, configId, workspaceId).then(
      (workspace) => {
        const credentials = oldCredentials.set('#password', workspace.get('password'));
        return this.saveCredentials(componentId, configId, credentials).then(() =>
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_LOAD_PROVISIONING_SUCCESS,
            componentId,
            configId,
            credentials,
          }),
        );
      },
    );
  },

  loadTableConfig(componentId, configId, tableId) {
    if (store.hasTableConfig(componentId, configId, tableId)) {
      return Promise.resolve();
    }
    return this.loadTableConfigForce(componentId, configId, tableId);
  },

  loadTableConfigForce(componentId, configId, tableId) {
    return WritersApi(componentId)
      .getTable(configId, tableId)
      .then((result) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_GET_TABLE_SUCCESS,
          componentId,
          configId,
          tableId,
          tableConfig: result,
        });
        return result;
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  resetCredentials(componentId, configId, driver, isProvisioning, workspace) {
    return Promise.resolve()
      .then(() => {
        if (workspace.count() !== 0) {
          return WorkspacesActions.deleteConfigurationWorkspace(
            componentId,
            configId,
            workspace.get('id'),
          );
        }
        if (isProvisioning) {
          return this.getProvisioningCredentials(componentId, configId, driver).then(() => {
            const credentials = store.getProvisioningCredentials(componentId, configId);
            return provisioningUtils.clearAll(componentId, configId, driver, credentials);
          });
        }
        return null;
      })
      .then(() => this.saveCredentials(componentId, configId, Map()));
  },

  setEditingData(componentId, configId, path, data) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_SET_EDITING,
      componentId,
      configId,
      path,
      data,
    });
  },

  addTableToConfig(componentId, configId, tableId, sapiTable) {
    const table = {
      id: tableId,
      dbName: sapiTable.get('name'),
      name: sapiTable.get('name'),
      export: true,
    };
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_ADD_TABLE_START,
      componentId,
      configId,
      tableId,
      table,
    });
    return WritersApi(componentId)
      .postTable(configId, tableId, table, sapiTable)
      .then(() => {
        return this.loadTableConfigForce(componentId, configId, tableId).then((loadedTable) => {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_ADD_TABLE_SUCCESS,
            componentId,
            configId,
            tableId,
            table: { ...table, dbName: loadedTable.get('dbName') },
          });
        });
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  saveCredentials(componentId, configId, credentials) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_SAVE_CREDENTIALS_START,
      componentId,
      configId,
      credentials,
    });
    return WritersApi(componentId)
      .postCredentials(configId, credentials.toJS())
      .then((configuration) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_SAVE_CREDENTIALS_SUCCESS,
          componentId,
          configId,
          credentials: configuration.parameters.db,
        });
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          errorPath: ['savingCredentials', componentId, configId],
          error: err,
        });
        throw err;
      });
  },

  loadConfiguration(componentId, configId) {
    if (store.hasConfiguration(componentId, configId)) {
      return Promise.resolve();
    }
    return this.loadConfigurationForce(componentId, configId);
  },

  loadConfigurationForce(componentId, configId) {
    return Promise.props({
      componentId,
      configId,
      credentials: WritersApi(componentId).getCredentials(configId),
      lookerCredentials: WritersApi(componentId).getLookerCredentials(configId),
      tables: WritersApi(componentId).getTables(configId),
    })
      .then((result) =>
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_GET_CONFIGURATION_SUCCESS,
          componentId,
          configId,
          config: result,
        }),
      )
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },
  deleteTable(componentId, configId, tableId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_DELETE_TABLE_START,
      componentId,
      configId,
      tableId,
    });

    return WritersApi(componentId)
      .deleteTable(configId, tableId)
      .then(() =>
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_DELETE_TABLE_SUCCESS,
          componentId,
          configId,
          tableId,
        }),
      )
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  saveTableColumns(componentId, configId, tableId, columns) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_SAVE_COLUMNS_START,
      componentId,
      configId,
      tableId,
      columns,
    });

    return WritersApi(componentId)
      .setTableColumns(configId, tableId, columns.toJS())
      .then(() =>
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_SAVE_COLUMNS_SUCCESS,
          componentId,
          configId,
          tableId,
          columns,
        }),
      )
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },

  setTableToExport(componentId, configId, tableId, dbName, isExported) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.WR_DB_SET_TABLE_START,
      componentId,
      configId,
      tableId,
    });
    return WritersApi(componentId)
      .setTable(configId, tableId, {
        dbName: dbName,
        export: isExported ? 1 : 0,
      })
      .then(() => {
        return this.loadTableConfigForce(componentId, configId, tableId).then((loadedTable) => {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.WR_DB_SET_TABLE_SUCCESS,
            componentId,
            configId,
            tableId,
            dbName: loadedTable.get('dbName'),
            isExported,
          });
        });
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.WR_DB_API_ERROR,
          componentId,
          configId,
          error: err,
        });
        throw err;
      });
  },
};
