import Promise from 'bluebird';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import RowsVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
// OLD WR DB MODULES and stuff
import WorkspacesActions from '@/modules/workspaces/WorkspacesActions';
import { configPoll, rowPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import ReadOnlyStorageAlert from './react/components/ReadOnlyStorageAlert';
import ResetCredentialsButton from './react/components/ResetCredentialsButton';
import dbWrCredentialsDetail from './react/pages/credentials/Credentials';
import dbwrIndex from './react/pages/index/Index';
import dbWrRowDetail from './react/pages/row/Row';
import dbWrTableDetail from './react/pages/table/Table';
import dbWrActionCreators from './actionCreators';
import { supportConfigRows } from './helpers';

export default function routes(componentsSetting) {
  return componentsSetting.map(([componentId, driver, isProvisioning = false]) => {
    return {
      name: componentId,
      path: `${componentId}/:config`,
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        return InstalledComponentsStore.getConfig(componentId, configId).get('name');
      },
      poll: configPoll(componentId),
      infoAlertHandler: ReadOnlyStorageAlert,
      defaultRouteHandler: dbwrIndex(componentId, driver, isProvisioning),
      requireData: [
        (params) => {
          return configRequiredData(componentId, params.config, () => {
            return dbWrActionCreators.loadConfiguration(componentId, params.config);
          });
        },
        (params) => {
          if (!isProvisioning || params.tableId || params.row) {
            return Promise.resolve();
          }

          return WorkspacesActions.getConfigurationWorkspaces(componentId, params.config);
        },
      ],
      childRoutes: [
        {
          name: componentId + '-table',
          path: 'table/:tableId',
          defaultRouteHandler: dbWrTableDetail(componentId),
          title: (routerState) => {
            return routerState.getIn(['params', 'tableId']);
          },
          requireData: [
            (params) => {
              return dbWrActionCreators.loadTableConfig(componentId, params.config, params.tableId);
            },
          ],
        },
        {
          name: componentId + '-credentials',
          path: 'credentials',
          title: 'Credentials',
          defaultRouteHandler: dbWrCredentialsDetail(componentId, driver, isProvisioning),
          headerButtonsHandler: ResetCredentialsButton(componentId, driver, isProvisioning),
        },
        supportConfigRows(componentId) && {
          name: componentId + '-row',
          path: 'rows/:row',
          title: (routerState) => {
            const configId = routerState.getIn(['params', 'config']);
            const rowId = routerState.getIn(['params', 'row']);
            const configurationRow = ConfigurationRowsStore.get(componentId, configId, rowId);
            return configurationRow.get('name');
          },
          poll: rowPoll(componentId),
          requireData: [
            (params) =>
              RowsVersionsActionCreators.loadVersions(componentId, params.config, params.row),
          ],
          defaultRouteHandler: dbWrRowDetail(componentId),
          headerButtonsHandler: null,
        },
      ].filter(Boolean),
    };
  });
}
