import React from 'react';
import classnames from 'classnames';
import type { BadgeProps } from 'design';
import { Badge } from 'design';

import { STAGE } from '@/modules/storage/constants';

const BucketStageLabel = ({
  stage,
  round,
  className,
  placement,
}: {
  stage?: string;
  round?: boolean;
  className?: string;
  placement?: BadgeProps['placement'];
}) => {
  if (!stage) {
    return null;
  }

  return (
    <Badge
      text={stage}
      asTag={!round}
      placement={placement}
      variant={stage === STAGE.IN ? 'blue' : 'teal'}
      className={classnames('tw-min-w-10 tw-uppercase', className)}
    />
  );
};

export default BucketStageLabel;
