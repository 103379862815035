import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import string from '@/utils/string';

const TasksSummary = ({ orchestration }) => {
  const tasks = orchestration.getIn(['configuration', 'tasks'], List()).count();
  const phases = orchestration.getIn(['configuration', 'phases'], List()).count();

  if (tasks === 0) {
    return 'No assigned tasks';
  }

  return `${tasks} ${string.pluralize(tasks, 'task')} in ${phases} ${string.pluralize(
    phases,
    'phase',
  )}`;
};

TasksSummary.propTypes = {
  orchestration: PropTypes.instanceOf(Map).isRequired,
};

export default TasksSummary;
