import { s3Regions } from './constants';

/*
fields: {
  [backend: string]: {
    type: 'select' | 'text' | 'password' | 'checkbox'
    label: string
    key: string
    help?: string
    info?:
      | type == 'select' -> { options?: { value: string, label: string }[] }
      | type == 'text' -> { placeholder?: string }
      | type == 'password' -> { placeholder?: string }
      | type == 'checkbox' -> void
  }
}
*/

const fields = {
  s3: [
    { type: 'select', label: 'Region', key: 'region', info: { options: s3Regions } },
    {
      type: 'text',
      label: 'Access Key ID',
      key: 'access_key_id',
    },
    {
      type: 'password',
      label: 'Secret Access Key',
      key: '#secret_access_key',
    },
    {
      type: 'text',
      label: 'Bucket',
      key: '#bucket',
      info: { placeholder: 'my-bucket' },
    },
    {
      type: 'text',
      label: 'Path',
      key: 'backupPath',
      info: { placeholder: 'my-backup-folder' },
    },
    {
      type: 'checkbox',
      label: 'Export project structure only',
      key: 'exportStructureOnly',
      help: 'Only bucket and table metadata and component configurations will be exported.',
    },
  ],
  abs: [
    {
      type: 'text',
      label: 'Account Name',
      key: 'accountName',
    },
    {
      type: 'password',
      label: 'Account Key',
      key: '#accountKey',
    },
    {
      type: 'text',
      label: 'Container',
      key: 'backupPath',
      help: 'It is recommended to backup the project into a new container each time',
      info: { placeholder: 'my-container' },
    },
    {
      type: 'checkbox',
      label: 'Export project structure only',
      key: 'exportStructureOnly',
      help: 'Only bucket and table metadata and component configurations will be exported.',
    },
  ],
};

export { fields };
