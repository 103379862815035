import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import pushGtmEvent, { events as gtmEvents } from '@/utils/pushGtmEvent';

const flattenBreadcrumbs = (breadcrumbs) => {
  return breadcrumbs
    .map((page) => renderToStaticMarkup(page.get('title')))
    .map((title) => {
      const div = document.createElement('div');
      div.innerHTML = title;
      return div.textContent || div.innerText || '';
    })
    .join(' / ');
};

const PageTitle = () => {
  const title = useStores(
    () => {
      const icon = RoutesStore.getCurrentRouteIsRunning() ? '▶ ' : '';
      const projectName = ApplicationStore.getCurrentProject().get('name');
      const page = flattenBreadcrumbs(RoutesStore.getBreadcrumbs());

      return `${icon}${projectName} - ${page}`;
    },
    [],
    [RoutesStore],
  );

  React.useEffect(() => {
    document.title = title;
    pushGtmEvent(gtmEvents.EVENT_PAGEVIEW);
  }, [title]);

  return null;
};

export default PageTitle;
