import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { fromJS, List, Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { automateComponent, ORCHESTRATION_SETUP } from '@/constants/external';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import OrchestrationActionCreators from '@/modules/orchestrations/ActionCreators';
import ScheduleTimezone from '@/modules/orchestrations-v2/components/ScheduleTimezone';
import { getPeriodForCrontab } from '@/modules/orchestrations-v2/helpers';
import queueApi from '@/modules/queue/api';
import { createAndActivateScheduler } from '@/modules/scheduler/actions';
import Confirm from '@/react/common/Confirm';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import CronScheduler from '@/react/common/CronScheduler';
import Select from '@/react/common/Select';
import configurationScheduled from './notifications/configurationScheduled';
import ConfigurationScheduleStatus from './ConfigurationScheduleStatus';

const CUSTOM_SCHEDULE_PLAN = 'custom';
const scheduleOptions = [
  { label: 'Custom schedule', value: CUSTOM_SCHEDULE_PLAN },
  { label: 'Every 5 minutes', value: '*/5 * * * *' },
  { label: 'Every 10 minutes', value: '*/10 * * * *' },
  { label: 'Twice per hour', value: '*/30 * * * *' },
  { label: 'Once per hour', value: '1 * * * *' },
  { label: 'Once per 6 hours', value: '0 */6 * * *' },
  { label: 'Twice a day (5 am, 5 pm)', value: '0 5,17 * * *' },
  { label: 'Once a day (12 pm)', value: '0 12 * * *' },
];

/** @type {any} */
const ScheduleConfigurationButton = createReactClass({
  propTypes: {
    flows: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    config: PropTypes.instanceOf(Map).isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(['link', 'button', 'menuitem']),
    linkToIndex: PropTypes.bool,
    onKeyDown: PropTypes.func,
  },

  getDefaultProps() {
    return {
      mode: 'link',
      linkToIndex: false,
    };
  },

  getInitialState() {
    const customCrontabRecord = '0 0 * * *';

    return {
      customCrontabRecord,
      name: '',
      timezone: 'UTC',
      predefinedCrontabRecord: '1 * * * *',
      customCrontabPeriod: getPeriodForCrontab(customCrontabRecord),
      isLoading: false,
      isLoadingJobs: false,
      automatedFlows: Map(),
      configurationLastJobs: Map(),
    };
  },

  render() {
    if (
      this.props.config.isEmpty() ||
      this.props.component.get('flags', List()).includes('excludeRun')
    ) {
      return null;
    }

    if (this.props.mode === 'menuitem') {
      return (
        <ConfirmMenuItem
          closeAfterResolve
          icon="clock"
          title={`Automate ${this.props.config.get('name')}`}
          onConfirm={this.handleSubmit}
          isLoading={this.state.isLoading}
          onKeyDown={this.props.onKeyDown}
          text={this.renderBody()}
          buttonType="success"
          buttonLabel="Automate"
          modalClass="scheduler-modal"
        >
          <FontAwesomeIcon icon="clock" fixedWidth />
          Automate
        </ConfirmMenuItem>
      );
    }

    return (
      <Confirm
        closeAfterResolve
        icon="clock"
        title={`Automate ${this.props.config.get('name')}`}
        onConfirm={this.handleSubmit}
        isLoading={this.state.isLoading}
        text={this.renderBody()}
        buttonType="success"
        buttonLabel="Automate"
        modalClass="scheduler-modal"
        buttonClass={`${ORCHESTRATION_SETUP} ${automateComponent(this.props.component)}`}
        childrenRootElement={this.props.mode === 'link' ? 'a' : 'span'}
        onEnter={this.loadData}
      >
        {this.props.mode === 'link' ? (
          <>
            <FontAwesomeIcon icon="clock" fixedWidth />
            Automate
          </>
        ) : (
          <Button bsStyle="success">
            <FontAwesomeIcon icon="clock" className="icon-addon-right" />
            Automate
          </Button>
        )}
      </Confirm>
    );
  },

  renderBody() {
    return (
      <div className="form">
        <p className="line-height-24">
          You are about to create a single task {this.props.hasFlows ? 'flow' : 'orchestration'}{' '}
          with a given name and schedule.
        </p>
        {!this.state.automatedFlows.isEmpty() && (
          <ConfigurationScheduleStatus
            flows={this.state.automatedFlows}
            hasFlows={this.props.hasFlows}
            hasNewQueue={this.props.hasNewQueue}
            isLoading={this.state.isLoadingJobs}
            lastJobs={this.state.configurationLastJobs}
          />
        )}
        <FormGroup>
          <ControlLabel>{this.props.hasFlows ? 'Flow' : 'Orchestration'} name</ControlLabel>
          <FormControl
            type="text"
            placeholder={`Scheduled ${this.props.config.get('name')}`}
            onChange={this.handleName}
            value={this.state.name}
          />
          <HelpBlock>Leave blank to use the default name</HelpBlock>
        </FormGroup>
        <FormGroup>
          <div className="flex-container tw-mb-[5px]">
            <ControlLabel className="tw-m-0">Schedule</ControlLabel>
            <ScheduleTimezone
              onChange={(timezone) => this.setState({ timezone })}
              crontabTimezone={this.state.timezone}
            />
          </div>
          <Select
            autoFocus
            clearable={false}
            onChange={this.handlePredefinedCrobtabRecord}
            value={this.state.predefinedCrontabRecord}
            options={scheduleOptions}
          />
        </FormGroup>
        {this.state.predefinedCrontabRecord === CUSTOM_SCHEDULE_PLAN && (
          <CronScheduler
            showPreview
            crontabRecord={this.state.customCrontabRecord}
            period={this.state.customCrontabPeriod}
            onChange={this.handleCustomCrobtabRecord}
          />
        )}
      </div>
    );
  },

  handleName(event) {
    this.setState({ name: event.target.value });
  },

  handlePredefinedCrobtabRecord(value) {
    this.setState({ predefinedCrontabRecord: value });
  },

  handleCustomCrobtabRecord(customCrontabRecord, customCrontabPeriod) {
    this.setState({ customCrontabRecord, ...(customCrontabPeriod && { customCrontabPeriod }) });
  },

  handleSubmit() {
    const name = this.state.name || `Scheduled ${this.props.config.get('name')}`;
    const crontabRecord =
      this.state.predefinedCrontabRecord === CUSTOM_SCHEDULE_PLAN
        ? this.state.customCrontabRecord
        : this.state.predefinedCrontabRecord;

    if (this.props.hasNewQueue) {
      this.setState({ isLoading: true });
      return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_ORCHESTRATOR, {
        name,
        configuration: JSON.stringify({
          tasks: [
            {
              id: 1,
              name: `${this.props.component.get('id')}-${this.props.config.get('id')}`,
              task: {
                mode: 'run',
                configId: this.props.config.get('id'),
                componentId: this.props.component.get('id'),
              },
              phase: 1,
              continueOnFailure: false,
              enabled: true,
            },
          ],
          phases: [{ id: 1, name: 'Scheduled configuration' }],
        }),
      })
        .then((response) => {
          return createAndActivateScheduler(response.id, {
            cronTab: crontabRecord,
            timezone: this.state.timezone,
          }).then(() => {
            ApplicationActionCreators.sendNotification({
              message: configurationScheduled(this.props.config, response.id, this.props.hasFlows, {
                newOrchestration: true,
                linkToIndex: this.props.linkToIndex,
              }),
            });
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }

    const redirectToCreatedOrchestration = false;
    this.setState({ isLoading: true });
    return OrchestrationActionCreators.createOrchestration(
      {
        name,
        crontabRecord,
        tasks: [
          {
            component: this.props.component.get('id'),
            action: 'run',
            actionParameters: {
              config: this.props.config.get('id').toString(),
            },
            continueOnFailure: false,
            active: true,
          },
        ],
      },
      redirectToCreatedOrchestration,
    )
      .then((orchestration) => {
        ApplicationActionCreators.sendNotification({
          type: 'info',
          message: configurationScheduled(
            this.props.config,
            orchestration.id,
            this.props.hasFlows,
            { linkToIndex: this.props.linkToIndex },
          ),
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  },

  loadData() {
    const automatedFlows = this.props.flows.filter((flow) => {
      return flow.getIn(['configuration', 'tasks'], List()).some((task) => {
        return (
          task.getIn(['task', 'componentId']) === this.props.component.get('id') &&
          task.getIn(['task', 'configId']) === this.props.config.get('id')
        );
      });
    });
    this.setState({ automatedFlows });

    if (!automatedFlows.isEmpty()) {
      this.setState({ isLoadingJobs: true, automatedFlows });
      queueApi
        .getConfigurationsJobs(KEBOOLA_ORCHESTRATOR, automatedFlows.keySeq().toArray())
        .then((jobs) => this.setState({ configurationLastJobs: fromJS(jobs) }))
        .finally(() => this.setState({ isLoadingJobs: false }));
    }
  },
});

export default ScheduleConfigurationButton;
