import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { ObjectTypes } from '@/modules/components/MetadataConstants';
import { getDescriptionValue } from '@/modules/storage/helpers';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const StorageDescription = createReactClass({
  propTypes: {
    objectType: PropTypes.oneOf(Object.values(ObjectTypes)).isRequired,
    objectId: PropTypes.string.isRequired,
    metadata: PropTypes.object.isRequired,
    metadataKey: PropTypes.string.isRequired,
    fallbackMetadataKey: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    isDevBucket: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      editorValue: null,
      description:
        getDescriptionValue(this.props.metadata, this.props.metadataKey) ||
        (this.props.fallbackMetadataKey
          ? getDescriptionValue(this.props.metadata, this.props.fallbackMetadataKey)
          : null),
    };
  },

  render() {
    return (
      <DescriptionBox
        editorValue={this.state.editorValue}
        description={this.state.description}
        onSetEditorValue={this.handleEditChange}
        onSave={this.handleEditSubmit}
        readOnly={this.props.readOnly}
        placeholder={`${capitalize(this.props.objectType)} Description`}
        warning={
          <DevBranchStorageWarning
            message="The description will also change in production."
            hasProductionEntity={!this.props.isDevBucket}
          />
        }
      />
    );
  },

  handleEditChange(description) {
    this.setState({ editorValue: description });
  },

  handleEditSubmit() {
    return MetadataActionCreators.saveMetadata(
      this.props.objectType,
      this.props.objectId,
      this.props.metadataKey,
      this.state.editorValue.trim(),
    ).then(() => {
      this.setState({ description: this.state.editorValue });
    });
  },
});

export default StorageDescription;
