import { IS_DEVELOPMENT } from '@/constants/environment';
import loadGoogleClient from '@/utils/external/googleClient';
import { GapiActions } from './GapiFlux';

const APP_ID_DEV = '383797098604';
const APP_ID_PROD = '154840892628';

const CLIENT_ID_DEV = '383797098604-iht2att1tuo5siqk786m9ju71qennav0.apps.googleusercontent.com';
const CLIENT_ID_PROD = '154840892628-1gg4ju3amt4qnblgfp9bul3n98v6d4a6.apps.googleusercontent.com';

const API_KEY_DEV = 'AIzaSyCCaip-b6ucQVYXGbZGB7kkM9HQunZPGwc';
const API_KEY_PROD = 'AIzaSyCXSGS_5umIHyuN79vLaBuBHrN8CawVJUY';

const getClientId = () => {
  if (IS_DEVELOPMENT) {
    return CLIENT_ID_DEV;
  }
  return CLIENT_ID_PROD;
};

const getApiKey = () => {
  if (IS_DEVELOPMENT) {
    return API_KEY_DEV;
  }
  return API_KEY_PROD;
};

const getAppId = () => {
  if (IS_DEVELOPMENT) {
    return APP_ID_DEV;
  }
  return APP_ID_PROD;
};

export function authorize(scope, callBackFn) {
  window.google.accounts.oauth2
    .initTokenClient({
      prompt: 'consent',
      client_id: getClientId(),
      scope: [].concat(scope).join(' '),
      callback: callBackFn,
    })
    .requestAccessToken();
}

export function injectGapiScript() {
  GapiActions.startInit();
  loadGoogleClient().then(() => GapiActions.finishInit());
}

export const apiKey = getApiKey();
export const appId = getAppId();
