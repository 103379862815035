import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { List, Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const DataApiFilters = (props: {
  query: Map<string, any>;
  onChange: (query: Map<string, any>) => void;
}) => {
  return (
    <>
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Metric Filter</ControlLabel>
        </div>
        <div className="col-xs-10">
          <Select
            placeholder="Metric"
            value={props.query.getIn(['metricFilter', 'filter', 'fieldName'])}
            onChange={(name: string) => {
              props.onChange(
                props.query
                  .setIn(['metricFilter', 'filter', 'fieldName'], name)
                  .updateIn(['metricFilter', 'filter'], (filter) => {
                    if (filter.has('numericFilter') || filter.has('betweenFilter')) {
                      return filter;
                    }

                    return filter.set('numericFilter', Map());
                  }),
              );
            }}
            options={props.query
              .get('metrics', List())
              .map((metric: Map<string, any>) => metric.get('name'))
              .map((value: string) => ({ label: value, value }))
              .toArray()}
          />
          {props.query.has('metricFilter') && (
            <Select
              className="mtp-2"
              clearable={false}
              placeholder="Filter type"
              value={
                props.query.hasIn(['metricFilter', 'filter', 'betweenFilter'])
                  ? 'between'
                  : 'numeric'
              }
              onChange={(name: string) => {
                props.onChange(
                  name === 'between'
                    ? props.query
                        .deleteIn(['metricFilter', 'filter', 'numericFilter'])
                        .setIn(['metricFilter', 'filter', 'betweenFilter'], Map())
                    : props.query
                        .deleteIn(['metricFilter', 'filter', 'betweenFilter'])
                        .setIn(['metricFilter', 'filter', 'numericFilter'], Map()),
                );
              }}
              options={[
                { label: 'between', value: 'between' },
                { label: 'numeric', value: 'numeric' },
              ]}
            />
          )}
          {props.query.hasIn(['metricFilter', 'filter', 'numericFilter']) && (
            <>
              <Select
                className="mtp-2"
                clearable={false}
                placeholder="Operation"
                value={props.query.getIn(['metricFilter', 'filter', 'numericFilter', 'operation'])}
                onChange={(value: string) => {
                  props.onChange(
                    props.query.setIn(
                      ['metricFilter', 'filter', 'numericFilter', 'operation'],
                      value,
                    ),
                  );
                }}
                options={[
                  { label: '==', value: 'EQUAL' },
                  { label: '<', value: 'LESS_THAN' },
                  { label: '<=', value: 'LESS_THAN_OR_EQUAL' },
                  { label: '>', value: 'GREATER_THAN' },
                  { label: '>=', value: 'GREATER_THAN_OR_EQUAL' },
                ]}
              />
              <FormControl
                className="mtp-2"
                placeholder="Value"
                value={props.query.getIn(
                  ['metricFilter', 'filter', 'numericFilter', 'value', 'int64Value'],
                  '',
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChange(
                    props.query.setIn(
                      ['metricFilter', 'filter', 'numericFilter', 'value', 'int64Value'],
                      event.target.value,
                    ),
                  );
                }}
              />
            </>
          )}
          {props.query.hasIn(['metricFilter', 'filter', 'betweenFilter']) && (
            <>
              <FormControl
                className="mtp-2"
                placeholder="From value"
                value={props.query.getIn(
                  ['metricFilter', 'filter', 'betweenFilter', 'fromValue', 'int64Value'],
                  '',
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChange(
                    props.query.setIn(
                      ['metricFilter', 'filter', 'betweenFilter', 'fromValue', 'int64Value'],
                      event.target.value,
                    ),
                  );
                }}
              />
              <FormControl
                className="mtp-2"
                placeholder="To value"
                value={props.query.getIn(
                  ['metricFilter', 'filter', 'betweenFilter', 'toValue', 'int64Value'],
                  '',
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChange(
                    props.query.setIn(
                      ['metricFilter', 'filter', 'betweenFilter', 'toValue', 'int64Value'],
                      event.target.value,
                    ),
                  );
                }}
              />
            </>
          )}
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Dimension Filter</ControlLabel>
        </div>
        <div className="col-xs-10">
          <Select
            placeholder="Dimension"
            value={props.query.getIn(['dimensionFilter', 'filter', 'fieldName'])}
            onChange={(name: string) => {
              props.onChange(
                props.query
                  .setIn(['dimensionFilter', 'filter', 'fieldName'], name)
                  .updateIn(['dimensionFilter', 'filter'], (filter) => {
                    if (filter.has('stringFilter') || filter.has('inListFilter')) {
                      return filter;
                    }

                    return filter.set('stringFilter', Map());
                  }),
              );
            }}
            options={props.query
              .get('dimensions', List())
              .map((dimension: Map<string, any>) => dimension.get('name'))
              .map((value: string) => ({ label: value, value }))
              .toArray()}
          />
          {props.query.has('dimensionFilter') && (
            <Select
              className="mtp-2"
              clearable={false}
              placeholder="Filter type"
              value={
                props.query.hasIn(['dimensionFilter', 'filter', 'stringFilter']) ? 'string' : 'list'
              }
              onChange={(name: string) => {
                props.onChange(
                  name === 'string'
                    ? props.query
                        .deleteIn(['dimensionFilter', 'filter', 'inListFilter'])
                        .setIn(['dimensionFilter', 'filter', 'stringFilter'], Map())
                    : props.query
                        .deleteIn(['dimensionFilter', 'filter', 'stringFilter'])
                        .setIn(['dimensionFilter', 'filter', 'inListFilter'], Map()),
                );
              }}
              options={[
                { label: 'string', value: 'string' },
                { label: 'in list', value: 'list' },
              ]}
            />
          )}
          {props.query.hasIn(['dimensionFilter', 'filter', 'stringFilter']) && (
            <>
              <Select
                className="mtp-2"
                clearable={false}
                placeholder="Match type"
                value={props.query.getIn([
                  'dimensionFilter',
                  'filter',
                  'stringFilter',
                  'matchType',
                ])}
                onChange={(value: string) => {
                  props.onChange(
                    props.query.setIn(
                      ['dimensionFilter', 'filter', 'stringFilter', 'matchType'],
                      value,
                    ),
                  );
                }}
                options={[
                  { label: 'Exact', value: 'EXACT' },
                  { label: 'Begins with', value: 'BEGINS_WITH' },
                  { label: 'Ends with', value: 'ENDS_WITH' },
                  { label: 'Contains', value: 'CONTAINS' },
                  { label: 'Regexp', value: 'FULL_REGEXP' },
                  { label: 'Partial regexp', value: 'PARTIAL_REGEXP' },
                ]}
              />
              <FormControl
                type="text"
                className="mtp-2"
                placeholder="Value"
                value={props.query.getIn(
                  ['dimensionFilter', 'filter', 'stringFilter', 'value'],
                  '',
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChange(
                    props.query.setIn(
                      ['dimensionFilter', 'filter', 'stringFilter', 'value'],
                      event.target.value,
                    ),
                  );
                }}
              />
            </>
          )}
          {props.query.hasIn(['dimensionFilter', 'filter', 'inListFilter']) && (
            <Select
              multi
              allowCreate
              className="mtp-2"
              placeholder="Values"
              value={props.query.getIn(
                ['dimensionFilter', 'filter', 'inListFilter', 'values'],
                List(),
              )}
              onChange={(values: List<string>) => {
                props.onChange(
                  props.query.setIn(
                    ['dimensionFilter', 'filter', 'inListFilter', 'values'],
                    values,
                  ),
                );
              }}
              promptTextCreator={(label) => `Add value "${label}"`}
            />
          )}
          {props.query.has('dimensionFilter') && (
            <Checkbox
              className="mtp-2"
              checked={props.query.getIn(
                [
                  'dimensionFilter',
                  'filter',
                  props.query.hasIn(['dimensionFilter', 'filter', 'stringFilter'])
                    ? 'stringFilter'
                    : 'inListFilter',
                  'caseSensitive',
                ],
                false,
              )}
              onChange={(checked: boolean) => {
                props.onChange(
                  props.query.setIn(
                    [
                      'dimensionFilter',
                      'filter',
                      props.query.hasIn(['dimensionFilter', 'filter', 'stringFilter'])
                        ? 'stringFilter'
                        : 'inListFilter',
                      'caseSensitive',
                    ],
                    checked,
                  ),
                );
              }}
            >
              Case sensitive
            </Checkbox>
          )}
        </div>
      </FormGroup>
    </>
  );
};

export default DataApiFilters;
