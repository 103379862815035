import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import DisableSharingModal from './DisableSharingModal';

const INITIAL_STATE = {
  showModal: false,
};

class DisabledSharingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ showModal: true });
          }}
          bsSize="sm"
        >
          Disable
        </Button>
        <DisableSharingModal
          show={this.state.showModal}
          closeModalFn={() => {
            this.setState({
              showModal: false,
            });
          }}
          urlTemplates={this.props.urlTemplates}
          bucket={this.props.bucket}
          sapiToken={this.props.sapiToken}
        />
      </>
    );
  }
}

DisabledSharingButton.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.object.isRequired,
};

export default DisabledSharingButton;
