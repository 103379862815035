import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Badge } from 'design';
import { Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const DatatypeFormRow = createReactClass({
  propTypes: {
    columnName: PropTypes.string.isRequired,
    datatype: PropTypes.object.isRequired,
    datatypesMap: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isPrimaryKey: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      hovered: false,
    };
  },

  lengthEnabled() {
    return this.props.datatypesMap
      .find((datatype) => datatype.get('name') === this.props.datatype.get('type'), null, Map())
      .get('size');
  },

  handleTypeChange(newType) {
    if (newType && this.props.datatype.get('type') !== newType) {
      this.props.onChange(
        this.props.datatype
          .set('type', newType)
          .set('length', null)
          .set('convertEmptyValuesToNull', false),
      );
    }
  },

  handleLengthChange(e) {
    this.props.onChange(this.props.datatype.set('length', e.target.value || null));
  },

  handleNullableChange(checked) {
    this.props.onChange(this.props.datatype.set('convertEmptyValuesToNull', checked));
  },

  getTypeOptions() {
    return this.props.datatypesMap
      .sortBy((dataType) => dataType.get('name').toLowerCase())
      .map((dataType) => {
        return { label: dataType.get('name'), value: dataType.get('name') };
      })
      .toArray();
  },

  setHoveredTrue() {
    this.setState({
      hovered: true,
    });
  },

  setHoveredFalse() {
    this.setState({
      hovered: false,
    });
  },

  getCheckboxLabel() {
    if (this.state.hovered) {
      return (
        <span>
          Empty values as <code>null</code>
        </span>
      );
    } else {
      return <span>&nbsp;</span>;
    }
  },

  render() {
    return (
      <tr onMouseEnter={this.setHoveredTrue} onMouseLeave={this.setHoveredFalse}>
        <td>
          {this.props.columnName}
          {this.props.isPrimaryKey && <Badge text="PK" variant="blue" placement="right" />}
        </td>
        <td>
          <Select
            name={this.props.columnName + '_datatype'}
            value={this.props.datatype.get('type')}
            options={this.getTypeOptions()}
            onChange={this.handleTypeChange}
            disabled={this.props.disabled}
            clearable={false}
          />
        </td>
        <td>
          {this.lengthEnabled() && (
            <FormControl
              name={this.props.columnName + '_length'}
              type="text"
              size={15}
              value={this.props.datatype.get('length') || ''}
              onChange={this.handleLengthChange}
              disabled={this.props.disabled || !this.lengthEnabled()}
              placeholder="Length, eg. 38,0"
            />
          )}
        </td>
        <td>
          <Checkbox
            checked={this.props.datatype.get('convertEmptyValuesToNull')}
            onChange={this.handleNullableChange}
            disabled={
              this.props.isPrimaryKey && !this.props.datatype.get('convertEmptyValuesToNull')
            }
          >
            {this.getCheckboxLabel()}
          </Checkbox>
        </td>
      </tr>
    );
  },
});

export default DatatypeFormRow;
