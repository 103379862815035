import React from 'react';
import * as Flow from '@keboola/flow-builder';
import type Promise from 'bluebird';
import classNames from 'classnames';
import type { Map } from 'immutable';
import { List } from 'immutable';
import _ from 'underscore';

import { BEHAVIOR_TYPES, JOB_FINISHED_STATUSES, JOBS_STATUS } from '@/modules/queue/constants';
import EmptyNode from './EmptyNode';
import PhaseInput from './PhaseInput';
import PhaseLabel from './PhaseLabel';
import PhaseOutput from './PhaseOutput';
import Task from './Task';
import TaskCount from './TaskCount';

type Props = {
  configId: string;
  phase: Map<string, any>;
  isFirst: boolean;
  isLast: boolean;
  isLone: boolean;
  dragging: string | null;
  selected: string | null;
  shouldMergeBefore: boolean;
  shouldMergeInto: boolean;
  shouldMergeAfter: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  allComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  tablesMetadataMap: Map<string, any>;
  previousPhase: Map<string, any> | null;
  status: Map<string, any> | null;
  folders: Map<string, any>;
  patternComponents: Map<string, any>;
  availableDatabricksClusters: List<any>;
  onEditPhase: (phase: Map<string, any>) => void;
  toggleBehaviorOnErrorChange: (phase: Map<string, any>) => void;
  onDelete: (phase: Map<string, any>) => void;
  onSelectTask: (task?: string) => void;
  onDragTask: (
    phase: Map<string, any>,
    task: Map<string, any>,
    state: 'begin' | 'move' | 'end',
    position?: [number, number],
  ) => void;
  onEditTask: (id: string, field: string | string[], value: any) => void;
  onSelectTaskConfig: (
    taskId: string,
    componentId: string,
    configId: string | null,
    options?: { autosave: boolean },
  ) => Promise<any>;
  showBackendSize: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  hasTemplates: boolean;
  isDevModeActive: boolean;
  onDeleteTask: (id: string) => void;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  onExploreData: (task: Map<string, any>, buckets: Map<string, any>) => void;
  onSetTaskParams: (taskId: string) => void;
};

// Destructuring only props used as hook dependency
const Phase = (props: Props) => {
  const phaseTasks = props.phase.get('tasks', List());
  const phaseStatus = props.status?.getIn([props.phase.get('id'), 'status']);
  const previousPhaseStatus = props.status?.getIn([props.previousPhase?.get('id'), 'status']);
  const isPhaseRunning =
    props.status?.get('isRunning') &&
    // to make sure always some phase/task is showing as running
    (props.isFirst || previousPhaseStatus === JOBS_STATUS.SUCCESS) &&
    !JOB_FINISHED_STATUSES.includes(phaseStatus);

  return (
    <Flow.Group
      key={props.phase.get('key')}
      name={props.phase.get('key')}
      className={classNames({
        'is-first': props.isFirst,
        'is-lone': props.isLone,
        'is-last': props.isLast,
        'merge-into':
          props.shouldMergeInto || props.newTaskPosition === props.phase.get('id').toString(),
        'merge-after':
          props.shouldMergeAfter ||
          (_.isString(props.newTaskPosition) &&
            props.newTaskPosition.includes(`${props.phase.get('id')}:`)),
        'merge-before': props.shouldMergeBefore,
      })}
    >
      <TaskCount tasks={phaseTasks.count()} />
      {!props.isLast && (
        <PhaseLabel
          phase={props.phase}
          readOnly={props.readOnly}
          shouldMergeInto={props.shouldMergeInto}
          behaviorOnError={props.phase.get('behaviorOnError', BEHAVIOR_TYPES.STOP)}
          toggleBehaviorOnErrorChange={props.toggleBehaviorOnErrorChange}
          newTaskPosition={props.newTaskPosition}
          handleShowAddTaskModal={props.handleShowAddTaskModal}
          onEdit={props.onEditPhase}
          onDelete={props.onDelete}
        />
      )}
      {props.previousPhase && (
        <Flow.Input name="run">
          <PhaseInput
            phase={props.phase}
            readOnly={props.readOnly}
            previousPhase={props.previousPhase}
            newTaskPosition={props.newTaskPosition}
            handleShowAddTaskModal={props.handleShowAddTaskModal}
            isLast={props.isLast}
          />
        </Flow.Input>
      )}
      {!props.isLast && phaseTasks.count() > 1 && (
        <Flow.Output name="done">
          <PhaseOutput
            isLoading={isPhaseRunning}
            continueOnFailure={
              props.phase.get('behaviorOnError', BEHAVIOR_TYPES.STOP) === BEHAVIOR_TYPES.WARNING
            }
          />
        </Flow.Output>
      )}
      {phaseTasks
        .sortBy((task: Map<string, any>) => `${task.get('component')} ${task.get('type')}`)
        .map((task: Map<string, any>) => {
          const isTaskRunning = [JOBS_STATUS.PROCESSING, JOBS_STATUS.TERMINATING].includes(
            props.status?.getIn([props.phase.get('id'), task.get('id'), 'status']),
          );

          return (
            <Task
              key={task.get('id')}
              task={task}
              phase={props.phase}
              configId={props.configId}
              isSelected={props.selected === task.get('id')}
              isDragged={props.dragging === task.get('id')}
              allComponents={props.allComponents}
              allConfigurations={props.allConfigurations}
              tablesMetadataMap={props.tablesMetadataMap}
              patternComponents={props.patternComponents}
              availableDatabricksClusters={props.availableDatabricksClusters}
              taskStatus={props.status?.getIn([props.phase.get('id'), task.get('id')])}
              isRunning={isTaskRunning}
              readOnly={props.readOnly}
              hasPayAsYouGo={props.hasPayAsYouGo}
              onSelect={props.onSelectTask}
              onDragTask={props.onDragTask}
              onExploreData={props.onExploreData}
              folders={props.folders}
              showBackendSize={props.showBackendSize}
              hasSnowflakeDynamicBackendSize={props.hasSnowflakeDynamicBackendSize}
              hasJobsDynamicBackendSize={props.hasJobsDynamicBackendSize}
              onEditTask={props.onEditTask}
              onSelectTaskConfig={props.onSelectTaskConfig}
              onDeleteTask={props.onDeleteTask}
              onSetTaskParams={props.onSetTaskParams}
              isDevModeActive={props.isDevModeActive}
            />
          );
        })
        .toArray()}
      {phaseTasks.isEmpty() && (
        <EmptyNode
          phase={props.phase}
          isLone={props.isLone}
          isLast={props.isLast}
          readOnly={props.readOnly}
          newTaskPosition={props.newTaskPosition}
          handleShowAddTaskModal={props.handleShowAddTaskModal}
          hasTemplates={props.hasTemplates}
          isDevModeActive={props.isDevModeActive}
        />
      )}
    </Flow.Group>
  );
};

const PhaseMemoized = React.memo(Phase);

export default PhaseMemoized;
