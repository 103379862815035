import React from 'react';
import { Alert } from 'design';

import type { FeedbackStatusType } from '@/modules/ai/constants';
import FeedbackButtons from './FeedbackButtons';
import NegativeFeedbackForm from './NegativeFeedbackForm';

const FeedbackBox = ({
  onReload,
  onBad,
  onGood,
  feedbackStatus,
  entityName,
  disabled,
}: {
  onReload: () => void;
  onBad: (message?: string) => void;
  onGood: () => void;
  feedbackStatus: FeedbackStatusType | null;
  entityName: string;
  disabled: boolean;
}) => {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <Alert withIcon={false}>
      <div className="tw-flex tw-w-full tw-flex-col">
        <span className="flex-container">
          <p className="mb-0 font-medium">{'Is this AI-generated explanation useful?'}</p>
          <FeedbackButtons
            disabled={disabled}
            entityName={entityName}
            feedbackStatus={feedbackStatus}
            onReload={() => {
              setShowForm(false);
              onReload();
            }}
            onGood={() => {
              onGood();
              setShowForm(false);
            }}
            onBad={() => {
              onBad();
              setShowForm(true);
            }}
          />
        </span>
        {showForm && (
          <NegativeFeedbackForm
            onBad={(selectedReason?: string) => {
              onBad(selectedReason);
              setShowForm(false);
            }}
          />
        )}
      </div>
    </Alert>
  );
};

export default FeedbackBox;
