import { fromJS, Map } from 'immutable';

import {
  isReservedParam,
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';

const KEY = 'root-params';

const mapToState = (parameters: Map<string, any>) => {
  const params = transformMapToKeyValueList(
    parameters.getIn(['api', 'http', 'defaultOptions', 'params'], Map()).filterNot(isReservedParam),
  );

  return fromJS({ [KEY]: { params } });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  const reservedParams = parameters
    .getIn(['api', 'http', 'defaultOptions', 'params'], Map())
    .filter(isReservedParam);

  const customParams = transformKeyValueListToMap(editing.getIn([KEY, 'params']));

  const params = reservedParams.merge(customParams);

  return params.isEmpty()
    ? parameters.deleteIn(['api', 'http', 'defaultOptions', 'params'])
    : parameters.setIn(['api', 'http', 'defaultOptions', 'params'], params);
};

export { KEY, mapToState, mapToApi };
