import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import { getBackendImage } from '@/modules/data-catalog/helpers';
import { backends } from '@/modules/storage/constants';
import { prepareBackendLabel } from '@/modules/storage/helpers';

const BackendRow = ({ backend }) => {
  const backendImage = getBackendImage(backend);

  return (
    <p className="first-line font-medium flex-container">
      <span>Backend</span>
      <span className="flex-container flex-end">
        {backendImage && <Image width={24} className="icon-addon-right" src={backendImage} />}
        <span className="font-normal text-muted">{prepareBackendLabel(backend)}</span>
      </span>
    </p>
  );
};

BackendRow.propTypes = {
  backend: PropTypes.oneOf(Object.values(backends)).isRequired,
};

const MemoizedBackedRow = React.memo(BackendRow);

export default MemoizedBackedRow;
