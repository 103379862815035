import crash from '../../media/crash.mp3';
import success from '../../media/success.mp3';

import { fileUrl } from './fileHelpers';

const paths = {
  success,
  crash,
};

const soundElements = {};

const playSound = (name) => {
  if (!soundElements[name]) {
    soundElements[name] = new Audio(fileUrl(paths[name]));
  }

  return soundElements[name].play().catch((error) => {
    if (error.name === 'NotAllowedError') {
      return;
    }

    throw error;
  });
};

export default {
  success() {
    return playSound('success');
  },
  crash() {
    return playSound('crash');
  },
};
