import React, { useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TooltipProps } from 'design';
import { cn, Tooltip } from 'design';

const copyToClipboard = (text: string) => {
  return navigator.clipboard.writeText(text);
};

type Props = {
  text?: string;
  label?: ReactNode;
  tooltipText?: TooltipProps['tooltip'];
  tooltipPlacement?: TooltipProps['placement'];
  tooltipType?: TooltipProps['type'];
  showIcon?: boolean;
  stopEventPropagation?: boolean;
  inline?: boolean;
  aloneLink?: boolean;
  className?: string;
  btnClassName?: string;
};

export const Clipboard = ({
  text = '',
  label,
  tooltipText = 'Copy to Clipboard',
  tooltipPlacement = 'right',
  tooltipType = 'action',
  showIcon = true,
  stopEventPropagation = false,
  inline = true,
  aloneLink = false,
  className,
  btnClassName,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const buttorRef = useRef<HTMLButtonElement>(null);
  const tooltip =
    error || (text ? (isCopied ? 'Copied to Clipboard' : tooltipText) : 'Nothing to copy');
  const isDisabled = text === '';

  return (
    <Tooltip
      tooltip={tooltip}
      type={tooltipType}
      forceHide={!tooltip}
      forceShow={isCopied}
      placement={tooltipPlacement}
      triggerClassName={cn('tw-inline-flex', className)}
    >
      {/* TODO: Use `Button` component from design package */}
      <button
        ref={buttorRef}
        disabled={isDisabled}
        className={cn(
          'btn',
          {
            'btn-default': !inline && !btnClassName?.includes('btn-'),
            'btn-link btn-link-inline': inline,
            'dark muted': inline && !aloneLink,
            'no-underline': inline && !label,
          },
          btnClassName,
        )}
        onClick={(event) => {
          event.preventDefault();

          if (stopEventPropagation) {
            event.stopPropagation();
          }

          copyToClipboard(text ?? '').then(
            () => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
                setError(null);
              }, 1100);
            },
            (error: { message?: string | null }) => {
              setIsCopied(false);
              setError(error.message || 'Failed to copy');
              setTimeout(() => {
                setIsCopied(false);
                setError(null);
              }, 2300);
            },
          );

          setTimeout(() => buttorRef.current?.blur(), 300);
        }}
      >
        <span className={className}>
          {(showIcon || !label) && (
            <FontAwesomeIcon
              icon="copy"
              className={cn('tw-text-base', {
                'tw-text-neutral-200': !text,
                'tw-ml-2.5': inline && !aloneLink,
                'tw-mr-2.5': !!label,
              })}
            />
          )}
          {label}
        </span>
      </button>
    </Tooltip>
  );
};
