import React from 'react';
import Promise from 'bluebird';

// stores
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import * as NewOrchestrationsActionCreators from '@/modules/orchestrations-v2/actions';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import JobDetailButtons from './react/components/JobDetailButtons';
import NewOrchestrationHeaderButton from './react/components/NewOrchestrationHeaderButton';
import OrchestrationNameEdit from './react/components/OrchestrationNameEdit';
import OrchestrationTasksButtons from './react/components/OrchestrationTasksButtons';
import OrchestrationDetail from './react/pages/orchestration-detail/OrchestrationDetail';
import OrchestrationJobDetail from './react/pages/orchestration-job-detail/OrchestrationJobDetail';
import OrchestrationNotifications from './react/pages/orchestration-notifications/OrchestrationNotifications';
import OrchestrationTasks from './react/pages/orchestration-tasks/OrchestrationTasks';
// pages and components
import OrchestrationsIndex from './react/pages/orchestrations-index/OrchestrationsIndex';
import OrchestrationsStore from './stores/OrchestrationsStore';
import OrchestrationsActionCreators from './ActionCreators';
import { ORCHESTRATION_JOBS_POLLER_INTERVAL_SECONDS, routeNames } from './Constants';

const routes = {
  name: routeNames.ROOT,
  title: 'Orchestrations',
  defaultRouteHandler: OrchestrationsIndex,
  headerButtonsHandler: NewOrchestrationHeaderButton,
  poll: {
    skipFirst: true,
    interval: DEFAULT_INTERVAL,
    action() {
      return OrchestrationsActionCreators.loadOrchestrationsForce();
    },
  },
  requireData: [
    () => OrchestrationsActionCreators.loadOrchestrations(),
    () =>
      InstalledComponentsActionsCreators.reloadInstalledComponents({ include: 'configuration' }),
    () => NewOrchestrationsActionCreators.loadTriggers(),
  ],
  childRoutes: [
    {
      name: routeNames.ORCHESTRATION,
      nameEdit(params) {
        const orchestrationId = parseInt(params.orchestrationId, 10);

        return <OrchestrationNameEdit key={orchestrationId} orchestrationId={orchestrationId} />;
      },
      path: ':orchestrationId',
      defaultRouteHandler: OrchestrationDetail,
      poll: {
        interval: ORCHESTRATION_JOBS_POLLER_INTERVAL_SECONDS,
        action: (params) => {
          return Promise.all([
            OrchestrationsActionCreators.loadOrchestrationJobsForce(
              parseInt(params.orchestrationId, 10),
            ),
            VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(
              'orchestrator',
              params.orchestrationId,
            ),
          ]);
        },
        skipFirst: true,
      },
      requireData: [
        (params) =>
          OrchestrationsActionCreators.loadOrchestration(parseInt(params.orchestrationId, 10)),
        (params) =>
          OrchestrationsActionCreators.loadOrchestrationJobs(parseInt(params.orchestrationId, 10)),
        (params) => VersionsActionCreators.loadVersions('orchestrator', params.orchestrationId),
        () => StorageActionCreators.loadBucketsAndTables(),
      ],
      title(routerState) {
        const orchestrationId = parseInt(routerState.getIn(['params', 'orchestrationId']), 10);
        return OrchestrationsStore.get(orchestrationId).get('name');
      },
      childRoutes: [
        {
          name: routeNames.JOB,
          poll: {
            interval: ORCHESTRATION_JOBS_POLLER_INTERVAL_SECONDS,
            action(params) {
              return Promise.all([
                OrchestrationsActionCreators.loadOrchestrationJobsForce(params.orchestrationId),
                OrchestrationsActionCreators.loadJobForce(parseInt(params.jobId, 10)),
              ]);
            },
            skipFirst: true,
          },
          requireData: [
            (params) => OrchestrationsActionCreators.loadJob(parseInt(params.jobId, 10)),
          ],
          title(routerState) {
            return `Job ${routerState.getIn(['params', 'jobId'])}`;
          },
          path: 'jobs/:jobId',
          defaultRouteHandler: OrchestrationJobDetail,
          headerButtonsHandler: JobDetailButtons,
        },
        {
          name: routeNames.TASKS,
          title: 'Tasks',
          path: 'tasks',
          defaultRouteHandler: OrchestrationTasks,
          headerButtonsHandler: OrchestrationTasksButtons,
        },
        {
          name: routeNames.NOTIFICATIONS,
          title: 'Notifications',
          path: 'notifications',
          defaultRouteHandler: OrchestrationNotifications,
        },
        createVersionsPageRoute('orchestrator', 'orchestrationId', routeNames.VERSIONS),
      ],
    },
  ],
};

export default routes;
