import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routeNames } from '@/modules/components-directory/constants';
import RouterLink from '@/react/common/RouterLink';

const ComponentsListLink = () => {
  return (
    <RouterLink to={routeNames.ROOT} className="btn btn-success" onlyActiveOnIndex>
      <FontAwesomeIcon icon="plus" className="icon-addon-right" />
      Add Component
    </RouterLink>
  );
};

export default ComponentsListLink;
