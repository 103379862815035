import Promise from 'bluebird';

import dayjs from '@/date';
import dispatcher from '@/Dispatcher';
import storageActions from '@/modules/components/StorageActionCreators';
import RoutesStore from '@/stores/RoutesStore';
import { getQueryWithFallback } from '@/utils/jobsQueryBuilder';
import JobsStore from './stores/JobsStore';
import * as constants from './Constants';
import jobsApi from './JobsApi';

const reloadSapiTablesTrigger = (jobs) => {
  const anyJobJustFinished = jobs
    .filter((job) => job.endTime)
    .some((job) => dayjs().diff(dayjs(job.endTime), 'seconds') < 20);

  if (anyJobJustFinished) {
    storageActions.loadBucketsAndTablesForce();
  }

  return null;
};

export default {
  loadJobs() {
    if (JobsStore.getIsLoaded()) {
      this.loadJobsForce(JobsStore.getOffset(), false, true);
      return Promise.resolve();
    }
    return this.loadJobsForce(JobsStore.getOffset(), false, true);
  },

  // poll for not finished jobs
  reloadNotFinishedJobs() {
    const allJobs = JobsStore.getAll()
      .filter((job) => !job.get('isFinished'))
      .map((j) => j.get('id'))
      .toArray();
    if (allJobs.length === 0) {
      return Promise.resolve();
    }
    const query = `(id:${allJobs.join(' OR id:')})`;
    return this.loadJobsForce(0, false, true, query);
  },

  reloadJobs() {
    if (JobsStore.loadJobsErrorCount() < 10) {
      return this.loadJobsForce(0, false, true).then(() => {
        return this.reloadNotFinishedJobs();
      });
    }
    return Promise.resolve();
  },

  loadMoreJobs() {
    const offset = JobsStore.getNextOffset();
    return this.loadJobsForce(offset, false, false);
  },

  loadJobsForce(offset, forceResetJobs, preserveCurrentOffset, forceQuery) {
    const actions = this;
    const limit = JobsStore.getLimit();
    const query = forceQuery || JobsStore.getQuery();
    // always reset jobs if showing only first page
    const isFirstPageOnly = offset === 0 && JobsStore.getAll().count() <= limit;
    const resetJobs = forceResetJobs || (isFirstPageOnly && !forceQuery);
    dispatcher.handleViewAction({ type: constants.ActionTypes.JOBS_LOAD });
    return jobsApi
      .getJobsParametrized(query, limit, offset)
      .then((jobs) => {
        let newOffset = offset;
        reloadSapiTablesTrigger(jobs);
        if (preserveCurrentOffset) {
          newOffset = JobsStore.getOffset();
        }
        return actions.recieveJobs(jobs, newOffset, resetJobs);
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.JOBS_LOAD_ERROR,
          exception: e,
        });
        throw e;
      });
  },

  filterJobs(query) {
    return RoutesStore.getRouter().replaceTo(constants.routeNames.ROOT, null, { q: query });
  },

  setQuery(query) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.JOBS_SET_QUERY,
      query,
    });
  },

  loadJobDetail(jobId) {
    if (JobsStore.has(jobId)) {
      this.loadJobDetailForce(jobId);
      return Promise.resolve();
    }
    return this.loadJobDetailForce(jobId);
  },

  loadJobDetailForce(jobId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.JOB_LOAD,
      jobId,
    });
    return jobsApi.getJobDetail(jobId).then((jobDetail) => {
      reloadSapiTablesTrigger([jobDetail]);
      dispatcher.handleViewAction({
        type: constants.ActionTypes.JOB_LOAD_SUCCESS,
        job: jobDetail,
      });
    });
  },

  recieveJobs(jobs, newOffset, resetJobs) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.JOBS_LOAD_SUCCESS,
      jobs,
      newOffset,
      resetJobs,
    });
  },

  terminateJob(jobId) {
    const actions = this;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.JOB_TERMINATE_START,
      jobId,
    });

    return jobsApi
      .terminateJob(jobId)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.JOB_TERMINATE_SUCCESS,
          jobId,
        });
        return actions.loadJobDetailForce(jobId);
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.JOB_TERMINATE_ERROR,
          jobId,
        });
        throw e;
      });
  },

  loadComponentConfigurationLatestJobs(componentId, configurationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.JOBS_LATEST_LOAD_START,
      componentId,
      configurationId,
    });

    const query = getQueryWithFallback(componentId, configurationId);

    return jobsApi
      .getJobsParametrized(query, 30, 0)
      .then((jobs) => {
        reloadSapiTablesTrigger(jobs);
        dispatcher.handleViewAction({
          type: constants.ActionTypes.JOBS_LATEST_LOAD_SUCCESS,
          componentId,
          configurationId,
          jobs,
        });
        return null;
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.JOBS_LATEST_LOAD_ERROR,
          componentId,
          configurationId,
          error: e,
        });
        throw e;
      });
  },
};
