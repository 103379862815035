import { createRequest, withProductionFallback } from '@/modules/components/StorageApi';

export default {
  createEvent(params) {
    return createRequest('POST', 'events')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  listEvents(params) {
    return createRequest('GET', 'events')
      .query(params)
      .timeout(10000)
      .promise()
      .then((response) => response.body);
  },

  listTableEvents(tableId, params) {
    return withProductionFallback(tableId, (options) => {
      return createRequest('GET', 'tables/' + tableId + '/events', options)
        .query(params)
        .timeout(10000)
        .promise()
        .then((response) => response.body);
    });
  },

  getEvent(id) {
    return createRequest('GET', `events/${id}`)
      .promise()
      .then((response) => response.body);
  },

  listBucketEvents(buckedId, params) {
    return withProductionFallback(buckedId, (options) => {
      return createRequest('GET', 'buckets/' + buckedId + '/events', options)
        .query(params)
        .timeout(10000)
        .promise()
        .then((response) => response.body);
    });
  },
};
