import { Dispatcher } from 'flux';

import { IS_DEVELOPMENT } from './constants/environment';
import { PayloadSources } from './constants/KbcConstants';
import { MUTE_STORES_LOGGING } from './constants/localStorageKeys';

class KbcDispatcher extends Dispatcher {
  handleViewAction(action) {
    if (IS_DEVELOPMENT && !window.localStorage.getItem(MUTE_STORES_LOGGING)) {
      console.log(action); // eslint-disable-line
    }

    this.dispatch({
      source: PayloadSources.VIEW_ACTION,
      action,
    });
  }
}

export default new KbcDispatcher();
