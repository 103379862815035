import React from 'react';
import { resolve } from 'react-router-named-routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { Map } from 'immutable';
import { rtrim } from 'underscore.string';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import * as NotificationActions from '@/modules/notifications/actions';
import OauthActions from '@/modules/oauth-v2/ActionCreators';
import JobsActionCreators from '@/modules/queue/actions';
import TokensActionCreators from '@/modules/tokens/actionCreators';
import DummyBreadcrumb from '@/react/common/DummyBreadcrumb';
import RouterLink from '@/react/common/RouterLink';
import RoutesStore from '@/stores/RoutesStore';
import AddNewTemplateHeader from './components/AddNewTemplateHeader';
import CloseTemplatesDirectoryHeader from './components/CloseTemplatesDirectoryHeader';
import InstanceConfigurator from './components/InstanceConfigurator';
import InstanceConfiguratorHeader from './components/InstanceConfiguratorHeader';
import InstanceDetailHeader from './components/InstanceDetailHeader';
import InstanceNameEdit from './components/InstanceNameEdit';
import UseTemplateHeader from './components/UseTemplateHeader';
import actions from './actions';
import { routeNames } from './constants';
import InstancesIndex from './Index';
import InstanceDetail from './InstanceDetail';
import TemplatesStore from './store';
import TemplateDetail from './TemplateDetail';
import TemplatesDirectory from './TemplatesDirectory';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: routeNames.ROOT,
  path: 'templates',
  title: 'Templates',
  defaultRouteHandler: InstancesIndex,
  headerButtonsHandler: AddNewTemplateHeader,
  requireData: [
    () => TokensActionCreators.loadTokens(),
    (params: Record<string, any>, query: Record<string, any>, routerState: Record<string, any>) =>
      Promise.resolve(actions.loadTemplatesOnce()).then(() => {
        const loadedInstances = TemplatesStore.getStore().instances;

        if (
          loadedInstances &&
          !loadedInstances.length &&
          rtrim(routerState.location.pathname, '/') === resolve(routeNames.ROOT)
        ) {
          RoutesStore.getRouter().transitionTo(routeNames.TEMPLATES);

          return Promise.resolve();
        }

        if (params.instanceId || loadedInstances) {
          actions.loadInstances();
          InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR);

          return Promise.resolve();
        }

        return actions.loadInstances().then((instances) => {
          InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR);
          if (
            rtrim(routerState.location.pathname, '/') === resolve(routeNames.ROOT) &&
            !instances.length
          ) {
            RoutesStore.getRouter().transitionTo(routeNames.TEMPLATES);
          }
        });
      }),
  ],
  childRoutes: [
    {
      name: routeNames.TEMPLATES,
      path: 'add-new',
      title: 'Add New Template',
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: TemplatesDirectory,
      headerButtonsHandler: CloseTemplatesDirectoryHeader,
      childRoutes: [
        {
          name: routeNames.TEMPLATE_DETAIL,
          path: ':templateId',
          title: () => TemplatesStore.getStore().templateVersionDetail?.template.name,
          breadcrumbHandler: ({ breadcrumbs }: { breadcrumbs: any[] }) => breadcrumbs.shift(),
          defaultRouteHandler: TemplateDetail,
          headerButtonsHandler: UseTemplateHeader,
          requireData: [
            (
              params: { templateId: string },
              query: { v?: string },
              routerState: Record<string, any>,
            ) => {
              if (
                routerState.routes?.findLast(({ name }: { name?: string }) => !!name)?.name ===
                routeNames.INSTANCE_NEW
              ) {
                return Promise.resolve();
              }

              return actions.loadTemplateDetail(params.templateId, query.v);
            },
          ],
          childRoutes: [
            {
              name: routeNames.INSTANCE_NEW,
              path: 'configure',
              title: (routerState: Map<string, any>) => {
                const { n: instanceName }: { n?: string } = routerState
                  .getIn(['location', 'query'], Map())
                  .toJS();

                return instanceName || 'New Template';
              },
              breadcrumbHandler: ({ breadcrumbs }: { breadcrumbs: any[] }) => breadcrumbs.slice(2),
              defaultRouteHandler: InstanceConfigurator,
              headerButtonsHandler: InstanceConfiguratorHeader,
              requireData: [
                (params: { templateId: string }, query: { v?: string }) =>
                  actions.loadTemplateInputs(params.templateId, query.v),
              ],
            },
          ],
        },
      ],
    },
    {
      name: routeNames.INSTANCE_DETAIL,
      path: ':instanceId',
      title: () => TemplatesStore.getStore().instanceDetail?.name,
      nameEdit: () => <InstanceNameEdit />,
      breadcrumbHandler: ({ breadcrumbs }: { breadcrumbs: any[] }) => {
        const { instanceDetail } = TemplatesStore.getStore();
        const usedTemplate = instanceDetail?.templateDetail;

        if (!usedTemplate) return breadcrumbs;

        const lastBreadcrumb = breadcrumbs.length > 1 ? breadcrumbs.pop() : null;

        return (
          <>
            {breadcrumbs}
            {breadcrumbs.length === 1 && <FontAwesomeIcon icon={['far', 'angle-right']} />}
            {usedTemplate.deprecated ? (
              <span className="text-muted f-16 line-height-18">{usedTemplate.name}</span>
            ) : (
              <RouterLink
                className="dark muted"
                to={routeNames.TEMPLATE_DETAIL}
                params={{ templateId: usedTemplate.id }}
              >
                {usedTemplate.name}
              </RouterLink>
            )}
            {lastBreadcrumb && (
              <>
                <FontAwesomeIcon icon={['far', 'angle-right']} />
                {lastBreadcrumb}
              </>
            )}
          </>
        );
      },
      defaultRouteHandler: InstanceDetail,
      headerButtonsHandler: InstanceDetailHeader,
      requireData: [
        (params: { instanceId: string }, query: { v?: string }) => {
          const { instanceDetail, templateVersionDetail } = TemplatesStore.getStore();

          if (
            templateVersionDetail?.template?.id === instanceDetail?.templateId &&
            params.instanceId === instanceDetail?.instanceId &&
            (!query.v || query.v === instanceDetail.version)
          ) {
            return;
          }

          return Promise.all([
            NotificationActions.loadNotifications(),
            actions.loadInstanceDetail(params.instanceId).then(({ templateId, version }) =>
              actions.loadTemplateDetail(templateId, query.v || version).catch((error) => {
                if (error.response.error === 'template.deprecated') return;

                throw error;
              }),
            ),
          ]);
        },
      ],
      poll: {
        interval: 60,
        action() {
          return JobsActionCreators.loadLatestJobsForConfigurations(KEBOOLA_ORCHESTRATOR);
        },
      },
      childRoutes: [
        {
          name: routeNames.INSTANCE_DETAIL_EDIT,
          path: 'configure',
          title: () => TemplatesStore.getStore().instanceDetail?.name,

          breadcrumbHandler: ({ breadcrumbs }: { breadcrumbs: any[] }) => {
            const { templates, instanceDetail } = TemplatesStore.getStore();
            const usedTemplate = templates.find(
              (template) => template.id === instanceDetail?.templateId,
            );

            if (!usedTemplate) return breadcrumbs;

            return (
              <>
                {breadcrumbs.slice(0, -1)}
                <RouterLink
                  className="dark muted"
                  to={routeNames.TEMPLATE_DETAIL}
                  params={{ templateId: usedTemplate.id }}
                >
                  {usedTemplate.name}
                </RouterLink>
              </>
            );
          },
          defaultRouteHandler: InstanceConfigurator,
          headerButtonsHandler: InstanceConfiguratorHeader,
          requireData: [
            (params: { instanceId: string }, query: { v?: string }) => {
              return actions.loadInstanceInputs(params.instanceId, query.v).then(() => {
                const configuredOauthInputs = TemplatesStore.getStore()
                  .instanceConfiguratorForm.inputs?.stepGroups.flatMap((group) => group.steps)
                  .flatMap((step) => step.inputs)
                  .filter(
                    (input) => input.kind === 'oauth' && input.componentId && input.default?.id,
                  );

                if (!configuredOauthInputs) return;

                return Promise.map(
                  configuredOauthInputs,
                  (input) => OauthActions.loadCredentials(input.componentId, input.default.id),
                  { concurrency: 3 },
                );
              });
            },
          ],
        },
      ],
    },
  ],
};
