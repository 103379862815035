import { fromJS } from 'immutable';

import Dispatcher from '@/Dispatcher';
import callDockerAction from '@/modules/components/DockerActionsApi';
import InstalledComponentsActionCreators, {
  storeEncodedConfig,
} from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import * as oauthUtils from '@/modules/oauth-v2/OauthUtils';
import fromJSOrdered from '@/utils/fromJSOrdered';
import Constants from './ConfigurationsConstants';
import ConfigurationsStore from './ConfigurationsStore';
import DockerActionsActionCreators from './DockerActionsActionCreators';

export default {
  updateConfiguration: function (componentId, configurationId, value) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_UPDATE_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      value: value,
    });
  },

  resetConfiguration: function (componentId, configurationId) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_RESET_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
    });
  },

  saveJsonConfiguration: function (componentId, configurationId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_SAVE_JSON_CONFIGURATION_START,
      componentId: componentId,
      configurationId: configurationId,
    });
    const configuration = fromJSOrdered(
      JSON.parse(
        ConfigurationsStore.getEditingJsonConfigurationString(componentId, configurationId),
      ),
    );

    return storeEncodedConfig(
      componentId,
      configurationId,
      configuration.toJS(),
      changeDescription ? changeDescription : 'Configuration parameters edited manually',
    )
      .then(function (storedConfiguration) {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_SAVE_JSON_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          data: storedConfiguration,
        });
      })
      .catch(function (e) {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_SAVE_JSON_CONFIGURATION_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          error: e,
        });
        throw e;
      });
  },

  updateJsonConfiguration: function (componentId, configurationId, value) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_UPDATE_JSON_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      value: value,
    });
  },

  resetJsonConfiguration: function (componentId, configurationId) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_RESET_JSON_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
    });
  },

  openJsonEditor: function (componentId, configurationId) {
    this.resetConfiguration(componentId, configurationId);
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_JSON_EDITOR_OPEN,
      componentId: componentId,
      configurationId: configurationId,
    });
  },

  closeJsonEditor: function (componentId, configurationId) {
    this.resetJsonConfiguration(componentId, configurationId);
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_JSON_EDITOR_CLOSE,
      componentId: componentId,
      configurationId: configurationId,
    });
  },

  saveForcedConfiguration: function (
    componentId,
    configurationId,
    forcedConfiguration,
    changeDescription,
  ) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_SAVE_CONFIGURATION_START,
      componentId: componentId,
      configurationId: configurationId,
    });
    return storeEncodedConfig(
      componentId,
      configurationId,
      forcedConfiguration.toJS(),
      changeDescription ? changeDescription : 'Configuration edited',
    )
      .then(function (response) {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_SAVE_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          data: response,
        });
        DockerActionsActionCreators.reloadIndexSyncActions(componentId, configurationId);
        return null;
      })
      .catch(function (e) {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_SAVE_CONFIGURATION_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          error: e,
        });
        throw e;
      });
  },

  orderRows: function (componentId, configurationId, rowIds, movedRowId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATIONS_ORDER_ROWS_START,
      componentId: componentId,
      configurationId: configurationId,
      rowId: movedRowId,
      rowIds: rowIds,
    });
    return InstalledComponentsApi.updateComponentConfiguration(componentId, configurationId, {
      rowsSortOrder: rowIds,
      changeDescription: changeDescription || 'Rows order changed',
    })
      .then(function (response) {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_ORDER_ROWS_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          response: response,
        });
        return null;
      })
      .catch(function (e) {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATIONS_ORDER_ROWS_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          error: e,
        });
        throw e;
      });
  },

  resetOauthCredentials(componentId, configurationId) {
    return oauthUtils.deleteCredentialsAndConfigAuth(componentId, configurationId).then(() => {
      VersionActionCreators.loadVersionsForce(componentId, configurationId);
      DockerActionsActionCreators.reloadIndexSyncActions(componentId, configurationId);
      return null;
    });
  },

  loadDatabricksClusters(componentId) {
    return callDockerAction(componentId, 'list-clusters', {
      configData: {},
    }).then((result) => {
      const isError = result?.status === 'error';

      InstalledComponentsActionCreators.updateLocalState(
        componentId,
        null,
        fromJS({ clusters: !isError && Array.isArray(result) ? result : [] }),
        'clusters',
      );

      if (isError) {
        throw result;
      }
    });
  },
};
