import React from 'react';
import { Promise } from 'bluebird';
import { Tooltip } from 'design';
import { fromJS, type List } from 'immutable';
import Switch from 'rc-switch';
import { capitalize } from 'underscore.string';

import { BETA_FEATURES, FEATURE_HAS_FLOWS_ONLY } from '@/constants/features';
import { BadgeBeta } from './Badges';

const FEATURES_OVERRIDES = fromJS({
  [FEATURE_HAS_FLOWS_ONLY]: {
    title: 'Orchestrations',
    description:
      'When activated, this feature makes the old Orchestration interface, now replaced by Flows, accessible to all users. ',
    reverse: true,
  },
});

const FeatureList = (props: {
  availableFeatures: List<any>;
  activatedFeatures: List<string>;
  onAddFeature: (feature: string) => Promise<any>;
  onRemoveFeature: (feature: string) => Promise<any>;
  readOnly?: boolean;
}) => {
  const [isSaving, setSaving] = React.useState(false);

  return (
    <div className="features-list">
      {props.availableFeatures
        .map((feature) => {
          if (FEATURES_OVERRIDES.has(feature.get('name'))) {
            return feature.mergeDeep(FEATURES_OVERRIDES.get(feature.get('name')));
          }

          return feature;
        })
        .sortBy((feature) => feature.get('title').toLowerCase())
        .map((feature) => {
          const isActivated = props.activatedFeatures.includes(feature.get('name'));
          const isEnabled = feature.get('reverse', false) ? !isActivated : isActivated;

          return (
            <div key={feature.get('id')} className="flex-container flex-start align-top">
              <Tooltip tooltip={`${isEnabled ? 'Deactivate' : 'Activate'} Feature`} placement="top">
                <Switch
                  prefixCls="switch"
                  className="wider tw-mt-0.5"
                  checked={isEnabled}
                  onChange={() => {
                    setSaving(true);
                    Promise.resolve()
                      .then(() => {
                        return isActivated
                          ? props.onRemoveFeature(feature.get('name'))
                          : props.onAddFeature(feature.get('name'));
                      })
                      .finally(() => setSaving(false));
                  }}
                  disabled={props.readOnly || isSaving}
                />
              </Tooltip>
              <div className="ml-1 fill-space">
                <div className="flex-container flex-start">
                  <h4 className="m-0 f-14 line-height-16 font-medium">
                    {!!feature.get('title') && feature.get('title') !== feature.get('name')
                      ? feature.get('title')
                      : capitalize(feature.get('name').replaceAll('-', ' '))}
                  </h4>
                  {BETA_FEATURES.includes(feature.get('name')) && <BadgeBeta className="tw-ml-2" />}
                </div>
                <p className="mtp-2 mb-0 f-12 line-height-16 color-base">
                  {feature.get('description')}
                </p>
              </div>
            </div>
          );
        })
        .reduce((prev, curr) => [prev, <hr key="split" />, curr])}
    </div>
  );
};

export default FeatureList;
