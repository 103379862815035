import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableIconAction from './TableIconAction';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

const ExportSelected = ({ onClick, disabled = false }: Props) => {
  return (
    <TableIconAction tooltip="Export selected" onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon="down-to-line" fixedWidth />
    </TableIconAction>
  );
};

export default ExportSelected;
