import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { HelpBlock, PanelWithDetails } from 'design';

import Checkbox from '@/react/common/Checkbox';

class ProcessingSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Processing Settings</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.value.decompress}
                onChange={(checked) => this.props.onChange({ decompress: checked })}
                disabled={this.props.disabled}
              >
                Decompress
              </Checkbox>
              <HelpBlock>
                Decompress downloaded file(s). All files in all archives will be imported into a
                single Storage table.
              </HelpBlock>
            </div>
          </FormGroup>
          <div className="-tw-mx-5">
            <PanelWithDetails
              defaultExpanded={
                this.props.value.addFilenameColumn || this.props.value.addRowNumberColumn
              }
              placement="bottom"
              labelOpen="Show additional processing settings"
              labelCollapse="Hide additional processing settings"
            >
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.addFilenameColumn}
                    onChange={(checked) => this.props.onChange({ addFilenameColumn: checked })}
                    disabled={this.props.disabled}
                  >
                    Add Filename Column
                  </Checkbox>
                  <HelpBlock>
                    Add an <code>azure_filename</code> column that will store the processed file
                    name.
                  </HelpBlock>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.addRowNumberColumn}
                    onChange={(checked) => this.props.onChange({ addRowNumberColumn: checked })}
                    disabled={this.props.disabled}
                  >
                    Add Row Number Column
                  </Checkbox>
                  <HelpBlock>
                    Add an <code>azure_row_number</code> column that will store the row number from
                    the processed file.
                  </HelpBlock>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.add_timestamp}
                    onChange={(checked) => this.props.onChange({ add_timestamp: checked })}
                    disabled={this.props.disabled}
                  >
                    Add Timestamp To Filename
                  </Checkbox>
                  <HelpBlock>
                    Add current timestamp to values in <code>filename</code> column
                  </HelpBlock>
                </div>
              </FormGroup>
            </PanelWithDetails>
          </div>
        </div>
      </div>
    );
  }
}

ProcessingSettings.propTypes = {
  value: PropTypes.shape({
    decompress: PropTypes.bool.isRequired,
    addFilenameColumn: PropTypes.bool.isRequired,
    addRowNumberColumn: PropTypes.bool.isRequired,
    add_timestamp: PropTypes.bool.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ProcessingSettings;
