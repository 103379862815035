import Promise from 'bluebird';
import { fromJS } from 'immutable';

import dispatcher from '@/Dispatcher';
import storageApi from '@/modules/components/StorageApi';
import pushGtmEvent, { events as gtmEvents } from '@/utils/pushGtmEvent';
import constants from './constants';
import StorageTokensStore from './StorageTokensStore';

const updateLocalState = (newLocalState) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TOKEN_UPDATE_LOCALSTATE,
    localState: newLocalState,
  });
};

const deleteToken = (tokenObject) => {
  const tokenId = tokenObject.get('id');
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TOKEN_DELETE,
    tokenId: tokenId,
  });
  return storageApi
    .deleteToken(tokenId)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_DELETE_SUCCESS,
        tokenId: tokenId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_DELETE_ERROR,
        error: error,
        tokenId: tokenId,
      });
      throw error;
    });
};

const refreshToken = (tokenObject) => {
  const tokenId = tokenObject.get('id');
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TOKEN_REFRESH,
    tokenId: tokenId,
  });
  return storageApi
    .refreshToken(tokenId)
    .tap(() => pushGtmEvent(gtmEvents.EVENT_PROJECT_TOKEN_REFRESHED))
    .then((newToken) => {
      const newTokenMap = fromJS(newToken);
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_REFRESH_SUCCESS,
        tokenId: tokenId,
        newToken: newTokenMap,
      });
      return newTokenMap;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_REFRESH_ERROR,
        error: error,
        tokenId: tokenId,
      });
      throw error;
    });
};

const sendToken = (tokenId, params) => {
  const { email, message } = params;
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TOKEN_SEND,
    tokenId: tokenId,
  });
  return storageApi
    .shareToken(tokenId, email, message)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_SEND_SUCCESS,
        tokenId: tokenId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_SEND_ERROR,
        error: error,
        tokenId: tokenId,
      });
      throw error;
    });
};

const loadTokens = () => {
  if (StorageTokensStore.getIsLoaded()) {
    loadTokensForce();
    return Promise.resolve();
  }
  return loadTokensForce();
};

const loadTokensForce = () => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TOKENS_LOAD,
  });
  return storageApi
    .getTokens()
    .then((tokens) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKENS_LOAD_SUCCESS,
        tokens: tokens,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKENS_LOAD_ERROR,
        error: error,
      });
      throw error;
    });
};

const createToken = (params) => {
  return storageApi
    .createToken(params)
    .tap(() => pushGtmEvent(gtmEvents.EVENT_PROJECT_TOKEN_CREATED))
    .then((createdToken) => {
      const token = fromJS(createdToken);
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_CREATE_SUCCESS,
        token: token,
      });
      return token;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_CREATE_ERROR,
        status: error.status,
        response: error.response,
      });
      throw error;
    });
};

const updateToken = (tokenId, params) => {
  if (params.canManageBuckets) {
    delete params.componentAccess;
    delete params.bucketPermissions;
  }
  return storageApi
    .updateToken(tokenId, params)
    .then((updatedToken) => {
      const token = fromJS(updatedToken);
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_UPDATE_SUCCESS,
        tokenId: tokenId,
        token: token,
      });
      return token;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TOKEN_CREATE_ERROR,
        status: error.status,
        response: error.response,
      });
      throw error;
    });
};

export default {
  updateLocalState,
  deleteToken,
  refreshToken,
  sendToken,
  loadTokens,
  loadTokensForce,
  createToken,
  updateToken,
};
