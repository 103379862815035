import { APIFY_APIFY as COMPONENT_ID } from '@/constants/componentIds';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import Index from './react/Index/Index';

export default {
  name: COMPONENT_ID,
  path: `${COMPONENT_ID}/:config`,
  defaultRouteHandler: Index,
  requireData: [(params: { config: string }) => configRequiredData(COMPONENT_ID, params.config)],
  poll: configPoll(COMPONENT_ID),
};
