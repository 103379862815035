import { fromJS, List, Map } from 'immutable';

import { TRANSFORMATION } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import * as constants from '@/modules/jobs/Constants';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

const EmptyValue = Map({ isLoaded: false, isLoading: true, jobs: List() });

let _store = initStore('LatestJobsStore', Map());

const JobsStore = StoreUtils.createStore({
  getJobs(componentId, configurationId) {
    return _store.getIn([componentId, configurationId], EmptyValue);
  },

  getRowJobs(componentId, configurationId, rowId) {
    return _store.getIn([componentId, configurationId], EmptyValue).update('jobs', (jobs) => {
      return jobs.filter((job) => {
        if (!job.hasIn(['params', 'row'])) {
          return true;
        }
        if (job.getIn(['params', 'row']) === rowId) {
          return true;
        }
        return false;
      });
    });
  },

  getTransformationJobs(configurationId, rowId) {
    return _store.getIn([TRANSFORMATION, configurationId], EmptyValue).update('jobs', (jobs) => {
      return jobs.filter((job) => {
        if (job.getIn(['params', 'transformations'], List()).count() === 0) {
          return true;
        }
        return job.getIn(['params', 'transformations']).includes(rowId);
      });
    });
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case constants.ActionTypes.JOBS_LATEST_LOAD_START:
      _store = _store.updateIn([action.componentId, action.configurationId], EmptyValue, (jobs) =>
        jobs.set('isLoading', true),
      );
      return JobsStore.emitChange();

    case constants.ActionTypes.JOBS_LATEST_LOAD_ERROR:
      _store = _store.setIn([action.componentId, action.configurationId, 'isLoading'], false);
      return JobsStore.emitChange();

    case constants.ActionTypes.JOBS_LATEST_LOAD_SUCCESS:
      _store = _store.withMutations((store) =>
        store.setIn(
          [action.componentId, action.configurationId],
          Map({
            isLoading: false,
            isLoaded: true,
            jobs: fromJS(action.jobs),
          }),
        ),
      );
      return JobsStore.emitChange();

    default:
      break;
  }
});

export default JobsStore;
