import React from 'react';
import { ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import type Promise from 'bluebird';
import classNames from 'classnames';
import { Alert, HelpBlock } from 'design';
import { fromJS, List, Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { defaultProxyAuthorization } from '@/modules/data-apps/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import PasswordControl from '@/react/common/PasswordControl';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import fromJSOrdered from '@/utils/fromJSOrdered';

const MODES = {
  BASIC: 'basic',
  OIDC: 'oidc',
  NONE: 'none',
};

const PROVIDERS = {
  OIDC: 'oidc',
  AZURE: 'azure',
};

const PROVIDERS_PATH = ['authorization', 'app_proxy', 'auth_providers'];
const AUTH_RULES_PATH = ['authorization', 'app_proxy', 'auth_rules'];
const STREAMLIT_AUTH_PATH = ['parameters', 'dataApp', 'streamlitAuthEnabled'];

const AuthenticationSettings = (props: {
  readOnly: boolean;
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  onSave: (configData: Map<string, any>) => Promise<any>;
}) => {
  const savedProvider = props.configData.getIn([...PROVIDERS_PATH, 0], Map());
  const hasStreamlitAuth = props.configData.getIn(STREAMLIT_AUTH_PATH, false);
  const savedMode =
    hasStreamlitAuth || savedProvider.get('type') === 'password'
      ? MODES.BASIC
      : savedProvider.isEmpty()
      ? MODES.NONE
      : MODES.OIDC;

  const [isSaving, setSaving] = React.useState(false);
  const [mode, setMode] = React.useState(savedMode);
  const [formData, setFormData] = React.useState(savedProvider);

  const provider = formData.has('tenant_id') ? PROVIDERS.AZURE : PROVIDERS.OIDC;

  const isDisabled = () => {
    if (mode !== MODES.OIDC) {
      return false;
    }

    if (!formData.get('client_id') || !formData.get('#client_secret')) {
      return true;
    }

    return provider === PROVIDERS.OIDC ? !formData.get('issuer_url') : !formData.get('tenant_id');
  };

  const renderRadioInput = (label: string, value: string) => {
    return (
      <Radio
        value={value}
        checked={value === mode}
        onChange={() => setMode(value)}
        className="radio-btn tw-flex-1"
        disabled={props.readOnly}
      >
        {label}
      </Radio>
    );
  };

  const renderInput = (
    label: string,
    property: string,
    options?: { optional?: boolean; help?: React.ReactNode },
  ) => {
    const inputProps = {
      disabled: props.readOnly,
      value: formData.get(property, ''),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(formData.set(property, event.target.value));
      },
    };

    return (
      <FormGroup>
        <ControlLabel>
          {label} {options?.optional && <OptionalFormLabel />}
        </ControlLabel>
        {property.startsWith('#') ? (
          <PasswordControl {...inputProps} />
        ) : (
          <FormControl type="text" {...inputProps} />
        )}
        {options?.help && <HelpBlock>{options.help}</HelpBlock>}
      </FormGroup>
    );
  };

  const renderForm = () => {
    if (mode === MODES.BASIC) {
      return (
        <Alert className="tw-mt-4">
          {hasStreamlitAuth
            ? 'Authentication with single username/password pair via Streamlit Authenticator.'
            : 'Authentication with simple password.'}
        </Alert>
      );
    }

    if (mode === MODES.NONE) {
      return (
        <Alert className="tw-mt-4" variant="warning">
          App will be publicly available without authentication!
        </Alert>
      );
    }

    return (
      <>
        <FormGroup className="tw-mt-4">
          <ControlLabel>Provider</ControlLabel>
          <Select
            clearable={false}
            searchable={false}
            value={provider}
            onChange={(type: string) => {
              const newformData = formData.update((data: Map<string, any>) => {
                switch (type) {
                  case PROVIDERS.OIDC:
                    return data.delete('tenant_id').set('issuer_url', '');

                  case PROVIDERS.AZURE:
                    return data.set('tenant_id', '').set('issuer_url', '');
                }
              });

              setFormData(newformData);
            }}
            options={[
              { value: PROVIDERS.OIDC, label: 'Generic OIDC' },
              { value: PROVIDERS.AZURE, label: 'Azure OIDC' },
            ]}
            disabled={props.readOnly}
          />
        </FormGroup>
        {renderInput('Client ID', 'client_id')}
        {renderInput('Client secret', '#client_secret')}
        {provider === PROVIDERS.AZURE && renderInput('Tenant ID', 'tenant_id')}
        {provider === PROVIDERS.OIDC && (
          <>
            {renderInput('Issuer URL', 'issuer_url')}
            {renderInput('Logout URL', 'logout_url', {
              optional: true,
              help: 'Provided by the identity provider.',
            })}
          </>
        )}
      </>
    );
  };

  return (
    <CollapsibleBox
      title="Authentication"
      defaultOpen={savedProvider.isEmpty() && !hasStreamlitAuth}
      collapsePrefix={
        <span
          className={classNames(
            'tw-mr-2 tw-text-xs tw-font-medium',
            savedMode !== MODES.NONE ? 'tw-text-primary-600' : 'tw-text-warning-600',
          )}
        >
          {savedMode !== MODES.NONE ? 'Configured' : 'None'}
        </span>
      }
      additionalActions={() => {
        if (props.readOnly) {
          return null;
        }

        return (
          <SaveButtons
            isChanged={!savedProvider.equals(formData) || savedMode !== mode}
            onReset={() => {
              setMode(savedMode);
              setFormData(savedProvider);
            }}
            onSave={() => {
              setSaving(true);
              let configData = props.configData
                .set('authorization', fromJS(defaultProxyAuthorization))
                .deleteIn(STREAMLIT_AUTH_PATH);

              switch (mode) {
                case MODES.BASIC: {
                  configData = configData
                    .setIn(PROVIDERS_PATH, fromJS([{ id: 'simpleAuth', type: 'password' }]))
                    .updateIn([...AUTH_RULES_PATH, 0], (rule) => {
                      return rule.set('auth_required', true).set('auth', List(['simpleAuth']));
                    });
                  break;
                }

                case MODES.OIDC: {
                  const providerId = `${PROVIDERS.OIDC}-1`;
                  const tenantId = formData.get('tenant_id');

                  let provider = formData.set('id', providerId).set('type', PROVIDERS.OIDC);

                  if (tenantId) {
                    provider = provider
                      .set('issuer_url', `https://login.microsoftonline.com/${tenantId}/v2.0`)
                      .set(
                        'logout_url',
                        `https://login.microsoftonline.com/${tenantId}/oauth2/logout`,
                      );
                  }

                  configData = configData
                    .setIn(PROVIDERS_PATH, fromJS([provider]))
                    .updateIn([...AUTH_RULES_PATH, 0], (rule) => {
                      return rule.set('auth_required', true).set('auth', List([providerId]));
                    });
                  break;
                }
              }

              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                configData,
                'Update authentication',
              )
                .then((response) => {
                  setFormData(fromJSOrdered(response).getIn([...PROVIDERS_PATH, 0], Map()));
                })
                .finally(() => setSaving(false));
            }}
            isSaving={isSaving}
            disabled={isDisabled()}
          />
        );
      }}
    >
      <div className="tw-flex tw-gap-2">
        {renderRadioInput('Basic', MODES.BASIC)}
        {renderRadioInput('OIDC', MODES.OIDC)}
        {renderRadioInput('None', MODES.NONE)}
      </div>
      {renderForm()}
    </CollapsibleBox>
  );
};

export default AuthenticationSettings;
