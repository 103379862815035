import React from 'react';
import PropTypes from 'prop-types';

import blankOrchestrationImage from '../../../../../../images/illustrations/blank-orchestrations.png';

import NewOrchestrationButton from '@/modules/orchestrations/react/modals/NewOrchestration';
import { fileUrl } from '@/utils/fileHelpers';

class BlankOrchestrationPage extends React.Component {
  render() {
    return (
      <div className="blank-page">
        <img
          src={fileUrl(blankOrchestrationImage)}
          className="intro-image"
          alt="Orchestrations introduction"
        />
        <h2>What are orchestrations</h2>
        <p className="intro-text">
          Orchestrations allow you to group together related tasks and schedule their execution.
        </p>
        <div className="intro-action">
          {this.props.canManageTokens && <NewOrchestrationButton />}
        </div>
      </div>
    );
  }
}

BlankOrchestrationPage.propTypes = {
  canManageTokens: PropTypes.bool.isRequired,
};

export default BlankOrchestrationPage;
