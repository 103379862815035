import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import HeaderButtons from './react/HeaderButtons';
import MySharedBucketDetail from './react/MySharedBucketDetail';
import MySharedBucketHeaderButton from './react/MySharedBucketHeaderButton';
import SharedFromThisProject from './react/SharedFromThisProject';
import { routeNames } from './constants';

export default {
  name: routeNames.SHARED_FROM_THIS_PROJECT,
  title: 'Shared from this project',
  subtitle: 'Data Catalog',
  defaultRouteHandler: SharedFromThisProject,
  headerButtonsHandler: HeaderButtons,
  requireData: [() => StorageActionCreators.loadBucketsAndTables()],
  poll: {
    skipFirst: true,
    interval: DEFAULT_INTERVAL,
    action: () => StorageActionCreators.loadBucketsAndTables(),
  },
  childRoutes: [
    {
      name: routeNames.SHARED_FROM_THIS_PROJECT_DETAIL,
      path: ':bucketId',
      title(routerState) {
        const sharedBucket = StorageBucketsStore.getBucket(
          routerState.getIn(['params', 'bucketId']),
        );
        return `Bucket ${sharedBucket.get(
          'displayName',
          routerState.getIn(['params', 'bucketId']),
        )}`;
      },
      defaultRouteHandler: MySharedBucketDetail,
      headerButtonsHandler: MySharedBucketHeaderButton,
    },
  ],
};
