import EventEmitter from 'eventemitter3';
import Immutable, { fromJS, Map } from 'immutable';
import _ from 'underscore';

import Timer from '@/utils/Timer';
import api from './EventsApi';

const CHANGE_EVENT = 'change';
const INITIAL_LIMIT = 50;
const MAX_LIMIT = 200;

class EventsService {
  constructor(api1, defaultParams) {
    this.loadNew = this.loadNew.bind(this);
    this.api = api1;
    this.defaultParams = defaultParams;
    this._emmiter = new EventEmitter();
    this._autoReload = false;
    this.reset();
  }

  reset() {
    this._events = Map();
    this._query = '';
    this._isLoading = false;
    this._loadingOlder = false;
    this._hasMore = true;
    this._errorMessage = null;
    this.stopAutoReload();
    this._emitChange();
    this._limit = INITIAL_LIMIT;
  }

  setParams(params) {
    this.defaultParams = params;
    return this.reset();
  }

  setQuery(query) {
    this._query = query;
  }

  setLimit(limit) {
    this._limit = limit;
  }

  setAutoReload(flag) {
    if (flag) {
      return this.startAutoReload();
    } else {
      return this.stopAutoReload();
    }
  }

  stopAutoReload() {
    if (!this._autoReload) {
      return;
    }
    this._autoReload = false;
    return Timer.stop(this.loadNew);
  }

  startAutoReload() {
    if (this._autoReload) {
      return;
    }
    this._autoReload = true;
    return Timer.poll(this.loadNew, {
      interval: 5,
      skipFirst: true,
    });
  }

  loadEvent(id) {
    const intId = parseInt(id, 10);

    if (this._events.has(intId)) {
      return Promise.resolve(this._events.get(id));
    }

    return this.api.getEvent(intId).then(fromJS);
  }

  load() {
    this._isLoading = true;
    this._errorMessage = null;
    this._limit = INITIAL_LIMIT;
    this._emitChange();
    return this._listEvents().then(this._setEvents.bind(this)).catch(this._onLoadError.bind(this));
  }

  _onLoadError(error) {
    this._events = Map();
    return this._onError(error);
  }

  loadNew() {
    this._isLoading = true;
    this._errorMessage = null;
    this._emitChange();

    const event = this.getEvents().first();
    const params = { limit: 10 };

    if (event && event.has('id')) {
      params.sinceId = event.get('id');
    }

    return this._listEvents(params)
      .then(this._prependEvents.bind(this))
      .catch(this._onError.bind(this));
  }

  loadMore(params = {}) {
    this._loadingOlder = true;
    this._errorMessage = null;
    this._emitChange();

    const lastEvent = this.getEvents().last();

    return this._listEvents({
      ...(lastEvent && { maxId: lastEvent.get('id') }),
      ...params,
    })
      .then(this._appendEvents.bind(this))
      .catch(this._onError.bind(this));
  }

  loadMoreMax() {
    this.loadMore({ limit: MAX_LIMIT }).then(() => {
      if (this._events.count() < MAX_LIMIT) {
        this._hasMore = false;
        this._emitChange();
      }
    });
  }

  _onError(error) {
    this._loadingOlder = false;
    this._isLoading = false;
    this._errorMessage = error.message;
    return this._emitChange();
  }

  _getParams() {
    return _.extend({}, this.defaultParams, {
      q: this._query,
      limit: this._limit,
    });
  }

  _listEvents(params) {
    return this.api.listEvents(_.extend({}, this._getParams(), params));
  }

  getEvents() {
    return this._events.toSeq().sortBy((event) => event.get('id') * -1);
  }

  getIsLoadingOlder() {
    return this._loadingOlder;
  }

  getIsLoading() {
    return this._isLoading;
  }

  getHasMore() {
    return this._hasMore;
  }

  getQuery() {
    return this._query;
  }

  getErrorMessage() {
    return this._errorMessage;
  }

  _setEvents(events) {
    this._isLoading = false;
    this._events = this._convertEvents(events);
    this._hasMore = events.length >= this._limit;
    return this._emitChange();
  }

  _prependEvents(events) {
    this._isLoading = false;
    if (events.length) {
      this._events = this._events.merge(this._convertEvents(events));
    }
    return this._emitChange();
  }

  _appendEvents(events) {
    this._loadingOlder = false;
    this._events = this._events.merge(this._convertEvents(events));
    if (!events.length) {
      this._hasMore = false;
    }
    return this._emitChange();
  }

  _convertEvents(eventsRaw) {
    return Immutable.fromJS(eventsRaw)
      .toMap()
      .mapKeys((key, event) => event.get('id'));
  }

  _emitChange() {
    return this._emmiter.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    return this._emmiter.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    return this._emmiter.removeListener(CHANGE_EVENT, callback);
  }
}

export { EventsService };

export function factory(params, apiParam) {
  return new EventsService(apiParam || api, params);
}
