import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Tooltip } from 'design';

import { canDownloadSlicedFile } from '@/modules/admin/privileges';
import ModalHandler from '@/modules/sapi-events/sliced-files-downloader/ModalHandler';
import ApplicationStore from '@/stores/ApplicationStore';

const FileLinkButton = (props) => {
  if (props.file.get('isSliced') && !canDownloadSlicedFile(ApplicationStore.getSapiToken())) {
    return null;
  }

  if (props.file.get('isSliced')) {
    return <ModalHandler mode="button" file={props.file} onKeyDown={props.onKeyDown} />;
  }

  return (
    <Tooltip tooltip="Download file" placement="top">
      <Link className="btn btn-link text-muted" href={props.file.get('url')}>
        <FontAwesomeIcon icon="circle-down" fixedWidth />
      </Link>
    </Tooltip>
  );
};

FileLinkButton.propTypes = {
  file: PropTypes.object,
  onKeyDown: PropTypes.func,
};

export default FileLinkButton;
