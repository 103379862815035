import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';

import Confirm from '@/react/common/Confirm';

const MigrateToRowsButton = (props: { isProcessing: boolean; onConfrim: () => Promise<any> }) => {
  return (
    <Confirm
      closeAfterResolve
      icon="circle-check"
      title="Migrating Configuration"
      text={
        <>
          <p>You are about to run migration to configuration rows.</p>
          <b>Do not close modal until migration completes.</b>
        </>
      }
      buttonType="success"
      buttonLabel="Migrate Configuration"
      isLoading={props.isProcessing}
      onConfirm={props.onConfrim}
    >
      <Button bsStyle="success" disabled={props.isProcessing}>
        <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
        Migrate Configuration
      </Button>
    </Confirm>
  );
};

export default MigrateToRowsButton;
