import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { DATE_FORMAT, TIME_FORMAT } from '@/date';
import { displayTimezone } from '@/modules/orchestrations-v2/timezones';
import { getNextSchedule } from './cronSchedulerUtils';

class CronSchedulerPreview extends React.Component {
  render() {
    const next = getNextSchedule(this.props.crontabRecord, this.props.timezone);

    if (!next || !next.length) {
      return null;
    }

    return (
      <div className="cron-preview">
        <p className="text-muted f-12 font-medium">
          Schedule Preview (in {displayTimezone(this.props.timezone)} Time Zone)
        </p>
        <ul className="list-unstyled mb-0">
          {next.map((date, index) => (
            <li
              key={index}
              className={classnames({
                'mb-1': index !== next.length - 1,
              })}
            >
              <FontAwesomeIcon
                icon={['far', 'calendar-check']}
                className="icon-addon-right text-muted f-16"
              />
              {date.format(DATE_FORMAT)} at {date.format(TIME_FORMAT)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

CronSchedulerPreview.propTypes = {
  crontabRecord: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default CronSchedulerPreview;
