import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import RoutesStore from '@/stores/RoutesStore';

const CreateQueryElement = createReactClass({
  propTypes: {
    isNav: PropTypes.bool.isRequired,
    configurationId: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    actionsProvisioning: PropTypes.object.isRequired,
  },

  createQuery() {
    const ExDbActionCreators = this.props.actionsProvisioning.createActions(this.props.componentId);
    let query = ExDbActionCreators.createQuery(this.props.configurationId);
    RoutesStore.getRouter().transitionTo('ex-db-generic-' + this.props.componentId + '-query', {
      config: this.props.configurationId,
      query: query.get('id'),
    });
  },

  render() {
    if (this.props.isNav) {
      return (
        <a className="list-group-item" onClick={this.createQuery}>
          <strong>
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Create a new entry
          </strong>
        </a>
      );
    }

    return (
      <Button bsStyle="success" bsSize="sm" onClick={this.createQuery}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Table
      </Button>
    );
  },
});

export default CreateQueryElement;
