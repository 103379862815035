import React from 'react';

const FragmentWithForwaredProps = (props: any) => {
  const { children, ...restProps } = props;

  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    return React.cloneElement(child, restProps);
  });
};

export default FragmentWithForwaredProps;
