export const ASSETS_URL = 'https://ui.keboola-assets.com';
export const PLATFORM_CHANGELOG_URL = 'https://changelog.keboola.com';

const PayloadSources = {
  SERVER_ACTION: 'SERVER_ACTION',
  VIEW_ACTION: 'VIEW_ACTION',
};

const ActionTypes = {
  // Application state
  APPLICATION_RECEIVE_STACK: 'APPLICATION_RECEIVE_STACK',
  APPLICATION_EMIT_CHANGE: 'APPLICATION_EMIT_CHANGE',
  APPLICATION_INITIAL_LOADING: 'APPLICATION_INITIAL_LOADING',
  APPLICATION_POLL_LOADING: 'APPLICATION_POLL_LOADING',
  APPLICATION_DATA_RECEIVED: 'APPLICATION_DATA_RECEIVED',
  APPLICATION_SEND_NOTIFICATION: 'APPLICATION_SEND_NOTIFICATION',
  APPLICATION_DELETE_NOTIFICATION: 'APPLICATION_DELETE_NOTIFICATION',
  APPLICATION_SET_PAUSE_NOTIFICATION: 'APPLICATION_SET_PAUSE_NOTIFICATION',
  APPLICATION_ADD_PROJECT_FEATURE: 'APPLICATION_ADD_PROJECT_FEATURE',
  APPLICATION_REMOVE_PROJECT_FEATURE: 'APPLICATION_REMOVE_PROJECT_FEATURE',

  // Admin state
  APPLICATION_ADD_USER_FEATURE: 'APPLICATION_ADD_USER_FEATURE',
  APPLICATION_REMOVE_USER_FEATURE: 'APPLICATION_REMOVE_USER_FEATURE',

  // Sapi Token
  SAPI_TOKEN_RECEIVED: 'SAPI_TOKEN_RECEIVED',

  // Router state
  ROUTER_ROUTE_CHANGE_START: 'ROUTER_ROUTE_CHANGE_START',
  ROUTER_ROUTE_CHANGE_SUCCESS: 'ROUTER_ROUTE_CHANGE_SUCCESS',
  ROUTER_ROUTE_CHANGE_ERROR: 'ROUTER_ROUTE_CHANGE_ERROR',
  ROUTER_ROUTES_CONFIGURATION_RECEIVE: 'ROUTER_ROUTES_CONFIGURATION_RECEIVE',
  ROUTER_HISTORY_CREATED: 'ROUTER_HISTORY_CREATED',
  ROUTER_ROUTER_CREATED: 'ROUTER_ROUTER_CREATED',
};

const ADMIN_ROLES = {
  ADMIN: 'admin',
  GUEST: 'guest',
  READ_ONLY: 'readOnly',
  SHARE: 'share',

  // SOX project roles
  PRODUCTION_MANAGER: 'productionManager',
  DEVELOPER: 'developer',
  REVIEWER: 'reviewer',
};

export { PayloadSources, ActionTypes, ADMIN_ROLES };
