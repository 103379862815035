import React from 'react';
import type { ReactNode } from 'react';
import { fromJS } from 'immutable';

import { MAPPINGS_COLLAPSIONS } from '@/constants/localStorageKeys';
import type { Props as CollapsibleBoxProps } from '@/react/common/CollapsibleBox';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import ApplicationStore from '@/stores/ApplicationStore';
import { getItem, setItem } from '@/utils/localStorage';

type Props = {
  readOnly: boolean;
  children: ReactNode;
  mappingKey: string;
  componentId: string;
  configId: string;
  rowId?: string;
  isEmpty: boolean;
  header: ReactNode;
  actions: ReactNode;
} & Omit<CollapsibleBoxProps, 'additionalActions'>;

const CollapsibleMapping = ({
  readOnly,
  children,
  mappingKey,
  componentId,
  configId,
  rowId = '',
  isEmpty,
  header,
  actions,
  ...collapsibleBoxProps
}: Props) => {
  const getStorageObject = () => {
    return fromJS(getItem(MAPPINGS_COLLAPSIONS, {}));
  };

  const getStorageKey = () => {
    const currentProjectId = ApplicationStore.getCurrentProjectId();

    return `${currentProjectId}-${componentId}-${configId}-${rowId}-${mappingKey}`;
  };

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(
    !readOnly && getStorageObject().get(getStorageKey(), false),
  );

  const toggleCollapsion = () => {
    setItem(MAPPINGS_COLLAPSIONS, getStorageObject().set(getStorageKey(), !isCollapsed).toJS());
    setIsCollapsed((prev) => !prev);
  };

  if (isEmpty) {
    return (
      <div className="box no-mapping bottom-radius">
        <div className="box-header above-table-with-buttons tw-block">
          <div className="tw-flex tw-items-center tw-justify-between">
            {collapsibleBoxProps.title}
            {actions}
          </div>
        </div>
      </div>
    );
  }

  return (
    <CollapsibleBox
      {...collapsibleBoxProps}
      childrenClassName="!tw-p-0"
      isOpen={!isCollapsed}
      onToggle={() => toggleCollapsion()}
      additionalActions={() => actions}
    >
      <div className="tw-flex tw-items-center tw-px-6 tw-pb-4 tw-shadow-table">{header}</div>
      {children}
    </CollapsibleBox>
  );
};

export default CollapsibleMapping;
