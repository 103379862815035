import type { Map } from 'immutable';

type Props = {
  config: Map<string, any>;
  sandbox: Map<string, any>;
  sapiToken: Map<string, any>;
  admins: Map<string, any>;
};

const OwnerAndSharing = ({ config, sandbox, sapiToken, admins }: Props) => {
  const owner = config?.getIn(['creatorToken', 'description'], '');

  if (!owner) {
    return 'N/A';
  }

  const adminInfo =
    sapiToken.get('description') === owner ? 'You' : admins.getIn([owner, 'name'], owner);
  const sharedInfo = sandbox.get('shared') ? 'Shared' : 'Not shared';

  return `${adminInfo}, ${sharedInfo}`;
};

export default OwnerAndSharing;
