import { List, Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';

const routeNames = {
  ROOT: 'components',
  COMPONENT: 'component',
  COMPONENT_RAW: 'component-raw',
  CONFIGURATIONS: 'configurations',
  GENERIC_CONFIG: 'generic-config',
  GENERIC_CONFIG_RAW: 'generic-config-raw',
  GENERIC_CONFIG_JOBS: 'generic-config-jobs',
  GENERIC_CONFIG_VERSIONS: 'generic-config-versions',
  GENERIC_CONFIG_NOTIFICATIONS: 'generic-config-notifications',
  GENERIC_CONFIG_ROW: 'generic-config-row',
  GENERIC_CONFIG_ROW_RAW: 'generic-config-row-raw',
  GENERIC_CONFIG_ROW_VERSIONS: 'generic-config-row-versions',
};

const allowedTypes = List([
  componentTypes.EXTRACTOR,
  componentTypes.WRITER,
  componentTypes.APPLICATION,
]);

const topComponents = Map({
  'keboola.ex-google-drive': 1,
  'ex-generic-v2': 2,
  'keboola.wr-db-snowflake': 3,
  'keboola.wr-google-sheets': 4,
  'keboola.ex-db-mysql': 5,
  'keboola.ex-google-analytics-v4': 6,
  'keboola.ex-db-snowflake': 7,
  'keboola.wr-google-bigquery-v2': 8,
  'kds-team.ex-mysql-next': 9,
  'keboola.ex-aws-s3': 10,
  'keboola.ex-google-adwords-reports-v201809': 11,
  'keboola.ex-db-mssql': 12,
  'keboola.ex-db-pgsql': 13,
  'tde-exporter': 14,
  'keboola.wr-db-mssql-v2': 15,
  'keboola.gooddata-writer': 16,
  'keboola.wr-google-drive': 17,
  'keboola.ex-http': 18,
  'keboola.ag-geocoding': 19,
  'keboola.ex-ftp': 20,
  'keboola.wr-snowflake-blob-storage': 21,
  'keboola.ex-onedrive': 22,
  'cleveranalytics.wr-clever-analytics': 23,
  'keboola.ex-facebook-ads': 24,
  'medio.ex-google-search-console': 25,
  'jakub-bartel.ex-onedrive': 26,
  'keboola.wr-db-pgsql': 27,
  'blueskydigital.wr-sftp-webdav': 28,
  'keboola.wr-aws-s3': 29,
  'kds-team.wr-powerbi': 30,
  'smetric.wr-wrapper': 31,
  'keboola.ex-sklik': 32,
  'keboola.ex-gooddata': 33,
  'kds-team.ex-azure-blob-storage-v2': 34,
  'keboola.wr-db-mysql': 35,
  'keboola.ex-telemetry-data': 36,
  'keboola.ex-currency': 37,
  'kds-team.wr-generic': 38,
  'keboola.wr-storage': 39,
  'keboola.ex-google-bigquery-v2': 40,
  'apify.apify': 42,
  'keboola.ex-email-attachments': 43,
  'keboola.ex-facebook': 44,
  'keboola.ex-db-oracle': 45,
  'kds-team.ex-adform-reports': 46,
  'htns.wr-salesforce': 47,
  'esnerda.app-orchestrator-trigger-mod': 48,
  'kds-team.app-powerbi-refresh': 49,
  'kds-team.wr-dynamodb': 50,
  'kds-team.ex-web-content-crawler': 51,
  'kds-team.wr-azure-blob': 52,
  'ech.wr-azure': 53,
  'revolt-bi.wr-elasticsearch': 55,
  'esnerda.wr-mailkit': 56,
  'jakub-bartel.wr-data-studio': 57,
  'keboola.ex-mongodb': 58,
  'smetric.ex-xero': 59,
  'kds-team.app-mailgun-v2': 60,
  'performax.ex-google-ad-manager-report-service': 61,
  'cleveranalytics.can-areamapper': 62,
  'kds-team.ex-kbc-project-metadata-v2': 63,
  'keboola.wr-onedrive': 64,
  'revolt-bi.app-daktela': 65,
  'keboola.ex-pipedrive': 66,
  'keboola.app-orchestrator-trigger': 67,
  'keboola.ex-instagram': 68,
  'blueskydigital.wr-ftp': 69,
  'performax.ex-ssp-sklik': 70,
  'kds-team.app-tableau-extract-refresh-trigger': 71,
  'kds-team.wr-simple-sftp': 72,
  'kds-team.ex-hubspot-crm': 73,
  'medio.ex-heureka-cz': 74,
  'smetric.ex-cin7-api': 75,
  'medio.ex-sklik-v2': 76,
  'keboola.ex-storage': 77,
  'apac.ex-gmail-attachments': 78,
  'leochan.ex-linkedin': 79,
  'bizztreat.wr-bigquery': 80,
  'cpex.ex-rubicon': 81,
  'kds-team.app-jira-project-creator': 82,
  'kds-team.app-gd-user-management': 83,
  'kds-team.ex-jira': 84,
  'cleveranalytics.app-cleveranalytics-spatialutils': 85,
  'smetric.ex-simpro': 86,
  'keboola.r-transformation-v2': 87,
  'keboola.wr-slack': 88,
  'keboola.ex-db-firebird': 89,
  'kds-team.ex-cj-affiliate': 90,
  'keboola.ex-mailchimp': 91,
  'ex-adform-masterdata': 92,
  'keboola.ex-zendesk': 93,
  'keboola.ex-sample-data': 94,
  'esnerda.ex-bingads': 95,
  'kds-team.wr-lytics-v2': 96,
  'keboola.ex-db-redshift': 97,
  'kds-team.wr-salesforce': 98,
  'geneea.nlp-analysis-v2': 99,
  'keboola.ex-dynamodb': 100,
});

export { routeNames, allowedTypes, topComponents };
