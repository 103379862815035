import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import ParametersResultsModal from '@/modules/queue/components/ParametersResultsModal';
import BlockButton from '@/react/common/BlockButton';

class JobsParametersResultsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShown: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  render() {
    const params = this.props.job.get('params');
    return (
      <>
        <div className="box-panel-content">
          <h4 className="mt-0 font-mono font-bold">Parameters</h4>
          <table className="params-table">
            <tbody>{params.map(this.renderListItem).valueSeq()}</tbody>
          </table>
        </div>
        <BlockButton label="Show All" onClick={this.showModal} />
        <ParametersResultsModal
          job={this.props.job}
          params={this.props.job.get('params')}
          onHide={this.hideModal}
          show={this.state.isModalShown}
        />
      </>
    );
  }

  renderListItem(value, key) {
    if (Immutable.Iterable.isIterable(value) === false) {
      return (
        <tr key={key}>
          <th>{key}</th>
          <td>{value}</td>
        </tr>
      );
    }
    return null;
  }

  showModal() {
    this.setState({
      isModalShown: true,
    });
  }

  hideModal() {
    this.setState({
      isModalShown: false,
    });
  }
}

JobsParametersResultsPanel.propTypes = {
  job: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default JobsParametersResultsPanel;
