export default {
  ActionTypes: {
    CREDENTIALS_REDSHIFT_SANDBOX_LOAD: 'CREDENTIALS_REDSHIFT_SANDBOX_LOAD',
    CREDENTIALS_REDSHIFT_SANDBOX_LOAD_SUCCESS: 'CREDENTIALS_REDSHIFT_SANDBOX_LOAD_SUCCESS',
    CREDENTIALS_REDSHIFT_SANDBOX_LOAD_ERROR: 'CREDENTIALS_REDSHIFT_SANDBOX_LOAD_ERROR',

    CREDENTIALS_REDSHIFT_SANDBOX_CREATE: 'CREDENTIALS_REDSHIFT_SANDBOX_CREATE',
    CREDENTIALS_REDSHIFT_SANDBOX_CREATE_SUCCESS: 'CREDENTIALS_REDSHIFT_SANDBOX_CREATE_SUCCESS',
    CREDENTIALS_REDSHIFT_SANDBOX_CREATE_ERROR: 'CREDENTIALS_REDSHIFT_SANDBOX_CREATE_ERROR',

    CREDENTIALS_REDSHIFT_SANDBOX_REFRESH: 'CREDENTIALS_REDSHIFT_SANDBOX_REFRESH',
    CREDENTIALS_REDSHIFT_SANDBOX_REFRESH_SUCCESS: 'CREDENTIALS_REDSHIFT_SANDBOX_REFRESH_SUCCESS',
    CREDENTIALS_REDSHIFT_SANDBOX_REFRESH_ERROR: 'CREDENTIALS_REDSHIFT_SANDBOX_REFRESH_ERROR',

    CREDENTIALS_REDSHIFT_SANDBOX_DROP: 'CREDENTIALS_REDSHIFT_SANDBOX_DROP',
    CREDENTIALS_REDSHIFT_SANDBOX_DROP_SUCCESS: 'CREDENTIALS_REDSHIFT_SANDBOX_DROP_SUCCESS',
    CREDENTIALS_REDSHIFT_SANDBOX_DROP_ERROR: 'CREDENTIALS_REDSHIFT_SANDBOX_DROP_ERROR',

    CREDENTIALS_WRDB_LOAD: 'CREDENTIALS_WRDB_LOAD',
    CREDENTIALS_WRDB_LOAD_SUCCESS: 'CREDENTIALS_WRDB_LOAD_SUCCESS',
    CREDENTIALS_WRDB_LOAD_ERROR: 'CREDENTIALS_WRDB_LOAD_ERROR',

    CREDENTIALS_WRDB_DROP: 'CREDENTIALS_WRDB_DROP',
    CREDENTIALS_WRDB_DROP_SUCCESS: 'CREDENTIALS_WRDB_DROP_SUCCESS',
    CREDENTIALS_WRDB_DROP_ERROR: 'CREDENTIALS_WRDB_DROP_ERROR',

    CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD: 'CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD',
    CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD_SUCCESS: 'CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD_SUCCESS',
    CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD_ERROR: 'CREDENTIALS_SNOWFLAKE_SANDBOX_LOAD_ERROR',

    CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE: 'CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE',
    CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE_SUCCESS: 'CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE_SUCCESS',
    CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE_ERROR: 'CREDENTIALS_SNOWFLAKE_SANDBOX_CREATE_ERROR',

    CREDENTIALS_SNOWFLAKE_SANDBOX_DROP: 'CREDENTIALS_SNOWFLAKE_SANDBOX_DROP',
    CREDENTIALS_SNOWFLAKE_SANDBOX_DROP_SUCCESS: 'CREDENTIALS_SNOWFLAKE_SANDBOX_DROP_SUCCESS',
    CREDENTIALS_SNOWFLAKE_SANDBOX_DROP_ERROR: 'CREDENTIALS_SNOWFLAKE_SANDBOX_DROP_ERROR',

    CREDENTIALS_RSTUDIO_SANDBOX_LOAD: 'CREDENTIALS_RSTUDIO_SANDBOX_LOAD',
    CREDENTIALS_RSTUDIO_SANDBOX_LOAD_SUCCESS: 'CREDENTIALS_RSTUDIO_SANDBOX_LOAD_SUCCESS',
    CREDENTIALS_RSTUDIO_SANDBOX_LOAD_ERROR: 'CREDENTIALS_RSTUDIO_SANDBOX_LOAD_ERROR',

    CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB: 'CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB',
    CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB_SUCCESS:
      'CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB_SUCCESS',
    CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB_ERROR: 'CREDENTIALS_RSTUDIO_SANDBOX_CREATE_JOB_ERROR',

    CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB: 'CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB',
    CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB_SUCCESS: 'CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB_SUCCESS',
    CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB_ERROR: 'CREDENTIALS_RSTUDIO_SANDBOX_DROP_JOB_ERROR',

    CREDENTIALS_RSTUDIO_SANDBOX_EXTEND: 'CREDENTIALS_RSTUDIO_SANDBOX_EXTEND',
    CREDENTIALS_RSTUDIO_SANDBOX_EXTEND_SUCCESS: 'CREDENTIALS_RSTUDIO_SANDBOX_EXTEND_SUCCESS',
    CREDENTIALS_RSTUDIO_SANDBOX_EXTEND_ERROR: 'CREDENTIALS_RSTUDIO_SANDBOX_EXTEND_ERROR',

    CREDENTIALS_JUPYTER_SANDBOX_LOAD: 'CREDENTIALS_JUPYTER_SANDBOX_LOAD',
    CREDENTIALS_JUPYTER_SANDBOX_LOAD_SUCCESS: 'CREDENTIALS_JUPYTER_SANDBOX_LOAD_SUCCESS',
    CREDENTIALS_JUPYTER_SANDBOX_LOAD_ERROR: 'CREDENTIALS_JUPYTER_SANDBOX_LOAD_ERROR',

    CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB: 'CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB',
    CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_SUCCESS:
      'CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_SUCCESS',
    CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_ERROR: 'CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_ERROR',

    CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB: 'CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB',
    CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_SUCCESS: 'CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_SUCCESS',
    CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_ERROR: 'CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_ERROR',

    CREDENTIALS_JUPYTER_SANDBOX_EXTEND: 'CREDENTIALS_JUPYTER_SANDBOX_EXTEND',
    CREDENTIALS_JUPYTER_SANDBOX_EXTEND_SUCCESS: 'CREDENTIALS_JUPYTER_SANDBOX_EXTEND_SUCCESS',
    CREDENTIALS_JUPYTER_SANDBOX_EXTEND_ERROR: 'CREDENTIALS_JUPYTER_SANDBOX_EXTEND_ERROR',

    CREDENTIALS_JULIPYTER_SANDBOX_LOAD: 'CREDENTIALS_JULIPYTER_SANDBOX_LOAD',
    CREDENTIALS_JULIPYTER_SANDBOX_LOAD_SUCCESS: 'CREDENTIALS_JULIPYTER_SANDBOX_LOAD_SUCCESS',
    CREDENTIALS_JULIPYTER_SANDBOX_LOAD_ERROR: 'CREDENTIALS_JULIPYTER_SANDBOX_LOAD_ERROR',

    CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB: 'CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB',
    CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB_SUCCESS:
      'CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB_SUCCESS',
    CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB_ERROR:
      'CREDENTIALS_JULIPYTER_SANDBOX_CREATE_JOB_ERROR',

    CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB: 'CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB',
    CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB_SUCCESS:
      'CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB_SUCCESS',
    CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB_ERROR: 'CREDENTIALS_JULIPYTER_SANDBOX_DROP_JOB_ERROR',

    CREDENTIALS_JULIPYTER_SANDBOX_EXTEND: 'CREDENTIALS_JULIPYTER_SANDBOX_EXTEND',
    CREDENTIALS_JULIPYTER_SANDBOX_EXTEND_SUCCESS: 'CREDENTIALS_JULIPYTER_SANDBOX_EXTEND_SUCCESS',
    CREDENTIALS_JULIPYTER_SANDBOX_EXTEND_ERROR: 'CREDENTIALS_JULIPYTER_SANDBOX_EXTEND_ERROR',
  },
};
