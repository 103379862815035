import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import { List, Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentWithIconAndType from '@/react/common/ComponentWithIconAndType';
import Loader from '@/react/common/Loader';
import User from '@/react/common/User';

const SEARCHED_TYPES = [
  componentTypes.TRANSFORMATION,
  componentTypes.WRITER,
  componentTypes.APPLICATION,
];

const usedInMapping = (config, tableId) => {
  return usedInInputMapping(config, tableId) || usedInOutputMapping(config, tableId);
};

const usedInInputMapping = (config, tableId) => {
  return config
    .getIn(['configuration', 'storage', 'input', 'tables'], List())
    .some((mapping) => mapping.get('source') === tableId);
};

const usedInOutputMapping = (config, tableId) => {
  return config
    .getIn(['configuration', 'storage', 'output', 'tables'], List())
    .some((mapping) => mapping.get('destination') === tableId);
};

class TableUsage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    InstalledComponentsActionCreators.loadInstalledComponentsForce({
      include: 'configuration,rows',
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div className="box">
        <Table hover>
          <thead>
            <tr>
              <th className="w-400">
                Component{' '}
                <Tooltip placement="top" tooltip={this.getTooltip()} type="explanatory">
                  <FontAwesomeIcon icon="circle-info" className="icon-addon-left text-muted" />
                </Tooltip>
              </th>
              <th>Configuration</th>
              <th className="w-200">Used in</th>
              <th className="w-50">Version</th>
              <th className="w-200">Created</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? (
              <tr className="no-hover">
                <td colSpan={5}>
                  <Loader /> Loading data...
                </td>
              </tr>
            ) : (
              this.renderData()
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  renderData() {
    const components = this.prepareComponent();

    if (components.isEmpty()) {
      return (
        <tr className="no-hover">
          <td colSpan={5}>
            The table is not used in any {this.getComponentType(componentTypes.TRANSFORMATION)},{' '}
            {this.getComponentType(componentTypes.APPLICATION)} or{' '}
            {this.getComponentType(componentTypes.WRITER)}.
          </td>
        </tr>
      );
    }

    return components
      .sortBy((component) => component.get('name').toLowerCase())
      .map((component, componentId) => {
        return component
          .get('configurations')
          .sortBy((config) => config.get('name').toLowerCase())
          .map((config, configId) => {
            if (config.get('rows', List()).isEmpty()) {
              return (
                <tr key={`${componentId}-${configId}`}>
                  <td>
                    <ComponentWithIconAndType component={component} />
                  </td>
                  <td>
                    <ComponentConfigurationLink
                      componentId={componentId}
                      configId={configId}
                      hasFlows={this.props.hasFlows}
                    >
                      {config.get('name')}
                    </ComponentConfigurationLink>
                  </td>
                  <td>{this.renderUsedIn(config)}</td>
                  <td>{this.renderVersion(config)}</td>
                  <td>{this.renderUser(config)}</td>
                </tr>
              );
            }

            return config
              .get('rows', List())
              .map((row) => {
                return (
                  <tr key={`${componentId}-${configId}-${row.get('id')}`}>
                    <td>
                      <ComponentWithIconAndType component={component} />
                    </td>
                    <td>
                      <ComponentConfigurationLink
                        componentId={componentId}
                        configId={configId}
                        rowId={row.get('id')}
                      >
                        {config.get('name')} - {row.get('name')}
                      </ComponentConfigurationLink>
                    </td>
                    <td>{this.renderUsedIn(row)}</td>
                    <td>{this.renderVersion(row)}</td>
                    <td>{this.renderUser(row)}</td>
                  </tr>
                );
              })
              .toArray();
          })
          .toArray();
      })
      .toArray();
  }

  renderUsedIn(config) {
    const inInputMapping = usedInInputMapping(config, this.props.tableId);
    const inOutputMapping = usedInOutputMapping(config, this.props.tableId);

    if (inInputMapping && inOutputMapping) {
      return 'Input and Output mapping';
    }

    return inInputMapping ? 'Input mapping' : 'Output mapping';
  }

  renderVersion(config) {
    return '#' + config.get('version');
  }

  renderUser(config) {
    const admin = this.props.admins.get(config.getIn(['creatorToken', 'description']));

    if (!admin) {
      return config.getIn(['creatorToken', 'description']);
    }

    return <User user={admin} />;
  }

  getTooltip() {
    return `We check only ${this.getComponentType(componentTypes.TRANSFORMATION)}s${
      this.props.hasShowTransformationMigration ? ' (non Legacy)' : ''
    }, ${this.getComponentType(componentTypes.APPLICATION)}s and ${this.getComponentType(
      componentTypes.WRITER,
    )}s.`;
  }

  getComponentType(type) {
    return getNewComponentTypeLabel(type);
  }

  prepareComponent() {
    return this.props.configurations
      .filter((component) => SEARCHED_TYPES.includes(component.get('type')))
      .map((component) => {
        return component.update('configurations', Map(), (configurations) => {
          return configurations
            .map((config) => {
              return config.update('rows', List(), (rows) => {
                return rows.filter((row) => usedInMapping(row, this.props.tableId));
              });
            })
            .filter((config) => {
              return (
                usedInMapping(config, this.props.tableId) || !config.get('rows', List()).isEmpty()
              );
            });
        });
      })
      .filter((component) => !component.get('configurations', Map()).isEmpty());
  }
}

TableUsage.propTypes = {
  tableId: PropTypes.string.isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  hasShowTransformationMigration: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
};

export default TableUsage;
