let _baseUrl = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, storageApiToken: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': storageApiToken };
  _fetch = fetch;
}
export type Author = { name: string; url: string };
export type ChangeInfo = { date: string; tokenId: string };
export type Config = { componentId: string; configId: string; name: string };
export type GenericError = { error: string; message: string; statusCode: number };
export type InputOption = { label: string; value: string };
export type Input = {
  componentId?: string | undefined;
  default: string | number | number | boolean | string[] | any;
  description: string;
  id: string;
  kind:
    | 'input'
    | 'hidden'
    | 'textarea'
    | 'confirm'
    | 'select'
    | 'multiselect'
    | 'oauth'
    | 'oauthAccounts';
  name: string;
  oauthInputId?: string | undefined;
  options?: { label: string; value: string }[] | undefined;
  type: 'string' | 'int' | 'double' | 'bool' | 'string[]' | 'object';
};
export type InputValidationResult = { error?: string | undefined; id: string; visible: boolean };
export type InputValue = { id: string; value: any };
export type StepValidationResult = {
  configured: boolean;
  id: string;
  inputs: InputValidationResult[];
  valid: boolean;
};
export type StepGroupValidationResult = {
  error?: string | undefined;
  id: string;
  steps: { configured: boolean; id: string; inputs: InputValidationResult[]; valid: boolean }[];
  valid: boolean;
};
export type ValidationResult = {
  stepGroups: {
    error?: string | undefined;
    id: string;
    steps: { configured: boolean; id: string; inputs: InputValidationResult[]; valid: boolean }[];
    valid: boolean;
  }[];
  valid: boolean;
};
export type Step = {
  description: string;
  dialogDescription: string;
  dialogName: string;
  icon: string;
  id: string;
  inputs: Input[];
  name: string;
};
export type StepGroup = {
  description: string;
  id: string;
  required: 'all' | 'atLeastOne' | 'exactlyOne' | 'zeroOrOne' | 'optional';
  steps: {
    description: string;
    dialogDescription: string;
    dialogName: string;
    icon: string;
    id: string;
    inputs: Input[];
    name: string;
  }[];
};
export type Inputs = {
  initialState: {
    stepGroups: {
      error?: string | undefined;
      id: string;
      steps: { configured: boolean; id: string; inputs: InputValidationResult[]; valid: boolean }[];
      valid: boolean;
    }[];
    valid: boolean;
  };
  stepGroups: {
    description: string;
    id: string;
    required: 'all' | 'atLeastOne' | 'exactlyOne' | 'zeroOrOne' | 'optional';
    steps: {
      description: string;
      dialogDescription: string;
      dialogName: string;
      icon: string;
      id: string;
      inputs: Input[];
      name: string;
    }[];
  }[];
};
export type MainConfig = { componentId: string; configId: string };
export type Instance = {
  branch: string;
  configurations: Config[];
  created: ChangeInfo;
  instanceId: string;
  mainConfig?: { componentId: string; configId: string } | undefined;
  name: string;
  repositoryName: string;
  templateId: string;
  updated: ChangeInfo;
  version: string;
};
export type TemplateBase = {
  author: Author;
  defaultVersion: string;
  deprecated: boolean;
  description: string;
  id: string;
  name: string;
};
export type VersionDetail = {
  components: string[];
  description: string;
  longDescription: string;
  readme: string;
  stable: boolean;
  version: string;
};
export type InstanceDetail = {
  branch: string;
  configurations: Config[];
  created: ChangeInfo;
  instanceId: string;
  mainConfig?: MainConfig | undefined;
  name: string;
  repositoryName: string;
  templateDetail: {
    author: Author;
    defaultVersion: string;
    deprecated: boolean;
    description: string;
    id: string;
    name: string;
  };
  templateId: string;
  updated: ChangeInfo;
  version: string;
  versionDetail: {
    components: string[];
    description: string;
    longDescription: string;
    readme: string;
    stable: boolean;
    version: string;
  };
};
export type Instances = { instances: Instance[] };
export type Repository = { author: Author; name: string; ref: string; url: string };
export type Repositories = {
  repositories: { author: Author; name: string; ref: string; url: string }[];
};
export type ServiceDetail = { api: string; documentation: string };
export type StepPayload = { id: string; inputs: InputValue[] };
export type TaskOutputs = { instanceId?: string | undefined };
export type Task = {
  createdAt: string;
  duration?: number | undefined;
  error?: string | undefined;
  finishedAt?: string | undefined;
  id: string;
  isFinished: boolean;
  outputs?: { instanceId?: string | undefined } | undefined;
  result?: string | undefined;
  status: 'processing' | 'success' | 'error';
  type: string;
  url: string;
};
export type Version = { description: string; stable: boolean; version: string };
export type Template = {
  author: Author;
  categories: string[];
  components: string[];
  defaultVersion: string;
  deprecated: boolean;
  description: string;
  id: string;
  name: string;
  versions: { description: string; stable: boolean; version: string }[];
};
export type TemplateDetail = {
  author: Author;
  categories: string[];
  components: string[];
  defaultVersion: string;
  deprecated: boolean;
  description: string;
  id: string;
  name: string;
  repository: Repository;
  versions: Version[];
};
export type Templates = { repository: Repository; templates: Template[] };
export type UpdateInstanceRequestBody = { name: string };
export type UseTemplateVersionRequestBody = { branch: string; name: string; steps: StepPayload[] };
export type ValidateInputsRequestBody = { steps: StepPayload[] };
export type ValidationError = {
  ValidationResult: ValidationResult;
  error: string;
  message: string;
};
export type VersionDetailExtended = {
  components: string[];
  description: string;
  longDescription: string;
  readme: string;
  repository: Repository;
  stable: boolean;
  template: Template;
  version: string;
};
/**
 * Redirect to /v1.
 */
export function ApiRootIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * List API name and link to documentation.
 */
export function ApiVersionIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function OpenapiJson(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.json';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function OpenapiYaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.yaml';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function Openapi3Json(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.json';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function Openapi3Yaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.yaml';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function InstancesIndex(params: { branch: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances'.replace('{branch}', params['branch']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function InstanceIndex(params: { branch: string; instanceId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function UpdateInstance(
  params: { branch: string; instanceId: string },
  body: UpdateInstanceRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId']);
  return _fetch(url.toString(), {
    method: 'put',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
export function DeleteInstance(params: { branch: string; instanceId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
export function UpgradeInstance(
  params: { branch: string; instanceId: string; version: string },
  body: ValidateInputsRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}/upgrade/{version}'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
export function UpgradeInstanceInputsIndex(params: {
  branch: string;
  instanceId: string;
  version: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}/upgrade/{version}/inputs'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function UpgradeInstanceValidateInputs(
  params: { branch: string; instanceId: string; version: string },
  body: ValidateInputsRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/project/{branch}/instances/{instanceId}/upgrade/{version}/inputs'
    .replace('{branch}', params['branch'])
    .replace('{instanceId}', params['instanceId'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * List all template repositories defined in the project.
 */
export function RepositoriesIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get details of specified repository. Use "keboola" for default Keboola repository.
 */
export function RepositoryIndex(params: { repository: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}'.replace('{repository}', params['repository']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * List all templates  defined in the repository.
 */
export function TemplatesIndex(params: { repository: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates'.replace(
    '{repository}',
    params['repository'],
  );
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get detail and versions of specified template.
 */
export function TemplateIndex(params: { repository: string; template: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates/{template}'
    .replace('{repository}', params['repository'])
    .replace('{template}', params['template']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get details of specified template version.
 */
export function VersionIndex(params: {
  repository: string;
  template: string;
  version: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates/{template}/{version}'
    .replace('{repository}', params['repository'])
    .replace('{template}', params['template'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get inputs for the "use" API call.
 */
export function InputsIndex(params: {
  repository: string;
  template: string;
  version: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates/{template}/{version}/inputs'
    .replace('{repository}', params['repository'])
    .replace('{template}', params['template'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Validate inputs and use template in the branch.
 * Only configured steps should be send.
 */
export function UseTemplateVersion(
  params: { repository: string; template: string; version: string },
  body: UseTemplateVersionRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates/{template}/{version}/use'
    .replace('{repository}', params['repository'])
    .replace('{template}', params['template'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Validate inputs for the "use" API call.
 * Only configured steps should be send.
 */
export function ValidateInputs(
  params: { repository: string; template: string; version: string },
  body: ValidateInputsRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/repositories/{repository}/templates/{template}/{version}/validate'
    .replace('{repository}', params['repository'])
    .replace('{template}', params['template'])
    .replace('{version}', params['version']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get details of a task.
 */
export function GetTask(params: { taskId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/tasks/{taskId}'.replace('{taskId}', params['taskId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
