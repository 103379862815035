import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import FilterPanel from '@/react/common/FilterPanel';
import NoResultsFound from '@/react/common/NoResultsFound';
import matchByWords from '@/utils/matchByWords';
import ConfigurationRowsTable from './ConfigurationRowsTable';
import CreateConfigurationRowButton from './CreateConfigurationRowButton';

const ConfigurationRows = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    rows: PropTypes.instanceOf(List).isRequired,
    config: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    rowDelete: PropTypes.func.isRequired,
    rowEnableDisable: PropTypes.func.isRequired,
    rowDeletePending: PropTypes.func.isRequired,
    rowEnableDisablePending: PropTypes.func.isRequired,
    rowLinkTo: PropTypes.string.isRequired,
    onOrder: PropTypes.func.isRequired,
    orderPending: PropTypes.object.isRequired,
    rowCreateEmptyConfig: PropTypes.func,
    filter: PropTypes.func,
    onRowCreated: PropTypes.func,
    columns: PropTypes.object,
  },

  getDefaultProps() {
    return {
      filter: (row, query) => {
        return matchByWords([row.get('name'), row.get('description')], query);
      },
      columns: fromJS([]),
    };
  },

  getInitialState() {
    return {
      query: '',
    };
  },

  render() {
    return (
      <div className="box-separator">
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-text-base">Rows</h2>
          {this.renderNewConfigRowButton()}
        </div>
        <FilterPanel query={this.state.query} onChange={this.onChangeSearch} />
        {this.renderTable()}
      </div>
    );
  },

  renderTable() {
    const rows = this.filteredRows();

    if (!rows.count()) {
      return <NoResultsFound entityName="tables" />;
    }

    return (
      <div className="box">
        <ConfigurationRowsTable
          isRowConfiguration
          readOnly={this.props.readOnly}
          columns={this.props.columns}
          component={this.props.component}
          config={this.props.config}
          rows={rows}
          rowLinkTo={this.props.rowLinkTo}
          rowDeletePending={this.props.rowDeletePending}
          rowDelete={this.props.rowDelete}
          rowEnableDisablePending={this.props.rowEnableDisablePending}
          rowEnableDisable={this.props.rowEnableDisable}
          disabledMove={this.state.query !== ''}
          onOrder={this.props.onOrder}
          orderPending={this.props.orderPending}
        />
      </div>
    );
  },

  renderNewConfigRowButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <CreateConfigurationRowButton
        component={this.props.component}
        configId={this.props.config.get('id')}
        emptyConfig={this.props.rowCreateEmptyConfig}
        onRowCreated={this.props.onRowCreated}
        buckets={this.props.buckets}
        tables={this.props.tables}
      />
    );
  },

  filteredRows() {
    if (this.state.query) {
      return this.props.rows.filter((row) => this.props.filter(row, this.state.query));
    }

    return this.props.rows;
  },

  onChangeSearch(query) {
    this.setState({ query });
  },
});

export default ConfigurationRows;
