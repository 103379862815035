import { Map } from 'immutable';

import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { getComponentIconUrl } from '@/utils/componentIconFinder';

const getVisualizationPhases = (phases, allComponents, allInstalledComponents) =>
  phases.map((phase, index) => {
    const tasks = phase.get('tasks');
    return Map({
      id: phase.get('id'),
      name: phase.get('id'),
      key: `phase-${index}-${phase
        .get('tasks')
        .map((task) => task.get('id'))
        .join('+')}`,
      tasks: tasks.map((task) => {
        const componentId = task.get('component');
        const configId = task.getIn(['actionParameters', 'config'], '').toString();
        const component = allComponents.get(componentId);
        const config = allInstalledComponents.getIn(
          [componentId, 'configurations', configId],
          Map(),
        );

        return Map({
          id: task.get('id'),
          name: config.get('name', configId),
          enabled: task.get('active', false),
          continueOnFailure: task.get('continueOnFailure', false),
          type: getNewComponentTypeLabel(component.get('type')),
          iconUrl: getComponentIconUrl(component),
        });
      }),
    });
  });

const hasLegacyOrchestrationInvalidToken = (error) => {
  return error?.message === 'Orchestration has no permission to read configuration table';
};

export { getVisualizationPhases, hasLegacyOrchestrationInvalidToken };
