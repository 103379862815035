import { type DependencyList, useEffect, useState } from 'react';

import RoutePendingStore from '@/stores/RoutePendingStore';
import type { CommonStoreGetters, StoreOptions } from '@/utils/StoreUtils';

const useStores = <Store extends CommonStoreGetters, State>(
  getStateFromStores: () => State,
  dependencies: DependencyList,
  stores: Store[],
  options?: StoreOptions,
): State => {
  const [stateFromStore, setState] = useState(getStateFromStores());

  useEffect(() => {
    const handleStoreChanged = () => {
      if (!RoutePendingStore.isPending() || options?.ignoreRouteChanges) {
        setState(() => getStateFromStores());
      }
    };

    handleStoreChanged();

    stores.forEach((store) => store.addChangeListener(handleStoreChanged));
    return () => {
      stores.forEach((store) => store.removeChangeListener(handleStoreChanged));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // we know stores references do not change

  return stateFromStore;
};

export default useStores;
