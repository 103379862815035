import React from 'react';
import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  label?: React.ReactNode;
  help?: React.ReactNode;
};

const defaultHelp = (
  <span>
    Field delimiter used in the CSV file. The default value is <code>,</code>. Use <code>\t</code>{' '}
    for tabulator.
  </span>
);

const CsvDelimiterInput = ({
  value,
  onChange,
  disabled,
  label = 'Delimiter',
  help = defaultHelp,
}: Props) => {
  return (
    <FormGroup>
      <div className="col-xs-4">
        <ControlLabel>{label}</ControlLabel>
      </div>
      <div className="col-xs-8">
        <FormControl
          type="text"
          value={value.replace('\t', '\\t')}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value.replace('\\t', '\t'))
          }
          disabled={disabled}
        />
        <HelpBlock>{help}</HelpBlock>
      </div>
    </FormGroup>
  );
};

export default CsvDelimiterInput;
