import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '@/constants/componentIds';
import ComponentStore from '@/modules/components/stores/ComponentsStore';
import NocodeDetail from '@/modules/no-code/Detail';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import DbtDetail from '@/modules/transformations-v2/DbtDetail';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import RoutesStore from '@/stores/RoutesStore';
import GenericDetail from './GenericDetail';

const GenericIndex = createReactClass({
  mixins: [createStoreMixin(RoutesStore, ComponentStore)],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteParam('component');

    return {
      componentId,
      component: ComponentStore.getComponent(componentId),
    };
  },

  render() {
    if (DBT_COMPONENTS.includes(this.state.componentId)) {
      return <DbtDetail />;
    }

    if (this.state.componentId === KEBOOLA_NO_CODE_DBT_TRANSFORMATION) {
      return <NocodeDetail />;
    }

    return <GenericDetail />;
  },
});

export default GenericIndex;
