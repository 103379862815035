import Promise from 'bluebird';
import { fromJS, type Map } from 'immutable';

import JSONEditor from './json-editor/json-editor';

const injectDefaultsFromConfigurationSchema = (
  data: Record<string, any>,
  jsonSchema?: Map<string, any>,
): Promise<typeof data> => {
  if (!jsonSchema || jsonSchema.isEmpty()) {
    return Promise.resolve(data);
  }

  let jsoneditor: any;

  try {
    jsoneditor = new JSONEditor(document.createElement('div'), { schema: jsonSchema.toJS() });
  } catch (error) {
    return Promise.resolve(data);
  }

  return jsoneditor.promise.then(() => {
    let value;

    try {
      jsoneditor.editors.root.setValue(null, true);
      value = jsoneditor.getValue();
      jsoneditor.destroy();
    } catch (error) {
      value = {};
    }

    data.configuration = JSON.stringify(
      fromJS({ parameters: value })
        .mergeDeep(fromJS(JSON.parse(data.configuration ?? '{}')))
        .toJS(),
    );

    return Promise.resolve(data);
  });
};

export default injectDefaultsFromConfigurationSchema;
