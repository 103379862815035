import React from 'react';
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpBlock } from 'design';
import type { List } from 'immutable';
import { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import keyCodes from '@/constants/keyCodes';
import { OPERATIONS } from '@/modules/no-code/operationsDefinition';
import { tableName } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InputValidation from '@/react/common/InputValidation';
import MarkedText from '@/react/common/MarkedText';
import Select from '@/react/common/Select';
import SimplifiedDataSample, { type DataSample } from './SimplifiedDataSample';

type OperationDefinition = (typeof OPERATIONS)[keyof typeof OPERATIONS];
type EditingOperation = {
  id: number;
  templateId: keyof typeof OPERATIONS;
  variables: Record<string, InputValue>;
  preview: null | DataSample;
  index: number;
} | null;
type InputType = 'string' | 'select' | 'multiselect' | 'sourceTable';
type InputOptionsType = 'table' | 'column' | 'multiselect';
type CommonDataSamples = { sourceTables: Record<string, DataSample> } & Record<string, DataSample>;
type InputValue = string | List<any>;
type InputDefinition = {
  id: string;
  name: string;
  type: InputType;
  helpText?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  optionsType?: InputOptionsType;
  optionsSourceTableInput?: string;
  allowCustomValues?: boolean;
  shouldHide?: (variables: Record<string, InputValue>) => boolean;
  predefinedValidation?: 'bucketName' | 'tableName' | 'columnName';
  customValidation?: (value: InputValue, previousDataSample: DataSample) => boolean;
};
type Inputs = OperationDefinition['inputs'];

type Props = {
  configData: Map<string, any>;
  allTables: Map<string, any>;
  editingOperation: EditingOperation;
  sourceTable: string;
  dataSamples: CommonDataSamples;
  currentDataSample: DataSample;
  previousDataSample: DataSample;
  fetchDataSample: () => Promise<void>;
  resetEditingOperation: () => void;
  onChangeSourceTable: (sourceTable: string) => void;
  onChangeEditingOperation: (
    editingOperation: EditingOperation,
    options?: { shouldResetHiddenInputs: boolean },
  ) => void;
  onConfirm: () => Promise<any>;
  onHide: () => void;
  isEditingOperationValid: boolean;
  isSaving: boolean;
};

class OperationModal extends React.Component<Props> {
  render() {
    const editingOperationDefinition =
      this.props.editingOperation?.templateId && OPERATIONS[this.props.editingOperation.templateId];
    const isFirstOperation = this.props.editingOperation?.index === 0;
    const prependSourceTableInput =
      isFirstOperation &&
      this.props.configData.getIn(['parameters', 'tables'], Map()).count() > 1 &&
      editingOperationDefinition?.inputs &&
      !Object.values(editingOperationDefinition.inputs).some(
        (input: InputDefinition | Record<string, InputDefinition>) =>
          input.type === 'sourceTable' ||
          Object.values(input).some(
            (nestedInput: InputDefinition) => nestedInput.type === 'sourceTable',
          ),
      );

    return (
      <span
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal
          bsSize="large"
          onHide={this.props.onHide}
          show={!!editingOperationDefinition}
          className="modal-with-side-content extra-large-modal scrollable-modal"
        >
          <Form onSubmit={this.handleSubmit}>
            <div>
              <Modal.Header>
                <Modal.Title>{editingOperationDefinition?.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {prependSourceTableInput && this.renderSourceTableInput()}
                {editingOperationDefinition?.inputs &&
                  this.renderInputs(
                    editingOperationDefinition.inputs,
                    !!prependSourceTableInput,
                    isFirstOperation,
                  )}
              </Modal.Body>
              <Modal.Footer>
                <ButtonToolbar className="multiple-buttons">
                  <Button
                    onClick={this.props.fetchDataSample}
                    disabled={
                      this.props.isSaving ||
                      !!this.props.currentDataSample?.isLoading ||
                      !this.props.isEditingOperationValid
                    }
                  >
                    Preview Results
                  </Button>
                  <ConfirmButtons
                    block
                    saveButtonType="submit"
                    isSaving={this.props.isSaving}
                    isDisabled={!this.props.isEditingOperationValid}
                  />
                </ButtonToolbar>
              </Modal.Footer>
            </div>
            <div>
              <Button onClick={this.props.onHide} bsStyle="link" className="close">
                ×
              </Button>
              <SimplifiedDataSample
                tableData={this.props.currentDataSample}
                isSaving={this.props.isSaving}
                handleReset={this.props.resetEditingOperation}
              />
            </div>
          </Form>
        </Modal>
      </span>
    );
  }

  renderInputs = (inputs: Inputs, prependSourceTableInput: boolean, isFirstOperation: boolean) => {
    return Object.entries(inputs).map(([key, input], index) => {
      if (!input.id) {
        return (
          <React.Fragment key={key}>
            <span className="font-medium">{key}</span>
            <div className="inputs-group">
              {this.renderInputs(input, prependSourceTableInput, isFirstOperation)}
            </div>
          </React.Fragment>
        );
      }

      return this.renderInputWrapper(
        input,
        index === 0 && !prependSourceTableInput,
        isFirstOperation,
      );
    });
  };

  renderInputWrapper = (
    input: InputDefinition,
    isFirstInput: boolean,
    isFirstOperation: boolean,
  ) => {
    if (!this.props.editingOperation || input.shouldHide?.(this.props.editingOperation.variables))
      return null;
    if (input.type === 'sourceTable') return this.renderSourceTableInput(!isFirstOperation);

    return (
      <InputValidation
        key={input.id}
        value={this.props.editingOperation.variables[input.id]}
        predefined={input.predefinedValidation}
        {...(input.customValidation && {
          customValidation: (value: InputValue) =>
            input.customValidation?.(value, this.props.previousDataSample),
        })}
      >
        {(inputState: null | 'error', message: null | 'string') => (
          <FormGroup validationState={inputState}>
            <ControlLabel>{input.name}</ControlLabel>
            {this.renderInput(input, isFirstInput)}
            {!!message && <HelpBlock>{message}</HelpBlock>}
            {input.helpText && inputState !== 'error' && <HelpBlock>{input.helpText}</HelpBlock>}
          </FormGroup>
        )}
      </InputValidation>
    );
  };

  renderInput = (input: InputDefinition, isFirst: boolean) => {
    if (!this.props.editingOperation) return null;

    const value = this.props.editingOperation.variables[input.id] || '';

    if (['select', 'multiselect'].includes(input.type)) {
      const { options, isLoading } = this.getInputOptions(input) || {};

      return (
        <Select
          autoFocus={isFirst}
          multi={input.type === 'multiselect'}
          allowCreate={!!input.allowCustomValues}
          value={value}
          onChange={(newValue: string | List<any>) => {
            if (!this.props.editingOperation) return;

            this.props.onChangeEditingOperation(
              {
                ...this.props.editingOperation,
                variables: {
                  ...this.props.editingOperation.variables,
                  [input.id]: newValue,
                },
              },
              { shouldResetHiddenInputs: input.id === 'operator' },
            );
          }}
          options={options}
          isLoading={isLoading}
          placeholder={
            input.placeholder ||
            (input.optionsType ? `Select ${capitalize(input.optionsType)}...` : '')
          }
        />
      );
    }

    return (
      <FormControl
        autoFocus={isFirst}
        type="text"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!this.props.editingOperation) return;

          this.props.onChangeEditingOperation({
            ...this.props.editingOperation,
            variables: {
              ...this.props.editingOperation.variables,
              [input.id]: event.target.value,
            },
          });
        }}
        placeholder={input.placeholder}
      />
    );
  };

  renderSourceTableInput = (isDisabled?: boolean) => (
    <FormGroup key="sourceTable">
      <ControlLabel>Select Table</ControlLabel>
      {isDisabled ? (
        <FormControl type="text" value="From previous result" className="text-muted" disabled />
      ) : (
        <Select
          autoFocus
          value={this.props.sourceTable || ''}
          onChange={this.props.onChangeSourceTable}
          options={this.getInputOptions({ optionsType: 'table' })?.options}
        />
      )}
    </FormGroup>
  );

  getInputOptions = (input: { optionsType: string } | InputDefinition) => {
    if (!this.props.editingOperation) return;
    if ('options' in input) return { options: input.options, isLoading: false };

    switch (input.optionsType) {
      case 'table':
        return {
          options: this.props.configData
            .getIn(['parameters', 'tables'], Map())
            .map((tableId: string) => {
              const table = this.props.allTables.get(tableId, Map());

              return {
                label: (inputString: string) => (
                  <>
                    <FontAwesomeIcon icon="table" className="icon-addon-right" />
                    <MarkedText source={tableName(table)} mark={inputString} />
                  </>
                ),
                value: tableId,
              };
            })
            .toArray(),
          isLoading: false,
        };
      case 'column': {
        const dataSample =
          ('optionsSourceTableInput' in input && input.optionsSourceTableInput
            ? this.props.dataSamples?.sourceTables?.[
                this.props.editingOperation.variables[input.optionsSourceTableInput] as string
              ]
            : this.props.previousDataSample) || {};

        return {
          options: dataSample?.dataSample?.columns?.sort().map((columnName) => ({
            label: columnName,
            value: columnName,
          })),
          isLoading: dataSample.isLoading,
        };
      }
    }
  };

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    return this.props.onConfirm().then(this.props.onHide);
  };
}

export default OperationModal;
