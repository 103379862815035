import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { ObjectTypes } from '@/modules/components/MetadataConstants';
import DescriptionButton from '@/modules/storage/components/DescriptionButton';
import { tableModalTabs } from '@/modules/storage/constants';
import { getLoadingActionName } from '@/modules/storage/helpers';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import TableActionLoader from '@/react/common/TableActionLoader';

type Props = {
  table: Map<string, any>;
  isDeleting: boolean;
  isTruncating: boolean;
  isExporting: boolean;
  isCreatingSnapshot: boolean;
  canExportTable: boolean;
  canWriteBucket: boolean;
  onSetState: (
    selectedTable: Map<string, any>,
    activeActionModal: (typeof tableModalTabs)[keyof typeof tableModalTabs],
  ) => void;
};

const TableActions = ({
  table,
  isDeleting,
  isTruncating,
  isExporting,
  isCreatingSnapshot,
  canExportTable,
  canWriteBucket,
  onSetState,
}: Props) => {
  const isLoading = isCreatingSnapshot || isDeleting || isExporting || isTruncating;
  return isLoading ? (
    <TableActionLoader
      loadingActionName={getLoadingActionName({
        isCreatingSnapshot,
        isDeleting,
        isExporting,
        isTruncating,
      })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.EXPORT)}>
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export table
        </RowActionMenuItem>
      )}
      <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.SNAPSHOT)}>
        <FontAwesomeIcon fixedWidth icon="camera-viewfinder" />
        Create snapshot
      </RowActionMenuItem>
      <DescriptionButton data={table} entity={ObjectTypes.TABLE} readOnly={!canWriteBucket} />
      {canWriteBucket && (
        <>
          <RowActionMenuItem divider />
          {!table.get('isAlias') && (
            <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.TRUNCATE)}>
              <FontAwesomeIcon icon="xmark" fixedWidth />
              Truncate table
            </RowActionMenuItem>
          )}
          <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.DELETE)}>
            <FontAwesomeIcon icon="trash" fixedWidth />
            Delete table
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};

export default TableActions;
