import { List, Map } from 'immutable';

import * as Constants from '@/constants/KbcConstants';
import dayjs from '@/date';
import Dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore('NotificationsStore', Map({ notifications: List() }));

const hasNotificationWithId = (id) => {
  if (!id) {
    return false;
  }
  const found = _store.get('notifications').find((notification) => notification.get('id') === id);
  return !!found;
};

const NotificationsStore = StoreUtils.createStore({
  getNotifications() {
    return _store.get('notifications');
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case Constants.ActionTypes.APPLICATION_SET_PAUSE_NOTIFICATION: {
      _store = _store.update('notifications', (notifications) => {
        return notifications.map((notification) => {
          if (!action.paused || !notification.get('timeout')) {
            return notification.set('paused', false).set('timestamp', dayjs());
          }

          return notification
            .update('timeout', (timeout) => timeout - dayjs().diff(notification.get('timestamp')))
            .set('paused', true);
        });
      });
      return NotificationsStore.emitChange();
    }

    case Constants.ActionTypes.APPLICATION_SEND_NOTIFICATION:
      // avoid duplication of same message
      if (hasNotificationWithId(action.notification.id)) {
        return;
      }

      _store = _store.update('notifications', (notifications) =>
        // add some extra ms to compensate initial fade animation
        notifications.push(action.notification.set('timestamp', dayjs().add(300, 'ms'))),
      );
      return NotificationsStore.emitChange();

    case Constants.ActionTypes.APPLICATION_DELETE_NOTIFICATION: {
      const index = _store
        .get('notifications')
        .findIndex((notification) => notification.get('id') === action.notificationId);

      if (index === -1) {
        return;
      }

      _store = _store.deleteIn(['notifications', index]);
      return NotificationsStore.emitChange();
    }

    default:
      break;
  }
});

export default NotificationsStore;
