import React from 'react';
import type { Map } from 'immutable';

import ExternalBucketLabel from '@/modules/storage/components/ExternalBucketLabel';
import BucketSharedLabel from './BucketSharedLabel';
import BucketStageLabel from './BucketStageLabel';

const BucketLabels = ({
  bucket,
  withStageLabel = true,
  withExternalLabel = true,
  withSharedLabel = true,
}: {
  bucket: Map<string, any>;
  withStageLabel?: boolean;
  withExternalLabel?: boolean;
  withSharedLabel?: boolean;
}) => {
  if (!bucket || bucket.isEmpty()) {
    return null;
  }

  return (
    <div className="tw-inline-flex tw-gap-2">
      {withStageLabel && <BucketStageLabel stage={bucket.get('stage')} round />}
      {withExternalLabel && (
        <ExternalBucketLabel hasExternalSchema={bucket.get('hasExternalSchema', false)} />
      )}
      {withSharedLabel && <BucketSharedLabel isSharing={!!bucket.get('sharing')} />}
    </div>
  );
};

export default BucketLabels;
