import { fromJS, Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import * as constants from './DockerActionsConstants';

let _store = initStore('DockerActionsStore', Map({ actions: Map() }));

function getCacheId(action, configuration, row) {
  const body = action.get('getPayload')(configuration, row);

  if (!body) {
    return null;
  }

  return fromJS(body.toJS()).hashCode();
}

const DockerActionsStore = StoreUtils.createStore({
  has(componentId, action, configuration, row) {
    const cacheId = getCacheId(action, configuration, row);

    return _store.hasIn(['actions', componentId, action.get('name'), cacheId]);
  },

  get(componentId, action, configuration, row) {
    const cacheId = getCacheId(action, configuration, row);

    if (_store.hasIn(['actions', componentId, action.get('name'), cacheId])) {
      return _store.getIn(['actions', componentId, action.get('name'), cacheId]);
    }

    return fromJS({ status: 'none' });
  },
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN:
      _store = _store.setIn(
        ['actions', action.component, action.actionName, action.cacheId],
        fromJS({ status: 'pending' }),
      );
      return DockerActionsStore.emitChange();

    case constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN_SUCCESS:
      _store = _store.setIn(
        ['actions', action.component, action.actionName, action.cacheId],
        fromJS({ status: 'success', data: action.response, request: action.requestBody }),
      );
      return DockerActionsStore.emitChange();

    case constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN_ERROR:
      _store = _store.setIn(
        ['actions', action.component, action.actionName, action.cacheId],
        fromJS({ status: 'error', error: action.error }),
      );
      return DockerActionsStore.emitChange();

    default:
      break;
  }
});

export default DockerActionsStore;
