import type { IconProp } from '@fortawesome/fontawesome-svg-core';

const commonFaIcons: { [key: string]: IconProp } = {
  upload: 'up-from-line',
  download: 'down-to-line',
  settings: 'gear',
  import: 'file-import',
};

export default commonFaIcons;
