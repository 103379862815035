import Promise from 'bluebird';
import { Map } from 'immutable';

import { TDE_EXPORTER as componentId } from '@/constants/componentIds';
import dayjs from '@/date';
import installedComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import storageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import OldJobsActionCreators from '@/modules/jobs/ActionCreators';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import oauth2Actions from '@/modules/oauth-v2/ActionCreators';
import JobsActionCreators from '@/modules/queue/actions';
import JobsStore from '@/modules/queue/store';
import ApplicationStore from '@/stores/ApplicationStore';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import destinationPage from './react/pages/Destination/Destination';
import index from './react/pages/Index/Index';
import tableDetail from './react/pages/Table/Table';
import { OAUTH_V2_WRITERS } from './tdeCommon';

const loadFilesParams = { q: '+tags:tde +tags:table-export' };

// reload files from files uploads if at least one job has finished up to 10 seconds ago
const reloadSapiFilesTrigger = (jobs) => {
  const tresholdTrigger = 20; // seconds of end time from now to reload all files
  for (let job of jobs) {
    if (job.endTime) {
      const endTime = dayjs(job.endTime);
      const now = dayjs();
      const diff = dayjs.duration(now.diff(endTime));
      if (diff < dayjs.duration(tresholdTrigger, 'seconds')) {
        return storageActionCreators.loadFilesForce(loadFilesParams);
      }
    }
  }
};

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: index,
  poll: {
    interval: DEFAULT_INTERVAL,
    action: (params) => {
      if (ApplicationStore.hasNewQueue()) {
        return Promise.all([
          JobsActionCreators.loadComponentConfigurationLatestJobs(componentId, params.config).then(
            () => {
              const jobs = JobsStore.getLatestJobs(componentId, params.config).get('jobs', Map());
              return reloadSapiFilesTrigger(jobs.toJS());
            },
          ),
          VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(componentId, params.config),
        ]);
      }

      return Promise.all([
        OldJobsActionCreators.loadComponentConfigurationLatestJobs(componentId, params.config).then(
          () => {
            const jobs = LatestJobsStore.getJobs(componentId, params.config).get('jobs', Map());
            return reloadSapiFilesTrigger(jobs.toJS());
          },
        ),
        VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(componentId, params.config),
      ]);
    },
  },
  requireData: [
    (params) => configRequiredData(componentId, params.config),
    () => storageActionCreators.loadFiles(loadFilesParams),
  ],
  title(routerState) {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(componentId, configId).get('name');
  },
  childRoutes: [
    {
      name: `${componentId}-table`,
      path: 'table/:tableId',
      defaultRouteHandler: tableDetail,
      title(routerState) {
        return routerState.getIn(['params', 'tableId']);
      },
    },
    {
      name: `${componentId}-destination`,
      path: 'destination',
      defaultRouteHandler: destinationPage,
      requireData: [
        (params) =>
          installedComponentsActions
            .loadComponentConfigData(componentId, params.config)
            .then(() => {
              const configData = InstalledComponentsStore.getConfigData(componentId, params.config);
              const parameters = configData.get('parameters', Map());

              return Promise.props(
                OAUTH_V2_WRITERS.map((cid) => {
                  const credentialsId = parameters.getIn([cid, 'id']);
                  return !!credentialsId && oauth2Actions.loadCredentials(cid, credentialsId);
                }),
              );
            }),
      ],
      title() {
        return 'Set Up Upload';
      },
    },
  ],
};
