import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { allowedTypes, routeNames } from '@/modules/components-directory/constants';
import RouterLink from '@/react/common/RouterLink';
import useStores from '@/react/hooks/useStores';

const ComponentsHeaderButton = () => {
  const { hasConfigurations } = useStores(
    () => {
      return {
        hasConfigurations: !InstalledComponentsStore.getAll()
          .filter((component: Map<string, any>) => allowedTypes.includes(component.get('type')))
          .isEmpty(),
      };
    },
    [],
    [InstalledComponentsStore],
  );

  if (!hasConfigurations) {
    return null;
  }

  return (
    <Tooltip tooltip="Back to configurations" placement="top">
      <RouterLink onlyActiveOnIndex to={routeNames.CONFIGURATIONS} className="btn btn-default">
        <FontAwesomeIcon icon="xmark" />
      </RouterLink>
    </Tooltip>
  );
};

export default ComponentsHeaderButton;
