import React, { useState } from 'react';

import actions from '@/modules/stream/actions';
import { findSource } from '@/modules/stream/helpers';
import store from '@/modules/stream/store';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const NameEdit = () => {
  const { source, readOnly } = useStores(
    () => ({
      source: findSource(store.getStore().sources, RoutesStore.getCurrentRouteParam('sourceId')),
      readOnly: ApplicationStore.isReadOnly(),
    }),
    [],
    [store, ApplicationStore],
  );
  const [currentValue, setCurrentValue] = useState(source?.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  if (!source) return null;
  if (readOnly) return <span>{source.name}</span>;

  return (
    <InlineEditTextInput
      text={currentValue}
      placeholder="Name the data stream..."
      tooltipPlacement="bottom"
      isSaving={isSaving}
      isEditing={isEditing}
      isValid={!!currentValue?.trim()}
      isChanged={currentValue !== source.name}
      onEditStart={() => setIsEditing(true)}
      onEditCancel={() => {
        setIsEditing(false);
        setCurrentValue(source.name);
      }}
      onEditChange={(name: string) => setCurrentValue(name)}
      onEditSubmit={() => {
        setIsSaving(true);

        return actions
          .updateSource(source.sourceId, { name: currentValue?.trim() || '' })
          .finally(() => {
            setIsSaving(false);
            setIsEditing(false);
          });
      }}
    />
  );
};

export default NameEdit;
