import React from 'react';
import classNames from 'classnames';
import { Alert } from 'design';
import type { Map } from 'immutable';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';
import SwitchControl from './SwitchControl';

const Debug = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  const debug = props.editing.get('debug');

  return (
    <CollapsibleBox
      title="Debug Settings"
      collapsePrefix={
        <span
          className={classNames(
            'tw-mr-2 tw-text-xs tw-font-medium',
            debug ? 'tw-text-primary-600' : 'tw-text-warning-600',
          )}
        >
          {debug ? 'Enabled' : 'Disabled'}
        </span>
      }
      titleSuffix={<DocumentationLink path="running/#debug-mode" />}
    >
      <SwitchControl
        label="Debug Mode"
        readOnly={props.readOnly}
        checked={debug}
        onChange={(checked: boolean) => props.setEditing(props.editing.set('debug', checked))}
        help="In debug mode, the extractor displays all API requests it sends, helping you understand what is really happening, why something is skipped, etc."
      />
      {debug && (
        <Alert variant="warning" title="Warning">
          If the API sends sensitive data (e.g., an authorization token) in the URL, these may
          become visible in the events. Also, debug mode considerably slows the extraction.
          Therefore, it should never be turned on in production configurations.
        </Alert>
      )}
    </CollapsibleBox>
  );
};

export default Debug;
