import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import QueryDeleteButton from './QueryDeleteButton';

const QueryActionButtons = function (componentId, actionsProvisioning, storeProvisioning) {
  const actionCreators = actionsProvisioning.createActions(componentId);

  return createReactClass({
    mixins: [createStoreMixin(RoutesStore, ApplicationStore, InstalledComponentsStore)],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const queryId = RoutesStore.getCurrentRouteParam('query');
      const query = ExDbStore.getConfigQuery(queryId);

      return {
        configId,
        queryId,
        query,
        pendingActions: ExDbStore.getQueriesPendingActions().get(queryId, Map()),
        isEditingQuery: ExDbStore.isEditingQuery(queryId),
        isSaving: ExDbStore.isSavingQuery(queryId),
        isChanged: ExDbStore.isChangedQuery(queryId),
        isValid: ExDbStore.isEditingQueryValid(queryId),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    render() {
      if (!this.state.query) {
        return null;
      }

      return (
        <>
          {!this.state.readOnly && (
            <>
              <ActivateDeactivateSwitch
                activateTooltip="Enable Export"
                deactivateTooltip="Disable Export"
                isActive={this.state.query.get('enabled')}
                isPending={this.state.pendingActions.get('enabled')}
                onChange={this.handleActiveChange}
                tooltipPlacement="bottom"
                buttonDisabled={this.state.isEditingQuery}
              />
              <QueryDeleteButton
                componentId={componentId}
                query={this.state.query}
                configurationId={this.state.configId}
                isPending={!!this.state.pendingActions.get('deleteQuery')}
                tooltipPlacement="bottom"
                actionsProvisioning={actionsProvisioning}
              />
              <CatchUnsavedChanges
                isDirty={this.state.isChanged}
                onSave={this.handleSave}
                isSaveDisabled={!this.state.isValid}
                onDirtyLeave={this.handleReset}
              >
                <SaveButtons
                  isSaving={this.state.isSaving}
                  isChanged={this.state.isChanged}
                  onReset={this.handleReset}
                  onSave={this.handleSave}
                  disabled={this.state.isSaving || !this.state.isValid}
                />
              </CatchUnsavedChanges>
            </>
          )}
          <RunComponentButton
            label="Run"
            buttonBsStyle="success"
            tooltipPlacement="bottom"
            componentId={componentId}
            runParams={this.runParams}
            config={this.state.configId}
            disabled={this.state.isEditingQuery}
          >
            You are about to run an extraction.
          </RunComponentButton>
        </>
      );
    },

    runParams() {
      return {
        config: this.state.configId,
        configData: actionCreators.prepareSingleQueryRunData(
          this.state.configId,
          this.state.query,
          'detail',
        ),
      };
    },

    handleActiveChange(newValue) {
      actionCreators.changeQueryEnabledState(
        this.state.configId,
        this.state.query.get('id'),
        newValue,
      );
    },

    handleReset() {
      return actionCreators.resetQueryEdit(this.state.configId, this.state.query.get('id'));
    },

    handleSave() {
      return actionCreators.saveQueryEdit(this.state.configId, this.state.query.get('id'));
    },
  });
};

export default QueryActionButtons;
