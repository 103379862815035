import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { GENERIC_DOCKER_UI_FILE_OUTPUT } from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { features } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import FilterPanel from '@/react/common/FilterPanel';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import { getCommonParameterForAllMapings, setParameterToAllMapings } from './helpers';
import SelectedDataSources from './SelectedDataSources';
import Table from './Table';

const Index = createReactClass({
  mixins: [createStoreMixin(RoutesStore, InstalledComponentsStore, ComponentStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const componentId = RoutesStore.getCurrentRouteParam('component') || KEBOOLA_SANDBOXES;
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);

    return {
      configId,
      componentId,
      localState,
      filesInputMapping: localState.get(
        'file-input-mapping',
        configData.getIn(['storage', 'input', 'files'], List()),
      ),
      configurations: Map(),
      allConfigurations: InstalledComponentsStore.getAll(),
      allComponents: ComponentStore.getAll(),
    };
  },

  getInitialState() {
    return {
      searchQuery: '',
    };
  },

  componentWillUnmount() {
    InstalledComponentsActionCreators.updateLocalState(
      this.state.componentId,
      this.state.configId,
      this.state.localState.delete('file-input-mapping'),
    );
  },

  render() {
    return (
      <div className="tw-grid tw-grid-cols-12 tw-items-start tw-gap-8">
        <div className="tw-col-span-7">
          <FilterPanel
            placeholder="Search configurations or queries"
            query={this.state.searchQuery}
            onChange={(query) => this.setState({ searchQuery: query })}
          />
          {this.renderTable()}
        </div>
        <div className="tw-col-span-5">
          <SelectedDataSources
            filesInputMapping={this.state.filesInputMapping}
            allConfigurations={this.state.allConfigurations}
            allComponents={this.state.allComponents}
            handleDeleteSource={(index) => this.handleDeleteSource(index)}
            handleUpdateFilter={(value) => this.handleUpdateFilter(value)}
          />
        </div>
      </div>
    );
  },

  renderTable() {
    let allFilteredConfigurations = this.getAllAvailableConfigurations();

    if (this.state.searchQuery) {
      const searchQuery = this.state.searchQuery;
      allFilteredConfigurations = allFilteredConfigurations
        .map((component, componenId) => {
          return component.update('configurations', Map(), (configurations) => {
            return configurations
              .map((configData) => {
                return configData.update('rows', List(), (rows) => {
                  return rows.filter((row) => {
                    if (
                      matchByWords(
                        row
                          .getIn(
                            ['configuration', 'storage', 'output', 'table_files', 'tags'],
                            List(),
                          )
                          .toArray(),
                        searchQuery,
                      )
                    ) {
                      return true;
                    }

                    if (
                      row
                        .getIn(['configuration', 'storage', 'output', 'files'], List())
                        .some((mapping) =>
                          matchByWords(mapping.get('tags', List()).toArray(), searchQuery),
                        )
                    ) {
                      return true;
                    }

                    return matchByWords(row.get('name'), searchQuery);
                  });
                });
              })
              .map((configData, configId) => {
                const matchesAnyFileTag = configData
                  .getIn(['configuration', 'storage', 'output', 'files'], List())
                  .some((mapping) =>
                    matchByWords(mapping.get('tags', List()).toArray(), searchQuery),
                  );

                if (
                  configData.get('rows', List()).isEmpty() &&
                  (matchByWords(configData.get('name'), searchQuery) || matchesAnyFileTag)
                ) {
                  return configData
                    .set(
                      'rows',
                      allFilteredConfigurations.getIn(
                        [componenId, 'configurations', configId, 'rows'],
                        List(),
                      ),
                    )
                    .set('matchOnlyName', true);
                }

                return configData;
              })
              .filter(
                (configData) =>
                  configData.get('rows', List()).count() > 0 ||
                  configData.get('matchOnlyName', false),
              );
          });
        })
        .map((component, componenId) => {
          if (
            component.get('configurations', Map()).isEmpty() &&
            matchByWords(component.get('name'), searchQuery)
          ) {
            return component
              .set(
                'configurations',
                allFilteredConfigurations.getIn([componenId, 'configurations'], Map()),
              )
              .set('matchOnlyName', true);
          }

          return component;
        })
        .filter((component) => component.get('configurations', Map()).count() > 0);
    }

    if (this.state.searchQuery && allFilteredConfigurations.isEmpty()) {
      return <p>No configuration match criteria.</p>;
    }

    if (allFilteredConfigurations.isEmpty()) {
      return <p>No configurations created yet.</p>;
    }

    return (
      <Table
        allConfigurations={allFilteredConfigurations}
        allComponents={this.state.allComponents}
        searchQuery={this.state.searchQuery}
        handleAdd={this.handleAddSelected}
      />
    );
  },

  handleAddSelected(mapping) {
    this.updateMapping(
      setParameterToAllMapings(
        this.state.filesInputMapping.concat(mapping).toSet().toList(),
        getCommonParameterForAllMapings(this.state.filesInputMapping),
      ),
    );
  },

  handleDeleteSource(index) {
    this.updateMapping(this.state.filesInputMapping.delete(index));
  },

  handleUpdateFilter(value) {
    this.updateMapping(setParameterToAllMapings(this.state.filesInputMapping, value));
  },

  updateMapping(inputMapping) {
    InstalledComponentsActionCreators.updateLocalState(
      this.state.componentId,
      this.state.configId,
      this.state.localState.set('file-input-mapping', inputMapping),
    );
  },

  getAllAvailableConfigurations() {
    return this.state.allConfigurations
      .filter(
        (component) =>
          component.get('features', List()).includes(features.ALLOW_USE_FILE_STORAGE_ONLY) ||
          component.get('flags', List()).includes(GENERIC_DOCKER_UI_FILE_OUTPUT),
      )
      .map((component) => {
        if (component.get('features', List()).includes(features.ALLOW_USE_FILE_STORAGE_ONLY)) {
          return component.update('configurations', (configurations) => {
            return configurations.filter((configData) =>
              configData.getIn(['configuration', 'runtime', 'use_file_storage_only']),
            );
          });
        }

        return component;
      })
      .filter((component) => !component.get('configurations').isEmpty());
  },
});

export default Index;
