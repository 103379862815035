const UPPER_PANEL_HEIGHT = 100;

const SIDEBAR_WIDTH = 480;

const COLUMNS_CHUNK_SIZE = 7;

const HIGHLIGHT_MUTED_OPACITY = 0.25;

const METADATA_KEYS = {
  DESCRIPTION: 'KBC.description',
  OWNER_METADATA_KEY: 'KBC.owner.token.description',
  VARIABLES: 'KBC.variables',
};

export {
  UPPER_PANEL_HEIGHT,
  SIDEBAR_WIDTH,
  COLUMNS_CHUNK_SIZE,
  HIGHLIGHT_MUTED_OPACITY,
  METADATA_KEYS,
};
