import { fromJS, type Map } from 'immutable';

const KEY = 'timeout';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({
    [KEY]: {
      connectTimeout: parameters.getIn(['api', 'http', 'connectTimeout'], 30),
      requestTimeout: parameters.getIn(['api', 'http', 'requestTimeout'], 300),
    },
  });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters
    .setIn(['api', 'http', 'connectTimeout'], editing.getIn([KEY, 'connectTimeout']))
    .setIn(['api', 'http', 'requestTimeout'], editing.getIn([KEY, 'requestTimeout']));
};

export { KEY, mapToState, mapToApi };
