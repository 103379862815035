import React from 'react';
import type Promise from 'bluebird';

import ConfirmModal from '@/react/common/ConfirmModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import PredefinedInput from './PredefinedInput';

const EditNameWithDevBranchWarningModal = (props: {
  entity: 'tableName' | 'bucketName';
  name: string;
  onChange: (name: string) => void;
  onSubmit: () => Promise<any>;
  onHide: () => void;
  isSaving: boolean;
  error: string | null;
}) => {
  return (
    <ConfirmModal
      show
      closeAfterResolve
      onHide={props.onHide}
      isLoading={props.isSaving}
      icon="pen"
      buttonType="success"
      buttonLabel="Update"
      title={`${props.entity === 'bucketName' ? 'Bucket' : 'Table'} Name`}
      text={
        <>
          <PredefinedInput
            autoFocus
            entity={props.entity}
            value={props.name}
            warning={props.error}
            onChange={props.onChange}
          />
          <DevBranchStorageWarning
            message="The name will also change in production."
            hasProductionEntity
          />
        </>
      }
      onConfirm={props.onSubmit}
    />
  );
};

export default EditNameWithDevBranchWarningModal;
