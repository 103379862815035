import React from 'react';
import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/jobs/ActionCreators';
import JobsStore from '@/modules/jobs/stores/JobsStore';
import BlockButton from '@/react/common/BlockButton';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import JobRow from './JobRow';
import QueryRow from './QueryRow';

const JobsIndex = createReactClass({
  mixins: [createStoreMixin(JobsStore, ApplicationStore)],

  getStateFromStores() {
    return {
      jobs: JobsStore.getAll(),
      isLoadMore: JobsStore.getIsLoadMore(),
      query: JobsStore.getQuery(),
      paginationLimit: JobsStore.getLimit(),
      admins: ApplicationStore.getAdmins(),
      userEmail: ApplicationStore.getCurrentAdmin().get('email'),
    };
  },

  getInitialState() {
    return {
      isLoadingMoreJobs: false,
    };
  },

  render() {
    return (
      <>
        <QueryRow
          onSearch={this.handleSearch}
          query={this.state.query}
          userEmail={this.state.userEmail}
        />
        {this.renderJobsTable()}
      </>
    );
  },

  renderJobsTable() {
    if (!this.state.jobs.count()) {
      return <NoResultsFound entityName="jobs" />;
    }

    return (
      <div className="box">
        <div className="table table-hover">
          {this.renderTableHeader()}
          <div className="tbody">{this.state.jobs.map(this.renderJob).toArray()}</div>
        </div>
        {this.state.isLoadMore && (
          <BlockButton
            onClick={this.handleLoadMore}
            label={`Show ${this.state.paginationLimit} More`}
            isLoading={this.state.isLoadingMoreJobs}
          />
        )}
      </div>
    );
  },

  renderTableHeader() {
    return (
      <div className="thead">
        <div className="tr">
          <span className="th w-250">Component</span>
          <span className="th">Configuration</span>
          <span className="th text-right w-150">Duration</span>
          <span className="th text-right w-250">Created</span>
          <span className="th w-100">Status</span>
        </div>
      </div>
    );
  },

  renderJob(job) {
    return (
      <JobRow
        key={job.get('id')}
        job={job}
        admin={this.state.admins.get(job.getIn(['token', 'description']))}
        userRunnedJob={JobsStore.getUserRunnedParentJob(job)}
        query={this.state.query}
      />
    );
  },

  handleSearch(query) {
    return ActionCreators.filterJobs(query);
  },

  handleLoadMore() {
    this.setState({ isLoadingMoreJobs: true });
    ActionCreators.loadMoreJobs().finally(() => this.setState({ isLoadingMoreJobs: false }));
  },
});

export default JobsIndex;
