export const APAC_EX_GMAIL_ATTACHMENTS = 'apac.ex-gmail-attachments';
export const APIFY_APIFY = 'apify.apify';
export const AZOR_EX_AZOR = 'azor.ex-azor';
export const BIZZTREAT_APP_RFM_SEGMENTATION = 'bizztreat.app-rfm-segmentation';
export const BIZZTREAT_EX_FEDGER = 'bizztreat.ex-fedger';
export const BIZZTREAT_EX_PLANTYST = 'bizztreat.ex-plantyst';
export const BIZZTREAT_EX_SEGMENT = 'bizztreat.ex-segment';
export const BIZZTREAT_EX_TALON_ONE = 'bizztreat.ex-talon-one';
export const BIZZTREAT_WR_TALON_ONE_COUPON = 'bizztreat.wr-talon-one-coupon';
export const BLUESKYDIGITAL_EX_OOYALA = 'blueskydigital.ex-ooyala';
export const BLUESKYDIGITAL_WR_DASHDB = 'blueskydigital.wr-dashdb';
export const BLUESKYDIGITAL_WR_FTP = 'blueskydigital.wr-ftp';
export const BLUESKYDIGITAL_WR_SFTP_WEBDAV = 'blueskydigital.wr-sftp-webdav';
export const BLUESKYDIGITAL_WR_S_3 = 'blueskydigital.wr-s3';
export const CLEVERANALYTICS_APP_CAN_MAPBOX_UTILS = 'cleveranalytics.app-can-mapbox-utils';
export const CLEVERANALYTICS_APP_CLEVERANALYTICS_SPATIALUTILS =
  'cleveranalytics.app-cleveranalytics-spatialutils';
export const CLEVERANALYTICS_CAN_AREAMAPPER = 'cleveranalytics.can-areamapper';
export const CLEVERANALYTICS_WR_CLEVER_ANALYTICS = 'cleveranalytics.wr-clever-analytics';
export const DATABREAKERS_APP_DATABREAKER = 'databreakers.app-databreaker';
export const DATAMIND_APP_RFM_MODEL_TEST = 'datamind.app-rfm-model-test';
export const DCA_ALOOK_ANALYTICS = 'dca-alook-analytics';
export const ESNERDA_EX_ARES = 'esnerda.ex-ares';
export const ESNERDA_EX_BINGADS = 'esnerda.ex-bingads';
export const ESNERDA_EX_MAILKIT = 'esnerda.ex-mailkit';
export const ESNERDA_WR_INTERCOM = 'esnerda.wr-intercom';
export const ESNERDA_WR_MAILKIT = 'esnerda.wr-mailkit';
export const EX_ADFORM_MASTERDATA = 'ex-adform-masterdata';
export const EX_GENERIC_V_2 = 'ex-generic-v2';
export const EX_ITUNES = 'ex-itunes';
export const EX_SILVERPOP = 'ex-silverpop';
export const EX_YOUTUBE_REPORTING = 'ex-youtube-reporting';
export const EX_ZUORA_AQUA = 'ex-zuora-aqua';
export const FISA_APP_GD_CUSTOM_COLORS = 'fisa.app-gd-custom-colors';
export const FISA_EX_TYPEFORM = 'fisa.ex-typeform';
export const FISA_EX_VISION_CRITICAL = 'fisa.ex-vision-critical';
export const GENEEA_NLP_ANALYSIS_V_2 = 'geneea.nlp-analysis-v2';
export const HTNS_EX_SALESFORCE = 'htns.ex-salesforce';
export const HTNS_WR_SALESFORCE = 'htns.wr-salesforce';
export const HTNS_WR_SALESFORCE_ANALYTICS_CLOUD = 'htns.wr-salesforce-analytics-cloud';
export const JAKUB_BARTEL_EX_ONEDRIVE = 'jakub-bartel.ex-onedrive';
export const JAKUB_BARTEL_PROCESSOR_RENAME_FILES = 'jakub-bartel.processor-rename-files';
export const JAKUB_BARTEL_PROCESSOR_XLS_2_CSV = 'jakub-bartel.processor-xls2csv';
export const KDS_EX_CA_RALLY = 'kds.ex-ca-rally';
export const KDS_EX_COLLABIM = 'kds.ex-collabim';
export const KDS_EX_SATISMETER = 'kds.ex-satismeter';
export const KDS_EX_DV360 = 'kds-team.ex-dv360';
export const KDS_EX_AZURE_BLOB_STORAGE_V2 = 'kds-team.ex-azure-blob-storage-v2';
export const KDS_EX_AZURE_DATA_LAKE_GEN2 = 'kds-team.ex-azure-data-lake-gen2';
export const KDS_TEAM_EX_PROGRESS_DB = 'kds-team.ex-progress-db';
export const KDS_TEAM_EX_NETSUITE = 'kds-team.ex-netsuite';
export const KDS_TEAM_EX_YOUTUBE_ANALYTICS = 'kds-team.ex-youtube-analytics';
export const KDS_TEAM_APP_GD_CUSTOM_COLORS = 'kds-team.app-gd-custom-colors';
export const KDS_TEAM_APP_GD_USER_MANAGEMENT = 'kds-team.app-gd-user-management';
export const KDS_TEAM_APP_MAILGUN = 'kds-team.app-mailgun';
export const KDS_TEAM_APP_SENDINBLUE = 'kds-team.app-sendinblue';
export const KDS_TEAM_EX_BITBUCKET = 'kds-team.ex-bitbucket';
export const KDS_TEAM_EX_BRIGHTLOOM = 'kds-team.ex-brightloom';
export const KDS_TEAM_EX_CJ_AFFILIATE = 'kds-team.ex-cj-affiliate';
export const KDS_TEAM_EX_GOOGLE_NLP = 'kds-team.ex-google-nlp';
export const KDS_TEAM_EX_GOOGLE_TRANSLATION = 'kds-team.ex-google-translation';
export const KDS_TEAM_EX_ITUNES_REVIEWS = 'kds-team.ex-itunes-reviews';
export const KDS_TEAM_EX_LOOKER = 'kds-team.ex-looker';
export const KDS_TEAM_EX_MIXPANEL = 'kds-team.ex-mixpanel';
export const KDS_TEAM_EX_REVIEWTRACKERS = 'kds-team.ex-reviewtrackers';
export const KDS_TEAM_EX_SURVEY_MONKEY = 'kds-team.ex-survey-monkey';
export const KDS_TEAM_EX_TRUSTPILOT = 'kds-team.ex-trustpilot';
export const KDS_TEAM_EX_2_PERFORMANT = 'kds-team.ex-2performant';
export const KDS_TEAM_EX_AWS_COST_AND_USAGE_REPORTS = 'kds-team.ex-aws-cost-and-usage-reports';
export const KDS_TEAM_EX_ZOHO = 'kds-team.ex-zoho';
export const KDS_TEAM_PROCESSOR_COMPRESS = 'kds-team.processor-compress';
export const KDS_TEAM_PROCESSOR_RENAME_FILES = 'kds-team.processor-rename-files';
export const KDS_TEAM_PROCESSOR_CSV2XLS = 'kds-team.processor-csv2xls';
export const KDS_TEAM_PROCESSOR_CREATE_FILE_MANIFEST = 'kds-team.processor-create-file-manifest';
export const KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE = 'kds-team.processor-convert-column-case';
export const KDS_TEAM_WR_POWERBI = 'kds-team.wr-powerbi';
export const KDS_TEAM_WR_PAIRITY = 'kds-team.wr-pairity';
export const KDS_TEAM_WR_FIREBOLT = 'kds-team.wr-firebolt';
export const KDS_TEAM_WR_EXASOL = 'kds-team.wr-exasol';
export const KDS_TEAM_WR_HIVE_CSAS = 'kds-team.wr-hive-csas';
export const KEBOOLA_PROJECT_BACKUP = 'keboola.project-backup';
export const KEBOOLA_KEBOOLA_AS_CODE = 'keboola.keboola-as-code';
export const KEBOOLA_AG_GEOCODING = 'keboola.ag-geocoding';
export const KEBOOLA_APP_TREE = 'keboola.app-tree';
export const KEBOOLA_APP_WHAT_3_WORDS = 'keboola.app-what3words';
export const KEBOOLA_APP_TRANSFORMATION_PATTERN_SCD = 'keboola.app-transformation-pattern-scd';
export const KEBOOLA_APP_TRANSFORMATION_MIGRATE = 'keboola.app-transformation-migrate';
export const KEBOOLA_CSV_IMPORT = 'keboola.csv-import';
export const KEBOOLA_DARK_SKY_AUGMENTATION = 'keboola.dark-sky-augmentation';
export const KEBOOLA_EX_AWS_S_3 = 'keboola.ex-aws-s3';
export const KEBOOLA_EX_BABELFORCE = 'keboola.ex-babelforce';
export const KEBOOLA_EX_CURRENCY = 'keboola.ex-currency';
export const KEBOOLA_EX_DB_DB_2 = 'keboola.ex-db-db2';
export const KEBOOLA_EX_DB_DB_2_BATA = 'keboola.ex-db-db2-bata';
export const KEBOOLA_EX_DB_FIREBIRD = 'keboola.ex-db-firebird';
export const KEBOOLA_EX_DB_IMPALA = 'keboola.ex-db-impala';
export const KEBOOLA_EX_DB_INFORMIX = 'keboola.ex-db-informix';
export const KEBOOLA_EX_DB_HIVE = 'keboola.ex-db-hive';
export const KEBOOLA_EX_DB_HIVE_CSAS = 'keboola.ex-db-hive-csas';
export const KEBOOLA_EX_DB_HIVE_CSAS_TEST = 'keboola.ex-db-hive-csas-test';
export const KEBOOLA_EX_DB_MSSQL = 'keboola.ex-db-mssql';
export const KEBOOLA_EX_DB_MSSQL_CDATA = 'keboola.ex-db-mssql-cdata';
export const KEBOOLA_EX_DB_MYSQL = 'keboola.ex-db-mysql';
export const KEBOOLA_EX_DB_ORACLE = 'keboola.ex-db-oracle';
export const KEBOOLA_EX_DB_PGSQL = 'keboola.ex-db-pgsql';
export const KEBOOLA_EX_DB_REDSHIFT = 'keboola.ex-db-redshift';
export const KEBOOLA_EX_DB_SNOWFLAKE = 'keboola.ex-db-snowflake';
export const KEBOOLA_EX_DB_NETSUITE = 'keboola.ex-db-netsuite';
export const KEBOOLA_EX_DYNAMODB = 'keboola.ex-dynamodb';
export const KEBOOLA_EX_EMAIL_ATTACHMENTS = 'keboola.ex-email-attachments';
export const KEBOOLA_EX_FACEBOOK = 'keboola.ex-facebook';
export const KEBOOLA_EX_FACEBOOK_ADS = 'keboola.ex-facebook-ads';
export const KEBOOLA_EX_FLEXIBEE = 'keboola.ex-flexibee';
export const KEBOOLA_EX_FTP = 'keboola.ex-ftp';
export const KEBOOLA_EX_FTP_CSAS = 'keboola.ex-ftp-csas';
export const KEBOOLA_EX_GCALENDAR = 'keboola.ex-gcalendar';
export const KEBOOLA_EX_GITHUB = 'keboola.ex-github';
export const KEBOOLA_EX_GMAIL = 'keboola.ex-gmail';
export const KEBOOLA_EX_GOODDATA = 'keboola.ex-gooddata';
export const KEBOOLA_EX_GOOGLE_ADS = 'keboola.ex-google-ads';
export const KEBOOLA_EX_GOOGLE_ADWORDS_REPORTS_V_201809 =
  'keboola.ex-google-adwords-reports-v201809';
export const KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 = 'keboola.ex-google-analytics-v4';
export const KEBOOLA_EX_GOOGLE_BIGQUERY = 'keboola.ex-google-bigquery';
export const KEBOOLA_EX_GOOGLE_BIGQUERY_V_2 = 'keboola.ex-google-bigquery-v2';
export const KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES = 'keboola.ex-google-bigquery-v2-avro-files';
export const KEBOOLA_EX_GOOGLE_DRIVE = 'keboola.ex-google-drive';
export const KEBOOLA_EX_HTTP = 'keboola.ex-http';
export const KEBOOLA_EX_HUBSPOT = 'keboola.ex-hubspot';
export const KEBOOLA_EX_INSTAGRAM = 'keboola.ex-instagram';
export const KEBOOLA_EX_INTERCOM_V_2 = 'keboola.ex-intercom-v2';
export const KEBOOLA_EX_MAILCHIMP = 'keboola.ex-mailchimp';
export const KEBOOLA_EX_MONGODB = 'keboola.ex-mongodb';
export const KEBOOLA_EX_ONEDRIVE = 'keboola.ex-onedrive';
export const KEBOOLA_EX_PAPERTRAIL = 'keboola.ex-papertrail';
export const KEBOOLA_EX_PINGDOM = 'keboola.ex-pingdom';
export const KEBOOLA_EX_PIPEDRIVE = 'keboola.ex-pipedrive';
export const KEBOOLA_EX_PORTADI = 'keboola.ex-portadi';
export const KEBOOLA_EX_SAMPLE_DATA = 'keboola.ex-sample-data';
export const KEBOOLA_EX_SKLIK = 'keboola.ex-sklik';
export const KEBOOLA_EX_SLACK = 'keboola.ex-slack';
export const KEBOOLA_EX_STORAGE = 'keboola.ex-storage';
export const KEBOOLA_EX_STRIPE = 'keboola.ex-stripe';
export const KEBOOLA_EX_TERADATA = 'keboola.ex-teradata';
export const KEBOOLA_EX_TWITTER = 'keboola.ex-twitter';
export const KEBOOLA_EX_YOUTUBE = 'keboola.ex-youtube';
export const KEBOOLA_EX_ZENDESK = 'keboola.ex-zendesk';
export const KEBOOLA_FAKTUROID_WRITER = 'keboola.fakturoid-writer';
export const KEBOOLA_GOODDATA_WRITER = 'keboola.gooddata-writer';
export const KEBOOLA_ORACLE_TRANSFORMATION = 'keboola.oracle-transformation';
export const KEBOOLA_ORCHESTRATOR = 'keboola.orchestrator';
export const KEBOOLA_PROCESSOR_SPLIT_TABLE = 'keboola.processor-split-table';
export const KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN = 'keboola.processor-add-filename-column';
export const KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN = 'keboola.processor-add-row-number-column';
export const KEBOOLA_PROCESSOR_CREATE_MANIFEST = 'keboola.processor-create-manifest';
export const KEBOOLA_PROCESSOR_DECOMPRESS = 'keboola.processor-decompress';
export const KEBOOLA_PROCESSOR_ESCAPE_CHARACTER = 'keboola.processor-escape-character';
export const KEBOOLA_PROCESSOR_FILTER_FILES = 'keboola.processor-filter-files';
export const KEBOOLA_PROCESSOR_FIT_INTO_STORAGE = 'keboola.processor-fit-into-storage';
export const KEBOOLA_PROCESSOR_FLATTEN_FOLDERS = 'keboola.processor-flatten-folders';
export const KEBOOLA_PROCESSOR_FORMAT_CSV = 'keboola.processor-format-csv';
export const KEBOOLA_PROCESSOR_ICONV = 'keboola.processor-iconv';
export const KEBOOLA_PROCESSOR_LAST_FILE = 'keboola.processor-last-file';
export const KEBOOLA_PROCESSOR_LINE_ENDINGS = 'keboola.processor-line-endings';
export const KEBOOLA_PROCESSOR_MOVE_FILES = 'keboola.processor-move-files';
export const KEBOOLA_PROCESSOR_ORTHOGONAL = 'keboola.processor-orthogonal';
export const KEBOOLA_PROCESSOR_REMOVE_EMPTY_FILES_AND_FOLDERS =
  'keboola.processor-remove-empty-files-and-folders';
export const KEBOOLA_PROCESSOR_SELECT_COLUMNS = 'keboola.processor-select-columns';
export const KEBOOLA_PROCESSOR_SKIP_LINES = 'keboola.processor-skip-lines';
export const KEBOOLA_SANDBOXES = 'keboola.sandboxes';
export const KEBOOLA_DATA_APPS = 'keboola.data-apps';
export const KEBOOLA_SCHEDULER = 'keboola.scheduler';
export const KEBOOLA_MLFLOW = 'keboola.mlflow';
export const KEBOOLA_APP_MERGE_BRANCH_STORAGE = 'keboola.app-merge-branch-storage';
export const KEBOOLA_SAPI_MERGED_EXPORT = 'keboola.sapi-merged-export';
export const KEBOOLA_SHARED_CODE = 'keboola.shared-code';
export const KEBOOLA_SLICED_FILES_DOWNLOADER = 'keboola.sliced-files-downloader';
export const KEBOOLA_LEGACY_TRANSFORMATION = 'keboola.legacy-transformation';
export const KEBOOLA_SNOWFLAKE_TRANSFORMATION = 'keboola.snowflake-transformation';
export const KEBOOLA_SYNAPSE_TRANSFORMATION = 'keboola.synapse-transformation';
export const KEBOOLA_REDSHIFT_TRANSFORMATION = 'keboola.redshift-transformation';
export const KEBOOLA_PYTHON_TRANSFORMATION_V_2 = 'keboola.python-transformation-v2';
export const KEBOOLA_PYTHON_SPARK_TRANSFORMATION = 'keboola.python-spark-transformation';
export const KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION = 'keboola.python-mlflow-transformation';
export const KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION = 'keboola.python-snowpark-transformation';
export const KEBOOLA_DATABRICKS_TRANSFORMATION = 'keboola.databricks-transformation';
export const KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2 = 'keboola.csas-python-transformation-v2';
export const KEBOOLA_JULIA_TRANSFORMATION = 'keboola.julia-transformation';
export const KEBOOLA_R_TRANSFORMATION_V_2 = 'keboola.r-transformation-v2';
export const KEBOOLA_EXASOL_TRANSFORMATION = 'keboola.exasol-transformation';
export const KEBOOLA_TERADATA_TRANSFORMATION = 'keboola.teradata-transformation';
export const KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION = 'keboola.google-bigquery-transformation';
export const KEBOOLA_DBT_TRANSFORMATION = 'keboola.dbt-transformation';
export const KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY =
  'keboola.dbt-transformation-local-bigquery';
export const KEBOOLA_TEST_DBT_TRANSFORMATION = 'keboola-test.dbt-transformation';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_BIGQUERY =
  'keboola.dbt-transformation-remote-bigquery';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_MSSQL = 'keboola.dbt-transformation-remote-mssql';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_POSTGRESQL =
  'keboola.dbt-transformation-remote-postgresql';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_REDSHIFT =
  'keboola.dbt-transformation-remote-redshift';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_SNOWFLAKE =
  'keboola.dbt-transformation-remote-snowflake';
export const KEBOOLA_NO_CODE_DBT_TRANSFORMATION = 'keboola.no-code-dbt-transformation';
export const KEBOOLA_STORAGE = 'keboola.storage'; // component to get icon and name
export const KEBOOLA_VARIABLES = 'keboola.variables';
export const KEBOOLA_WR_AWS_S_3 = 'keboola.wr-aws-s3';
export const KEBOOLA_WR_AZURE_EVENT_HUB = 'keboola.wr-azure-event-hub';
export const KEBOOLA_WR_DB_IMPALA = 'keboola.wr-db-impala';
export const KEBOOLA_WR_DB_HIVE = 'keboola.wr-db-hive';
export const KEBOOLA_WR_DB_MSSQL_V_2 = 'keboola.wr-db-mssql-v2';
export const KEBOOLA_WR_DB_MYSQL = 'keboola.wr-db-mysql';
export const KEBOOLA_WR_DB_ORACLE = 'keboola.wr-db-oracle';
export const KEBOOLA_WR_DB_PGSQL = 'keboola.wr-db-pgsql';
export const KEBOOLA_WR_DB_SNOWFLAKE = 'keboola.wr-db-snowflake';
export const KEBOOLA_WR_DB_SNOWFLAKE_GCS = 'keboola.wr-db-snowflake-gcs';
export const KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3 = 'keboola.wr-db-snowflake-gcs-s3';
export const KEBOOLA_WR_DB_SYNAPSE = 'keboola.wr-synapse';
export const KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE = 'keboola.wr-snowflake-blob-storage';
export const KEBOOLA_WR_DROPBOX_V_2 = 'keboola.wr-dropbox-v2';
export const KEBOOLA_WR_ELASTICSEARCH = 'keboola.wr-elasticsearch';
export const KEBOOLA_WR_GOOGLE_BIGQUERY_V_2 = 'keboola.wr-google-bigquery-v2';
export const KEBOOLA_WR_GOOGLE_DRIVE = 'keboola.wr-google-drive';
export const KEBOOLA_WR_GOOGLE_SHEETS = 'keboola.wr-google-sheets';
export const KEBOOLA_WR_LOOKER_V2 = 'keboola.wr-looker-v2';
export const KEBOOLA_WR_ONEDRIVE = 'keboola.wr-onedrive';
export const KEBOOLA_WR_REDSHIFT_V_2 = 'keboola.wr-redshift-v2';
export const KEBOOLA_WR_STORAGE = 'keboola.wr-storage';
export const KEBOOLA_WR_SISENSE = 'keboola.wr-sisense';
export const KEBOOLA_WR_THOUGHTSPOT = 'keboola.wr-thoughtspot';
export const LEOCHAN_DATAHEALTH = 'leochan.datahealth';
export const LEOCHAN_EX_ASANA = 'leochan.ex-asana';
export const LEOCHAN_EX_LINKEDIN = 'leochan.ex-linkedin';
export const LEOCHAN_EX_MAGENTO_2 = 'leochan.ex-magento2';
export const LEOCHAN_EX_QUICKBOOKS = 'leochan.ex-quickbooks';
export const LEOCHAN_GEOIP = 'leochan.geoip';
export const LEOCHAN_GOOGLE_PLACES_REVIEWS = 'leochan.google_places_reviews';
export const LEOCHAN_GOOGLE_TRENDS = 'leochan.google_trends';
export const MARKETINGMINER_EX_MARKETING_MINER = 'marketingminer.ex-marketing-miner';
export const MEDIO_APP_ATTRIBUTION = 'medio.app-attribution';
export const MEDIO_EX_GOOGLE_SEARCH_CONSOLE = 'medio.ex-google-search-console';
export const MEDIO_EX_HEUREKA_CZ = 'medio.ex-heureka-cz';
export const MEDIO_EX_HEUREKA_SK = 'medio.ex-heureka-sk';
export const MEDIO_EX_MAJESTIC = 'medio.ex-majestic';
export const MEDIO_EX_PRICEMANIA_SK = 'medio.ex-pricemania-sk';
export const MEDIO_EX_SROVNAME_CZ = 'medio.ex-srovname-cz';
export const MEDIO_EX_ZBOZI_V_2 = 'medio.ex-zbozi-v2';
export const ORCHESTRATOR = 'orchestrator'; // fake
export const POCIN_EX_MAILCHIMP_EXPORT_API = 'pocin.ex-mailchimp-export-api';
export const POCIN_WR_MAILCHIMP = 'pocin.wr-mailchimp';
export const POND_5_EX_SAILTHRU = 'pond5.ex-sailthru';
export const PROVISIONING = 'provisioning'; // fake
export const QUINTLY_EX_QUINTLY = 'quintly.ex-quintly';
export const RADEKTOMASEK_EX_DROPBOX_V_2 = 'radektomasek.ex-dropbox-v2';
export const RECOMBEE_APP_RECOMBEEE = 'recombee.app-recombeee';
export const REVOLT_BI_APP_DAKTELA = 'revolt-bi.app-daktela';
export const REVOLT_BI_EX_ADFORM = 'revolt-bi.ex-adform';
export const REVOLT_BI_EX_FAVI = 'revolt-bi.ex-favi';
export const REVOLT_BI_EX_FINGERA = 'revolt-bi.ex-fingera';
export const REVOLT_BI_EX_RTBHOUSE = 'revolt-bi.ex-rtbhouse';
export const REVOLT_BI_PROCESSOR_BATCHIFY = 'revolt-bi.processor-batchify';
export const STORIES_WR_STORIES = 'stories.wr-stories';
export const TDE_EXPORTER = 'tde-exporter';
export const TOMASTRNKA_KBL_CSV_S_3_XLSX = 'tomastrnka.kbl_csv_s3_xlsx';
export const TRANSFORMATION = 'transformation'; // fake
export const VOKURKA_EX_CONDUCTOR = 'vokurka.ex-conductor';
export const VOKURKA_EX_XERO = 'vokurka.ex-xero';
export const WR_SILVERPOP = 'wr-silverpop';
export const SMETRIC_EX_SIMPRO_API = 'smetric.ex-simpro-api';
export const SMETRIC_EX_VEND_API = 'smetric.ex-vend-api';
