import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import { disableTasksWithDeletedConfigurations } from './helpers';

const updateFlowTasks = (flow: Map<string, any>, componentId: string, config: Map<string, any>) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    flow.get('id'),
    {
      configuration: JSON.stringify(
        disableTasksWithDeletedConfigurations(flow, componentId, config.get('id')).toJS(),
      ),
    },
    `Disabled tasks of deleted configuration`,
  ).catch((error: any) => {
    if (error.response?.status !== HTTP_STATUS_CODE_NOT_FOUND) {
      throw error;
    }
  });
};

export { updateFlowTasks };
