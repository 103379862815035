import adapter from '@/modules/configurations/adapters/oauth';
import OauthSection from '@/modules/configurations/react/components/OauthSection';

export default function () {
  return {
    render: OauthSection,
    onLoad: adapter.parseConfiguration,
    onSave: adapter.createConfiguration,
    isComplete: adapter.isComplete,
    isAuthSection: true,
  };
}
