import React from 'react';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import actionsProvisioning from '@/modules/ex-facebook/actionsProvisioning';
import { QUERY_TYPE } from '@/modules/ex-facebook/constants';
import { isResultsLimitValid } from '@/modules/ex-facebook/helpers';
import storeProvisioning from '@/modules/ex-facebook/storeProvisioning';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const HeaderButtons = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');
      const queryId = RoutesStore.getCurrentRouteParam('queryId');
      const store = storeProvisioning(componentId, configId);
      const actions = actionsProvisioning(componentId, configId);
      const query = store.findQuery(queryId);

      return {
        query,
        actions,
        localState: store.getLocalState(),
        isSaving: store.isSavingQuery(queryId),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore],
  );

  const isAsyncQuery = () => {
    return state.localState.getIn(['query', 'type']) === QUERY_TYPE.ASYNC_INSIGHTS;
  };

  const handleSave = () => {
    let query = state.localState.get('query');

    if (isAsyncQuery()) {
      query = query.deleteIn(['query', 'fields']).deleteIn(['query', 'path']);
    } else {
      query = query.deleteIn(['query', 'parameters']);
    }

    return state.actions.saveQuery(query);
  };

  const isValid = () => {
    return isResultsLimitValid(state.localState.getIn(['query', 'query', 'limit']));
  };

  const config = state.localState.get('query', state.query);

  if (state.readOnly || state.query.get('id') !== config.get('id')) {
    return null;
  }

  const isChanged = () => {
    // Saving of the name is handled in dedicated component, don't trigger save button when it's changed.
    const localStateWithoutName = state.query.delete('name');
    const configWithoutName = config.delete('name');

    return !localStateWithoutName.equals(configWithoutName);
  };

  return (
    <CatchUnsavedChanges isDirty={isChanged()} onSave={handleSave} isSaveDisabled={!isValid()}>
      <SaveButtons
        isChanged={isChanged()}
        isSaving={state.isSaving}
        disabled={!isValid()}
        onReset={() => state.actions.updateLocalState(['query'], state.query)}
        onSave={handleSave}
      />
    </CatchUnsavedChanges>
  );
};

export default HeaderButtons;
