import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { PanelWithDetails } from 'design';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';

const InputMapping = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      changedSince: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const panelExpanded = this.props.value.changedSince !== '';

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Table</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl.Static>
                <StorageApiTableLinkEx tableId={this.props.value.source} />
              </FormControl.Static>
            </div>
          </FormGroup>
          <PanelWithDetails
            defaultExpanded={panelExpanded}
            placement="bottom"
            labelCollapse="Hide Advanced Options"
            labelOpen="Show Advanced Options"
          >
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Changed in last</ControlLabel>
              </div>
              <div className="col-xs-8">
                <ChangedSinceInput
                  value={this.props.value.changedSince}
                  onChange={(value) => this.props.onChange({ changedSince: value })}
                  disabled={this.props.disabled}
                  tableId={this.props.value.source}
                />
              </div>
            </FormGroup>
          </PanelWithDetails>
        </div>
      </div>
    );
  },
});

export default InputMapping;
