import type { List, Map } from 'immutable';
import { strRightBack } from 'underscore.string';

const getRuntimeShortLabel = (runtime: Map<string, any>, imageKey: string) => {
  if (!runtime.get('imageTag')) {
    const version = strRightBack(runtime.get('description'), ' ');
    return /^[.\d]+$/.test(version) ? version : imageKey;
  }

  return runtime.get('imageTag', imageKey);
};

const prepareRuntimeLabel = (runtime: Map<string, any>) => {
  const isDefault = runtime.get('isTypeDefault', false);
  const description = `${runtime.get('description')}${isDefault ? ' (default)' : ''}`;

  if (!runtime.get('imageTag')) {
    return description;
  }

  return `${runtime.get('imageTag')} - ${description}`;
};

const prepareRuntimeValue = (runtime: Map<string, any>, imageKey: string) => {
  return runtime.get('isTypeDefault', false) ? '' : runtime.get(imageKey);
};

const prepareRuntimes = (runtimes: List<any>, imageKey: string) => {
  return runtimes
    .sortBy(prepareRuntimeLabel)
    .reverse()
    .map((runtime: Map<string, any>) => {
      return { label: prepareRuntimeLabel(runtime), value: prepareRuntimeValue(runtime, imageKey) };
    })
    .toJS();
};

const prepareRuntimesForTransformation = (runtimes: List<any>) => {
  return prepareRuntimes(runtimes, 'transformationComponentTag');
};

const prepareRuntimesForSandboxes = (runtimes: List<any>) => {
  return prepareRuntimes(runtimes, 'sandboxImageTag');
};

export {
  getRuntimeShortLabel,
  prepareRuntimeLabel,
  prepareRuntimesForTransformation,
  prepareRuntimesForSandboxes,
};
