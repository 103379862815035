import { List, Map } from 'immutable';

import {
  getTableColumnMetadata,
  hasTableColumnMetadataDatatypes,
} from '@/modules/components/utils/tableMetadataHelper';
import {
  buildDatatypes,
  getMetadataDataTypes,
} from '@/modules/legacy-transformation/react/components/mapping/InputMappingRowSnowflakeEditorHelper';

/**
 * Snowflake advanced columns
 * @param {Object} mapping Map mapping
 * @param {Object} tables Map tables
 * @return {boolean} Show details
 */
function snowflake(mapping, tables) {
  if (mapping.has('columns') && mapping.get('columns').count() > 0) {
    return true;
  }

  if (mapping.get('days') > 0) {
    return true;
  }

  if (mapping.get('changedSince')) {
    return true;
  }

  if (mapping.get('whereColumn')) {
    return true;
  }

  if (mapping.has('whereValues') && mapping.get('whereValues').count() > 0) {
    return true;
  }

  if (mapping.has('datatypes') && mapping.get('datatypes').count() > 0) {
    const sourceTable = tables.find(
      (table) => table.get('id') === mapping.get('source'),
      null,
      Map(),
    );

    if (hasTableColumnMetadataDatatypes(sourceTable)) {
      const datatypes = buildDatatypes(
        sourceTable.get('columns', List()),
        getMetadataDataTypes(
          getTableColumnMetadata(sourceTable),
          sourceTable.get('primaryKey', List()),
        ),
        sourceTable,
      );
      return !mapping.get('datatypes').equals(datatypes);
    }

    return !mapping.get('datatypes').equals(buildDatatypes(sourceTable.get('columns', List())));
  }

  return false;
}

/**
 * Docker advanced columns
 * @param {Object} mapping Map mapping
 * @return {boolean} Show details
 */
function docker(mapping) {
  if (mapping.has('columns') && mapping.get('columns').count() > 0) {
    return true;
  }

  if (mapping.get('whereColumn')) {
    return true;
  }

  if (mapping.get('days') > 0) {
    return true;
  }

  if (mapping.get('changedSince')) {
    return true;
  }

  if (mapping.has('whereValues') && mapping.get('whereValues').count() > 0) {
    return true;
  }
  return false;
}

export default function (backend, mapping, tables) {
  if (backend === 'docker') {
    return docker(mapping);
  } else if (backend === 'snowflake') {
    return snowflake(mapping, tables);
  } else {
    return false;
  }
}
