import React from 'react';

import Loader from '@/react/common/Loader';

const EmptyRow = (props: { isLoading: boolean; entity?: string }) => {
  const entity = props.entity || 'configuration';

  return (
    <div className="tr no-hover">
      <div className="td">
        {props.isLoading ? (
          <>
            <Loader className="icon-addon-right" />
            Loading {entity}s...
          </>
        ) : (
          `No ${entity} created yet.`
        )}
      </div>
    </div>
  );
};

export default EmptyRow;
