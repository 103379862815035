import { KEBOOLA_KEBOOLA_AS_CODE } from '@/constants/componentIds';
import { prepareJobsQuery } from '@/modules/dev-branches/helpers';
import { createUrl as createOldQueueUrl } from '@/modules/jobs/JobsApi';
import queueApi from '@/modules/queue/api';
import EventsApi from '@/modules/sapi-events/EventsApi';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import { DASHBOARD_EVENTS_LIMIT, DASHBOARD_JOBS_LIMIT } from './constants';
import { createQueryToGetActivities } from './helpers';

export default {
  getJobs() {
    if (ApplicationStore.hasNewQueue()) {
      return queueApi.getJobs({
        limit: DASHBOARD_JOBS_LIMIT,
      });
    }

    return request('GET', createOldQueueUrl('jobs'))
      .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
      .query({
        q: prepareJobsQuery(),
        limit: DASHBOARD_JOBS_LIMIT,
      })
      .promise()
      .then((response) => response.body);
  },

  getActivities() {
    return EventsApi.listEvents({
      q: createQueryToGetActivities(),
      limit: DASHBOARD_EVENTS_LIMIT,
    });
  },

  getGitLatestEvent() {
    return EventsApi.listEvents({
      component: KEBOOLA_KEBOOLA_AS_CODE,
      limit: 1,
    }).then((events) => events[0]);
  },
};
