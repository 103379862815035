import React from 'react';
import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import dispatcher from '@/Dispatcher';
import { ActionTypes as globalActionTypes } from '@/modules/components/Constants';
import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import storageApi from '@/modules/components/StorageApi';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prefixTagsWithDevBranchId } from '@/modules/dev-branches/helpers';
import RoutesStore from '@/stores/RoutesStore';
import HttpError from '@/utils/errors/HttpError';
import SimpleError from '@/utils/errors/SimpleError';
import tableHasBeenExportedNotification from './components/tableHasBeenExportedNotification';
import storageExplorerApi from './api';
import { actionTypes, filesLimit, jobsLimit, routeNames, sortEntities } from './constants';

const updateSearchQuery = (query = '') => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_SEARCH_QUERY,
    query,
  });
};

const updateSearchFilters = (searchFilters = Map()) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_SEARCH_FILTERS,
    searchFilters,
  });
};

const toggleContextFilter = () => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_CONTEXT_FILTER,
  });
};

const toggleExpandedBucket = (bucketId, isExpanded) => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_EXPAND_BUCKET,
    bucketId,
    isExpanded,
  });
};

const toggleShowAllBuckets = (show) => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_SHOW_ALL_BUCKETS,
    show,
  });
};

const updateBucketsSort = (sort) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_BUCKETS_SORT,
    sort,
  });
};

const resetExpandedBuckets = () => {
  dispatcher.handleViewAction({
    type: actionTypes.RESET_EXPANDED_BUCKETS,
  });
};

const errorNotification = (message) => {
  if (!_.isString(message)) {
    throw message;
  }

  ApplicationActionCreators.sendNotification({
    type: 'error',
    message: message,
  });
};

const tokenVerify = () => {
  return StorageActionCreators.tokenVerify();
};

const loadJobs = () => {
  return StorageActionCreators.loadJobs({ limit: jobsLimit });
};

const loadJob = (jobId) => {
  return StorageActionCreators.loadJob(jobId);
};

const loadMoreJobs = (offset) => {
  return StorageActionCreators.loadMoreJobs({ limit: jobsLimit, offset });
};

const uploadFile = (id, file, params) => {
  return StorageActionCreators.uploadFile(id, file, params);
};

const deleteFile = (fileId) => {
  return StorageActionCreators.deleteFile(fileId)
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const deleteFiles = (files) => {
  return Promise.each(files, deleteFile)
    .then(loadFiles)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'All files have been removed.',
      });
    });
};

const loadFiles = (params) => {
  return StorageActionCreators.loadFilesForce({ ...params, limit: filesLimit })
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const loadMoreFiles = (params) => {
  return StorageActionCreators.loadMoreFiles({ ...params, limit: filesLimit })
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const filterFiles = (query) => {
  dispatcher.handleViewAction({
    type: globalActionTypes.STORAGE_FILES_RESET,
  });

  RoutesStore.getRouter().replaceTo(routeNames.FILES, null, { ...(query && { q: query }) });
};

const updateFilesSearchQuery = (query) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_FILES_SEARCH_QUERY,
    query,
  });
};

const createBucket = (newBucket, transitionToRoute = routeNames.BUCKET) => {
  return StorageActionCreators.createBucket(newBucket).then((bucket) => {
    if (transitionToRoute) {
      return RoutesStore.getRouter().transitionTo(transitionToRoute, {
        bucketId: bucket.id,
      });
    }

    return fromJS(bucket);
  });
};

const registerExternalBucket = (backend, bucketName, path) => {
  return StorageActionCreators.registerExternalBucket({
    stage: 'in',
    backend: backend,
    name: bucketName,
    displayName: bucketName,
    path,
  });
};

const refreshExternalBucket = (bucketId) => {
  return StorageActionCreators.refreshExternalBucket(bucketId);
};

const updateBucket = (bucketId, params) => {
  return StorageActionCreators.updateBucket(bucketId, params);
};

const deleteBucket = (bucketId, options) => {
  return StorageActionCreators.deleteBucket(bucketId, options);
};

const deleteBucketsAndTables = (selected) => {
  const buckets = selected.filter((row) => row.has('stage'));
  const tables = selected.filter((row) => {
    return (
      !row.has('stage') &&
      !buckets.some((bucket) => bucket.get('id') === row.getIn(['bucket', 'id']))
    );
  });

  return Promise.each(buckets, (bucket) => {
    return storageApi
      .deleteBucket(bucket.get('id'), { force: true, async: true })
      .then(StorageActionCreators.waitForFinishedStorageJob);
  })
    .then(() => {
      return Promise.each(tables, (table) =>
        storageApi.deleteTable(table.get('id'), { force: true }),
      );
    })
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'Selected items have been removed.',
      });

      return StorageActionCreators.loadBucketsAndTablesForce();
    });
};

const createTable = (bucketId, params) => {
  return StorageActionCreators.createTable(bucketId, params);
};

const createTableDefinition = (bucketId, params) => {
  return StorageActionCreators.createTableDefinition(bucketId, params);
};

const createAliasTable = (bucketId, params) => {
  return StorageActionCreators.createAliasTable(bucketId, params);
};

const updateTable = (tableId, params) => {
  return StorageActionCreators.updateTable(tableId, params);
};

const deleteTable = (tableId, onTableDeleteHook) => {
  return StorageActionCreators.deleteTable(tableId, { forceDelete: true, onTableDeleteHook });
};

const deleteMultipleTables = (tablesIds) => {
  return StorageActionCreators.deleteMultipleTables(tablesIds, { forceDelete: true });
};

const deleteMultipleColumns = (tableId, columns, options) => {
  return StorageActionCreators.deleteMultipleColumns(tableId, columns, options).then(() => {
    return StorageActionCreators.loadTableDetailForce(tableId);
  });
};

const truncateTable = (tableId) => {
  return StorageActionCreators.truncateTable(tableId)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Table <b>{tableId}</b> has been truncated.
          </>
        ),
      });
    })
    .catch(errorNotification);
};

const truncateMultipleTables = (tablesIds) => {
  return StorageActionCreators.truncateMultipleTables(tablesIds);
};

const pullTable = (tableId) => {
  return StorageActionCreators.pullTable(tableId)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => (
          <>
            Table <b>{tableId}</b> has been cloned.
          </>
        ),
      });

      return StorageActionCreators.loadTableDetailForce(tableId).then((table) => {
        return StorageActionCreators.loadBucketDetailForce(table.bucket.id);
      });
    })
    .catch(errorNotification);
};

const exportTables = (tables, type) => {
  const tablesIds = tables.map((table) => table.get('id'));

  dispatcher.handleViewAction({
    type: globalActionTypes.STORAGE_TABLES_EXPORT,
    tablesIds,
  });

  return storageExplorerApi
    .exportTables(tablesIds, type)
    .then(StorageActionCreators.waitForFinishedStorageJob)
    .catch((error) => {
      dispatcher.handleViewAction({
        type: globalActionTypes.STORAGE_TABLES_EXPORT_ERROR,
        tablesIds,
      });

      throw error?.message || error;
    })
    .then((response) => {
      dispatcher.handleViewAction({
        type: globalActionTypes.STORAGE_TABLES_EXPORT_SUCCESS,
        tablesIds,
      });

      return storageApi.getFilesWithRetry({
        runId: response.runId,
        'tags[]': prefixTagsWithDevBranchId(
          ['storage-merged-export'],
          DevBranchesStore.getCurrentId(),
        ),
      });
    })
    .then((files) => {
      if (!files || files.length === 0) {
        throw new SimpleError('Loading files for download failed', 'Please try again.');
      }

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: tableHasBeenExportedNotification(tables),
        link: {
          download: true,
          href: files[0].url,
          label: 'Download Now',
        },
        timeout: 0,
      });
    });
};

const loadTable = (tableId, params) => {
  return StorageActionCreators.loadTable(tableId, params);
};

const loadTableDetail = (tableId) => {
  return StorageActionCreators.loadTableDetail(tableId);
};

const loadBucketDetail = (bucketId) => {
  return StorageActionCreators.loadBucketDetail(bucketId);
};

const setAliasTableFilter = (tableId, params) => {
  return StorageActionCreators.setAliasTableFilter(tableId, params).catch(HttpError, (error) => {
    throw error.message;
  });
};

const removeAliasTableFilter = (tableId) => {
  return StorageActionCreators.removeAliasTableFilter(tableId).catch(HttpError, (error) => {
    throw error.message;
  });
};

const createTablePrimaryKey = (tableId, params) => {
  return StorageActionCreators.createTablePrimaryKey(tableId, params).catch(errorNotification);
};

const removeTablePrimaryKey = (tableId) => {
  return StorageActionCreators.removeTablePrimaryKey(tableId).catch(errorNotification);
};

const restoreUsingTimeTravel = (bucketId, params) => {
  return StorageActionCreators.restoreUsingTimeTravel(bucketId, params).catch(errorNotification);
};

const loadSnapshots = (tableId, params) => {
  return StorageActionCreators.loadTableSnapshots(tableId, params);
};

const createSnapshots = (tableIds, params) => {
  return StorageActionCreators.createSnapshots(tableIds, params);
};

const createTableFromSnapshot = (bucketId, params) => {
  return StorageActionCreators.createTableFromSnapshot(bucketId, params).catch(errorNotification);
};

const deleteSnapshot = (tableId, snapshotId) => {
  return StorageActionCreators.deleteSnapshot(tableId, snapshotId);
};

const dataPreview = (tableId, params) => {
  return storageApi.tableDataJsonPreview(tableId, params);
};

const addTableColumn = (tableId, params) => {
  return StorageActionCreators.addTableColumn(tableId, params).catch(errorNotification);
};

const deleteTableColumn = (tableId, columnName, params) => {
  return StorageActionCreators.deleteTableColumn(tableId, columnName, params)
    .then(() => StorageActionCreators.loadTableDetailForce(tableId))
    .catch(errorNotification);
};

const addFileTag = (fileId, tag) => {
  return StorageActionCreators.addFileTag(fileId, tag).catch(errorNotification);
};

const deleteFileTag = (fileId, tag) => {
  return StorageActionCreators.deleteFileTag(fileId, tag).catch(errorNotification);
};

const saveColumnMetadata = (columnId, keyValues) => {
  return MetadataActionCreators.saveMetadataSet('column', columnId, keyValues).catch(
    errorNotification,
  );
};

const deleteColumnMetadata = (columnId, metadataId) => {
  return MetadataActionCreators.deleteMetadata('column', columnId, metadataId).catch(
    errorNotification,
  );
};

const resetStorageStore = () => {
  updateSearchQuery();
  updateSearchFilters();
  resetExpandedBuckets();
  updateBucketsSort({ entity: sortEntities.NAME, order: 1 });
  toggleShowAllBuckets(false);
};

export {
  tokenVerify,
  createBucket,
  registerExternalBucket,
  refreshExternalBucket,
  updateBucket,
  deleteBucket,
  deleteBucketsAndTables,
  createTable,
  createTableDefinition,
  createAliasTable,
  updateTable,
  deleteTable,
  deleteMultipleTables,
  deleteMultipleColumns,
  setAliasTableFilter,
  removeAliasTableFilter,
  createTablePrimaryKey,
  removeTablePrimaryKey,
  truncateTable,
  truncateMultipleTables,
  pullTable,
  exportTables,
  loadTable,
  loadTableDetail,
  loadBucketDetail,
  loadJob,
  loadJobs,
  loadMoreJobs,
  uploadFile,
  deleteFile,
  deleteFiles,
  loadFiles,
  loadMoreFiles,
  filterFiles,
  toggleContextFilter,
  updateSearchQuery,
  updateSearchFilters,
  updateFilesSearchQuery,
  restoreUsingTimeTravel,
  loadSnapshots,
  createSnapshots,
  createTableFromSnapshot,
  deleteSnapshot,
  dataPreview,
  addTableColumn,
  deleteTableColumn,
  saveColumnMetadata,
  deleteColumnMetadata,
  toggleExpandedBucket,
  resetExpandedBuckets,
  updateBucketsSort,
  toggleShowAllBuckets,
  resetStorageStore,
  addFileTag,
  deleteFileTag,
};
