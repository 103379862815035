import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard, Tooltip } from 'design';
import { ltrim } from 'underscore.string';

import EncryptedValue from '@/react/common/EncryptedValue';
import Loader from '@/react/common/Loader';

const Secret = (props: {
  name: string;
  value: string;
  isDeleting: boolean;
  startEditingFn: () => void;
  deleteVariableFn: () => void;
  readOnly: boolean;
}) => {
  const renderValue = () => {
    if (props.name.startsWith('#')) {
      return <EncryptedValue />;
    }

    if (props.value === '') {
      return <code>EMPTY STRING</code>;
    }

    return props.value;
  };

  const trimmedName = ltrim(props.name, '#');

  return (
    <div className="flex-container variable-static">
      <div className="variable-name flex-container flex-start">
        <FontAwesomeIcon icon="code" fixedWidth className="icon-addon-right" />
        {trimmedName}
      </div>
      <div className="variable-value overflow-break-anywhere">{renderValue()}</div>
      {!props.readOnly && (
        <div className="variable-actions no-wrap">
          <Clipboard
            inline={false}
            tooltipPlacement="top"
            tooltipText="Copy Secret"
            text={`st.secrets["${trimmedName}"]`}
            btnClassName="btn-link text-muted"
          />
          <Tooltip placement="top" tooltip="Edit Secret">
            <Button
              className="text-muted"
              bsStyle="link"
              disabled={props.isDeleting}
              onClick={props.startEditingFn}
            >
              <FontAwesomeIcon icon="pen" fixedWidth />
            </Button>
          </Tooltip>
          <Tooltip placement="top" tooltip="Delete Secret">
            <Button
              className="text-muted"
              bsStyle="link"
              disabled={props.isDeleting}
              onClick={props.deleteVariableFn}
            >
              {props.isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Secret;
