import React from 'react';
import Promise from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { IS_DEVELOPMENT } from '@/constants/environment';
import { ActionTypes as ApplicationActionTypes } from '@/constants/KbcConstants';
import dispatcher from '@/Dispatcher';
import { createAdminAccountSessionToken } from '@/modules/admin/api';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import pushGtmEvent, { events as gtmEvents } from '@/utils/pushGtmEvent';
import { ActionTypes } from './constants';
import { redirectToProjects } from './helpers';
import manageApi from './manageApi';
import SettingsStore from './SettingsStore';

const generateSessionToken = () => {
  if (SettingsStore.isTokenLoaded()) {
    return manageApi.verifyToken().catch(() => generateSessionTokenForce());
  }

  return generateSessionTokenForce();
};

const generateSessionTokenForce = () => {
  // in dev env we use manage token with type "admin" to create new manage token with type "session"
  if (IS_DEVELOPMENT) {
    return manageApi.createSessionToken().then((token) => {
      dispatcher.handleViewAction({
        type: ActionTypes.GENERATE_SESSION_TOKEN_SUCCESS,
        token,
      });
    });
  }
  // in production we create manage token with type "session" directly, since we are authorized
  // using PHP session
  return createAdminAccountSessionToken().then((token) => {
    dispatcher.handleViewAction({
      type: ActionTypes.GENERATE_SESSION_TOKEN_SUCCESS,
      token,
    });
  });
};

const loadProject = () => {
  if (SettingsStore.isProjectLoaded()) {
    return Promise.resolve();
  }

  return loadProjectForce();
};

const loadProjectForce = () => {
  return manageApi.getCurrentProject().then((project) => {
    dispatcher.handleViewAction({
      type: ActionTypes.LOAD_PROJECT_SUCCESS,
      project,
    });
  });
};

const loadOrganizationsViaMaintainer = () => {
  if (SettingsStore.areOrganizationsViaMaintainerLoaded()) {
    loadOrganizationsViaMaintainerForce();
    return Promise.resolve();
  }

  return loadOrganizationsViaMaintainerForce();
};

const loadOrganizationsViaMaintainerForce = () => {
  return manageApi.getMaintainers().then((maintainers) => {
    return Promise.all(
      maintainers.map((maintainer) => {
        return manageApi.getMaintainerOrganizations(maintainer.id).then((organizations) => {
          dispatcher.handleViewAction({
            type: ActionTypes.LOAD_MAINTAINER_ORGANIZATIONS_SUCCESS,
            maintainer,
            organizations,
          });
        });
      }),
    );
  });
};

const loadOrganizations = () => {
  if (SettingsStore.areOrganizationsLoaded()) {
    loadOrganizationsForce();
    return Promise.resolve();
  }

  return loadOrganizationsForce();
};

const loadOrganizationsForce = () => {
  return manageApi.getOrganizations().then((organizations) => {
    dispatcher.handleViewAction({
      type: ActionTypes.LOAD_ORGANIZATIONS_SUCCESS,
      organizations,
    });
  });
};

const loadOrganization = (id) => {
  return manageApi.getOrganization(id).then((organization) => {
    dispatcher.handleViewAction({
      type: ActionTypes.LOAD_ORGANIZATION_SUCCESS,
      organization,
    });
  });
};

const loadUsers = () => {
  if (SettingsStore.areUsersLoaded()) {
    loadUsersForce();
    return Promise.resolve();
  }

  return loadUsersForce();
};

const loadUsersForce = () => {
  return Promise.props({
    users: manageApi.getUsers(),
    invitations: manageApi.getInvitations(),
    joinRequests: manageApi.getJoinRequests(),
  }).then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.LOAD_USERS_SUCCESS,
      data,
    });
  });
};

const inviteUser = (email, role) => {
  return manageApi
    .inviteUser({ email, role })
    .tap(() => pushGtmEvent(gtmEvents.EVENT_USER_INVITED))
    .then(loadUsersForce)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              The user <b>{email}</b> was invited to the project.
            </>
          );
        },
      });
    });
};

const cancelInvitation = (id) => {
  return manageApi.cancelInvite(id).then(loadUsersForce);
};

const approveUser = (id) => {
  return manageApi.approveUser(id).then(loadUsersForce);
};

const rejectUser = (id) => {
  return manageApi.rejectUser(id).then(loadUsersForce);
};

const removeUser = (id, isCurrentAdmin) => {
  return manageApi.removeUser(id).then(() => {
    if (!isCurrentAdmin) {
      return loadUsersForce();
    }

    redirectToProjects();
  });
};

const updateProject = (params) => {
  return manageApi.updateProject(params).then(() => {
    return Promise.all([
      loadProjectForce(),
      loadOrganizationsForce(),
      StorageActionCreators.tokenVerifyForce(),
    ]);
  });
};

const removeProject = () => {
  return manageApi
    .removeProject()
    .tap(() => pushGtmEvent(gtmEvents.EVENT_PROJECT_DELETED))
    .then(() => redirectToProjects());
};

const addProjectFeature = (feature) => {
  return generateSessionToken().then(() => {
    return manageApi.addProjectFeature(feature).then(() => {
      dispatcher.handleViewAction({
        type: ApplicationActionTypes.APPLICATION_ADD_PROJECT_FEATURE,
        feature,
      });
    });
  });
};

const removeProjectFeature = (feature) => {
  return generateSessionToken().then(() => {
    return manageApi.removeProjectFeature(feature).then(() => {
      dispatcher.handleViewAction({
        type: ApplicationActionTypes.APPLICATION_REMOVE_PROJECT_FEATURE,
        feature,
      });
    });
  });
};

const addUserFeature = (feature) => {
  return generateSessionToken().then(() => {
    return manageApi.addUserFeature(feature).then(() => {
      dispatcher.handleViewAction({
        type: ApplicationActionTypes.APPLICATION_ADD_USER_FEATURE,
        feature,
      });
    });
  });
};

const removeUserFeature = (feature) => {
  return generateSessionToken().then(() => {
    return manageApi.removeUserFeature(feature).then(() => {
      dispatcher.handleViewAction({
        type: ApplicationActionTypes.APPLICATION_REMOVE_USER_FEATURE,
        feature,
      });
    });
  });
};

const changeOrganization = (organizationId, oldOrganizationId) => {
  return manageApi.moveProject(organizationId).then(() => {
    return Promise.all([
      loadProjectForce(),
      loadOrganization(organizationId),
      loadOrganization(oldOrganizationId),
      loadOrganizationsForce(),
      StorageActionCreators.tokenVerifyForce(),
    ]);
  });
};

const loadFeatures = () => {
  if (!SettingsStore.getFeatures().isEmpty()) {
    loadFeaturesForce();
    return Promise.resolve();
  }

  return loadFeaturesForce();
};

const loadFeaturesForce = () => {
  return manageApi.loadFeatures().then((features) => {
    dispatcher.handleViewAction({
      type: ActionTypes.LOAD_FEATURES_SUCCESS,
      features,
    });
  });
};

export {
  generateSessionToken,
  loadProject,
  loadOrganizationsViaMaintainer,
  loadOrganizations,
  loadUsers,
  inviteUser,
  cancelInvitation,
  approveUser,
  rejectUser,
  removeUser,
  updateProject,
  removeProject,
  addProjectFeature,
  removeProjectFeature,
  addUserFeature,
  removeUserFeature,
  changeOrganization,
  loadFeatures,
};
