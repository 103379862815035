import { List, Map } from 'immutable';

export const endpointTypes = {
  DATA_API: 'data-api',
  ENDPOINT_REPORT: 'reports',
  ENDPOINT_MCF: 'mcf',
};

export const DEFAULT_DATE_RANGE = Map({
  startDate: '-4 days',
  endDate: 'today',
});

export const DEFAULT_DATE_RANGES = List([DEFAULT_DATE_RANGE]);

export const LAST_RUN_DATE = 'lastRunDate';

export const MCF_DIMENSIONS = [
  'mcf:basicChannelGroupingPath',
  'mcf:sourcePath',
  'mcf:mediumPath',
  'mcf:sourceMediumPath',
  'mcf:campaignPath',
  'mcf:keywordPath',
  'mcf:adwordsAdContentPath',
  'mcf:adwordsAdGroupIDPath',
  'mcf:adwordsAdGroupPath',
  'mcf:adwordsCampaignIDPath',
  'mcf:adwordsCampaignPath',
  'mcf:adwordsCreativeIDPath',
  'mcf:adwordsCriteriaIDPath',
  'mcf:adwordsCustomerIDPath',
  'mcf:adwordsDestinationUrlPath',
  'mcf:adwordsDisplayUrlPath',
  'mcf:adwordsKeywordPath',
  'mcf:adwordsMatchedSearchQueryPath',
  'mcf:adwordsPlacementDomainPath',
  'mcf:adwordsPlacementUrlPath',
  'mcf:conversionDate',
  'mcf:conversionGoalNumber',
  'mcf:conversionType',
  'mcf:dcmAd',
  'mcf:dcmAdPath',
  'mcf:dcmAdType',
  'mcf:dcmAdvertiser',
  'mcf:dcmAdvertiserPath',
  'mcf:dcmCampaign',
  'mcf:dcmCampaignPath',
  'mcf:dcmCreative',
  'mcf:dcmCreativePath',
  'mcf:dcmCreativeVersion',
  'mcf:dcmCreativeVersionPath',
  'mcf:dcmNetwork',
  'mcf:dcmPlacement',
  'mcf:dcmPlacementPath',
  'mcf:dcmSite',
  'mcf:dcmSitePath',
  'mcf:pathLengthInInteractionsHistogram',
  'mcf:timeLagInDaysHistogram',
  'mcf:basicChannelGrouping',
  'mcf:source',
  'mcf:medium',
  'mcf:sourceMedium',
  'mcf:campaignName',
  'mcf:keyword',
  'mcf:transactionId',
  'mcf:adwordsAdContent',
  'mcf:adwordsAdGroup',
  'mcf:adwordsAdGroupID',
  'mcf:adwordsAdNetworkType',
  'mcf:adwordsCampaign',
  'mcf:adwordsCampaignID',
  'mcf:adwordsCreativeID',
  'mcf:adwordsCriteriaID',
  'mcf:adwordsCustomerID',
  'mcf:adwordsDestinationUrl',
  'mcf:adwordsDisplayUrl',
  'mcf:adwordsKeyword',
  'mcf:adwordsMatchedSearchQuery',
  'mcf:adwordsMatchType',
  'mcf:adwordsPlacementDomain',
  'mcf:adwordsPlacementType',
  'mcf:adwordsPlacementUrl',
  'mcf:adwordsTargetingType',
  'mcf:nthDay',
];

export const MCF_METRICS = [
  'mcf:firstImpressionConversions',
  'mcf:firstImpressionValue',
  'mcf:impressionAssistedConversions',
  'mcf:impressionAssistedValue',
  'mcf:totalConversions',
  'mcf:totalConversionValue',
  'mcf:assistedConversions',
  'mcf:assistedValue',
  'mcf:firstInteractionConversions',
  'mcf:firstInteractionValue',
  'mcf:lastInteractionConversions',
  'mcf:lastInteractionValue',
];

export const PREFERED_METRICS = [
  'ga:sessions',
  'ga:users',
  'ga:transactions',
  'ga:pageviews',
  'ga:uniquePageviews',
  'ga:sessionDuration',
  'ga:newUsers',
  'ga:impressions',
  'ga:transactionRevenue',
  'ga:adClicks',
  'ga:adCost',
  'ga:bounces',
  'ga:bounceRate',
];

export const PREFERED_DIMENSIONS = [
  'ga:segment',
  'ga:date',
  'ga:medium',
  'ga:sourceMedium',
  'ga:source',
  'ga:campaign',
  'ga:yearMonth',
  'ga:isoYearIsoWeek',
  'ga:transactionId',
  'ga:adwordsCampaignID',
  'ga:deviceCategory',
  'ga:keyword',
];

export const PREFERED_DATA_API_METRICS = [
  'sessions',
  'totalUsers',
  'transactions',
  'screenPageViews',
  'userEngagementDuration',
  'newUsers',
  'publisherAdImpressions',
  'averagePurchaseRevenue',
  'publisherAdClicks',
];

export const PREFERED_DATA_API_DIMENSTIONS = [
  'date',
  'firstUserMedium',
  'sessionMedium',
  'sessionSource',
  'firstUserSource',
  'campaignName',
  'year',
  'month',
  'transactionId',
  'deviceCategory',
  'googleAdsKeyword',
];
export const PREDEFINED_QUERIES = {
  //Definition of GA use cases for OLD version AP

  OLD_API: {
    'conversions-ecommerce-overview': [
      {
        outputTable: 'conversions_ecommerce_overview',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:productName',
            },
            {
              name: 'ga:date',
            },
          ],
          metrics: [
            {
              expression: 'ga:itemRevenue',
            },
            {
              expression: 'ga:uniquePurchases',
            },
            {
              expression: 'ga:itemQuantity',
            },
            {
              expression: 'ga:revenuePerItem',
            },
            {
              expression: 'ga:itemsPerPurchase',
            },
            {
              expression: 'ga:productRefundAmount',
            },
            {
              expression: 'ga:cartToDetailRate',
            },
            {
              expression: 'ga:buyToDetailRate',
            },
          ],
        },
      },
    ],
    'conversions-ecommerce-product-performance': [
      {
        outputTable: 'conversions_ecommerce_product-performance',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:productName',
            },
            {
              name: 'ga:date',
            },
          ],
          metrics: [
            {
              expression: 'ga:itemRevenue',
            },
            {
              expression: 'ga:uniquePurchases',
            },
            {
              expression: 'ga:itemQuantity',
            },
            {
              expression: 'ga:revenuePerItem',
            },
            {
              expression: 'ga:itemsPerPurchase',
            },
            {
              expression: 'ga:productRefundAmount',
            },
            {
              expression: 'ga:productListViews',
            },
            {
              expression: 'ga:productDetailViews',
            },
            {
              expression: 'ga:productCheckouts',
            },
            {
              expression: 'ga:productRevenuePerPurchase',
            },
          ],
        },
      },
    ],
    'conversions-multi-channel-funnels-model-comparison-tool': [
      {
        outputTable: 'conversions_multi-channel-funnels_model-comparison-tool',
        endpoint: endpointTypes.ENDPOINT_MCF,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'mcf:basicChannelGrouping',
            },
            {
              name: 'mcf:conversionDate',
            },
          ],
          metrics: [
            {
              expression: 'mcf:lastInteractionConversions',
            },
            {
              expression: 'mcf:lastInteractionValue',
            },
            {
              expression: 'mcf:totalConversions',
            },
            {
              expression: 'mcf:totalConversionValue',
            },
          ],
        },
      },
    ],
    'audience-overview': [
      {
        outputTable: 'audience_overview',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:date',
            },
            {
              name: 'ga:language',
            },
            {
              name: 'ga:country',
            },
            {
              name: 'ga:city',
            },
            {
              name: 'ga:deviceCategory',
            },
          ],
          metrics: [
            {
              expression: 'ga:users',
            },
            {
              expression: 'ga:newUsers',
            },
            {
              expression: 'ga:sessions',
            },
            {
              expression: 'ga:sessionsPerUser',
            },
            {
              expression: 'ga:pageviews',
            },
            {
              expression: 'ga:pageviewsPerSession',
            },
            {
              expression: 'ga:avgSessionDuration',
            },
            {
              expression: 'ga:bounceRate',
            },
          ],
        },
      },
    ],
    'behavior-site-content-All-Pages': [
      {
        outputTable: 'behavior_site-content_all-pages',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:pagePath',
            },
          ],
          metrics: [
            {
              expression: 'ga:pageviews',
            },
            {
              expression: 'ga:uniquePageviews',
            },
            {
              expression: 'ga:avgTimeOnPage',
            },
            {
              expression: 'ga:entrances',
            },
            {
              expression: 'ga:bounceRate',
            },
            {
              expression: 'ga:exitRate',
            },
            {
              expression: 'ga:pageValue',
            },
          ],
        },
      },
    ],
    'acquisition-all-traffic-source-medium': [
      {
        outputTable: 'acquisition_all-traffic_source-medium',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:sourceMedium',
            },
            {
              name: 'ga:date',
            },
            {
              name: 'ga:campaign',
            },
          ],
          metrics: [
            {
              expression: 'ga:users',
            },
            {
              expression: 'ga:newUsers',
            },
            {
              expression: 'ga:sessions',
            },
            {
              expression: 'ga:bounceRate',
            },
            {
              expression: 'ga:pageviewsPerSession',
            },
            {
              expression: 'ga:avgSessionDuration',
            },
            {
              expression: 'ga:goalConversionRateAll',
            },
            {
              expression: 'ga:goalCompletionsAll',
            },
            {
              expression: 'ga:goalValueAll',
            },
          ],
        },
      },
    ],
    'conversion-goals-overview': [
      {
        outputTable: 'conversion_goals_overview',
        endpoint: endpointTypes.ENDPOINT_REPORT,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'ga:date',
            },
          ],
          metrics: [
            {
              expression: 'ga:goalCompletionsAll',
            },
            {
              expression: 'ga:goalValueAll',
            },
            {
              expression: 'ga:goalConversionRateAll',
            },
          ],
        },
      },
    ],
  },

  //Definition of GA use cases for NEW version API

  NEW_API: {
    'acquisition-user-acquisition': [
      {
        outputTable: 'acquisition_user-acquisition',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
            {
              name: 'medium',
            },
          ],
          metrics: [
            {
              name: 'newUsers',
            },
            {
              name: 'engagedSessions',
            },
            {
              name: 'engagementRate',
            },
            {
              name: 'sessionsPerUser',
            },
            {
              name: 'userEngagementDuration',
            },
            {
              name: 'eventCount',
            },
            {
              name: 'conversions',
            },
            {
              name: 'totalRevenue',
            },
          ],
        },
      },
    ],
    'engagement-overview': [
      {
        outputTable: 'engagement_overview',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'eventName',
            },
            {
              name: 'pagePath',
            },
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'userEngagementDuration',
            },
            {
              name: 'engagedSessions',
            },
            {
              name: 'transactions',
            },
            {
              name: 'eventCount',
            },
          ],
        },
      },
    ],
    'engagement-events': [
      {
        outputTable: 'engagement_events',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'eventName',
            },
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'eventCount',
            },
            {
              name: 'totalUsers',
            },
            {
              name: 'eventCountPerUser',
            },
            {
              name: 'totalRevenue',
            },
          ],
        },
      },
    ],
    'monetization-overview': [
      {
        outputTable: 'monetization_overview',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'itemName',
            },
            {
              name: 'itemCategory',
            },
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'totalRevenue',
            },
            {
              name: 'totalAdRevenue',
            },
            {
              name: 'totalPurchasers',
            },
            {
              name: 'firstTimePurchasers',
            },
            {
              name: 'averageRevenuePerUser',
            },
          ],
        },
      },
    ],
    'demographic-overview': [
      {
        outputTable: 'demographic_overview',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'country',
            },
            {
              name: 'city',
            },
            {
              name: 'userGender',
            },
            {
              name: 'brandingInterest',
            },
            {
              name: 'userAgeBracket',
            },
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'totalUsers',
            },
          ],
        },
      },
    ],
    'demographic-details': [
      {
        outputTable: 'demographic_details',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'country',
            },
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'totalUsers',
            },
            {
              name: 'newUsers',
            },
            {
              name: 'engagedSessions',
            },
            {
              name: 'engagementRate',
            },
            {
              name: 'sessionsPerUser',
            },
            {
              name: 'userEngagementDuration',
            },
            {
              name: 'eventCount',
            },
            {
              name: 'conversions',
            },
            {
              name: 'totalRevenue',
            },
          ],
        },
      },
    ],
    'tech-overview': [
      {
        outputTable: 'tech_overview',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
            {
              name: 'platform',
            },
            {
              name: 'deviceCategory',
            },
            {
              name: 'browser',
            },
            {
              name: 'operatingSystem',
            },
            {
              name: 'screenResolution',
            },
          ],
          metrics: [
            {
              name: 'totalUsers',
            },
          ],
        },
      },
    ],
    'acquisition-overview': [
      {
        outputTable: 'acquisition_overview',
        endpoint: endpointTypes.DATA_API,
        query: {
          dateRanges: [
            {
              endDate: 'today',
              startDate: LAST_RUN_DATE,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
            {
              name: 'firstUserMedium',
            },
            {
              name: 'country',
            },
          ],
          metrics: [
            {
              name: 'totalUsers',
            },
            {
              name: 'newUsers',
            },
            {
              name: 'sessions',
            },
          ],
        },
      },
    ],
  },
};
