import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { List, Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import TransformationTabs from '@/modules/components/react/pages/TransformationTabs';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames } from '@/modules/transformations-v2/constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import DescriptionButton from '@/react/common/ConfigurationsTable/DescriptionButton';
import ConfirmModal from '@/react/common/ConfirmModal';
import FilterPanel from '@/react/common/FilterPanel';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import NoEntityCreated from '@/react/common/NoEntityCreated';
import NoResultsFound from '@/react/common/NoResultsFound';
import Link from '@/react/common/RouterLink';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import TimeAndUser from '@/react/common/TimeAndUser';
import Truncated from '@/react/common/Truncated';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import string from '@/utils/string';
import CodeUsagesPanel from './components/CodeUsagesPanel';
import CreateSharedCodeFromSourceModal from './components/CreateSharedCodeFromSourceModal';
import SharedCodesActions from './Actions';
import { getConfigurations } from './helpers';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      InstalledComponentsStore,
      ComponentsStore,
      DevBranchesStore,
      StackFeaturesStore,
    ),
  ],

  getStateFromStores() {
    return {
      component: ComponentsStore.getComponent(KEBOOLA_SHARED_CODE),
      sharedCodes: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE),
      admins: ApplicationStore.getAdmins(),
      readOnly: ApplicationStore.isReadOnly(),
      components: InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      isDeleting: Map(),
      sharedCodeDetail: Map(),
      copySource: Map(),
      showCopyModal: false,
      showDeleteModal: false,
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    return (
      <TransformationTabs activeTab={routeNames.SHARED_CODES}>
        <FilterPanel
          placeholder={this.getPlaceholder}
          query={this.state.filterQuery}
          onChange={(query) => {
            this.setState({ filterQuery: query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        {this.renderSharedCodes()}
        {this.renderCopyModal()}
        {this.renderDeleteModal()}
      </TransformationTabs>
    );
  },

  renderSharedCodes() {
    let filteredSharedCodes = this.state.sharedCodes.filter(
      (config) => !config.get('rows', List()).isEmpty(),
    );

    if (this.state.filterQuery) {
      filteredSharedCodes = filteredSharedCodes
        .map((sharedCode) => {
          return sharedCode.update('rows', List(), (rows) =>
            rows.filter((row) => matchByWords(row.get('name'), this.state.filterQuery)),
          );
        })
        .filter((config) => !config.get('rows', List()).isEmpty());
    }

    if (this.state.filterQuery && filteredSharedCodes.isEmpty()) {
      return <NoResultsFound entityName="shared code" />;
    }

    if (filteredSharedCodes.isEmpty()) {
      return <NoEntityCreated entityName="shared code" />;
    }

    return (
      <div className="box mb-2">
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <div className="th">Name</div>
              <div className="th w-150 text-right">Used by</div>
              <div className="th w-280 text-right">Created</div>
            </div>
          </div>
          <div className="tbody">{filteredSharedCodes.map(this.renderSharedCode).toArray()}</div>
        </div>
      </div>
    );
  },

  renderCopyModal() {
    return (
      <CreateSharedCodeFromSourceModal
        show={this.state.showCopyModal}
        onSubmit={(type, name, code, variables) => {
          return SharedCodesActions.createSharedCode(type, name, code, variables).then(
            (newConfig) => {
              RoutesStore.getRouter().transitionTo(routeNames.SHARED_CODE, {
                config: newConfig.sharedCodesConfigurationId,
                row: newConfig.sharedCodesConfigurationRowId,
              });
              return newConfig;
            },
          );
        }}
        onHide={() => this.setState({ showCopyModal: false, copySource: Map() })}
        sourceCode={this.state.copySource}
        copy
      />
    );
  },

  renderDeleteModal() {
    const componentId = this.state.sharedCodeDetail.get('type');
    const usedInConfigurations = getConfigurations(
      this.state.components,
      Map({
        type: componentId,
        rowId: this.state.sharedCodeDetail.get('rowId'),
        name: this.state.sharedCodeDetail.get('name'),
      }),
    );

    return (
      <ConfirmModal
        show={this.state.showDeleteModal}
        onHide={() => this.setState({ showDeleteModal: false })}
        icon="trash"
        buttonType="danger"
        buttonLabel="Delete Code"
        title="Delete Code"
        text={
          <>
            <p>
              Are you sure you want to delete the code{' '}
              <b>{this.state.sharedCodeDetail.get('name')}</b>?
            </p>
            {usedInConfigurations.count() > 0 && (
              <>
                <Alert variant="warning" className="tw-mb-5">
                  There are transformations that use this code. They will no longer work.
                </Alert>
                <CodeUsagesPanel
                  configurations={usedInConfigurations}
                  componentId={componentId}
                  hasFlows={this.state.hasFlows}
                />
              </>
            )}
          </>
        }
        onConfirm={this.handleDelete}
        isDisabled={this.state.isDeleting.has(this.state.sharedCodeDetail.get('rowId'))}
        loadOnEnter={() => componentsActions.loadComponentConfigsData(componentId)}
      />
    );
  },

  renderSharedCode(sharedCode) {
    const component = this.state.components.get(sharedCode.getIn(['configuration', 'componentId']));

    return sharedCode
      .get('rows', List())
      .map((row) => {
        const componentId = sharedCode.getIn(['configuration', 'componentId']);
        const usedInConfigurations = getConfigurations(
          this.state.components,
          Map({
            type: componentId,
            rowId: row.get('id'),
            name: row.get('name'),
          }),
        );

        return (
          <Link
            key={row.get('id')}
            className="tr hoverable-actions-with-replacement"
            to={routeNames.SHARED_CODE}
            params={{
              config: sharedCode.get('id'),
              row: row.get('id'),
            }}
          >
            <div className="td">
              <div className="flex-container flex-start">
                <ComponentIcon
                  className="icon-addon-right align-start"
                  component={component}
                  size="36"
                />
                <div>
                  <Truncated className="component-name" text={row.get('name')} />
                  <span className="f-13 text-muted">
                    <InlineDescriptionEditInput
                      entity="Shared Code"
                      description={row.get('description', '')}
                      onSave={(newDescription) => {
                        return ConfigurationRowsActionCreators.updateSimple(
                          KEBOOLA_SHARED_CODE,
                          sharedCode.get('id'),
                          row.get('id'),
                          { description: newDescription },
                          'Update row description',
                        );
                      }}
                      readOnly={this.state.readOnly}
                      component={component}
                      config={sharedCode}
                      row={row}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="td text-right text-muted">
              {usedInConfigurations.size > 0
                ? `${usedInConfigurations.size} ${string.pluralize(
                    usedInConfigurations.size,
                    'configuration',
                  )}`
                : 'None'}
            </div>
            {this.state.readOnly ? (
              <div className="td w-280 text-right">{this.renderLastChanged(row)}</div>
            ) : (
              <div className="td w-280 no-wrap">
                <div className="actions-container">
                  <div className="not-actions">{this.renderLastChanged(row)}</div>
                  <div className="actions">
                    {this.renderDropdownActions(componentId, row, sharedCode)}
                  </div>
                </div>
              </div>
            )}
          </Link>
        );
      })
      .toArray();
  },

  renderLastChanged(row) {
    return (
      <TimeAndUser
        admin={this.state.admins.get(row.getIn(['creatorToken', 'description']))}
        time={row.get('created')}
        fallbackName={row.getIn(['creatorToken', 'description'])}
      />
    );
  },

  renderDropdownActions(componentId, row, sharedCode) {
    return (
      <RowActionDropdown>
        <RowActionMenuItem
          onSelect={() =>
            this.setState({
              showCopyModal: true,
              copySource: Map({
                type: componentId,
                name: row.get('name'),
                code: row.getIn(['configuration', 'code_content']),
                variablesId: row.getIn(['configuration', 'variables_id'], false),
              }),
            })
          }
        >
          <FontAwesomeIcon icon="clone" fixedWidth />
          Copy code
        </RowActionMenuItem>
        <DescriptionButton component={this.state.component} configuration={sharedCode} row={row} />
        <RowActionMenuItem divider />
        <RowActionMenuItem
          onSelect={() => {
            this.setState({
              showDeleteModal: true,
              sharedCodeDetail: Map({
                type: componentId,
                configId: sharedCode.get('id'),
                rowId: row.get('id'),
                name: row.get('name'),
                variablesId: row.getIn(['configuration', 'variables_id'], false),
              }),
            });
          }}
        >
          <FontAwesomeIcon icon="trash" fixedWidth />
          Delete code
        </RowActionMenuItem>
      </RowActionDropdown>
    );
  },

  getPlaceholder() {
    return `Search shared code (${this.state.sharedCodes
      .toList()
      .flatMap((sharedCode) => sharedCode.get('rows'))
      .count()})`;
  },

  openDeleteModal(componentId, sharedCode, row) {
    this.setState({
      showDeleteModal: true,
      sharedCodeDetail: Map({
        type: componentId,
        configId: sharedCode.get('id'),
        rowId: row.get('id'),
        name: row.get('name'),
        variablesId: row.getIn(['configuration', 'variables_id'], false),
      }),
    });
  },

  handleDelete() {
    this.setState({
      isDeleting: this.state.isDeleting.set(this.state.sharedCodeDetail.get('rowId'), true),
    });
    return SharedCodesActions.deleteSharedCode(
      this.state.sharedCodeDetail.get('configId'),
      this.state.sharedCodeDetail.get('rowId'),
      this.state.sharedCodeDetail.get('variablesId'),
    ).then(() => {
      this.setState({
        isDeleting: this.state.isDeleting.delete(this.state.sharedCodeDetail.get('rowId')),
      });
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Shared code <b>{this.state.sharedCodeDetail.get('name')}</b> has been deleted.
          </>
        ),
      });
    });
  },
});

export default Index;
