import React from 'react';
import Immutable from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { constants } from '@/modules/configurations/utils/configurationState';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import CreatedDate from './CreatedDate';
import ResetAutomaticLoadTypeButton from './ResetAutomaticLoadTypeButton';

const AutomaticLoadTypeLastUpdated = ({ tableId }: { tableId: string }) => {
  const storeState = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getConfigId();
      const rowId = RoutesStore.getRowId();

      const configuration = rowId
        ? ConfigurationRowsStore.get(componentId, configId, rowId)
        : InstalledComponentsStore.getConfig(componentId, configId);

      const configurationState = configuration.get(
        'state',
        Immutable.Map<string, unknown>(),
      ) as Immutable.Map<string, unknown>;

      const tableState = configurationState
        .getIn(
          [constants.STORAGE_NAMESPACE, constants.INPUT_NAMESPACE, constants.TABLES_NAMESPACE],
          Immutable.Map(),
        )
        .find((item: Immutable.Map<string, any>) => item.get('source') === tableId);

      return {
        componentId,
        configId,
        rowId,
        table: StorageTablesStore.getTable(tableId),
        tableState: tableState,
      };
    },
    [tableId],
    [RoutesStore, InstalledComponentsStore, ConfigurationRowsStore, StorageTablesStore],
  );

  const resetState = () => {
    if (storeState.rowId) {
      return ConfigurationRowActionCreators.clearInputMappingState(
        storeState.componentId,
        storeState.configId,
        storeState.rowId,
        tableId,
      );
    }

    return InstalledComponentsActionCreators.clearInputMappingState(
      storeState.componentId,
      storeState.configId,
      tableId,
    );
  };

  if (!storeState.table) {
    return null;
  }

  if (!storeState.tableState || !storeState.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY)) {
    return <div className="help-block">Table has not been loaded yet.</div>;
  }

  if (
    storeState.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY) ===
    storeState.table.get(constants.LAST_IMPORT_DATE_PROPERTY)
  ) {
    return (
      <div className="help-block">
        Table contains no new data. <ResetAutomaticLoadTypeButton onClick={resetState} />
      </div>
    );
  }

  return (
    <div className="help-block">
      Source table updated since the previous update{' '}
      <CreatedDate createdTime={storeState.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY)} />
      . <ResetAutomaticLoadTypeButton onClick={resetState} />
    </div>
  );
};

export default AutomaticLoadTypeLastUpdated;
