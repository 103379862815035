import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { ioType } from '@/modules/components/Constants';
import { getDefaultBucketName, getDefaultTableName } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import TableOutputMappingModal from '@/modules/components/react/components/generic/TableOutputMappingModal';
import { runComponent } from '@/modules/simplified-ui/actions';

class SimplifiedTableOutputMapping extends React.Component {
  static propTypes = {
    allBuckets: PropTypes.instanceOf(Map).isRequired,
    allTables: PropTypes.instanceOf(Map).isRequired,
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    configName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    commonState: PropTypes.object.isRequired,
    isHeaderButton: PropTypes.bool,
  };

  render() {
    const configuredTables = this.props.configData.getIn(['storage', 'output', 'tables'], List());

    return (
      <div className="simplified-table-output-mapping flex-container align-top">
        <div className="flex-container fill-space">
          {!this.props.readOnly && (
            <TableOutputMappingModal
              simple
              isEdit={!configuredTables.isEmpty()}
              componentId={this.props.componentId}
              mapping={
                !configuredTables.isEmpty()
                  ? configuredTables.first()
                  : Map({ source: 'model_last' })
              }
              buckets={this.props.allBuckets}
              tables={this.props.allTables}
              onSave={this.handleMappingAdd}
              sourceType={ioType.TABLE}
              defaultBucketName={getDefaultBucketName(this.props.configName)}
              defaultTableName={getDefaultTableName(this.props.configName)}
              renderCustomOpenButton={(onClickHandler) =>
                configuredTables.isEmpty() ? (
                  <Button block bsStyle="success" onClick={onClickHandler}>
                    <FontAwesomeIcon icon="warehouse" className="icon-addon-right" />
                    Save To Storage
                  </Button>
                ) : (
                  <Button bsSize="sm" className="ml-1" onClick={onClickHandler}>
                    <FontAwesomeIcon icon="pen" className="icon-addon-right" />
                    Edit Result
                  </Button>
                )
              }
            />
          )}
        </div>
      </div>
    );
  }

  handleMappingAdd = (editing) => {
    const configData = this.props.configData.setIn(
      ['storage', 'output', 'tables'],
      List([editing]),
    );

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      configData,
      `Update output table ${editing.get('destination')}`,
    ).then(() => {
      return runComponent(this.props.componentId, this.props.configId, this.props.hasNewQueue);
    });
  };
}

export default SimplifiedTableOutputMapping;
