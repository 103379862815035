import React, { useContext, useState } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { domToBlob } from 'modern-screenshot';

import { GraphContext } from '@/modules/lineage/components/GraphContext';
import { getExportOptions } from '@/modules/lineage/helpers';
import Loader from '@/react/common/Loader';
import contactSupport from '@/utils/contactSupport';

const NodeDropdown = ({
  onOpenSidebar,
  onHighlight,
  graphId = null,
  nodeId,
}: {
  onHighlight?: (nodeId: string) => void;
  onOpenSidebar?: (nodeId: string) => void;
  graphId?: string | null;
  nodeId: string;
}) => {
  const dropdownId = React.useId();

  const { nodeDetail, focusedNodeId } = useContext(GraphContext);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleReportBug = () => {
    if (!graphId) {
      return;
    }

    const { flowPane, ...options } = getExportOptions(graphId);

    if (!flowPane) {
      console.error('cannot export, viewport is missing');
      return;
    }

    setIsProcessing(true);
    domToBlob(flowPane, { ...options })
      .then((result) =>
        contactSupport({
          ticketContext: { screenshot: result },
        }),
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <Dropdown id={dropdownId} className="row-dropdown">
      <Dropdown.Toggle
        noCaret
        bsStyle="link"
        disabled={isProcessing}
        className={classNames(
          { 'tw-invisible': !isProcessing },
          '!tw-py-0 !tw-pl-3 !tw-pr-2 group-hover/node:tw-visible',
        )}
      >
        {isProcessing ? (
          <Loader />
        ) : (
          <FontAwesomeIcon icon="ellipsis-vertical" className="!tw-text-2xl" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-icon">
        {onOpenSidebar && (
          <MenuItem disabled={nodeId === nodeDetail?.fqid} onClick={() => onOpenSidebar(nodeId)}>
            View Detail
          </MenuItem>
        )}
        {onHighlight && (
          <MenuItem disabled={nodeId === focusedNodeId} onClick={() => onHighlight(nodeId)}>
            Focus on Node
          </MenuItem>
        )}
        {graphId && <MenuItem divider className="!tw-m-2 !tw-bg-neutral-200" />}
        {graphId && <MenuItem onClick={handleReportBug}>Report Lineage Bug</MenuItem>}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NodeDropdown;
