import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';

import Confirm from '@/react/common/Confirm';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import SliderPicker from '@/react/common/SliderPicker';

const OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];
type optionValue = (typeof OPTIONS)[number];

const ThreadsModal = ({
  onChange,
  savedValue,
  readOnly,
}: {
  onChange: (selectedValue: optionValue) => Promise<void>;
  savedValue: optionValue;
  readOnly: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState(savedValue);
  const [isSaving, setIsSaving] = useState(false);

  const onConfirm = () => {
    setIsSaving(true);
    return onChange(selectedValue).finally(() => setIsSaving(false));
  };

  return (
    <Confirm
      closeAfterResolve
      icon="line-height"
      title="Threads"
      onConfirm={onConfirm}
      onEnter={() => setSelectedValue(savedValue)}
      text={
        <>
          <p>Set number of threads.</p>
          <SliderPicker options={OPTIONS} value={selectedValue} onChange={setSelectedValue} />
        </>
      }
      buttonType="success"
      buttonLabel="Set threads"
      childrenRootElement="button"
      childrenRootElementClass="btn btn-link btn-block"
      isLoading={isSaving}
      isDisabledConfirmButton={selectedValue === savedValue}
      isDisabled={readOnly}
      {...(readOnly && { disabledReason: READ_ONLY_TOOLTIP_MESSAGE })}
    >
      <FontAwesomeIcon icon="line-height" className="icon-addon-right" />
      Threads: <span className="tw-font-medium">{savedValue}</span>
    </Confirm>
  );
};

export default ThreadsModal;
