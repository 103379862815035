import { List, Map } from 'immutable';

import { KEBOOLA_CSV_IMPORT } from '@/constants/componentIds';
import { removeDevBranchReferenceFromTable } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import getDefaultBucket from '@/utils/getDefaultBucket';

export const getDefaultTable = function (configId: string) {
  if (!ApplicationStore.hasDisableLegacyBucketPrefix()) {
    return 'in.c-csv-import.' + configId;
  }

  return `${getDefaultBucket('in', KEBOOLA_CSV_IMPORT, configId)}.data`;
};

export const createConfiguration = function (
  settings: Map<string, any>,
  configId: string,
  currentBranchId?: string | number | null | undefined,
) {
  let config = Map();

  if (settings.get('destination') && settings.get('destination') !== '') {
    config = config.set(
      'destination',
      currentBranchId
        ? removeDevBranchReferenceFromTable(settings.get('destination'), currentBranchId)
        : settings.get('destination'),
    );
  } else {
    config = config.set('destination', getDefaultTable(configId));
  }

  config = config.set('incremental', settings.get('incremental', false));

  if (settings.get('primaryKey') && settings.get('primaryKey').count() > 0) {
    config = config.set('primaryKey', settings.get('primaryKey'));
  } else {
    config = config.set('primaryKey', List());
  }

  if (settings.get('delimiter') && settings.get('delimiter') !== '') {
    config = config.set('delimiter', settings.get('delimiter'));
  } else {
    config = config.set('delimiter', ',');
  }

  if (settings.has('enclosure')) {
    config = config.set('enclosure', settings.get('enclosure'));
  } else {
    config = config.set('enclosure', '"');
  }
  return config;
};

export default {
  getDefaultTable: getDefaultTable,
  createConfiguration: createConfiguration,
};
