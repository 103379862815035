import React from 'react';
import type { Map } from 'immutable';

import type { ConfigRow } from './constants';
import LastChange from './LastChange';

const LastChangeActionCell = (props: {
  admins: Map<string, any>;
  row: ConfigRow;
  renderAction: (row: ConfigRow, body: React.ReactNode) => React.ReactNode;
}) => {
  return props.renderAction(
    props.row,
    <LastChange configuration={props.row.original.data.config} admins={props.admins} />,
  );
};

const MemoizedLastChangeActionCell = React.memo(LastChangeActionCell);

export default MemoizedLastChangeActionCell;
