import React from 'react';
import type { List, Map, OrderedMap } from 'immutable';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import Select from '@/react/common/Select';

const getValue = (ids: string, allValues: string) => {
  if (ids === '') {
    return allValues;
  }

  return ids;
};

type Props = {
  query: Map<string, any>;
  accounts: OrderedMap<string, any>;
  readOnly: boolean;
  storedIds: string;
  updateLocalState: (path: string[], newValue: Map<string, any>) => void;
};

const AccountsSetting = ({ query, readOnly, accounts, updateLocalState }: Props) => {
  const ids = query.get('ids');
  const selectOptions = accounts
    .map((account, accountId) => {
      return { value: accountId as string, label: account.get('name') as string };
    })
    .toArray();

  const allValues = selectOptions.map((i) => i.value).join(',');
  const value = getValue(ids, allValues);

  const onSelectAccount = (selectedValue: string) => {
    if (!selectedValue) {
      return updateLocalState(['query'], query.delete('ids'));
    }

    if (selectedValue === allValues) {
      return updateLocalState(['query'], query.set('ids', ''));
    }

    return updateLocalState(['query'], query.set('ids', selectedValue));
  };

  return (
    <CollapsibleBox title="Accounts" defaultOpen>
      <Select
        multi
        value={value?.split(',')}
        options={selectOptions}
        clearable={false}
        disabled={readOnly}
        onChange={(values: List<string>) => onSelectAccount(values.join(','))}
      />
    </CollapsibleBox>
  );
};

export default AccountsSetting;
