import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from '@keboola/constants';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { HelpBlock, Link } from 'design';

import {
  KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_R_TRANSFORMATION_V_2,
} from '@/constants/componentIds';
import { transformationType } from '@/modules/transformations/Constants';
import Select from '@/react/common/Select';

const TRANSFORMATIONS_LINK_ROOT = `${URLS.USER_DOCUMENTATION}/transformations`;

const Packages = createReactClass({
  propTypes: {
    packages: PropTypes.object.isRequired,
    onEditChange: PropTypes.func.isRequired,
    transformationType: PropTypes.string,
    componentId: PropTypes.string,
    disabled: PropTypes.bool,
    forGenericComponents: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      disabled: false,
      forGenericComponents: false,
    };
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  render() {
    const isGeneric = this.props.forGenericComponents;

    return (
      <div className="box">
        <div
          className={classnames(
            'box-header',
            isGeneric ? 'smaller ptp-4 prp-1 plp-6' : 'big-padding with-border',
          )}
        >
          <h2 className={classnames('box-title', { 'line-height-24': isGeneric })}>Packages</h2>
        </div>
        <div className={classnames('box-content', { 'ptp-2 prp-6 pbp-4 plp-6': isGeneric })}>
          <Select
            multi
            allowCreate
            trimMultiCreatedValues
            value={this.props.packages}
            placeholder="Add packages"
            onChange={this.handleChange}
            promptTextCreator={(label) => `Add package "${label}"`}
            disabled={this.state.isSaving || this.props.disabled}
          />
          <HelpBlock>
            For more information about package installation and usage, and the list of pre-installed
            packages, see our{' '}
            <Link href={this.props.componentId ? this.resolveLink() : this.resolveLegacyLink()}>
              documentation
            </Link>
            .
          </HelpBlock>
        </div>
      </div>
    );
  },

  handleChange(packages) {
    this.setState({ isSaving: true });
    this.props
      .onEditChange(packages.toSet().toList())
      .finally(() => this.setState({ isSaving: false }));
  },

  resolveLink() {
    switch (this.props.componentId) {
      case KEBOOLA_R_TRANSFORMATION_V_2:
        return `${TRANSFORMATIONS_LINK_ROOT}/r-plain/#packages`;

      case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
      case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
      case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
        return `${TRANSFORMATIONS_LINK_ROOT}/python-plain/#packages`;

      default:
        return TRANSFORMATIONS_LINK_ROOT;
    }
  },

  resolveLegacyLink() {
    switch (this.props.transformationType) {
      case transformationType.R:
        return `${TRANSFORMATIONS_LINK_ROOT}/r/#packages`;

      case transformationType.PYTHON:
        return `${TRANSFORMATIONS_LINK_ROOT}/python/#packages`;

      case transformationType.JULIA:
        return `${TRANSFORMATIONS_LINK_ROOT}/julia/#packages`;

      default:
        return TRANSFORMATIONS_LINK_ROOT;
    }
  },
});

export default Packages;
