import { fromJS, List } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        tableId: localState.get('tableId', ''),
        datasetId: localState.get('datasetId', ''),
        columns: localState.get('columns', []),
      },
    },
  });
};

const parseConfiguration = (configData) => {
  return fromJS({
    tableId: configData.getIn(['parameters', 'query', 'tableId'], ''),
    datasetId: configData.getIn(['parameters', 'query', 'datasetId'], ''),
    columns: configData.getIn(['parameters', 'query', 'columns'], List()),
  });
};

const createEmptyConfiguration = () => {
  return createConfiguration(fromJS({}));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
