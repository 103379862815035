import Promise from 'bluebird';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import TriggersStore from '@/modules/components/stores/StorageTriggersStore';
import { removeScheduler } from '@/modules/scheduler/actions';
import RoutesStore from '@/stores/RoutesStore';
import { actionTypes, routeNames } from './constants';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadOrchestration = (configId) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_ORCHESTRATOR, configId);
};

const createOrchestration = (name, description = '') => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_ORCHESTRATOR, {
    name,
    description,
  }).then((response) => {
    RoutesStore.getRouter().transitionTo(routeNames.DETAIL, { config: response.id });
    return null;
  });
};

const deleteOrchestration = (orchestration, options = {}) => {
  return InstalledComponentsActionCreators.deleteConfiguration(
    KEBOOLA_ORCHESTRATOR,
    orchestration.get('id'),
    { transition: false, notification: true, ...options },
  ).then(() => {
    if (orchestration.hasIn(['schedulerConfiguration', 'id'])) {
      return removeScheduler(orchestration.getIn(['schedulerConfiguration', 'id']));
    }

    return null;
  });
};

const updateOrchestration = (configurationId, configuration, changeDescription) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    configurationId,
    configuration,
    changeDescription,
  );
};

const updateOrchestrationConfiguration = (
  configurationId,
  configurationData,
  changeDescription,
) => {
  return updateOrchestration(
    configurationId,
    {
      configuration: JSON.stringify(configurationData.toJS()),
    },
    changeDescription,
  );
};

const loadTriggersForce = () => {
  Dispatcher.handleViewAction({
    type: actionTypes.TRIGGERS_LOAD,
  });

  return StorageApi.listTriggers()
    .then((triggers) => {
      return Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_SUCCESS,
        triggers,
      });
    })
    .catch((error) => {
      Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_ERROR,
      });

      throw error;
    });
};

const loadTriggers = () => {
  if (TriggersStore.getIsLoaded()) {
    loadTriggersForce();

    return Promise.resolve();
  }

  return loadTriggersForce();
};

const createTrigger = (data) => {
  return StorageApi.createTrigger(data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_CREATE_SUCCESS,
      trigger,
    });
  });
};

const updateTrigger = (id, data) => {
  return StorageApi.updateTrigger(id, data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_UPDATE_SUCCESS,
      trigger,
    });
  });
};

const deleteTrigger = (id) => {
  return StorageApi.deleteTrigger(id).then(() => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_DELETE_SUCCESS,
      id,
    });
  });
};

export {
  loadAll,
  loadOrchestration,
  createOrchestration,
  deleteOrchestration,
  updateOrchestration,
  updateOrchestrationConfiguration,
  loadTriggersForce,
  loadTriggers,
  createTrigger,
  updateTrigger,
  deleteTrigger,
};
