import { fromJS } from 'immutable';

export default fromJS({
  INTEGER: {
    name: 'INTEGER',
    basetype: 'INTEGER',
    size: false,
  },
  NUMERIC: {
    name: 'NUMERIC',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '131072,16383',
  },
  REAL: {
    name: 'REAL',
    basetype: 'FLOAT',
    size: false,
  },
  BOOLEAN: {
    name: 'BOOLEAN',
    basetype: 'BOOLEAN',
    size: false,
  },
  VARCHAR: {
    name: 'VARCHAR',
    basetype: 'STRING',
    size: true,
    maxLength: 10485760,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false,
  },
});
