import React from 'react';
import { Modal } from 'react-bootstrap';
import { configure, getApplicationKeyMap, GlobalHotKeys } from 'react-hotkeys-ce';
import { KeyCode } from 'design';
import _ from 'underscore';

import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_MLFLOW,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
} from '@/constants/features';
import { APP_ROUTE } from '@/constants/routeNames';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import { routeNames as dataCatalogRoutes } from '@/modules/data-catalog/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { routeNames as flowsRoutes } from '@/modules/flows/constants';
import { routeNames as jobsRoutes } from '@/modules/jobs/Constants';
import { routeNames as legacyOrchestrationsRoutes } from '@/modules/orchestrations/Constants';
import { routeNames as queueRoutes } from '@/modules/queue/constants';
import { routeNames as workspaceRoutes } from '@/modules/sandboxes/Constants';
import { routeNames as settingsRoutes } from '@/modules/settings/constants';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames as storageRoutes } from '@/modules/storage/constants';
import { routeNames as templatesRoutes } from '@/modules/templates/constants';
import { routeNames as legacyTransformationRoutes } from '@/modules/transformations/Constants';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import ModalIcon from '@/react/common/ModalIcon';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import isInputElement from '@/utils/isInputElement';

const KEYS_BINDING = {
  OPEN_HELP_MODAL: 'OPEN_HELP_MODAL',
  OPEN_GLOBAL_SEARCH: 'OPEN_GLOBAL_SEARCH',
  GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
  GO_TO_COMPONENTS: 'GO_TO_COMPONENTS',
  GO_TO_CONFIGURATIONS: 'GO_TO_CONFIGURATIONS',
  GO_TO_DATA_CATALOG: 'GO_TO_DATA_CATALOG',
  GO_TO_STORAGE: 'GO_TO_STORAGE',
  GO_TO_TEMPLATES: 'GO_TO_TEMPLATES',
  GO_TO_ORCHESTRATIONS: 'GO_TO_ORCHESTRATIONS',
  GO_TO_TRANSFORMATION: 'GO_TO_TRANSFORMATION',
  GO_TO_SHARED_CODES: 'GO_TO_SHARED_CODES',
  GO_TO_WORKSPACES: 'GO_TO_WORKSPACES',
  GO_TO_MODELS: 'GO_TO_MODELS',
  GO_TO_JOBS: 'GO_TO_JOBS',
  GO_TO_SETTINGS: 'GO_TO_SETTINGS',
};

configure({
  defaultKeyEvent: 'keyup',
  ignoreEventsCondition: (event) => {
    if (!event.target) {
      return false;
    }

    return (
      isInputElement(event.target) ||
      event.composedPath?.().some(isInputElement) ||
      event.target.isContentEditable
    );
  },
});

const Hotkeys = () => {
  const [showModal, setShowModal] = React.useState(false);
  const {
    router,
    hasFlows,
    hasNewQueue,
    hasTemplates,
    hasPayAsYouGo,
    hasNewTransformations,
    hasModels,
    hasSnowflakePartnerConnectLimited,
    isDevModeActive,
  } = useStores(
    () => {
      return {
        router: RoutesStore.getRouter(),
        hasFlows: ApplicationStore.hasFlows(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasTemplates: ApplicationStore.hasTemplates(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasNewTransformations: ApplicationStore.hasNewTransformationsOnly(),
        hasModels:
          !DevBranchesStore.isDevModeActive() &&
          (ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW) ||
            !StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT)),
        hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
        ),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
      };
    },
    [],
    [RoutesStore, DevBranchesStore, StackFeaturesStore, ApplicationStore],
  );

  const keyMap = React.useMemo(() => {
    return {
      [KEYS_BINDING.OPEN_HELP_MODAL]: { name: 'Open help modal', sequences: ['h', 'shift+?'] },
      [KEYS_BINDING.OPEN_GLOBAL_SEARCH]: { name: 'Open global search', sequences: ['/'] },
      [KEYS_BINDING.GO_TO_DASHBOARD]: { name: 'Go to Dashboard', sequences: ['g i', 'g+i'] },
      [KEYS_BINDING.GO_TO_COMPONENTS]: {
        name: 'Go to Components directory',
        sequences: ['g n', 'g+n'],
      },
      [KEYS_BINDING.GO_TO_CONFIGURATIONS]: {
        name: 'Go to my Configurations',
        sequences: ['g l', 'g+l'],
      },
      [KEYS_BINDING.GO_TO_DATA_CATALOG]: { name: 'Go to Data catalog', sequences: ['g d', 'g+d'] },
      [KEYS_BINDING.GO_TO_STORAGE]: { name: 'Go to Storage', sequences: ['g s', 'g+s'] },
      [KEYS_BINDING.GO_TO_TEMPLATES]: { name: 'Go to Templates', sequences: ['g u', 'g+u'] },
      [KEYS_BINDING.GO_TO_ORCHESTRATIONS]: hasFlows
        ? { name: 'Go to Flows', sequences: ['g f', 'g+f'] }
        : { name: 'Go to Orchestrations', sequences: ['g o', 'g+o'] },
      [KEYS_BINDING.GO_TO_TRANSFORMATION]: {
        name: 'Go to Transformations',
        sequences: ['g t', 'g+t'],
      },
      [KEYS_BINDING.GO_TO_SHARED_CODES]: { name: 'Go to Shared codes', sequences: ['g c', 'g+c'] },
      [KEYS_BINDING.GO_TO_WORKSPACES]: { name: 'Go to Workspaces', sequences: ['g w', 'g+w'] },
      [KEYS_BINDING.GO_TO_MODELS]: { name: 'Go to Models', sequences: ['g m', 'g+m'] },
      [KEYS_BINDING.GO_TO_JOBS]: { name: 'Go to Jobs', sequences: ['g j', 'g+j'] },
      [KEYS_BINDING.GO_TO_SETTINGS]: { name: 'Go to Admin', sequences: ['g a', 'g+a'] },
    };
  }, [hasFlows]);

  const handlers = React.useMemo(() => {
    const go = (target) => {
      router.transitionTo(target);
      setShowModal(false);
    };

    const handlers = {
      [KEYS_BINDING.OPEN_HELP_MODAL]: () => setShowModal(true),
      [KEYS_BINDING.OPEN_GLOBAL_SEARCH]: hasNewQueue ? _.noop : false, // it is handle in Global Search component
      [KEYS_BINDING.GO_TO_DASHBOARD]: () => go(APP_ROUTE),
      [KEYS_BINDING.GO_TO_CONFIGURATIONS]: () => go(componentsRoutes.CONFIGURATIONS),
      [KEYS_BINDING.GO_TO_COMPONENTS]: () => go(componentsRoutes.ROOT),
      [KEYS_BINDING.GO_TO_STORAGE]: () => go(storageRoutes.ROOT),
      [KEYS_BINDING.GO_TO_TEMPLATES]: hasTemplates ? () => go(templatesRoutes.ROOT) : false,
      [KEYS_BINDING.GO_TO_JOBS]: () => go(hasNewQueue ? queueRoutes.JOBS : jobsRoutes.ROOT),
      [KEYS_BINDING.GO_TO_ORCHESTRATIONS]: () =>
        go(hasFlows ? flowsRoutes.ROOT : legacyOrchestrationsRoutes.ROOT),
      [KEYS_BINDING.GO_TO_TRANSFORMATION]: () =>
        !hasSnowflakePartnerConnectLimited
          ? go(hasNewTransformations ? transformationRoutes.ROOT : legacyTransformationRoutes.ROOT)
          : false,
      [KEYS_BINDING.GO_TO_SHARED_CODES]: hasNewTransformations
        ? () => go(transformationRoutes.SHARED_CODES)
        : false,
      [KEYS_BINDING.GO_TO_WORKSPACES]:
        hasNewTransformations && !hasSnowflakePartnerConnectLimited
          ? () => go(workspaceRoutes.WORKSPACES)
          : false,
      [KEYS_BINDING.GO_TO_DATA_CATALOG]:
        !hasPayAsYouGo && !isDevModeActive ? () => go(dataCatalogRoutes.SHARED_WITH_YOU) : false,
      [KEYS_BINDING.GO_TO_MODELS]: hasModels ? () => go(transformationRoutes.MODELS) : false,
      [KEYS_BINDING.GO_TO_SETTINGS]: () => go(settingsRoutes.PROJECT),
    };

    Object.keys(handlers).forEach((key) => {
      if (!handlers[key]) {
        delete handlers[key];
      }
    });

    return handlers;
  }, [
    hasFlows,
    hasModels,
    hasNewQueue,
    hasTemplates,
    hasPayAsYouGo,
    hasNewTransformations,
    hasSnowflakePartnerConnectLimited,
    isDevModeActive,
    router,
  ]);

  const applicationKeyMap = getApplicationKeyMap();

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <Modal className="tw-content-center" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Navigation Hotkeys</Modal.Title>
          <ModalIcon icon="bolt" bold />
        </Modal.Header>
        <Modal.Body className="color-main">
          <p className="tw-mb-7 tw-leading-6">Use the hotkeys below to work faster with Keboola!</p>
          <div className="tw-mb-4 tw-grid tw-grid-cols-2 tw-gap-4">
            {Object.keys(applicationKeyMap)
              .filter((key) => handlers[key])
              .map((key) => (
                <div key={key}>
                  {applicationKeyMap[key].sequences[0].sequence
                    .split(' ')
                    .map((key, index) => <KeyCode key={index}>{key}</KeyCode>)
                    .reduce((prev, curr) => [prev, ' + ', curr])}
                  <span className="icon-addon-left">{applicationKeyMap[key].name}</span>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hotkeys;
