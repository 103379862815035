import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Alert } from 'design';

import Loader from './Loader';

const TestCredentialsButtonGroup = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    testCredentialsFn: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  getInitialState() {
    return {
      isTesting: false,
      isError: false,
      result: null,
    };
  },

  render() {
    return (
      <FormGroup>
        <div className="col-xs-8 col-xs-offset-4">
          {this.renderButton()}
          {this.renderResult()}
        </div>
      </FormGroup>
    );
  },

  renderButton() {
    return (
      <Button disabled={this.state.isTesting || this.props.disabled} onClick={this._startTesting}>
        {this.state.isTesting ? (
          <>
            <Loader className="icon-addon-right" />
            Testing Connection
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="key" className="icon-addon-right" />
            Test Connection
          </>
        )}
      </Button>
    );
  },

  renderResult() {
    if (!this.state.result && !this.state.isError) {
      return null;
    }

    if (this.state.isError || !['success', 'ok'].includes(this.state.result.status)) {
      return this.renderError();
    }

    return this.renderSuccess();
  },

  renderSuccess() {
    return (
      <Alert variant="success" className="tw-mt-3.5" title="Connected!">
        {this.props.isEditing && <p>Don&apos;t forget to save the credentials.</p>}
      </Alert>
    );
  },

  renderError() {
    return (
      <Alert variant="error" title="Failed to connect!" className="tw-mt-3.5">
        {this.state.result && (
          <>
            <p>{this.state.result.message}</p>
            {this.state.result.exceptionId && (
              <small>ExceptionId: {this.state.result.exceptionId}</small>
            )}
          </>
        )}
      </Alert>
    );
  },

  _startTesting() {
    this.setState({ isTesting: true, isError: false, result: null });
    return this.props.testCredentialsFn().then(this._onTestingDone, this._onTestingError);
  },

  _onTestingDone(result) {
    return this.setState({ isTesting: false, isError: false, result });
  },

  _onTestingError(result) {
    return this.setState({ isTesting: false, isError: true, result });
  },
});

export default TestCredentialsButtonGroup;
