import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

const prepareUrlPreview = (baseUrl: string, path: string) => {
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  if (path.startsWith('/')) {
    return `${new URL(baseUrl).origin}${path}`;
  }

  return `${baseUrl}${path}`;
};

const UrlPreview = (props: { baseUrl: string; path: string }) => {
  if (!props.baseUrl) {
    return null;
  }

  return (
    <FormGroup>
      <ControlLabel>URL Preview</ControlLabel>
      <FormControl
        disabled
        type="text"
        value={prepareUrlPreview(props.baseUrl, props.path)}
        className="!tw-bg-neutral-150"
      />
    </FormGroup>
  );
};

export default UrlPreview;
