import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'design';

type Props = {
  className?: string;
};

const ConfigurationDisabledLabel = (props: Props) => {
  return (
    <Badge variant="purple" className={props.className}>
      <FontAwesomeIcon icon="ban" /> Disabled
    </Badge>
  );
};

export default ConfigurationDisabledLabel;
