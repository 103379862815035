export default [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: true,
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true,
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true,
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true,
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: true,
  },
  {
    label: 'Schema',
    name: 'schema',
    type: 'text',
    protected: false,
    required: true,
  },
];
