import type { REQUESTABLE_FEATURE } from '@/constants/features';
import EventsApi from './EventsApi';

const FAKE_UI_COMPONENT_ID = 'keboola.kbc-ui';
const FAKE_UI_CONFIGURATION_ID = 'feature-request';

export const requestFeature = (featureId: REQUESTABLE_FEATURE['key'], userMessage: string) =>
  EventsApi.createEvent({
    component: FAKE_UI_COMPONENT_ID,
    type: 'info',
    message: 'Request for a feature',
    description: featureId,
    configurationId: FAKE_UI_CONFIGURATION_ID,
    params: JSON.stringify({ featureRequest: { reason: userMessage, feature: featureId } }),
  });

export const isFeatureRequested = (featureId: REQUESTABLE_FEATURE['key']) =>
  EventsApi.listEvents({
    q: `description:${featureId}`,
    component: FAKE_UI_COMPONENT_ID,
    configurationId: FAKE_UI_CONFIGURATION_ID,
  }).then((events?: Record<string, any>[]) => !!events?.length);
