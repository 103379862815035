import React from 'react';
import type { ReactNode } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Tooltip } from 'design';
import { fromJS } from 'immutable';
import { numberFormat, strLeft } from 'underscore.string';

import keyCodes from '@/constants/keyCodes';
import { canAccessProject } from '@/modules/admin/privileges';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { getBackendImage } from '@/modules/data-catalog/helpers';
import { METADATA_KEYS, SIDEBAR_WIDTH } from '@/modules/lineage/components/constants';
import { parseFQID } from '@/modules/lineage/fqid';
import { countMetadata, getMetadataValue } from '@/modules/lineage/helpers';
import type { NodeData, OnSelectProject } from '@/modules/lineage/rfTypes';
import { prepareBackendLabel } from '@/modules/storage/helpers';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentType from '@/react/common/ComponentType';
import { byteConverter, formatAbsolute } from '@/react/common/helpers';
import Truncated from '@/react/common/Truncated';
import ConnectedTo from './ConnectedTo';
import Description from './Description';
import { constructBucketFqidFromTableNode } from './helpers';
import LineageLink from './LineageLink';
import Row from './Row';
import RowWithRenderer from './RowWithRenderer';

const Sidebar = ({
  node,
  onCloseSidebar,
  projectId,
  onSelectProject,
}: {
  node: NodeData;
  onCloseSidebar: () => void;
  projectId: string | null;
  onSelectProject: OnSelectProject;
}) => {
  const params = parseFQID(node.fqid);
  const { projectId: nodeProjectId } = params;

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === keyCodes.ESCAPE) {
        event.stopPropagation();

        onCloseSidebar();
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [onCloseSidebar]);

  return (
    <div
      className="tw-h-full tw-overflow-auto tw-bg-white tw-px-6 tw-py-4"
      style={{ width: SIDEBAR_WIDTH }}
    >
      <div className="tw-mb-6 tw-flex tw-items-center tw-justify-between tw-gap-4">
        <div className="tw-flex tw-items-center tw-gap-4">
          {node.type === 'table' && (
            <>
              <FontAwesomeIcon icon="table" className="tw-text-neutral-400" />
              <Truncated
                text={node.displayName || node.name}
                className="tw-text-sm tw-font-medium"
              />
            </>
          )}
          {(node.type === 'component' || node.type === 'transformation') && (
            <>
              <ComponentIcon component={fromJS(node.component)} size="32" />
              <div className="tw-flex tw-flex-col">
                <Truncated text={node.name} className="tw-m-0 tw-text-sm tw-font-medium" />
                <div className="tw-text-xs">
                  <ComponentType
                    type={node.component?.type ?? 'other'}
                    noIcon
                    labelClassName="tw-text-cyan-600"
                  />{' '}
                  - {node.component?.name}
                </div>
              </div>
            </>
          )}
          {!canAccessProject(nodeProjectId) && (
            <Tooltip
              placement="left"
              tooltip="You don't have access to this component's project."
              type="explanatory"
            >
              <FontAwesomeIcon
                icon="lock"
                className="tw-ml-auto tw-text-base tw-text-warning-500"
              />
            </Tooltip>
          )}
        </div>
        <Tooltip placement="left" tooltip="Hide sidebar">
          <Button onClick={onCloseSidebar} className="!tw-px-3.5">
            <FontAwesomeIcon icon="xmark" />
          </Button>
        </Tooltip>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3 tw-text-sm tw-font-medium">
        <>
          {nodeProjectId && nodeProjectId !== projectId && 'projectName' in node && (
            <RowWithRenderer title="Linked from" value={node.projectName}>
              {(value) => (
                <Link
                  className="btn btn-link btn-link-inline text-muted tw-break-all !tw-text-right"
                  onClick={(event) =>
                    onSelectProject(node.projectName, nodeProjectId, node.fqid, event)
                  }
                >
                  {value}
                </Link>
              )}
            </RowWithRenderer>
          )}
          <ConnectedTo
            node={node}
            projectId={projectId}
            nodeProjectId={nodeProjectId ?? null}
            onSelectProject={onSelectProject}
          />
          {node.type === 'table' && (
            <>
              <RowWithRenderer title="Name" value={node.displayName || node.name}>
                {(value) => <LineageLink params={params}>{value}</LineageLink>}
              </RowWithRenderer>
              <RowWithRenderer title="Bucket" value={node.bucket.displayName || node.bucket.id}>
                {(value) => (
                  <LineageLink params={parseFQID(constructBucketFqidFromTableNode(node))}>
                    {value}
                  </LineageLink>
                )}
              </RowWithRenderer>
              <Row
                title="Primary Key"
                value={!node.primaryKey?.length ? 'Not set' : node.primaryKey.join(', ')}
              />
              <Row title="Stage" value={strLeft(node.id, '.').toUpperCase()} />
              <RowWithRenderer title="Backend" value={node.bucket.backend}>
                {(value: ReactNode) => {
                  const backendImage = getBackendImage(value);

                  return (
                    <span className="tw-flex tw-items-center tw-text-neutral-400">
                      {backendImage && (
                        <Image width={20} className="icon-addon-right" src={backendImage} />
                      )}
                      <span>{prepareBackendLabel(value)}</span>
                    </span>
                  );
                }}
              </RowWithRenderer>
              <Row title="Created" value={node.created ? formatAbsolute(node.created) : 'N/A'} />
              <Row
                title="Last Import"
                value={node.lastImportDate ? formatAbsolute(node.lastImportDate) : 'N/A'}
              />
              <Row
                title="Last Change"
                value={node.lastChangeDate ? formatAbsolute(node.lastChangeDate) : 'N/A'}
              />
              <Row title="Row Count" value={node.rowsCount ? numberFormat(node.rowsCount) : null} />
              <Row
                title="Data Size"
                value={node.dataSizeBytes ? byteConverter(node.dataSizeBytes) : null}
              />
              <Description
                description={getMetadataValue(node.metadata, METADATA_KEYS.DESCRIPTION)}
              />
            </>
          )}
          {(node.type === 'component' || node.type === 'transformation') && (
            <>
              <RowWithRenderer title="Name" value={node.name}>
                {(value) => <LineageLink params={params}>{value}</LineageLink>}
              </RowWithRenderer>
              <Row title="Type" value={getNewComponentTypeLabel(node.component?.type ?? 'other')} />
              <Row
                title="Owner"
                value={getMetadataValue(node.metadata, METADATA_KEYS.OWNER_METADATA_KEY)}
              />
              <Row title="Created" value={node.created ? formatAbsolute(node.created) : 'N/A'} />
              <Row
                title="Last update"
                value={
                  node.currentVersion?.created ? formatAbsolute(node.currentVersion.created) : 'N/A'
                }
              />
              <Row
                title="User Variables"
                value={countMetadata(node.metadata, METADATA_KEYS.VARIABLES) || 0}
              />
              <Description description={node.description} />
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Sidebar;
