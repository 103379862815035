import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard } from 'design';
import type { Map } from 'immutable';

const RunIdTooltip = ({ job }: { job: Map<string, any> }) => {
  return (
    <Clipboard
      text={job.get('runId')}
      label={
        <span className="f-12 text-muted font-normal tw-ml-1">
          Run ID
          <FontAwesomeIcon icon="copy" className="f-12 tw-ml-0.5" />
        </span>
      }
      tooltipText={
        <>
          <p className="tooltip-title">Run ID</p>
          <p className="overflow-break-anywhere">{job.get('runId')}</p>
          <small>Click to copy</small>
        </>
      }
      tooltipPlacement="top"
      tooltipType="explanatory"
      showIcon={false}
    />
  );
};

export default RunIdTooltip;
