import { fromJS, Map } from 'immutable';

import { backends, transformationType } from '@/modules/transformations/Constants';

const hasSandbox = (backend, type) => {
  if (backend === backends.DOCKER) {
    return [transformationType.PYTHON, transformationType.JULIA, transformationType.R].includes(
      type,
    );
  }

  return [backends.REDSHIFT, backends.SNOWFLAKE].includes(backend);
};

const generateRunParameters = (transformation, bucketId, versionId) => {
  const backend = transformation.get('backend');
  const transformationId = transformation.get('id');
  if (backend === 'docker') {
    return fromJS({
      transformation: {
        config_id: bucketId,
        row_id: transformationId,
        ...(versionId ? { config_version: versionId.toString() } : {}),
      },
    });
  }
  return Map({
    configBucketId: bucketId,
    transformations: [transformationId],
  });
};

const getDockerConnectLink = (credentials, dockerType) => {
  if (credentials.get('url')) {
    return credentials.get('url');
  }

  const scheme = credentials.get('hasHttps') ? 'https://' : 'http://';
  const suffix = [transformationType.PYTHON, transformationType.JULIA].includes(dockerType)
    ? '/notebooks/notebook.ipynb'
    : '';

  return `${scheme}${credentials.get('hostname')}:${credentials.get('port')}${suffix}`;
};

export { generateRunParameters, getDockerConnectLink, hasSandbox };
