const portMapping = {
  snowflake: '443',
  redshift: '5439',
};

const updateWorkspaceParameters = (workspace) => {
  const backend = workspace.getIn(['connection', 'backend']);
  let newWorkspace = workspace;
  if (backend) {
    newWorkspace = newWorkspace
      .setIn(['connection', 'driver'], backend)
      .deleteIn(['connection', 'backend']);

    if (!newWorkspace.hasIn(['connection', 'port']) && portMapping[backend]) {
      newWorkspace = newWorkspace.setIn(['connection', 'port'], portMapping[backend]);
    }

    if (backend === 'redshift') {
      newWorkspace = newWorkspace.deleteIn(['connection', 'warehouse']);
    }
  }

  return newWorkspace;
};

const prepareConfigurationWorkspaces = (workspaces) => {
  return workspaces
    .map(updateWorkspaceParameters)
    .toMap()
    .mapKeys((key, workspace) => workspace.get('id'));
};

export { prepareConfigurationWorkspaces, updateWorkspaceParameters };
