import { fromJS, Map } from 'immutable';

import {
  isReservedParam,
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';

const KEY = 'root-headers';

const mapToState = (parameters: Map<string, any>) => {
  const headers = transformMapToKeyValueList(
    parameters.getIn(['api', 'http', 'headers'], Map()).filterNot(isReservedParam),
  );

  return fromJS({ [KEY]: { headers } });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  const reservedHeaders = parameters
    .getIn(['api', 'http', 'headers'], Map())
    .filter(isReservedParam);

  const customHeaders = transformKeyValueListToMap(editing.getIn([KEY, 'headers']));

  const headers = reservedHeaders.merge(customHeaders);

  return headers.isEmpty()
    ? parameters.deleteIn(['api', 'http', 'headers'])
    : parameters.setIn(['api', 'http', 'headers'], headers);
};

export { KEY, mapToState, mapToApi };
