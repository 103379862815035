import React from 'react';
import { URLS } from '@keboola/constants';
import createReactClass from 'create-react-class';
import { Link } from 'design';

import { KEBOOLA_PROJECT_BACKUP } from '@/constants/componentIds';
import actionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import Form from './Form';

const Index = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          <p>
            You can export this project to <Link href="https://aws.amazon.com/s3/">Amazon S3</Link>{' '}
            or{' '}
            <Link href="https://azure.microsoft.com/services/storage/blobs/">
              Azure Blob Storage
            </Link>
            .
          </p>
          <p>
            Export will contain all bucket and table metadata, all table data exported to gzipped
            CSV files, and all component configurations. Read more in{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/management/project/export/`}>
              Data Takeout documentation
            </Link>
            .
          </p>
          <hr />
          <Form
            readOnly={this.state.readOnly}
            onSubmit={this.handleSubmit}
            isSaving={this.state.isSaving}
          />
        </div>
      </div>
    );
  },

  handleSubmit(parameters) {
    this.setState({ isSaving: true });
    actionCreators
      .runComponent({
        component: KEBOOLA_PROJECT_BACKUP,
        data: { configData: { parameters } },
      })
      .finally(() => this.setState({ isSaving: false }));
  },
});

export default Index;
