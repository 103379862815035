import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import { Map } from 'immutable';

import { createTableFromSnapshot, deleteSnapshot, loadSnapshots } from '@/modules/storage/actions';
import BlockButton from '@/react/common/BlockButton';
import ConfirmModal from '@/react/common/ConfirmModal';
import CreatedDate from '@/react/common/CreatedDate';
import Loader from '@/react/common/Loader';
import User from '@/react/common/User';
import CreateTableFromSnapshotModal from './CreateTableFromSnapshotModal';

const LOAD_LIMIT = 20;
const LOAD_LIMIT_MAX = 1000;

class Snapshots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadedAll: false,
      selectedSnapshot: Map(),
      showCreateTableModal: false,
      showDeleteConfirmModal: false,
    };
  }

  componentDidMount() {
    this.loadSnapshots();
  }

  render() {
    return (
      <div className="box">
        {this.renderSnapshots()}
        <CreateTableFromSnapshotModal
          sapiToken={this.props.sapiToken}
          show={this.state.showCreateTableModal}
          snapshot={this.state.selectedSnapshot}
          activeBucketId={this.props.table.getIn(['bucket', 'id'])}
          buckets={this.props.buckets}
          tables={this.props.tables}
          tableName={this.props.table.get('name')}
          onConfirm={(snapshotId, bucketId, name) =>
            createTableFromSnapshot(bucketId, { snapshotId, name })
          }
          onHide={() => this.setState({ showCreateTableModal: false })}
        />
        <ConfirmModal
          show={this.state.showDeleteConfirmModal}
          onHide={() => this.setState({ showDeleteConfirmModal: false })}
          icon="trash"
          title="Delete snapshot"
          buttonLabel="Delete snapshot"
          buttonType="danger"
          text={
            <p>
              Are you sure you want to delete the snapshot {this.state.selectedSnapshot.get('id')}
              {this.state.selectedSnapshot.get('description')
                ? ` (${this.state.selectedSnapshot.get('description')})`
                : ''}
              ?
            </p>
          }
          onConfirm={() =>
            deleteSnapshot(this.props.table.get('id'), this.state.selectedSnapshot.get('id'))
          }
        />
      </div>
    );
  }

  renderSnapshots() {
    const snapshots = this.getSnapshots();

    return (
      <>
        <Table hover>
          <thead>
            <tr>
              <th className="w-100">ID</th>
              <th>Description</th>
              <th className="w-250">Created time</th>
              <th className="w-250">Created</th>
              <th className="w-50" />
            </tr>
          </thead>
          <tbody>
            {snapshots.isEmpty() && (
              <tr className="no-hover">
                <td colSpan={5}>
                  {this.state.isLoading ? (
                    <>
                      <Loader /> Loading snapshots...
                    </>
                  ) : (
                    'No snapshots created yet.'
                  )}
                </td>
              </tr>
            )}
            {snapshots
              .sortBy((snapshot) => -1 * new Date(snapshot.get('createdTime')).getTime())
              .map((snapshot) => {
                const creating = this.props.creatingFromSnapshot.get(snapshot.get('id'), false);
                const deleting = this.props.deletingSnapshot.get(snapshot.get('id'), false);
                const admin = this.props.admins.get(
                  snapshot.getIn(['creatorToken', 'description']),
                );

                return (
                  <tr key={snapshot.get('id')} className="hoverable-actions">
                    <td>{snapshot.get('id')}</td>
                    <td>{snapshot.get('description')}</td>
                    <td>
                      <CreatedDate createdTime={snapshot.get('createdTime')} />
                    </td>
                    <td title={`ID: ${snapshot.getIn(['creatorToken', 'id'])}`}>
                      {admin ? (
                        <User user={admin} />
                      ) : (
                        snapshot.getIn(['creatorToken', 'description'])
                      )}
                    </td>
                    <td className="td pl-0 pr-1 no-wrap">
                      <Tooltip tooltip="Restore" placement="top">
                        <Button
                          bsStyle="link"
                          className="text-muted"
                          onClick={() =>
                            this.setState({
                              showCreateTableModal: true,
                              selectedSnapshot: snapshot,
                            })
                          }
                          disabled={creating}
                        >
                          {creating ? <Loader /> : <FontAwesomeIcon fixedWidth icon="reply" />}
                        </Button>
                      </Tooltip>
                      <Tooltip tooltip="Delete" placement="top">
                        <Button
                          bsStyle="link"
                          className="text-muted"
                          onClick={() =>
                            this.setState({
                              showDeleteConfirmModal: true,
                              selectedSnapshot: snapshot,
                            })
                          }
                          disabled={deleting}
                        >
                          {deleting ? <Loader /> : <FontAwesomeIcon fixedWidth icon="trash" />}
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
              .toArray()}
          </tbody>
        </Table>
        {this.renderLoadMoreButton()}
      </>
    );
  }

  renderLoadMoreButton() {
    if (this.state.isLoadedAll || this.props.snapshots.count() <= LOAD_LIMIT) {
      return null;
    }

    return (
      <BlockButton
        label="Load All"
        onClick={() => this.loadAllSnapshots()}
        isLoading={this.state.isLoading}
      />
    );
  }

  getSnapshots() {
    if (this.state.isLoadedAll) {
      return this.props.snapshots;
    }

    return this.props.snapshots.slice(0, LOAD_LIMIT);
  }

  loadSnapshots() {
    this.setState({ isLoading: true });
    return loadSnapshots(this.props.table.get('id'), { limit: LOAD_LIMIT + 1 }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  loadAllSnapshots() {
    this.setState({ isLoading: true });
    return loadSnapshots(this.props.table.get('id'), { limit: LOAD_LIMIT_MAX }).finally(() => {
      this.setState({ isLoading: false, isLoadedAll: true });
    });
  }
}

Snapshots.propTypes = {
  snapshots: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  table: PropTypes.instanceOf(Map).isRequired,
  creatingFromSnapshot: PropTypes.instanceOf(Map).isRequired,
  deletingSnapshot: PropTypes.instanceOf(Map).isRequired,
};

export default Snapshots;
