import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/orchestrations/ActionCreators';
import RunOrchestrationModal from '@/modules/orchestrations/react/modals/RunOrchestration';

const OrchestrationRunButton = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    tasks: PropTypes.object,
    buttonBlock: PropTypes.bool,
    onKeyDown: PropTypes.func,
  },

  getInitialState() {
    return {
      isLoading: false,
    };
  },

  componentWillUnmount() {
    this.cancellablePromise && this.cancellablePromise.cancel();
  },

  render() {
    return (
      <RunOrchestrationModal
        orchestration={this.props.orchestration}
        onKeyDown={this.props.onKeyDown}
        tasks={this.props.tasks}
        onRequestRun={this.handleRunStart}
        onRequestCancel={this.handleRunCancel}
        isLoading={this.state.isLoading}
        onOpen={this.handleOnOpen}
        buttonBlock={this.props.buttonBlock}
        loadTasksFn={this.handleLoadTasks}
      />
    );
  },

  handleLoadTasks() {
    this.setState({
      isLoading: true,
    });
    ActionCreators.loadOrchestration(this.props.orchestration.get('id')).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  },

  handleOnOpen() {
    ActionCreators.startOrchestrationRunTasksEdit(this.props.orchestration.get('id'));
  },

  handleRunCancel() {
    ActionCreators.cancelOrchestrationRunTasksEdit(this.props.orchestration.get('id'));
  },

  handleRunStart() {
    this.setState({ isLoading: true });

    this.cancellablePromise = ActionCreators.runOrchestration(
      this.props.orchestration.get('id'),
      this.props.tasks ? this.props.tasks : null,
      true,
    ).finally(() => {
      if (!this.cancellablePromise.isCancelled()) {
        this.setState({ isLoading: false });
      }
    });
  },
});

export default OrchestrationRunButton;
