import React from 'react';
import { Clipboard } from 'design';
import type { List } from 'immutable';

import isEmailValid from '@/utils/isEmailValid';
import Truncated from './Truncated';

const ComponentAuthor = (props: { is3rdParty: boolean; contact: List<string> }) => {
  if (!props.is3rdParty) {
    return <span className="font-medium">Keboola</span>;
  }

  const name = props.contact.get(0) || (
    <>
      3<sup>rd</sup> party
    </>
  );
  const email = props.contact.find((data) => !!data && isEmailValid(data));

  if (email) {
    return (
      <Clipboard
        text={email}
        label={<Truncated className="color-primary font-medium underline" text={name} noTooltip />}
        tooltipText="Click to Copy Email Address"
        stopEventPropagation
        tooltipPlacement="top"
        showIcon={false}
      />
    );
  }

  return <Truncated className="font-medium" text={name} />;
};

export default ComponentAuthor;
