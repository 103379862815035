import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CollapsiblePanel } from 'design';
import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import keyCodes from '@/constants/keyCodes';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { routeNames } from '@/modules/flows/constants';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import CreatedDate from './CreatedDate';
import { isUsedInFlow } from './helpers';
import Truncated from './Truncated';

type Row = { componentId: string; config: Map<string, any> };

const FlowsValidationBox = (props: { flows: Map<string, any>; configurations: Row[] }) => {
  const usedConfigurations = props.configurations.filter((row) => {
    return props.flows.some((flow) => isUsedInFlow(flow, row.componentId, row.config.get('id')));
  });

  if (!usedConfigurations.length) {
    return null;
  }

  return (
    <>
      <Alert
        title={`There are flows that use ${
          usedConfigurations.length > 1 ? 'these configurations' : 'that configuration'
        }`}
        variant="warning"
        className="tw-mb-5 tw-mt-3.5"
      >
        Configurations in Flows will be disabled
      </Alert>
      {usedConfigurations.map((row) => {
        return (
          <CollapsiblePanel
            defaultExpanded
            key={row.config.get('id')}
            header={<Truncated text={row.config.get('name')} />}
            className="tw-mb-3"
            bodyClassName="p-0"
          >
            <Table hover>
              <tbody>
                {props.flows
                  .filter((flow) => isUsedInFlow(flow, row.componentId, row.config.get('id')))
                  .map((flow) => {
                    const onClick = (e: React.MouseEvent | React.KeyboardEvent) => {
                      if (hasSelections()) {
                        return;
                      }

                      if (shouldUseNewWindow(e)) {
                        return windowOpen(
                          RoutesStore.getRouter().createHref(routeNames.DETAIL, {
                            config: flow.get('id'),
                          }),
                        );
                      }

                      return RoutesStore.getRouter().transitionTo(routeNames.DETAIL, {
                        config: flow.get('id'),
                      });
                    };

                    return (
                      <tr
                        key={flow.get('id')}
                        tabIndex={0}
                        role="button"
                        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                        onClick={onClick}
                        onKeyDown={(e) => {
                          if (e.key === keyCodes.ENTER) {
                            onClick(e);
                          }
                        }}
                      >
                        <td className="pl-1">
                          <div className="flex-container flex-start">
                            <FontAwesomeIcon
                              icon="bars-staggered"
                              className="text-muted icon-addon-right"
                            />
                            <ComponentConfigurationLink
                              hasFlows
                              componentId={KEBOOLA_ORCHESTRATOR}
                              configId={flow.get('id')}
                            >
                              <Truncated text={flow.get('name')} />
                            </ComponentConfigurationLink>
                          </div>
                        </td>
                        <td className="text-muted pr-1">
                          Last changed{' '}
                          <CreatedDate createdTime={flow.getIn(['currentVersion', 'created'])} />
                        </td>
                      </tr>
                    );
                  })
                  .toArray()}
              </tbody>
            </Table>
          </CollapsiblePanel>
        );
      })}
    </>
  );
};

export default FlowsValidationBox;
