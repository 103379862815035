import Promise from 'bluebird';
import { List } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { SERVICE_SYRUP, SERVICE_SYRUP_DOCKER } from '@/constants/serviceIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import QueueActions from '@/modules/queue/actions';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import SimpleError from '@/utils/errors/SimpleError';
import request from '@/utils/request';
import { features } from './Constants';
import { ensureComponentWithDetails } from './helpers';

const runLegacyJob = (componentUrl, params) => {
  const url = params.tag
    ? `${componentUrl}/run/tag/${params.tag}`
    : `${componentUrl}/${params.method}`;

  return request('POST', url)
    .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
    .send(params.data)
    .promise()
    .then((response) => response.body);
};

const prepareData = (data) => {
  const { row, ...rest } = data;

  return { ...rest, ...(row && { configRowIds: [row] }) };
};

export default {
  run(params) {
    const { config, row } = params.data;
    const syrupDockerUrl = `${ServicesStore.getServiceUrl(SERVICE_SYRUP)}/${SERVICE_SYRUP_DOCKER}`;
    let configData = InstalledComponentsStore.getConfigData(params.component, config);

    return ensureComponentWithDetails(params.component)
      .then((component) => {
        if (DevBranchesStore.isDevModeActive()) {
          const devBranchId = DevBranchesStore.getCurrentId();
          const componentFeatures = component.get('features', List());
          const hasDevBranchConfigurationUnsafeFeature =
            componentFeatures.includes(features.DEV_BRANCH_CONFIGURATION_UNSAFE) &&
            !ApplicationStore.hasProtectedDefaultBranch();

          if (componentFeatures.includes(features.DEV_BRANCH_JOB_BLOCKED)) {
            throw new SimpleError(
              'Development branch restriction',
              `${component.get('name')} cannot be run in development branch.`,
            );
          }

          if (
            component.get('uri') !== `${syrupDockerUrl}/${params.component}` ||
            (hasDevBranchConfigurationUnsafeFeature &&
              !configData.getIn(['runtime', 'safe'], false))
          ) {
            const name = component.get('name');
            const type = component.get('type');

            throw new SimpleError(
              'Development branch restriction',
              Object.values(componentTypes).includes(type)
                ? `${name} cannot be run in development branch unless it's marked as "Safe for run in branch".`
                : `${name} cannot be run in development branch.`,
            );
          }

          if (row) {
            configData = ConfigurationRowsStore.getConfiguration(params.component, config, row);
          }

          const hasProcessedTagsOrQuery = configData
            .getIn(['storage', 'input', 'files'], List())
            .find((item) => item.has('query'));

          if (hasProcessedTagsOrQuery) {
            throw new SimpleError(
              'Configuration Error',
              'Configuration containing Query or Processed Tags parameters in File Input mapping cannot be run in development branch.',
            );
          }

          return component.set(
            'uri',
            `${syrupDockerUrl}/branch/${devBranchId}/${params.component}`,
          );
        }

        return component;
      })
      .then((component) => {
        if (
          component.get('uri').startsWith(syrupDockerUrl) &&
          Object.values(componentTypes).includes(component.get('type')) &&
          !component.getIn(['data', 'definition', 'uri'])
        ) {
          return Promise.reject(
            new SimpleError('Component Error', 'Component without image cannot be run.'),
          );
        }

        return ApplicationStore.hasNewQueue()
          ? QueueActions.createJob(params.component, prepareData(params.data), params.method)
          : runLegacyJob(component.get('uri'), params);
      });
  },
  runProduction(params) {
    return ApplicationStore.hasNewQueue()
      ? QueueActions.createProductionJob(params.component, prepareData(params.data), params.method)
      : ensureComponentWithDetails(params.component).then((component) =>
          runLegacyJob(component.get('uri'), params),
        );
  },
};
