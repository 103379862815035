import { generateSessionToken } from '@/modules/settings/actions';
import SettingsStore from '@/modules/settings/SettingsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';

const createRequest = (method: 'POST' | 'GET' | 'DELETE' | 'PUT', path: string) => {
  return request(method, path)
    .set('X-KBC-ManageApiToken', SettingsStore.getTokenString())
    .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
    .set('Content-Type', 'application/json');
};

const GcpApi = {
  validateBackend(params: {
    gcsBucketId: string;
    folderId: string;
    serviceAccountKey: Record<string, string>;
  }) {
    return generateSessionToken().then(() =>
      createRequest(
        'POST',
        `/integration/partners/gcp/project/${ApplicationStore.getCurrentProject().get(
          'id',
        )}/set-up/big-query`,
      )
        .send(params)
        .promise()
        .then((response) => response.body),
    );
  },
};

export default GcpApi;
