import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import TrashHeaderButton from './react/components/TrashHeaderButtons';
import Index from './react/pages/TrashIndex';
import { routeNames } from './constants';

export default {
  name: routeNames.SETTINGS_TRASH,
  title: 'Recently Deleted',
  path: 'settings-trash',
  defaultRouteHandler: Index,
  headerButtonsHandler: TrashHeaderButton,
  requireData: [
    () => {
      // not blocking loading, we show loading indicator on index page
      InstalledComponentsActionCreators.loadDeletedComponents();
    },
  ],
};
