import React, { type PropsWithChildren, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import keyCodes from '@/constants/keyCodes';
import Truncated from './Truncated';

export const FullScreenModalHeader = ({
  title,
  isTitleWithIcon = false,
  children,
  onClose,
}: PropsWithChildren<{
  title: React.ReactNode;
  isTitleWithIcon?: boolean;
  onClose: () => void;
}>) => {
  const previousTitle = useMemo(() => {
    const modals = Array.from(document.querySelectorAll('.full-screen-modal'));
    return modals?.length > 0 && modals.at(-1)?.querySelector('.modal-title')?.textContent;
  }, []);

  return (
    <Modal.Header>
      <div className="flex-container">
        <div className="tw-flex tw-grow tw-items-center">
          {previousTitle && (
            <Button onClick={onClose} className="tw-mr-6">
              <FontAwesomeIcon icon="angle-left" />
            </Button>
          )}

          <div className="tw-w-full">
            <Modal.Title className="m-0">
              {previousTitle && (
                <div onClick={onClose}>
                  <Truncated
                    text={previousTitle}
                    className={classnames(
                      'tw-relative tw-top-0 tw-cursor-pointer tw-text-base tw-font-normal tw-text-neutral-400 tw-underline',
                      'hover:tw-text-neutral-800 hover:tw-no-underline',
                      isTitleWithIcon && 'tw-ml-14',
                    )}
                  />
                </div>
              )}
              <div
                className={classnames(
                  'flex-container flex-start',
                  previousTitle && '[&>img]:-tw-mt-6',
                )}
              >
                {title}
              </div>
            </Modal.Title>
          </div>
        </div>
        {children}
      </div>
    </Modal.Header>
  );
};

const FullScreenModal = ({
  children,
  onHide,
  className,
  hideOverflow = true,
}: {
  children: React.ReactNode;
  onHide?: () => void;
  hideOverflow?: boolean;
  className?: string;
}) => {
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const handleFocus = () => {
    const fullScreenModalElement = modalRef.current?.closest('.full-screen-modal');

    if (fullScreenModalElement && !fullScreenModalElement.contains(document.activeElement)) {
      (fullScreenModalElement as HTMLElement).focus();
    }
  };

  React.useEffect(() => {
    handleFocus();
  }, []);

  React.useEffect(() => {
    if (!hideOverflow) {
      document.body.classList.remove('full-screen-modal-open');
      return;
    }

    document.body.classList.add('full-screen-modal-open');

    return () => {
      if (!document.querySelector('.full-screen-modal')) {
        document.body.classList.remove('full-screen-modal-open');
      }
    };
  }, [hideOverflow]);

  React.useEffect(() => {
    if (!onHide) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === keyCodes.ESCAPE && !document.body.classList.contains('modal-open')) {
        const fullScreenModalElement = modalRef.current?.closest('.full-screen-modal');
        const targetElement = event.target as HTMLElement;

        // If there are multiple modals opened at once we want to close
        // only the one where the keyboard event originated from.
        if (fullScreenModalElement?.contains(targetElement)) {
          event.stopPropagation();
          onHide();
        } else {
          handleFocus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onHide]);

  return ReactDOM.createPortal(
    <Modal.Dialog className={classnames('full-screen-modal', className)}>
      <div ref={modalRef}>{children}</div>
    </Modal.Dialog>,
    document.body,
  );
};

export default FullScreenModal;
