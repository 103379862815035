import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { ltrim, rtrim } from 'underscore.string';

const MAXIMUM_URL_HISTORY = 20;

class ScrollAndFocusManager extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  url = OrderedMap();

  shouldComponentUpdate({ location }) {
    if (
      ltrim(window.location.hash, '#') ||
      rtrim(this.props.location.pathname, '/') === rtrim(location.pathname, '/')
    ) {
      return false;
    }

    if (location.action === 'PUSH') {
      this.saveUrl(this.props.location, window.scrollY);
      this.scroll(location?.state?.scroll);
    } else if (location.action === 'POP') {
      this.resetFocus();
      this.scroll(this.url.get(location.pathname));
    }

    return false;
  }

  saveUrl = (location, offset) => {
    this.url = this.url.delete(location.pathname);

    if (offset === 0) {
      return null;
    }

    this.url = this.url.set(location.pathname, offset).takeLast(MAXIMUM_URL_HISTORY);
  };

  resetFocus = () => {
    document.activeElement?.blur();
  };

  scroll = (position = 0) => {
    window.requestAnimationFrame(() => window.scrollTo(0, position));
  };

  render() {
    return null;
  }
}

export default ScrollAndFocusManager;
