import React from 'react';
import classNames from 'classnames';

const TabNav = (props: { children: React.ReactNode; className?: string }) => {
  return (
    <ul role="navigation" className={classNames('nav nav-tabs', props.className)}>
      {props.children}
    </ul>
  );
};

export default TabNav;
