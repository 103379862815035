export const Types = {
  IGNORE: 'IGNORE',
  STRING: 'STRING',
  INTEGER: 'INTEGER',
  FLOAT: 'FLOAT',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  TIMESTAMP: 'TIMESTAMP',
};

export const DEFAULT_CHUNK_SIZE = 1000;
