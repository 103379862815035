import React, { useState } from 'react';
import { Button, ButtonToolbar, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard } from 'design';
import type { Map } from 'immutable';
import _ from 'underscore';
import { strLeftBack } from 'underscore.string';

import type { CreateSinkRequestBody } from '@/api/routes/streamService';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ImportConditions from './components/ImportConditions';
import IntegrationExamples from './components/IntegrationExamples';
import Overview from './components/Overview';
import PayloadTest from './components/PayloadTest';
import TableSettings from './components/TableSettings';
import actions from './actions';
import { INITIAL_SINK } from './constants';
import { findSource } from './helpers';
import type { StoreSink } from './store';
import StreamStore from './store';

const Detail = () => {
  const { source, buckets, tables, readOnly } = useStores(
    () => {
      const { sources } = StreamStore.getStore();
      const source = findSource(sources, RoutesStore.getCurrentRouteParam('sourceId'));

      return {
        source,
        buckets: StorageBucketsStore.getAll(),
        tables: StorageTablesStore.getAll() as Map<string, any>,
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [StreamStore, StorageBucketsStore, ApplicationStore, RoutesStore],
  );
  const existingSink = source?.sinks?.[0];
  const [isSavingSink, setIsSavingSink] = useState<boolean>(false);
  const [editingSink, setEditingSink] = useState<StoreSink | CreateSinkRequestBody>(
    existingSink ?? (INITIAL_SINK as DeepMutable<typeof INITIAL_SINK>),
  );
  const [editingDescription, setEditingDescription] = useState<string | null>(null);
  const [sinkError, setSinkError] = useState<string | null>(null);

  if (!source) return null;

  const hasUnsavedChanges =
    editingSink &&
    !_.isEqual(
      _.pick(existingSink ?? INITIAL_SINK, ['name', 'table']),
      _.pick(editingSink, ['name', 'table']),
    );

  const handleSaveSink = () => {
    setIsSavingSink(true);
    setSinkError(null);

    return actions
      .saveEditingSink(editingSink, source.sourceId)
      .finally(() => setIsSavingSink(false));
  };

  const handleReset = () => {
    setEditingSink(existingSink ?? (INITIAL_SINK as DeepMutable<typeof INITIAL_SINK>));
    setSinkError(null);
  };

  const handleSaveDescription = () => {
    return actions
      .updateSource(source.sourceId, { description: editingDescription?.trim() || '' })
      .then(() => setEditingDescription(null));
  };

  return (
    <>
      <DescriptionBox
        description={source.description}
        editorValue={editingDescription}
        onSetEditorValue={setEditingDescription}
        onSave={handleSaveDescription}
        readOnly={readOnly}
        placeholder="Stream Description"
      />
      <CollapsibleBox
        defaultOpen
        title="Data Stream URL"
        hint="An endpoint for receiving events using HTTP."
      >
        <FormControl.Static bsClass="form-control form-control-static" className="flex-container">
          {source.http ? (
            <>
              <Truncated text={source.http.url} />{' '}
              <Clipboard text={source.http.url} tooltipPlacement="left" />
            </>
          ) : (
            'N/A'
          )}
        </FormControl.Static>
      </CollapsibleBox>
      <div className="box">
        <Overview tables={tables} source={source} readOnly={readOnly} />
      </div>
      <CollapsibleBox
        title="Table Settings"
        hint="A table where the data will flow from the streams. You can adjust the structure or add your own definition how tha data should be written into the table."
        additionalActions={() => (
          <CatchUnsavedChanges
            isDirty={hasUnsavedChanges && !isSavingSink}
            onSave={() => handleSaveSink()}
            onDirtyLeave={() => handleReset()}
          >
            {hasUnsavedChanges && (
              <ButtonToolbar>
                <Button onClick={() => handleReset()} disabled={isSavingSink}>
                  <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
                  Reset
                </Button>
                <Button
                  bsStyle="success"
                  onClick={() =>
                    handleSaveSink().catch((error) => setSinkError(error.message ?? error))
                  }
                  disabled={
                    isSavingSink ||
                    !strLeftBack(editingSink.table?.tableId || '', '.') ||
                    !editingSink.name?.trim() ||
                    editingSink.table?.mapping?.columns?.some((column) => !column.name.trim()) ||
                    editingSink.table?.mapping?.columns?.some(
                      (column) => column.type === 'template' && !column.template?.content.trim(),
                    )
                  }
                >
                  {isSavingSink ? 'Saving...' : 'Save'}
                </Button>
              </ButtonToolbar>
            )}
          </CatchUnsavedChanges>
        )}
      >
        <TableSettings
          buckets={buckets}
          tables={tables}
          editingSink={editingSink}
          hasExistingSink={!!existingSink}
          updateEditingSink={setEditingSink}
          isSavingSink={isSavingSink}
          error={sinkError}
          readOnly={readOnly}
        />
      </CollapsibleBox>
      <CollapsibleBox
        title="Sample Codes for Integration"
        hint="Leverage our ready made examples for different languages or try to send example payload to see the results immediately."
      >
        <IntegrationExamples sourceId={source.sourceId} sourceUrl={source.http?.url} />
      </CollapsibleBox>
      {!!existingSink && (
        <CollapsibleBox
          title="Import Conditions"
          hint='You can set import conditions that operate as an "OR" statement, ensuring that as soon as any of the conditions are met, the data will be streamed into the table.'
        >
          <ImportConditions
            sourceId={source.sourceId}
            sink={existingSink}
            isSavingSink={isSavingSink}
            readOnly={readOnly}
          />
        </CollapsibleBox>
      )}
      {!readOnly && !!source.http && (
        <CollapsibleBox
          title="Payload Test"
          hint="Run the example payload to test the Data Stream and check the result"
        >
          <PayloadTest
            sourceId={source.sourceId}
            sourceUrl={source.http.url}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        </CollapsibleBox>
      )}
    </>
  );
};

export default Detail;
