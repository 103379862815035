import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import DropboxChooser from 'react-dropbox-chooser';
import createReactClass from 'create-react-class';
import { Tabs, TabsContent } from 'design';
import { first, isEmpty } from 'underscore';

import { getDestinationName } from '@/modules/ex-dropbox-v2/actions/ApplicationActions.js';
import ConfirmButtons from '@/react/common/ConfirmButtons';

const DropboxFileSelectorModal = createReactClass({
  propTypes: {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    canSaveConfig: PropTypes.func,
    saveConfig: PropTypes.func,
    cancelConfig: PropTypes.func,
    isSaving: PropTypes.bool,
    handleCsvSelectChange: PropTypes.func,
    selectedDropboxFiles: PropTypes.array,
  },

  getInitialState() {
    return {
      activeTab: 'instant',
      name: '',
      link: '',
    };
  },

  handleCancelFunction() {
    this.props.cancelConfig();
    this.props.onHide();
  },

  handleSaveFunction() {
    if (this.state.activeTab === 'external') {
      const forceData = [
        {
          link: this.state.link,
          name: this.state.name,
          manualInsert: true,
        },
      ];
      return this.props.saveConfig(forceData).then(() => this.props.onHide());
    }
    return this.props.saveConfig().then(() => this.props.onHide());
  },

  goToTab(tab) {
    this.setState({
      activeTab: tab,
    });
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton className="tw-border-0 !tw-pb-0">
          <Modal.Title>Dropbox File Selector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            inModal
            value={this.state.activeTab}
            onValueChange={this.goToTab}
            triggers={[
              {
                value: 'instant',
                title: 'Choose From Dropbox',
              },
              {
                value: 'external',
                title: 'Insert Link Manually',
              },
            ]}
          >
            <TabsContent value="instant">
              <p>
                Please choose a CSV file you want to extract via Dropbox Chooser. It uses a pop-up
                window; hence, disable pop-up blocking for this site in the browser settings.
              </p>
              <DropboxChooser
                appKey={'2is8jmvnwbchcyr'}
                cancel={() => {}}
                success={(files) => this.onSelectFiles(files)}
                multiselect={false}
                extensions={['.csv']}
              >
                <Button bsStyle="primary" block>
                  Choose from Dropbox
                </Button>
              </DropboxChooser>
              {Array.isArray(this.props.selectedDropboxFiles) &&
                this.props.selectedDropboxFiles.length > 0 && (
                  <div>
                    <br />
                    <div>
                      <h4>Selected: {first(this.props.selectedDropboxFiles).name}</h4>
                    </div>
                  </div>
                )}
            </TabsContent>
            <TabsContent value="external">{this.renderManualInsert()}</TabsContent>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Create file"
            isSaving={this.props.isSaving}
            onSave={this.handleSaveFunction}
            isDisabled={this.canSave()}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  canSave() {
    if (this.state.activeTab === 'external') {
      return isEmpty(this.state.name) || isEmpty(this.state.link);
    }
    return this.props.canSaveConfig();
  },

  onInsertLink(e) {
    const value = e.target.value;
    let name = this.state.name;
    if (isEmpty(name)) {
      name = getDestinationName(value.split('?')[0]);
    }
    this.setState({ link: value, name: name });
  },

  renderManualInsert() {
    return (
      <div className="form form-horizontal">
        <div className="form-group">
          <label className="control-label col-xs-3">Link</label>
          <div className="col-xs-9">
            <input
              className="form-control"
              type="text"
              name="link"
              value={this.state.link}
              onChange={this.onInsertLink}
              autoFocus
            />
            <span className="help-block">Link to a CSV file shared by a Dropbox account</span>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-xs-3">Name</label>
          <div className="col-xs-9">
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              autoFocus
            />
            <span className="help-block">Name of the CSV file and the output table</span>
          </div>
        </div>
      </div>
    );
  },

  onSelectFiles(values = []) {
    this.props.handleCsvSelectChange(values);
  },
});

export default DropboxFileSelectorModal;
