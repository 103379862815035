import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import TextDivider from '@/react/common/TextDivider';

class SchedulePredefinedButtons extends React.Component {
  render() {
    return (
      <div className="schedule-predefined mt-2 mb-1">
        <p className="text-muted">Select Predefined</p>
        <div className="flex-container flex-start mb-1">
          <button
            className="btn predefined-button"
            onClick={() => this.props.onSelect('*/15 * * * *')}
          >
            Every 15 minutes
          </button>
          <button
            className="btn predefined-button"
            onClick={() => this.props.onSelect(`${_.random(0, 59)} * * * *`)}
          >
            Every hour
          </button>
          <button
            className="btn predefined-button"
            onClick={() =>
              this.props.onSelect(`${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * *`)
            }
          >
            Once a day
          </button>
          <button
            className="btn predefined-button"
            onClick={() =>
              this.props.onSelect(
                `${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * ${_.random(0, 6)}`,
              )
            }
          >
            Once a week
          </button>
        </div>
        <TextDivider className="tw-mb-3" />
        <p className="text-muted">Set Your Own</p>
      </div>
    );
  }
}

SchedulePredefinedButtons.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SchedulePredefinedButtons;
