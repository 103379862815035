import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/legacy-transformation/Constants';
import Link from '@/react/common/RouterLink';

const copyTransformationNotification = (bucketId, transformation) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },
    render() {
      return (
        <span>
          Transformation{' '}
          <Link
            to={routeNames.DETAIL}
            params={{
              config: bucketId,
              row: transformation.get('id'),
            }}
            onClick={this.props.onClick}
          >
            {transformation.get('name')}
          </Link>{' '}
          was created
        </span>
      );
    },
  });
};

export default copyTransformationNotification;
