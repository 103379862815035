import { replaceAll } from 'underscore.string';

const DEFAULT_VALID_DIMENSIONS = ['minute', 'hour', 'day'];

export default function (value, validDimensions = DEFAULT_VALID_DIMENSIONS) {
  // has to be a string
  if (typeof value !== 'string') {
    return false;
  }

  // convert "a|an minute|hour|day" to "1 minute|hour|day"
  if (value.startsWith('a ')) {
    value = value.replace('a', '1');
  } else if (value.startsWith('an ')) {
    value = value.replace('an', '1');
  }

  // remove all spaces
  const trimmedLabel = replaceAll(value, ' ', '').toLowerCase();

  // try to find a number at the beginning
  const numberPart = parseInt(value, 10);

  if (isNaN(numberPart) || numberPart === 0) {
    return false;
  }

  // trim the number from the beginning
  const dimensionPart = trimmedLabel.substring(numberPart.toString().length);

  // plural or singular? and set default
  let dimensions = validDimensions;
  if (Math.abs(numberPart) !== 1) {
    dimensions = validDimensions.map((dimension) => `${dimension}s`);
  }

  let dimensionPartFull;
  // hour is default
  if (dimensionPart.length === 0) {
    if (Math.abs(numberPart) === 1) {
      dimensionPartFull = 'hour';
    } else {
      dimensionPartFull = 'hours';
    }
  } else {
    // try to match dimension
    for (let i = 0; i < dimensions.length; i++) {
      if (dimensions[i].substring(0, dimensionPart.length) === dimensionPart) {
        dimensionPartFull = dimensions[i];
        break;
      }
    }
  }

  if (typeof dimensionPartFull === 'undefined') {
    return false;
  }

  // return absolute number + dimension
  return Math.abs(numberPart).toString() + ' ' + dimensionPartFull;
}
