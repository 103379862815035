const actionTypes = {
  LOAD_NOTIFICATIONS_SUCCESS: 'STORAGE_LOAD_NOTIFICATIONS_SUCCESS',
};

const DEFAULT_TOLERANCE = 20;

const EVENT_JOB_SUCCESS = 'job-succeeded';
const EVENT_JOB_FAILED = 'job-failed';
const EVENT_JOB_WARNING = 'job-succeeded-with-warning';
const EVENT_JOB_PROCESSING = 'job-processing-long';

const EVENT_PHASE_JOB_SUCCESS = 'phase-job-succeeded';
const EVENT_PHASE_JOB_FAILED = 'phase-job-failed';
const EVENT_PHASE_JOB_WARNING = 'phase-job-succeeded-with-warning';

const OPERATOR_EQUAL = '==';
const OPERATOR_BIGGER_OR_EQUAL = '>=';

const CHANNEL_EMAIL = 'email';
const FIELD_JOB_ID = 'job.id';
const FIELD_JOB_COMPONENT_ID = 'job.component.id';
const FIELD_JOB_CONFIGURATION_ID = 'job.configuration.id';
const FIELD_BRANCH_ID = 'branch.id';
const FIELD_JOB_DURATION_OVERTIME_PERCENTAGE = 'durationOvertimePercentage';

export {
  actionTypes,
  EVENT_JOB_SUCCESS,
  EVENT_JOB_FAILED,
  EVENT_JOB_WARNING,
  EVENT_JOB_PROCESSING,
  EVENT_PHASE_JOB_SUCCESS,
  EVENT_PHASE_JOB_FAILED,
  EVENT_PHASE_JOB_WARNING,
  OPERATOR_EQUAL,
  OPERATOR_BIGGER_OR_EQUAL,
  CHANNEL_EMAIL,
  FIELD_JOB_ID,
  FIELD_JOB_COMPONENT_ID,
  FIELD_JOB_CONFIGURATION_ID,
  FIELD_BRANCH_ID,
  FIELD_JOB_DURATION_OVERTIME_PERCENTAGE,
  DEFAULT_TOLERANCE,
};
