import React from 'react';
import type { ReactNode } from 'react';

import Checkbox from './Checkbox';

type Props = {
  hide: boolean;
  disabled: boolean;
  totalCount: number;
  selectedCount: number;
  onToggleAll: (checked: boolean) => void;
  children: ReactNode;
  placeholder: ReactNode;
  entity: string;
};

const MultiActionsHeader = ({
  hide,
  disabled,
  totalCount,
  selectedCount,
  onToggleAll,
  children,
  placeholder,
  entity,
}: Props) => {
  if (hide) {
    return placeholder;
  }

  const isAllSelected = !!selectedCount && selectedCount === totalCount;
  const isSomeSelected = !!selectedCount && selectedCount !== totalCount;

  return (
    <div className="flex-container flex-start tw-whitespace-nowrap">
      <Checkbox
        className="mr-1"
        tooltip={`${isAllSelected || isSomeSelected ? 'Deselect' : 'Select'} all ${entity}s`}
        checked={isAllSelected}
        onChange={onToggleAll}
        indeterminate={!!selectedCount && !isAllSelected}
        disabled={disabled}
      />
      {selectedCount ? (
        <>
          <strong>
            {selectedCount} {entity}
            {selectedCount > 1 ? 's ' : ' '}
            selected
          </strong>
          {children}
        </>
      ) : (
        placeholder
      )}
    </div>
  );
};

export default MultiActionsHeader;
