import { fromJS, List, Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import ApplicationStore from '@/stores/ApplicationStore';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes } from './Constants';

let _store = initStore(
  'SandboxesStore',
  Map({
    settings: Map(),
    sandboxes: Map(),
    pendingActions: Map(),
    processingJobs: List(),
    settingsLoaded: false,
    isLoading: false,
    isLoaded: false,
  }),
);

const SandboxesStore = StoreUtils.createStore({
  getSandboxes() {
    return _store.get('sandboxes', Map());
  },

  getSandbox(id) {
    return _store.getIn(['sandboxes', id], Map());
  },

  getIsLoading() {
    return _store.get('isLoading', false);
  },

  getIsLoaded() {
    return _store.get('isLoaded', false);
  },

  isDetailLoaded(id) {
    return _store.hasIn(['sandboxes', id, 'password'], false);
  },

  getPendingActions() {
    return _store.get('pendingActions', Map());
  },

  getProcessingJobs() {
    return _store.get('processingJobs', List());
  },

  isSettingsLoaded() {
    return _store.get('settingsLoaded', false);
  },

  getMlflowInstanceUrl() {
    return _store.getIn(['settings', 'mlflowUri'], '');
  },
});

dispatcher.register((payload) => {
  const { action } = payload;
  switch (action.type) {
    case ActionTypes.PROJECT_SETTINGS_LOAD_SUCCESS:
      _store = _store.set('settingsLoaded', true).set('settings', fromJS(action.settings));
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_LOAD:
      _store = _store.set('isLoading', true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOX_LOAD_SUCCESS:
      _store = _store.setIn(['sandboxes', action.sandbox.id], fromJS(action.sandbox));
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_LOAD_SUCCESS: {
      const sandboxes = fromJS(action.sandboxes)
        .filter((sandbox) => {
          return (
            sandbox.get('shared') ||
            sandbox.get('tokenId') === ApplicationStore.getSapiToken().get('id')
          );
        })
        .map((sandbox) => {
          if (!!_store.getIn(['sandboxes', sandbox.get('id'), 'password'])) {
            return sandbox.set(
              'password',
              _store.getIn(['sandboxes', sandbox.get('id'), 'password']),
            );
          }

          if (!!_store.getIn(['sandboxes', sandbox.get('id'), 'credentials'])) {
            return sandbox.set(
              'credentials',
              _store.getIn(['sandboxes', sandbox.get('id'), 'credentials']),
            );
          }

          return sandbox;
        })
        .toMap()
        .mapKeys((key, sandbox) => sandbox.get('id'));

      _store = _store.set('isLoading', false).set('isLoaded', true).set('sandboxes', sandboxes);
      return SandboxesStore.emitChange();
    }

    case ActionTypes.SANDBOXES_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_DELETE:
      _store = _store.setIn(['pendingActions', 'delete', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_DELETE_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'delete', action.id]);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_RESTORE:
      _store = _store.setIn(['pendingActions', 'restore', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_RESTORE_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'restore', action.id]);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_TERMINATE:
      _store = _store.setIn(['pendingActions', 'terminate', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_TERMINATE_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'terminate', action.id]);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_SHARE:
      _store = _store.setIn(['pendingActions', 'share', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_SHARE_SUCCESS:
      _store = _store
        .deleteIn(['pendingActions', 'share', action.id])
        .setIn(['sandboxes', action.sandbox.id], fromJS(action.sandbox));
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOX_UPDATE_PARAMETERS:
      _store = _store.setIn(['pendingActions', 'update', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOX_UPDATE_PARAMETERS_SUCCESS:
      _store = _store
        .deleteIn(['pendingActions', 'update', action.id])
        .setIn(['sandboxes', action.sandbox.id], fromJS(action.sandbox));
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_LOAD_DATA:
      _store = _store.setIn(['pendingActions', 'load-data', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_LOAD_DATA_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'load-data', action.id]);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_UNLOAD_DATA:
      _store = _store.setIn(['pendingActions', 'unload-data', action.id], true);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_UNLOAD_DATA_SUCCESS:
      _store = _store.deleteIn(['pendingActions', 'unload-data', action.id]);
      return SandboxesStore.emitChange();

    case ActionTypes.SANDBOXES_PROCESSING_JOBS_LOADED:
      _store = _store.set('processingJobs', fromJS(action.jobs));
      return SandboxesStore.emitChange();

    default:
      break;
  }
});

export default SandboxesStore;
