import React from 'react';
import { Map } from 'immutable';

import TimeAndUser from '@/react/common/TimeAndUser';

type Props = {
  configuration: Map<string, any>;
  admins: Map<string, any>;
};

const LastChange = (props: Props) => {
  const currentVersions = props.configuration.get('currentVersion', Map());

  if (currentVersions.isEmpty()) return 'a few seconds ago';

  return (
    <TimeAndUser
      showAvatar={false}
      admin={props.admins.get(currentVersions.getIn(['creatorToken', 'description']))}
      time={currentVersions.get('created')}
      fallbackName={currentVersions.getIn(['creatorToken', 'description'])}
    />
  );
};

const MemoizedLastChange = React.memo(LastChange);

export default MemoizedLastChange;
