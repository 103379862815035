import React from 'react';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { CONTAINER_BASED } from '@/modules/sandboxes/Constants';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import RoutesStore from '@/stores/RoutesStore';

const SandboxStatusLabel = createReactClass({
  mixins: [createStoreMixin(RoutesStore, InstalledComponentsStore, SandboxesStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const configData = InstalledComponentsStore.getConfigData(KEBOOLA_SANDBOXES, configId);

    return {
      sandbox: SandboxesStore.getSandbox(configData.getIn(['parameters', 'id'])),
    };
  },

  render() {
    if (!this.state.sandbox.count()) {
      return null;
    }

    const isActive = this.state.sandbox.get('active', false);

    return (
      <span
        className={classnames('workspace-status-label', {
          'text-success': isActive,
          'bg-color-purple': !isActive && CONTAINER_BASED.includes(this.state.sandbox.get('type')),
        })}
      >
        {this.renderText()}
      </span>
    );
  },

  renderText() {
    if (this.state.sandbox.get('active', false)) {
      return 'ACTIVE';
    }

    if (CONTAINER_BASED.includes(this.state.sandbox.get('type'))) {
      return 'SLEEPING';
    }

    return 'INACTIVE';
  },
});

export default SandboxStatusLabel;
