import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ConfigurationRowEditField from '@/modules/components/react/components/ConfigurationRowEditField';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

const ConfigurationRowName = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    rowId: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  },

  getDefaultProps() {
    return {
      placeholder: 'My Configuration',
    };
  },

  render() {
    return (
      <ConfigurationRowEditField
        key={`name-${this.props.componentId}-${this.props.configId}-${this.props.rowId}`}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        fieldName="name"
        editElement={InlineEditTextInput}
        placeholder={this.props.placeholder}
        tooltipPlacement="bottom"
      />
    );
  },
});

export default ConfigurationRowName;
