export default {
  ActionTypes: {
    STORAGE_TOKENS_LOAD: 'STORAGE_TOKENS_LOAD',
    STORAGE_TOKENS_LOAD_SUCCESS: 'STORAGE_TOKENS_LOAD_SUCCESS',
    STORAGE_TOKENS_LOAD_ERROR: 'STORAGE_TOKENS_LOAD_ERROR',
    STORAGE_TOKEN_CREATE: 'STORAGE_TOKEN_CREATE',
    STORAGE_TOKEN_CREATE_SUCCESS: 'STORAGE_TOKEN_CREATE_SUCCESS',
    STORAGE_TOKEN_CREATE_ERROR: 'STORAGE_TOKEN_CREATE_ERROR',
    STORAGE_TOKEN_SEND: 'STORAGE_TOKEN_SEND',
    STORAGE_TOKEN_SEND_SUCCESS: 'STORAGE_TOKEN_SEND_SUCCESS',
    STORAGE_TOKEN_SEND_ERROR: 'STORAGE_TOKEN_SEND_ERROR',
    STORAGE_TOKEN_DELETE: 'STORAGE_TOKEN_DELETE',
    STORAGE_TOKEN_DELETE_SUCCESS: 'STORAGE_TOKEN_DELETE_SUCCESS',
    STORAGE_TOKEN_DELETE_ERROR: 'STORAGE_TOKEN_DELETE_ERROR',
    STORAGE_TOKEN_REFRESH: 'STORAGE_TOKEN_REFRESH',
    STORAGE_TOKEN_REFRESH_SUCCESS: 'STORAGE_TOKEN_REFRESH_SUCCESS',
    STORAGE_TOKEN_REFRESH_ERROR: 'STORAGE_TOKEN_REFRESH_ERROR',
    STORAGE_TOKEN_API_ERROR: 'STORAGE_TOKEN_API_ERROR',
    STORAGE_TOKEN_UPDATE_LOCALSTATE: 'STORAGE_TOKEN_UPDATE_LOCALSTATE',
    STORAGE_TOKEN_UPDATE_SUCCESS: 'STORAGE_TOKEN_UPDATE_SUCCESS',
  },
};
