import { KEBOOLA_WR_ONEDRIVE } from '@/constants/componentIds';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import createOauthSection from '@/modules/configurations/utils/createOauthSection';
import createRoute from '@/modules/configurations/utils/createRoute';
import conform from './adapters/conform';
import destinationAdapter from './adapters/destination';
import inputMappingAdapter from './adapters/inputMapping';
import DestinationSection from './react/Destination';
import InputMappingSection from './react/InputMapping';

const routeSettings = {
  componentId: KEBOOLA_WR_ONEDRIVE,
  index: {
    authRequired: true,
    sections: [createOauthSection()],
  },
  row: {
    hasState: false,
    onConform: conform,
    sections: [
      {
        render: InputMappingSection,
        onSave: inputMappingAdapter.createConfiguration,
        onLoad: inputMappingAdapter.parseConfiguration,
        onCreate: inputMappingAdapter.createEmptyConfiguration,
      },
      {
        render: DestinationSection,
        onSave: destinationAdapter.createConfiguration,
        onLoad: destinationAdapter.parseConfiguration,
        onCreate: destinationAdapter.createEmptyConfiguration,
      },
    ],
    columns: [
      {
        name: 'Source Table',
        type: columnTypes.TABLE_LINK,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'source'], 'Unknown');
        },
      },
      {
        name: 'Destination Table',
        type: columnTypes.VALUE,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'destination'], 'Unknown');
        },
      },
    ],
  },
};

const result = createRoute(routeSettings);

export default result;
