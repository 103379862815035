import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import type { IconColor } from './CircleIcon';
import CircleIcon from './CircleIcon';

type Props = {
  icon?: IconProp;
  iconUrl?: string;
  color?: IconColor;
  generatedBackground?: string;
  bold?: boolean;
};

const ModalIcon = ({ icon, iconUrl, color, bold = false, generatedBackground }: Props) => {
  return (
    <CircleIcon
      className="modal-icon"
      icon={icon}
      iconUrl={iconUrl}
      color={color}
      bold={bold}
      bigger
      generatedBackground={generatedBackground}
    />
  );
};

ModalIcon.Clock = () =>
  React.createElement(ModalIcon, { color: 'green', icon: 'clock', bold: true });
ModalIcon.Code = () => React.createElement(ModalIcon, { color: 'blue', icon: 'code', bold: true });
ModalIcon.Edit = () => React.createElement(ModalIcon, { color: 'green', icon: 'pen', bold: true });
ModalIcon.Play = () => React.createElement(ModalIcon, { color: 'blue', icon: 'play', bold: true });
ModalIcon.Plus = () => React.createElement(ModalIcon, { color: 'green', icon: 'plus', bold: true });
ModalIcon.Clone = () =>
  React.createElement(ModalIcon, { color: 'green', icon: 'clone', bold: true });
ModalIcon.Reply = () =>
  React.createElement(ModalIcon, { color: 'green', icon: 'reply', bold: true });
ModalIcon.Trash = () => React.createElement(ModalIcon, { color: 'red', icon: 'trash', bold: true });
ModalIcon.Upload = () =>
  React.createElement(ModalIcon, { color: 'green', icon: 'upload', bold: true });
ModalIcon.Database = () =>
  React.createElement(ModalIcon, { color: 'green', icon: 'database', bold: true });

export default ModalIcon;
