import React from 'react';
import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/components-directory/constants';
import Link from '@/react/common/RouterLink';

const configurationRowDeleted = (componentId, configurationId, row) => {
  return createReactClass({
    render() {
      return (
        <>
          Row{' '}
          <Link
            to={routeNames.GENERIC_CONFIG_VERSIONS}
            params={{ component: componentId, config: configurationId }}
          >
            {row.get('name') || 'Untitled'}
          </Link>{' '}
          has been deleted.
        </>
      );
    },
  });
};

export default configurationRowDeleted;
