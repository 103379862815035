import React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { cn } from '../utils/classNames';

const DELAY_SHOW = 500;
const ACTION_TOOLTIP_LIMIT = 30;

const TYPE_STYLES = {
  action:
    'tw-font-medium tw-text-xs tw-text-center tw-uppercase tw-tracking-wider tw-px-3 tw-py-2 tw-max-w-fit',
  explanatory: 'tw-font-normal tw-text-sm tw-text-left tw-px-4 tw-py-3 tw-max-w-80',
};

export type Props = {
  tooltip: React.ReactNode;
  children: React.ReactNode;
  type?: 'action' | 'explanatory' | 'auto';
  placement?: 'top' | 'right' | 'bottom' | 'left';
  allowHoverTooltip?: boolean;
  forceHide?: boolean;
  forceShow?: boolean;
  className?: string;
  triggerClassName?: string;
  triggerOnClick?: (e: React.SyntheticEvent) => void;
};

const TooltipProvider = RadixTooltip.Provider;

const Tooltip = ({
  tooltip,
  children,
  type = 'action',
  placement = 'right',
  allowHoverTooltip = false,
  forceHide = false,
  forceShow = false,
  className,
  triggerClassName,
  triggerOnClick,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const resolveType = () => {
    if (type === 'auto') {
      return typeof tooltip === 'string' && tooltip.length > ACTION_TOOLTIP_LIMIT
        ? 'explanatory'
        : 'action';
    }

    return type;
  };

  return (
    <RadixTooltip.Root
      open={(open || forceShow) && !forceHide && !!tooltip}
      onOpenChange={setOpen}
      delayDuration={DELAY_SHOW}
      disableHoverableContent={!allowHoverTooltip}
    >
      <RadixTooltip.Trigger asChild>
        <span data-tooltip-trigger onClick={triggerOnClick} className={triggerClassName}>
          {children}
        </span>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          data-tooltip-content
          hideWhenDetached
          sideOffset={8}
          collisionPadding={8}
          side={placement}
          className={cn(
            'tw-inline-block tw-rounded tw-bg-neutral-800 tw-text-white tw-break-anywhere',
            TYPE_STYLES[resolveType()],
            className,
          )}
        >
          {tooltip}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

export { Tooltip, TooltipProvider };
