import { DEV_CREDENTIALS } from '@/constants/localStorageKeys';
import { getItem, setItem } from '@/utils/localStorage';

type Credentials = {
  email: string;
  projectName: string;
  host: string;
  storageApiToken: string;
  manageApiToken?: string;
};

const saveDevCredentials = (history: Credentials[], newCredentials: Credentials) => {
  const allCredentials = history.filter(
    ({ host, storageApiToken, manageApiToken }) =>
      newCredentials.host !== host ||
      newCredentials.storageApiToken !== storageApiToken ||
      newCredentials.manageApiToken !== manageApiToken,
  );

  allCredentials.push(newCredentials);

  setDevCredentials(allCredentials);
};

const setDevCredentials = (history: Credentials[]) => {
  setItem(DEV_CREDENTIALS, history.slice(-20));
};

const getDevCredentials = () => {
  const allCredentials = getItem(DEV_CREDENTIALS, []);

  // Mapping old structure to new one.
  if (!Array.isArray(allCredentials)) {
    return [allCredentials];
  }

  return allCredentials;
};

export { Credentials, saveDevCredentials, setDevCredentials, getDevCredentials };
