import React, { useState } from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import classNames from 'classnames';
import { Alert, HelpBlock, Link, Tooltip } from 'design';
import { Map } from 'immutable';
import _ from 'underscore';
import { strLeftBack } from 'underscore.string';

import type { CreateSinkRequestBody, TableColumn } from '@/api/routes/streamService';
import { getDefaultBucketName } from '@/modules/components/helpers';
import { filterProductionAndCurrentDevBranchBuckets } from '@/modules/dev-branches/helpers';
import { nameWarning } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import type { StoreSink } from '@/modules/stream/store';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import Checkbox from '@/react/common/Checkbox';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import ConnectorIcon from '@/react/common/ConnectorIcon';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import MarkedText from '@/react/common/MarkedText';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import Truncated from '@/react/common/Truncated';
import string from '@/utils/string';

const COLUMN_TYPES = {
  uuid: 'ID',
  datetime: 'DATETIME',
  ip: 'IP',
  body: 'BODY',
  headers: 'HEADERS',
  template: 'TEMPLATE (JSONNET)',
};

type Props = {
  buckets: Map<string, any>;
  tables: Map<string, any>;
  editingSink: StoreSink | CreateSinkRequestBody;
  hasExistingSink: boolean;
  updateEditingSink: (mapping: StoreSink | CreateSinkRequestBody) => void;
  isSavingSink: boolean;
  error: string | null;
  readOnly: boolean;
};

const StreamMapping = (props: Props) => {
  const [bucketSearch, setBucketSearch] = useState('');
  const [editingTemplateColumn, setEditingTemplateColumn] = useState<
    (TableColumn & { index: number }) | null
  >(null);
  const bucketId = strLeftBack(props.editingSink.table?.tableId || '', '.');

  const setMappingColumns = (setter: (columns: TableColumn[]) => TableColumn[]) => {
    if (!props.editingSink.table?.mapping) return;

    props.updateEditingSink({
      ...props.editingSink,
      table: {
        ...props.editingSink.table,
        mapping: {
          ...props.editingSink.table.mapping,
          columns: setter(props.editingSink.table.mapping.columns),
        },
      },
    });
  };

  const changeColumn = (index: number, changes?: Partial<TableColumn>) => {
    setMappingColumns((columns) => {
      if (!changes) {
        return columns.filter((column, columnIndex) => index !== columnIndex);
      }

      if (index >= columns.length) {
        return columns.concat(changes as TableColumn);
      }

      return columns.map((column, columnIndex) => {
        if (index !== columnIndex) return column;

        if (column.type !== 'template' && column.template) {
          delete column.template;
        }

        return { ...column, ...changes };
      });
    });
  };

  const filteredBuckets = filterProductionAndCurrentDevBranchBuckets(props.buckets);
  const currentBucket = filteredBuckets.get(bucketId, Map());

  const constructTableId = (bucketId: string, tableName: string) => {
    return `${
      filteredBuckets.has(bucketId) ? bucketId : `in.${getDefaultBucketName(bucketId)}`
    }.${tableName}`;
  };

  return (
    <>
      {props.error && (
        <Alert variant="error" className="tw-mb-5 tw-mt-2">
          {props.error}
        </Alert>
      )}
      <div className="flex-container">
        <FormGroup className="fill-space">
          <ControlLabel>Bucket</ControlLabel>
          {!!props.hasExistingSink ? (
            <FormControl.Static bsClass="form-control form-control-static mb-0 flex-container flex-start">
              <FontAwesomeIcon icon="folder" className="color-base icon-addon-right" />
              {!currentBucket.isEmpty() && (
                <>
                  <BucketStageLabel placement="left" stage={currentBucket.get('stage')} />
                  <DevBranchLabel bucket={currentBucket} />
                </>
              )}
              <Truncated text={currentBucket.get('displayName', bucketId)} className="mtp-1" />
            </FormControl.Static>
          ) : (
            <Select
              allowCreate
              hideSelectAllOptions
              placeholder="Select or create bucket"
              value={bucketId}
              inputValue={bucketSearch}
              onChange={(newBucketId: string) => {
                if (!props.editingSink.table?.mapping) return;

                props.updateEditingSink({
                  ...props.editingSink,
                  table: {
                    ...props.editingSink.table,
                    tableId: constructTableId(newBucketId, props.editingSink.name),
                  },
                });
              }}
              onInputChange={(newValue) =>
                setBucketSearch(string.sanitizeKbcTableIdString(newValue))
              }
              options={filteredBuckets
                .sortBy(bucketDisplayNameWithStage)
                .map((bucket: Map<string, any>) => ({
                  value: bucket.get('id'),
                  name: bucket.get('displayName'),
                  label: (
                    <div className="flex-container flex-start">
                      <FontAwesomeIcon icon="folder" className="color-base icon-addon-right" />
                      <BucketStageLabel placement="left" stage={bucket.get('stage')} />
                      <DevBranchLabel bucket={bucket} />
                      <MarkedText
                        className="font-medium"
                        source={bucket.get('displayName')}
                        mark={bucketSearch}
                      />
                    </div>
                  ),
                }))
                .toArray()}
              disabled={props.readOnly || props.isSavingSink}
            />
          )}
        </FormGroup>
        <FormGroup className="mlp-6 fill-space">
          <ControlLabel>Table Name</ControlLabel>
          <FormControl
            type="text"
            value={props.editingSink.name || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!props.editingSink.table?.mapping) return;

              const tableName = string.sanitizeKbcTableIdString(event.target.value);

              props.updateEditingSink({
                ...props.editingSink,
                name: tableName,
                table: {
                  ...props.editingSink.table,
                  tableId: constructTableId(bucketId, tableName),
                },
              });
            }}
            placeholder="Table name"
            disabled={props.readOnly || props.isSavingSink}
          />
        </FormGroup>
      </div>
      <HelpBlock>
        Please note Keboola is case sensitive, you most likely should use UPPERCASE name of the
        table in the output mapping. {nameWarning}
      </HelpBlock>
      <hr className="mtp-6 mr-0 mbp-6 ml-0" />
      <Table className="compact">
        <thead>
          <tr className="no-shadow">
            <th className="pl-0">Column Name</th>
            <th className="pl-0">Type</th>
            <th className="text-center">Primary Key</th>
          </tr>
        </thead>
        <tbody>
          {props.editingSink.table?.mapping?.columns.map((column, index) => {
            const hasDuplicitName = props.editingSink.table?.mapping?.columns.some(
              (column2, column2Index) => column2.name === column.name && column2Index !== index,
            );

            return (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className={classNames('w-300 pl-0 pr-2 overflow-break-anywhere', {
                      'pb-0': hasDuplicitName,
                    })}
                  >
                    <FormGroup validationState={hasDuplicitName ? 'error' : null} className="mb-0">
                      <FormControl
                        type="text"
                        value={column.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          changeColumn(index, {
                            name: event.target.value.trim(),
                          })
                        }
                        disabled={props.readOnly || props.isSavingSink}
                      />
                      {hasDuplicitName && (
                        <HelpBlock variant="danger" className="mbp-1">
                          The column is already defined.
                        </HelpBlock>
                      )}
                    </FormGroup>
                  </td>
                  <td className="w-300 pl-0 vertical-text-top">
                    <Select
                      clearable={false}
                      placeholder="Select type..."
                      value={column.type}
                      onChange={(columnType: string) =>
                        changeColumn(index, {
                          type: columnType as keyof typeof COLUMN_TYPES,
                        })
                      }
                      options={Object.entries(COLUMN_TYPES).map(([value, label]) => ({
                        value,
                        label,
                      }))}
                      disabled={props.readOnly || props.isSavingSink}
                    />
                  </td>
                  <td className="w-100 text-center">
                    <Checkbox
                      checked={column.primaryKey}
                      onChange={(checked) =>
                        changeColumn(index, {
                          primaryKey: checked,
                        })
                      }
                      disabled={props.readOnly || props.isSavingSink}
                    />
                  </td>
                  <td className="w-50 text-center">
                    <Tooltip placement="top" tooltip="Delete Column">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => changeColumn(index)}
                        disabled={
                          props.readOnly ||
                          props.isSavingSink ||
                          props.editingSink.table?.mapping?.columns.length === 1
                        }
                      >
                        <FontAwesomeIcon icon="trash" fixedWidth />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
                {column.type === 'template' && (
                  <tr>
                    <td colSpan={2} className="pt-0 pb-0 plp-3 prp-2">
                      <div className="flex-container">
                        <ConnectorIcon className="mrp-3" />
                        <Button
                          block
                          className={classNames(
                            'stream-mapping-template-code-button text-left no-uppercase f-14 line-height-20',
                            column.template?.content.trim() ? 'color-main' : 'text-muted',
                          )}
                          onClick={() => setEditingTemplateColumn({ ...column, index })}
                          disabled={props.readOnly || props.isSavingSink}
                        >
                          <FontAwesomeIcon
                            icon="code"
                            className={classNames(
                              'icon-addon-right f-12',
                              column.template?.content.trim() ? 'text-success' : 'text-muted',
                            )}
                          />
                          {column.template?.content.trim() ? 'Code' : 'Add Code'}
                          {column.name ? ` - ${column.name}` : ''}
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className="flex-container mtp-4">
        <Button
          onClick={() =>
            changeColumn(props.editingSink.table?.mapping?.columns.length ?? 0, {
              name: '',
              type: 'body',
              primaryKey: false,
            })
          }
          disabled={
            props.readOnly ||
            props.isSavingSink ||
            props.editingSink.table?.mapping?.columns.some((col) => !col.name)
          }
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Add Column
        </Button>
        <span>
          Read the documentation for{' '}
          <Link href={`${URLS.DEVELOPERS_DOCUMENTATION}/integrate/data-streams/overview/#columns`}>
            column definitions
          </Link>
          .
        </span>
      </div>
      {!!editingTemplateColumn && (
        <CodeEditorModal
          autoFocus
          editorKey={`${editingTemplateColumn.index}-column-template-code`}
          title={editingTemplateColumn.name ? `Code - ${editingTemplateColumn.name}` : 'Code'}
          value={editingTemplateColumn.template?.content ?? ''}
          onChange={(content: string) => {
            setEditingTemplateColumn((column) => ({
              ...(column as TableColumn & { index: number }),
              template: { language: 'jsonnet', content },
            }));
          }}
          onClose={() => setEditingTemplateColumn(null)}
          renderAdditionalButtons={() => (
            <SaveButtons
              isSaving={props.isSavingSink}
              isChanged={
                !_.isEqual(
                  editingTemplateColumn.template?.content,
                  props.editingSink.table?.mapping?.columns[editingTemplateColumn.index]?.template
                    ?.content,
                )
              }
              onSave={() => {
                const { index, ...column } = editingTemplateColumn;
                changeColumn(index, column);
                setEditingTemplateColumn(null);
              }}
              onReset={() => {
                setEditingTemplateColumn(
                  (column) =>
                    column && {
                      index: column!.index,
                      ...props.editingSink.table!.mapping!.columns[column!.index],
                    },
                );
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default StreamMapping;
