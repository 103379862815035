import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import CredentialsBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import ValidUntilWithIcon from '@/react/common/ValidUntilWithIcon';

const RStudioCredentials = createReactClass({
  propTypes: {
    credentials: PropTypes.object,
    expiration: PropTypes.string,
    isCreating: PropTypes.bool,
  },

  render() {
    if (this.props.isCreating) {
      return (
        <>
          <Loader className="icon-addon-right" />
          Creating sandbox
        </>
      );
    }

    if (this.props.credentials.get('id')) {
      return this._renderCredentials();
    }

    return 'Sandbox not running';
  },

  _renderCredentials() {
    const rows = Map({
      User: this.props.credentials.get('user'),
      Password: {
        protected: true,
        text: this.props.credentials.get('password'),
      },
      Expires: {
        noCopy: true,
        component: <ValidUntilWithIcon validUntil={this.props.expiration} />,
      },
    });

    return <CredentialsBox rows={rows} />;
  },
});

export default RStudioCredentials;
