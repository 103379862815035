import React from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'design';
import { strLeftBack, strRightBack } from 'underscore.string';

import { canAccessProject } from '@/modules/admin/privileges';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import type { ParsedFQID } from '@/modules/lineage/fqid';
import { FQID_TYPES } from '@/modules/lineage/fqid';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import RoutesStore from '@/stores/RoutesStore';

const LineageLink = (props: {
  params: ParsedFQID | Record<string, never>;
  children: ReactNode;
}) => {
  const href = (() => {
    switch (props.params.type) {
      case FQID_TYPES.config: {
        const linkParams = resolveRouterLinkParams(
          props.params.componentId,
          props.params.configId,
          null,
          true,
        );

        return linkParams
          ? RoutesStore.getRouter().createHref(linkParams.to, linkParams.params)
          : null;
      }

      case FQID_TYPES.table: {
        return props.params.tableId
          ? RoutesStore.getRouter().createHref(storageRouteNames.TABLE, {
              bucketId: strLeftBack(props.params.tableId, '.'),
              tableName: strRightBack(props.params.tableId, '.'),
            })
          : null;
      }

      case FQID_TYPES.bucket: {
        return props.params.bucketId
          ? RoutesStore.getRouter().createHref(storageRouteNames.BUCKET, {
              bucketId: props.params.bucketId,
            })
          : null;
      }

      default:
        return null;
    }
  })();

  if (!href || !canAccessProject(props.params.projectId)) {
    return <span className="tw-break-all tw-text-right tw-text-neutral-400">{props.children}</span>;
  }

  return (
    <Link
      href={window.location.origin + href.replace(/\d+/, props.params.projectId)}
      className="text-muted tw-flex tw-items-center tw-break-all !tw-text-right hover:tw-text-secondary-500"
    >
      {props.children}
      <FontAwesomeIcon className="tw-ml-1 tw-py-0.5 tw-pl-1" icon="arrow-up-right-from-square" />
    </Link>
  );
};

export default LineageLink;
