import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import Markdown from '@/react/common/Markdown';
import Select from '@/react/common/Select';

const ConfigurationTemplateSelector = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    templates: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  render() {
    return (
      <>
        <FormGroup>
          <Select
            clearable={false}
            placeholder="Select template..."
            onChange={this.handleSelectorChange}
            value={this.getSelectedValue()}
            options={this.props.templates
              .map((template) => ({
                value: JSON.stringify(template.toJS()),
                label: template.get('name'),
              }))
              .toArray()}
            disabled={this.props.readOnly}
          />
        </FormGroup>
        {this.templateDescription()}
      </>
    );
  },

  templateDescription() {
    if (!this.props.value) {
      return null;
    }

    return <Markdown source={this.props.value.get('description', '')} collapsible={false} />;
  },

  getSelectedValue() {
    const value = this.props.templates.find((option) => {
      return _.isEqual(option.toJS(), this.props.value.toJS());
    });
    return typeof value !== 'undefined' ? JSON.stringify(value.toJS()) : '';
  },

  handleSelectorChange(value) {
    const selectedTemplate = fromJS(JSON.parse(value));
    this.props.onChange(selectedTemplate || Map());
  },
});

export default ConfigurationTemplateSelector;
