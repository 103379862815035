import React from 'react';
import { Alert } from 'design';
import type { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import { KEBOOLA_DATA_APPS, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import SandboxActions from '@/modules/sandboxes/Actions';
import { hasBasicAuthEnabled } from '@/modules/sandboxes/helpers';
import Confirm from '@/react/common/Confirm';

const ResetPassword = (props: {
  id: string;
  configId: string;
  isDataApp: boolean;
  config?: Map<string, any>;
}) => {
  const [isResetting, setIsResetting] = React.useState(false);
  const entity = props.isDataApp ? 'data app' : 'workspace';

  const handleConfirm = React.useCallback(() => {
    const additionalParams = !!props.config?.hasIn(['configuration', 'authorization'])
      ? { authorization: props.config.getIn(['configuration', 'authorization']).toJS() }
      : {};

    setIsResetting(true);
    return SandboxActions.resetPassword(
      props.isDataApp ? KEBOOLA_DATA_APPS : KEBOOLA_SANDBOXES,
      props.id,
      props.configId,
      additionalParams,
    ).finally(() => {
      return setIsResetting(false);
    });
  }, [props.id, props.configId, props.isDataApp, props.config]);

  if (props.isDataApp && !hasBasicAuthEnabled(props.config)) {
    return null;
  }

  return (
    <Alert className="tw-mt-4">
      To reset password{' '}
      <Confirm
        icon="key"
        buttonType="primary"
        title={`Reset ${capitalize(entity)} Password`}
        text={`Are you sure you want to reset the ${entity} password?`}
        buttonLabel={isResetting ? 'Resetting...' : 'Reset'}
        onConfirm={handleConfirm}
        isLoading={isResetting}
        closeAfterResolve
      >
        <button className="btn btn-link btn-link-inline">click here</button>
      </Confirm>
      .
    </Alert>
  );
};

export default ResetPassword;
