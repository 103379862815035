import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN,
  KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN,
  KEBOOLA_PROCESSOR_CREATE_MANIFEST,
  KEBOOLA_PROCESSOR_DECOMPRESS,
  KEBOOLA_PROCESSOR_FLATTEN_FOLDERS,
  KEBOOLA_PROCESSOR_MOVE_FILES,
  KEBOOLA_PROCESSOR_SKIP_LINES,
} from '@/constants/componentIds';

const createConfiguration = function (localState) {
  let config = fromJS({
    parameters: {
      file: {
        file_name: localState.get('file_name', ''),
        primary_key: localState.get('primary_key', []),
        new_files_only: localState.get('new_files_only', false),
        add_timestamp: localState.get('add_timestamp', false),
        incremental: localState.get('incremental', false),
        storage: localState.get('storage', ''),
      },
    },
  });

  let skipLinesProcessor;
  let decompressProcessor;
  let flattenFoldersProcessor;
  let createManifestProcessor = fromJS({
    definition: {
      component: KEBOOLA_PROCESSOR_CREATE_MANIFEST,
    },
    parameters: {
      delimiter: localState.get('delimiter', ','),
      enclosure: localState.get('enclosure', '"'),
      incremental: localState.get('incremental', false),
      primary_key: localState.get('primary_key', []),
    },
  });

  if (localState.get('columnsFrom', 'header') === 'header') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns_from'],
      'header',
    );
    skipLinesProcessor = fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_SKIP_LINES,
      },
      parameters: {
        lines: 1,
      },
    });
  } else if (localState.get('columnsFrom') === 'auto') {
    createManifestProcessor = createManifestProcessor.setIn(['parameters', 'columns_from'], 'auto');
  } else if (localState.get('columnsFrom', 'manual') === 'manual') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns'],
      localState.get('columns', List()),
    );
  }

  if (localState.get('decompress', false) === true) {
    decompressProcessor = fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_DECOMPRESS,
      },
    });
    flattenFoldersProcessor = fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_FLATTEN_FOLDERS,
      },
      parameters: {
        starting_depth: 1,
      },
    });
  }

  let processors = List();

  if (decompressProcessor) {
    processors = processors.push(decompressProcessor);
  }

  processors = processors.push(
    fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_MOVE_FILES,
      },
      parameters: {
        direction: 'tables',
        addCsvSuffix: true,
        folder: localState.get('storage', ''),
      },
    }),
  );

  if (flattenFoldersProcessor) {
    processors = processors.push(flattenFoldersProcessor);
  }

  processors = processors.push(createManifestProcessor);

  if (skipLinesProcessor) {
    processors = processors.push(skipLinesProcessor);
  }

  if (localState.get('addRowNumberColumn')) {
    processors = processors.push(
      fromJS({
        definition: {
          component: KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN,
        },
        parameters: {
          column_name: 'azure_row_number',
        },
      }),
    );
  }

  if (localState.get('addFilenameColumn')) {
    processors = processors.push(
      fromJS({
        definition: {
          component: KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN,
        },
        parameters: {
          column_name: 'azure_filename',
        },
      }),
    );
  }

  config = config.setIn(['processors', 'after'], processors);

  return config;
};

const parseConfiguration = function (configuration) {
  const afterProcessors = configuration.getIn(['processors', 'after'], List());
  const processorCreateManifest = afterProcessors.find(
    (processor) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_CREATE_MANIFEST;
    },
    null,
    Map(),
  );
  const processorDecompress = afterProcessors.find((processor) => {
    return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_DECOMPRESS;
  });
  const processorAddRowNumberColumn = afterProcessors.find((processor) => {
    return (
      processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN &&
      processor.getIn(['parameters', 'column_name']) === 'azure_row_number'
    );
  });
  const processorAddFilenameColumn = afterProcessors.find((processor) => {
    return (
      processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN &&
      processor.getIn(['parameters', 'column_name']) === 'azure_filename'
    );
  });

  let columnsFrom = processorCreateManifest.getIn(['parameters', 'columns_from'], 'header');

  if (processorCreateManifest.hasIn(['parameters', 'columns'])) {
    columnsFrom = 'manual';
  }

  return fromJS({
    file_name: configuration.getIn(['parameters', 'file', 'file_name'], ''),
    new_files_only: configuration.getIn(['parameters', 'file', 'new_files_only'], false),
    add_timestamp: configuration.getIn(['parameters', 'file', 'add_timestamp'], false),
    primary_key: configuration.getIn(['parameters', 'file', 'primary_key'], []),
    incremental: configuration.getIn(['parameters', 'file', 'incremental'], false),
    storage: configuration.getIn(['parameters', 'file', 'storage'], ''),
    delimiter: processorCreateManifest.getIn(['parameters', 'delimiter'], ','),
    enclosure: processorCreateManifest.getIn(['parameters', 'enclosure'], '"'),
    columnsFrom,
    columns: processorCreateManifest.getIn(['parameters', 'columns'], []),
    addRowNumberColumn: processorAddRowNumberColumn ? true : false,
    addFilenameColumn: processorAddFilenameColumn ? true : false,
    decompress: processorDecompress ? true : false,
  });
};

const createEmptyConfiguration = function (name, webalizedName) {
  return createConfiguration(fromJS({ storage: webalizedName }));
};

export default { createConfiguration, parseConfiguration, createEmptyConfiguration };
