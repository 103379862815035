import type { Map } from 'immutable';
import { List } from 'immutable';

const isAppConfigured = (config: Map<string, any>) => {
  return (
    !!config.getIn(['configuration', 'parameters', 'dataApp', 'git', 'repository']) ||
    !!config.getIn(['configuration', 'parameters', 'script'], List()).join('').trim()
  );
};

const prepareSlug = (name: string) => {
  return name.replace(/[^a-zA-Z0-9-]/g, '-');
};

export { isAppConfigured, prepareSlug };
