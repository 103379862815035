import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Alert, HelpBlock } from 'design';

const NameControl = (props: {
  value: string;
  onChange: (newValue: string) => void;
  error?: string;
  warning?: string;
}) => {
  return (
    <>
      {props.error && (
        <Alert variant="error" className="tw-mb-5">
          {props.error}
        </Alert>
      )}
      <FormGroup validationState={props.warning ? 'error' : null}>
        <ControlLabel>Name</ControlLabel>
        <FormControl
          autoFocus
          type="text"
          placeholder="Type a name"
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
        />
        {!!props.warning && <HelpBlock variant="danger">{props.warning}</HelpBlock>}
      </FormGroup>
    </>
  );
};

export default NameControl;
