import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import type { List, Map } from 'immutable';

import StorageApiTableLink from '@/modules/components/react/components/StorageApiTableLink';

const tableHasBeenExportedNotification = (tables: List<Map<string, any>>) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      return (
        <span>
          {tables.count() === 1 ? (
            <>
              Table{' '}
              <StorageApiTableLink tableId={tables.getIn([0, 'id'])}>
                {tables.getIn([0, 'displayName'])}
              </StorageApiTableLink>{' '}
              is ready to download.
            </>
          ) : (
            <>Tables are ready to download.</>
          )}
        </span>
      );
    },
  });
};

export default tableHasBeenExportedNotification;
