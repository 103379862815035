import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { JOBS_STATUS } from '@/modules/queue/constants';

const ICON_MAP: Record<string, IconProp> = {
  [JOBS_STATUS.CREATED]: 'circle-dot',
  [JOBS_STATUS.WAITING]: 'spinner',
  [JOBS_STATUS.PROCESSING]: 'spinner',
  [JOBS_STATUS.SUCCESS]: 'check-circle',
  [JOBS_STATUS.ERROR]: 'exclamation-circle',
  warn: 'exclamation-circle',
  [JOBS_STATUS.WARNING]: 'exclamation-circle',
  canceled: 'times-circle',
  [JOBS_STATUS.CANCELLED]: 'times-circle',
  [JOBS_STATUS.TERMINATED]: 'times-circle',
  [JOBS_STATUS.TERMINATING]: 'times-circle',
};

const JobStatusIcon = (props: { status: string; className?: string }) => {
  const icon = ICON_MAP[props.status] ?? 'circle';

  return (
    <FontAwesomeIcon
      icon={icon}
      spin={icon === 'spinner'}
      className={classnames(`status-circle`, props.status, props.className)}
    />
  );
};

export default JobStatusIcon;
