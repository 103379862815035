import React from 'react';

import { routeNames } from '@/modules/storage/constants';
import Link from '@/react/common/RouterLink';

const tableAliasCreatedNotification = (table: Record<string, any>) => {
  return (props: { onClick: () => void }) => {
    return (
      <>
        Table alias{' '}
        <Link
          to={routeNames.TABLE}
          params={{ bucketId: table.bucket.id, tableName: table.name }}
          onClick={props.onClick}
        >
          {table.displayName}
        </Link>{' '}
        has been created.
      </>
    );
  };
};

export default tableAliasCreatedNotification;
