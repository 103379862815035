import React from 'react';
import _ from 'underscore';
import { numberFormat } from 'underscore.string';

const RowsCount = (props: { count?: number }) => {
  if (!_.isNumber(props.count)) {
    return <span>N/A</span>;
  }

  return <span>{numberFormat(props.count)}</span>;
};

export default RowsCount;
