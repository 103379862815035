import React from 'react';

import type { WorkspaceData } from './api';
import { createCredentials } from './api';
import LazyLoadedDashboard from './LazyLoadedDashboard';
import LoadingData from './LoadingData';

const OrganizationUsageInfo = () => {
  const [credentials, setCredentials] = React.useState<WorkspaceData | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAvailable, setIsAvailable] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    createCredentials({ project_type: 'organization-usage' })
      .then(setCredentials)
      .catch((e) => {
        if (e?.message?.includes('Organization Usage')) {
          return setIsAvailable(false);
        }
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (!credentials) {
    return <LoadingData isLoading={isLoading} usageBoardIsNotAvailable={!isAvailable} />;
  }

  return <LazyLoadedDashboard credentials={credentials} />;
};

export default OrganizationUsageInfo;
