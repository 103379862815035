import { fromJS, List, Map } from 'immutable';

import type {
  SettingResult,
  Sink,
  SinkStatisticsFilesResult,
  SinkStatisticsTotalResult,
  Source,
} from '@/api/routes/streamService';
import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

export type Store = { sources: StoreSource[] };
export type StoreSource = Source & { sinks?: StoreSink[] };
export type StoreSink = Sink & {
  settings?: SettingResult[];
  statistics?: SinkStatisticsFilesResult['files'];
  statisticsTotal?: SinkStatisticsTotalResult;
};

let _store = initStore('StreamStore', Map({ sources: List<any>() }));

const StreamStore = StoreUtils.createStore({ getStore: () => _store.toJS() as Store });

dispatcher.register(
  ({
    action,
  }: {
    action:
      | {
          type: typeof actionTypes.LOAD_SOURCES_SUCCESS;
          sources: Source[];
        }
      | {
          type: typeof actionTypes.LOAD_SOURCE_SUCCESS;
          source: Source;
        }
      | {
          type: typeof actionTypes.DELETE_SOURCE_SUCCESS;
          sourceId: string;
        }
      | {
          type: typeof actionTypes.LOAD_SINKS_SUCCESS;
          sinks: Sink[];
          sourceId: string;
        }
      | {
          type: typeof actionTypes.LOAD_SINK_SETTINGS_SUCCESS;
          settings: SettingResult[];
          sourceId: string;
          sinkId: string;
        }
      | {
          type: typeof actionTypes.LOAD_SINK_STATISTICS_SUCCESS;
          statistics: SinkStatisticsFilesResult['files'];
          sourceId: string;
          sinkId: string;
        }
      | {
          type: typeof actionTypes.LOAD_SINK_STATISTICS_TOTAL_SUCCESS;
          statisticsTotal: SinkStatisticsTotalResult;
          sourceId: string;
          sinkId: string;
        }
      | {
          type: typeof actionTypes.CLEAR_SINK_STATISTICS;
          sourceId: string;
          sinkId: string;
        };
  }) => {
    switch (action.type) {
      case actionTypes.LOAD_SOURCES_SUCCESS: {
        const sources = action.sources.map((source) => {
          const existingSource = (_store.toJS() as Store).sources.find(
            ({ sourceId }) => sourceId === source.sourceId,
          );

          if (!existingSource) return source;

          return { ...existingSource, ...source };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.LOAD_SOURCE_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];

        if (sources.find(({ sourceId }) => sourceId === action.source.sourceId)) {
          sources = sources.map((source) => {
            if (source.sourceId !== action.source.sourceId) return source;

            return { ...source, ...action.source };
          });
        } else {
          sources = sources.concat(action.source);
        }

        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.DELETE_SOURCE_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.filter(({ sourceId }) => sourceId !== action.sourceId);
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.LOAD_SINKS_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.map((source) => {
          if (source.sourceId !== action.sourceId) return source;

          return {
            ...source,
            sinks: action.sinks.map((sink) => {
              const existingSink = source.sinks?.find(({ sinkId }) => sinkId === sink.sinkId);

              if (!existingSink) return sink;

              return { ...existingSink, ...sink };
            }),
          };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.LOAD_SINK_SETTINGS_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.map((source) => {
          if (source.sourceId !== action.sourceId) return source;

          return {
            ...source,
            sinks: source.sinks?.map((sink) => {
              if (sink.sinkId !== action.sinkId) return sink;

              return { ...sink, settings: action.settings };
            }),
          };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.LOAD_SINK_STATISTICS_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.map((source) => {
          if (source.sourceId !== action.sourceId) return source;

          return {
            ...source,
            sinks: source.sinks?.map((sink) => {
              if (sink.sinkId !== action.sinkId) return sink;

              return { ...sink, statistics: action.statistics };
            }),
          };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.LOAD_SINK_STATISTICS_TOTAL_SUCCESS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.map((source) => {
          if (source.sourceId !== action.sourceId) return source;

          return {
            ...source,
            sinks: source.sinks?.map((sink) => {
              if (sink.sinkId !== action.sinkId) return sink;

              return { ...sink, statisticsTotal: action.statisticsTotal };
            }),
          };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      case actionTypes.CLEAR_SINK_STATISTICS: {
        let sources = _store.get('sources').toJS() as Store['sources'];
        sources = sources.map((source) => {
          if (source.sourceId !== action.sourceId) return source;

          return {
            ...source,
            sinks: source.sinks?.map((sink) => {
              if (sink.sinkId === action.sinkId) {
                delete sink.statistics;
                delete sink.statisticsTotal;
              }

              return sink;
            }),
          };
        });
        _store = _store.set('sources', fromJS(sources));

        return StreamStore.emitChange();
      }

      default:
        break;
    }
  },
);

export default StreamStore;
