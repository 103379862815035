import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'design';
import { Map } from 'immutable';

import { AVAILABLE_COUNTRIES } from '@/modules/billing/constants';

class BillingAddress extends React.Component {
  render() {
    const hasBillingDetails = !!this.props.billingInformation.get('name');

    return (
      <div className="flex-container align-top">
        {hasBillingDetails && (
          <address className="fill-space line-height-2 mb-0">
            {this.renderLine(['name'])}
            {this.renderLine(['address', 'line1'])}
            {this.renderLine(['address', 'line2'])}
            {this.renderLine(['address', 'postal_code'], null, ' ')}
            {this.renderLine(['address', 'city'])}
            {this.renderLine(['address', 'state'])}
            {this.props.billingInformation.getIn(['address', 'country']) && (
              <>
                {AVAILABLE_COUNTRIES[this.props.billingInformation.getIn(['address', 'country'])] ||
                  this.props.billingInformation.getIn(['address', 'country'])}
                <br />
              </>
            )}
          </address>
        )}
        <div className="fill-space line-height-2">
          <span className="text-muted m-0">
            {hasBillingDetails ? 'Other Info' : 'Billing Email'}
          </span>
          <br />
          {this.renderLine(['vat_id'], 'Vat ID: ')}
          <Link
            className="font-medium"
            href={`mailto:${this.props.billingInformation.get('email')}`}
          >
            {this.props.billingInformation.get('email')}
          </Link>
        </div>
      </div>
    );
  }

  renderLine(path, prefix, suffix = <br />) {
    if (!this.props.billingInformation.getIn(path)) {
      return null;
    }

    return (
      <>
        {prefix}
        {this.props.billingInformation.getIn(path)}
        {suffix}
      </>
    );
  }
}

BillingAddress.propTypes = {
  billingInformation: PropTypes.instanceOf(Map).isRequired,
};

export default BillingAddress;
