import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'immutable';

import { getDescriptionFromMetadata } from '@/modules/data-catalog/helpers';

class SharedBucketTables extends React.Component {
  render() {
    if (!this.props.tables.count()) {
      return null;
    }

    return (
      <div className="box">
        <Table responsive hover className="without-header">
          <tbody>
            {this.props.tables
              .map((table) => (
                <tr key={table.get('id')} className="f-16 w-250">
                  <td>
                    <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                    {table.get('displayName')}
                  </td>
                  <td className="text-left text-muted">
                    {getDescriptionFromMetadata(table.get('metadata', List()))}
                  </td>
                </tr>
              ))
              .toArray()}
          </tbody>
        </Table>
      </div>
    );
  }
}

SharedBucketTables.propTypes = {
  tables: PropTypes.object.isRequired,
};

export default SharedBucketTables;
