import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Tooltip } from 'design';
import type { Map, OrderedMap } from 'immutable';

import * as externalClasses from '@/constants/external';
import { allowedTypes, routeNames } from '@/modules/components-directory/constants';
import {
  componentsUnavailableInTrial,
  routeNames as snowflakePartnerConnectRouteNames,
} from '@/modules/snowflake-partner-connect/constants';
import ComponentBadges from '@/react/common/ComponentBadges';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ComponentType from '@/react/common/ComponentType';
import MarkedText from '@/react/common/MarkedText';
import RouterLink from '@/react/common/RouterLink';
import Truncated from '@/react/common/Truncated';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import ComponentTypeIcon from './ComponentTypeIcon';

type Props = {
  readOnly: boolean;
  component: Map<string, any>;
  configurations: OrderedMap<string, any>;
  query: string;
  showDetail: (component: Map<string, any>) => void;
  hasSnowflakePartnerConnectLimited: boolean;
};

class ComponentBox extends React.PureComponent<Props> {
  render() {
    const flags = this.props.component.get('flags');

    return (
      <div className="box box-panel">
        <div className="box-header with-big-icon">
          <div className="flex-container">
            <div className="component-icon-with-type">
              <ComponentIcon component={this.props.component} size="48" />
              <ComponentTypeIcon type={this.props.component.get('type')} size="18" />
            </div>
            <h2 className="box-title line-height-24 pl-1">
              <div className="tw-flex tw-items-start">
                <ComponentName component={this.props.component}>
                  {(name) => <MarkedText source={name} mark={this.props.query} />}
                </ComponentName>
                <ComponentBadges flags={flags} className="tw-ml-2 tw-mt-0.5" />
              </div>

              {this.renderComponentType()}
            </h2>
          </div>
          {this.props.component.get('documentationUrl') && (
            <Tooltip tooltip="Open documentation" placement="top">
              <Link className="text-muted mb-1" href={this.props.component.get('documentationUrl')}>
                <FontAwesomeIcon icon="book-blank" />
              </Link>
            </Tooltip>
          )}
        </div>
        <div className="box-panel-content flex-container align-top flex-column fill-space">
          <Truncated
            twoLines
            className="text-muted mb-1"
            tooltip={this.props.component.get('description')}
            text={
              <MarkedText
                source={this.props.component.get('description')}
                mark={this.props.query}
              />
            }
          />
          <div className="flex-container flex-start flex-wrap mt-auto gap-16">
            {!this.props.readOnly && (
              <Button
                bsSize="sm"
                bsStyle={this.props.configurations.isEmpty() ? 'success' : 'default'}
                className={externalClasses.useThisComponent(this.props.component)}
                onClick={() => {
                  if (
                    this.props.hasSnowflakePartnerConnectLimited &&
                    componentsUnavailableInTrial.includes(this.props.component.get('id'))
                  ) {
                    return RoutesStore.getRouter().transitionTo(
                      snowflakePartnerConnectRouteNames.UPGRADE_PAGE,
                    );
                  }

                  this.props.showDetail(this.props.component);
                }}
              >
                <FontAwesomeIcon icon="plus" className="btn-icon" fixedWidth />
                {this.props.configurations.isEmpty() ? 'Add Component' : 'Create Configuration'}
              </Button>
            )}
            {!this.props.configurations.isEmpty() && (
              <RouterLink
                to={routeNames.COMPONENT}
                params={{ component: this.props.component.get('id') }}
                className="text-muted no-wrap"
              >
                <FontAwesomeIcon icon="circle-check" className="color-success btn-icon" />
                {this.props.configurations.count()}{' '}
                {string.pluralize(this.props.configurations.count(), 'configuration')}
              </RouterLink>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderComponentType() {
    if (!allowedTypes.includes(this.props.component.get('type'))) {
      return null;
    }

    return (
      <ComponentType
        noIcon
        className="display-block f-14 font-medium"
        type={this.props.component.get('type')}
      />
    );
  }
}

export default ComponentBox;
