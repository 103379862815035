import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { canExportTable, canManageBucket } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import { deleteBucket, exportTables } from '@/modules/storage/actions';
import Loader from '@/react/common/Loader';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import DeleteBucketModal from './DeleteBucketModal';
import DeleteUnlinkBucketMenuItem from './DeleteUnlinkBucketMenuItem';
import ExportModal from './ExportModal';
import RefreshExternalBucketMenuItem from './RefreshExternalBucketMenuItem';

const BucketActions = ({ bucket }: { bucket: Map<string, any> }) => {
  const bucketId = bucket.get('id');

  const state = useStores(
    () => {
      const sapiToken = ApplicationStore.getSapiToken();
      const allTables = TablesStore.getAll() as Map<string, any>;
      const tables = allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId) as Map<
        string,
        any
      >;

      return {
        tables,
        sapiToken,
        readOnly: ApplicationStore.isReadOnly(),
        canExportTable: canExportTable(sapiToken),
        exportingTables: TablesStore.getExportingTables(),
        pendingActions: BucketsStore.getPendingBucketsActions(),
        deletingBucket: BucketsStore.deletingBuckets(bucketId),
      };
    },
    [bucketId],
    [ApplicationStore, TablesStore, BucketsStore],
  );

  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [isDeleteBucketModalOpen, setIsDeleteBucketModalOpen] = useState(false);

  const handleDeleteBucket = () => {
    return deleteBucket(bucket.get('id'), {
      forceDelete: state.tables.count() > 0,
      transition: true,
    });
  };

  if (
    !state.tables.isEmpty() &&
    state.tables.every((table, tableId) => state.exportingTables.get(tableId))
  ) {
    return (
      <span className="text-muted line-height-40">
        <Loader className="icon-addon-right" />
        Preparing export
      </span>
    );
  }

  const hasPermissionToManageBucket = canManageBucket(state.sapiToken, bucket);

  if (state.readOnly || (!hasPermissionToManageBucket && !state.canExportTable)) {
    return null;
  }

  return (
    <>
      <RowActionDropdown toggleClassName="in-navigation">
        {state.canExportTable && (
          <RowActionMenuItem
            disabled={state.tables.isEmpty()}
            onSelect={() => setExportModalOpen(true)}
          >
            <FontAwesomeIcon fixedWidth icon="down-to-line" />
            Export bucket
          </RowActionMenuItem>
        )}
        {hasPermissionToManageBucket && (
          <>
            <RefreshExternalBucketMenuItem bucket={bucket} />
            <RowActionMenuItem divider />
            <DeleteUnlinkBucketMenuItem
              bucket={bucket}
              token={state.sapiToken}
              onSelect={() => setIsDeleteBucketModalOpen(true)}
              linkedBucketsCount={bucket.get('linkedBy', Map()).count()}
              isLinked={bucket.has('sourceBucket')}
            />
          </>
        )}
      </RowActionDropdown>

      <ExportModal
        show={isExportModalOpen}
        tables={List([state.tables])}
        onSubmit={(type) => exportTables(state.tables, type)}
        onHide={() => setExportModalOpen(false)}
      />
      <DeleteBucketModal
        show={isDeleteBucketModalOpen}
        bucket={bucket}
        pendingActions={state.pendingActions}
        tables={state.tables}
        deleting={state.deletingBucket}
        onConfirm={handleDeleteBucket}
        onHide={() => setIsDeleteBucketModalOpen(false)}
      />
    </>
  );
};

export default BucketActions;
