import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { features as componentFeatures } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { prepareBlocks } from '@/modules/components/react/components/generic/code-blocks/helpers';
import { prepareRuntimesForTransformation } from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import { routeNames } from '@/modules/transformations-v2/constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import RoutesStore from '@/stores/RoutesStore';

class CreateTransformationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      imageTag: '',
      isCreating: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const runtimes = RuntimesStore.getRuntimes(this.props.transformationComponent.get('id'));

    return (
      <Modal
        show={this.props.show}
        onEnter={() => this.setState({ name: this.props.config.get('name'), description: '' })}
        onHide={this.props.onHide}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Transformation</Modal.Title>
            <ModalIcon icon="gear" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              You are about to create a new transformation from the workspace{' '}
              <strong>{this.props.config.get('name')}</strong>.
            </p>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                placeholder="Name your transformation"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                Description <OptionalFormLabel />
              </ControlLabel>
              <FormControl
                type="text"
                placeholder="Describe your transformation"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </FormGroup>
            {this.props.transformationComponent
              .get('features', List())
              .includes(componentFeatures.ALLOW_TAG_OVERRIDE) &&
              !runtimes.isEmpty() && (
                <FormGroup>
                  <ControlLabel>Backend Version</ControlLabel>
                  <Select
                    clearable={false}
                    options={prepareRuntimesForTransformation(runtimes)}
                    value={this.state.imageTag}
                    onChange={(selected) => this.setState({ imageTag: selected })}
                  />
                </FormGroup>
              )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.state.isCreating ? 'Creating Transformation...' : 'Create Transformation'
              }
              saveButtonType="submit"
              isSaving={this.state.isCreating}
              isDisabled={!this.state.name.trim()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    const data = {
      name: this.state.name,
      description: this.state.description,
      configuration: JSON.stringify({
        storage: this.props.config.getIn(['configuration', 'storage'], Map()),
        parameters: {
          blocks: prepareBlocks(),
        },
        ...(this.state.imageTag && {
          runtime: { image_tag: this.state.imageTag },
        }),
      }),
    };

    this.setState({ isCreating: true });
    return InstalledComponentsActionCreators.createConfiguration(
      this.props.transformationComponent.get('id'),
      data,
    )
      .then((response) => {
        // save from which workspace the transformation was created
        return InstalledComponentsActionCreators.setConfigurationMetadata(
          this.props.transformationComponent.get('id'),
          response.id,
          [
            {
              key: MetadataKeys.CONFIGURATION_CREATED_FROM,
              value: JSON.stringify({
                componentId: KEBOOLA_SANDBOXES,
                configurationId: this.props.config.get('id'),
              }),
            },
          ],
        ).then(() => {
          RoutesStore.getRouter().transitionTo(routeNames.GENERIC_TRANSFORMATION_CONFIG, {
            component: this.props.transformationComponent.get('id'),
            config: response.id,
          });
          return null;
        });
      })
      .catch((error) => {
        this.setState({ isCreating: false });
        throw error;
      });
  }
}

CreateTransformationModal.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  sandbox: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  transformationComponent: PropTypes.instanceOf(Map).isRequired,
};

export default CreateTransformationModal;
