import { fromJS, List, Map } from 'immutable';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isPhaseJob } from '@/modules/queue/helpers';
import {
  CHANNEL_EMAIL,
  DEFAULT_TOLERANCE,
  EVENT_JOB_FAILED,
  EVENT_JOB_PROCESSING,
  EVENT_JOB_SUCCESS,
  EVENT_JOB_WARNING,
  EVENT_PHASE_JOB_FAILED,
  EVENT_PHASE_JOB_SUCCESS,
  EVENT_PHASE_JOB_WARNING,
  FIELD_BRANCH_ID,
  FIELD_JOB_COMPONENT_ID,
  FIELD_JOB_CONFIGURATION_ID,
  FIELD_JOB_DURATION_OVERTIME_PERCENTAGE,
  FIELD_JOB_ID,
  OPERATOR_BIGGER_OR_EQUAL,
  OPERATOR_EQUAL,
} from './constants';

const getCurrentBranchId = () => {
  const branchId = DevBranchesStore.isDevModeActive()
    ? DevBranchesStore.getCurrentId()
    : DevBranchesStore.getDefaultBranchId();

  return branchId.toString();
};

const filterCurrentBranchNotifications = (notifications) => {
  const currentBranchId = getCurrentBranchId();

  return fromJS(notifications).filter((notification) => {
    const branchIdFilter = notification.get('filters', List()).find((filter) => {
      return filter.get('field') === FIELD_BRANCH_ID;
    });

    return !branchIdFilter || branchIdFilter.get('value') === currentBranchId;
  });
};

const prepareNotification = (componentId, configurationId, notifications, eventType, email) => {
  return {
    event: eventType,
    filters: [
      { field: FIELD_BRANCH_ID, value: getCurrentBranchId() },
      { field: FIELD_JOB_COMPONENT_ID, value: componentId, operator: OPERATOR_EQUAL },
      { field: FIELD_JOB_CONFIGURATION_ID, value: configurationId, operator: OPERATOR_EQUAL },
      eventType === EVENT_JOB_PROCESSING && {
        field: FIELD_JOB_DURATION_OVERTIME_PERCENTAGE,
        value: getNotificationsTolerance(notifications) / 100,
        operator: OPERATOR_BIGGER_OR_EQUAL,
      },
    ].filter(Boolean),
    recipient: { channel: CHANNEL_EMAIL, address: email.toLowerCase() },
  };
};

const prepareOneTimeNotifications = (job, email) => {
  const events = isPhaseJob(job)
    ? [EVENT_PHASE_JOB_SUCCESS, EVENT_PHASE_JOB_FAILED, EVENT_PHASE_JOB_WARNING]
    : [EVENT_JOB_SUCCESS, EVENT_JOB_FAILED, EVENT_JOB_WARNING];
  const branchId = job.get('branchId') ?? DevBranchesStore.getDefaultBranchId();

  return events.map((event) => {
    return {
      event: event,
      expiresAt: '+3 days',
      filters: [
        { field: FIELD_JOB_ID, value: job.get('id') },
        { field: FIELD_BRANCH_ID, value: branchId.toString() },
      ],
      recipient: { channel: 'email', address: email },
    };
  });
};

const updateNotificationsTolerance = (notifications, tolerance) => {
  return notifications.map((notification) => updateNotificationTolerance(notification, tolerance));
};

const updateNotificationTolerance = (notification, tolerance) => {
  const parsedTolerance = parseInt(tolerance, 10) || 1;

  if (notification.get('event') !== EVENT_JOB_PROCESSING) {
    return notification;
  }

  return notification.update('filters', (filters) => {
    return filters.map((filter) => {
      if (filter.get('field') !== FIELD_JOB_DURATION_OVERTIME_PERCENTAGE) {
        return filter;
      }

      return filter.set('value', parsedTolerance / 100);
    });
  });
};

const getNotificationsTolerance = (notifications) => {
  const notification = notifications.find((notification) => {
    return notification.get('event') === EVENT_JOB_PROCESSING;
  });

  if (!notification) {
    return DEFAULT_TOLERANCE;
  }

  return getNotificationTolerance(notification);
};

const getNotificationTolerance = (notification) => {
  const durationOvertimePercentage = notification
    .get('filters')
    .find((filter) => filter.get('field') === FIELD_JOB_DURATION_OVERTIME_PERCENTAGE, null, Map())
    .get('value');

  if (!durationOvertimePercentage) {
    return null;
  }

  return Math.round(durationOvertimePercentage * 100);
};

export {
  filterCurrentBranchNotifications,
  prepareNotification,
  prepareOneTimeNotifications,
  updateNotificationsTolerance,
  updateNotificationTolerance,
  getNotificationsTolerance,
  getNotificationTolerance,
};
