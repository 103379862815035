import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';

const DescriptionControl = (props: {
  value: string;
  onChange: (newValue: string) => void;
  autoFocus?: boolean;
}) => {
  return (
    <FormGroup>
      <ControlLabel>Notes</ControlLabel>
      <Textarea
        minRows={4}
        className="form-control"
        placeholder="Describe development branch"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        autoFocus={props.autoFocus}
      />
    </FormGroup>
  );
};

export default DescriptionControl;
