import { APP_ROUTE } from '@/constants/routeNames';
import RoutesStore from '@/stores/RoutesStore';
import * as Api from './api';

const getQueriesToRun = () => {
  return Api.check().then((response) => response.queriesToRun);
};

const upgrade = () => {
  return Api.upgrade().then(() => {
    window.location = RoutesStore.getRouter().createHref(APP_ROUTE);
  });
};

export { getQueriesToRun, upgrade };
