import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import Confirm from '@/react/common/Confirm';

const MigrateToRowsButton = createReactClass({
  propTypes: {
    onMigrate: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      isPending: false,
    };
  },

  render() {
    return (
      <Confirm
        closeAfterResolve
        icon="circle-check"
        title="Migrating Configuration"
        text={
          <>
            <p>You are about to run migration to configuration rows.</p>
            <b>Do not close modal until migration completes.</b>
          </>
        }
        buttonType="success"
        buttonLabel="Migrate Configuration"
        isLoading={this.state.isPending}
        onConfirm={this.migrateConfig}
      >
        <Button bsStyle="success" disabled={this.state.isPending}>
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
          Migrate Configuration
        </Button>
      </Confirm>
    );
  },

  migrateConfig() {
    this.setState({ isPending: true });
    return this.props.onMigrate().finally(() => {
      this.setState({ isPending: false });
    });
  },
});

export default MigrateToRowsButton;
