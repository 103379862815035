export default {
  transparent: 'rgba(255,255,255,0)',
  white: '#fff',
  neutral: {
    50: '#f2f4f7',
    100: '#edf0f5',
    150: '#d9dde5',
    200: '#c5cbd6',
    300: '#a2aab8',
    400: '#7c8594',
    500: '#565c66',
    600: '#454952',
    700: '#33373d',
    800: '#222529',
    900: '#101114',
  },
  primary: {
    100: '#e0ffe0',
    200: '#baf5ba',
    300: '#93f593',
    400: '#51e051',
    500: '#1ec71e',
    600: '#1bb31b',
    700: '#189f18',
    800: '#158b15',
    900: '#0f630f',
  },
  secondary: {
    100: '#e0f0ff',
    200: '#c2e0ff',
    300: '#99ccff',
    400: '#5cadff',
    500: '#1f8fff',
    600: '#0975e0',
    700: '#075fb8',
    800: '#064a8f',
    900: '#043566',
  },
  error: {
    100: '#ffe0e6',
    200: '#ffc2cc',
    300: '#ff99aa',
    400: '#ff5c77',
    500: '#e00025',
    550: '#c40021',
    600: '#b8001f',
    700: '#a3001b',
    800: '#7a0014',
    900: '#52000e',
  },
  warning: {
    100: '#fff3e0',
    200: '#ffe7c2',
    300: '#ffd699',
    400: '#ffbe5c',
    500: '#f59300',
    550: '#e38800',
    600: '#d17d00',
    700: '#a36200',
    800: '#7a4900',
    900: '#523100',
  },
  purple: {
    100: '#ebe0ff',
    200: '#d6c2ff',
    300: '#c9adff',
    400: '#ad85ff',
    500: '#925cff',
    600: '#6f36e0',
    700: '#5b2cb8',
    800: '#46228f',
    900: '#321866',
  },
  cyan: {
    100: '#e0f9ff',
    200: '#bef3ff',
    300: '#83ebff',
    400: '#00def7',
    500: '#00bbd1',
    600: '#00a0b2',
    700: '#007d8c',
    800: '#005d69',
    900: '#003f47',
  },
  teal: {
    100: '#cfffe6',
    200: '#96ffce',
    300: '#00faaf',
    400: '#00e6a1',
    500: '#00c287',
    600: '#00a673',
    700: '#008159',
    800: '#006141',
    900: '#00422b',
  },
};
