import { List } from 'immutable';
import _ from 'underscore';

export const conform = (configuration) => {
  let conformed = configuration;

  if (!conformed.hasIn(['parameters', 'customerId'])) {
    conformed = conformed.setIn(['parameters', 'customerId'], List());
  }

  if (_.isString(conformed.getIn(['parameters', 'customerId']))) {
    conformed = conformed.setIn(
      ['parameters', 'customerId'],
      List([conformed.getIn(['parameters', 'customerId'])]).filter(Boolean),
    );
  }

  if (!conformed.hasIn(['parameters', 'onlyEnabledCustomers'])) {
    conformed = conformed.setIn(['parameters', 'onlyEnabledCustomers'], true);
  }

  if (!conformed.hasIn(['parameters', 'getAccountChildren'])) {
    conformed = conformed.setIn(['parameters', 'getAccountChildren'], false);
  }

  return conformed;
};
