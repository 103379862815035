import { backends, transformationType } from '@/modules/legacy-transformation/Constants';

export default function (backend, type) {
  if (backend === backends.SNOWFLAKE) {
    return 'text/x-sfsql';
  }

  if (backend === backends.DOCKER) {
    switch (type) {
      case transformationType.R:
        return 'text/x-rsrc';

      case transformationType.PYTHON:
        return 'text/x-python';
    }
  }

  return null;
}
