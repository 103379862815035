import Immutable from 'immutable';

import { KEBOOLA_PROCESSOR_MOVE_FILES } from '@/constants/componentIds';

export function createConfiguration(localState) {
  const destination = localState.get('destination', '');
  const prefix = destination.substring(0, destination.lastIndexOf('/') + 1);
  const filename = destination.substring(destination.lastIndexOf('/') + 1);
  const config = Immutable.fromJS({
    parameters: {
      prefix: prefix,
    },
    storage: {
      input: {
        tables: [
          {
            source: localState.get('source', ''),
            destination: filename,
          },
        ],
      },
    },
    processors: {
      before: [
        {
          definition: {
            component: KEBOOLA_PROCESSOR_MOVE_FILES,
          },
          parameters: {
            direction: 'files',
          },
        },
      ],
    },
  });
  return config;
}

export function parseConfiguration(configuration) {
  const prefix = configuration.getIn(['parameters', 'prefix'], '');
  const filename = configuration.getIn(['storage', 'input', 'tables', 0, 'destination'], '');
  return Immutable.fromJS({
    destination: prefix + filename,
    source: configuration.getIn(['storage', 'input', 'tables', 0, 'source'], ''),
  });
}

export function createEmptyConfiguration(tableId) {
  const tableName = tableId.substring(tableId.lastIndexOf('.') + 1);
  return createConfiguration(
    Immutable.fromJS({ source: tableId, destination: tableName + '.csv' }),
  );
}
