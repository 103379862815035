import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { rtrim, trim } from 'underscore.string';

import {
  KDS_TEAM_EX_ZOHO,
  SMETRIC_EX_SIMPRO_API,
  SMETRIC_EX_VEND_API,
} from '@/constants/componentIds';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const zohoAuthUrls = [
  {
    value: 'zoho.eu',
    label: 'EU (zoho.eu)',
  },
  {
    value: 'zoho.uk',
    label: 'UK (zoho.uk)',
  },
  {
    value: 'zoho.com',
    label: 'US (zoho.com)',
  },
  {
    value: 'zohocloud.ca',
    label: 'CA (zohocloud.ca)',
  },
  {
    value: 'zoho.com.au',
    label: 'AU (zoho.com.au)',
  },
  {
    value: 'zoho.in',
    label: 'In (zoho.in)',
  },
  {
    value: 'zoho.jp',
    label: 'JP (zoho.jp)',
  },
];

const InstantAuthorizationFields = createReactClass({
  propTypes: {
    values: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    onChangeFn: PropTypes.func,
    infoText: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div>
        {!!this.props.infoText && (
          <Alert variant="warning" className="tw-mb-5">
            {this.props.infoText}
          </Alert>
        )}
        <div className="form-group">
          <label className="control-label col-sm-3">
            Name <OptionalFormLabel />
          </label>
          <div className="col-sm-9">
            <FormControl
              autoFocus
              value={this.props.values.authorizedFor || ''}
              onChange={(e) => this.props.onChangeFn('authorizedFor', e.target.value)}
              disabled={this.props.disabled}
            />
            <p className="help-block">Describe this authorization, e.g., by the account name.</p>
          </div>
        </div>
        {this.props.componentId === SMETRIC_EX_SIMPRO_API && (
          <>
            <div className="form-group">
              <label className="control-label col-sm-3">Company URL</label>
              <div className="col-sm-9">
                <FormControl
                  onChange={(e) => {
                    this.handleCompanyUrlChange(e.target.value);
                  }}
                  disabled={this.props.disabled}
                />
                <input type="hidden" name="authUrl" value={this.props.values.authUrl || ''} />
                <p className="help-block">
                  Use the full URL, e.g., <code>https://ACCOUNT_NAME.simprosuite.com</code>
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-3">Token URL</label>
              <div className="col-sm-9">
                <FormControl
                  name="tokenUrl"
                  onChange={(e) => this.props.onChangeFn('tokenUrl', e.target.value)}
                  value={this.props.values.tokenUrl || ''}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-3">Client ID</label>
              <div className="col-sm-9">
                <FormControl
                  name="appKey"
                  onChange={(e) => this.props.onChangeFn('appKey', e.target.value)}
                  value={this.props.values.appKey || ''}
                  disabled={this.props.disabled}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-3">Client secret</label>
              <div className="col-sm-9">
                <FormControl
                  name="appSecret"
                  onChange={(e) => this.props.onChangeFn('appSecret', e.target.value)}
                  value={this.props.values.appSecret || ''}
                  disabled={this.props.disabled}
                />
              </div>
            </div>
          </>
        )}
        {this.props.componentId === SMETRIC_EX_VEND_API && (
          <div className="form-group">
            <label className="control-label col-sm-3">Token URL</label>
            <div className="col-sm-9">
              <FormControl
                name="tokenUrl"
                onChange={(e) => this.props.onChangeFn('tokenUrl', e.target.value)}
                value={this.props.values.tokenUrl || ''}
                disabled={this.props.disabled}
              />
              <p className="help-block">
                Use the full Token URL, e.g., <code>https://STORE.vendhq.com/api/1.0/token</code>
              </p>
            </div>
          </div>
        )}
        {this.props.componentId === KDS_TEAM_EX_ZOHO && (
          <div className="form-group">
            <label className="control-label col-sm-3">Company URL</label>
            <div className="col-sm-9">
              <Select
                placeholder="Select available URL"
                clearable={false}
                disabled={this.props.disabled}
                options={zohoAuthUrls}
                value={string.getStringBetween(
                  this.props.values.authUrl,
                  'https://accounts.',
                  '/oauth',
                )}
                onChange={(value) => this.handleZohoUrlChange(value)}
              />
              <input type="hidden" name="authUrl" value={this.props.values.authUrl || ''} />
              <input type="hidden" name="tokenUrl" value={this.props.values.tokenUrl || ''} />
            </div>
          </div>
        )}
      </div>
    );
  },

  handleZohoUrlChange(companyUrl) {
    const authUrl = `https://accounts.${companyUrl}/oauth/v2/auth?scope=ZohoCRM.modules.all,ZohoCRM.settings.modules.READ,ZohoCRM.bulk.READ,ZohoCRM.settings.fields.READ&client_id=%%client_id%%&response_type=code&access_type=offline&redirect_uri=%%redirect_uri%%`;
    const tokenUrl = `https://accounts.${companyUrl}/oauth/v2/token`;

    this.props.onChangeFn('authUrl', authUrl);
    this.props.onChangeFn('tokenUrl', tokenUrl);
  },

  handleCompanyUrlChange(companyUrl) {
    const authUrl = `${rtrim(trim(companyUrl), '/')}/oauth2/login?client_id=%%client_id%%`;

    this.props.onChangeFn('authUrl', authUrl);
  },
});

export default InstantAuthorizationFields;
