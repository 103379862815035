import React from 'react';
import Switch from 'rc-switch';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import HomeActions from '@/modules/home/ActionCreators';
import { saveMetadataDescription } from '@/modules/home/actions';
import HomeStore from '@/modules/home/store';
import DescriptionButton from '@/react/common/DescriptionButton';
import DescriptionModal from '@/react/common/DescriptionModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const HomeHeaderButtons = () => {
  const [showDescriptionModal, setShowDescriptionModal] = React.useState(false);
  const { isProjectDescriptionHidden, readOnly, projectDescription, isDevModeActive } = useStores(
    () => {
      return {
        isProjectDescriptionHidden: HomeStore.isProjectDescriptionHidden(),
        readOnly: ApplicationStore.isReadOnly(),
        projectDescription: DevBranchesStore.getProjectDescription(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
      };
    },
    [],
    [HomeStore, ApplicationStore, DevBranchesStore],
  );

  if (isDevModeActive || readOnly) {
    return null;
  }

  return (
    <>
      <DescriptionButton
        onClick={() => setShowDescriptionModal(true)}
        isFilled={!!projectDescription}
        showsInModalOnly={isProjectDescriptionHidden}
      />
      <DescriptionModal
        entity="project"
        show={showDescriptionModal}
        onHide={() => setShowDescriptionModal(false)}
        description={projectDescription}
        onSave={saveMetadataDescription}
        additionalTitleControl={
          <div
            tabIndex={0}
            onClick={HomeActions.toggleIsProjectDescriptionHidden}
            className="flex-container flex-start clickable"
          >
            <Switch
              prefixCls="switch"
              className="wider btn-icon"
              checked={!isProjectDescriptionHidden}
            />
            <span className="font-normal f-14 line-height-1">Show on Dashboard</span>
          </div>
        }
      />
    </>
  );
};

export default HomeHeaderButtons;
