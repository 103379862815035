import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import createReactClass from 'create-react-class';
import { Link } from 'design';

import TokensActions from '@/modules/tokens/actionCreators';
import RefreshTokenModal from '@/modules/tokens/react/modals/RefreshTokenModal';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import CliSyncItem from './components/CliSyncItem';
import SettingsTabs from './components/SettingsTabs';
import TerminalWithKbcInit from './components/TerminalWithKbcInit';
import { routeNames } from './constants';

const CliSync = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TokensStore)],

  getStateFromStores() {
    const sapiToken = ApplicationStore.getSapiToken();
    return {
      sapiUrl: ApplicationStore.getSapiUrl(),
      sapiToken,
      hasNewQueue: ApplicationStore.hasNewQueue(),
      isRefreshingToken: TokensStore.isRefreshingToken(sapiToken.get('id')),
    };
  },

  getInitialState() {
    return {
      showRefreshTokenModal: false,
    };
  },

  render() {
    return (
      <SettingsTabs activeTab={routeNames.CLI}>
        <div className="git-sync-page">
          <CliSyncItem
            icon={
              <i className="fa-stack w-2em">
                <FontAwesomeIcon icon="circle" className="fa-stack-2x text-info w-2em" />
                <FontAwesomeIcon icon="key" className="fa-stack-1x" inverse />
              </i>
            }
            title="1 – Use your Keboola (master) token"
            description="To see the token you need to refresh it. Once copied, keep it safe."
            content={
              <Button
                bsStyle="success"
                onClick={() => {
                  this.setState({ showRefreshTokenModal: true });
                }}
              >
                Refresh token
              </Button>
            }
          />
          <CliSyncItem
            icon={
              <FontAwesomeIcon
                icon={['far', 'circle-arrow-down']}
                size="2x"
                className="text-success"
              />
            }
            title="2 – Download CLI application"
            description="All major platforms are supported."
            content={
              <Link
                href="https://github.com/keboola/keboola-as-code/releases"
                className="btn btn-success"
              >
                Download KBC binary
              </Link>
            }
          />
          <CliSyncItem
            icon={
              <i className="fa-stack w-2em">
                <FontAwesomeIcon icon="circle" className="fa-stack-2x w-2em" />
                <FontAwesomeIcon icon="code" className="fa-stack-1x" inverse />
              </i>
            }
            title="3 – Run init command"
            description="Open your terminal and run the init command."
            content={
              <>
                <TerminalWithKbcInit storageApiHost={this.state.sapiUrl.replace('https://', '')} />
                <p className="help-block text-right">
                  <Link href={`${URLS.DEVELOPERS_DOCUMENTATION}/cli/getting-started`}>
                    View quickstart guide
                  </Link>
                </p>
              </>
            }
          />
        </div>
        <RefreshTokenModal
          token={this.state.sapiToken}
          show={this.state.showRefreshTokenModal}
          onHideFn={() => this.setState({ showRefreshTokenModal: false })}
          onRefreshFn={() => TokensActions.refreshToken(this.state.sapiToken)}
          isRefreshing={this.state.isRefreshingToken}
          hasNewQueue={this.state.hasNewQueue}
          sapiToken={this.state.sapiToken}
        />
      </SettingsTabs>
    );
  },
});

export default CliSync;
