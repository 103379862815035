import React, { useState } from 'react';
import { Promise } from 'bluebird';

import type { Version } from '@/api/routes/templatesService';
import ConfirmModal from '@/react/common/ConfirmModal';
import Select from '@/react/common/Select';
import RoutesStore from '@/stores/RoutesStore';

type Props = { versions: Version[]; current: string; disabled?: boolean; confirm?: boolean };

const VersionSelect = ({ versions, current, disabled, confirm }: Props) => {
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
  const onChangeHandler = (version = selectedVersion) =>
    RoutesStore.getRouter().transitionTo(
      RoutesStore.getRouterState().getIn(['location', 'pathname']),
      null,
      {
        v: version,
      },
    );

  if (versions.length < 2) return null;

  return (
    <>
      <Select
        clearable={false}
        searchable={false}
        className="w-150 is-white font-medium f-11 line-height-18 letter-spacing-wider"
        value={current}
        onChange={(version: string) => {
          if (version === current) return;
          if (!confirm) {
            return onChangeHandler(version);
          }

          setSelectedVersion(version);
        }}
        options={versions.map(({ version }) => ({
          value: version,
          label: `VERSION ${version}`,
        }))}
        disabled={disabled}
      />
      <ConfirmModal
        show={!!selectedVersion}
        onHide={() => setSelectedVersion(null)}
        icon={['far', 'code-branch']}
        title="Change Template Version"
        text={
          <p>
            Existing component configurations may be modified or deleted, new configurations may be
            created. The changes will be applied on Save. Carefully study what has changed between
            versions.
          </p>
        }
        buttonLabel="Change Version"
        buttonType="primary"
        onConfirm={() => new Promise(() => onChangeHandler())}
      />
    </>
  );
};

export default VersionSelect;
