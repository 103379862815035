import type { Map } from 'immutable';
import { fromJS } from 'immutable';

import { DEFAULT_RETRY_CODES } from '@/modules/ex-generic/constants';

const KEY = 'retry';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({
    [KEY]: {
      retryHeader: parameters.getIn(['api', 'http', 'retryHeader'], 'Retry-After'),
      maxRetries: parameters.getIn(['api', 'http', 'maxRetries'], 10),
      codes: parameters.getIn(['api', 'http', 'codes'], DEFAULT_RETRY_CODES),
    },
  });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters
    .setIn(['api', 'http', 'retryHeader'], editing.getIn([KEY, 'retryHeader']))
    .setIn(['api', 'http', 'maxRetries'], editing.getIn([KEY, 'maxRetries']))
    .setIn(['api', 'http', 'codes'], editing.getIn([KEY, 'codes']));
};

export { KEY, mapToState, mapToApi };
