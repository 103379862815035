import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock, Tooltip } from 'design';
import Switch from 'rc-switch';

const SwitchControl = (props: {
  label: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  readOnly: boolean;
  help?: string;
  disabledReason?: string;
}) => {
  const toggle = () => !props.readOnly && props.onChange(!props.checked);

  return (
    <FormGroup>
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={props.disabledReason}
        forceHide={!props.disabledReason}
        triggerClassName="tw-inline-flex tw-items-center tw-justify-start"
      >
        <Switch
          prefixCls="switch"
          className="btn-icon wider"
          checked={props.checked}
          disabled={props.readOnly}
          onChange={toggle}
        />
        <ControlLabel className="clickable m-0" onClick={toggle}>
          {props.label}
        </ControlLabel>
      </Tooltip>
      {props.help && <HelpBlock>{props.help}</HelpBlock>}
    </FormGroup>
  );
};

export default SwitchControl;
