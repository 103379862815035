import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { routeNames } from '@/modules/gooddata-writer-v3/constants';
import RunLoadButton from '@/modules/gooddata-writer-v3/react/components/RunLoadButton';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import FilterPanel from '@/react/common/FilterPanel';
import Loader from '@/react/common/Loader';
import NoResultsFound from '@/react/common/NoResultsFound';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';

const ConfiguredTables = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    loadOnly: PropTypes.bool.isRequired,
    configurationId: PropTypes.string.isRequired,
    showIncrementalResetWarning: PropTypes.bool.isRequired,
    tables: PropTypes.object,
    isSaving: PropTypes.bool,
    isTablePending: PropTypes.func,
    deleteTable: PropTypes.func,
    toggleTableExport: PropTypes.func,
    newTableButton: PropTypes.object,
    getSingleRunParams: PropTypes.func,
  },

  getInitialState() {
    return { query: '' };
  },

  renderTable() {
    const filteredRows = this.getFilteredRows();

    if (!filteredRows.count()) {
      return <NoResultsFound entityName="tables" />;
    }

    return (
      <div className="box">
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <span className="th">Table Name</span>
              <span className="th">GoodData Title</span>
              <span className="th" />
            </div>
          </div>
          <div className="tbody">{filteredRows.map(this.renderTableRow).toArray()}</div>
        </div>
      </div>
    );
  },

  getFilteredRows() {
    if (!this.state.query) {
      return this.props.tables;
    }

    return this.props.tables.filter((table, tableId) => {
      return matchByWords([tableId, table.get('title')], this.state.query);
    });
  },

  transitionToTableDetail(tableId) {
    const router = RoutesStore.getRouter();
    router.transitionTo(routeNames.TABLE, {
      config: this.props.configurationId,
      table: tableId,
    });
  },

  renderTableRow(table, tableId) {
    const isDeleting = this.props.isTablePending([tableId, 'delete']);
    const isPending = this.props.isTablePending([tableId, 'activate']);

    return (
      <div
        className={classnames('tr clickable hoverable-actions', {
          'row-disabled': table.get('disabled'),
        })}
        key={tableId}
        onClick={() => this.transitionToTableDetail(tableId)}
      >
        <div className="td overflow-break-anywhere">
          <StorageApiTableLinkEx tableId={tableId} />
        </div>
        <div className="td overflow-break-anywhere">{table.get('title')}</div>
        <div className="td pr-1">
          {!this.props.readOnly && (
            <RowActionDropdown showLoading={this.props.isSaving || isDeleting || isPending}>
              <RunLoadButton
                mode="menuitem"
                loadOnly={this.props.loadOnly}
                tableId={tableId}
                isTableDisabled={table.get('disabled')}
                getRunParams={this.props.getSingleRunParams}
                showIncrementalResetWarning={this.props.showIncrementalResetWarning}
                label="Load table"
              />
              <RowActionMenuItem divider />
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={!table.get('disabled')}
                isPending={isPending}
                onChange={(val) => this.props.toggleTableExport(tableId, val)}
              />
              <RowActionMenuItem divider />
              <RowActionMenuItem
                onSelect={() => this.props.deleteTable(tableId)}
                disabled={isDeleting}
              >
                {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                Delete
              </RowActionMenuItem>
            </RowActionDropdown>
          )}
        </div>
      </div>
    );
  },

  onChangeSearch(query) {
    this.setState({ query: query });
  },

  render() {
    return (
      <div className="box-separator">
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-text-base">Tables</h2>
          {this.props.newTableButton}
        </div>
        <FilterPanel query={this.state.query} onChange={this.onChangeSearch} />
        {this.renderTable()}
      </div>
    );
  },
});

export default ConfiguredTables;
