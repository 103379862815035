import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { Alert } from 'design';
import { fromJS, List, Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { deployApp } from '@/modules/data-apps/actions';
import { defaultProxyAuthorization } from '@/modules/data-apps/constants';
import { isAppConfigured } from '@/modules/data-apps/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const isStreamlitAuthEnabled = (dataApp: Map<string, any>) => {
  return dataApp.getIn(['configuration', 'parameters', 'dataApp', 'streamlitAuthEnabled']);
};

const MigrationAlert = () => {
  const store = useStores(
    () => {
      return {
        readOnly: ApplicationStore.isReadOnly(),
        sandboxes: SandboxesStore.getSandboxes() as Map<string, any>,
        dataApps: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_DATA_APPS),
      };
    },
    [],
    [InstalledComponentsStore, SandboxesStore, ApplicationStore],
  );
  const [isProcessing, setIsProcessing] = React.useState(false);

  const withStreamlit = store.dataApps.filter((dataApp: Map<string, any>) => {
    return dataApp.hasIn(['configuration', 'parameters', 'dataApp', 'streamlitAuthEnabled']);
  });

  if (store.readOnly || isProcessing || withStreamlit.isEmpty()) {
    return null;
  }

  const handleMigrate = () => {
    setIsProcessing(true);
    return Promise.each(withStreamlit.toArray(), (dataApp: Map<string, any>) => {
      const isEnabled = isStreamlitAuthEnabled(dataApp);

      return InstalledComponentsActionCreators.saveComponentConfigData(
        KEBOOLA_DATA_APPS,
        dataApp.get('id'),
        dataApp
          .get('configuration')
          .deleteIn(['parameters', 'dataApp', 'streamlitAuthEnabled'])
          .update((configData: Map<string, any>) => {
            if (!isEnabled) {
              return configData;
            }

            return configData
              .set('authorization', fromJS(defaultProxyAuthorization))
              .setIn(
                ['authorization', 'app_proxy', 'auth_providers'],
                fromJS([{ id: 'simpleAuth', type: 'password' }]),
              )
              .updateIn(['authorization', 'app_proxy', 'auth_rules', 0], (rule) => {
                return rule.set('auth_required', true).set('auth', List(['simpleAuth']));
              });
          }),
        isEnabled ? 'Switch to new basic auth' : 'Remove streamlit auth reference',
      );
    })
      .then(() => {
        const withStreamlitAuthEnabled = withStreamlit
          .filter((dataApp: Map<string, any>) => {
            return isAppConfigured(dataApp) && isStreamlitAuthEnabled(dataApp);
          })
          .toArray();

        return Promise.each(withStreamlitAuthEnabled, (dataApp: Map<string, any>) => {
          const sandbox = store.sandboxes.find((sandbox: Map<string, any>) => {
            return sandbox.get('id') === dataApp.getIn(['configuration', 'parameters', 'id']);
          });

          return deployApp(
            dataApp,
            sandbox || Map(),
            dataApp.getIn(['configuration', 'parameters'], Map()),
            { notify: false },
          );
        });
      })
      .then(() => {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: `${
            store.dataApps.count() === 1 ? 'Data app has' : 'Data Apps have'
          } been successfully migrated.`,
        });
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <div className="container tw-mt-5">
      <Alert title="Migrate to Basic Proxy Auth for Enhanced Security!" variant="warning">
        <p>
          Switch from simple Streamlit auth to basic proxy auth now. Enjoy better security managed
          by a proxy and avoid conflicts with other Streamlit packages. Complete the migration soon
          to ensure optimal performance and security!
        </p>
        <Button bsStyle="success" onClick={handleMigrate} disabled={isProcessing}>
          {isProcessing ? (
            <Loader className="btn-icon" />
          ) : (
            <FontAwesomeIcon icon="circle-play" className="btn-icon" fixedWidth />
          )}
          {isProcessing ? 'Migrating...' : 'Run Migration'}
        </Button>
      </Alert>
    </div>
  );
};

export default MigrationAlert;
