import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import dayjs from '@/date';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import CredentialsActionCreators from '@/modules/provisioning/ActionCreators';
import ExtendJulipyterCredentials from '@/modules/provisioning/react/components/ExtendJulipyterCredentials';
import JulipyterCredentials from '@/modules/provisioning/react/components/JulipyterCredentials';
import JulipyterSandboxCredentialsStore from '@/modules/provisioning/stores/JulipyterSandboxCredentialsStore';
import { transformationType } from '@/modules/transformations/Constants';
import CreateDockerSandboxModal from '@/modules/transformations/react/modals/CreateDockerSandboxModal';
import { getDockerConnectLink } from '@/modules/transformations/utils/sandboxUtils';
import DeleteButton from '@/react/common/DeleteButton';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import CannotExtendSandboxWarning from './CannotExtendSandboxWarning';

const JulipyterSandbox = createReactClass({
  mixins: [
    createStoreMixin(JulipyterSandboxCredentialsStore, StorageBucketsStore, StorageTablesStore),
  ],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
  },

  getStateFromStores() {
    let expiration = JulipyterSandboxCredentialsStore.getExpirationDate();
    if (!dayjs(expiration).isValid()) {
      expiration = null;
    }

    return {
      credentials: JulipyterSandboxCredentialsStore.getCredentials(),
      canExtend: JulipyterSandboxCredentialsStore.getCanExtend(),
      expiration,
      pendingActions: JulipyterSandboxCredentialsStore.getPendingActions(),
      isLoading: JulipyterSandboxCredentialsStore.getIsLoading(),
      buckets: StorageBucketsStore.getAll(),
      tables: StorageTablesStore.getAll(),
    };
  },

  getInitialState() {
    return {
      showModal: false,
      sandboxConfiguration: Map(),
    };
  },

  _renderCredentials() {
    return (
      <JulipyterCredentials
        credentials={this.state.credentials}
        expiration={this.state.expiration}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  },

  _renderControlButtons() {
    if (this.props.readOnly) {
      return null;
    }

    if (this.state.credentials.get('id')) {
      return (
        <>
          <div>
            <form
              action={getDockerConnectLink(this.state.credentials, transformationType.JULIA)}
              target="_blank"
            >
              <button
                type="submit"
                className="btn btn-link"
                disabled={this.state.pendingActions.count() > 0}
              >
                <FontAwesomeIcon icon="database" className="icon-addon-right" />
                Connect
              </button>
            </form>
            <div>
              <DeleteButton
                tooltip="Delete Jupyter Sandbox"
                isPending={this.state.pendingActions.get('drop')}
                pendingLabel="Deleting sandbox"
                isEnabled={this.state.pendingActions.count() === 0}
                label="Drop sandbox"
                confirm={{
                  title: 'Delete Jupyter Sandbox',
                  text: 'Are you sure you want to delete the Jupyter sandbox?',
                  onConfirm: this._dropCredentials,
                }}
              />
            </div>
            <div>
              <ExtendJulipyterCredentials buttonStyle="link" canExtend={this.state.canExtend} />
            </div>
          </div>
        </>
      );
    } else if (!this.state.pendingActions.get('create')) {
      return (
        <span>
          <CreateDockerSandboxModal
            show={this.state.showModal}
            close={this.handleClose}
            create={this._createCredentials}
            tables={this.state.tables}
            buckets={this.state.buckets}
            type="Jupyter"
            onConfigurationChange={this.onConfigurationChange}
            disabled={
              this.state.sandboxConfiguration.getIn(['input', 'tables'], List()).count() === 0
            }
          />
          <button className="btn btn-link" onClick={this.openModal}>
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            New Sandbox
          </button>
        </span>
      );
    }
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title tw-flex tw-items-center tw-gap-2">Julia (Jupyter)</h2>
        </div>
        <div className="box-content">
          {this.state.isLoading ? (
            <span>
              <Loader /> Loading...
            </span>
          ) : (
            <div className="row">
              <div className="col-md-6">{this._renderCredentials()}</div>
              <div className="col-md-3 col-md-offset-3">{this._renderControlButtons()}</div>
              {!!this.state.credentials.get('id') && !this.state.canExtend && (
                <div className="col-md-12">
                  <CannotExtendSandboxWarning />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },

  _createCredentials() {
    return CredentialsActionCreators.createJulipyterSandboxCredentials(
      this.state.sandboxConfiguration.toJS(),
    );
  },

  _dropCredentials() {
    return CredentialsActionCreators.dropJulipyterSandboxCredentials();
  },

  handleClose() {
    this.setState({
      showModal: false,
      sandboxConfiguration: Map(),
    });
  },

  openModal() {
    this.setState({ showModal: true });
  },

  onConfigurationChange(configuration) {
    this.setState({ sandboxConfiguration: fromJS(configuration) });
  },
});

export default JulipyterSandbox;
