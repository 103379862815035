import { backends, transformationType } from '@/modules/transformations/Constants';

export default function (backend, type) {
  if (backend === backends.REDSHIFT) {
    return 'text/x-sql';
  }

  if (backend === backends.SNOWFLAKE) {
    return 'text/x-sfsql';
  }

  if (backend === backends.DOCKER) {
    switch (type) {
      case transformationType.R:
        return 'text/x-rsrc';

      case transformationType.PYTHON:
        return 'text/x-python';

      case transformationType.JULIA:
        return 'text/x-julia';

      case transformationType.OPENREFINE:
        return 'application/json';
    }
  }

  return null;
}
