export const GENERIC_UI = 'genericUI';
export const GENERIC_DOCKER_UI = 'genericDockerUI';
export const GENERIC_TEMPLATES_UI = 'genericTemplatesUI';
export const GENERIC_PACKAGES_UI = 'genericPackagesUI';
export const GENERIC_CODE_BLOCKS_UI = 'genericCodeBlocksUI';
export const GENERIC_DOCKER_UI_ROWS = 'genericDockerUI-rows';
export const GENERIC_DOCKER_UI_RESET_STATE = 'genericDockerUI-resetState';
export const GENERIC_DOCKER_UI_TABLE_INPUT = 'genericDockerUI-tableInput';
export const GENERIC_DOCKER_UI_TABLE_OUTPUT = 'genericDockerUI-tableOutput';
export const GENERIC_DOCKER_UI_FILE_INPUT = 'genericDockerUI-fileInput';
export const GENERIC_DOCKER_UI_FILE_OUTPUT = 'genericDockerUI-fileOutput';
export const GENERIC_DOCKER_UI_AUTHORIZATION = 'genericDockerUI-authorization';
export const GENERIC_DOCKER_UI_PROCESSORS = 'genericDockerUI-processors';
export const GENERIC_DOCKER_UI_SIMPLE_TABLE_INPUT = 'genericDockerUI-simpleTableInput';
export const EXCLUDE_FROM_NEW_LIST = 'excludeFromNewList';
export const EXCLUDE_RUN = 'excludeRun';
export const BETA = 'appInfo.beta';
export const EXPERIMENTAL = 'appInfo.experimental';
