import React from 'react';
import classNames from 'classnames';

import Loader from './Loader';

const TableActionLoader = ({ loadingActionName }: { loadingActionName?: string | null }) => (
  <span className="text-muted">
    <Loader className={classNames({ 'btn-icon': !!loadingActionName })} />
    {loadingActionName}
  </span>
);

export default TableActionLoader;
