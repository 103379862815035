import { TRANSFORMATION } from '@/constants/componentIds';
import { SERVICE_SYRUP, SERVICE_SYRUP_TRANSFORMATION } from '@/constants/serviceIds';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import parseBucket from './utils/parseBucket';
import parseBuckets from './utils/parseBuckets';
import parseTransformation from './utils/parseTransformation';

export const createUrl = function (path) {
  return (
    ServicesStore.getServiceUrl(SERVICE_SYRUP) + '/' + SERVICE_SYRUP_TRANSFORMATION + '/' + path
  );
};

var createRequest = function (method, path) {
  return request(method, createUrl(path)).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

var transformationsApi = {
  getTransformationBuckets: function () {
    return InstalledComponentsApi.getComponentConfigurations(TRANSFORMATION).then(
      function (response) {
        return parseBuckets(response);
      },
    );
  },

  createTransformationBucket: function (data, changeDescription) {
    return InstalledComponentsApi.createConfiguration(TRANSFORMATION, data, changeDescription).then(
      function (response) {
        return parseBucket(response);
      },
    );
  },

  restoreTransformationBucket: function (bucketId) {
    return InstalledComponentsApi.restoreConfiguration(TRANSFORMATION, bucketId);
  },

  createTransformation: function (bucketId, data, changeDescription) {
    const form = {
      name: data.name,
    };
    delete data.name;
    if (data.description) {
      form.description = data.description;
    }
    delete data.description;
    form.configuration = JSON.stringify(data);
    return InstalledComponentsApi.createConfigurationRow(
      TRANSFORMATION,
      bucketId,
      form,
      changeDescription,
    ).then(function (response) {
      return parseTransformation(response);
    });
  },

  saveTransformation: function (bucketId, transformationId, data, changeDescription) {
    if (data.queriesString) {
      delete data.queriesString;
    }
    var form = {
      configuration: JSON.stringify(data),
      changeDescription: changeDescription,
    };
    return InstalledComponentsApi.updateConfigurationRow(
      TRANSFORMATION,
      bucketId,
      transformationId,
      form,
    ).then(function (response) {
      return parseTransformation(response);
    });
  },

  getGraph: function (configuration) {
    var path;
    path = 'graph?table=' + configuration.tableId;
    path = path + ('&direction=' + configuration.direction);
    path = path + '&showDisabled=' + (configuration.showDisabled ? '1' : '0');
    path = path + '&limit=' + JSON.stringify(configuration.limit);
    return createRequest('GET', path)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },
};

export default transformationsApi;
