import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Collapse } from 'design';
import type { List } from 'immutable';

import type { InstanceDetail, Template } from '@/api/routes/templatesService';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTokensStore from '@/modules/tokens/StorageTokensStore';
import CollapseButton from '@/react/common/CollapseButton';
import FilterPanel from '@/react/common/FilterPanel';
import Markdown from '@/react/common/Markdown';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ComponentBox from './components/ComponentBox';
import { InstancesTable } from './components/InstancesTable';
import { getFilteredInstances, getInstancesForTemplate } from './helpers';
import TemplatesStore from './store';

const TemplateDetail = ({
  hideInstancesTable,
  expandByDefault = true,
}: {
  hideInstancesTable?: boolean;
  expandByDefault?: boolean;
}) => {
  const [expand, setExpand] = React.useState(expandByDefault);
  const [filterQuery, setFilterQuery] = React.useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const {
    instances,
    template,
    version,
    longDescription,
    readme,
    includedComponents,
    allFlows,
    tokens,
    admins,
    readOnly,
  } = useStores(
    () => {
      const {
        templateVersionDetail,
        instanceDetail,
        instances: allInstances,
      } = TemplatesStore.getStore();
      const components =
        templateVersionDetail?.components || instanceDetail?.versionDetail?.components;

      if (!components) return {};

      const template =
        templateVersionDetail?.template ||
        (instanceDetail?.templateDetail as Template | InstanceDetail['templateDetail']);

      return {
        template,
        instances: allInstances && getInstancesForTemplate(allInstances, template.id),
        version: templateVersionDetail?.version || instanceDetail?.versionDetail.version,
        longDescription:
          templateVersionDetail?.longDescription || instanceDetail?.versionDetail?.longDescription,
        readme: templateVersionDetail?.readme || instanceDetail?.versionDetail?.readme,
        includedComponents: ComponentsStore.getAll().filter(
          (component) => !!components?.includes(component.get('id')),
        ),
        allFlows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        tokens: StorageTokensStore.getAll() as List<any>,
        admins: ApplicationStore.getAdmins(),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [
      RoutesStore,
      TemplatesStore,
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTokensStore,
    ],
  );

  if (!template?.id) return null;

  const filteredInstances = instances && getFilteredInstances(instances, filterQuery);
  const disableCollapse = !hideInstancesTable && !instances?.length;

  return (
    <>
      <Collapse
        open={expand || disableCollapse}
        className="box panel-info tw-py-4 tw-pl-6 tw-pr-5"
        header={
          <div
            className={classNames('flex-container', { 'btn-collapse-area': !disableCollapse })}
            onClick={() => !disableCollapse && setExpand(!expand)}
          >
            <div className="flex-container line-height-24">
              <div className="mr-2 flex-container flex-start">
                <span className="mr-1 text-muted">Type: </span>
                <FontAwesomeIcon icon="book-open" className="icon-addon-right color-primary" />
                <span className="font-medium">Template</span>
              </div>
              <div className="mr-2 flex-container flex-start">
                <span className="mr-1 text-muted">Author: </span>
                <FontAwesomeIcon icon="user" className="icon-addon-right text-muted" />
                <span className="font-medium">{template.author.name}</span>
              </div>
              <div className="mr-2">
                <span className="mr-1 text-muted">Template Version: </span>
                <span className="font-medium">{version}</span>
              </div>
            </div>
            {!disableCollapse && (
              <CollapseButton
                entity="template info"
                isCollapsed={!expand}
                onToggle={() => setExpand(!expand)}
              />
            )}
          </div>
        }
      >
        <div className="template-detail panel-info-content text-muted tw-pt-4">
          <Markdown source={longDescription} collapsible={false} />
          {!includedComponents?.isEmpty() && (
            <>
              <h3 className="mtp-6 line-height-24">Components Included</h3>
              <div className="flex-container align-top limit-3 gap-16">
                {includedComponents
                  ?.map((component) => (
                    <ComponentBox key={component.get('id')} component={component} />
                  ))
                  .toArray()}
              </div>
            </>
          )}
          {readme && (
            <>
              <h3 className="mtp-6 line-height-24">More Details</h3>
              <Markdown collapsible={false} source={readme} />
            </>
          )}
        </div>
      </Collapse>
      {!hideInstancesTable && !!instances?.length && (
        <>
          <FilterPanel
            placeholder={`Search template configurations (${instances.length})`}
            query={filterQuery}
            onChange={(query: string) => {
              setFilterQuery(query);
              RoutesStore.getRouter().updateQuery({ q: query });
            }}
          />
          {!!filterQuery.trim() && !filteredInstances?.length ? (
            <NoResultsFound entityName="template instances" />
          ) : (
            <div className="box">
              <div className="box-content p-0">
                <InstancesTable
                  template={template}
                  instances={filteredInstances!}
                  allFlows={allFlows}
                  admins={admins}
                  tokens={tokens}
                  readOnly={readOnly}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TemplateDetail;
