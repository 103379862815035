import React from 'react';
import { Alert } from 'design';

const CannotExtendSandboxWarning = () => {
  return (
    <Alert variant="warning" className="tw-mb-5">
      Security updates have to be applied to the sandbox environment and it needs to be restarted.
      Please save your work and recreate the sandbox to be able to extend it.
    </Alert>
  );
};

export default CannotExtendSandboxWarning;
