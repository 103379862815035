import React from 'react';

const NoEntityCreated = (props: { entityName: string }) => {
  return (
    <div className="box">
      <div className="box-content">No {props.entityName} created yet.</div>
    </div>
  );
};

export default NoEntityCreated;
