import { fromJS, List, Map } from 'immutable';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import {
  AUTH_METHODS,
  BASIC_AUTH_PARAMS,
  RESERVED_CUSTOM_NAMES,
} from '@/modules/ex-generic/constants';
import {
  filterReservedRows,
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';

const KEY = 'auth';

const prepareMethodFields = (
  method: (typeof AUTH_METHODS)[keyof typeof AUTH_METHODS],
  parameters: Map<string, any>,
) => {
  if (method === AUTH_METHODS.BASIC) {
    return {
      username: parameters.getIn(['config', BASIC_AUTH_PARAMS.USERNAME], ''),
      password: parameters.getIn(['config', BASIC_AUTH_PARAMS.PASSWORD], ''),
    };
  }

  if (method === AUTH_METHODS.API_KEY) {
    const keyInHeaders = parameters
      .getIn(['api', 'http', 'headers'], Map())
      .findKey((value: PARAM_VALUE) => {
        return (
          Map.isMap(value) &&
          (value as Map<string, any>).get('attr') === RESERVED_CUSTOM_NAMES.AUTH_TOKEN
        );
      });

    const keyInParams = parameters
      .getIn(['api', 'http', 'defaultOptions', 'params'], Map())
      .findKey((value: PARAM_VALUE) => {
        return (
          Map.isMap(value) &&
          (value as Map<string, any>).get('attr') === RESERVED_CUSTOM_NAMES.AUTH_TOKEN
        );
      });

    return {
      key: keyInHeaders || keyInParams || '',
      token: parameters.getIn(['config', RESERVED_CUSTOM_NAMES.AUTH_TOKEN], ''),
      add_to: keyInParams ? 'query' : 'header',
    };
  }

  if (method === AUTH_METHODS.BEARER) {
    return {
      token: parameters.getIn(['config', RESERVED_CUSTOM_NAMES.BEARER_TOKEN], ''),
    };
  }

  if (method === AUTH_METHODS.OAUTH2) {
    const loginRequestPath = ['api', 'authentication', 'loginRequest'];
    const scopes = parameters.getIn([...loginRequestPath, 'params', 'scope'], '').trim();

    return {
      scopes: List(scopes ? scopes.split(' ') : []),
      login_type:
        parameters.getIn([...loginRequestPath, 'method']) === 'POST'
          ? 'json'
          : parameters.hasIn([...loginRequestPath, 'params', 'client_id'])
          ? 'post'
          : 'basic',
      endpoint: parameters.getIn([...loginRequestPath, 'endpoint'], ''),
      client_id: parameters.getIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_ID], ''),
      client_secret: parameters.getIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_SECRET], ''),
    };
  }

  if (method === AUTH_METHODS.QUERY) {
    return {
      query: transformMapToKeyValueList(
        parameters.getIn(['api', 'authentication', 'query'], Map()),
      ),
    };
  }

  if (method === AUTH_METHODS.CUSTOM) {
    return {
      authentication: JSON.stringify(
        parameters.getIn(['api', 'authentication'], Map().toJS()),
        null,
        '  ',
      ),
    };
  }

  return {};
};

const mapToState = (parameters: Map<string, any>) => {
  const method = parameters.getIn(
    ['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD],
    parameters.hasIn(['api', 'authentication']) ? AUTH_METHODS.CUSTOM : AUTH_METHODS.NONE,
  );

  return fromJS({ [KEY]: { method, ...prepareMethodFields(method, parameters) } });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  const method = editing.getIn([KEY, 'method']);

  // delete all auth related fields
  parameters = parameters
    // generic
    .deleteIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD])
    .deleteIn(['api', 'authentication'])
    .updateIn(['api', 'http', 'headers'], Map(), filterReservedRows)
    .updateIn(['api', 'http', 'defaultOptions', 'params'], Map(), filterReservedRows)
    // basic
    .deleteIn(['config', BASIC_AUTH_PARAMS.USERNAME])
    .deleteIn(['config', BASIC_AUTH_PARAMS.PASSWORD])
    // api key
    .deleteIn(['config', RESERVED_CUSTOM_NAMES.AUTH_TOKEN])
    // bearer
    .deleteIn(['config', RESERVED_CUSTOM_NAMES.BEARER_TOKEN])
    // oauth2
    .deleteIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_ID])
    .deleteIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_SECRET]);

  if (method === AUTH_METHODS.BASIC) {
    return parameters
      .setIn(['api', 'authentication'], fromJS({ type: 'basic' }))
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.BASIC)
      .setIn(['config', BASIC_AUTH_PARAMS.USERNAME], editing.getIn([KEY, 'username']))
      .setIn(['config', BASIC_AUTH_PARAMS.PASSWORD], editing.getIn([KEY, 'password']));
  }

  if (method === AUTH_METHODS.API_KEY) {
    const authTokenPath =
      editing.getIn([KEY, 'add_to']) !== 'query'
        ? ['headers', editing.getIn([KEY, 'key']), 'attr']
        : ['defaultOptions', 'params', editing.getIn([KEY, 'key']), 'attr'];

    return parameters
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.API_KEY)
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_TOKEN], editing.getIn([KEY, 'token']))
      .setIn(['api', 'http', ...authTokenPath], RESERVED_CUSTOM_NAMES.AUTH_TOKEN);
  }

  if (method === AUTH_METHODS.BEARER) {
    return parameters
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.BEARER)
      .setIn(['config', RESERVED_CUSTOM_NAMES.BEARER_TOKEN], editing.getIn([KEY, 'token']))
      .setIn(
        ['api', 'http', 'headers', 'Authorization'],
        fromJS({
          function: 'concat',
          args: ['Bearer ', { attr: RESERVED_CUSTOM_NAMES.BEARER_TOKEN }],
        }),
      );
  }

  if (method === AUTH_METHODS.OAUTH2) {
    const clientId = { attr: RESERVED_CUSTOM_NAMES.CLIENT_ID };
    const clientSecret = { attr: RESERVED_CUSTOM_NAMES.CLIENT_SECRET };
    const scopes = editing.getIn([KEY, 'scopes'], List());
    const loginType = editing.getIn([KEY, 'login_type'], 'basic');

    return parameters
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.OAUTH2)
      .setIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_ID], editing.getIn([KEY, 'client_id']))
      .setIn(['config', RESERVED_CUSTOM_NAMES.CLIENT_SECRET], editing.getIn([KEY, 'client_secret']))
      .setIn(
        ['api', 'authentication'],
        fromJS({
          type: 'login',
          format: 'json',
          loginRequest: {
            endpoint: editing.getIn([KEY, 'endpoint']),
            method: loginType === 'json' ? 'POST' : 'FORM',
            headers: {
              Accept: 'application/json',
              ...(loginType === 'basic' && {
                Authorization: {
                  function: 'concat',
                  args: [
                    'Basic ',
                    {
                      function: 'base64_encode',
                      args: [{ function: 'concat', args: [clientId, ':', clientSecret] }],
                    },
                  ],
                },
              }),
            },
            params: {
              grant_type: 'client_credentials',
              ...(!scopes.isEmpty() && { scope: scopes.join(' ') }),
              ...(['post', 'json'].includes(loginType) && {
                client_id: clientId,
                client_secret: clientSecret,
              }),
            },
          },
          apiRequest: {
            headers: {
              Authorization: {
                function: 'concat',
                args: ['Bearer ', { response: 'access_token' }],
              },
            },
          },
        }),
      );
  }

  if (method === AUTH_METHODS.QUERY) {
    return parameters
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.QUERY)
      .setIn(
        ['api', 'authentication'],
        fromJS({
          type: 'query',
          query: transformKeyValueListToMap(editing.getIn([KEY, 'query'])),
        }),
      );
  }

  if (method === AUTH_METHODS.CUSTOM) {
    return parameters
      .setIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD], AUTH_METHODS.CUSTOM)
      .setIn(
        ['api', 'authentication'],
        fromJS(JSON.parse(editing.getIn([KEY, 'authentication'] || '{}'))),
      );
  }

  return parameters;
};

export { KEY, mapToState, mapToApi };
