import React from 'react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import {
  getComponentTypeColorClassName,
  getNewComponentTypeLabel,
} from '@/modules/components/helpers';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentIcon from './ComponentIcon';
import Truncated from './Truncated';

type Props = {
  component: Map<string, any>;
  configuration: Map<string, any>;
  openInNewTab?: boolean;
  withLink?: boolean;
};

const ConfigurationWithComponent = ({
  component,
  configuration,
  openInNewTab,
  withLink,
}: Props) => {
  if (!component || component.isEmpty() || !configuration || configuration.isEmpty()) return null;

  return (
    <div className="tw-flex tw-items-center tw-justify-start">
      <ComponentIcon component={component} size="36" />
      <div className="tw-ml-3 tw-flex tw-flex-col tw-whitespace-pre-wrap">
        {withLink ? (
          <ComponentConfigurationLink
            componentId={component.get('id')}
            configId={configuration.get('id')}
            className="tw-break-all tw-text-sm tw-text-neutral-800 hover:tw-text-secondary-500"
            openInNewTab={openInNewTab}
          >
            <Truncated twoLines text={configuration.get('name')} />
          </ComponentConfigurationLink>
        ) : (
          <Truncated twoLines text={configuration.get('name')} />
        )}
        <div className="tw-flex tw-items-center tw-justify-start tw-gap-1 tw-text-xs tw-text-neutral-400">
          {component.get('type') && (
            <span
              className={classNames(
                'tw-whitespace-nowrap tw-font-medium',
                getComponentTypeColorClassName(component.get('type')),
              )}
            >
              {getNewComponentTypeLabel(component.get('type'))}
            </span>
          )}
          -
          <Truncated text={component.get('name')} />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationWithComponent;
