import React from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { canExportTable, canManageBuckets, canWriteBucket } from '@/modules/admin/privileges';
import { routeNames as bucketBrowserRouteNames } from '@/modules/bucket-browser/constants';
import { getAllowedTransformations } from '@/modules/components/helpers';
import StorageApiBucketLink from '@/modules/components/react/components/StorageApiBucketLink';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { factory as eventsFactory } from '@/modules/sapi-events/BucketEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import BucketActions from '@/modules/storage/components/BucketActions';
import BucketOverview from '@/modules/storage/components/BucketOverview';
import BucketEvents from '@/modules/storage/components/Events';
import { routeNames } from '@/modules/storage/constants';
import FullScreenModal from '@/react/common/FullScreenModal';
import Loader from '@/react/common/Loader';
import RouterLink from '@/react/common/RouterLink';
import TabLink from '@/react/common/TabLink';
import TabNav from '@/react/common/TabNav';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { parseLocationContext } from '@/utils/modalRoutes';

const tabs = {
  OVERVIEW: 'overview',
  EVENTS: 'events',
};

const Index = () => {
  const [isReloadingData, setIsReloadingData] = React.useState(false);
  const state = useStores(
    () => {
      const routerState = RoutesStore.getRouterState();
      const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
      const bucket = StorageBucketsStore.getBucket(bucketId, Map());
      const sapiToken = ApplicationStore.getSapiToken();
      const allTables = StorageTablesStore.getAll() as Map<string, any>;
      const tables = allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId);

      return {
        bucket,
        tables,
        bucketId,
        routerState,
        sapiToken,
        allTables,
        activeTab: RoutesStore.getCurrentRouteParam('bucketTab') || tabs.OVERVIEW,
        allBuckets: StorageBucketsStore.getAll(),
        components: ComponentsStore.getAll(),
        configurations: InstalledComponentsStore.getAll(),
        updatingBucket: StorageBucketsStore.isUpdatingBucket(bucketId),
        pendingActions: StorageBucketsStore.getPendingBucketsActions(),
        deletingTables: StorageTablesStore.getDeletingTables(),
        exportingTables: StorageTablesStore.getExportingTables(),
        truncatingTables: StorageTablesStore.getTruncatingTables(),
        createSnapshotsTables: StorageTablesStore.getCreatingSnapshotsTables(),
        canExportTable: canExportTable(sapiToken),
        canWriteBucket: canWriteBucket(sapiToken, bucket),
        canManageBuckets: canManageBuckets(sapiToken),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
        availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        availableDatabricksClusters: InstalledComponentsStore.getLocalState(
          KEBOOLA_SANDBOXES,
          null,
        ).get('clusters'),
        admins: ApplicationStore.getAdmins(),
        hasFlows: ApplicationStore.hasFlows(),
        hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
        ),
      };
    },
    [],
    [
      StorageTablesStore,
      StorageBucketsStore,
      ComponentsStore,
      InstalledComponentsStore,
      StackFeaturesStore,
      ApplicationStore,
      SandboxesStore,
      DevBranchesStore,
      RoutesStore,
    ],
  );

  const onHide = () => {
    const { pathname, query, hash, routeState } = parseLocationContext(
      state.routerState.get('location', Map()),
    );

    RoutesStore.getRouter().transitionTo(pathname, null, query, hash, routeState);
  };

  const renderTabLink = (tab: string, label: string) => {
    return (
      <TabLink
        active={state.activeTab === tab}
        to={bucketBrowserRouteNames.BUCKET_PREVIEW}
        params={{ bucketId: state.bucketId, bucketTab: tab }}
        query={{ context: state.routerState.getIn(['location', 'query', 'context']) }}
      >
        {label}
      </TabLink>
    );
  };

  const renderTabContent = () => {
    if (state.activeTab === tabs.EVENTS) {
      return (
        <BucketEvents eventsFactory={eventsFactory(state.bucket.get('id'))} admins={state.admins} />
      );
    }

    return (
      <BucketOverview
        isBucketBrowser
        components={state.components}
        configurations={state.configurations}
        allowedTransformationComponents={state.allowedTransformationComponents}
        sandboxes={state.sandboxes}
        canManageBuckets={state.canManageBuckets}
        sapiToken={state.sapiToken}
        buckets={state.allBuckets}
        bucket={state.bucket}
        tables={state.tables}
        allTables={state.allTables}
        updatingBucket={state.updatingBucket}
        pendingActions={state.pendingActions}
        hasPayAsYouGo={state.hasPayAsYouGo}
        isDevModeActive={state.isDevModeActive}
        canExportTable={state.canExportTable}
        hasFlows={state.hasFlows}
        canWriteBucket={state.canWriteBucket}
        urlTemplates={state.urlTemplates}
        deletingTables={state.deletingTables}
        truncatingTables={state.truncatingTables}
        exportingTables={state.exportingTables}
        createSnapshotsTables={state.createSnapshotsTables}
        availableUsersOptions={state.availableUsersOptions}
        availableProjectsOptions={state.availableProjectsOptions}
        availableDatabricksClusters={state.availableDatabricksClusters}
        hasSnowflakePartnerConnectLimited={state.hasSnowflakePartnerConnectLimited}
      />
    );
  };

  const renderTabs = () => {
    if (state.bucket.isEmpty()) {
      return <p>Bucket not found</p>;
    }

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-nowrap">
          <TabNav className="no-shrink pr-2">
            {renderTabLink(tabs.OVERVIEW, 'Overview')}
            {renderTabLink(tabs.EVENTS, 'Events')}
          </TabNav>
          <div className="ml-auto flex-container flex-end">
            <BucketActions bucket={state.bucket} />
          </div>
        </div>
        {renderTabContent()}
      </>
    );
  };

  const renderTopButton = () => {
    return (
      <ButtonToolbar>
        {!state.bucket.isEmpty() && (
          <>
            <StorageApiBucketLink bucketId={state.bucketId} className="btn btn-default">
              <FontAwesomeIcon icon="warehouse" className="icon-addon-right" />
              Explore in Storage
            </StorageApiBucketLink>
            <Button
              onClick={() => {
                setIsReloadingData(true);
                StorageActionCreators.loadBucketDetailForce(state.bucketId).finally(() => {
                  setIsReloadingData(false);
                });
              }}
              disabled={isReloadingData}
            >
              {isReloadingData ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="rotate" className="icon-addon-right" fixedWidth />
              )}
              Reload Data
            </Button>
            <span className="btn-separator" />
          </>
        )}
        <Button onClick={onHide}>
          <FontAwesomeIcon icon="xmark" />
        </Button>
      </ButtonToolbar>
    );
  };

  return (
    <FullScreenModal className="full-screen-generic-overview" onHide={onHide}>
      <Modal.Header>
        <div className="flex-container">
          <div className="modal-title">
            <div className="breadcrumb">
              <RouterLink className="active dark muted" to={routeNames.ROOT}>
                Storage
              </RouterLink>
            </div>
            <h4 className="flex-container flex-start">
              {state.bucket.isEmpty() ? (
                state.bucketId
              ) : (
                <>
                  <FontAwesomeIcon icon="folder" className="text-muted icon-addon-right" />
                  <Truncated text={state.bucket.get('displayName')} />
                </>
              )}
            </h4>
          </div>
          {renderTopButton()}
        </div>
      </Modal.Header>
      <Modal.Body>{renderTabs()}</Modal.Body>
    </FullScreenModal>
  );
};

export default Index;
