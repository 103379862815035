import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import Select from '@/react/common/Select';

const Notifications = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    notifications: PropTypes.object.isRequired, // notifications in structure from API
    emailOptions: PropTypes.array.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onNotificationsChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  },

  render() {
    const errorEmails = this._getNotificationsForChannel('error');
    const warningEmails = this._getNotificationsForChannel('warning');
    const processingEmails = this._getNotificationsForChannel('processing');

    return (
      <Form horizontal onSubmit={this.props.onSave}>
        {!this.props.readOnly && (
          <FormGroup>
            <div className="col-xs-12">
              <ConfirmButtons
                isSaving={this.props.isSaving}
                onCancel={this.props.onCancel}
                onSave={this.props.onSave}
                isDisabled={!this.props.isEditing}
                showCancel={this.props.isEditing}
                className="text-right"
              />
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <div className="col-xs-12">
            <p>
              Subscribe to receive notifications on some of the orchestration job events that might
              require your attention.
            </p>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Errors</ControlLabel>
          </div>
          <div className="col-xs-10">
            {this._renderNotificationsEditor('error', errorEmails)}
            <HelpBlock>Get notified when the orchestration finishes with an error.</HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Warnings</ControlLabel>
          </div>
          <div className="col-xs-10">
            {this._renderNotificationsEditor('warning', warningEmails)}
            <HelpBlock>Get notified when the orchestration finishes with a warning.</HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Processing</ControlLabel>
          </div>
          <div className="col-xs-10">
            {this._renderNotificationsEditor('processing', processingEmails)}
            <HelpBlock>
              <p className="form-inline">
                Get notified when a job is processing {this._renderToleranceInput()} % longer than
                usual.
              </p>
            </HelpBlock>
          </div>
        </FormGroup>
      </Form>
    );
  },

  _renderToleranceInput() {
    const notificationsCount = this._getNotificationsForChannel('processing')
      .filter((notification) => notification.hasIn(['parameters', 'tolerance']))
      .count();

    return (
      <FormControl
        type="number"
        min="1"
        max="100"
        value={this._getTolerance()}
        onChange={this._onToleranceChange}
        disabled={!notificationsCount || this.props.readOnly}
      />
    );
  },

  _renderNotificationsEditor(channelName, emails) {
    return (
      <Select
        multi
        allowCreate
        disabled={this.props.readOnly}
        value={emails.map((email) => email.get('email'))}
        options={this.props.emailOptions}
        placeholder="Enter email address"
        promptTextCreator={() => 'Add email address'}
        onChange={(newEmails) => this._onChannelChange(channelName, newEmails)}
      />
    );
  },

  _onChannelChange(channelName, newEmails) {
    const tolerance = this._getTolerance();
    let newNotifications = newEmails.map((email) =>
      Map({
        email: email,
        channel: channelName,
        parameters: Map(channelName === 'processing' ? { tolerance } : {}),
      }),
    );

    newNotifications = this.props.notifications
      .filter((notification) => notification.get('channel') !== channelName)
      .concat(newNotifications);

    return this.props.onNotificationsChange(newNotifications);
  },

  _onToleranceChange(e) {
    const tolerance = parseInt(e.target.value, 10);
    const newNotifications = this.props.notifications.map((notification) => {
      if (notification.get('channel') !== 'processing') {
        return notification;
      } else {
        return notification.setIn(['parameters', 'tolerance'], tolerance);
      }
    });
    return this.props.onNotificationsChange(newNotifications);
  },

  _getTolerance() {
    const notifications = this._getNotificationsForChannel('processing').filter((notification) =>
      notification.hasIn(['parameters', 'tolerance']),
    );

    if (!notifications.count()) {
      return 20;
    } else {
      return notifications.first().getIn(['parameters', 'tolerance']);
    }
  },

  _getNotificationsForChannel(channelName) {
    return this.props.notifications.filter(
      (notification) => notification.get('channel') === channelName,
    );
  },
});

export default Notifications;
