import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { Map } from 'immutable';

import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';

const NewProjectForm = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
  },

  handleChange(newDiff) {
    this.props.onChange({ ...this.props.value, ...newDiff });
  },

  allBucketsOptions() {
    return this.props.buckets
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      }))
      .toArray();
  },

  render() {
    return (
      <div>
        {this.renderInputControlGroup(
          'Project ID',
          'pid',
          'Type project ID',
          'ID of GoodData project/workspace',
        )}
        {this.renderInputControlGroup(
          'Username',
          'login',
          'Type username',
          'Username of GoodData user',
        )}
        {this.renderInputControlGroup(
          'Password',
          'password',
          'Type password',
          'Password of GoodData user',
        )}
        {this.renderInputControlGroup(
          'Backend URL (optional)',
          'backendUrl',
          'Type URL',
          'If empty, the default (https://secure.gooddata.com) will be used.',
        )}
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">
            <Checkbox
              checked={this.props.value.readLDM}
              onChange={(checked) => this.handleChange({ readLDM: checked })}
              disabled={this.props.disabled}
            >
              Read LDM model
            </Checkbox>
          </div>
        </FormGroup>
        {this.props.value.readLDM && (
          <FormGroup>
            <div className="col-xs-3">
              <ControlLabel>Target bucket</ControlLabel>
            </div>
            <div className="col-xs-9">
              <Select
                options={this.allBucketsOptions()}
                value={this.props.value.targetBucket}
                onChange={(selected) => this.handleChange({ targetBucket: selected })}
              />
            </div>
          </FormGroup>
        )}
      </div>
    );
  },

  renderInputControlGroup(label, fieldName, placeholder, help) {
    const { disabled, value } = this.props;

    return (
      <FormGroup key={fieldName}>
        <div className="col-xs-3">
          <ControlLabel>{label}</ControlLabel>
        </div>
        <div className="col-xs-9">
          {fieldName === 'password' ? (
            <PasswordControl
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => this.handleChange({ [fieldName]: e.target.value })}
              value={value[fieldName] || ''}
            />
          ) : (
            <FormControl
              placeholder={placeholder}
              type="text"
              disabled={disabled}
              onChange={(e) => this.handleChange({ [fieldName]: e.target.value })}
              value={value[fieldName] || ''}
            />
          )}
          {help && <HelpBlock>{help}</HelpBlock>}
        </div>
      </FormGroup>
    );
  },
});

export default NewProjectForm;
