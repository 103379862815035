import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from '@keboola/constants';
import { Clipboard, Link } from 'design';
import { Map } from 'immutable';

import CodeEditor from '@/react/common/CodeEditor';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import SaveButtons from '@/react/common/SaveButtons';
import nextTick from '@/utils/nextTick';
import { isValidJsonConfig } from '@/utils/validation';

class Processors extends React.Component {
  constructor(props) {
    super(props);

    const initialValue = this.getInitialValue();

    this.state = {
      isSaving: false,
      initialValue,
      value: initialValue,
    };
  }

  render() {
    return (
      <CollapsibleBox
        hideSuffixIfCollapsed
        title="Processors"
        titleSuffix={
          !this.isEmpty() && <Clipboard text={this.state.value} btnClassName="text-muted-light" />
        }
        entity="processors"
        defaultOpen={!this.isEmpty()}
        additionalActions={() => {
          if (this.props.readOnly) {
            return null;
          }

          return (
            <SaveButtons
              isSaving={this.state.isSaving}
              disabled={!this.isValid()}
              isChanged={this.state.value !== this.state.initialValue}
              onSave={this.handleSubmmit}
              onReset={this.handleReset}
            />
          );
        }}
      >
        <div className="edit">
          <CodeEditor
            value={this.state.value}
            onChange={this.handleChange}
            options={{ readOnly: this.props.readOnly || this.state.isSaving }}
          />
          <div className="help-block mb-0">
            Learn more about{' '}
            <Link href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/component/processors/`}>
              Processors
            </Link>
          </div>
        </div>
      </CollapsibleBox>
    );
  }

  getInitialValue() {
    const value = this.isEmpty() ? { before: [], after: [] } : this.props.value.toJS();

    return JSON.stringify(value, null, 2);
  }

  isEmpty() {
    return !Map.isMap(this.props.value) || this.props.value.isEmpty();
  }

  isValid() {
    return this.state.value === '' || isValidJsonConfig(this.state.value);
  }

  isChanged() {
    return this.state.value !== this.state.initialValue;
  }

  handleReset = () => {
    this.setState({ value: this.state.initialValue });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  handleSubmmit = () => {
    const processors =
      this.state.value === '' || this.state.value === '{}' ? null : JSON.parse(this.state.value);

    this.setState({ isSaving: true });
    return this.props
      .onSubmit(processors)
      .then(() => {
        // wait for update props
        nextTick(() => {
          const newInitialValue = this.getInitialValue();
          this.setState({ initialValue: newInitialValue, value: newInitialValue });
        });
      })
      .finally(() => this.setState({ isSaving: false }));
  };
}

Processors.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Processors;
