import React, { useState } from 'react';
import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type CreatePrimaryKeyModalProps = {
  show: boolean;
  table: Map<string, any>;
  onSubmit: (primaryKey: string[]) => void;
  onHide: () => void;
};

const CreatePrimaryKeyModal = ({ show, table, onSubmit, onHide }: CreatePrimaryKeyModalProps) => {
  const [primaryKey, setPrimaryKey] = useState<string[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(primaryKey);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} onEnter={() => setPrimaryKey([])}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Primary Key</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <DevBranchStorageWarning
            message="The primary key will also be added to the table in production."
            hasProductionEntity={!isCreatedInDevBranch(table.get('bucket'))}
          />
          <FormGroup>
            <ControlLabel>Columns</ControlLabel>
            <Select
              multi
              autoFocus
              clearable={false}
              placeholder="Check one or more columns"
              value={primaryKey}
              onChange={(selectedColumns) => setPrimaryKey(selectedColumns.toJS())}
              options={table
                .get('columns')
                .map((column: any) => ({ label: column, value: column }))
                .toArray()}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isDisabled={primaryKey.length === 0}
            saveLabel="Create primary key"
            saveButtonType="submit"
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreatePrimaryKeyModal;
