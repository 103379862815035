import { fromJS } from 'immutable';

const LOGIN_TYPES = {
  CREDENTIALS: 'credentials',
  ROLE: 'role',
};

const GET_EXTERNAL_ID_ACTION = {
  name: 'getExternalId',
  cache: true,
  autoload: true,
  getPayload: () => fromJS({ configData: {} }),
};

export { LOGIN_TYPES, GET_EXTERNAL_ID_ACTION };
