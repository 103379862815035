import { fromJS } from 'immutable';

const createConfiguration = function (localState) {
  return fromJS({
    parameters: {
      append: localState.get('append', false),
      workbook: {
        driveId: localState.get('driveId', ''),
        fileId: localState.get('fileId', ''),
        metadata: {
          name: localState.get('workbookName', ''),
        },
      },
      worksheet: {
        id: localState.get('id', ''),
        metadata: {
          name: localState.get('worksheetName', ''),
        },
      },
    },
  });
};

const parseConfiguration = function (configuration) {
  return fromJS({
    append: configuration.getIn(['parameters', 'append'], false),
    driveId: configuration.getIn(['parameters', 'workbook', 'driveId'], ''),
    fileId: configuration.getIn(['parameters', 'workbook', 'fileId'], ''),
    id: configuration.getIn(['parameters', 'worksheet', 'id'], ''),
    workbookName: configuration.getIn(['parameters', 'workbook', 'metadata', 'name'], ''),
    worksheetName: configuration.getIn(['parameters', 'worksheet', 'metadata', 'name'], ''),
  });
};

const createEmptyConfiguration = function () {
  return createConfiguration(fromJS({}));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
