import React from 'react';

type StatusIndicatorProps = {
  width: number;
  x: number;
  y: number;
  radius: number;
  color: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: (e: React.MouseEvent) => void;
};

const StatusIndicator = (props: StatusIndicatorProps) => (
  <g
    style={props.onClick && { cursor: 'pointer' }}
    onClick={props.onClick}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    <circle
      fill={props.color}
      cx={props.x + props.width / 2}
      cy={props.y - props.width / 2}
      r={props.radius}
      strokeWidth={20}
      stroke="transparent"
    />
  </g>
);

export default StatusIndicator;
