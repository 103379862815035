import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { FEATURE_POWER_USER } from '@/constants/features';
import keyCodes from '@/constants/keyCodes';
import { addUserFeature } from '@/modules/settings/actions';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const INITIAL_STATE = { hideConfirm: false };

class RunComponentButtonModal extends React.Component {
  state = INITIAL_STATE;

  render() {
    return (
      <span
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          onExited={this.props.onExited}
          onEnter={() => {
            this.setState(INITIAL_STATE);
            this.props.onEnter?.();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.shouldShowOutputMappingWarning
                ? 'Output Mapping Not Defined'
                : this.props.title}
            </Modal.Title>
            {this.props.shouldShowOutputMappingWarning ? (
              <ModalIcon icon="warning" color="yellow" bold />
            ) : (
              <ModalIcon icon="play" color="green" bold />
            )}
          </Modal.Header>
          <Modal.Body>
            {this.props.shouldShowOutputMappingWarning && (
              <div className="tw-mb-6">
                <p>
                  The output mapping in this transformation has not been defined. Have you perhaps
                  forgotten to set it up? While the transformation will proceed, it will not
                  generate an output table unless specified in the output mapping.
                </p>
                <p className="tw-text-neutral-400">
                  Please note that any tables or files not listed in the output mapping are
                  considered temporary. They are removed once the transformation job is completed.
                </p>
              </div>
            )}
            {this.props.body}
            {!this.props.forceModal && window.location.hash !== '#run' && (
              <Checkbox
                className="block mt-1"
                checked={this.state.hideConfirm}
                onChange={this.handleToggleHideConfirm}
              >
                I am a Keboola power user. Do not display similar confirmations.
              </Checkbox>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.props.runLabel}
              saveButtonClass={this.props.className}
              onSave={this.handleRun}
              isDisabled={this.props.disabled}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  handleToggleHideConfirm = (checked) => {
    this.setState({ hideConfirm: checked });
  };

  handleRun = () => {
    if (this.state.hideConfirm) {
      addUserFeature(FEATURE_POWER_USER);
    }

    this.props.onHide();
    this.props.onRequestRun();
  };
}

RunComponentButtonModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onRequestRun: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  runLabel: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  forceModal: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default RunComponentButtonModal;
