import { Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import { ActionTypes, ObjectTypes } from './MetadataConstants';
import storageApi from './StorageApi';

export default {
  saveMetadata(objectType, objectId, metadataKey, newValue) {
    return storageApi
      .saveMetadata(objectType, objectId, Map([[metadataKey, newValue]]))
      .then((metadata) => {
        dispatcher.handleViewAction({
          type: ActionTypes.METADATA_SAVE_SUCCESS,
          objectType,
          objectId,
          metadataKey,
          metadata,
        });
        return metadata;
      });
  },

  saveBranchesMetadata(branchId, key, value) {
    return storageApi.saveBranchesMetadata(branchId, key, value).then((metadata) => {
      dispatcher.handleViewAction({
        type: ActionTypes.METADATA_SAVE_SUCCESS,
        objectType: ObjectTypes.BRANCH,
        metadata,
      });
    });
  },

  saveMetadataSet(objectType, objectId, metadata) {
    return storageApi.saveMetadata(objectType, objectId, metadata).then((metadata) => {
      dispatcher.handleViewAction({
        type: ActionTypes.METADATA_SAVE_SUCCESS,
        objectType,
        objectId,
        metadata,
      });
      return metadata;
    });
  },

  loadProjectBranchMetadata(id) {
    storageApi.getDevBranchMetaData(id).then((metadata) =>
      dispatcher.handleViewAction({
        type: ActionTypes.BRANCH_METADATA_LOAD_SUCCESS,
        metadata,
      }),
    );
  },

  deleteMetadata(objectType, objectId, metadataId) {
    return storageApi.deleteMetadata(objectType, objectId, metadataId).then(() => {
      dispatcher.handleViewAction({
        type: ActionTypes.METADATA_DELETE_SUCCESS,
        objectType,
        objectId,
        metadataId,
      });
    });
  },
};
