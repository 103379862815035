import React from 'react';
import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { prepareUserParameters } from '@/modules/ex-generic/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';
import GenericPairs from './GenericPairs';

const Headers = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  parameters: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
  title?: string;
}) => {
  const rows = props.editing.get('headers', List());

  return (
    <CollapsibleBox
      title={props.title || 'Headers'}
      defaultOpen={!rows.isEmpty()}
      titleSuffix={<DocumentationLink path="configuration/api/#headers" />}
    >
      <GenericPairs
        entity="Header"
        rows={rows}
        onSave={props.onSave}
        readOnly={props.readOnly}
        parameters={props.parameters}
        onChange={(headers) => props.setEditing(props.editing.set('headers', headers))}
        userParameters={prepareUserParameters(props.parameters)}
      />
    </CollapsibleBox>
  );
};

export default Headers;
