import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import { ActionTypes as InstalledComponentsActionTypes } from '@/modules/components/Constants';
import rowsConstants from '@/modules/configurations/ConfigurationRowsConstants';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { ActionTypes } from '@/modules/legacy-transformation/Constants';
import parseBuckets from '@/modules/legacy-transformation/utils/parseBuckets';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'TransformationsStore2',
  Map({
    transformationsByBucketId: Map(),
    loadingTransformationBuckets: List(),
    pendingActions: Map(),
    editingTransformationsFields: Map(),
  }),
);

const enhanceTransformation = (transformation) => {
  const queries = transformation.get('queries', List());
  if (transformation.get('backend') === 'docker') {
    return transformation.set('queriesString', queries.join('\n'));
  }
  return transformation.set('queriesString', queries.join('\n\n'));
};

const TransformationsStore = StoreUtils.createStore({
  getAllTransformations() {
    return _store.getIn(['transformationsByBucketId'], List());
  },

  getTransformations(bucketId) {
    return _store
      .getIn(['transformationsByBucketId', bucketId], List())
      .sortBy((transformation) => {
        const phase = transformation.get('phase', 0);
        const name = transformation.get('name', '');
        return phase + name.toLowerCase();
      });
  },

  hasTransformations(bucketId) {
    return _store.get('transformationsByBucketId').has(bucketId);
  },

  getTransformation(bucketId, transformationId) {
    return _store.getIn(['transformationsByBucketId', bucketId, transformationId]);
  },

  getTransformationEditingFields(bucketId, transformationId) {
    return _store.getIn(['editingTransformationsFields', bucketId, transformationId], Map());
  },

  isTransformationEditingName(bucketId, transformationId) {
    return _store.getIn(['editingTransformationsName', bucketId, transformationId], false);
  },

  hasTransformation(bucketId, transformationId) {
    return _store.hasIn(['transformationsByBucketId', bucketId, transformationId]);
  },

  /*
    Test if specified transformation buckets are currently being loaded
  */
  isBucketLoading(bucketId) {
    return _store.get('loadingTransformationBuckets').contains(bucketId);
  },

  getAllPendingActions() {
    return _store.getIn(['pendingActions'], Map());
  },

  getPendingActions(bucketId) {
    return _store.getIn(['pendingActions', bucketId], Map());
  },

  getTransformationPendingActions(bucketId, transformationId) {
    return _store.getIn(['pendingActions', bucketId, transformationId], Map());
  },

  getTransformationEditingIsValid(bucketId, transformationId) {
    return !!this.getTransformation(bucketId, transformationId);
  },

  getTransformationDescription(bucketId, transformationId) {
    let description = ConfigurationRowsStore.get(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
      transformationId,
    ).get('description');
    if (description === '') {
      description = this.getTransformation(bucketId, transformationId).get('description');
    }
    return description;
  },

  getTransformationName(bucketId, transformationId) {
    let name = ConfigurationRowsStore.get(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
      transformationId,
    ).get('name');
    if (name === '') {
      name = this.getTransformation(bucketId, transformationId).get('name');
    }
    return name;
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.TRANSFORMATION_CREATE_SUCCESS:
      _store = _store.setIn(
        ['transformationsByBucketId', action.bucketId, action.transformation.id],
        enhanceTransformation(fromJS(action.transformation)),
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_DELETE:
      _store = _store.setIn(
        ['pendingActions', action.bucketId, action.transformationId, 'delete'],
        true,
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_DELETE_SUCCESS:
      _store = _store.withMutations((store) =>
        store
          .removeIn(['transformationsByBucketId', action.bucketId, action.transformationId])
          .removeIn(['pendingActions', action.bucketId, action.transformationId, 'delete']),
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_DELETE_ERROR:
      _store = _store.removeIn([
        'pendingActions',
        action.bucketId,
        action.transformationId,
        'delete',
      ]);
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_EDIT_SAVE_START:
      _store = _store.setIn(
        ['pendingActions', action.bucketId, action.transformationId, action.pendingAction],
        true,
      );
      // Update Transformation in store immediately (we assume it'll be saved)
      if (action.transformation) {
        _store = _store.setIn(
          ['transformationsByBucketId', action.bucketId, action.transformationId],
          action.transformation,
        );
      }
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS:
      _store = _store.withMutations((store) => {
        const tObj = enhanceTransformation(fromJS(action.data));
        store
          .setIn(['transformationsByBucketId', action.bucketId, action.transformationId], tObj)
          .deleteIn([
            'pendingActions',
            action.bucketId,
            action.transformationId,
            action.pendingAction,
          ]);

        if (action.editingId) {
          store.deleteIn([
            'editingTransformationsFields',
            action.bucketId,
            action.transformationId,
            action.editingId,
          ]);

          if (action.editingId === 'queries') {
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'splitQueries',
            ]);
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'queriesString',
            ]);
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'queriesChanged',
            ]);
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'description',
            ]);
          } else if (action.editingId === 'packages') {
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'packagesChanged',
            ]);
          } else if (action.editingId === 'tags') {
            store.deleteIn([
              'editingTransformationsFields',
              action.bucketId,
              action.transformationId,
              'tagsChanged',
            ]);
          }
        }
      });

      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR:
      _store = _store.deleteIn([
        'pendingActions',
        action.bucketId,
        action.transformationId,
        action.pendingAction,
      ]);
      // Revert Transformation to the previous state (save didn't happen)
      if (action.transformation) {
        _store = _store.setIn(
          ['transformationsByBucketId', action.bucketId, action.transformationId],
          action.transformation,
        );
      }
      return TransformationsStore.emitChange();

    case InstalledComponentsActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS: {
      if (action.componentId !== KEBOOLA_LEGACY_TRANSFORMATION) {
        return;
      }
      const bucketsData = parseBuckets(action.configData);
      _store = _store.withMutations((store) => {
        store.delete('transformationsByBucketId');
        return _.each(bucketsData, (bucket) =>
          _.each(bucket.transformations, (transformation) => {
            const tObj = enhanceTransformation(fromJS(transformation));
            return store.setIn(['transformationsByBucketId', bucket.id, tObj.get('id')], tObj);
          }),
        );
      });
      return TransformationsStore.emitChange();
    }

    case rowsConstants.ActionTypes.CONFIGURATION_ROWS_UPDATE_SUCCESS: {
      // Delete description from store if it's no longer part of configuration
      if (
        action.componentId === KEBOOLA_LEGACY_TRANSFORMATION &&
        action.field &&
        action.field === 'description' &&
        action.data.configuration &&
        !action.data.configuration.description
      ) {
        _store = _store.deleteIn([
          'transformationsByBucketId',
          action.configurationId,
          action.rowId,
          'description',
        ]);
        return TransformationsStore.emitChange();
      }
      return;
    }

    case ActionTypes.TRANSFORMATION_UPDATE_EDITING_FIELD:
      _store = _store.setIn(
        ['editingTransformationsFields', action.bucketId, action.transformationId, action.fieldId],
        action.newValue,
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_CANCEL_EDITING_FIELD:
      _store = _store.deleteIn([
        'editingTransformationsFields',
        action.bucketId,
        action.transformationId,
        action.fieldId,
      ]);
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES:
      _store = _store.setIn(
        ['pendingActions', action.bucketId, action.transformationId, 'queries-processing'],
        true,
      );
      _store = _store.setIn(
        ['editingTransformationsFields', action.bucketId, action.transformationId, 'splitQueries'],
        fromJS([action.queriesString]),
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS:
      _store = _store.deleteIn([
        'pendingActions',
        action.bucketId,
        action.transformationId,
        'queries-processing',
      ]);
      _store = _store.setIn(
        ['editingTransformationsFields', action.bucketId, action.transformationId, 'splitQueries'],
        fromJS(action.splitQueries),
      );
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR:
      _store = _store.deleteIn([
        'pendingActions',
        action.bucketId,
        action.transformationId,
        'queries-processing',
      ]);
      return TransformationsStore.emitChange();

    case ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS:
      _store = _store.withMutations((store) =>
        store.removeIn(['transformationsByBucketId', action.bucketId]),
      );
      return TransformationsStore.emitChange();

    default:
  }
});

export default TransformationsStore;
