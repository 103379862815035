import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'design';
import _ from 'underscore';

const OFFSET_TOLERANCE = 2;

type Props = {
  text: ReactNode;
  tooltip?: ReactNode;
  className?: string;
  twoLines?: boolean;
  noTooltip?: boolean;
  redact?: boolean;
};

const Truncated = ({
  text,
  tooltip = text,
  className,
  twoLines = false,
  noTooltip = false,
  redact = false,
}: Props) => {
  const tooltipText = _.isString(tooltip) ? tooltip : '';
  const [showTooltip, setShowTooltip] = React.useState(false);

  if (noTooltip || !tooltipText) {
    return (
      <span className={classNames(className, 'truncated', { 'truncated-2-lines': twoLines })}>
        {text}
      </span>
    );
  }

  return (
    <Tooltip
      placement="top"
      type="explanatory"
      forceHide={!showTooltip}
      tooltip={<div data-heap-redact-text={redact}>{tooltipText}</div>}
    >
      <span
        className={classNames(className, 'truncated', { 'truncated-2-lines': twoLines })}
        onMouseOver={(e) => {
          setShowTooltip(
            e.currentTarget.scrollHeight - OFFSET_TOLERANCE >= e.currentTarget.clientHeight,
          );
        }}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export default Truncated;
