import React from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

import Loader from './Loader';

type Props = {
  onClick: () => void;
  className?: string;
  style?: 'default' | 'primary' | 'success';
  label?: string | React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
};

const BlockButton = ({
  onClick,
  label = 'Load more',
  isLoading = false,
  disabled = false,
  style = 'primary',
  className,
}: Props) => (
  <div className={classnames('block-button', className)}>
    <Button block bsStyle={style} onClick={onClick} disabled={disabled || isLoading}>
      {isLoading ? <Loader /> : label}
    </Button>
  </div>
);

export default BlockButton;
