import { Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

let _store = initStore(
  'StorageFilesLocalStore',
  Map({
    searchQuery: '',
  }),
);

const FilesLocalStore = StoreUtils.createStore({
  getSearchQuery() {
    return _store.get('searchQuery', '');
  },
});

dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case actionTypes.UPDATE_FILES_SEARCH_QUERY:
      _store = _store.set('searchQuery', action.query);
      return FilesLocalStore.emitChange();

    default:
      break;
  }
});

export default FilesLocalStore;
