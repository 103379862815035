export default {
  ActionTypes: {
    VERSIONS_LOAD_START: 'VERSIONS_LOAD_START',
    VERSIONS_LOAD_SUCCESS: 'VERSIONS_LOAD_SUCCESS',
    VERSIONS_LOAD_ERROR: 'VERSIONS_LOAD_ERROR',

    VERSIONS_RELOAD_SUCCESS: 'VERSIONS_RELOAD_SUCCESS',

    VERSIONS_ROLLBACK_SUCCESS: 'VERSIONS_ROLLBACK_SUCCESS',

    VERSIONS_PENDING_START: 'VERSIONS_PENDING_START',
    VERSIONS_PENDING_STOP: 'VERSIONS_PENDING_STOP',

    VERSIONS_CONFIG_LOAD_SUCCESS: 'VERSIONS_CONFIG_LOAD_SUCCESS',
    VERSIONS_MULTI_PENDING_START: 'VERSIONS_MULTI_PENDING_START',
    VERSIONS_MULTI_PENDING_STOP: 'VERSIONS_MULTI_PENDING_STOP',
  },
};
