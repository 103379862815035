import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import InstalledComponentsAction from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const ComponentEditField = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore)],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    editElement: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    readOnly: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      placeholder: 'Describe the component ...',
      tooltipPlacement: 'top',
    };
  },

  getInitialState() {
    const config = InstalledComponentsStore.getConfig(this.props.componentId, this.props.configId);

    return {
      value: config.get(this.props.fieldName, ''),
      isEditing: false,
      isSaving: false,
    };
  },

  getStateFromStores() {
    const config = InstalledComponentsStore.getConfig(this.props.componentId, this.props.configId);

    return {
      originalValue: config.get(this.props.fieldName, ''),
      readOnly: this.props.readOnly || ApplicationStore.isReadOnly(),
    };
  },

  render() {
    if (this.props.fieldName === 'name' && this.state.readOnly) {
      return this.state.value;
    }

    const EditElement = this.props.editElement;

    return (
      <EditElement
        readOnly={this.state.readOnly}
        text={this.state.value}
        placeholder={this.props.placeholder}
        tooltipPlacement={this.props.tooltipPlacement}
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        isValid={this.isValid()}
        isChanged={this.state.value !== this.state.originalValue}
        onEditStart={this.handleEditStart}
        onEditCancel={this.handleEditCancel}
        onEditChange={this.handleEditChange}
        onEditSubmit={this.handleEditSubmit}
      />
    );
  },

  handleEditStart() {
    this.setState({ isEditing: true });
  },

  handleEditCancel() {
    this.setState({ isSaving: false, isEditing: false, value: this.state.originalValue });
  },

  handleEditChange(newValue) {
    this.setState({ value: newValue });
  },

  handleEditSubmit() {
    this.setState({ isSaving: true });
    InstalledComponentsAction.updateComponentConfiguration(
      this.props.componentId,
      this.props.configId,
      { [this.props.fieldName]: this.state.value.trim() },
      `Update ${this.props.fieldName}`,
      this.props.fieldName,
    ).finally(() => this.setState({ isSaving: false, isEditing: false }));
  },

  isValid() {
    if (this.props.fieldName === 'name') {
      return !!this.state.value.trim();
    }

    return true;
  },
});

export default ComponentEditField;
