import React from 'react';
import PropTypes from 'prop-types';
import { Well } from 'react-bootstrap';
import classnames from 'classnames';
import { Link } from 'design';
import _ from 'underscore';

const Help = ({ title, text, documentation, style, down = false }) => {
  return (
    <Well className={classnames('step-help', { 'is-down': down })} style={style}>
      {title && <h3>{title}</h3>}
      {_.isString(text) ? <p>{text}</p> : text}
      {documentation && (
        <Link className="display-block mt-2 f-12 font-medium" href={documentation}>
          Read more in documentation
        </Link>
      )}
    </Well>
  );
};

Help.propTypes = {
  text: PropTypes.node.isRequired,
  documentation: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  down: PropTypes.bool,
};

export default Help;
