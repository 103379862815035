import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import {
  KDS_TEAM_WR_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
} from '@/constants/componentIds';
import Checkbox from './Checkbox';
import { HIVE_AUTH_TYPES } from './constants';
import PasswordControl from './PasswordControl';
import Select from './Select';
import SshForm from './SshForm';
import TestCredentialsButtonGroup from './TestCredentialsButtonGroup';

const SSL_FILE_TYPES = {
  PEM: 'pem',
  JKS: 'jks',
};

const HiveCredentials = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    credentials: PropTypes.object.isRequired,
    editingCredentials: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    hasValidCredentials: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    actionCreators: PropTypes.object.isRequired,
    hasArtifacts: PropTypes.bool.isRequired,
    hasQueries: PropTypes.bool,
  },

  render() {
    return (
      <div className="form-horizontal">
        {this.renderQueriesWarning()}
        {this.renderForm()}
        {this.renderTestButton()}
      </div>
    );
  },

  renderForm() {
    const isDisabled = this.props.isSaving || this.props.readOnly;

    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Hostname</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.credentials.get('host', '')}
              onChange={({ target }) =>
                this.props.onChange(this.props.editingCredentials.set('host', target.value))
              }
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Port</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="number"
              value={this.props.credentials.get('port', '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.set(
                    'port',
                    target.value ? parseInt(target.value, 10) : '',
                  ),
                )
              }
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Database</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.credentials.get('database', '')}
              onChange={({ target }) =>
                this.props.onChange(this.props.editingCredentials.set('database', target.value))
              }
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              disabled={isDisabled}
              checked={this.props.credentials.get('connectThrough', false)}
              onChange={(checked) => {
                this.props.onChange(this.props.editingCredentials.set('connectThrough', checked));
              }}
            >
              Enable Connect Through
            </Checkbox>
            <HelpBlock>
              Sets the <code>DelegationUID</code> from the user who runs the configuration.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Auth Type</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              clearable={false}
              options={Object.values(HIVE_AUTH_TYPES).map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={this.props.credentials.get('authType', HIVE_AUTH_TYPES.PASSWORD)}
              onChange={(value) =>
                this.props.onChange(
                  value === HIVE_AUTH_TYPES.PASSWORD
                    ? this.props.editingCredentials
                        .set('authType', HIVE_AUTH_TYPES.PASSWORD)
                        .delete('kerberos')
                    : this.props.credentials
                        .set('authType', value)
                        .delete('user')
                        .delete('#password'),
                )
              }
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        {this.props.credentials.get('authType', HIVE_AUTH_TYPES.PASSWORD) ===
        HIVE_AUTH_TYPES.PASSWORD
          ? this.renderPasswordForm(isDisabled)
          : this.renderKerberosForm(isDisabled)}
        {this.renderSshForm()}
        {this.renderSslForm(isDisabled)}
        {this.renderAdditionalAdvancedFields(isDisabled)}
      </>
    );
  },

  renderPasswordForm(isDisabled) {
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Username</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.credentials.get('user', '')}
              onChange={({ target }) =>
                this.props.onChange(this.props.editingCredentials.set('user', target.value))
              }
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Password</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              disabled={isDisabled}
              value={this.props.credentials.get('#password', '')}
              onChange={({ target }) =>
                this.props.onChange(this.props.editingCredentials.set('#password', target.value))
              }
            />
          </div>
        </FormGroup>
      </>
    );
  },

  renderKerberosForm(isDisabled) {
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Kinit Principal</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.credentials.getIn(['kerberos', 'kinitPrincipal'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', 'kinitPrincipal'], target.value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Name of the principal for the kinit. It may be shortened if it is supported by your{' '}
              <code>krb5.conf</code>.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Service Principal</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.credentials.getIn(['kerberos', 'servicePrincipal'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(
                    ['kerberos', 'servicePrincipal'],
                    target.value,
                  ),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Name of the principal for the ODBC connection. A fully qualified name must be used:{' '}
              <code>[service]/[host]@[realm]</code>.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Config</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Textarea
              minRows={4}
              className="form-control"
              value={this.props.credentials.getIn(['kerberos', 'config'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', 'config'], target.value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Content of the <code>krb5.conf</code> file.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Keytab</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Textarea
              minRows={4}
              className="form-control"
              value={this.props.credentials.getIn(['kerberos', '#keytab'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', '#keytab'], target.value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Base64 encoded content of the <code>*.keytab</code> file
            </HelpBlock>
          </div>
        </FormGroup>
      </>
    );
  },

  renderAdditionalAdvancedFields(isDisabled) {
    if (!this.supportAdditionalFields()) {
      return null;
    }

    return (
      <>
        <h3>Advanced Options</h3>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Http Path</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              placeholder="Keep empty for default behavior"
              value={this.props.credentials.get('httpPath', '')}
              onChange={({ target }) =>
                this.props.onChange(this.props.editingCredentials.set('httpPath', target.value))
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Default <code>/hive2</code> if using Windows Azure HDInsight Service. <code>/</code>{' '}
              if using non-Windows Azure HDInsight Service with Thrift Transport set to HTTP.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Thrift Transport</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              placeholder="Keep empty for default behavior"
              value={this.props.credentials.get('thriftTransport')}
              onChange={(selected) =>
                this.props.onChange(this.props.editingCredentials.set('thriftTransport', selected))
              }
              options={[
                { value: 0, label: 'Binary' },
                { value: 1, label: 'SASL' },
                { value: 2, label: 'HTTP' },
              ]}
              disabled={isDisabled}
            />
            <HelpBlock>
              Default <code>Binary</code> if you are connecting to Hive Server 1. <code>SASL</code>{' '}
              if you are connecting to Hive Server 2.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Batch Size</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              min={1}
              type="number"
              value={this.props.credentials.get('batchSize', 10000)}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.set('batchSize', parseInt(target.value) || 1),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              It sets <code>RowsFetchedPerBlock</code> parameter.
            </HelpBlock>
          </div>
        </FormGroup>
        {this.props.hasArtifacts && (
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.credentials.get('verboseLogging', false)}
                onChange={(checked) =>
                  this.props.onChange(this.props.editingCredentials.set('verboseLogging', checked))
                }
              >
                Verbose Logging
              </Checkbox>
              <HelpBlock>Logs all driver activity.</HelpBlock>
            </div>
          </FormGroup>
        )}
        <hr />
      </>
    );
  },

  renderSshForm() {
    return (
      <SshForm
        isEnabled={!this.props.isSaving}
        readOnly={this.props.isSaving || this.props.readOnly}
        data={this.props.credentials.get('ssh', Map())}
        onChange={(ssh) => {
          this.props.onChange(
            this.props.editingCredentials.set(
              'ssh',
              ssh.get('enabled')
                ? ssh
                : Map({
                    enabled: false,
                  }),
            ),
          );
        }}
      />
    );
  },

  renderSslForm(isDisabled) {
    const activeSsl = this.props.credentials.getIn(['ssl', 'enabled'], false);
    const caFileType = this.props.credentials.getIn(['ssl', 'caFileType'], SSL_FILE_TYPES.PEM);
    const caType = caFileType === SSL_FILE_TYPES.PEM && this.isCsasComponent() ? '#ca' : 'ca';

    return (
      <>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              checked={activeSsl}
              onChange={(checked) =>
                this.props.onChange(
                  this.props.editingCredentials.set(
                    'ssl',
                    Map({
                      enabled: checked,
                      ...(checked && {
                        caFileType: SSL_FILE_TYPES.PEM,
                        ca: '',
                        verifyServerCert: true,
                      }),
                    }),
                  ),
                )
              }
            >
              Encrypted (SSL) connection
            </Checkbox>
          </div>
        </FormGroup>
        {activeSsl && (
          <>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>File Type</ControlLabel>
              </div>
              <div className="col-xs-8">
                <Select
                  clearable={false}
                  options={Object.values(SSL_FILE_TYPES).map((value) => ({
                    value,
                    label: capitalize(value),
                  }))}
                  value={caFileType}
                  onChange={(value) =>
                    this.props.onChange(
                      this.props.credentials
                        .setIn(['ssl', 'caFileType'], value)
                        .deleteIn([
                          'ssl',
                          value === SSL_FILE_TYPES.PEM && this.isCsasComponent() ? 'ca' : '#ca',
                        ]),
                    )
                  }
                  disabled={isDisabled}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>CA Certificate</ControlLabel>
              </div>
              <div className="col-xs-8">
                <Textarea
                  minRows={4}
                  className="form-control"
                  value={this.props.credentials.getIn(['ssl', caType], '')}
                  onChange={({ target }) => {
                    this.props.onChange(
                      this.props.editingCredentials.setIn(['ssl', caType], target.value),
                    );
                  }}
                  disabled={isDisabled}
                />
                <HelpBlock>
                  Trusted certificates in {caFileType === SSL_FILE_TYPES.PEM ? 'PEM' : 'JKS'}{' '}
                  format.
                </HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-8 col-xs-offset-4">
                <Checkbox
                  disabled={isDisabled}
                  checked={this.props.credentials.getIn(['ssl', 'verifyServerCert'], true)}
                  onChange={(checked) => {
                    this.props.onChange(
                      this.props.editingCredentials.setIn(['ssl', 'verifyServerCert'], checked),
                    );
                  }}
                >
                  Verify server certificate
                </Checkbox>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-8 col-xs-offset-4">
                <Checkbox
                  disabled={isDisabled}
                  checked={this.props.credentials.getIn(['ssl', 'ignoreCertificateCn'], false)}
                  onChange={(checked) => {
                    this.props.onChange(
                      this.props.editingCredentials.setIn(['ssl', 'ignoreCertificateCn'], checked),
                    );
                  }}
                >
                  Ignore Certificate CN
                </Checkbox>
              </div>
            </FormGroup>
          </>
        )}
      </>
    );
  },

  renderQueriesWarning() {
    if (!this.props.hasQueries) {
      return null;
    }

    return (
      <HelpBlock>
        Note that by changing the credentials (e.g., database name), all your configured queries may
        become invalid.
      </HelpBlock>
    );
  },

  renderTestButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <TestCredentialsButtonGroup
        componentId={this.props.componentId}
        configId={this.props.configId}
        isEditing={this.props.isChanged}
        disabled={this.props.isSaving || !this.props.hasValidCredentials()}
        testCredentialsFn={() =>
          this.props.actionCreators.testCredentials(this.props.configId, this.props.credentials)
        }
      />
    );
  },

  isCsasComponent() {
    return [KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST, KDS_TEAM_WR_HIVE_CSAS].includes(
      this.props.componentId,
    );
  },

  supportAdditionalFields() {
    return [KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST, KEBOOLA_EX_DB_HIVE].includes(
      this.props.componentId,
    );
  },
});

export default HiveCredentials;
