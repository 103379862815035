import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Tooltip } from 'design';
import { Map } from 'immutable';

import Loader from '@/react/common/Loader';

class Variable extends React.Component {
  render() {
    const value = this.props.variable.get('value', 'Default value not set');

    return (
      <div
        tabIndex="0"
        role="button"
        onClick={() => {
          if (!this.props.isDeleting) {
            this.props.startEditingFn();
          }
        }}
        className={classNames('flex-container variable-static', {
          clickable: !this.props.isDeleting,
        })}
      >
        <div className="variable-name overflow-break-anywhere">
          <FontAwesomeIcon icon="code" fixedWidth className="icon-addon-right" />
          {this.props.variable.get('name')}
        </div>
        {!this.props.nameOnly && (
          <div className="variable-value overflow-break-anywhere">
            {value === '' ? <code>EMPTY STRING</code> : value}
          </div>
        )}
        {!this.props.readOnly && (
          <div className="variable-actions no-wrap">
            <Tooltip placement="top" tooltip="Edit Variable">
              <Button
                className="text-muted"
                bsStyle="link"
                disabled={this.props.isDeleting}
                onClick={(event) => {
                  event.stopPropagation();

                  this.props.startEditingFn();
                }}
              >
                <FontAwesomeIcon icon="pen" fixedWidth />
              </Button>
            </Tooltip>
            <Tooltip placement="top" tooltip="Delete Variable">
              <Button
                className="text-muted"
                bsStyle="link"
                disabled={this.props.isDeleting}
                onClick={(event) => {
                  event.stopPropagation();

                  this.props.deleteVariableFn();
                }}
              >
                {this.props.isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

Variable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  variable: PropTypes.instanceOf(Map).isRequired,
  deleteVariableFn: PropTypes.func.isRequired,
  startEditingFn: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  nameOnly: PropTypes.bool,
};

export default Variable;
