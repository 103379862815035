import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'design';

const SidebarLabel = ({ version }) => {
  if (!version) {
    return 'Change Backend';
  }
  return (
    <>
      Backend Version
      <Badge text={version} placement="right" />
    </>
  );
};

SidebarLabel.propTypes = {
  version: PropTypes.string,
};

export default SidebarLabel;
