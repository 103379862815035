import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';

import { nameWarning } from '@/modules/storage/constants';
import InputValidation from '@/react/common/InputValidation';

const PredefinedInput = (props: {
  entity: 'tableName' | 'bucketName';
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  isSaving?: boolean;
  label?: React.ReactNode;
  warning?: React.ReactNode;
}) => {
  return (
    <InputValidation predefined={props.entity} value={props.value}>
      {(inputState: 'error' | null) => (
        <FormGroup validationState={props.warning ? 'error' : inputState}>
          <ControlLabel>
            {props.label || props.entity === 'bucketName' ? 'Name' : 'Table Name'}
          </ControlLabel>
          <FormControl
            type="text"
            autoFocus={props.autoFocus}
            disabled={!!props.isSaving}
            value={props.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.onChange(event.target.value);
            }}
          />
          <HelpBlock variant={!!props.warning || inputState === 'error' ? 'danger' : 'default'}>
            {props.warning || nameWarning}
          </HelpBlock>
        </FormGroup>
      )}
    </InputValidation>
  );
};

export default PredefinedInput;
