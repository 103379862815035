const isInputElement = (element: Element | null) => {
  if (!element) {
    return false;
  }

  return (
    ['input', 'select', 'textarea'].includes(element.tagName?.toLowerCase()) ||
    !!element.shadowRoot?.querySelector('input, select, textarea') ||
    element.classList?.contains('cm-content')
  );
};

export default isInputElement;
