import later from '@breejs/later';

import dayjs from '@/date';
import { currentDateInTimezone } from '@/modules/orchestrations-v2/timezones';

export const getNextSchedule = (crontabRecord, crontabTimezone = 'UTC') => {
  const startDate = currentDateInTimezone(crontabTimezone);
  if (!dayjs.isDayjs(startDate)) {
    return [];
  }
  const tzOffset = dayjs().tz(crontabTimezone).utcOffset();

  return later
    .schedule(later.parse.cron(crontabRecord))
    .next(5, startDate.utc(true).toDate())
    .map((date) => dayjs.utc(date).utcOffset(tzOffset, true));
};
