import React from 'react';

const PaddingRow = ({ height }: { height: number }) => {
  if (height === 0) {
    return null;
  }

  return (
    <div className="tr no-hover">
      <div className="td" style={{ height }} />
    </div>
  );
};

export default PaddingRow;
