import { List, Map } from 'immutable';

export function conform(configuration) {
  const oldSchemas = configuration.getIn(['parameters', 'user', 'business_schemas'], false);
  const entityType = configuration.hasIn(['parameters', 'user']) ? 'user' : 'business_schema';

  if (oldSchemas === false) {
    if (!configuration.hasIn(['parameters', entityType, 'reset_password'])) {
      return configuration.setIn(['parameters', entityType, 'reset_password'], false);
    }

    return configuration;
  }

  const newSchemas = configuration.getIn(['parameters', 'user', 'schemas'], List());
  const newlyFormattedSchemas = newSchemas.push(
    ...oldSchemas.map((name) => Map({ name, permission: 'read' })),
  );

  return configuration
    .setIn(['parameters', 'user', 'reset_password'], false)
    .setIn(['parameters', 'user', 'schemas'], newlyFormattedSchemas)
    .deleteIn(['parameters', 'user', 'business_schemas']);
}
