import React, { useState } from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpBlock } from 'design';
import type { Map } from 'immutable';

import { getDefaultBucketName } from '@/modules/components/helpers';
import { nameWarning, routeNames } from '@/modules/storage/constants';
import { validateTableName } from '@/modules/storage/helpers';
import actions from '@/modules/stream/actions';
import { INITIAL_SINK } from '@/modules/stream/constants';
import type { StoreSource } from '@/modules/stream/store';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';

const AddNewStream = ({
  tables,
  sources,
}: {
  tables: Map<string, any>;
  sources: StoreSource[];
}) => {
  const [showModal, setShowModal] = useState(false);
  const [sourceName, setSourceName] = useState('');
  const [tableName, setTableName] = useState('');
  const [pending, setPending] = useState(false);
  const [existingSources] = useState(sources);

  const tablesInCurrentBucket = tables.filter(
    (table) => table.getIn(['bucket', 'id']) === createBucketId(sourceName),
  ) as Map<string, any>;
  const isSourceNameDuplicate = existingSources?.some(
    (source) => string.webalize(source.name) === string.webalize(sourceName),
  );
  const tableNameWarning =
    sourceName && !isSourceNameDuplicate
      ? validateTableName(createTableName(tableName, sourceName), tablesInCurrentBucket)
      : null;

  return (
    <>
      <Button bsStyle="success" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Create Data Stream
      </Button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
        onEnter={() => {
          setSourceName('');
          setTableName('');
        }}
      >
        <Form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setPending(true);

            const tableNameSanitized = createTableName(tableName, sourceName);

            return actions
              .createSource(sourceName, {
                name: tableNameSanitized,
                type: 'table',
                table: {
                  ...(INITIAL_SINK as DeepMutable<typeof INITIAL_SINK>).table,
                  tableId: `${createBucketId(sourceName)}.${tableNameSanitized}`,
                },
              })
              .then((sourceId) =>
                RoutesStore.getRouter().transitionTo(routeNames.STREAM_DETAIL, { sourceId }),
              )
              .finally(() => setPending(false));
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Data Stream</Modal.Title>
            <ModalIcon icon={['fas', 'webhook']} color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup validationState={isSourceNameDuplicate ? 'error' : null}>
              <ControlLabel>Data Stream Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={sourceName}
                placeholder="Stream name"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setSourceName(target.value)
                }
              />
              {isSourceNameDuplicate && (
                <HelpBlock variant="danger">
                  Source &quot;{sourceName}&quot; already exists in the project.
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup validationState={tableNameWarning ? 'error' : null}>
              <ControlLabel>Destination Table Name </ControlLabel>
              <InfoTooltip tooltip="Table where data stream will be persisted" />
              <FormControl
                type="text"
                value={createTableName(tableName, sourceName)}
                placeholder="Destination Table name"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setTableName(string.sanitizeKbcTableIdString(target.value))
                }
              />
              <HelpBlock variant={!!tableNameWarning ? 'danger' : 'default'}>
                {tableNameWarning || nameWarning}
              </HelpBlock>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              isSaving={pending}
              isDisabled={
                !sourceName.trim() || isSourceNameDuplicate || !!tableNameWarning || pending
              }
              saveLabel={pending ? 'Creating Data Stream...' : 'Create Data Stream'}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const createBucketId = (sourceName: string) =>
  `in.${getDefaultBucketName(`data-stream-${sourceName}`)}`;
const createTableName = (tableName: string, sourceName: string) =>
  tableName || string.sanitizeKbcTableIdString(sourceName);

export default AddNewStream;
