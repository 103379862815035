import type { Map } from 'immutable';
import { fromJS } from 'immutable';

const KEY = 'general';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({ [KEY]: { baseUrl: parameters.getIn(['api', 'baseUrl'], '') } });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters.setIn(['api', 'baseUrl'], editing.getIn([KEY, 'baseUrl']));
};

export { KEY, mapToState, mapToApi };
