import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from './componentIds';

const EXTERNAL_PREFIX = 'extCls';

const HEADER_BUTTONS = `${EXTERNAL_PREFIX}-header-buttons`;
const NEW_ENTITY_BUTTON = `${EXTERNAL_PREFIX}-new-entity-button`;
const SIDEBAR = `${EXTERNAL_PREFIX}-sidebar`;
const ORCHESTRATION_SETUP = `${EXTERNAL_PREFIX}-orchestration-setup`;
const RUN_COMPONENT_BUTTON = `${EXTERNAL_PREFIX}-run-component-button`;
const RUN_COMPONENT_MENU_ITEM = `${EXTERNAL_PREFIX}-run-component-menu-item`;

const getType = (component) => {
  switch (component.get('id')) {
    case KEBOOLA_ORCHESTRATOR: {
      const isFlows = window.location.pathname.includes('/flows');

      return isFlows ? 'flow' : 'orchestration';
    }

    case KEBOOLA_DATA_APPS:
      return 'data-app';

    default:
      return component.get('type');
  }
};

// HELPER FUNCTIONS FOR HEAP TRACKING
const componentClass = (component) => {
  return `${EXTERNAL_PREFIX}-component-${component.get('id')?.replace('.', '-')}`;
};

const useThisComponent = (component) => {
  return `${EXTERNAL_PREFIX}-use-this-${getType(component)} ${componentClass(component)}`;
};

const connectToMyDataComponent = (component) => {
  return `${EXTERNAL_PREFIX}-connect-to-my-data-${getType(component)} ${componentClass(component)}`;
};

const useDemoDataComponent = (component) => {
  return `${EXTERNAL_PREFIX}-use-with-demo-data-${getType(component)} ${componentClass(component)}`;
};

const automateComponent = (component) => {
  return `${EXTERNAL_PREFIX}-automate-${getType(component)} ${componentClass(component)}`;
};

const runComponent = (component) => {
  return `${EXTERNAL_PREFIX}-run-${getType(component)} ${componentClass(component)}`;
};

const routesNames = (routes) => {
  return routes
    .filter((route) => !!route.name)
    .map((route) => `${EXTERNAL_PREFIX}-${route.name}`.replace('.', '-'))
    .join(' ');
};

export {
  useDemoDataComponent,
  connectToMyDataComponent,
  automateComponent,
  runComponent,
  useThisComponent,
  routesNames,
  HEADER_BUTTONS,
  NEW_ENTITY_BUTTON,
  SIDEBAR,
  ORCHESTRATION_SETUP,
  RUN_COMPONENT_BUTTON,
  RUN_COMPONENT_MENU_ITEM,
};
