import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map } from 'immutable';

import dayjs from '@/date';
import { hasDynamicBackendSizeEnabled } from '@/modules/components/helpers';
import { ACTIVITY_TYPES } from '@/modules/home/constants';
import { groupSameActivity, injectLastSeenRow } from '@/modules/home/helpers';
import LazyList from '@/react/common/LazyList';
import SearchBar from '@/react/common/SearchBar';
import Select from '@/react/common/Select';
import TableDivideRow from '@/react/common/TableDivideRow';
import matchByWords from '@/utils/matchByWords';
import LatestActivityBranch from './LatestActivityBranch';
import LatestActivityConfiguration from './LatestActivityConfiguration';
import LatestActivityJob from './LatestActivityJob';

class LatestActivity extends React.Component {
  state = {
    query: '',
    typeFilter: '',
  };

  render() {
    if (!this.props.activity.count()) {
      return null;
    }

    return (
      <div className="box">
        <div className="box-header above-table">
          <h2 className="box-title">Latest Activity</h2>
          {this.props.activity.count() > 1 && (
            <div className="box-filters">
              {this.renderSearchInput()}
              {this.renderTypeFilter()}
            </div>
          )}
        </div>
        <div className="table table-hover">
          {this.renderTableHeader()}
          <LazyList className="tbody" items={this.getActivity()} render={this.renderActivities} />
        </div>
      </div>
    );
  }

  renderSearchInput() {
    return (
      <SearchBar
        bordered
        onChange={this.handleQuery}
        query={this.state.query}
        placeholder="SEARCH ACTIVITY"
        className="box-search"
      />
    );
  }

  renderTypeFilter() {
    return (
      <Select
        clearable={false}
        searchable={false}
        className="box-filter"
        value={this.state.typeFilter}
        onChange={this.handleTypeFilter}
        options={[
          { value: '', label: 'All data' },
          { value: ACTIVITY_TYPES.JOB, label: 'Jobs' },
          { value: ACTIVITY_TYPES.CONFIGURATION, label: 'Configurations' },
        ]}
      />
    );
  }

  renderTableHeader() {
    if (this.state.typeFilter === ACTIVITY_TYPES.JOB) {
      return this.renderHeader('Name', 'Duration', 'Created', 'Status');
    }

    if (this.state.typeFilter === ACTIVITY_TYPES.CONFIGURATION) {
      return this.renderHeader('Name', 'Version', 'Last Change');
    }

    return this.renderHeader('Name', 'Duration / Version', 'Created / Last Change', 'Status');
  }

  renderHeader(...columns) {
    return (
      <div className="thead">
        <div className="tr">
          {columns.map((column, index) => (
            <div className={classnames('th', { 'text-right': index > 0 })} key={index}>
              {column}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderActivities = (rows) => {
    return rows.map(this.renderTableRow).toArray();
  };

  renderTableRow = (row) => {
    switch (row.get('type')) {
      case ACTIVITY_TYPES.JOB:
        return (
          <LatestActivityJob
            key={row.getIn(['data', 'id'])}
            job={row.get('data')}
            hasNewQueue={this.props.hasNewQueue}
            showBackendSize={hasDynamicBackendSizeEnabled(
              row.get('component', Map()),
              this.props.hasSnowflakeDynamicBackendSize,
              this.props.hasJobsDynamicBackendSize,
            )}
          />
        );

      case ACTIVITY_TYPES.CONFIGURATION:
        return (
          <LatestActivityConfiguration
            event={row.get('data')}
            component={row.get('component')}
            groupCount={row.get('count')}
            key={row.getIn(['data', 'id'])}
            admins={this.props.admins}
            showedOnlyConfigurations={this.state.typeFilter === ACTIVITY_TYPES.CONFIGURATION}
            hasFlows={this.props.hasFlows}
          />
        );

      case ACTIVITY_TYPES.BRANCH:
        return (
          <LatestActivityBranch
            event={row.get('data')}
            key={row.getIn(['data', 'id'])}
            admins={this.props.admins}
          />
        );

      case 'last-seen':
        return (
          <TableDivideRow key="last-seen" className="color-primary">
            <FontAwesomeIcon icon="clock" /> Already seen last time
          </TableDivideRow>
        );

      default:
        return null;
    }
  };

  getActivity = () => {
    let activity = groupSameActivity(this.props.activity);

    if (this.state.typeFilter) {
      activity = activity.filter((row) => row.get('type') === this.state.typeFilter);
    }

    if (this.state.query) {
      activity = activity.filter((row) => {
        switch (row.get('type')) {
          case ACTIVITY_TYPES.JOB:
            return matchByWords(row.getIn(['data', 'params', 'component'], ''), this.state.query);

          case ACTIVITY_TYPES.CONFIGURATION:
            return matchByWords(row.getIn(['data', 'message'], ''), this.state.query);

          case ACTIVITY_TYPES.BRANCH:
            return matchByWords(
              `Development branch ${row.getIn(['data', 'params', 'devBranchName'], '')}`,
              this.state.query,
            );

          default:
            return false;
        }
      });
    }

    if (this.props.lastSeen) {
      return injectLastSeenRow(activity, this.props.lastSeen);
    }

    return activity;
  };

  handleQuery = (query) => {
    this.setState({ query });
  };

  handleTypeFilter = (selected) => {
    this.setState({ typeFilter: selected });
  };
}

LatestActivity.propTypes = {
  activity: PropTypes.instanceOf(List).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  lastSeen: PropTypes.instanceOf(dayjs),
};

export default LatestActivity;
