import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames, { type Argument } from 'classnames';

const DropdownToggleIcon = ({
  isOpen,
  className,
  withBackground = false,
}: {
  isOpen: boolean;
  className?: Argument;
  withBackground?: boolean;
}) => {
  return (
    <FontAwesomeIcon
      icon={isOpen ? 'angle-up' : 'angle-down'}
      className={classnames(
        'dropdown-toggle-icon',
        { 'with-background': withBackground },
        className,
      )}
      fixedWidth
    />
  );
};

export default DropdownToggleIcon;
