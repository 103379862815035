import React from 'react';
import { Table } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { dataPreview } from '@/modules/storage/actions';
import Loader from '@/react/common/Loader';
import Truncated from '@/react/common/Truncated';

const ColumnsPreview = (props: { tableId: string }) => {
  const [data, setData] = React.useState<Map<string, any>>(Map());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    dataPreview(props.tableId, { limit: 5 })
      .then((data: any) => {
        setData(fromJS(data).update('rows', List(), (rows: List<any>) => rows.flatten(1)));
      })
      .finally(() => setLoading(false));
  }, [props.tableId]);

  return (
    <div className="box box-separator overflow-hidden">
      <Table striped>
        <thead>
          <tr>
            <th className="w-200">Column Name</th>
            <th>Sample Value</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={2}>
                <Loader className="icon-addon-right" />
                Loading preview data....
              </td>
            </tr>
          ) : (
            data
              .get('columns', List())
              .map((column: string) => {
                const sampleData = data
                  .get('rows', List())
                  .filter((row: Map<string, any>) => row.get('columnName') === column);

                return (
                  <tr key={column}>
                    <td className="w-200">{column}</td>
                    <td>
                      <div data-heap-redact-text className="flex-container flex-end pre-wrap">
                        {sampleData.isEmpty()
                          ? 'N/A'
                          : sampleData
                              .map((row: Map<string, any>) => {
                                return String(row.get('value'))
                                  .replace(/(\r\n|\n|\r)/gm, ' ')
                                  .trim();
                              })
                              .filter(Boolean)
                              .map((text: string, rowIndex: number) => (
                                <Truncated key={rowIndex} text={text} redact />
                              ))
                              .reduce((prev: React.ReactNode, curr: React.ReactNode) => [
                                prev,
                                ', ',
                                curr,
                              ])}
                      </div>
                    </td>
                  </tr>
                );
              })
              .toArray()
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ColumnsPreview;
