import React from 'react';
import { Button, Form } from 'react-bootstrap';
import AutosizeInput from 'react-input-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';

import keyCodes from '@/constants/keyCodes';
import Loader from './Loader';

type InputProps = {
  onEditCancel: () => void;
  onEditChange: (input: string) => void;
  onEditSubmit: () => void;
  onEditStart?: () => void;
  isChanged: boolean;
  text?: string;
  isSaving?: boolean;
  isEditing?: boolean;
  forceEditing?: boolean;
  isValid?: boolean;
  editTooltip?: string;
  tooltipPlacement?: string;
  placeholder?: string;
  showEditIcon?: boolean;
  className?: string;
};

class InlineEditTextInput extends React.Component<InputProps> {
  static defaultProps: Partial<InputProps> = {
    placeholder: 'Click to edit',
    editTooltip: 'Click to edit',
    tooltipPlacement: 'top',
    isChanged: true,
    showEditIcon: true,
    isValid: true,
  };

  render() {
    if (this.props.isEditing || this.props.forceEditing) {
      return (
        <Form
          inline
          className={classnames(
            'inline-edit-input',
            { invalid: !this.props.isValid },
            this.props.className,
          )}
          onSubmit={this.handleSubmit}
          onBlur={(event: React.FocusEvent<HTMLFormElement>) => {
            if (!this.props.isChanged) {
              return this.handleCancel(event);
            }

            this.handleSubmit(event);
          }}
          onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
        >
          <AutosizeInput
            inputClassName="form-control inline-edit-input-control"
            value={this.props.text}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            inputRef={(ref) => ref?.focus()}
          />
          <div className="no-wrap">
            <Button
              type="submit"
              bsStyle="success"
              disabled={this.props.isSaving || !this.props.isValid || !this.props.isChanged}
              onClick={this.handleSubmit}
            >
              {this.props.isSaving ? <Loader /> : <FontAwesomeIcon icon="check" />}
            </Button>
            {(this.props.isChanged || !this.props.forceEditing) && (
              <Button
                type="reset"
                className="inline-edit-input-reset"
                disabled={this.props.isSaving}
                onClick={this.handleCancel}
              >
                <FontAwesomeIcon icon="xmark" />
              </Button>
            )}
          </div>
        </Form>
      );
    }

    return (
      <Tooltip
        placement="top"
        tooltip={this.props.editTooltip}
        triggerOnClick={this.handleEdit}
        triggerClassName="inline-edit-input-edit"
      >
        {(this.props.text && <span className="inline-edit-input-text">{this.props.text}</span>) || (
          <span className="text-muted">{this.props.placeholder}</span>
        )}
        {this.props.showEditIcon && (
          <FontAwesomeIcon icon="pen" fixedWidth className="icon-addon-left" />
        )}
      </Tooltip>
    );
  }

  handleSubmit = (event: React.SyntheticEvent | React.FocusEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      ('relatedTarget' in event &&
        event.relatedTarget?.classList.contains('inline-edit-input-reset')) ||
      !this.props.isChanged ||
      !this.props.isValid
    ) {
      return;
    }

    this.props.onEditSubmit();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onEditChange(event.target.value);
  };

  handleEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.props.onEditStart?.();
  };

  handleCancel = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.props.onEditCancel();
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (this.props.isChanged && event.key === keyCodes.ESCAPE) {
      event.stopPropagation();

      this.props.onEditCancel();
    }
  };
}

export default InlineEditTextInput;
