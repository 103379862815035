import { fromJS, List } from 'immutable';

const conform = (configData) => {
  return fromJS({
    parameters: {
      query: {
        tableId: '',
        datasetId: '',
        columns: List(),
        incrementalFetchingColumn: '',
        limit: 0,
        query: '',
        useLegacySql: true,
        advancedMode: !configData.hasIn(['parameters', 'query', 'tableId']),
      },
    },
  }).mergeDeep(configData);
};

export default conform;
