import React from 'react';

import Loader from '@/react/common/Loader';

const LoadingPlaceholder = () => {
  return (
    <div className="tw-absolute tw-left-2/4 tw-top-2/4 -tw-translate-x-2/4 -tw-translate-y-2/4">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
        <Loader className="tw-mb-1 tw-text-[24px] tw-text-neutral-400" />
        <h3 className="tw-m-0 tw-text-base tw-font-medium">Calculating Lineage Graph</h3>
        <span className="tw-text-sm tw-text-neutral-400">
          For bigger projects it can take few minutes
        </span>
      </div>
    </div>
  );
};

export default LoadingPlaceholder;
