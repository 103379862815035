import { TRANSFORMATION } from '@/constants/componentIds';

const getQuery = (componentId, configId, rowId) => {
  let query = `params.component:${componentId} AND params.config:${configId}`;

  if (rowId) {
    query += ` AND (params.row:${rowId} OR (NOT _exists_: params.row))`;
  }

  return query;
};

const getLegacyComponentQuery = (componentId, configId, rowId) => {
  let query = `component:${componentId} AND params.config:${configId}`;

  if (rowId && componentId === TRANSFORMATION) {
    query += ` AND (params.transformations:${rowId} OR (NOT _exists_: params.transformations))`;
  }

  return query;
};

const getQueryWithFallback = (componentId, configurationId) => {
  return `(component:${componentId} OR params.component:${componentId}) AND params.config:${configurationId}`;
};

export { getQuery, getQueryWithFallback, getLegacyComponentQuery };
