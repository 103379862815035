import React from 'react';
import type { Map } from 'immutable';

import { JOB_NAME } from '@/modules/ex-generic/constants';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

const EndpointName = (props: { job: Map<string, any>; onSave: (name: string) => Promise<any> }) => {
  const originalName = props.job.get(JOB_NAME, 'Endpoint');

  const [name, setName] = React.useState(originalName);
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  return (
    <h2 className="tw-m-0 tw-break-all tw-text-2xl tw-font-normal tw-leading-9">
      <InlineEditTextInput
        placeholder="My Endpoint"
        isValid={!!name.trim()}
        isChanged={name !== originalName}
        text={name}
        isSaving={saving}
        isEditing={editing}
        onEditStart={() => setEditing(true)}
        onEditCancel={() => {
          setEditing(false);
          setName(originalName);
        }}
        onEditChange={(value) => setName(value)}
        onEditSubmit={() => {
          setSaving(true);
          return props
            .onSave(name)
            .then(() => setEditing(false))
            .finally(() => setSaving(false));
        }}
      />
    </h2>
  );
};

export default EndpointName;
