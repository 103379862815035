import { fromJS, type Map } from 'immutable';

const KEY = 'certificates';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({
    [KEY]: {
      caCertificate: parameters.getIn(['api', 'caCertificate'], ''),
      clientCertificate: parameters.getIn(['api', '#clientCertificate'], ''),
    },
  });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters.withMutations((parameters) => {
    editing.getIn([KEY, 'caCertificate'])
      ? parameters.setIn(['api', 'caCertificate'], editing.getIn([KEY, 'caCertificate']))
      : parameters.deleteIn(['api', 'caCertificate']);

    editing.getIn([KEY, 'clientCertificate'])
      ? parameters.setIn(['api', '#clientCertificate'], editing.getIn([KEY, 'clientCertificate']))
      : parameters.deleteIn(['api', '#clientCertificate']);
  });
};

export { KEY, mapToState, mapToApi };
