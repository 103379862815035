import React from 'react';
import { Link } from 'design';

import { routeNames as dataTakeoutRouteNames } from '@/modules/data-takeout/constants';
import RouterLink from '@/react/common/RouterLink';

class DataTakeout extends React.Component {
  render() {
    return (
      <div className="well pp-6">
        <h3 className="tw-mb-6 tw-mt-0">Data Takeout</h3>
        <div className="value-list m-0">
          <p className="tw-mb-6">
            Export this project to <Link href="https://aws.amazon.com/s3/">Amazon S3</Link> or{' '}
            <Link href="https://azure.microsoft.com/services/storage/blobs/">
              Azure Blob Storage
            </Link>
            .
          </p>
          <RouterLink to={dataTakeoutRouteNames.DATA_TAKEOUT} className="btn btn-sm btn-primary">
            Data Takeout
          </RouterLink>
        </div>
      </div>
    );
  }
}

export default DataTakeout;
