const routeNames = {
  ROOT: 'data-apps',
  DATA_APP_DETAIL: 'apps-details',
  DATA_APP_VERSIONS: 'data-apps-versions',
};

const defaultProxyAuthorization = {
  app_proxy: {
    auth_providers: [],
    auth_rules: [{ type: 'pathPrefix', value: '/', auth_required: false }],
  },
};

const PREINSTALLED_PACKAGES = [
  'altair',
  'attrs',
  'backports.zoneinfo',
  'blinker',
  'cachetools',
  'certifi',
  'charset-normalizer',
  'click',
  'decorator',
  'gitdb',
  'GitPython',
  'idna',
  'importlib-metadata',
  'importlib-resources',
  'Jinja2',
  'jsonschema',
  'jsonschema-specifications',
  'markdown-it-py',
  'MarkupSafe',
  'mdurl',
  'numpy',
  'packaging',
  'pandas',
  'Pillow',
  'pip',
  'pkgutil_resolve_name',
  'protobuf',
  'pyarrow',
  'pydeck',
  'Pygments',
  'Pympler',
  'python-dateutil',
  'pytz',
  'pytz-deprecation-shim',
  'referencing',
  'requests',
  'rich',
  'rpds-py',
  'setuptools',
  'six',
  'smmap',
  'streamlit',
  'tenacity',
  'toml',
  'toolz',
  'tornado',
  'typing_extensions',
  'tzdata',
  'tzlocal',
  'urllib3',
  'validators',
  'watchdog',
  'wheel',
  'zipp',
];

const THEME_DEFAULT_FONT = 'sans serif';
const THEME_KEBOOLA = {
  themeName: 'keboola',
  font: THEME_DEFAULT_FONT,
  textColor: '#222529',
  backgroundColor: '#FFFFFF',
  secondaryBackgroundColor: '#E6F2FF',
  primaryColor: '#1F8FFF',
} as const;

const themes = {
  keboola: THEME_KEBOOLA,
  'light-red': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#FFE6E6',
    primaryColor: '#FF5D5D',
    themeName: 'light-red',
  },
  'light-purple': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#F2E6FF',
    primaryColor: '#9A6DD7',
    themeName: 'light-purple',
  },
  'light-blue': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#E6E6FF',
    primaryColor: '#0000B2',
    themeName: 'light-blue',
  },
  'dark-green': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#3D4F41',
    primaryColor: '#4CAF50',
    themeName: 'dark-green',
  },
  'dark-amber': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#4A3A24',
    primaryColor: '#FFC107',
    themeName: 'dark-amber',
  },
  'dark-orange': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#4A3324',
    primaryColor: '#FFA500',
    themeName: 'dark-orange',
  },
} as const;

export {
  routeNames,
  defaultProxyAuthorization,
  PREINSTALLED_PACKAGES,
  themes,
  THEME_DEFAULT_FONT,
  THEME_KEBOOLA,
};
