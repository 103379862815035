import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import VersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';

export default function (componentId, configId, rowId, versionId) {
  return function () {
    const reloadCallback = () => [
      InstalledComponentsActionCreators.loadComponentConfigData(componentId, configId),
    ];

    return VersionsActionCreators.rollbackVersion(
      componentId,
      configId,
      rowId,
      versionId,
      reloadCallback,
    );
  };
}
