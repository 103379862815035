import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { trim } from 'underscore.string';

const Credentials = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      baseUrl: PropTypes.string.isRequired,
      maxRedirects: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Base URL</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.value.baseUrl}
              onChange={(e) => {
                this.props.onChange({ baseUrl: trim(e.target.value) });
              }}
              placeholder="https://example.com"
              disabled={this.props.disabled}
            />
            <HelpBlock>
              Base URL is common for all files downloaded from a certain site/domain.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Maximum Redirects</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="number"
              min="0"
              value={this.props.value.maxRedirects}
              onChange={(e) => {
                this.props.onChange({ maxRedirects: e.target.value });
              }}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              The maximum number of redirects to follow when downloading files. Leave empty to use
              default value (5).
            </HelpBlock>
          </div>
        </FormGroup>
      </div>
    );
  },
});

export default Credentials;
