import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { SIDEBAR } from '@/constants/external';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import StorageTableColumnsEditor from '@/modules/configurations/react/components/StorageTableColumnsEditor';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import columnsEditorAdapter from '@/modules/gooddata-writer-v3/adapters/columnsEditorAdapter';
import loadTypeAdater from '@/modules/gooddata-writer-v3/adapters/loadTypeAdapter';
import tableLoadSettingsAdapter from '@/modules/gooddata-writer-v3/adapters/tablesLoadSettingsAdapter';
import titleAdapter from '@/modules/gooddata-writer-v3/adapters/titleAdapter';
import makeConfigProvisioning from '@/modules/gooddata-writer-v3/configProvisioning';
import { routeNames } from '@/modules/gooddata-writer-v3/constants';
import hasAnyRowWithChangedSinceSet from '@/modules/gooddata-writer-v3/helpers/hasAnyRowWithChangedSinceSet';
import LoadTypeSection from '@/modules/gooddata-writer-v3/react/components/LoadTypeSection';
import LoadTypeSectionTitle from '@/modules/gooddata-writer-v3/react/components/LoadTypeSectionTitle';
import RunLoadButton from '@/modules/gooddata-writer-v3/react/components/RunLoadButton';
import TitleSection from '@/modules/gooddata-writer-v3/react/components/TitleSection';
import makeTablesProvisioning from '@/modules/gooddata-writer-v3/tablesProvisioning';
// helpers
// components
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
// stores
import RoutesStore from '@/stores/RoutesStore';

const LoadTypeCollapsibleComponent = CollapsibleSection({
  title: LoadTypeSectionTitle,
  contentComponent: LoadTypeSection,
});

const Table = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, TablesStore)],

  getStateFromStores() {
    const tableId = RoutesStore.getCurrentRouteParam('table');
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const tablesProvisioning = makeTablesProvisioning(configurationId);
    const { tables, toggleTableExport, deleteTable, getSingleRunParams } = tablesProvisioning;
    const table = tables.get(tableId);
    const configProvisioning = makeConfigProvisioning(configurationId);
    const { isSaving, isPendingFn } = configProvisioning;
    const storageTable = TablesStore.getTable(tableId);
    const isPendingToggleExport = isPendingFn([tableId, 'activate']);
    const loadOnly = tableLoadSettingsAdapter(configProvisioning).value.loadOnly;

    // section props adapters
    const titleSectionProps = titleAdapter(configProvisioning, tablesProvisioning, tableId);
    const columnsEditorSectionProps = columnsEditorAdapter(
      configProvisioning,
      tablesProvisioning,
      storageTable,
      tableId,
    );

    const loadTypeSectionProps = loadTypeAdater(configProvisioning, tablesProvisioning, tableId);

    return {
      titleSectionProps,
      columnsEditorSectionProps,
      loadTypeSectionProps,
      deleteTable,
      toggleTableExport,
      isSaving: isSaving && !isPendingToggleExport,
      isPendingFn,
      tableId,
      configurationId,
      table,
      getSingleRunParams,
      configProvisioning,
      loadOnly,
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
    };
  },

  render() {
    return (
      <div className="row">
        <div className="col-sm-9">{this.renderSections()}</div>
        {!this.state.readOnly && (
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>{this.renderActionsSideBar()}</div>
          </div>
        )}
      </div>
    );
  },

  renderSections() {
    return (
      <>
        <TitleSection
          {...this.state.titleSectionProps}
          disabled={this.state.readOnly || this.state.isSaving}
        />
        <LoadTypeCollapsibleComponent
          {...this.state.loadTypeSectionProps}
          disabled={this.state.readOnly || this.state.isSaving}
        />
        <StorageTableColumnsEditor
          {...this.state.columnsEditorSectionProps}
          disabled={this.state.readOnly || this.state.isSaving}
          isDevModeActive={this.state.isDevModeActive}
        />
      </>
    );
  },

  renderActionsSideBar() {
    const { table, tableId, isPendingFn, toggleTableExport, isSaving } = this.state;
    const isTableDisabled = table.get('disabled');
    return (
      <ul className="nav nav-stacked">
        <li>
          <RunLoadButton
            mode="sidebar"
            loadOnly={this.state.loadOnly}
            tableId={tableId}
            isTableDisabled={isTableDisabled}
            getRunParams={this.state.getSingleRunParams}
            showIncrementalResetWarning={hasAnyRowWithChangedSinceSet(
              this.state.configProvisioning.configData,
            )}
          />
        </li>
        <li>
          <ActivateDeactivateButton
            key="activate"
            isActive={!isTableDisabled}
            isPending={isPendingFn([tableId, 'activate'])}
            onChange={(val) => toggleTableExport(tableId, val)}
          />
        </li>
        <li>
          <hr />
          <a className="text-muted" disabled={isSaving} onClick={this.deleteTableAndRedirect}>
            {isPendingFn([tableId, 'delete']) ? (
              <Loader />
            ) : (
              <FontAwesomeIcon icon="trash" fixedWidth />
            )}
            Delete
          </a>
        </li>
      </ul>
    );
  },

  deleteTableAndRedirect() {
    RoutesStore.getRouter().transitionTo(routeNames.ROOT, {
      config: this.state.configurationId,
    });
    this.state.deleteTable(this.state.tableId);
  },
});

export default Table;
