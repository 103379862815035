import { useEffect } from 'react';

const useMatchMedia = (mediaQueryString: string, eventHandler: (query: MediaQueryList) => void) => {
  useEffect(() => {
    const mediaQuery = window.matchMedia(mediaQueryString);
    const mediaQueryHandler = () => eventHandler(mediaQuery);
    mediaQuery.addEventListener('change', mediaQueryHandler);
    return () => {
      mediaQuery.removeEventListener('change', mediaQueryHandler);
    };
  }, [mediaQueryString, eventHandler]);
};

export default useMatchMedia;
