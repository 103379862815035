import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import InputMappingModal from '@/modules/legacy-transformation/react/modals/InputMapping';

const AddInputMapping = createReactClass({
  propTypes: {
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    transformation: PropTypes.object.isRequired,
    bucket: PropTypes.object.isRequired,
    mapping: PropTypes.object.isRequired,
    otherDestinations: PropTypes.object.isRequired,
  },

  render() {
    return (
      <InputMappingModal
        mode="create"
        mapping={this.props.mapping}
        tables={this.props.tables}
        buckets={this.props.buckets}
        backend={this.props.transformation.get('backend')}
        type={this.props.transformation.get('type')}
        onChange={this.handleChange}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        otherDestinations={this.props.otherDestinations}
      />
    );
  },

  handleChange(newMapping) {
    ActionCreators.updateTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'new-input-mapping',
      newMapping,
    );
  },

  handleCancel() {
    ActionCreators.cancelTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'new-input-mapping',
    );
  },

  handleSave() {
    return ActionCreators.saveTransformationMapping(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'input',
      'new-input-mapping',
    );
  },
});

export default AddInputMapping;
