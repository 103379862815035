import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import { capitalize } from 'underscore.string';

import {
  EXCLUDE_FROM_NEW_LIST,
  GENERIC_CODE_BLOCKS_UI,
  GENERIC_DOCKER_UI,
  GENERIC_DOCKER_UI_ROWS,
  GENERIC_DOCKER_UI_SIMPLE_TABLE_INPUT,
  GENERIC_PACKAGES_UI,
  GENERIC_TEMPLATES_UI,
  GENERIC_UI,
} from '@/constants/componentFlags';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_DATABRICKS_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY,
  KEBOOLA_EX_GOOGLE_BIGQUERY,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_JULIA_TRANSFORMATION,
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_R_TRANSFORMATION_V_2,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION,
  ORCHESTRATOR,
  TRANSFORMATION,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  FEATURE_ARTIFACTS,
  FEATURE_DISABLE_JULIA_AND_R,
  FEATURE_ORACLE_TRANSFORMATIONS,
  FEATURE_SANDBOXES_PYTHON_MLFLOW,
  FEATURE_SANDBOXES_PYTHON_SPARK,
  FEATURE_SNOWPARK_PYTHON,
} from '@/constants/features';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import { routeNames as dataAppsRoutes } from '@/modules/data-apps/constants';
import { EX_DB_GENERIC_COMPONENTS } from '@/modules/ex-db-generic/constants';
import { routeNames as flowsRoutes } from '@/modules/flows/constants';
import { routeNames as fakeLegacyTransformationRoutes } from '@/modules/legacy-transformation/Constants';
import { routeNames as legacyOrchestrationRoutes } from '@/modules/orchestrations/Constants';
import { routeNames as orchestrationRoutes } from '@/modules/orchestrations-v2/constants';
import { getBranchTableId } from '@/modules/storage/helpers';
import { routeNames as legacyTransformationRoutes } from '@/modules/transformations/Constants';
import {
  DBT_REMOTE_TRANSFORMATIONS,
  routeNames as transformationRoutes,
  SNOWFLAKE_BACKEND_TRANSFORMATIONS,
} from '@/modules/transformations-v2/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';
import { isValidJsonConfig } from '@/utils/validation';
import ComponentsStore from './stores/ComponentsStore';
import InstalledComponentsStore from './stores/InstalledComponentsStore';
import ComponentsActionCreators from './ComponentsActionCreators';
import {
  DATA_TYPE_SUPPORT,
  features as componentFeatures,
  ioType,
  stagingStorageType,
  transformationBackendSizes,
} from './Constants';
import InstalledComponentsActionCreators from './InstalledComponentsActionCreators';
import { MetadataKeys } from './MetadataConstants';

const ensureComponentWithDetails = (componentId) => {
  return Promise.resolve().then(() => {
    if (ComponentsStore.hasComponentDetails(componentId)) {
      return ComponentsStore.getComponent(componentId);
    }

    return ComponentsActionCreators.loadComponentForce(componentId).then(fromJS);
  });
};

const ensureConfigurationsDetails = (componentId) => {
  const missingDetails = InstalledComponentsStore.getComponentConfigurations(componentId).some(
    (config) => !config.has('rows') || !config.has('configuration'),
  );

  if (!missingDetails) {
    return Promise.resolve();
  }

  return InstalledComponentsActionCreators.loadComponentConfigsDataForce(componentId);
};

const normalizeSnowflakeTransformationColumnTypes = (data) => {
  return data.updateIn(['configuration', 'storage', 'input', 'tables'], List(), (tables) => {
    return tables.map((table) => {
      return table.update('column_types', List(), (columnTypes) => columnTypes.toList());
    });
  });
};

const convertComponentsToByKeyStructure = (components) => {
  return fromJSOrdered(components)
    .toMap()
    .map((component) => {
      let configurations = component
        .get('configurations')
        .toMap()
        .mapKeys((key, config) => config.get('id'));

      if ([KEBOOLA_SNOWFLAKE_TRANSFORMATION, KEBOOLA_SANDBOXES].includes(component.get('id'))) {
        configurations = configurations.map(normalizeSnowflakeTransformationColumnTypes);
      }

      return component.set('configurations', configurations);
    })
    .mapKeys((key, component) => component.get('id'));
};

const getDefaultBucketName = (name = '') => {
  const webalizeName = string.webalize(name);
  return ApplicationStore.hasDisableLegacyBucketPrefix() || webalizeName.startsWith('c-')
    ? webalizeName
    : `c-${webalizeName}`;
};

const getDefaultTableName = (name = '') => {
  return string.webalize(name);
};

const getDestinationTypeFromStagingStorage = (type, componentId = null) => {
  if (componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
    return ioType.TABLE;
  }

  if (
    [
      stagingStorageType.WORKSPACE_SNOWFLAKE,
      stagingStorageType.WORKSPACE_REDSHIFT,
      stagingStorageType.WORKSPACE_SYNAPSE,
      stagingStorageType.WORKSPACE_EXASOL,
      stagingStorageType.WORKSPACE_TERADATA,
      stagingStorageType.WORKSPACE_BIGQUERY,
    ].includes(type)
  ) {
    return ioType.TABLE;
  }

  return ioType.FILE;
};

const getSourceTypeFromStagingStorage = (type) => {
  if (
    [
      stagingStorageType.WORKSPACE_SNOWFLAKE,
      stagingStorageType.WORKSPACE_REDSHIFT,
      stagingStorageType.WORKSPACE_SYNAPSE,
      stagingStorageType.WORKSPACE_EXASOL,
      stagingStorageType.WORKSPACE_TERADATA,
      stagingStorageType.WORKSPACE_BIGQUERY,
    ].includes(type)
  ) {
    return ioType.TABLE;
  }

  return ioType.FILE;
};

const getAllowedTransformations = (
  allTransformations,
  sapiToken,
  projectFeatures,
  stackFeatures,
) => {
  return allTransformations
    .filter((component) => {
      if (
        component.get('id') === KEBOOLA_SNOWFLAKE_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasSnowflake'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_REDSHIFT_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasRedshift'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_SYNAPSE_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasSynapse'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_EXASOL_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasExasol'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_TERADATA_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasTeradata'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION &&
        !sapiToken.getIn(['owner', 'hasBigquery'])
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_ORACLE_TRANSFORMATION &&
        !projectFeatures.includes(FEATURE_ORACLE_TRANSFORMATIONS)
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_DBT_TRANSFORMATION &&
        (!projectFeatures.includes(FEATURE_ARTIFACTS) ||
          !ApplicationStore.hasReadOnlyStorage() ||
          ApplicationStore.hasProtectedDefaultBranch() ||
          !sapiToken.getIn(['owner', 'hasSnowflake']))
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY &&
        (!projectFeatures.includes(FEATURE_ARTIFACTS) ||
          !ApplicationStore.hasReadOnlyStorage() ||
          ApplicationStore.hasProtectedDefaultBranch() ||
          !sapiToken.getIn(['owner', 'hasBigquery']))
      ) {
        return false;
      }

      if (
        DBT_REMOTE_TRANSFORMATIONS.includes(component.get('id')) &&
        (!projectFeatures.includes(FEATURE_ARTIFACTS) ||
          ApplicationStore.hasProtectedDefaultBranch())
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_NO_CODE_DBT_TRANSFORMATION &&
        (!ApplicationStore.hasReadOnlyStorage() ||
          ApplicationStore.hasProtectedDefaultBranch() ||
          !sapiToken.getIn(['owner', 'hasSnowflake']))
      ) {
        return false;
      }

      if (
        component.get('id') === KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION &&
        !projectFeatures.includes(FEATURE_SNOWPARK_PYTHON)
      ) {
        return false;
      }

      if (
        [KEBOOLA_R_TRANSFORMATION_V_2, KEBOOLA_JULIA_TRANSFORMATION].includes(
          component.get('id'),
        ) &&
        stackFeatures.includes(FEATURE_DISABLE_JULIA_AND_R)
      ) {
        return false;
      }

      // we allow Databricks and MLflow even if they are not published
      if (
        component.get('id') === KEBOOLA_DATABRICKS_TRANSFORMATION &&
        (stackFeatures.includes(FEATURE_SANDBOXES_PYTHON_SPARK) ||
          projectFeatures.includes(FEATURE_SANDBOXES_PYTHON_SPARK))
      ) {
        return true;
      }

      if (
        component.get('id') === KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION &&
        projectFeatures.includes(FEATURE_SANDBOXES_PYTHON_MLFLOW)
      ) {
        return true;
      }

      return !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST);
    })
    .withMutations((transformations) => {
      // if we have only a remote dbt transformations, we need to add default one to properly render the list UI
      if (
        !transformations.has(KEBOOLA_DBT_TRANSFORMATION) &&
        !transformations.has(KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY) &&
        allTransformations.has(KEBOOLA_DBT_TRANSFORMATION) &&
        transformations.some((transformation) => {
          return DBT_REMOTE_TRANSFORMATIONS.includes(transformation.get('id'));
        })
      ) {
        transformations.set(
          KEBOOLA_DBT_TRANSFORMATION,
          allTransformations
            .get(KEBOOLA_DBT_TRANSFORMATION)
            .update('flags', (flags) => flags.push(EXCLUDE_FROM_NEW_LIST)),
        );
      }

      return transformations;
    });
};

const isSQLBased = (transformationComponent) =>
  !!transformationComponent.getIn(['data', 'staging_storage', 'input'], '').includes('workspace');

const isContainerBased = (transformationComponent) => !isSQLBased(transformationComponent);

const shouldDisableExternalBuckets = (componentId) =>
  isSQLBased(ComponentsStore.getComponent(componentId));

const supportsDynamicBackendSize = (transformationComponent) => {
  if (
    transformationComponent.get('type') !== componentTypes.TRANSFORMATION ||
    DBT_REMOTE_TRANSFORMATIONS.includes(transformationComponent.get('id'))
  ) {
    return false;
  }

  return (
    isContainerBased(transformationComponent) ||
    SNOWFLAKE_BACKEND_TRANSFORMATIONS.includes(transformationComponent.get('id'))
  );
};

const hasDynamicBackendSizeEnabled = (
  transformationComponent,
  hasSnowflakeDynamicBackendSize,
  hasJobsDynamicBackendSize,
) => {
  if (!supportsDynamicBackendSize(transformationComponent)) {
    return false;
  }

  if (SNOWFLAKE_BACKEND_TRANSFORMATIONS.includes(transformationComponent.get('id'))) {
    return hasSnowflakeDynamicBackendSize;
  }

  if (isContainerBased(transformationComponent)) {
    return hasJobsDynamicBackendSize;
  }

  return false;
};

const getTransformationBackendSizes = (componentId) => {
  return SNOWFLAKE_BACKEND_TRANSFORMATIONS.includes(componentId)
    ? transformationBackendSizes.snowflake
    : transformationBackendSizes.common;
};

const getLargerBackendSize = (componentId, currentBackendSize) => {
  const availableSizes = getTransformationBackendSizes(componentId);

  return availableSizes[availableSizes.findIndex((size) => size === currentBackendSize) + 1];
};

const getAllowedPatternComponents = (allPaternsComponents, isDevModeActive) => {
  return allPaternsComponents.filter((component) => {
    const flags = component.get('flags', List());
    const features = component.get('features', List());

    return (
      !flags.includes(EXCLUDE_FROM_NEW_LIST) &&
      (!isDevModeActive ||
        !features.includes(componentFeatures.DEV_BRANCH_JOB_BLOCKED) ||
        !features.includes(componentFeatures.DEV_BRANCH_CONFIGURATION_UNSAFE))
    );
  });
};

const hasDevModeProblematicMapping = (branchId, mapping, storageTables) => {
  if (!branchId) {
    return false;
  }

  return mapping
    .getIn(['output', 'tables'], List())
    .filter((table) => table.get('incremental'))
    .some((outputTable) => {
      return mapping.getIn(['input', 'tables'], List()).some((inputTable) => {
        const devBranchTable = getBranchTableId(inputTable.get('source'), branchId);

        return (
          outputTable.get('destination') === inputTable.get('source') &&
          !storageTables.has(devBranchTable)
        );
      });
    });
};

const getNewComponentTypeLabel = (type, options) => {
  switch (type) {
    case componentTypes.EXTRACTOR:
      return 'Data Source';

    case componentTypes.WRITER:
      return 'Data Destination';

    case 'other':
      return options?.showOtherType ? 'Other' : '';

    default:
      return capitalize(type);
  }
};

const shouldWarnAboutNewVersion = (previousVersions, newVersions, currentAdmin) => {
  const email = currentAdmin.get('email');
  const previous = previousVersions.toMap().mapKeys((key, version) => version.get('version'));

  return (
    !previous.isEmpty() &&
    newVersions.some((version) => {
      return (
        !previous.has(version.get('version')) &&
        (version.getIn(['creatorToken', 'description']) !== email ||
          version.get('changeDescription') === 'Migrate configuration to configRow.')
      );
    })
  );
};

const renameOrchestratorToFlow = (components, hasFlows) => {
  if (hasFlows && components.has(KEBOOLA_ORCHESTRATOR)) {
    return components.setIn([KEBOOLA_ORCHESTRATOR, 'name'], 'Flow');
  }

  return components;
};

const resolveRouterLinkParams = (componentId, configId, rowId, hasFlows) => {
  const component = ComponentsStore.getComponent(componentId);
  const componentType = component ? component.get('type') : 'extractor';

  if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
    if (!configId) return { to: fakeLegacyTransformationRoutes.ROOT };
    if (!rowId) return { to: fakeLegacyTransformationRoutes.BUCKET, params: { config: configId } };
    return { to: fakeLegacyTransformationRoutes.DETAIL, params: { config: configId, row: rowId } };
  }

  if (!configId) {
    if (componentType === componentTypes.TRANSFORMATION) {
      return { to: transformationRoutes.ROOT };
    }

    if (componentId === KEBOOLA_ORCHESTRATOR) {
      return { to: hasFlows ? flowsRoutes.ROOT : orchestrationRoutes.ROOT };
    }

    return { to: componentsRoutes.COMPONENT, params: { component: componentId } };
  }

  if (rowId && [KEBOOLA_EX_GOOGLE_BIGQUERY, ...EX_DB_GENERIC_COMPONENTS].includes(componentId)) {
    return {
      to: 'ex-db-generic-' + componentId + '-query',
      params: { component: componentId, config: configId, query: rowId },
    };
  }

  if (componentId === KEBOOLA_DATA_APPS) {
    return { to: dataAppsRoutes.DATA_APP_DETAIL, params: { config: configId } };
  }

  if (componentId === KEBOOLA_SANDBOXES) {
    return { to: 'workspace', params: { config: configId } };
  }

  if (componentId === TRANSFORMATION) {
    return rowId
      ? { to: legacyTransformationRoutes.DETAIL, params: { config: configId, row: rowId } }
      : { to: legacyTransformationRoutes.BUCKET, params: { config: configId } };
  }

  if (componentId === KEBOOLA_SHARED_CODE) {
    return rowId
      ? { to: transformationRoutes.SHARED_CODE, params: { config: configId, row: rowId } }
      : { to: transformationRoutes.SHARED_CODES };
  }

  if (componentId === KEBOOLA_ORCHESTRATOR) {
    return {
      to: hasFlows ? flowsRoutes.DETAIL : orchestrationRoutes.DETAIL,
      params: { config: configId },
    };
  }

  if (componentId === ORCHESTRATOR) {
    return { to: legacyOrchestrationRoutes.ORCHESTRATION, params: { orchestrationId: configId } };
  }

  if (RoutesStore.hasRoute(componentId)) {
    return rowId
      ? {
          to: `${componentId}-row`,
          params: { component: componentId, config: configId, row: rowId },
        }
      : { to: componentId, params: { component: componentId, config: configId } };
  }

  if (componentType === 'transformation') {
    return {
      to: transformationRoutes.GENERIC_TRANSFORMATION_CONFIG,
      params: { config: configId, component: componentId },
    };
  }

  if (componentType !== 'other') {
    return rowId
      ? {
          to: componentsRoutes.GENERIC_CONFIG_ROW,
          params: { component: componentId, config: configId, row: rowId },
        }
      : {
          to: componentsRoutes.GENERIC_CONFIG,
          params: { component: componentId, config: configId },
        };
  }

  return null;
};

const getPredefinedCluster = (availableClusters, clusterId) =>
  availableClusters?.find((cluster) => cluster?.get('cluster_id') === clusterId) || Map();

const getDatabricksParametersFromCluster = (cluster) => {
  if (cluster.isEmpty()) {
    return Map();
  }

  return fromJS({
    nodeType: cluster.get('node_type_id'),
    numberOfNodes: cluster.get('num_workers'),
    sparkVersion: cluster.get('spark_version'),
  })
    .filter(Boolean)
    .toMap();
};

const findCreatedFromMetadata = (componentId, configId, allMetadata) => {
  const metadataValue = allMetadata
    .getIn([componentId, configId], List())
    .find((row) => row.get('key') === MetadataKeys.CONFIGURATION_CREATED_FROM, null, Map())
    .get('value');

  if (!isValidJsonConfig(metadataValue)) {
    return null;
  }

  return JSON.parse(metadataValue);
};

const findTemplateInstanceIdFromMetadata = (componentId, configId, allMetadata) => {
  return allMetadata
    ?.getIn([componentId, configId], List())
    .find((row) => row.get('key') === MetadataKeys.TEMPLATES_INSTANCE_ID, null, Map())
    .get('value');
};

const prepareCreatedFromMetadata = (componentId, configId, allConfigurations, allMetadata) => {
  const data = findCreatedFromMetadata(componentId, configId, allMetadata);

  if (!data) {
    return Map();
  }

  const configName = allConfigurations.getIn([
    data.componentId,
    'configurations',
    data.configurationId,
    'name',
  ]);

  return Map({
    componentId: data.componentId,
    configId: data.configurationId,
    name: configName || data.configurationId,
    isDeleted: !configName,
  });
};

const prepareFoldersFromMetadata = (metadata) => {
  return Map().withMutations((folders) => {
    metadata.forEach((configs, componentId) => {
      configs.forEach((metadata, configId) => {
        const folder = getFolderFromMetadata(metadata);

        if (folder) {
          folders.setIn([componentId, configId], folder);
        }
      });
    });
  });
};

const getFolderFromMetadata = (metadata = List(), defaultValue = '') => {
  return metadata
    .find((row) => row.get('key') === MetadataKeys.CONFIGURATION_FOLDER, null, Map())
    .get('value', defaultValue);
};

const saveFolderToMetadata = (componentId, configId, folder, options) => {
  return InstalledComponentsActionCreators.setConfigurationMetadata(
    componentId,
    configId,
    [{ key: MetadataKeys.CONFIGURATION_FOLDER, value: folder.trim() }],
    options,
  );
};

const getComponentTypeColorClassName = (componentType) => {
  switch (componentType) {
    case componentTypes.EXTRACTOR:
    case getNewComponentTypeLabel(componentTypes.EXTRACTOR):
      return 'color-source';

    case componentTypes.WRITER:
    case getNewComponentTypeLabel(componentTypes.WRITER):
      return 'color-destination';

    case componentTypes.APPLICATION:
    case getNewComponentTypeLabel(componentTypes.APPLICATION):
      return 'color-application';

    case componentTypes.TRANSFORMATION:
    case getNewComponentTypeLabel(componentTypes.TRANSFORMATION):
      return 'color-transformation';

    default:
      return '';
  }
};

const isComponentDeprecated = (component) => {
  return component.get('flags', List()).includes('deprecated');
};

const hasGenericUI = (component) => {
  return component.get('flags', List()).includes(GENERIC_UI);
};

const hasGenericDockerUI = (component) => {
  return component.get('flags', List()).includes(GENERIC_DOCKER_UI);
};

const hasGenericCodeBlocksUI = (component) => {
  return component.get('flags', List()).includes(GENERIC_CODE_BLOCKS_UI);
};

const hasGenericPackagesUI = (component) => {
  return component.get('flags', List()).includes(GENERIC_PACKAGES_UI);
};

const hasGenericTemplatesUI = (component) => {
  return component.get('flags', List()).includes(GENERIC_TEMPLATES_UI);
};

const hasVariables = () => {
  return !ApplicationStore.hasProtectedDefaultBranch();
};

const hasWriterSimpleTableInput = (component) => {
  return (
    component.get('flags').includes(GENERIC_DOCKER_UI_ROWS) &&
    component.get('flags').includes(GENERIC_DOCKER_UI_SIMPLE_TABLE_INPUT)
  );
};

const hasDisabledRollback = (componentId) => {
  return [KEBOOLA_SANDBOXES, ORCHESTRATOR].includes(componentId);
};

const hasDisabledCopy = (componentId) => {
  return [KEBOOLA_SANDBOXES].includes(componentId);
};

const hasSingleSyncAction = (schema) => {
  return (
    !!schema &&
    schema.get('properties', Map()).count() === 1 &&
    schema.get('properties').first().get('type') === 'button' &&
    schema.get('properties').first().get('format') === 'sync-action'
  );
};

const hasAuthoritativeDataTypes = (component) => {
  return (
    component.getIn(['dataTypesConfiguration', 'dataTypesSupport']) ===
    DATA_TYPE_SUPPORT.AUTHORITATIVE
  );
};

const removeSingleSyncActionFromSchema = (schema) => {
  return hasSingleSyncAction(schema) ? Map() : schema;
};

export {
  ensureComponentWithDetails,
  ensureConfigurationsDetails,
  normalizeSnowflakeTransformationColumnTypes,
  convertComponentsToByKeyStructure,
  getDefaultBucketName,
  getDefaultTableName,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  isContainerBased,
  supportsDynamicBackendSize,
  hasDynamicBackendSizeEnabled,
  getTransformationBackendSizes,
  getLargerBackendSize,
  getAllowedTransformations,
  getAllowedPatternComponents,
  hasDevModeProblematicMapping,
  getNewComponentTypeLabel,
  shouldWarnAboutNewVersion,
  renameOrchestratorToFlow,
  resolveRouterLinkParams,
  getPredefinedCluster,
  getDatabricksParametersFromCluster,
  findCreatedFromMetadata,
  findTemplateInstanceIdFromMetadata,
  prepareCreatedFromMetadata,
  prepareFoldersFromMetadata,
  getFolderFromMetadata,
  saveFolderToMetadata,
  shouldDisableExternalBuckets,
  getComponentTypeColorClassName,
  isComponentDeprecated,
  hasGenericUI,
  hasGenericDockerUI,
  hasGenericCodeBlocksUI,
  hasGenericPackagesUI,
  hasGenericTemplatesUI,
  hasVariables,
  hasWriterSimpleTableInput,
  hasDisabledRollback,
  hasDisabledCopy,
  hasSingleSyncAction,
  hasAuthoritativeDataTypes,
  removeSingleSyncActionFromSchema,
};
