import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List } from 'immutable';
import { trim } from 'underscore.string';

import { isVariableDuplicate } from './helpers';

class VariableForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variableName: this.props.variableName,
      variableValue: this.props.variableValue,
    };

    this.handleReset = this.handleReset.bind(this);
  }

  render() {
    return (
      <div className="variable-editing">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (this.isSavingDisabled()) {
              return;
            }
            this.props
              .saveVariableFn(this.state.variableName, this.state.variableValue)
              .then(this.props.resetVariableFn);
          }}
        >
          <div className="variable-form-inner">
            <FontAwesomeIcon icon="code" fixedWidth className="text-muted icon-addon-right" />
            <div className="variable-name">
              <input
                type="text"
                value={this.state.variableName}
                onChange={(event) => {
                  this.setState({
                    variableName: trim(event.target.value),
                  });
                }}
                placeholder="Variable name"
                className={classNames({
                  'has-error': !this.props.isSaving && this.isNameDuplicate(),
                })}
                autoFocus
              />
            </div>
            {!this.props.nameOnly && (
              <div className="variable-value">
                <input
                  type="text"
                  value={this.state.variableValue}
                  onChange={(event) => {
                    this.setState({
                      variableValue: event.target.value,
                    });
                  }}
                  placeholder="Default value"
                />
              </div>
            )}
          </div>
          <div className="variable-controls">
            <Button
              type="submit"
              bsStyle="success"
              bsSize="small"
              disabled={this.isSavingDisabled() || this.props.isSaving}
            >
              Save
            </Button>
            <Button type="reset" bsSize="small" onClick={this.handleReset}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }

  isNameDuplicate() {
    return isVariableDuplicate(
      this.props.variables,
      this.props.variableName,
      this.state.variableName,
    );
  }

  isSavingDisabled() {
    return (
      !trim(this.state.variableName) ||
      (!this.state.variableValue && !this.props.nameOnly) ||
      (this.props.variableName === this.state.variableName &&
        this.props.variableValue === this.state.variableValue) ||
      this.props.isSaving ||
      this.isNameDuplicate()
    );
  }

  handleReset() {
    this.setState(
      {
        variableName: this.props.variableName,
        variableValue: this.props.variableValue,
      },
      this.props.resetVariableFn,
    );
  }
}

VariableForm.propTypes = {
  saveVariableFn: PropTypes.func.isRequired,
  resetVariableFn: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  variables: PropTypes.instanceOf(List).isRequired,
  variableName: PropTypes.string,
  variableValue: PropTypes.string,
  nameOnly: PropTypes.bool,
};

VariableForm.defaultProps = {
  variableName: '',
  variableValue: '',
};

export default VariableForm;
