import React from 'react';
import { fromJS, type List, Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import { JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import type { ConfigRow } from '@/react/common/ConfigurationsTable/constants';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import MoveActionButton from '@/react/common/ConfigurationsTable/MoveActionButton';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import DeleteDataApp from './DeleteDataApp';
import DeployDataApp from './DeployDataApp';
import OpenDataApp from './OpenDataApp';
import StatusLabel from './StatusLabel';
import TerminateDataApp from './TerminateDataApp';

const CUSTOM_CLASSES = fromJS({
  link: { th: 'w-250 text-right', td: 'w-250 text-right' },
  status: { th: 'w-175', td: 'w-175 no-wrap' },
});

type Props = {
  component: Map<string, any>;
  dataApps: Map<string, any>;
  sandboxes: Map<string, any>;
  allConfigurations: Map<string, any>;
  componentsMetadata: Map<string, any>;
  pendingActions: Map<string, any>;
  filterQuery: string;
  readOnly: boolean;
  hasNewQueue: boolean;
  hasFlows: boolean;
  hasPayAsYouGo: boolean;
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  processingJobs: List<any>;
};

class DataApps extends React.Component<Props> {
  render() {
    return (
      <ConfigurationsTable
        supportFolders
        hideCommonActions
        entity="data app"
        forceShowAll={!!this.props.filterQuery}
        customClasses={CUSTOM_CLASSES}
        component={this.props.component}
        configurations={this.prepareDataApps()}
        allConfigurations={this.props.allConfigurations}
        availableConfigurations={this.props.allConfigurations.get(KEBOOLA_DATA_APPS)}
        componentsMetadata={this.props.componentsMetadata}
        readOnly={this.props.readOnly}
        hasNewQueue={this.props.hasNewQueue}
        hasFlows={this.props.hasFlows}
        admins={this.props.admins}
        currentAdmin={this.props.currentAdmin}
        additionalColumns={this.additionalColumns}
        renderAdditionalActions={this.renderAdditionalActions}
      />
    );
  }

  prepareDataApps = () => {
    return this.props.dataApps
      .filter((dataApp) => {
        return !this.props.pendingActions.hasIn([
          'delete',
          dataApp.getIn(['configuration', 'parameters', 'id']),
        ]);
      })
      .map((dataApp) => {
        const sandbox = this.getSandboxForConfig(dataApp);
        const sandboxId = dataApp.getIn(['configuration', 'parameters', 'id']);

        return dataApp.set(
          'pending',
          Map({
            isRestoring: this.props.pendingActions.hasIn(['restore', sandboxId]),
            isTerminating: this.props.pendingActions.hasIn(['terminate', sandboxId]),
            isDeploying: this.props.processingJobs.some((job) => {
              return (
                job.get('config') === sandbox.get('configurationId') &&
                ['create', 're-create'].includes(job.getIn(['configData', 'parameters', 'task'])) &&
                JOB_RUNNING_STATUSES.includes(job.get('status'))
              );
            }),
          }),
        );
      });
  };

  additionalColumns = [
    {
      enableSorting: false,
      accessorKey: 'link',
      header: 'Application Link',
      cell: ({ row }: { row: ConfigRow }) => {
        if (!row.original.data.config) return null;

        return (
          <OpenDataApp
            mode="list"
            config={row.original.data.config}
            sandbox={this.getSandboxForConfig(row.original.data.config)}
          />
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }: { row: ConfigRow }) => {
        if (!row.original.data.config) return null;

        return (
          <StatusLabel
            sandboxConfig={this.getSandboxForConfig(row.original.data.config)}
            isDeploying={row.original.data.config.getIn(['pending', 'isDeploying'])}
            isRestoring={row.original.data.config.getIn(['pending', 'isRestoring'])}
            isTerminating={row.original.data.config.getIn(['pending', 'isTerminating'])}
          />
        );
      },
      sortingFn: (rowA: ConfigRow, rowB: ConfigRow) => {
        const sandboxA = this.getSandboxForConfig(rowA.original.data.config);
        const sandboxB = this.getSandboxForConfig(rowB.original.data.config);

        if (!sandboxA.isEmpty() && sandboxB.isEmpty()) {
          return 1;
        }

        return sandboxA.isEmpty() || sandboxA.get('active') ? -1 : 1;
      },
      isLastColumn: true,
    },
  ];

  renderAdditionalActions = (
    config: Map<string, any> | undefined,
    commonProps: Record<string, any>,
  ) => {
    if (!config) return null;

    const sandbox = this.getSandboxForConfig(config);

    return (
      <>
        <DeployDataApp
          mode="menuitem"
          config={config}
          sandbox={sandbox}
          readOnly={this.props.readOnly}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
        />
        <RowActionMenuItem divider />
        <TerminateDataApp
          mode="menuitem"
          config={config}
          sandbox={sandbox}
          readOnly={this.props.readOnly}
          isPending={config.getIn(['pending', 'isTerminating'])}
        />
        <CopyButton
          configuration={config}
          component={this.props.component}
          hasFlows={this.props.hasFlows}
        />
        <MoveActionButton
          mode="menuitem"
          onClick={commonProps.onMove}
          component={this.props.component}
          hasFlows={this.props.hasFlows}
        />
        <RowActionMenuItem divider />
        <DeleteDataApp
          mode="menuitem"
          config={config}
          sandbox={sandbox}
          readOnly={this.props.readOnly}
        />
      </>
    );
  };

  getSandboxForConfig = (config: Map<string, any>) => {
    return this.props.sandboxes.find(
      (sandbox) => sandbox!.get('id') === config.getIn(['configuration', 'parameters', 'id']),
      null,
      Map(),
    );
  };
}

export default DataApps;
