import { KEBOOLA_GOODDATA_WRITER as componentId } from '@/constants/componentIds';

export const ProvisioningActionTypes = {
  GD_PROVISIONING_READ_MODEL_START: 'GD_PROVISIONING_READ_MODEL_START',
  GD_PROVISIONING_READ_MODEL_SUCCESS: 'GD_PROVISIONING_READ_MODEL_SUCCESS',
  GD_PROVISIONING_READ_MODEL_ERROR: 'GD_PROVISIONING_READ_MODEL_ERROR',
};

export const DataTypes = {
  BIGINT: 'BIGINT',
  DATE: 'DATE',
  DECIMAL: 'DECIMAL',
  INT: 'INT',
  VARCHAR: 'VARCHAR',
};

export const Types = {
  ATTRIBUTE: 'ATTRIBUTE',
  CONNECTION_POINT: 'CONNECTION_POINT',
  DATE: 'DATE',
  FACT: 'FACT',
  HYPERLINK: 'HYPERLINK',
  LABEL: 'LABEL',
  REFERENCE: 'REFERENCE',
  IGNORE: 'IGNORE',
};

export const routeNames = {
  ROOT: componentId,
  TABLE: `${componentId}-table`,
};
