import React from 'react';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import ConfigurationDisabledLabel from './ConfigurationDisabledLabel';

const ConfigurationTitleLabel = () => {
  const { config } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');

      return {
        config: InstalledComponentsStore.getConfig(componentId, configId),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore],
  );

  if (config.isEmpty() || !config.get('isDisabled')) {
    return null;
  }

  return <ConfigurationDisabledLabel className="in-title" />;
};

export default ConfigurationTitleLabel;
