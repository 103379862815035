import { Map } from 'immutable';

type Mapping = {
  mapToState: (parameters: Map<string, any>) => Map<string, any>;
  mapToApi: (parameters: Map<string, any>, editing: Map<string, any>) => Map<string, any>;
};

const prepareMapToStateFn = (mapping: Mapping[]) => (parameters: Map<string, any>) => {
  return mapping.reduce((state, mapping) => {
    return state.mergeDeep(mapping.mapToState(parameters));
  }, Map()) as Map<string, any>;
};

const prepareMapToApiFn =
  (mapping: Mapping[]) => (parameters: Map<string, any>, editing: Map<string, any>) => {
    return mapping.reduce((parameters, mapping) => {
      return mapping.mapToApi(parameters, editing);
    }, parameters);
  };

export { prepareMapToStateFn, prepareMapToApiFn };
