import React from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';

import SearchBar from './SearchBar';

type Props = {
  onSearch: (query: string) => void;
  onSubmit?: (query: string) => void;
  query?: string;
  autoFocus?: boolean;
  searchFieldPlaceholder?: string;
  predefinedSearches?: { query: string; name: string }[];
  additionalActions?: ReactNode;
};

const SearchPanel = ({
  onSearch,
  onSubmit,
  query = '',
  autoFocus = false,
  searchFieldPlaceholder,
  predefinedSearches,
  additionalActions,
}: Props) => {
  const [currentQuery, setCurrentQuery] = React.useState(query);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleSubmit = () => {
    onSearch(currentQuery);

    if (onSubmit) {
      onSubmit(currentQuery);
    }
  };

  const initRef = (input: HTMLInputElement) => {
    inputRef.current = input;
  };

  return (
    <div className="search-panel box">
      <SearchBar
        autoFocus={autoFocus}
        query={currentQuery}
        onChange={(newQuery) => setCurrentQuery(newQuery)}
        onSubmit={handleSubmit}
        inputRef={initRef}
        placeholder={searchFieldPlaceholder}
        additionalActions={
          !predefinedSearches && !additionalActions ? null : (
            <div className="predefined-search-list">
              {predefinedSearches?.map((link, index) => (
                <button
                  key={index}
                  type="button"
                  className={classnames('btn predefined-search-link', {
                    active: currentQuery === link.query,
                  })}
                  onClick={() => {
                    setCurrentQuery(link.query);
                    onSearch(link.query);
                    inputRef.current?.focus();

                    if (onSubmit) {
                      onSubmit(link.query);
                    }
                  }}
                >
                  {link.name}
                </button>
              ))}
              {additionalActions}
            </div>
          )
        }
      />
    </div>
  );
};

export default SearchPanel;
