import React from 'react';

import whereOperatorConstants from './whereOperatorConstants';

const WhereOperator = ({ backendOperator }: { backendOperator?: string }) => {
  if (backendOperator === whereOperatorConstants.NOT_EQ_VALUE) {
    return <span>{whereOperatorConstants.NOT_EQ_LABEL}</span>;
  }

  return <span>{whereOperatorConstants.EQ_LABEL}</span>;
};

export default WhereOperator;
