import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Alert, HelpBlock } from 'design';
import { List, Map } from 'immutable';

import DockerActionsActionCreators from '@/modules/configurations/DockerActionsActionCreators';
import getColumns, {
  preparePayload as prepareGetColumnsPayload,
} from '@/modules/ex-google-bigquery-v2/actions/getColumns';
import { preparePayload as prepareGetTablesPayload } from '@/modules/ex-google-bigquery-v2/actions/getTables';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';

class DataSource extends React.Component {
  constructor(props) {
    super(props);

    this.refreshTables = this.refreshTables.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.loadColumns = this.loadColumns.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.value.tableId) {
      return;
    }

    if (
      (prevProps.actions.getIn(['getTables', 'status']) === 'pending' &&
        this.props.actions.getIn(['getTables', 'status']) === 'success') ||
      (this.props.actions.getIn(['getTables', 'status']) === 'success' &&
        this.props.value.tableId !== prevProps.value.tableId)
    ) {
      this.loadColumns();
    }
  }

  render() {
    if (this.props.allValues.advancedMode) {
      return null;
    }

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Data Source</h2>
        </div>
        <div className="box-content">
          {this.renderMissingConfigurationWarning()}
          {this.renderLoadingTablesErrors()}
          {this.renderTables()}
          {this.renderColumns()}
        </div>
      </div>
    );
  }

  renderTables() {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Table</ControlLabel>
        </div>
        <div className="col-xs-8">
          {this.props.actions.getIn(['getTables', 'status']) === 'pending' ? (
            <FormControl.Static>
              <Loader className="icon-addon-right" />
              Fetching list of tables from source
            </FormControl.Static>
          ) : (
            <>
              <Select
                placeholder="Select source table"
                value={`${this.props.value.datasetId}.${this.props.value.tableId}`}
                options={this.props.actions
                  .getIn(['getTables', 'data'], List())
                  .map((table) => ({
                    label: `${table.get('datasetId')}.${table.get('tableId')}`,
                    value: `${table.get('datasetId')}.${table.get('tableId')}`,
                  }))
                  .toArray()}
                onChange={(selected) => {
                  if (!selected) {
                    return this.props.onChange({ datasetId: '', tableId: '' });
                  }

                  const table = this.props.actions
                    .getIn(['getTables', 'data'], List())
                    .find(
                      (table) => `${table.get('datasetId')}.${table.get('tableId')}` === selected,
                      null,
                      Map(),
                    );

                  this.props.onChange({
                    datasetId: table.get('datasetId', ''),
                    tableId: table.get('tableId', ''),
                  });
                }}
                disabled={
                  this.props.disabled ||
                  this.props.actions.getIn(['getTables', 'status']) !== 'success'
                }
              />
              <HelpBlock>
                Not seeing your newest tables?{' '}
                <Button
                  bsStyle="link"
                  className="btn-link-inline"
                  onClick={this.refreshTables}
                  disabled={this.props.disabled}
                >
                  Reload
                </Button>{' '}
                the list of tables.
              </HelpBlock>
            </>
          )}
        </div>
      </FormGroup>
    );
  }

  renderColumns() {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Columns</ControlLabel>
        </div>
        <div className="col-xs-8">
          {this.props.actions.getIn(['getColumns', 'status']) === 'pending' ? (
            <FormControl.Static>
              <Loader className="icon-addon-right" />
              Fetching list of columns
            </FormControl.Static>
          ) : (
            <>
              <Select
                multi
                placeholder="All columns will be imported"
                value={this.props.value.columns}
                options={this.props.actions
                  .getIn(['getColumns', 'data'], List())
                  .map((column) => ({ label: column.get('name'), value: column.get('name') }))
                  .toArray()}
                onChange={(columns) => this.props.onChange({ columns })}
                disabled={
                  this.props.disabled ||
                  !this.props.value.tableId ||
                  this.props.actions.getIn(['getTables', 'status']) !== 'success'
                }
              />
              <HelpBlock>
                Not seeing all columns?{' '}
                <Button
                  bsStyle="link"
                  className="btn-link-inline"
                  onClick={this.refreshColumns}
                  disabled={this.props.disabled || !this.props.value.tableId}
                >
                  Reload
                </Button>{' '}
                the list of columns.
              </HelpBlock>
            </>
          )}
        </div>
      </FormGroup>
    );
  }

  renderMissingConfigurationWarning() {
    const parameters = this.props.context.getIn(['configuration', 'parameters'], Map());

    if (parameters.has('service_account') && parameters.hasIn(['google', 'location'])) {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        <b>Google Service Account Key</b> and <b>Unload Configuration</b> must be configured before
        tables are loaded.
      </Alert>
    );
  }

  renderLoadingTablesErrors() {
    if (this.props.actions.getIn(['getTables', 'status']) !== 'error') {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        {this.props.actions.getIn(['getTables', 'error']) ||
          'An unknown error occurred during loading tables.'}
      </Alert>
    );
  }

  loadColumns() {
    return setTimeout(() => {
      return DockerActionsActionCreators.requestAction(
        this.props.context.get('componentId'),
        Map(getColumns),
        this.props.context.get('configuration'),
        Map()
          .setIn(['parameters', 'query', 'datasetId'], this.props.value.datasetId)
          .setIn(['parameters', 'query', 'tableId'], this.props.value.tableId),
      );
    });
  }

  refreshTables() {
    return DockerActionsActionCreators.callAction(
      this.props.context.get('componentId'),
      'getTables',
      prepareGetTablesPayload(this.props.context.get('configuration')),
    );
  }

  refreshColumns() {
    return DockerActionsActionCreators.callAction(
      this.props.context.get('componentId'),
      'getColumns',
      prepareGetColumnsPayload(
        this.props.context.get('configuration'),
        Map()
          .setIn(['parameters', 'query', 'datasetId'], this.props.value.datasetId)
          .setIn(['parameters', 'query', 'tableId'], this.props.value.tableId),
      ),
    );
  }
}

DataSource.propTypes = {
  context: PropTypes.instanceOf(Map).isRequired,
  actions: PropTypes.instanceOf(Map).isRequired,
  allValues: PropTypes.object.isRequired,
  value: PropTypes.shape({
    tableId: PropTypes.string.isRequired,
    datasetId: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DataSource;
