import { Map } from 'immutable';

import { KEBOOLA_DATABRICKS_TRANSFORMATION } from '@/constants/componentIds';
import { hasGenericCodeBlocksUI } from '@/modules/components/helpers';
import { prepareBlocks } from '@/modules/components/react/components/generic/code-blocks/helpers';

const prepareTransformationData = (component: Map<string, any>, tempData: Map<string, any>) => {
  let configuration = Map();

  if (hasGenericCodeBlocksUI(component)) {
    configuration = configuration.setIn(['parameters', 'blocks'], prepareBlocks());
  }

  if (component.get('id') === KEBOOLA_DATABRICKS_TRANSFORMATION) {
    configuration = configuration.updateIn(['parameters'], Map(), (parameters) =>
      parameters.merge(
        !tempData.get('clusterId')
          ? {
              numberOfNodes: tempData.get('numberOfNodes'),
              nodeType: tempData.get('nodeType'),
              sparkVersion: tempData.get('sparkVersion'),
            }
          : { clusterId: tempData.get('clusterId') },
      ),
    );
  }

  if (tempData.get('image_tag')) {
    configuration = configuration.setIn(['runtime', 'image_tag'], tempData.get('image_tag'));
  }

  if (tempData.get('pattern')) {
    configuration = configuration.setIn(
      ['runtime', 'codePattern', 'componentId'],
      tempData.get('pattern'),
    );
  }

  if (!component.get('emptyConfiguration', Map()).isEmpty()) {
    configuration = configuration.mergeDeep(component.get('emptyConfiguration'));
  }

  return {
    componentId: component.get('id'),
    data: Map({
      name: tempData.get('name'),
      description: tempData.get('description', ''),
      configuration: JSON.stringify(configuration.toJS()),
    }).toJS(),
  };
};

const prepareDbtSyncActionConfigData = (configId: string, configData: Map<string, any>) => {
  return {
    parameters: { configId },
    ...(configData.has('artifacts') && { artifacts: configData.get('artifacts').toJS() }),
  };
};

export { prepareTransformationData, prepareDbtSyncActionConfigData };
