import React from 'react';
import { Badge } from 'design';

const BucketSharedLabel = (props: { isSharing: boolean; className?: string }) => {
  if (!props.isSharing) {
    return null;
  }

  return <Badge text="Shared" variant="purple" className={props.className} />;
};

export default BucketSharedLabel;
