import type { ShownHandles } from '@/modules/lineage/rfTypes';

export const isMyHandleHidden = (
  type: 'source' | 'target',
  // node of edge (source or target)
  nodeId: string,
  edgeHandle: string | null | undefined,
  shownHandles: ShownHandles | null,
) => {
  if (shownHandles === null) {
    return edgeHandle !== 'main';
  }

  return shownHandles.some((node) => {
    return (
      node.id === nodeId &&
      (type === 'source'
        ? !node.sourceHandles.includes(edgeHandle ?? '')
        : !node.targetHandles.includes(edgeHandle ?? ''))
    );
  });
};
