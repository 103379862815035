import { STACKS } from '@keboola/constants';

const getProjectStackOptions = (isSingleTenant) => {
  if (isSingleTenant) {
    return [{ value: `https://${window.location.host}/`, label: window.location.host }];
  }

  let defaultOptions = [
    {
      value: `https://${STACKS.EU_CENTRAL_1_AWS.host}/`,
      label: `AWS EU (${STACKS.EU_CENTRAL_1_AWS.host})`,
    },
    {
      value: `https://${STACKS.US_EAST_1_AWS.host}/`,
      label: `AWS US (${STACKS.US_EAST_1_AWS.host})`,
    },
    {
      value: `https://${STACKS.NORTH_EUROPE_AZURE.host}/`,
      label: `Azure EU (${STACKS.NORTH_EUROPE_AZURE.host})`,
    },
    {
      value: `https://${STACKS.EUROPE_WEST3_GCP.host}/`,
      label: `GCP EU (${STACKS.EUROPE_WEST3_GCP.host})`,
    },
  ];

  if (
    ![
      STACKS.EU_CENTRAL_1_AWS.host,
      STACKS.US_EAST_1_AWS.host,
      STACKS.NORTH_EUROPE_AZURE.host,
      STACKS.EUROPE_WEST3_GCP.host,
    ].includes(window.location.host)
  ) {
    defaultOptions.push({ value: `https://${window.location.host}/`, label: window.location.host });
  }

  return defaultOptions;
};

export { getProjectStackOptions };
