import React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';
import classNames from 'classnames';
import { HelpBlock, Tooltip } from 'design';
import { type List, Map } from 'immutable';

import type { ACTIVE_MENU, PARAM_VALUE } from '@/modules/ex-generic/constants';
import { FUNCTION_NAME } from '@/modules/ex-generic/constants';
import { preparePropertyName } from '@/modules/ex-generic/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import UserParameterSelector from './UserParameterSelector';

const GenericPairs = (props: {
  readOnly: boolean;
  entity: string;
  rows: List<any>;
  onChange: (value: List<any>) => void;
  onSave?: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
  userParameters?: Map<string, any>;
  parameters?: Map<string, any>;
  valueTitle?: string;
  valueTooltip?: string;
  keyTooltip?: string;
}) => {
  const renderValue = (row: Map<string, any>, index: number) => {
    if (!props.userParameters || !props.parameters || !props.onSave) {
      return (
        <FormControl
          type="text"
          value={row.get('value', '')}
          disabled={props.readOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(props.rows.setIn([index, 'value'], e.target.value));
          }}
        />
      );
    }

    return (
      <UserParameterSelector
        row={row}
        readOnly={props.readOnly}
        userParameters={props.userParameters}
        parameters={props.parameters}
        onSave={props.onSave}
        onChange={(value: string, parameter?: PARAM_VALUE) => {
          const isSimpleValue = typeof parameter === 'undefined';

          const row = props.rows.get(index, Map()).withMutations((row: Map<string, any>) => {
            const newValue = isSimpleValue
              ? value
              : Map.isMap(parameter)
              ? (parameter as Map<string, any>).set(FUNCTION_NAME, value)
              : Map({ attr: value });

            row.set('value', newValue);

            if (!isSimpleValue && !row.get('key')) {
              row.set('key', preparePropertyName(value));
            }
          });

          return props.onChange(props.rows.set(index, row));
        }}
      />
    );
  };

  const renderRow = (row: Map<string, any>, index?: number) => {
    if (typeof index === 'undefined') {
      return;
    }

    const hasDuplicateKey = props.rows.some((r, i) => {
      return !!r.get('key') && i !== index && r.get('key') === row.get('key');
    });

    return (
      <div className="tw-mb-4 tw-flex tw-justify-between" key={index}>
        <div key={index} className="tw-grid tw-flex-1 tw-grid-cols-2 tw-items-start tw-gap-4">
          <div className={classNames({ 'has-error': hasDuplicateKey })}>
            <FormControl
              type="text"
              autoFocus={row.isEmpty() && index === props.rows.count() - 1}
              value={row.get('key', '')}
              disabled={props.readOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange(props.rows.setIn([index, 'key'], e.target.value));
              }}
            />
            {hasDuplicateKey && <HelpBlock variant="danger">Duplicated key name.</HelpBlock>}
          </div>
          {renderValue(row, index)}
        </div>
        <div className="tw-mr-1 tw-inline-flex tw-h-10 tw-w-8 tw-justify-end">
          {!props.readOnly && (
            <Tooltip tooltip="Delete row" placement="top">
              <Button
                bsStyle="link"
                className="btn-link-inline dark muted"
                onClick={() => props.onChange(props.rows.delete(index))}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!props.rows.isEmpty() && (
        <>
          <div className="tw-mb-3 tw-flex tw-text-xs tw-font-medium tw-uppercase tw-text-neutral-500">
            <div className="tw-grid tw-flex-1 tw-grid-cols-2 tw-items-start tw-gap-4">
              <div>Key{props.keyTooltip && <InfoTooltip tooltip={props.keyTooltip} />}</div>
              <div>
                {props.valueTitle || 'Value'}
                {props.valueTooltip && <InfoTooltip tooltip={props.valueTooltip} />}
              </div>
            </div>
            <div className="tw-w-8" />
          </div>
          {props.rows.map(renderRow).toArray()}
        </>
      )}
      <Button
        bsSize="sm"
        disabled={props.readOnly}
        onClick={() => {
          props.onChange(props.rows.update((headers) => headers.push(Map())));
        }}
      >
        <FontAwesomeIcon icon="plus" className="btn-icon" />
        Add {props.entity}
      </Button>
    </>
  );
};

export default GenericPairs;
