import React from 'react';
import { Badge } from 'design';

const states = {
  NOT_DEPLOYED: { variant: 'gray', text: 'Not Deployed' },
  ACTIVE: { variant: 'green', text: 'Active' },
  SLEEPING: { variant: 'purple', text: 'Sleeping' },
  DEPLOYING: { variant: 'orange', text: 'Deploying' },
  RESTORING: { variant: 'orange', text: 'Restoring' },
  TERMINATING: { variant: 'orange', text: 'Terminating' },
} as const;

const StatusLabel = ({
  sandboxConfig,
  isDeploying,
  isRestoring,
  isTerminating,
}: {
  sandboxConfig: Record<string, any>;
  isDeploying: boolean;
  isRestoring: boolean;
  isTerminating: boolean;
}) => {
  const state = isDeploying
    ? states.DEPLOYING
    : isRestoring
    ? states.RESTORING
    : isTerminating
    ? states.TERMINATING
    : sandboxConfig.isEmpty()
    ? states.NOT_DEPLOYED
    : sandboxConfig.get('active', false)
    ? states.ACTIVE
    : states.SLEEPING;

  return (
    <Badge text={state.text} variant={state.variant} className="tw-min-w-16 tw-rounded tw-py-1.5" />
  );
};

export default StatusLabel;
