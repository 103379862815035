import '@/utils/codemirror/setup';

import { JSONEditor } from '@json-editor/json-editor';
import DOMPurify from 'dompurify';

import CodeMirrorPlugin from './plugins/codemirror-plugin';
import PasswordPlugin from './plugins/password-plugin';
import ReactMultiSelectPlugin from './plugins/react-multi-select-plugin';
import ReactSelectPlugin from './plugins/react-select-plugin';
import SshFormPlugin from './plugins/ssh-form-plugin';
import SyncActionButtonPlugin from './plugins/sync-action-button-plugin';
import TestConnectionPlugin from './plugins/test-connection-plugin';
import TrimStringPlugin from './plugins/trim-string-plugin';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

DOMPurify.addHook('beforeSanitizeElements', function (currentNode) {
  if (currentNode.tagName === 'A') {
    currentNode.setAttribute('target', '_blank');
    currentNode.setAttribute('rel', 'noopener noreferrer');
  }
  return currentNode;
});

window.DOMPurify = DOMPurify; // to allow HTML format in titles/headers and descriptions

const originalResolvers = [...JSONEditor.defaults.resolvers];

JSONEditor.defaults.resolvers.unshift((schema) => {
  if (schema.type === 'button' && schema.format === 'test-connection') {
    return 'test-connection';
  }

  if (schema.type === 'button' && schema.format === 'sync-action') {
    return 'sync-action-button';
  }

  if (['string', 'object'].includes(schema.type) && schema.format === 'editor') {
    return 'editor';
  }

  if (schema.type === 'object' && schema.format === 'ssh-editor') {
    return 'ssh-editor';
  }

  if (schema.type === 'string' && schema.format === 'trim') {
    return 'trim';
  }

  if (schema.type === 'string' && schema.format === 'password') {
    return 'password';
  }

  if (originalResolvers.some((resolver) => resolver(schema) === 'select')) {
    return 'react-select';
  }

  if (
    schema.items &&
    schema.uniqueItems &&
    schema.type === 'array' &&
    schema.format !== 'checkbox' &&
    !Array.isArray(schema.items) &&
    ['string', 'number', 'integer'].includes(schema.items.type) &&
    (schema.items.enum || schema.items.enumSource || schema.options?.tags)
  ) {
    return 'react-multi-select';
  }
});

JSONEditor.defaults.options.theme = 'bootstrap3';
JSONEditor.defaults.editors.editor = CodeMirrorPlugin;
JSONEditor.defaults.editors['ssh-editor'] = SshFormPlugin;
JSONEditor.defaults.editors['react-select'] = ReactSelectPlugin;
JSONEditor.defaults.editors['react-multi-select'] = ReactMultiSelectPlugin;
JSONEditor.defaults.editors['test-connection'] = TestConnectionPlugin;
JSONEditor.defaults.editors['sync-action-button'] = SyncActionButtonPlugin;
JSONEditor.defaults.editors.trim = TrimStringPlugin;
JSONEditor.defaults.editors.password = PasswordPlugin;

export default JSONEditor;
