import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { canCreateAliasTable, canWriteBucket } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import {
  createAliasTable,
  createTable,
  createTableDefinition,
  uploadFile,
} from '@/modules/storage/actions';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import CreateAliasTableModal from './CreateAliasTableModal';
import CreateTableModal from './CreateTableModal';

const CreateTableButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, BucketsStore, TablesStore, FilesStore)],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('bucketId');

    return {
      sapiToken: ApplicationStore.getSapiToken(),
      bucket: BucketsStore.getBucket(bucketId),
      tables: TablesStore.getAll().filter((table) => table.getIn(['bucket', 'id']) === bucketId),
      allBuckets: BucketsStore.getAll(),
      allTables: TablesStore.getAll(),
      creatingTable: TablesStore.getIsCreatingTable(),
      creatingAliasTable: TablesStore.getIsCreatingAliasTable(),
      uploadingProgress: FilesStore.getUploadingProgress(bucketId),
      hasNewNativeTypes: ApplicationStore.hasNewNativeTypes(),
      defaultBackend: ApplicationStore.getCurrentProject().get('defaultBackend'),
    };
  },

  getInitialState() {
    return {
      showCreateTableModal: false,
      showCreateAliasTableModal: false,
    };
  },

  render() {
    if (!canWriteBucket(this.state.sapiToken, this.state.bucket)) {
      return null;
    }

    return (
      <>
        <ButtonToolbar>
          {canCreateAliasTable(this.state.sapiToken, this.state.bucket) && (
            <Button
              onClick={() => this.setState({ showCreateAliasTableModal: true })}
              disabled={this.state.creatingAliasTable}
              className={NEW_ENTITY_BUTTON}
            >
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              Create alias table
            </Button>
          )}
          <Button
            bsStyle="success"
            onClick={() => this.setState({ showCreateTableModal: true })}
            disabled={this.state.creatingTable || this.state.uploadingProgress > 0}
            className={NEW_ENTITY_BUTTON}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Create table
          </Button>
        </ButtonToolbar>
        {this.renderCreateTableModal()}
        {this.renderCreateAliasTableModal()}
      </>
    );
  },

  renderCreateTableModal() {
    return (
      <CreateTableModal
        bucket={this.state.bucket}
        tables={this.state.tables}
        openModal={this.state.showCreateTableModal}
        onCreateFromCsv={this.handleCreateTable}
        onCreateDefinition={this.handleCreateTableDefinition}
        onHide={() => this.setState({ showCreateTableModal: false })}
        isCreatingTable={this.state.creatingTable}
        progress={this.state.uploadingProgress}
        hasNewNativeTypes={this.state.hasNewNativeTypes}
        defaultBackend={this.state.defaultBackend}
      />
    );
  },

  renderCreateAliasTableModal() {
    if (!canCreateAliasTable(this.state.sapiToken, this.state.bucket)) {
      return null;
    }

    return (
      <CreateAliasTableModal
        bucket={this.state.bucket}
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
        openModal={this.state.showCreateAliasTableModal}
        onSubmit={this.handleCreateAliasTable}
        onHide={() => this.setState({ showCreateAliasTableModal: false })}
        isSaving={this.state.creatingAliasTable}
      />
    );
  },

  handleCreateTable(file, params) {
    const bucketId = this.state.bucket.get('id');

    return uploadFile(bucketId, file).then((dataFileId) => {
      return createTable(bucketId, { ...params, dataFileId });
    });
  },

  handleCreateTableDefinition(params) {
    return createTableDefinition(this.state.bucket.get('id'), params);
  },

  handleCreateAliasTable(params) {
    return createAliasTable(this.state.bucket.get('id'), params);
  },
});

export default CreateTableButton;
