import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import Promise from 'bluebird';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Alert, Badge, Link, Tooltip } from 'design';
import { fromJS, List, Map } from 'immutable';

import { HTNS_EX_SALESFORCE, KEBOOLA_EX_GOOGLE_BIGQUERY } from '@/constants/componentIds';
import DockerActionFn from '@/modules/components/DockerActionsApi';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import jobsApi from '@/modules/jobs/JobsApi';
import queueApi from '@/modules/queue/api';
import { routeNames as queueRouteNames } from '@/modules/queue/constants';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import CreatedDate from '@/react/common/CreatedDate';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Loader from '@/react/common/Loader';
import RefreshIcon from '@/react/common/RefreshIcon';
import RouterLink from '@/react/common/RouterLink';
import contactSupport from '@/utils/contactSupport';
import ComponentConfigurationLink from './ComponentConfigurationLink';

const MIGRATION_COMPONENT_ID = 'keboola.config-migration-tool';

/** @type {any} */
const MigrationRow = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    replacementAppId: PropTypes.string,
    inTable: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      inTable: false,
    };
  },

  getInitialState() {
    return {
      loadingStatus: false,
      isLoading: false,
      status: Map(),
      showModal: false,
      error: null,
    };
  },

  render() {
    return (
      <div className={classnames({ 'p-1': this.props.inTable })}>
        <CollapsibleAlert
          id={`migration-${this.props.componentId}`}
          title="This component has been deprecated"
          variant="warning"
          className={classnames({ 'tw-mb-6': !this.props.inTable })}
        >
          {this.props.replacementAppId ? (
            <>
              {this.renderInfo()}
              {this.renderMigrationButton()}
            </>
          ) : this.props.componentId === KEBOOLA_EX_GOOGLE_BIGQUERY ? (
            <p>
              We will turn off this component at the end of the year. Until then it will be fully
              functional, although its UI will be replaced by a simple JSON editor on April 15,
              2022. Even though you can use the component for several more months, we suggest you
              migrate your configurations to its successor component,{' '}
              <Link href={`${URLS.COMPONENTS}/components/keboola.ex-google-bigquery-v2`}>
                Google BigQuery v2
              </Link>
              . If you{' '}
              <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
                ask
              </Button>
              , our support team will be happy to assist with the migration.
            </p>
          ) : (
            <p>
              New configuration cannot be created. Consider switching to another component to ensure
              a functionality in the future.{' '}
              <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
                Contact our support
              </Button>{' '}
              if you have any questions.
            </p>
          )}
        </CollapsibleAlert>
        {this.renderModal()}
      </div>
    );
  },

  renderInfo() {
    return (
      <>
        <p>
          Migration process will migrate all configurations of{' '}
          {this.renderComponentName(this.props.componentId)} to new configurations of{' '}
          {this.renderComponentName(this.props.replacementAppId)} component within this project.
          {this.props.componentId === HTNS_EX_SALESFORCE && (
            <>
              {' '}
              The migration process respects the component state and maintains the output bucket.
            </>
          )}
        </p>
        <p>
          Please note that{' '}
          <strong>any encrypted values or authorized accounts will not be migrated</strong> and have
          to be entered/authorized manually again.{' '}
          <strong>
            {this.props.hasFlows ? 'Flows' : 'Orchestrations'} need to be updated manually
          </strong>{' '}
          to use the new component configurations.
        </p>
      </>
    );
  },

  renderMigrationButton() {
    return (
      <Button bsStyle="success" onClick={this.loadStatus} disabled={this.state.isLoading}>
        {this.state.isLoading ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
        )}
        Proceed to Migration
      </Button>
    );
  },

  renderTabTitle(title, helptext) {
    return (
      <span>
        {`${title} `}
        <Tooltip tooltip={helptext} type="explanatory">
          <FontAwesomeIcon icon="circle-question" fixedWidth />
        </Tooltip>
      </span>
    );
  },

  renderComponentName(componentId) {
    return ComponentsStore.hasComponent(componentId) ? (
      <>
        <strong>{ComponentsStore.getComponent(componentId).get('name')}</strong> ({componentId})
      </>
    ) : (
      componentId
    );
  },

  renderModal() {
    return (
      <Modal
        bsSize="large"
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              Configuration Migration{' '}
              <RefreshIcon isLoading={this.state.loadingStatus} onClick={this.loadStatus} />
              {this.renderJobInfo()}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderModalBody()}</Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveStyle="success"
            saveLabel="Migrate"
            isSaving={this.state.isLoading}
            isDisabled={this.state.isLoading || this.state.loadingStatus || this.state.error}
            onSave={this.onMigrate}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  renderModalBody() {
    if (this.state.loadingStatus) {
      return (
        <>
          <Loader /> Loading migration status...
        </>
      );
    }

    if (this.state.error) {
      return (
        <Alert variant="error" className="tw-mb-5">
          Error Loading status: {this.state.error}
        </Alert>
      );
    }

    return (
      <Table responsive striped>
        <thead>
          <tr>
            <th>Configuration</th>
            <th>New Configuration</th>
            <th className="w-150">Migration Status</th>
          </tr>
        </thead>
        <tbody>
          {this.state.status.get('configurations', List()).map((row) => {
            return (
              <tr key={row.get('configId')}>
                <td>
                  {this.renderConfigLink(
                    row.get('configId'),
                    this.props.componentId,
                    row.get('configName'),
                  )}
                </td>
                <td>{this.renderNewConfigLink(row)}</td>
                <td>{this.renderRowStatus(row.get('status'))}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  },

  renderRowStatus(status) {
    if (status === 'success') {
      return <Badge text={status} variant="green" />;
    }

    if (status.includes('error')) {
      return <Badge text="Error" variant="red" />;
    }

    return <Badge text={status} variant="blue" />;
  },

  renderJobInfo() {
    if (!this.state.job) {
      return (
        <div>
          <small>Last Job: N/A</small>
        </div>
      );
    }

    return (
      <div>
        <small>
          <strong>Last Job: </strong>
          <CreatedDate createdTime={this.state.job.get('createdTime')} /> by{' '}
          {this.state.job.getIn(['token', 'description'])}{' '}
          <RouterLink
            className="icon-addon-right"
            to={this.props.hasNewQueue ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
            params={{ jobId: this.state.job.get('id') }}
          >
            {this.state.job.get('id')}
          </RouterLink>
          <JobStatusLabel status={this.state.job.get('status')} />
        </small>
      </div>
    );
  },

  renderNewConfigLink(row) {
    const hasConfig = !InstalledComponentsStore.getConfig(
      this.props.replacementAppId,
      row.get('configId'),
    ).isEmpty();

    return (
      <ul className="list-unstyled">
        {hasConfig ? (
          <li key={row.get('configId')}>
            {this.renderConfigLink(
              row.get('configId'),
              this.props.replacementAppId,
              `${this.props.replacementAppId} / ${row.get('configName')}`,
            )}
          </li>
        ) : (
          <li>{`${this.props.replacementAppId} / ${row.get('configName')}`}</li>
        )}
      </ul>
    );
  },

  renderConfigLink(configId, componentId, label) {
    return (
      <ComponentConfigurationLink
        componentId={componentId}
        configId={configId}
        hasFlows={this.props.hasFlows}
      >
        {label ? label : configId}
      </ComponentConfigurationLink>
    );
  },

  loadStatus() {
    this.setState({ showModal: true, loadingStatus: true });
    return Promise.props({
      components: InstalledComponentsActionCreators.loadInstalledComponentsForce(),
      job: this.fetchLastMigrationJob(this.props.componentId),
      status: DockerActionFn(MIGRATION_COMPONENT_ID, 'status', {
        configData: {
          parameters: { origin: this.props.componentId, destination: this.props.replacementAppId },
        },
      }),
    }).then((result) => {
      if (result.status.status === 'error') {
        return this.setState({ error: result.status.message, loadingStatus: false });
      }

      return this.setState({
        job: result.job,
        status: fromJS(result.status),
        loadingStatus: false,
      });
    });
  },

  fetchLastMigrationJob(componentId) {
    if (this.props.hasNewQueue) {
      return queueApi
        .getJobs({
          component: MIGRATION_COMPONENT_ID,
          limit: 10,
        })
        .then(fromJS)
        .then((jobs) => {
          return jobs.find((job) => {
            return job.getIn(['configData', 'parameters', 'origin']) === componentId;
          });
        });
    }

    return jobsApi
      .getJobsParametrized(`params.component:${MIGRATION_COMPONENT_ID}`, 10, 0)
      .then((jobs) => fromJS(jobs || []))
      .then((jobs) => {
        return jobs.find((job) => {
          return job.getIn(['params', 'configData', 'parameters', 'origin']) === componentId;
        });
      });
  },

  onMigrate() {
    this.setState({ isLoading: true });
    return InstalledComponentsActionCreators.runComponent({
      method: 'run',
      component: MIGRATION_COMPONENT_ID,
      data: {
        configData: {
          parameters: {
            origin: this.props.componentId,
            destination: this.props.replacementAppId,
          },
        },
      },
      notify: true,
    })
      .then(() => this.setState({ showModal: false }))
      .finally(() => this.setState({ isLoading: false }));
  },
});

export default MigrationRow;
