import { fromJS, List, Map } from 'immutable';

import type {
  Inputs,
  Instance,
  InstanceDetail,
  StepPayload,
  Template,
  Templates,
  ValidationResult,
  VersionDetailExtended,
} from '@/api/routes/templatesService';
import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

export type Store = {
  templates: Template[];
  templateVersionDetail: VersionDetailExtended | null;
  templatesCategories: string[];
  instances: Instance[] | null;
  instanceDetail: InstanceDetail | null;
  instanceConfiguratorForm: {
    inputs?: Inputs;
    userValues?: StepPayload[];
    validation?: ValidationResult;
  };
};

let _store = initStore(
  'TemplatesStore2',
  Map({
    templates: List<any>(),
    templateVersionDetail: null,
    templatesCategories: List<string>(),
    instances: null,
    instanceDetail: null,
    instanceConfiguratorForm: Map<string, any>(),
  }),
);

const TemplatesStore2 = StoreUtils.createStore({ getStore: () => _store.toJS() as Store });

dispatcher.register(
  ({
    action,
  }: {
    action: {
      type: string;
      templates: Templates;
      templateVersionDetail: VersionDetailExtended;
      templateId: string;
      templateVersion: string;
      inputs: Inputs;
      instances: Instance[];
      instanceDetail: InstanceDetail;
      instanceConfiguratorForm: Store['instanceConfiguratorForm'];
    };
  }) => {
    switch (action.type) {
      case actionTypes.LOAD_TEMPLATES_SUCCESS: {
        const templates = fromJS(action.templates.templates);
        _store = _store.set('templates', templates).set(
          'templatesCategories',
          templates
            .map((component: Record<string, any>) => component.get('categories'))
            .toSet()
            .flatten()
            .sort(),
        );

        return TemplatesStore2.emitChange();
      }

      case actionTypes.LOAD_TEMPLATE_SUCCESS:
        _store = _store.set('templateVersionDetail', fromJS(action.templateVersionDetail));

        return TemplatesStore2.emitChange();

      case actionTypes.LOAD_INSTANCES_SUCCESS: {
        const newInstances = fromJS(action.instances);

        _store = _store.set('instances', newInstances);

        // Reset `instanceDetail` if it doesn't exist in `instances` anymore
        if (
          newInstances &&
          newInstances?.some(
            (instance: Record<string, any>) =>
              instance.get('instanceId') === _store.getIn(['instanceDetail', 'instanceId  ']),
          )
        ) {
          _store = _store.set('instanceDetail', null);
        }

        return TemplatesStore2.emitChange();
      }

      case actionTypes.LOAD_INSTANCE_DETAIL_SUCCESS:
        _store = _store.set('instanceDetail', fromJS(action.instanceDetail));

        return TemplatesStore2.emitChange();

      case actionTypes.CHANGE_INSTANCE_CONFIGURATOR_FORM:
        _store = _store.mergeIn(
          ['instanceConfiguratorForm'],
          fromJS(action.instanceConfiguratorForm),
        );

        return TemplatesStore2.emitChange();

      case actionTypes.RESET_INSTANCE_CONFIGURATOR_FORM:
        _store = _store
          .deleteIn(['instanceConfiguratorForm', 'userValues'])
          .deleteIn(['instanceConfiguratorForm', 'validation']);

        return TemplatesStore2.emitChange();

      default:
        break;
    }
  },
);

export default TemplatesStore2;
