import React from 'react';

import blankComponentsImage from '../../../../../images/illustrations/blank-components.png';

import { fileUrl } from '@/utils/fileHelpers';

const TYPES = {
  TRANSFORMATION: 'transformation',
  DATA_APP: 'data-app',
} as const;

const HEADER = {
  [TYPES.TRANSFORMATION]: 'Your data source extracts data. Let’s transform it.',
  [TYPES.DATA_APP]: 'Turn Raw Data Into Interactive Data Apps.',
};

const ALT = {
  [TYPES.TRANSFORMATION]: 'Transformation introduction',
  [TYPES.DATA_APP]: 'Data App introduction',
};

const TEXTS = {
  [TYPES.TRANSFORMATION]: 'Data gets transformed all the time. You can use SQL, Python, or R.',
  [TYPES.DATA_APP]:
    'Power up your business teams with self-serve data and free data engineers to focus on strategic projects.',
};

const BlankComponentsPage = (props: { type: (typeof TYPES)[keyof typeof TYPES] }) => {
  return (
    <div className="blank-page">
      <img src={fileUrl(blankComponentsImage)} className="intro-image" alt={ALT[props.type]} />
      <h2>{HEADER[props.type]}</h2>
      <p className="intro-text">{TEXTS[props.type]}</p>
    </div>
  );
};

export default BlankComponentsPage;
