import React from 'react';
import type { Map } from 'immutable';

import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import StreamStore from '@/modules/stream/store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import AddNewStream from './AddNewStream';

const IndexHeaderButton = () => {
  const { sources, tables, readOnly } = useStores(
    () => {
      const { sources } = StreamStore.getStore();

      return {
        sources,
        tables: StorageTablesStore.getAll() as unknown as Map<string, any>,
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [StreamStore, StorageTablesStore, ApplicationStore],
  );

  if (!sources?.length || readOnly) return;

  return <AddNewStream tables={tables} sources={sources} />;
};

export default IndexHeaderButton;
