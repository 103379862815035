const ActionTypes = {
  TRANSFORMATION_BUCKET_CREATE: 'LEGACY_TRANSFORMATION_BUCKET_CREATE',
  TRANSFORMATION_BUCKET_CREATE_SUCCESS: 'LEGACY_TRANSFORMATION_BUCKET_CREATE_SUCCESS',
  TRANSFORMATION_BUCKET_CREATE_ERROR: 'LEGACY_TRANSFORMATION_BUCKET_CREATE_ERROR',

  TRANSFORMATION_CREATE_SUCCESS: 'LEGACY_TRANSFORMATION_CREATE_SUCCESS',

  TRANSFORMATION_BUCKET_DELETE: 'LEGACY_TRANSFORMATION_BUCKET_DELETE',
  TRANSFORMATION_BUCKET_DELETE_SUCCESS: 'LEGACY_TRANSFORMATION_BUCKET_DELETE_SUCCESS',
  TRANSFORMATION_BUCKET_DELETE_ERROR: 'LEGACY_TRANSFORMATION_BUCKET_DELETE_ERROR',

  TRANSFORMATION_DELETE: 'LEGACY_TRANSFORMATION_DELETE',
  TRANSFORMATION_DELETE_SUCCESS: 'LEGACY_TRANSFORMATION_DELETE_SUCCESS',
  TRANSFORMATION_DELETE_ERROR: 'LEGACY_TRANSFORMATION_DELETE_ERROR',

  TRANSFORMATION_EDIT_START: 'LEGACY_TRANSFORMATION_EDIT_START',
  TRANSFORMATION_EDIT_CANCEL: 'LEGACY_TRANSFORMATION_EDIT_CANCEL',
  TRANSFORMATION_EDIT_SAVE_START: 'LEGACY_TRANSFORMATION_EDIT_SAVE_START',
  TRANSFORMATION_EDIT_SAVE_SUCCESS: 'LEGACY_TRANSFORMATION_EDIT_SAVE_SUCCESS',
  TRANSFORMATION_EDIT_SAVE_ERROR: 'LEGACY_TRANSFORMATION_EDIT_SAVE_ERROR',
  TRANSFORMATION_EDIT_CHANGE: 'LEGACY_TRANSFORMATION_EDIT_CHANGE',

  TRANSFORMATION_EDIT_INPUT_MAPPING_OPEN_TOGGLE:
    'LEGACY_TRANSFORMATION_EDIT_INPUT_MAPPING_OPEN_TOGGLE',
  TRANSFORMATION_EDIT_INPUT_MAPPING_ADD: 'LEGACY_TRANSFORMATION_EDIT_INPUT_MAPPING_ADD',
  TRANSFORMATION_EDIT_INPUT_MAPPING_DELETE: 'LEGACY_TRANSFORMATION_EDIT_INPUT_MAPPING_DELETE',

  TRANSFORMATION_EDIT_OUTPUT_MAPPING_OPEN_TOGGLE:
    'LEGACY_TRANSFORMATION_EDIT_OUTPUT_MAPPING_OPEN_TOGGLE',
  TRANSFORMATION_EDIT_OUTPUT_MAPPING_ADD: 'LEGACY_TRANSFORMATION_EDIT_OUTPUT_MAPPING_ADD',
  TRANSFORMATION_EDIT_OUTPUT_MAPPING_DELETE: 'LEGACY_TRANSFORMATION_EDIT_OUTPUT_MAPPING_DELETE',

  TRANSFORMATION_UPDATE_EDITING_FIELD: 'LEGACY_TRANSFORMATION_UPDATE_EDITING_FIELD',
  TRANSFORMATION_CANCEL_EDITING_FIELD: 'LEGACY_TRANSFORMATION_CANCEL_EDITING_FIELD',

  DELETED_TRANSFORMATION_BUCKET_RESTORE: 'LEGACY_DELETED_TRANSFORMATION_BUCKET_RESTORE',
  DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS:
    'LEGACY_DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS',
  DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR: 'LEGACY_DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR',

  TRANSFORMATION_UPDATE_PARSE_QUERIES: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_START: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_START',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR',
};

const backends = {
  SNOWFLAKE: 'snowflake',
  DOCKER: 'docker',
};

const transformationType = {
  PYTHON: 'python',
  R: 'r',
};

const transformationLabels = {
  snowflake: 'Snowflake',
  docker: 'Docker',
  python: 'Python',
  r: 'R',
};

const routeNames = {
  ROOT: 'legacy-transformation',
  BUCKET: 'legacyTransformationBucket',
  DETAIL: 'legacyTransformationDetail',
  BUCKET_VERSIONS: 'legacy-transformation-bucket-versions',
  BUCKET_ROW_VERSIONS: 'legacy-transformation-bucket-row-versions',
  DETAIL_GRAPH: 'legacyTransformationDetailGraph',
};

export { ActionTypes, backends, transformationType, transformationLabels, routeNames };
