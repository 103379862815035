import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import Dispatcher from '@/Dispatcher';
import { loadMetadata } from '@/modules/google-utils/AnalyticsMetadata';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

const ActionTypes = {
  GAPI_START_INIT: 'GAPI_START_INIT',
  GAPI_FINISH_INIT: 'GAPI_FINISH_INIT',
  GAPI_METADATA_LOAD_START: 'GAPI_METADATA_LOAD_START',
  GAPI_METADATA_LOAD_SUCCESS: 'GAPI_METADATA_LOAD_SUCCESS',
};

let _store = initStore(
  'GoogleUtilsStore',
  Map({
    isInitialized: false,
    metadata: Map(),
    isLoadingMetadata: false,
    isLoadedMetadata: false,
  }),
);

export const GapiStore = StoreUtils.createStore({
  isInitialized: () => _store.get('isInitialized'),
  isLoadingMetadata: () => _store.get('isLoadingMetadata', false),
  isLoadedMetadata: () => _store.get('isLoadedMetadata', false),
  getMetadata: () => _store.get('metadata', Map()),
});

Dispatcher.register((payload) => {
  const action = payload.action;
  switch (action.type) {
    case ActionTypes.GAPI_START_INIT:
      _store = _store.set('isInitialized', false);
      GapiStore.emitChange();
      break;
    case ActionTypes.GAPI_FINISH_INIT:
      _store = _store.set('isInitialized', true);
      GapiStore.emitChange();
      break;
    case ActionTypes.GAPI_METADATA_LOAD_START:
      _store = _store.set('isLoadingMetadata', true);
      _store = _store.set('isLoadedMetadata', false);
      GapiStore.emitChange();
      break;
    case ActionTypes.GAPI_METADATA_LOAD_SUCCESS:
      _store = _store.set('isLoadingMetadata', false);
      _store = _store.set('isLoadedMetadata', true);
      _store = _store.set('metadata', fromJS(action.data));
      GapiStore.emitChange();
      break;

    default:
      return;
  }
});

export const GapiActions = {
  loadAnalyticsMetadata: (configData) => {
    if (GapiStore.isLoadedMetadata()) {
      return Promise.resolve();
    }
    Dispatcher.handleViewAction({
      type: ActionTypes.GAPI_METADATA_LOAD_START,
    });
    return loadMetadata(configData).then((data) => {
      Dispatcher.handleViewAction({
        type: ActionTypes.GAPI_METADATA_LOAD_SUCCESS,
        data: data,
      });
    });
  },

  startInit: () => {
    Dispatcher.handleViewAction({
      type: ActionTypes.GAPI_START_INIT,
    });
  },

  finishInit: () => {
    Dispatcher.handleViewAction({
      type: ActionTypes.GAPI_FINISH_INIT,
    });
  },
};
