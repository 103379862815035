import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import { Map } from 'immutable';

import { routeNames as lineageRouteNames } from '@/modules/lineage/constants';
import RoutesStore from '@/stores/RoutesStore';
import { prepareLocationContext } from '@/utils/modalRoutes';

const LineageButton = ({
  currentProjectId,
  currentProjectName,
}: {
  currentProjectId: string | number | null;
  currentProjectName: string | null;
}) => {
  return (
    <Tooltip placement="bottom" tooltip="Data lineage">
      <Button
        bsStyle="link"
        className="text-muted"
        onClick={() => {
          const location = RoutesStore.getRouterState().get('location', Map());
          RoutesStore.getRouter().transitionTo(
            lineageRouteNames.LINEAGE,
            {
              projectId: currentProjectId,
              projectName: currentProjectName,
            },
            {
              context: prepareLocationContext(location),
            },
          );
        }}
      >
        <FontAwesomeIcon icon="chart-network" className="f-24 !tw-bottom-0" />
      </Button>
    </Tooltip>
  );
};

export default LineageButton;
