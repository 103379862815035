import Promise from 'bluebird';
import { Map } from 'immutable';

import {
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
} from '@/constants/componentIds';
import provisioningActions from '@/modules/provisioning/ActionCreators';
import wrDbProvStore from '@/modules/provisioning/stores/WrDbCredentialsStore';
import StorageService from '@/modules/tokens/actionCreators';
import SapiStorage from '@/modules/tokens/StorageTokensStore';

const NEW_WR_REDSHIFT_COMPONENT_ID = [KEBOOLA_WR_REDSHIFT_V_2];
const WR_SNOWFLAKE_COMPONENT_ID = [
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_LOOKER_V2,
];

const getDriverAndPermission = (driverParam, permissionParam, componentId) => {
  let driver = driverParam;
  let permission = permissionParam;
  if (NEW_WR_REDSHIFT_COMPONENT_ID.includes(componentId)) {
    driver = 'redshift-workspace';
    permission = 'writer';
  }
  if (WR_SNOWFLAKE_COMPONENT_ID.includes(componentId)) {
    driver = 'snowflake';
    permission = 'writer';
  }
  return {
    driver,
    permission,
  };
};

const getCredentials = (token, driver, componentId) => {
  const { driver: realDriver, permission: realPermission } = getDriverAndPermission(
    driver,
    'write',
    componentId,
  );
  return provisioningActions.loadWrDbCredentials(realPermission, token, realDriver).then(() => {
    const creds = wrDbProvStore.getCredentials(realPermission, token);
    if (creds) {
      return {
        write: creds,
      };
    }
    return Map();
  });
};

const getWrDbToken = (desc, legacyDesc) =>
  StorageService.loadTokens().then(() => {
    const tokens = SapiStorage.getAll();
    const wrDbToken = tokens.find((token) => {
      const needle = token.get('description');
      return [desc, legacyDesc].includes(needle);
    });
    return wrDbToken;
  });

const _dropCredentials = (driver, permission, token, configHasCredentials) => {
  if (configHasCredentials && wrDbProvStore.getCredentials(permission, token)) {
    return provisioningActions.dropWrDbCredentials(permission, token, driver);
  }
  return Promise.resolve();
};

const clearCredentials = (componentId, driver, token, configCredentials) => {
  const hasWriteCredentials = configCredentials && configCredentials.get('write', null);
  const writeTypes = getDriverAndPermission(driver, 'write', componentId);
  return _dropCredentials(writeTypes.driver, writeTypes.permission, token, hasWriteCredentials);
};

export default {
  getCredentialsWithoutTokenCreation(driver, componentId, configId) {
    const desc = `wrdb${driver}_${configId}`;
    const legacyDesc = `wrdb${driver}`;
    return getWrDbToken(desc, legacyDesc).then((token) => {
      if (!token) {
        return Map();
      }
      return getCredentials(token.get('token'), driver, componentId);
    });
  },

  clearAll(componentId, configId, driver, currentCredentials) {
    const desc = `wrdb${driver}_${configId}`;
    const legacyDesc = `wrdb${driver}`;
    return getWrDbToken(desc, legacyDesc).then((token) => {
      if (!token) {
        return;
      }
      const tokenStr = token.get('token');
      return clearCredentials(componentId, driver, tokenStr, currentCredentials).then(() =>
        StorageService.deleteToken(token),
      );
    });
  },
};
