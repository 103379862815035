import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';

const CodeEditorCloseButton = (props: { onClick: () => void }) => {
  return (
    <Tooltip placement="bottom" tooltip="Close">
      <Button className="!tw-px-3 !tw-py-[9px] !tw-text-sm" onClick={() => props.onClick()}>
        <FontAwesomeIcon icon="xmark" fixedWidth />
      </Button>
    </Tooltip>
  );
};

export default CodeEditorCloseButton;
