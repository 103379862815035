import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { NodeProps } from '@xyflow/react';
import { Controls, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';

import type {
  ConfigurationNode,
  CustomEdge,
  CustomNode,
  EdgeData,
  NodeData,
  OnSelectProject,
  OnSetShownHandles,
  ProjectNode,
  ShownHandles,
  TableNode,
} from '@/modules/lineage/rfTypes';
import { useLayoutNodes } from '@/modules/lineage/useLayoutNodes';
import Configuration from './CustomNode/Configuration';
import Project from './CustomNode/Project';
import Table from './CustomNode/Table/Table';
import CenterButton from './CenterButton';
import CommonEdge from './CommonEdge';
import { HIGHLIGHT_MUTED_OPACITY, SIDEBAR_WIDTH } from './constants';
import { isMyHandleHidden } from './dynamicEdges';
import FitViewButton from './FitViewButton';
import { GraphContext } from './GraphContext';
import { highlightPath } from './highlighting';
import { initEdgesProps, initNodesProps, proOptions, zoomOptions } from './initConfig';
import LockButton from './LockButton';
import NodesLoadingPlaceholder from './NodesLoadingPlaceholder';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

const RENDER_ONLY_VISIBLE_ELEMENTS_LIMIT = 500;

const edgeTypes = {
  default: CommonEdge,
};

// hidden property works in combination with opacity to prevent showing unmeasured edges and nodes
const getInitEdges = (edgesData: EdgeData[]) => {
  return edgesData.map((link) => ({ ...initEdgesProps, ...link, hidden: true }));
};

const getInitNodes = (nodesData: NodeData[], withColumns: boolean): CustomNode[] => {
  return nodesData.map((node) => {
    const commonProps = { id: node.fqid, ...initNodesProps, hidden: true };

    switch (node.type) {
      case 'table':
        return {
          ...commonProps,
          type: node.type,
          data: { ...node, columns: withColumns ? node.columns.sort() : [] },
        };

      case 'component':
      case 'transformation':
        return { ...commonProps, type: node.type, data: { ...node } };

      case 'project':
        return { ...commonProps, type: node.type, data: { ...node } };
    }
  });
};

type Props = {
  nodesData: NodeData[];
  edgesData: EdgeData[];
  onOpenSidebar?: (nodeId: string | null) => void;
  openedSidebar?: boolean;
  inModal?: boolean;
  onSelectProject: OnSelectProject;
  withColumns?: boolean;
  projectId?: string | null;
};

const DataLineageReactFlow = ({
  nodesData,
  edgesData,
  onOpenSidebar,
  openedSidebar = false,
  onSelectProject,
  inModal = false,
  withColumns = false,
  projectId = null,
}: Props) => {
  const { graphId, setNodeFocus, focusedNodeId } = useContext(GraphContext);

  const [nodesDraggable, setNodesDraggable] = useState(true);

  // Dynamic handles. First we only have main handle, then we can dynamically add more. To prevent an edge to assign to random handle
  // if its own handle is not visible (will be fixed in react-flow 12), we will go through visible handles here and set opacity 0 to the edges
  // that have their handles hidden.
  const shownHandles = useRef<ShownHandles>(
    nodesData.map((node) => ({ id: node.fqid, sourceHandles: ['main'], targetHandles: ['main'] })),
  );

  const initEdges = useMemo(() => getInitEdges(edgesData), [edgesData]);

  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>(initEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNode>(
    getInitNodes(nodesData, withColumns),
  );
  const { getNodes, getEdges } = useReactFlow<CustomNode, CustomEdge>();

  useLayoutNodes();

  const onSetShownHandles: OnSetShownHandles = useCallback(
    (nodeId, columns) => {
      const handles = shownHandles.current.map((node) => {
        if (node.id !== nodeId) {
          return node;
        }

        return {
          ...node,
          sourceHandles: ['main', ...columns.map((column) => `${column}-source`)],
          targetHandles: ['main', ...columns.map((column) => `${column}-target`)],
        };
      });

      setEdges((prevEdges) => {
        const mutedNodes = getNodes()
          .filter((node) => node.style?.opacity === HIGHLIGHT_MUTED_OPACITY)
          .map((node) => node.id);

        const mutedEdges = prevEdges
          .filter((edge) => edge.style?.opacity === HIGHLIGHT_MUTED_OPACITY)
          .map((edge) => edge.id);

        const newEdges = initEdges
          .filter(({ source, target, sourceHandle, targetHandle }) => {
            return (
              !isMyHandleHidden('source', source, sourceHandle, handles) &&
              !isMyHandleHidden('target', target, targetHandle, handles)
            );
          })
          .map((edge) => {
            return {
              ...edge,
              hidden: false,
              style: {
                opacity:
                  mutedEdges.includes(edge.id) ||
                  mutedNodes.includes(edge.source) ||
                  mutedNodes.includes(edge.target)
                    ? HIGHLIGHT_MUTED_OPACITY
                    : 1,
              },
            };
          });

        return newEdges;
      });

      shownHandles.current = handles;
    },
    [initEdges, getNodes, setEdges],
  );

  const resetHighlightedPath = useCallback(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => ({
        ...node,
        style: { ...node.style, opacity: 1, pointerEvents: 'all' },
      })),
    );
    setEdges((prevEdges) =>
      prevEdges.map((edge) => ({ ...edge, style: { ...edge.style, opacity: 1 } })),
    );
  }, [setEdges, setNodes]);

  const nodeTypes = useMemo(() => {
    const commonProps = {
      onHighlight: (nodeId: string) => {
        setNodeFocus(nodeId);
        highlightPath(nodeId, getNodes(), getEdges(), setNodes, setEdges);

        if (onOpenSidebar && new URLSearchParams(window.location.search).has('node')) {
          onOpenSidebar(nodeId);
        }
      },
      onSetShownHandles,
      onClick: (nodeId: string) => {
        onOpenSidebar?.(nodeId);

        const node = getNodes().find((node) => node.id === nodeId);

        if (node?.style?.opacity === HIGHLIGHT_MUTED_OPACITY) {
          resetHighlightedPath();
        }
      },
    };

    return {
      table: (props: NodeProps<TableNode>) => (
        <Table
          {...props}
          {...commonProps}
          projectId={projectId}
          onSelectProject={onSelectProject}
          graphId={graphId}
        />
      ),
      transformation: (props: NodeProps<ConfigurationNode>) => (
        <Configuration
          {...props}
          onHighlight={commonProps.onHighlight}
          onClick={commonProps.onClick}
          projectId={projectId}
          onSelectProject={onSelectProject}
          graphId={graphId}
        />
      ),
      component: (props: NodeProps<ConfigurationNode>) => (
        <Configuration
          {...props}
          onHighlight={commonProps.onHighlight}
          onClick={commonProps.onClick}
          projectId={projectId}
          onSelectProject={onSelectProject}
          graphId={graphId}
        />
      ),
      project: (props: NodeProps<ProjectNode>) => <Project {...props} onClick={onSelectProject} />,
    };
  }, [
    getEdges,
    getNodes,
    graphId,
    onOpenSidebar,
    onSelectProject,
    onSetShownHandles,
    projectId,
    resetHighlightedPath,
    setEdges,
    setNodeFocus,
    setNodes,
  ]);

  const handlePaneClick = useCallback(() => {
    if (focusedNodeId) {
      setNodeFocus(null);
      resetHighlightedPath();
    }
  }, [focusedNodeId, resetHighlightedPath, setNodeFocus]);

  return (
    <ReactFlow
      id={graphId}
      panOnScroll
      nodes={nodes}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      minZoom={zoomOptions.minZoom}
      maxZoom={zoomOptions.maxZoom}
      nodesDraggable={nodesDraggable}
      onPaneClick={handlePaneClick}
      proOptions={proOptions}
      disableKeyboardA11y
      edgesFocusable={false}
      nodesFocusable={false}
      deleteKeyCode={null}
      onlyRenderVisibleElements={nodes.length + edges.length > RENDER_ONLY_VISIBLE_ELEMENTS_LIMIT}
    >
      <NodesLoadingPlaceholder>
        <Controls
          className="tw-m-0 tw-flex tw-flex-col tw-text-neutral-400 [&>span:last-child_button]:!tw-border-b-0 [&_button]:!tw-border-0 [&_button]:!tw-border-b-2 [&_button]:!tw-border-solid [&_button]:!tw-border-neutral-200 [&_button]:tw-p-4 [&_svg]:tw-size-4 [&_svg]:tw-max-h-4 [&_svg]:tw-max-w-4"
          position="top-right"
          showZoom={false}
          showFitView={false}
          showInteractive={false}
          style={{ top: inModal ? 24 : 88, right: openedSidebar ? SIDEBAR_WIDTH + 24 : 24 }}
        >
          <ZoomInButton />
          <ZoomOutButton />
          <CenterButton nodes={nodes} />
          <FitViewButton />
          <LockButton lock={!nodesDraggable} onToggle={() => setNodesDraggable((prev) => !prev)} />
        </Controls>
      </NodesLoadingPlaceholder>
    </ReactFlow>
  );
};

export default DataLineageReactFlow;
