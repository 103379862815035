import React from 'react';
import { Button, ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import { components } from 'react-select';
import { URLS } from '@keboola/constants';
import { HelpBlock, Link, Tooltip } from 'design';
import type { Map } from 'immutable';
import { List } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { PREINSTALLED_PACKAGES } from '@/modules/data-apps/constants';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import CodeEditorPreview from '@/react/common/CodeEditorPreview';
import GitSetting from '@/react/common/GitSetting';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';

const UI_FORM = {
  GIT: 'git',
  CODE: 'code',
} as const;

const DEFAULT_PACKAGES_PLACEHOLDER = '__default';

type Props = {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  readOnly: boolean;
};

type TYPE = (typeof UI_FORM)[keyof typeof UI_FORM];

const DeploymentSettings = (props: Props) => {
  const code = props.configData.getIn(['parameters', 'script', 0], '');

  const [forceType, setForceType] = React.useState<TYPE>(!!code ? UI_FORM.CODE : UI_FORM.GIT);
  const [savingPackage, setSavingPackage] = React.useState(false);
  const [savingCode, setSavingCode] = React.useState(false);
  const [showCodeEditorModal, setShowCodeEditorModal] = React.useState(false);
  const [editingCode, setEditingCode] = React.useState('');

  const isCodeChanged = code !== editingCode;

  const handleCodeSave = () => {
    setSavingCode(true);
    return InstalledComponentsActionCreators.saveComponentConfigData(
      props.componentId,
      props.configId,
      props.configData
        .deleteIn(['parameters', 'dataApp', 'git'])
        .setIn(['parameters', 'script'], List([editingCode])),
      'Change code',
    ).finally(() => setSavingCode(false));
  };

  const handleCodeReset = () => setEditingCode(code);

  const handleOpenEditor = () => {
    handleCodeReset();
    setShowCodeEditorModal(true);
  };

  const getCodeMirrorOptions = () => {
    const commonOptions = { mode: 'text/x-python', placeholder: '-- Your code goes here' };

    if (props.readOnly) {
      return { ...commonOptions, cursorHeight: 0, readOnly: true };
    }

    return {
      ...commonOptions,
      hintOptions: {
        completeSingle: false,
        container: document.querySelector('.full-screen-modal.full-screen-editor'),
      },
    };
  };

  const renderBox = (content: React.ReactNode, saveButton?: React.ReactNode) => {
    return (
      <div className="box">
        <div className="box-header big-padding">
          <h2 className="box-title">Deployment</h2>
          {saveButton}
        </div>
        <div className="box-content !tw-pt-0">
          <div className="tw-flex tw-gap-2">
            <Radio
              value={UI_FORM.GIT}
              checked={UI_FORM.GIT === forceType}
              onChange={() => setForceType(UI_FORM.GIT)}
              disabled={props.readOnly}
              className="radio-btn tw-flex-1"
            >
              Git Repository
            </Radio>
            <Radio
              value={UI_FORM.CODE}
              checked={UI_FORM.CODE === forceType}
              onChange={() => setForceType(UI_FORM.CODE)}
              disabled={props.readOnly}
              className="radio-btn tw-flex-1"
            >
              Code
            </Radio>
          </div>
          <div className="tw-mt-4">{content}</div>
        </div>
      </div>
    );
  };

  if (forceType === UI_FORM.GIT) {
    return (
      <GitSetting
        readOnly={props.readOnly}
        componentId={props.componentId}
        configId={props.configId}
        configData={props.configData}
        prepareDataBeforeSave={(configData) => {
          return configData.deleteIn(['parameters', 'script']).deleteIn(['parameters', 'packages']);
        }}
      >
        {(renderContent, renderSaveButton) => {
          return renderBox(renderContent(), renderSaveButton());
        }}
      </GitSetting>
    );
  }

  const codeContent = (
    <>
      {!code && !props.readOnly && (
        <div className="tw-flex tw-justify-center">
          <Button bsStyle="success" onClick={() => setShowCodeEditorModal(true)}>
            Add Code
          </Button>
        </div>
      )}
      {!!code && (
        <FormGroup>
          <ControlLabel>Code</ControlLabel>
          <CodeEditorPreview
            mode="text/x-python"
            readOnly={props.readOnly}
            code={code}
            onClick={handleOpenEditor}
          />
        </FormGroup>
      )}
      <FormGroup className="tw-mb-0">
        <ControlLabel>Packages</ControlLabel>
        <Select
          multi
          allowCreate
          value={List([
            DEFAULT_PACKAGES_PLACEHOLDER,
            ...props.configData.getIn(['parameters', 'packages'], List()),
          ])}
          placeholder="Add packages"
          onChange={(packages) => {
            const newPackages = packages.filter((value) => {
              return value !== DEFAULT_PACKAGES_PLACEHOLDER;
            });

            setSavingPackage(true);
            return InstalledComponentsActionCreators.saveComponentConfigData(
              props.componentId,
              props.configId,
              props.configData
                .deleteIn(['parameters', 'dataApp', 'git'])
                .setIn(['parameters', 'packages'], newPackages),
              'Change packages',
            ).finally(() => setSavingPackage(false));
          }}
          promptTextCreator={(label) => `Add package "${label}"`}
          disabled={savingPackage || props.readOnly}
          multiValueRenderer={(props) => {
            if (props.data.value === DEFAULT_PACKAGES_PLACEHOLDER) {
              return (
                <Tooltip
                  placement="top"
                  type="explanatory"
                  tooltip={PREINSTALLED_PACKAGES.join(', ')}
                >
                  <components.MultiValueContainer {...props}>
                    <components.MultiValueLabel {...props.children[0].props}>
                      Default Packages
                    </components.MultiValueLabel>
                  </components.MultiValueContainer>
                </Tooltip>
              );
            }

            return <components.MultiValueContainer {...props} />;
          }}
        />
        <HelpBlock>
          For more information about package installation and usage, and the list of pre-installed
          packages, see our{' '}
          <Link href={`${URLS.USER_DOCUMENTATION}/components/data-apps/#packages`}>
            documentation
          </Link>
          .
        </HelpBlock>
      </FormGroup>
      {showCodeEditorModal && (
        <CodeEditorModal
          withAutocomplete
          editorKey={`data-app-${props.configId}}`}
          title="Code"
          value={editingCode}
          isChanged={isCodeChanged}
          onChange={setEditingCode}
          onSave={handleCodeSave}
          onReset={handleCodeReset}
          onClose={() => setShowCodeEditorModal(false)}
          renderAdditionalButtons={() => (
            <SaveButtons
              disabled={props.readOnly}
              isSaving={savingCode}
              isChanged={isCodeChanged}
              onSave={handleCodeSave}
              onReset={handleCodeReset}
            />
          )}
          codeMirrorOptions={getCodeMirrorOptions()}
        />
      )}
    </>
  );

  return renderBox(codeContent);
};

export default DeploymentSettings;
