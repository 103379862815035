import { ORCHESTRATOR, TRANSFORMATION } from '@/constants/componentIds';

const isPartialJob = (job) => {
  if (
    job.get('component') === ORCHESTRATOR &&
    !!job.getIn(['params', 'tasks']) &&
    job.getIn(['params', 'tasks']).some((task) => !task.get('active'))
  ) {
    return true;
  }

  if (
    job.get('component') === TRANSFORMATION &&
    job.hasIn(['params', 'transformations']) &&
    job.getIn(['params', 'transformations']).count() > 0
  ) {
    return true;
  }

  return job.hasIn(['params', 'row']) || job.hasIn(['params', 'configData']);
};

export { isPartialJob };
