import clsx from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  prefix: 'tw-',
});

type Mapping = Record<string, unknown>;
type Argument = string | boolean | undefined | Mapping;

export const cn = (...classes: Argument[]) => twMerge(clsx(...classes));
