import Promise from 'bluebird';

import dispatcher from '@/Dispatcher';
import api from './api';
import * as constants from './constants';
import Store from './store';

export default {
  loadJobs() {
    if (Store.isJobsLoaded()) {
      this.loadJobsForce();
      return Promise.resolve();
    }
    return this.loadJobsForce();
  },

  loadActivities() {
    if (Store.isActivitiesLoaded()) {
      this.loadActivitiesForce();
      return Promise.resolve();
    }
    return this.loadActivitiesForce();
  },

  loadLatestGitEvent() {
    if (Store.isLatestGitEventLoaded()) {
      this.loadLatestGitEventForce();
      return Promise.resolve();
    }
    return this.loadLatestGitEventForce();
  },

  loadLatestGitEventForce() {
    return api.getGitLatestEvent().then((event) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.DASHBOARD_LATEST_GIT_EVENT_LOAD_SUCCESS,
        event,
      });
    });
  },

  loadJobsForce() {
    return api.getJobs().then((jobs) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.DASHBOARD_JOBS_LOAD_SUCCESS,
        jobs,
      });
    });
  },

  loadActivitiesForce() {
    return api.getActivities().then((activities) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.DASHBOARD_ACTIVITIES_LOAD_SUCCESS,
        activities,
      });
    });
  },

  toggleIsProjectDescriptionHidden() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.DASHBOARD_TOGGLE_IS_PROJECT_DESCRIPTION_HIDDEN,
    });
  },
};
