import { Map } from 'immutable';

import commonFaIcons from '@/constants/commonFaIcons';
import { getComponentIconUrl } from './componentIconFinder';

export const getCommonIcon = (components: Map<string, any>, iconDefinition?: string) => {
  if (typeof iconDefinition === 'undefined') return;

  const [type, ...valueDestructured] = iconDefinition.split(':');
  const value = valueDestructured.join(':');

  if (type === 'component') {
    return { iconUrl: getComponentIconUrl(components.get(value, Map())) };
  }

  return { icon: commonFaIcons[value] };
};
