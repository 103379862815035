import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import dayjs from '@/date';
import { canExportTable, canPullTable, canWriteBucket } from '@/modules/admin/privileges';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal';
import { DISABLED_WORKSPACE_ACTIONS_MESSAGE, FORM_STEPS } from '@/modules/sandboxes/Constants';
import {
  prepareSandboxes,
  updateExistingWorkspace,
  workspaceActionsDisabled,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import {
  createAliasTable,
  createSnapshots,
  deleteTable,
  exportTables,
  loadTable,
  restoreUsingTimeTravel,
  truncateTable,
  uploadFile,
} from '@/modules/storage/actions';
import { backends, tableModalTabs } from '@/modules/storage/constants';
import {
  getTableAliases,
  getTableLinks,
  prepareMappingFromSelectedBucketsAndTables,
} from '@/modules/storage/helpers';
import Loader from '@/react/common/Loader';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import CloneToCurrentBranch from './CloneToCurrentBranch';
import CreateAliasTableAlternativeModal from './CreateAliasTableAlternativeModal';
import CreateSnapshotModal from './CreateSnapshotModal';
import DeleteTableModal from './DeleteTableModal';
import ExportModal from './ExportModal';
import LoadTableFromCsvModal from './LoadTableFromCsvModal';
import TimeTravelModal from './TimeTravelModal';
import TruncateTableModal from './TruncateTableModal';

type TableModalTabsType = keyof typeof tableModalTabs;

const TableActions = ({
  table,
  onDeleteTable,
}: {
  table: Map<string, any>;
  onDeleteTable: () => void;
}) => {
  const tableId = table.get('id');
  const bucket = table.get('bucket', Map());

  const state = useStores(
    () => {
      const sapiToken = ApplicationStore.getSapiToken();
      const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();
      const hasSnowflakePartnerConnectLimited = ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      );

      return {
        sapiToken,
        hasShowTransformationMigration,
        hasSnowflakePartnerConnectLimited,
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
        currentDevBranch: DevBranchesStore.getCurrentDevBranch(),
        canWriteTable: canWriteBucket(sapiToken, bucket),
        canExportTable: canExportTable(sapiToken),
        canPullTable: canPullTable(),
        addingColumn: StorageTablesStore.getAddingColumn(),
        deletingColumn: StorageTablesStore.getDeletingColumn().get(tableId, Map()),
        creatingPrimaryKey: StorageTablesStore.getIsCreatingPrimaryKey(tableId),
        deletingPrimaryKey: StorageTablesStore.getIsDeletingPrimaryKey(tableId),
        settingAliasFilter: StorageTablesStore.getIsSettingAliasFilter(tableId),
        removingAliasFilter: StorageTablesStore.getIsRemovingAliasFilter(tableId),
        updatingTable: StorageTablesStore.getIsUpdatingTable(tableId),
        uploadingProgress: FilesStore.getUploadingProgress(bucket.get('id')),
        creatingAliasTable: StorageTablesStore.getIsCreatingAliasTable(),
        truncatingTable: StorageTablesStore.getTruncatingTables().get(tableId, false),
        deletingTable: StorageTablesStore.getDeletingTables().get(tableId, false),
        loadingIntoTable: StorageTablesStore.getIsLoadingTable(),
        exportingTable: StorageTablesStore.getIsExportingTable(tableId),
        restoringTable: StorageTablesStore.getIsRestoringTable(tableId),
        pullingTable: StorageTablesStore.getIsPullingTable(tableId),
        creatingSnapshot: StorageTablesStore.getCreatingSnapshotsTables()
          .keySeq()
          .includes(tableId),
        creatingFromSnapshot: StorageTablesStore.getIsCreatingFromSnapshot(),
        deletingSnapshot: StorageTablesStore.getIsDeletingSnapshot(),
        configurations: InstalledComponentsStore.getAll(),
        components: ComponentsStore.getAll(),
        hasGraph: !ApplicationStore.hasNewTransformationsOnly(),
        sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        availableDatabricksClusters: InstalledComponentsStore.getLocalState(
          KEBOOLA_SANDBOXES,
          null,
        ).get('clusters'),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        readOnly: ApplicationStore.isReadOnly(),
        hasUsage: ApplicationStore.hasNewTransformationsOnly() || hasShowTransformationMigration,
        admins: ApplicationStore.getAdmins(),
        hasFlows: ApplicationStore.hasFlows(),
      };
    },
    [tableId, bucket],
    [
      StorageTablesStore,
      StorageBucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      ApplicationStore,
      FilesStore,
      SandboxesStore,
    ],
  );

  const [activeActionModal, setActiveActionModal] = useState<TableModalTabsType | null>(null);
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] = useState(false);
  const [createWorkspaceForceStep, setCreateWorkspaceForceStep] = useState<string | null>(null);
  const tableAliases = getTableAliases(table, state.tables, state.sapiToken);
  const tableLinks = getTableLinks(table, bucket);

  const openActionModal = (event: React.MouseEvent<HTMLElement>, action: TableModalTabsType) => {
    if (Object.values(tableModalTabs).includes(action)) {
      setActiveActionModal(action);
    }
  };

  const closeActionModal = () => {
    setActiveActionModal(null);
  };

  const handleLoadTable = (
    file: File,
    params: { delimiter: string; enclosure: string; incremental: number },
  ) => {
    return uploadFile(bucket.get('id'), file).then((dataFileId) =>
      loadTable(tableId, { ...params, dataFileId }),
    );
  };

  const handleExportTable = (type: string) => {
    return exportTables(List([table]), type);
  };

  const handleTimeTravel = (bucketId: string, tableName: string, timestamp: string) => {
    const params = {
      sourceTableId: tableId,
      timestamp: dayjs(timestamp).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss Z'),
      name: tableName,
    };

    return restoreUsingTimeTravel(bucketId, params);
  };

  const handleCreateSnapshot = (description: string) => {
    return createSnapshots([tableId], { description });
  };

  if (state.exportingTable) {
    return (
      <span className="text-muted line-height-40">
        <Loader className="icon-addon-right" />
        Preparing export
      </span>
    );
  }

  if (
    state.readOnly ||
    (!state.canWriteTable &&
      !state.canExportTable &&
      !state.canPullTable &&
      state.hasSnowflakePartnerConnectLimited)
  ) {
    return null;
  }

  const workspaceDisabled = workspaceActionsDisabled(bucket);

  return (
    <div>
      <RowActionDropdown
        toggleClassName="in-navigation"
        showLoading={
          state.exportingTable ||
          state.loadingIntoTable ||
          state.uploadingProgress > 0 ||
          state.restoringTable ||
          state.creatingSnapshot ||
          state.creatingAliasTable ||
          state.truncatingTable ||
          state.deletingTable ||
          state.pullingTable
        }
      >
        {state.canExportTable && (
          <RowActionMenuItem eventKey={tableModalTabs.EXPORT} onSelect={openActionModal}>
            <FontAwesomeIcon fixedWidth icon="down-to-line" />
            Export table
          </RowActionMenuItem>
        )}
        {state.canWriteTable && (
          <RowActionMenuItem
            eventKey={tableModalTabs.LOAD}
            onSelect={openActionModal}
            disabled={table.get('isAlias')}
          >
            {state.loadingIntoTable || state.uploadingProgress > 0 ? (
              <>
                <Loader />
                Loading into table...
              </>
            ) : (
              <>
                <FontAwesomeIcon fixedWidth icon="arrow-up" />
                Load
              </>
            )}
          </RowActionMenuItem>
        )}
        {state.canPullTable && (
          <CloneToCurrentBranch
            table={table}
            pullingTable={state.pullingTable}
            currentDevBranch={state.currentDevBranch}
          />
        )}
        {state.canWriteTable &&
          !!state.sapiToken.getIn(['owner', 'dataRetentionTimeInDays']) &&
          !table.get('isAlias') &&
          [backends.SNOWFLAKE, backends.BIGQUERY].includes(bucket.get('backend')) && (
            <RowActionMenuItem
              eventKey={tableModalTabs.RESTORE}
              onSelect={openActionModal}
              disabled={state.restoringTable}
            >
              {state.restoringTable ? (
                <>
                  <Loader />
                  Restoring table...
                </>
              ) : (
                <>
                  <FontAwesomeIcon fixedWidth icon="arrow-rotate-left" />
                  Restore
                </>
              )}
            </RowActionMenuItem>
          )}
        {state.canWriteTable && !table.get('isAlias') && (
          <RowActionMenuItem
            eventKey={tableModalTabs.SNAPSHOT}
            onSelect={openActionModal}
            disabled={state.creatingSnapshot}
          >
            {state.creatingSnapshot ? (
              <>
                <Loader />
                Creating snapshot...
              </>
            ) : (
              <>
                <FontAwesomeIcon fixedWidth icon="camera-viewfinder" />
                Create snapshot
              </>
            )}
          </RowActionMenuItem>
        )}
        {table.get('isAliasable') && state.canWriteTable && !isCreatedInDevBranch(bucket) && (
          <RowActionMenuItem
            eventKey={tableModalTabs.ALIAS}
            onSelect={openActionModal}
            disabled={state.creatingAliasTable}
          >
            <FontAwesomeIcon fixedWidth icon="plus" />
            Create alias table
          </RowActionMenuItem>
        )}
        {!state.hasSnowflakePartnerConnectLimited && (
          <>
            <RowActionMenuItem
              disabled={workspaceDisabled}
              disabledReason={DISABLED_WORKSPACE_ACTIONS_MESSAGE}
              onSelect={() => {
                setShowCreateWorkspaceModal(true);
                setCreateWorkspaceForceStep(null);
              }}
            >
              <>
                <FontAwesomeIcon icon="box" fixedWidth />
                Create new workspace
              </>
            </RowActionMenuItem>
            <RowActionMenuItem
              disabled={workspaceDisabled}
              disabledReason={DISABLED_WORKSPACE_ACTIONS_MESSAGE}
              onSelect={() => {
                setShowCreateWorkspaceModal(true);
                setCreateWorkspaceForceStep(FORM_STEPS.SANDBOX_UPDATE);
              }}
            >
              <>
                <FontAwesomeIcon icon="box" fixedWidth />
                Copy to existing workspace
              </>
            </RowActionMenuItem>
          </>
        )}
        {state.canWriteTable && (
          <>
            <RowActionMenuItem divider />
            {!table.get('isAlias') && (
              <RowActionMenuItem
                eventKey={tableModalTabs.TRUNCATE}
                onSelect={openActionModal}
                disabled={state.truncatingTable}
              >
                <FontAwesomeIcon fixedWidth icon="xmark" />
                Truncate table
              </RowActionMenuItem>
            )}
            <RowActionMenuItem
              eventKey={tableModalTabs.DELETE}
              onSelect={openActionModal}
              disabled={state.deletingTable}
            >
              {state.deletingTable ? (
                <>
                  <Loader />
                  Deleting table...
                </>
              ) : (
                <>
                  <FontAwesomeIcon fixedWidth icon="trash" />
                  Delete table
                </>
              )}
            </RowActionMenuItem>
          </>
        )}
      </RowActionDropdown>

      <CreateAliasTableAlternativeModal
        show={activeActionModal === tableModalTabs.ALIAS}
        buckets={state.buckets}
        tables={state.tables}
        table={table}
        sapiToken={state.sapiToken}
        onSubmit={createAliasTable}
        onHide={closeActionModal}
        isSaving={state.creatingAliasTable}
      />
      <DeleteTableModal
        show={activeActionModal === tableModalTabs.DELETE}
        table={table}
        sapiToken={state.sapiToken}
        urlTemplates={state.urlTemplates}
        tableAliases={tableAliases}
        tableLinks={tableLinks}
        deleting={state.deletingTable}
        onConfirm={() => deleteTable(tableId, onDeleteTable)}
        onHide={closeActionModal}
      />
      <TruncateTableModal
        show={activeActionModal === tableModalTabs.TRUNCATE}
        onConfirm={() => truncateTable(tableId)}
        onHide={closeActionModal}
        table={table}
      />
      <LoadTableFromCsvModal
        show={activeActionModal === tableModalTabs.LOAD}
        table={table}
        onSubmit={handleLoadTable}
        onHide={closeActionModal}
        isLoading={state.loadingIntoTable}
        progress={state.uploadingProgress}
      />
      <ExportModal
        show={activeActionModal === tableModalTabs.EXPORT}
        tables={List([table])}
        onSubmit={handleExportTable}
        onHide={closeActionModal}
      />
      <TimeTravelModal
        show={activeActionModal === tableModalTabs.RESTORE}
        buckets={state.buckets}
        tables={state.tables}
        table={table}
        sapiToken={state.sapiToken}
        onConfirm={handleTimeTravel}
        onHide={closeActionModal}
      />
      <CreateSnapshotModal
        show={activeActionModal === tableModalTabs.SNAPSHOT}
        onConfirm={handleCreateSnapshot}
        onHide={closeActionModal}
      />
      <AddSandboxModal
        hasTableInputMapping
        show={showCreateWorkspaceModal}
        forceStep={createWorkspaceForceStep}
        onHide={() => setShowCreateWorkspaceModal(false)}
        sandboxComponent={state.sandboxComponent}
        workspaces={state.sandboxes}
        allowedComponents={state.allowedTransformationComponents}
        availableDatabricksClusters={state.availableDatabricksClusters}
        hasPayAsYouGo={state.hasPayAsYouGo}
        onUpdate={(workspace: Map<string, any>, preserve: boolean) => {
          return prepareMappingFromSelectedBucketsAndTables(
            List([table]),
            state.tables,
            workspace.get('type'),
          ).then((storage: Map<string, any>) => {
            return updateExistingWorkspace(
              fromJS({ configuration: { parameters: { id: workspace.get('id') }, storage } }),
              workspace,
              preserve,
              'Use mapping from storage tables',
            );
          });
        }}
        onSubmit={(
          name: string,
          type: string,
          options: Map<string, any>,
          params: Map<string, any>,
          description: string,
        ) => {
          return prepareMappingFromSelectedBucketsAndTables(List([table]), state.tables, type).then(
            (storage: Map<string, any>) => {
              return SandboxesActions.createSandbox(
                { name, description, configuration: JSON.stringify({ storage }) },
                type,
                options,
                params.set('storage', storage),
              );
            },
          );
        }}
      />
    </div>
  );
};

export default TableActions;
