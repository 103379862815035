import React from 'react';
import type { Map } from 'immutable';

import { mapTablesDataToGraph } from '@/modules/lineage/helpers';
import type { OnSelectProject } from '@/modules/lineage/rfTypes';
import Content from '@/modules/lineage/TablesLineage/Content';
import type { TablesLineage } from '@/modules/lineage/types';

const ProjectDetailLineage = ({
  allComponents,
  isLocked,
  isError,
  isLoading,
  data,
  showTablesOnly,
  projectId,
  onSelectProject,
}: {
  allComponents: Map<string, any>;
  isLocked: boolean;
  isError: boolean;
  isLoading: boolean;
  data: TablesLineage;
  showTablesOnly: boolean;
  projectId: string | null;
  onSelectProject: OnSelectProject;
}) => {
  const graph = React.useMemo(() => {
    return mapTablesDataToGraph(data, allComponents, showTablesOnly);
  }, [data, allComponents, showTablesOnly]);

  return (
    <Content
      inModal
      isLocked={isLocked}
      isLoading={isLoading}
      isError={isError}
      graph={graph}
      projectId={projectId}
      onSelectProject={onSelectProject}
    />
  );
};

export default ProjectDetailLineage;
