const ActionTypes = {
  LOAD_PROJECT_SUCCESS: 'LOAD_PROJECT_SUCCESS',
  LOAD_MAINTAINER_ORGANIZATIONS_SUCCESS: 'LOAD_MAINTAINER_ORGANIZATIONS_SUCCESS',
  LOAD_ORGANIZATIONS_SUCCESS: 'LOAD_ORGANIZATIONS_SUCCESS',
  LOAD_ORGANIZATION_SUCCESS: 'LOAD_ORGANIZATION_SUCCESS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_FEATURES_SUCCESS: 'LOAD_FEATURES_SUCCESS',
  GENERATE_SESSION_TOKEN_SUCCESS: 'GENERATE_SESSION_TOKEN_SUCCESS',
};

const routeNames = {
  PROJECT: 'project-settings',
  USERS: 'users-settings',
  TOKENS: 'tokens-settings',
  NEW_TOKEN: 'tokens-settings-new',
  TOKEN_DETAIL: 'tokens-settings-detail',
  CLI: 'cli-settings',
};

const FEATURE_TYPES = {
  ADMIN: 'admin',
  PROJECT: 'project',
};

export { ActionTypes, routeNames, FEATURE_TYPES };
