const ActionTypes = {
  CONFIGURATION_WORKSPACE_CREATE_SUCCESS: 'CONFIGURATION_WORKSPACE_CREATE_SUCCESS',
  CONFIGURATION_WORKSPACES_DELETE_SUCCESS: 'CONFIGURATION_WORKSPACES_DELETE_SUCCESS',
  CONFIGURATION_WORKSPACES_PASSWORD_RESET_SUCCESS:
    'CONFIGURATION_WORKSPACES_PASSWORD_RESET_SUCCESS',
  CONFIGURATION_WORKSPACES_LOAD_SUCCESS: 'CONFIGURATION_WORKSPACES_LOAD_SUCCESS',
  CONFIGURATION_WORKSPACES_HIDE_PASSWORD: 'CONFIGURATION_WORKSPACES_HIDE_PASSWORD',
};

export { ActionTypes };
