import React from 'react';

import Truncated from '@/react/common/Truncated';

const className = 'tw-inline-flex tw-m-0 tw-text-sm tw-text-neutral-400';

const Breadcrumbs = ({
  organizationName = null,
  projectName = null,
  onOrganizationClick = null,
  configurationName = null,
}: {
  organizationName?: string | null;
  projectName?: string | null;
  onOrganizationClick?: null | (() => void);
  configurationName?: string | null;
}) => (
  <div className="tw-flex tw-flex-col tw-font-medium">
    <h2 className="tw-m-0 tw-text-2xl tw-text-neutral-900">Data Lineage</h2>
    <span className="tw-flex tw-whitespace-pre-wrap">
      {organizationName && (
        <>
          {onOrganizationClick ? (
            <Truncated
              text={<a onClick={onOrganizationClick}>{organizationName}</a>}
              tooltip={organizationName}
              className={className}
            />
          ) : (
            <Truncated text={organizationName} className={className} />
          )}
          {projectName && <p className={className}> / </p>}
        </>
      )}
      {projectName && (
        <p className={className}>
          <Truncated text={projectName} />
          {configurationName && <> / </>}
        </p>
      )}
      {configurationName && <Truncated text={configurationName} className={className} />}
    </span>
  </div>
);

export default Breadcrumbs;
