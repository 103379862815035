import { List } from 'immutable';

import { SERVICE_SYRUP, SERVICE_SYRUP_ORCHESTRATOR } from '@/constants/serviceIds';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import StorageApi from '@/modules/components/StorageApi';
import TriggersStore from '@/modules/components/stores/StorageTriggersStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import VersionActions from '@/modules/components/VersionsActionCreators';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';

const createUrl = (path) => {
  return ServicesStore.getServiceUrl(SERVICE_SYRUP) + '/' + SERVICE_SYRUP_ORCHESTRATOR + '/' + path;
};

const createRequest = (method, path) =>
  request(method, createUrl(path)).set('X-StorageApi-Token', ApplicationStore.getSapiTokenString());

const Api = {
  createOrchestration(data) {
    return createRequest('POST', 'orchestrations')
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  createConfigCopy(componentId, configId, version, name) {
    return VersionActions.loadComponentConfigByVersion(componentId, configId, version).then(() => {
      const config = VersionsStore.getConfigByVersion(componentId, configId, version);
      return (
        Api.createOrchestration({
          name,
          description: config.get('description'),
          crontabRecord: config.getIn(['configuration', 'crontabRecord'], ''),
          crontabTimezone: config.getIn(['configuration', 'crontabTimezone'], null),
          notifications: config.getIn(['configuration', 'notifications'], List()).toJS(),
          tasks: config.getIn(['configuration', 'tasks'], List()).toJS(),
        })
          .then((result) => {
            const versions = VersionsStore.getVersions(componentId, configId);
            const isLast = versions.first().get('version') === version;
            const trigger = TriggersStore.get(componentId, configId);

            if (!isLast || trigger.isEmpty() || trigger.get('tables', List()).isEmpty()) {
              return result;
            }

            return StorageApi.createToken({
              canManageBuckets: false,
              canReadAllFileUploads: false,
              componentAccess: [componentId],
              description: `Token for triggering an orchestrator`,
            })
              .then((token) =>
                StorageApi.createTrigger({
                  runWithTokenId: token.id,
                  component: componentId,
                  configurationId: result.id.toString(),
                  coolDownPeriodMinutes: trigger.get('coolDownPeriodMinutes'),
                  tableIds: trigger
                    .get('tables')
                    .map((table) => table.get('tableId'))
                    .toArray(),
                }),
              )
              .then(() => result);
          })
          // Orchestrator API doesn't accept "active" parameter on creation, so additional request
          // is needed
          .then((result) => Api.updateOrchestration(result.id, { active: false }))
          .then((result) => {
            if (!config.get('description')) {
              return result;
            }
            return InstalledComponentsApi.updateComponentConfiguration(componentId, result.id, {
              description: config.get('description'),
              changeDescription: 'Update description',
            }).then(() => result);
          })
      );
    });
  },

  getOrchestrations() {
    return createRequest('GET', 'orchestrations')
      .promise()
      .then((response) => response.body);
  },

  getOrchestration(id) {
    return createRequest('GET', `orchestrations/${id}`)
      .promise()
      .then((response) => response.body);
  },

  deleteOrchestration(id) {
    return createRequest('DELETE', `orchestrations/${id}`).promise();
  },

  runOrchestration(data) {
    return createRequest('POST', 'run')
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  updateOrchestration(id, data) {
    return createRequest('PUT', `orchestrations/${id}`)
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  saveOrchestrtionNotifications(id, notifications) {
    return createRequest('PUT', `orchestrations/${id}/notifications`)
      .send(notifications)
      .promise()
      .then((response) => response.body);
  },

  saveOrchestrationTasks(id, tasks) {
    return createRequest('PUT', `orchestrations/${id}/tasks`)
      .send(tasks)
      .promise()
      .then((response) => response.body);
  },

  getOrchestrationJobs(id, limit, offset) {
    return createRequest('GET', `orchestrations/${id}/jobs`)
      .query({ limit, offset })
      .promise()
      .then((response) => response.body);
  },

  getJob(id) {
    return createRequest('GET', `jobs/${id}`)
      .promise()
      .then((response) => response.body);
  },

  retryJob(jobId, tasks) {
    return createRequest('POST', `jobs/${jobId}/retry`)
      .send({
        tasks,
      })
      .promise()
      .then((response) => response.body);
  },
};

export default Api;
