import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRendererMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import dayjs from '@/date';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import Truncated from '@/react/common/Truncated';

const SidebarVersionsRow = createReactClass({
  mixins: [ImmutableRendererMixin],

  propTypes: {
    version: PropTypes.object.isRequired,
    admin: PropTypes.object,
    isLast: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isLast: false,
    };
  },

  render() {
    return (
      <>
        <div className="flex-container flex-start align-top color-dark">
          <JobStatusIcon
            status={this.props.isLast ? 'success' : 'default'}
            className="status-info icon-addon-right"
          />
          <span className="version-label no-wrap">#{this.props.version.get('version')}</span>{' '}
          <Truncated text={this.props.version.get('changeDescription') || 'No description'} />
        </div>
        <div className="flex-container flex-start align-top text-muted">
          <FontAwesomeIcon icon="clock" className="time-info icon-addon-right" />
          <div className="font-medium overflow-break-anywhere m-0">
            {dayjs(this.props.version.get('created')).fromNow()} by {this.renderAuthor()}
          </div>
        </div>
      </>
    );
  },

  renderAuthor() {
    if (this.props.admin) {
      return this.props.admin.get('name') || this.props.admin.get('email');
    }

    return this.props.version.getIn(['creatorToken', 'description']);
  },
});

export default SidebarVersionsRow;
