import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Well } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { CollapsiblePanel } from 'design';
import { Map } from 'immutable';

import CreatedDate from '@/react/common/CreatedDate';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import ModalIcon from '@/react/common/ModalIcon';
import Tree from '@/react/common/Tree';

const JobDetailModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired,
    renderTableLink: PropTypes.func.isRequired,
    renderDataTransfer: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      job: Map(),
    };
  },

  render() {
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ job: this.props.job })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Job {this.state.job.get('id')}</Modal.Title>
          <ModalIcon icon="magnifying-glass" color="blue" />
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  },

  renderBody() {
    if (this.state.job.isEmpty()) {
      return null;
    }

    return (
      <Modal.Body>
        {this.renderDetail()}
        {this.renderTreeData('Params', 'operationParams')}
        {this.renderTreeData('Results', 'results')}
        {this.renderTreeData('Error', 'error')}
      </Modal.Body>
    );
  },

  renderDetail() {
    const tableIdLink = this.props.renderTableLink(this.state.job);
    const runId = this.state.job.get('runId');
    const fileId = this.state.job.getIn(
      ['results', 'file', 'id'],
      this.state.job.getIn(['operationParams', 'source', 'fileId']),
    );

    return (
      <Well>
        <p className="flex-container">
          <strong>Created time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={this.state.job.get('createdTime')}
          />
        </p>
        <p className="flex-container">
          <strong>Status</strong>
          <span className="text-muted">
            <JobStatusLabel status={this.state.job.get('status')} />
          </span>
        </p>
        <p className="flex-container">
          <strong>Start time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={this.state.job.get('startTime')}
          />
        </p>
        <p className="flex-container">
          <strong>End time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={this.state.job.get('endTime')}
          />
        </p>
        <p className="flex-container">
          <strong>Duration</strong>
          <span className="text-muted">
            <JobDuration
              status={this.state.job.get('status')}
              startTime={this.state.job.get('startTime')}
              endTime={this.state.job.get('endTime')}
            />
          </span>
        </p>
        <p className="flex-container">
          <strong>Data Transfer</strong>
          <span className="text-muted">{this.props.renderDataTransfer(this.state.job)}</span>
        </p>
        <p className="flex-container">
          <strong>Operation</strong>
          <span className="text-muted">{this.state.job.get('operationName')}</span>
        </p>
        {tableIdLink && (
          <p className="flex-container">
            <strong>Table ID</strong>
            <span className="text-muted text-right">{tableIdLink}</span>
          </p>
        )}
        {runId && (
          <p className="flex-container">
            <strong>Run ID</strong>
            <span className="text-muted">{runId}</span>
          </p>
        )}
        {fileId && (
          <p className="flex-container">
            <strong>File ID</strong>
            <span className="text-muted">{fileId}</span>
          </p>
        )}
      </Well>
    );
  },

  renderTreeData(header, param) {
    const data = this.state.job.get(param);

    if (!data || !data.count()) {
      return null;
    }

    return (
      <CollapsiblePanel header={header} className="tw-mb-3">
        <Tree data={data} />
      </CollapsiblePanel>
    );
  },
});

export default JobDetailModal;
