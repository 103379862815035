import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { capitalize } from 'underscore.string';

import { KEBOOLA_WR_AWS_S_3 } from '@/constants/componentIds';
import SyncActionSimpleValue from '@/modules/configurations/react/components/SyncActionSimpleValue';
import { LOGIN_TYPES } from '@/modules/wr-aws-s3/constants';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import DocumentationLink from './DocumentationLink';

const Credentials = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      loginType: PropTypes.oneOf(Object.values(LOGIN_TYPES)).isRequired,
      bucket: PropTypes.string,
      awsAccessKeyId: PropTypes.string,
      awsSecretAccessKey: PropTypes.string,
      accountId: PropTypes.string,
      roleName: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const isWriter = this.props.context.get('componentId') === KEBOOLA_WR_AWS_S_3;

    return (
      <div className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Login Type</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              clearable={false}
              searchable={false}
              disabled={this.props.disabled}
              value={this.props.value.loginType}
              onChange={(selected) => this.props.onChange({ loginType: selected })}
              options={Object.values(LOGIN_TYPES).map((value) => ({
                value,
                label: capitalize(value),
              }))}
            />
          </div>
        </FormGroup>
        {this.props.value.loginType === LOGIN_TYPES.ROLE ? (
          <>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Account ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={this.props.value.accountId}
                  onChange={(e) => {
                    this.props.onChange({ accountId: e.target.value });
                  }}
                  disabled={this.props.disabled}
                  placeholder="123456789012"
                />
                <HelpBlock>ID of your AWS account</HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Role Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={this.props.value.roleName}
                  onChange={(e) => {
                    this.props.onChange({ roleName: e.target.value });
                  }}
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  Fill in name of a role you created in your AWS account. More information is
                  available in the{' '}
                  <DocumentationLink isWriter={isWriter} section="#authentication-with-aws-role" />.
                </HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>External Id</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  <SyncActionSimpleValue
                    action={this.props.actions.get('getExternalId')}
                    valueKey={['external-id']}
                    withClipboard
                  />
                </FormControl.Static>
                <HelpBlock>
                  Use this identifier as the External ID when creating the new role.
                </HelpBlock>
              </div>
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Access Key Id</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={this.props.value.awsAccessKeyId}
                  onChange={(e) => {
                    this.props.onChange({ awsAccessKeyId: e.target.value });
                  }}
                  placeholder="MYAWSACCESSKEYID123"
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  <span>
                    Make sure that this AWS Access Key ID has correct permissions. Required
                    permissions are
                    {isWriter ? (
                      <ul>
                        <li>
                          <code>s3:PutObject</code> to upload files
                        </li>
                        <li>
                          <code>s3:GetBucketLocation</code> to determine the bucket region
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          <code>s3:GetObject</code> for the given key/wildcard
                        </li>
                        <li>
                          <code>s3:ListBucket</code> to access all wildcard files
                        </li>
                        <li>
                          <code>s3:GetBucketLocation</code> to determine the bucket region
                        </li>
                      </ul>
                    )}
                    More information is available in the <DocumentationLink isWriter={isWriter} />.
                  </span>
                </HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Secret Access Key</ControlLabel>
              </div>
              <div className="col-xs-8">
                <PasswordControl
                  value={this.props.value.awsSecretAccessKey}
                  onChange={(e) => this.props.onChange({ awsSecretAccessKey: e.target.value })}
                  disabled={this.props.disabled}
                />
                <HelpBlock>The AWS Secret Access Key will be encrypted.</HelpBlock>
              </div>
            </FormGroup>
          </>
        )}
        {isWriter && (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>S3 Bucket</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                placeholder="mybucket"
                value={this.props.value.bucket}
                onChange={(e) => {
                  this.props.onChange({ bucket: e.target.value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the target AWS S3 bucket.</HelpBlock>
            </div>
          </FormGroup>
        )}
      </div>
    );
  },
});

export default Credentials;
