import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as workspaceRoutes } from '@/modules/sandboxes/Constants';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import RoutesStore from '@/stores/RoutesStore';

const Index = createReactClass({
  mixins: [createStoreMixin(RoutesStore, InstalledComponentsStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const componentId = RoutesStore.getCurrentRouteParam('component') || KEBOOLA_SANDBOXES;
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);
    const savedMapping = configData.getIn(['storage', 'input', 'files'], List());
    const editingMapping = localState.get('file-input-mapping', savedMapping);

    return {
      configId,
      componentId,
      configData,
      localState,
      savedMapping,
      editingMapping,
      isChanged: !savedMapping.equals(editingMapping),
    };
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  render() {
    const hasEmptyChangedSince = this.state.editingMapping.some(
      (mapping) => mapping.get('changed_since') === '',
    );

    if (!this.state.isChanged) {
      return (
        <Button disabled bsStyle="success">
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
          Save &amp; Close
        </Button>
      );
    }

    return (
      <ButtonToolbar>
        <Button
          onClick={this.handleResetMapping}
          disabled={this.state.isSaving || !this.state.isChanged}
        >
          <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
          Reset
        </Button>
        <Button
          bsStyle="success"
          disabled={this.state.isSaving || !this.state.isChanged || hasEmptyChangedSince}
          onClick={() => this.saveMapping()}
        >
          {this.state.isSaving ? (
            <>
              <Loader className="icon-addon-right" />
              Saving...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
              Save &amp; Close
            </>
          )}
        </Button>
      </ButtonToolbar>
    );
  },

  saveMapping() {
    const configData = this.state.configData.setIn(
      ['storage', 'input', 'files'],
      this.state.editingMapping,
    );

    this.setState({ isSaving: true });
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update input mapping',
    )
      .then(() => {
        if (this.state.componentId === KEBOOLA_SANDBOXES) {
          RoutesStore.getRouter().transitionTo(workspaceRoutes.WORKSPACE, {
            config: this.state.configId,
          });
        } else {
          RoutesStore.getRouter().transitionTo(transformationRoutes.GENERIC_TRANSFORMATION_CONFIG, {
            component: this.state.componentId,
            config: this.state.configId,
          });
        }
        return null;
      })
      .catch((error) => {
        this.setState({ isSaving: false });

        throw error;
      });
  },

  handleResetMapping() {
    InstalledComponentsActionCreators.updateLocalState(
      this.state.componentId,
      this.state.configId,
      this.state.localState.delete('file-input-mapping'),
    );
  },
});

export default Index;
