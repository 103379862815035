import React from 'react';
import { Button } from 'react-bootstrap';
import type { List } from 'immutable';

type Props = {
  primaryKey: List<string> | undefined;
  onChange: (primaryKey: List<string>) => void;
};

const CopyPrimaryKeyButton = ({ primaryKey, onChange }: Props) => {
  if (!primaryKey) {
    return null;
  }

  return (
    <>
      <Button bsStyle="link" className="btn-link-inline" onClick={() => onChange(primaryKey!)}>
        Fill in the primary key
      </Button>{' '}
      from the Storage table.
    </>
  );
};

export default CopyPrimaryKeyButton;
