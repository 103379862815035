import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

class DeleteConfigurationRowButton extends React.Component {
  render() {
    if (this.props.mode === 'link') {
      return (
        <Button bsStyle="link" onClick={this.props.onClick} className="btn-block">
          {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          Delete Row
        </Button>
      );
    }

    return (
      <RowActionMenuItem
        onSelect={this.props.onClick}
        disabled={this.props.isPending}
        onKeyDown={this.props.onKeyDown}
      >
        {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        Delete row
      </RowActionMenuItem>
    );
  }
}

DeleteConfigurationRowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['menuitem', 'link']).isRequired,
  onKeyDown: PropTypes.func,
};

export default DeleteConfigurationRowButton;
