import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { routeNames as billingRouteNames } from '@/modules/billing/constants';
import { routeNames as settingsRouteNames } from '@/modules/settings/constants';
import { routeNames as telemetryRouteNames } from '@/modules/telemetry/routes';
import FragmentWithForwaredProps from '@/react/common/FragmentWithForwaredProps';
import Gravatar from '@/react/common/Gravatar';
import Truncated from '@/react/common/Truncated';
import contactSupport from '@/utils/contactSupport';
import LinkMenuItem from './LinkMenuItem';
import UserFeaturesModal from './UserFeaturesModal';

const CurrentUser = createReactClass({
  propTypes: {
    user: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired,
    canManageApps: PropTypes.bool.isRequired,
    hasPayAsYouGo: PropTypes.bool.isRequired,
    isMarketplaceProject: PropTypes.bool.isRequired,
    availableFeatures: PropTypes.instanceOf(List).isRequired,
    activatedFeatures: PropTypes.instanceOf(List).isRequired,
    isDevModeActive: PropTypes.bool,
    hasOrganizationUsage: PropTypes.bool.isRequired,
    hasActivityCentrum: PropTypes.bool.isRequired,
    hasInvalidCustomBackend: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isDevModeActive: false,
    };
  },

  getInitialState() {
    return {
      isOpen: false,
      showFeaturesModal: false,
    };
  },

  render() {
    return (
      <>
        <div className="user">
          <Dropdown
            id="current-user-dropdown"
            rootCloseEvent="mousedown"
            dropup={this.props.dropup}
            open={this.state.isOpen}
            onToggle={(isOpen, event, data) => {
              if (
                data.source === 'rootClose' &&
                event.composedPath?.().some((el) => el.id === 'current-user-dropdown')
              ) {
                return;
              }

              this.setState({ isOpen });
            }}
            pullRight
          >
            <Dropdown.Toggle
              noCaret
              bsStyle="link"
              className="tw-flex tw-h-10 tw-items-center tw-p-0 tw-text-left"
            >
              <Gravatar user={this.props.user} className="user-avatar" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="tw-mt-1 tw-min-w-[240px] tw-border tw-border-neutral-150 tw-p-2">
              <div className="tw-flex tw-flex-col tw-p-2">
                {!!this.props.user.get('name') && (
                  <Truncated
                    className="tw-text-base tw-font-medium"
                    text={this.props.user.get('name')}
                  />
                )}
                <Truncated
                  className="tw-text-xs tw-text-neutral-400"
                  text={this.props.user.get('email')}
                />
              </div>

              {!this.props.hasInvalidCustomBackend && (
                <FragmentWithForwaredProps>
                  {this.props.canManageApps && (
                    <MenuItem
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.urlTemplates.get('manageApps')}
                    >
                      Manage Applications
                    </MenuItem>
                  )}
                  <MenuItem
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.urlTemplates.get('changePassword')}
                  >
                    My Account &amp; Organizations
                  </MenuItem>
                  <MenuItem onClick={() => this.setState({ showFeaturesModal: true })}>
                    User Features
                  </MenuItem>
                  {this.renderDivider()}
                  <LinkMenuItem to={settingsRouteNames.PROJECT} forceProduction>
                    Project Settings
                  </LinkMenuItem>
                  <LinkMenuItem to={telemetryRouteNames.BILLING_CONSUMPTION}>
                    Project Consumption
                  </LinkMenuItem>
                  {this.props.hasOrganizationUsage && (
                    <LinkMenuItem to={telemetryRouteNames.ORGANIZATION_USAGE}>
                      Organization Usage
                    </LinkMenuItem>
                  )}
                  {this.props.hasActivityCentrum && (
                    <LinkMenuItem to={telemetryRouteNames.ACTIVITY_CENTER}>
                      Activity Center
                    </LinkMenuItem>
                  )}
                  {this.renderBillingLink()}
                  {this.renderDivider()}
                </FragmentWithForwaredProps>
              )}
              <MenuItem onClick={this.openSupportModal}>Support</MenuItem>
              <MenuItem href={URLS.USER_DOCUMENTATION} target="_blank" rel="noopener noreferrer">
                Help
              </MenuItem>
              <MenuItem href={URLS.ACADEMY} target="_blank" rel="noopener noreferrer">
                Academy
              </MenuItem>
              {this.renderDivider()}
              <MenuItem href={this.props.urlTemplates.get('logout')}>Logout</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <UserFeaturesModal
          show={this.state.showFeaturesModal}
          onHide={() => this.setState({ showFeaturesModal: false })}
          availableFeatures={this.props.availableFeatures}
          activatedFeatures={this.props.activatedFeatures}
        />
      </>
    );
  },

  renderBillingLink() {
    if (!this.props.hasPayAsYouGo || this.props.isMarketplaceProject) {
      return null;
    }

    return <LinkMenuItem to={billingRouteNames.BILLING_OVERVIEW}>Billing</LinkMenuItem>;
  },

  renderDivider() {
    return <MenuItem divider className="!tw-m-2 !tw-bg-neutral-200" />;
  },

  openSupportModal(e) {
    e.preventDefault();

    contactSupport();
  },
});

export default CurrentUser;
