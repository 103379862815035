import { fromJS } from 'immutable';

import { DEFAULT_CHUNK_SIZE } from '@/modules/wr-google-bigquery-v2/helpers/constants';

const createConfiguration = function (localState) {
  return fromJS({
    storage: {
      input: {
        tables: [
          {
            source: localState.get('source', ''),
            destination: localState.get('source') ? localState.get('source') + '.csv' : '',
          },
        ],
      },
    },
    parameters: {
      tables: [
        {
          dbName: localState.get('destination', ''),
          tableId: localState.get('source', ''),
          chunkSize: localState.get('chunkSize', DEFAULT_CHUNK_SIZE),
        },
      ],
    },
  });
};

const parseConfiguration = function (configuration) {
  return fromJS({
    destination: configuration.getIn(['parameters', 'tables', 0, 'dbName'], ''),
    source: configuration.getIn(['storage', 'input', 'tables', 0, 'source'], ''),
    chunkSize: configuration.getIn(['parameters', 'tables', 0, 'chunkSize'], DEFAULT_CHUNK_SIZE),
  });
};

const createEmptyConfiguration = function (tableId) {
  const tableName = tableId.substring(tableId.lastIndexOf('.') + 1);
  return createConfiguration(fromJS({ destination: tableName, source: tableId }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
