import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { refreshExternalBucket } from '@/modules/storage/actions';
import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

const RefreshExternalBucketMenuItem = (props: { bucket: Map<string, any> }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  if (!props.bucket.get('hasExternalSchema', false)) {
    return null;
  }

  const isDisabled = isLoading || props.bucket.has('sourceBucket');

  return (
    <RowActionMenuItem
      eventKey="refresh"
      disabled={isDisabled}
      disabledReason={
        isLoading
          ? 'Refreshing bucket...'
          : 'Refresh operation is blocked. Ask owner of the source bucket to refresh it.'
      }
      onSelect={() => {
        setIsLoading(true);
        refreshExternalBucket(props.bucket.get('id')).finally(() => setIsLoading(false));
      }}
      onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
    >
      {isLoading ? (
        <Loader className="icon-addon-right" />
      ) : (
        <FontAwesomeIcon icon="rotate" fixedWidth className="icon-addon-right" />
      )}
      Refresh bucket
    </RowActionMenuItem>
  );
};

export default RefreshExternalBucketMenuItem;
