import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';

import { stringifyCron } from '@/modules/orchestrations-v2/helpers';
import { displayTimezone } from '@/modules/orchestrations-v2/timezones';

const CronRecord = createReactClass({
  propTypes: {
    crontabRecord: PropTypes.string,
    crontabTimezone: PropTypes.string,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      crontabTimezone: 'UTC',
    };
  },

  render() {
    if (!this.props.crontabRecord) {
      return <span className="text-muted">No Schedule</span>;
    }

    if (this.props.disabled) {
      return (
        <Tooltip
          placement="top"
          tooltip="Scheduler is not enabled."
          type="explanatory"
          triggerClassName="text-muted"
        >
          {stringifyCron(this.props.crontabRecord)} ({displayTimezone(this.props.crontabTimezone)})
        </Tooltip>
      );
    }

    return (
      <span className="text-muted">
        {stringifyCron(this.props.crontabRecord)}
        {` (${displayTimezone(this.props.crontabTimezone)})`}
      </span>
    );
  },
});

export default CronRecord;
