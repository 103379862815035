import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const CopyTransformationModal = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    transformationBuckets: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      isLoading: false,
      name: `${this.props.transformation.get('name')} (Copy)`,
      bucket: this.props.bucketId,
    };
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Modal show={this.props.show} onHide={this.props.onHide}>
          <Form onSubmit={this.onSubmit} horizontal>
            <Modal.Header closeButton>
              <Modal.Title>Copy Transformation {this.props.transformation.get('name')}</Modal.Title>
              <ModalIcon color="green" icon="clone" bold />
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <div className="col-xs-3">
                  <ControlLabel>Name</ControlLabel>
                </div>
                <div className="col-xs-9">
                  <FormControl
                    autoFocus
                    type="text"
                    value={this.state.name}
                    onChange={this.handleName}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className="col-xs-3">
                  <ControlLabel>Target Bucket</ControlLabel>
                </div>
                <div className="col-xs-9">
                  <Select
                    clearable={false}
                    value={this.state.bucket}
                    onChange={this.handleBucket}
                    options={this.bucketsOptions()}
                  />
                </div>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                isSaving={this.state.isLoading}
                isDisabled={this.isDisabled()}
                saveLabel="Copy"
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </span>
    );
  },

  bucketsOptions() {
    return this.props.transformationBuckets
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucket.get('name'),
      }))
      .toArray();
  },

  isDisabled() {
    return this.state.isLoading || !this.state.name || !this.state.bucket;
  },

  onSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });
    ActionCreators.createTransformationWithoutRedirect(
      this.state.bucket,
      this.props.transformation.delete('id').set('name', this.state.name),
    )
      .then(this.props.onHide)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  },

  handleName(e) {
    this.setState({ name: e.target.value });
  },

  handleBucket(selected) {
    this.setState({ bucket: selected });
  },
});

export default CopyTransformationModal;
