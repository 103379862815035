import React from 'react';
import type { List, Map } from 'immutable';

import { JOBS_LIMIT_FOR_RUN_RESULTS, routeNames } from '@/modules/queue/constants';
import { prepareGraphData } from '@/modules/queue/helpers';
import { JOBS_GRAPH_COLORS } from '@/react/common/Graph/constants';
import JobsGraphTooltip from '@/react/common/Graph/JobsGraphTooltip';
import MiniBarGraph from '@/react/common/Graph/MiniBarGraph';
import type { GraphJob } from '@/react/common/Graph/types';
import RoutesStore from '@/stores/RoutesStore';

const RunResults = (props: { jobs?: List<Map<string, any>> }) => {
  const data = React.useMemo(() => {
    if (!props.jobs) {
      return null;
    }

    return prepareGraphData(props.jobs, { onlyJobs: true, limit: JOBS_LIMIT_FOR_RUN_RESULTS });
  }, [props.jobs]);

  if (!data || data.get('jobs').isEmpty()) {
    return <span className="text-muted">No run yet</span>;
  }

  return (
    <MiniBarGraph
      records={data.get('jobs').toJS() as GraphJob[]}
      dataKey="duration"
      getColor={(record) => JOBS_GRAPH_COLORS[record.status]}
      renderTooltip={(record) => <JobsGraphTooltip job={record} />}
      onRecordClick={(record) => {
        RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, { jobId: record.jobId });
      }}
    />
  );
};

export default RunResults;
