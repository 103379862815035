import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Modal, Radio } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { HelpBlock, Link } from 'design';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import ExtendJulipyterCredentials from '@/modules/provisioning/react/components/ExtendJulipyterCredentials';
import ExtendJupyterCredentials from '@/modules/provisioning/react/components/ExtendJupyterCredentials';
import ExtendRStudioCredentials from '@/modules/provisioning/react/components/ExtendRStudioCredentials';
import { backends, transformationType } from '@/modules/transformations/Constants';
import DockerCredentialsContainer from '@/modules/transformations/react/components/DockerCredentialsContainer';
import RedshiftCredentialsContainer from '@/modules/transformations/react/components/RedshiftCredentialsContainer';
import SnowflakeCredentialsContainer from '@/modules/transformations/react/components/SnowflakeCredentialsContainer';
import { getDockerConnectLink } from '@/modules/transformations/utils/sandboxUtils';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import RouterLink from '@/react/common/RouterLink';

const ConfigureSandboxModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    backend: PropTypes.string.isRequired,
    isRunning: PropTypes.bool.isRequired,
    isCreated: PropTypes.bool.isRequired,
    canDockerExtend: PropTypes.bool.isRequired,
    transformationType: PropTypes.string.isRequired,
    redshiftCredentials: PropTypes.object.isRequired,
    snowflakeCredentials: PropTypes.object.isRequired,
    dockerCredentials: PropTypes.object.isRequired,
    dockerPendingActions: PropTypes.object.isRequired,
    isLoadingDockerCredentials: PropTypes.bool.isRequired,
    isPythonTransformation: PropTypes.bool.isRequired,
    isJuliaTransformation: PropTypes.bool.isRequired,
    onModeChange: PropTypes.func.isRequired,
    onCreateStart: PropTypes.func.isRequired,
    onDropStart: PropTypes.func.isRequired,
    progress: PropTypes.string,
    progressStatus: PropTypes.string,
    jobId: PropTypes.string,
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Modal show={this.props.show} onHide={this.props.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Sandbox</Modal.Title>
            <ModalIcon color="green" icon="wrench" bold />
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal">
              {this.renderModeSelect()}
              {this.renderCredentials()}
              {this.renderProgressInfo()}
            </form>
          </Modal.Body>
          <Modal.Footer>{this.renderButtons()}</Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderModeSelect() {
    if (this.props.backend === backends.DOCKER) {
      return null;
    }

    return (
      <>
        <p>Disabled transformations will not be executed.</p>
        <FormGroup>
          <Radio
            value="input"
            checked={this.props.mode === 'input'}
            onChange={(event) => this.props.onModeChange(event.target.value)}
          >
            Load input tables only
          </Radio>
        </FormGroup>
        <FormGroup>
          <Radio
            value="prepare"
            checked={this.props.mode === 'prepare'}
            onChange={(event) => this.props.onModeChange(event.target.value)}
          >
            Prepare transformation
          </Radio>
          <HelpBlock>Load input tables AND execute required transformations</HelpBlock>
        </FormGroup>
        <FormGroup>
          <Radio
            value="dry-run"
            checked={this.props.mode === 'dry-run'}
            onChange={(event) => this.props.onModeChange(event.target.value)}
          >
            Execute transformation without writing to Storage
          </Radio>
        </FormGroup>
      </>
    );
  },

  renderCredentials() {
    if (this.props.backend === backends.DOCKER) {
      return <DockerCredentialsContainer type={this.props.transformationType} isAutoLoad />;
    }

    if (![backends.REDSHIFT, backends.SNOWFLAKE].includes(this.props.backend)) {
      return null;
    }

    return (
      <>
        <h2>Credentials</h2>
        {this.props.backend === backends.REDSHIFT && <RedshiftCredentialsContainer isAutoLoad />}
        {this.props.backend === backends.SNOWFLAKE && <SnowflakeCredentialsContainer isAutoLoad />}
      </>
    );
  },

  renderProgressInfo() {
    if (!this.props.progress) {
      return null;
    }

    return (
      <div
        className={classnames('mt-1', {
          'text-success': this.props.progressStatus === 'success',
          'text-danger': this.props.progressStatus === 'danger',
        })}
      >
        {this.renderStatusIcon()}
        {this.props.progress}{' '}
        {this.props.jobId && (
          <RouterLink to={jobsRouteNames.DETAIL} params={{ jobId: this.props.jobId }}>
            More details
          </RouterLink>
        )}
      </div>
    );
  },

  renderButtons() {
    return (
      <div className="btn-toolbar">
        <ConfirmButtons
          showCancel={false}
          showSave={this.showCreateSandbox()}
          saveLabel={this.props.backend === backends.DOCKER ? 'Create Sandbox' : 'Load Data'}
          onSave={this.props.onCreateStart}
          isDisabled={
            this.props.isRunning ||
            (this.props.backend !== backends.DOCKER && !this.hasCredentials())
          }
        />
        {this.renderSnowflakeButtons()}
        {this.renderDockerButtons()}
      </div>
    );
  },

  renderSnowflakeButtons() {
    if (this.props.backend !== backends.SNOWFLAKE || !this.props.snowflakeCredentials.get('id')) {
      return null;
    }

    return (
      <Link
        href={`https://${this.props.snowflakeCredentials.get(
          'hostname',
        )}/console/login#/?returnUrl=sql/worksheet`}
        className="btn btn-primary"
      >
        <FontAwesomeIcon icon="database" className="icon-addon-right" />
        Connect
      </Link>
    );
  },

  renderDockerButtons() {
    if (this.props.backend !== backends.DOCKER || !this.props.dockerCredentials.get('id')) {
      return null;
    }

    return (
      <>
        <Button
          disabled={this.props.dockerPendingActions.get('drop')}
          bsStyle="danger"
          onClick={this.props.onDropStart}
        >
          {this.props.dockerPendingActions.get('drop') ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="trash" className="icon-addon-right" />
          )}
          Drop Sandbox
        </Button>
        {this.renderDockerExtendCrendetials()}
        <Link
          href={getDockerConnectLink(this.props.dockerCredentials, this.getDockerType())}
          className={classnames('btn btn-primary', {
            disabled: this.props.dockerPendingActions.get('drop'),
          })}
        >
          <FontAwesomeIcon icon="database" className="icon-addon-right" />
          Connect
        </Link>
      </>
    );
  },

  renderStatusIcon() {
    if (this.props.isRunning) {
      return <Loader className="icon-addon-right" />;
    }

    switch (this.props.progressStatus) {
      case 'success':
        return <FontAwesomeIcon icon="check" className="icon-addon-right" />;
      case 'danger':
        return <FontAwesomeIcon icon="xmark" className="icon-addon-right" />;
      default:
        return null;
    }
  },

  renderDockerExtendCrendetials() {
    if (this.props.isPythonTransformation) {
      return (
        <ExtendJupyterCredentials buttonStyle="primary" canExtend={this.props.canDockerExtend} />
      );
    }

    if (this.props.isJuliaTransformation) {
      return (
        <ExtendJulipyterCredentials buttonStyle="primary" canExtend={this.props.canDockerExtend} />
      );
    }

    return (
      <ExtendRStudioCredentials buttonStyle="primary" canExtend={this.props.canDockerExtend} />
    );
  },

  getDockerType() {
    if (this.props.isPythonTransformation) {
      return transformationType.PYTHON;
    }

    if (this.props.isJuliaTransformation) {
      return transformationType.JULIA;
    }

    return transformationType.R;
  },

  showCreateSandbox() {
    if (this.props.backend !== backends.DOCKER) {
      return !this.props.isCreated;
    }

    return !this.props.isLoadingDockerCredentials && !this.hasCredentials();
  },

  hasCredentials() {
    switch (this.props.backend) {
      case backends.REDSHIFT:
        return this.props.redshiftCredentials.get('id');
      case backends.SNOWFLAKE:
        return this.props.snowflakeCredentials.get('id');
      case backends.DOCKER:
        return this.props.dockerCredentials.get('id');
      default:
        return true;
    }
  },
});

export default ConfigureSandboxModal;
