import { List, Map } from 'immutable';

const prepareSourceMapping = (selected, currentMapping, tables) => {
  const table = tables.get(selected);

  if (table) {
    return currentMapping.withMutations((mapping) =>
      mapping
        .set('source', table.get('id'))
        .set('destination', table.get('displayName'))
        .set('datatypes', Map())
        .delete('tables')
        .delete('changedSince')
        .set('whereColumn', '')
        .set('whereValues', List())
        .set('whereOperator', 'eq')
        .set('columns', List())
        .set('sortKey', '')
        .set('distKey', ''),
    );
  }

  return currentMapping.withMutations((mapping) =>
    mapping
      .set('source', selected)
      .set(
        'tables',
        tables
          .filter((table) => table.getIn(['bucket', 'id']) === selected)
          .map((table) =>
            Map({
              source: table.get('id'),
              destination: table.get('displayName'),
              datatypes: Map(),
            }),
          ),
      )
      .delete('changedSince')
      .set('whereColumn', '')
      .set('whereValues', List())
      .set('whereOperator', 'eq')
      .set('columns', List())
      .set('sortKey', '')
      .set('distKey', ''),
  );
};

export { prepareSourceMapping };
