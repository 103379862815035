import React from 'react';
import type { List, Map } from 'immutable';

import {
  FEATURE_EXTERNAL_BUCKETS,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_SANDBOXES_DATA_APP,
  FEATURE_SNOWPARK_PYTHON,
} from '@/constants/features';
import { addProjectFeature, removeProjectFeature } from '@/modules/settings/actions';
import { FEATURE_TYPES } from '@/modules/settings/constants';
import SettingsStore from '@/modules/settings/SettingsStore';
import FeatureList from '@/react/common/FeatureList';
import ApplicationStore from '@/stores/ApplicationStore';

const isApprovedFeature = (feature: Map<string, any>) => {
  const name = feature.get('name');

  if (
    // No features for legacy queue
    !ApplicationStore.hasNewQueue() ||
    // Some features require a read only storage
    ([FEATURE_SNOWPARK_PYTHON].includes(name) && !ApplicationStore.hasReadOnlyStorage()) ||
    // Data Apps are not available for SOX projects
    (ApplicationStore.hasProtectedDefaultBranch() && name === FEATURE_SANDBOXES_DATA_APP) ||
    // Backend specific
    (SettingsStore.hasProjectOwnBackend() && name === FEATURE_EXTERNAL_BUCKETS)
  ) {
    return false;
  }

  return [
    FEATURE_HAS_FLOWS_ONLY,
    FEATURE_SNOWPARK_PYTHON,
    FEATURE_SANDBOXES_DATA_APP,
    FEATURE_EXTERNAL_BUCKETS,
  ].includes(name);
};

const EditableFeatures = (props: {
  readOnly: boolean;
  activatedFeatures: List<string>;
  availableFeatures: List<any>;
}) => {
  const availableFeatures = props.availableFeatures
    .filter((feature) => feature.get('type') === FEATURE_TYPES.PROJECT)
    .filter(isApprovedFeature) as List<any>;

  if (availableFeatures.isEmpty()) {
    return null;
  }

  return (
    <div className="well pp-6">
      <h3 className="tw-mb-6 tw-mt-0">Features</h3>
      <FeatureList
        readOnly={props.readOnly}
        availableFeatures={availableFeatures}
        activatedFeatures={props.activatedFeatures}
        onAddFeature={addProjectFeature}
        onRemoveFeature={removeProjectFeature}
      />
    </div>
  );
};

export default EditableFeatures;
