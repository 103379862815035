import '@/utils/codemirror/setup';

import React from 'react';
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ReactDOM from 'react-dom';
import { URLS } from '@keboola/constants';
import { HelpBlock, KeyCode, Link } from 'design';

import nextTick from '@/utils/nextTick';

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.editorDidMount = this.editorDidMount.bind(this);
    this.onBeforeChange = this.onBeforeChange.bind(this);
    this.editorRef = React.createRef();
  }

  render() {
    return (
      <>
        <CodeMirror
          value={this.props.value}
          options={{
            ...(this.props.withSearch
              ? { enableSearch: true }
              : {
                  extraKeys: {
                    'Ctrl-F': false,
                    'Cmd-F': false,
                    'Ctrl-G': false,
                    'Cmd-G': false,
                    'Shift-Ctrl-G': false,
                    'Shift-Cmd-G': false,
                    ...this.props.options?.extraKeys,
                  },
                }),
            ...this.props.options,
          }}
          editorDidMount={this.editorDidMount}
          onBeforeChange={this.onBeforeChange}
          className={this.props.className}
        />
        {(this.props.withAutocomplete || this.props.withToggleComment || this.props.help) &&
          !this.props.options?.readOnly && (
            <HelpBlock>
              {this.props.help}
              {this.props.withAutocomplete && (
                <>
                  {!!this.props.help ? ' ' : ''}
                  Use <KeyCode>Ctrl+Space</KeyCode> or <KeyCode>Option+Space</KeyCode> to trigger{' '}
                  <Link href={`${URLS.USER_DOCUMENTATION}/transformations/#autocompletion`}>
                    autocomplete
                  </Link>
                  .
                </>
              )}
              {this.props.withToggleComment && (
                <>
                  {!!this.props.help || this.props.withAutocomplete ? ' ' : ''}
                  Use <KeyCode>Ctrl+/</KeyCode> or <KeyCode>Command+/</KeyCode> to toggle comments.
                </>
              )}
            </HelpBlock>
          )}
      </>
    );
  }

  onBeforeChange(editor, data, value) {
    if (this.props.onChange) {
      ReactDOM.flushSync(() => this.props.onChange(value));
    }
  }

  editorDidMount(editor) {
    this.editorRef.current = editor;

    if (this.props.value) {
      nextTick(() => editor.refresh());
    }

    if (this.props.autoFocus) {
      editor.focus();
    }

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor);
    }

    if (this.props.withSearch) {
      editor.execCommand('find');
    }

    if (editor.options.mode === 'application/json' && !editor.options.readOnly) {
      editor.setOption('lint', true);
    }
  }
}

CodeEditor.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.object,
  onChange: PropTypes.func,
  editorDidMount: PropTypes.func,
  help: PropTypes.node,
  autoFocus: PropTypes.bool,
  withAutocomplete: PropTypes.bool,
  withToggleComment: PropTypes.bool,
  withSearch: PropTypes.bool,
  className: PropTypes.string,
};

export default CodeEditor;
