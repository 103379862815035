import React from 'react';
import { Map } from 'immutable';

import BillingStore from '@/modules/billing/store';
import useStores from '@/react/hooks/useStores';
import AccountPlanBox from './AccountPlanBox';
import BillingInformation from './BillingInformation';
import CreditCardBox from './CreditCardBox';
import CreditsBalanceBox from './CreditsBalanceBox';
import LatestPurchases from './LatestPurchases';

const Overview = () => {
  const state = useStores(
    () => {
      return {
        creditPrice: BillingStore.getCreditPrice(),
        remainingCredits: BillingStore.getRemainingCredits(),
        isLoadingCredits: BillingStore.isLoadingCredits(),
        billingInformation: BillingStore.getBillingInformation(),
        purchases: BillingStore.getPurchases(),
        topUpSetting: BillingStore.getTopUpSetting(),
      };
    },
    [],
    [BillingStore],
  );

  return (
    <>
      <div className="box-container two-columns">
        <CreditsBalanceBox
          simple
          creditsCount={state.remainingCredits}
          isLoadingCredits={state.isLoadingCredits}
        />
        <AccountPlanBox />
        <CreditCardBox
          creditPrice={state.creditPrice}
          topUpSetting={state.topUpSetting}
          purchases={state.purchases}
          card={state.billingInformation.getIn(['cards', 0], Map())}
        />
        <BillingInformation billingInformation={state.billingInformation} />
      </div>
      <LatestPurchases purchases={state.purchases} />
    </>
  );
};

export default Overview;
