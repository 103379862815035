import React from 'react';
import { getIncomers, getOutgoers, useReactFlow } from '@xyflow/react';
import { Link } from 'design';

import { parseFQID } from '@/modules/lineage/fqid';
import type { CustomEdge, CustomNode, NodeData, OnSelectProject } from '@/modules/lineage/rfTypes';
import RowWithRenderer from './RowWithRenderer';

const ConnectedTo = ({
  node,
  projectId,
  nodeProjectId,
  onSelectProject,
}: {
  node: NodeData;
  projectId: string | null;
  nodeProjectId: string | null;
  onSelectProject: OnSelectProject;
}) => {
  const { getNodes, getEdges } = useReactFlow<CustomNode, CustomEdge>();

  const neighbours = [
    ...getIncomers({ id: node.fqid }, getNodes(), getEdges()),
    ...getOutgoers({ id: node.fqid }, getNodes(), getEdges()),
  ];

  const connectedProjectsOfNode = neighbours
    .map((node) => {
      const parsed = parseFQID(node.id);
      const foundNode = getNodes().find((n) => n.id === node.id);
      return {
        id: parsed?.projectId ?? null,
        name: foundNode && 'projectName' in foundNode.data ? foundNode.data.projectName : '',
      };
    })
    .filter(
      (prj1, i, arr) =>
        prj1.id !== null &&
        prj1.name &&
        prj1.id !== projectId &&
        prj1.id !== nodeProjectId &&
        arr.findIndex((prj2) => prj2.id === prj1.id) === i,
    ) as { id: string; name: string }[];

  if (connectedProjectsOfNode.length === 0) {
    return null;
  }

  return (
    <RowWithRenderer title="Connected to" value={connectedProjectsOfNode}>
      {(value) => (
        <div className="tw-flex tw-flex-col">
          {value?.map((project) => (
            <Link
              key={project.id}
              className="btn btn-link btn-link-inline text-muted tw-break-all !tw-text-right"
              onClick={(event) => onSelectProject(project.name, project.id, node.fqid, event)}
            >
              {project.name}
            </Link>
          ))}
        </div>
      )}
    </RowWithRenderer>
  );
};

export default ConnectedTo;
