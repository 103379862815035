import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { FEATURE_CAN_UPDATE_PROJECT_SETTINGS } from '@/constants/features';
import UpdateProjectModal from './UpdateProjectModal';

class UpdateProjectButton extends React.Component {
  state = {
    showModal: false,
  };

  render() {
    return (
      <>
        <Button
          bsStyle="link"
          className="btn-link-muted btn-link-inline icon-addon-left"
          onClick={() => this.setState({ showModal: true })}
        >
          <FontAwesomeIcon icon="pen" />
        </Button>
        <UpdateProjectModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          project={this.props.project}
          projectTemplates={this.props.projectTemplates}
          isSuperAdmin={this.props.user.get('isSuperAdmin', false)}
          canUpdateTypeAndExpiration={this.props.user
            .get('features', List())
            .includes(FEATURE_CAN_UPDATE_PROJECT_SETTINGS)}
          onSubmit={this.props.onSubmit}
        />
      </>
    );
  }
}

UpdateProjectButton.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  projectTemplates: PropTypes.instanceOf(List).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateProjectButton;
