import { fromJS } from 'immutable';

export default fromJS({
  BIT: {
    name: 'BIT',
    basetype: 'BOOLEAN',
    size: false,
  },
  BIGINT: {
    name: 'BIGINT',
    basetype: 'INTEGER',
    size: false,
  },
  DECIMAL: {
    name: 'DECIMAL',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '38,38',
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  DATETIME2: {
    name: 'DATETIME2',
    basetype: 'TIMESTAMP',
    size: false,
  },
  TEXT: {
    name: 'TEXT',
    basetype: 'STRING',
    size: false,
  },
});
