import React from 'react';
import PropTypes from 'prop-types';

const renderCount = (count, name) => {
  return (
    <>
      {count} {name}
      {count > 1 && 's'}
    </>
  );
};

const TasksSummary = ({ phases }) => {
  if (!phases.count()) {
    return 'No assigned tasks';
  }

  return (
    <>
      {renderCount(phases.flatMap((phase) => phase.get('tasks')).count(), 'task')}
      {' in '}
      {renderCount(phases.count(), 'phase')}
    </>
  );
};

TasksSummary.propTypes = {
  phases: PropTypes.object.isRequired,
};

export default TasksSummary;
