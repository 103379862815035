import { MarkerType } from '@xyflow/react';

import type { CustomNode } from '@/modules/lineage/rfTypes';

export const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'RIGHT',
  'elk.layered.spacing.nodeNodeBetweenLayers': '160',
  'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
  'elk.spacing.nodeNode': '30',
  'elk.spacing.edgeEdge': '0',
  'elk.layered.spacing.edgeEdgeBetweenLayers': '0.0',
  'elk.spacing.componentComponent': '50',
  'elk.layered.considerModelOrder.components': 'GROUP_MODEL_ORDER',
  'elk.aspectRatio': '1.5',
};

export const initNodesProps = {
  position: { x: 0, y: 0 },
};

export const initEdgesProps = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
  },
};

export const zoomOptions = {
  minZoom: 0.05,
  maxZoom: 1.2,
};

export const getFitViewOptions = (
  centerNode: { id: CustomNode['id'] } | null,
  nodes?: CustomNode[],
) => {
  const bigGraph = nodes && nodes.length > 300;

  return {
    duration: nodes ? 0 : 400,
    minZoom: centerNode ? zoomOptions.maxZoom : zoomOptions.minZoom,
    maxZoom: zoomOptions.maxZoom,
    nodes: centerNode ? [centerNode] : bigGraph ? [nodes[0]] : nodes,
  };
};

export const proOptions = { hideAttribution: true };
