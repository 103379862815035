import { KEBOOLA_ORACLE_TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import * as actionsProvisioning from '@/modules/ex-db-generic/actionsProvisioning';
import ExDbCredentialsPage from '@/modules/ex-db-generic/react/pages/credentials/CredentialsPage';
import * as storeProvisioning from '@/modules/ex-db-generic/storeProvisioning';
import * as credentialsTemplate from '@/modules/ex-db-generic/templates/credentials';
import { routeNames } from './constants';

export default {
  name: routeNames.TRANSFORMATION_CREDENTIALS,
  path: 'credentials',
  defaultRouteHandler: ExDbCredentialsPage(
    KEBOOLA_ORACLE_TRANSFORMATION,
    actionsProvisioning,
    storeProvisioning,
    credentialsTemplate,
    componentTypes.TRANSFORMATION,
  ),
  title: 'Credentials',
};
