import React, { useState } from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import type { InstanceDetail, Template } from '@/api/routes/templatesService';
import { routeNames } from '@/modules/templates/constants';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  showModal: boolean;
  onHide: () => void;
  templateDetail: Template | InstanceDetail['templateDetail'];
  templateVersion?: string;
};

const NewInstanceModal = ({ showModal, onHide, templateDetail, templateVersion }: Props) => {
  const [instanceName, setInstanceName] = useState('');
  const [pending, setPending] = useState(false);
  const [instanceVersion, setInstanceVersion] = useState<string | null>(null);

  return (
    <>
      <Modal
        show={showModal}
        onHide={onHide}
        onEnter={() => {
          setInstanceName('');
          setInstanceVersion(templateVersion || templateDetail.defaultVersion);
        }}
      >
        <Form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setPending(true);
            return RoutesStore.getRouter().transitionTo(
              routeNames.INSTANCE_NEW,
              {
                templateId: templateDetail.id,
              },
              {
                n: instanceName,
                v: instanceVersion,
                flowId: RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']),
              },
            );
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{templateDetail.name}</Modal.Title>
            <ModalIcon icon="book-open" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p className="text-muted tw-mb-6">
              The template creates a customizable flow(s). Each template can be used multiple times
              with different component configurations.
            </p>
            <FormGroup>
              <ControlLabel>Template Configuration Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={instanceName}
                placeholder="My New Template"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setInstanceName(target.value)
                }
              />
            </FormGroup>
            {'versions' in templateDetail && !!templateDetail.versions?.slice(1)?.length && (
              <FormGroup>
                <ControlLabel>Template Version</ControlLabel>
                <Select
                  clearable={false}
                  searchable={false}
                  options={templateDetail.versions?.map(({ version }) => ({
                    value: version,
                    label: version,
                  }))}
                  value={instanceVersion}
                  onChange={(value: string) => setInstanceVersion(value)}
                />
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              bsStyle="success"
              disabled={!instanceName.trim() || pending}
              block
            >
              {pending && <Loader className="icon-addon-right" />}
              Set Up Template
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NewInstanceModal;
