import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import RetryButton from '@/modules/flows/components/RetryButton';
import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import NotificationsStore from '@/modules/notifications/store';
import ActionCreators from '@/modules/queue/actions';
import { JOBS_STATUS } from '@/modules/queue/constants';
import JobsStore from '@/modules/queue/store';
import TerminateButton from '@/react/common/JobTerminateButton';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const JobDetailButtons = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      JobsStore,
      InstalledComponentsStore,
      NotificationsStore,
      ComponentsStore,
    ),
  ],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteParam('jobId');
    const job = JobsStore.get(jobId);
    const config = InstalledComponentsStore.getConfig(job.get('component'), job.get('config'));

    return {
      job,
      config,
      isTerminating:
        JobsStore.getPendingActions().getIn(['terminating', jobId], false) ||
        job.get('status') === JOBS_STATUS.TERMINATING,
      sapiToken: ApplicationStore.getSapiToken(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      allComponents: ComponentsStore.getAll(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  render() {
    return (
      <ButtonToolbar>
        <RetryButton
          mode="button"
          latestJob={this.state.job}
          config={this.state.config}
          sapiToken={this.state.sapiToken}
          allComponents={this.state.allComponents}
          allInstalledComponents={this.state.allConfigurations}
          hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        />
        <TerminateButton
          job={this.state.job}
          sapiToken={this.state.sapiToken}
          isTerminating={this.state.isTerminating}
          onTerminate={this.handleTerminate}
        />
        <OneTimeNotificationButton
          job={this.state.job}
          notifications={this.state.notifications}
          admin={this.state.currentAdmin}
        />
      </ButtonToolbar>
    );
  },

  handleTerminate() {
    return ActionCreators.terminateJob(this.state.job.get('id'));
  },
});

export default JobDetailButtons;
