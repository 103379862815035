import React from 'react';
import { cn } from 'design';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
  size?: Size;
  className?: string;
  disableAnimation?: boolean;
};

const sizeClassName = {
  xs: 'tw-h-4 tw-rounded',
  sm: 'tw-h-5 tw-rounded',
  md: 'tw-h-6 tw-rounded-md',
  lg: 'tw-h-8 tw-rounded-lg',
  xl: 'tw-h-10 tw-rounded-lg',
};

export const SkeletonBar = ({ size = 'md', disableAnimation = false, className }: Props) => (
  <div
    className={cn(
      'tw-bg-neutral-150',
      !disableAnimation && 'animation-bar-core',
      sizeClassName[size],
      className,
    )}
  />
);
