import { fromJS } from 'immutable';

export default function (configuration) {
  let conformed = configuration;

  if (!configuration.hasIn(['parameters', 'workbook', 'metadata'])) {
    conformed = conformed.setIn(['parameters', 'workbook', 'metadata'], fromJS({ name: '' }));
  }

  if (!configuration.hasIn(['parameters', 'worksheet', 'metadata'])) {
    conformed = conformed.setIn(['parameters', 'worksheet', 'metadata'], fromJS({ name: '' }));
  }

  return conformed;
}
