import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { IS_DEVELOPMENT } from '@/constants/environment';
import ApplicationStore from '@/stores/ApplicationStore';

const redirectToProjects = () => {
  window.location.href = '/admin/projects';
};

const catchSessionTokenError = (error, isPoll) => {
  if (ApplicationStore.isPreview()) {
    return;
  }

  if (IS_DEVELOPMENT) {
    if (!isPoll) {
      ApplicationActionCreators.sendNotification({
        timeout: 6000,
        type: 'warning',
        message: 'Failed to create local session token - missing Manage Api Token.',
        id: 'sessionTokenCreationFailed',
      });
    }
    return;
  }

  throw error;
};

export { redirectToProjects, catchSessionTokenError };
