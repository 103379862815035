import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Alert } from 'design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const DeleteBucketModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    bucket: PropTypes.object.isRequired,
    deleting: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    tables: PropTypes.object,
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{this.renderTitle()}</Modal.Title>
            <ModalIcon.Trash />
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to {this.props.bucket.has('sourceBucket') ? 'unlink' : 'delete'}{' '}
              the bucket <b>{this.props.bucket.get('displayName')}</b>?
            </p>
            <DevBranchStorageWarning
              message={`The bucket will also be ${
                this.props.bucket.has('sourceBucket') ? 'unlinked' : 'deleted'
              } in production. `}
              hasProductionEntity={!isCreatedInDevBranch(this.props.bucket)}
            />
            {this.renderWarning()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.isPending()}
              saveLabel={this.saveLabel()}
              saveStyle="danger"
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderTitle() {
    if (this.props.bucket.has('sourceBucket')) {
      return <span>Unlink bucket</span>;
    }

    return <span>Delete bucket</span>;
  },

  renderWarning() {
    if (this.props.bucket.has('sourceBucket') || this.props.tables.count() === 0) {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        The bucket is not empty. All tables will also be deleted!
      </Alert>
    );
  },

  saveLabel() {
    if (this.props.bucket.has('sourceBucket')) {
      return this.props.deleting ? 'Unlinking...' : 'Unlink';
    }

    return this.props.deleting ? 'Deleting...' : 'Delete';
  },

  isPending() {
    return this.props.deleting;
  },

  onSubmit(event) {
    event.preventDefault();

    return this.props.onConfirm();
  },
});

export default DeleteBucketModal;
