import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import walkthroughVideoPreview from '../../../images/walkthrough-video-preview.png';

import { loadAsync } from '@/utils/external/loadAsync';
import CircleIcon from './CircleIcon';

const YOUTUBE_VIDEOS = {
  keboola_walkthrough: {
    title: 'Keboola Walk-through Video',
    id: 'cnvXW7v9jy8',
    previewImage: walkthroughVideoPreview,
  },
} as const;

export const VideoWidget = ({ type }: { type: keyof typeof YOUTUBE_VIDEOS }) => {
  const [showModal, setShowModal] = useState(false);
  const { id, title, previewImage } = YOUTUBE_VIDEOS[type];

  return (
    <div className="video-widget-wrapper">
      <Button bsStyle="link" onClick={() => setShowModal(true)}>
        <img src={previewImage} loading="lazy" width="182" />
        <CircleIcon icon="play" bold />
      </Button>
      <VideoWidgetModal show={showModal} onHide={() => setShowModal(false)} id={id} title={title} />
    </div>
  );
};

type Props = {
  show: boolean;
  onHide: (show: boolean) => void;
  id: string;
  title: string;
};

export const VideoWidgetModal = ({ show, onHide, id, title }: Props) => {
  const initPlayer = () => {
    loadAsync({ id: 'youtube_iframe_api', src: 'https://www.youtube.com/iframe_api' }).then(() => {
      window.YT?.ready(() => {
        new window.YT.Player('youtube-iframe', {
          events: {
            onReady: (event: any) => event.target.playVideo(),
          },
        });
      });
    });
  };

  return (
    <Modal
      show={show}
      onEnter={() => initPlayer()}
      onHide={onHide}
      bsSize="large"
      className="video-widget-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ptp-8 pbp-3">
        <iframe
          allowFullScreen
          id="youtube-iframe"
          width="800"
          height="471"
          src={`https://www.youtube-nocookie.com/embed/${id}?enablejsapi=1`}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};
