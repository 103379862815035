import { fromJS, List } from 'immutable';

import dispatcher from '@/Dispatcher';
import { unhandledRequest } from '@/modules/components/DockerActionsApi';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_BAD_REQUEST } from '@/utils/errors/helpers';
import nextTick from '@/utils/nextTick';
import ConfigurationRowsStore from './ConfigurationRowsStore';
import * as Constants from './DockerActionsConstants';
import Store from './DockerActionsStore';

export default {
  callAction: function (componentId, actionName, body) {
    const cacheId = fromJS(body.toJS()).hashCode();

    dispatcher.handleViewAction({
      type: Constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN,
      component: componentId,
      actionName: actionName,
      cacheId: cacheId,
    });
    return unhandledRequest(componentId, actionName, body.toJS())
      .then((response) => {
        dispatcher.handleViewAction({
          type: Constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN_SUCCESS,
          component: componentId,
          actionName: actionName,
          cacheId: cacheId,
          response: response,
          requestBody: body,
        });
        return response;
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: Constants.ActionTypes.DOCKER_RUNNER_SYNC_ACTION_RUN_ERROR,
          component: componentId,
          actionName: actionName,
          cacheId: cacheId,
          error:
            error.response?.body?.message ||
            error.response?.body?.error ||
            'An unknown error occurred',
        });

        if (error.response?.status === HTTP_STATUS_CODE_BAD_REQUEST) {
          return;
        }

        throw error;
      });
  },

  requestAction: function (componentId, action, configuration, row) {
    const body = action.get('getPayload')(configuration, row);

    if (
      !ApplicationStore.isReadOnly() &&
      body !== false &&
      (action.get('cache') !== true || !Store.has(componentId, action, configuration, row))
    ) {
      nextTick(() => this.callAction(componentId, action.get('name'), body));
    }
  },

  reloadIndexSyncActions(componentId, configurationId) {
    RoutesStore.getRouteSettings()
      .getIn(['index', 'actions'], List())
      .filter((actionItem) => actionItem.get('autoload', false))
      .forEach((action) => {
        this.requestAction(
          componentId,
          action,
          InstalledComponentsStore.getConfigData(componentId, configurationId),
        );
      });
  },

  reloadRowSyncActions(componentId, configurationId, rowId) {
    RoutesStore.getRouteSettings()
      .getIn(['row', 'actions'], List())
      .filter((actionItem) => actionItem.get('autoload', false))
      .forEach((action) => {
        this.requestAction(
          componentId,
          action,
          InstalledComponentsStore.getConfigData(componentId, configurationId),
          ConfigurationRowsStore.getEditingConfiguration(componentId, configurationId, rowId),
        );
      });
  },
};
