import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { HelpBlock, Link } from 'design';
import type { Map } from 'immutable';

import string from '@/utils/string';

const UrlPathControl = (props: {
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  autoFocus?: boolean;
  showUsedWarning?: boolean;
}) => {
  const renderLearMoreLink = () => {
    return (
      <Link
        href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/generic-extractor/configuration/config/jobs/#endpoint`}
      >
        Learn more
      </Link>
    );
  };

  return (
    <FormGroup validationState={props.showUsedWarning ? 'error' : null}>
      <ControlLabel>URL Path</ControlLabel>
      <FormControl
        type="text"
        autoFocus={props.autoFocus}
        value={props.editing.get('endpoint', '')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const endpoint = string.sanitizeDiacritics(e.target.value);

          props.setEditing(props.editing.set('endpoint', endpoint));
        }}
      />
      <HelpBlock variant={props.showUsedWarning ? 'danger' : 'default'}>
        {props.showUsedWarning ? (
          'Endpoint with the same path already exists.'
        ) : props.editing.get('childJob') ? (
          <>
            A relative or absolute URL of the resource with placeholders linking the parent job
            response, e.g., <code>{'user/{user_id}/detail'}</code>. {renderLearMoreLink()}
          </>
        ) : (
          <>
            Relative or absolute URL of the resource, e.g., <code>users</code>.{' '}
            {renderLearMoreLink()}
          </>
        )}
      </HelpBlock>
    </FormGroup>
  );
};

export default UrlPathControl;
