import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';
import { fromJS, List, Map } from 'immutable';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import CredentialsActionCreators from '@/modules/provisioning/ActionCreators';
import RedshiftCredentials from '@/modules/provisioning/react/components/RedshiftCredentials';
import RedshiftSandboxCredentialsStore from '@/modules/provisioning/stores/RedshiftSandboxCredentialsStore';
import DeleteButton from '@/react/common/DeleteButton';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ConfigureSandbox from './ConfigureSandbox';
import RedshiftSSLInfoModal from './RedshiftSSLInfoModal';

const RedshiftSandbox = createReactClass({
  mixins: [
    createStoreMixin(RedshiftSandboxCredentialsStore, StorageBucketsStore, StorageTablesStore),
  ],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
  },

  getStateFromStores() {
    return {
      credentials: RedshiftSandboxCredentialsStore.getCredentials(),
      pendingActions: RedshiftSandboxCredentialsStore.getPendingActions(),
      isLoading: RedshiftSandboxCredentialsStore.getIsLoading(),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
    };
  },

  getInitialState() {
    return {
      showSSLInfoModal: false,
      sandboxConfiguration: Map(),
    };
  },

  _renderCredentials() {
    return (
      <RedshiftCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  },

  _renderControlButtons() {
    if (this.props.readOnly) {
      return null;
    }

    if (this.state.credentials.get('id')) {
      return (
        <>
          <div>
            <RunComponentButton
              forceModal
              componentId="transformation"
              method="create-sandbox"
              title="Load Tables into Redshift Sandbox"
              mode="button"
              label="Load data"
              disabled={this.state.pendingActions.count() > 0}
              runParams={() => this.state.sandboxConfiguration.toJS()}
              onEnter={() => this.setState({ sandboxConfiguration: Map() })}
              modalRunButtonDisabled={
                this.state.sandboxConfiguration.get('include', List()).count() === 0
              }
            >
              <ConfigureSandbox
                backend="redshift"
                tables={this.state.tables}
                buckets={this.state.buckets}
                onChange={this.updateSandboxConfiguration}
              />
            </RunComponentButton>
          </div>
          <div>
            <Tooltip tooltip="Information about secure connection" placement="top">
              <button
                className="btn btn-link"
                onClick={this._showSSLInfoModal}
                disabled={this.state.pendingActions.count() > 0}
              >
                <FontAwesomeIcon icon="lock" className="icon-addon-right" />
                SSL
              </button>
            </Tooltip>
            <RedshiftSSLInfoModal
              show={this.state.showSSLInfoModal}
              onHide={this._hideSSLInfoModal}
            />
          </div>
          <div>
            <DeleteButton
              tooltip="Delete Redshift Sandbox"
              isPending={this.state.pendingActions.count() > 0}
              isEnabled={this.state.pendingActions.count() === 0}
              pendingLabel="Deleting sandbox"
              label="Drop sandbox"
              confirm={{
                title: 'Delete Redshift Sandbox',
                text: 'Are you sure you want to delete the Redshift sandbox?',
                onConfirm: this._dropCredentials,
              }}
            />
          </div>
        </>
      );
    } else if (!this.state.pendingActions.get('create')) {
      return (
        <button className="btn btn-link" onClick={this._createCredentials}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Sandbox
        </button>
      );
    }
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">SQL (Redshift)</h2>
        </div>
        <div className="box-content">
          {this.state.isLoading ? (
            <>
              <Loader /> Loading...
            </>
          ) : (
            <div className="row">
              <div className="col-md-6">{this._renderCredentials()}</div>
              <div className="col-md-3 col-md-offset-3">{this._renderControlButtons()}</div>
            </div>
          )}
        </div>
      </div>
    );
  },

  _createCredentials() {
    return CredentialsActionCreators.createRedshiftSandboxCredentials();
  },

  _dropCredentials() {
    return CredentialsActionCreators.dropRedshiftSandboxCredentials();
  },

  _showSSLInfoModal() {
    this.setState({ showSSLInfoModal: true });
  },

  _hideSSLInfoModal() {
    this.setState({ showSSLInfoModal: false });
  },

  updateSandboxConfiguration(params) {
    this.setState({ sandboxConfiguration: fromJS(params) });
  },
});

export default RedshiftSandbox;
