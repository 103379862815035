import React from 'react';

import InlineTooltipWarningIcon from './InlineTooltipWarningIcon';

const NullablePrimaryKeyWarning = () => {
  return (
    <InlineTooltipWarningIcon message="This table contains column set as primary key that can no longer be nullable" />
  );
};

export default NullablePrimaryKeyWarning;
