import type { Map } from 'immutable';

import Api from '@/api';
import type { Feedback } from '@/api/routes/aiService';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';

const getBranchId = () =>
  `${
    DevBranchesStore.isDevModeActive()
      ? DevBranchesStore.getCurrentId()
      : DevBranchesStore.getDefaultBranchId()
  }`;

const getErrorExplanation = (job: Map<string, any>) => {
  return Api.aiService.explainError({
    jobId: job.get('id'),
    error: job.getIn(['result', 'message']),
  });
};

const getConfigDescription = (componentId: string, configId: string, rowId?: string) => {
  return Api.aiService.describeConfiguration({
    branchId: getBranchId(),
    componentId,
    configId,
    rowId,
  });
};

const sendFeedback = (data: Feedback) => {
  return Api.aiService.feedback(data);
};

const createConversation = (componentId: string, configId: string) => {
  return Api.aiService.createConversation({
    branchId: getBranchId(),
    componentId,
    configId,
  });
};

const getConversation = (conversationId: string) => {
  return Api.aiService.getConversation({ conversationId });
};

const deleteConversation = (conversationId: string) => {
  return getConversation(conversationId).then((conversation) => {
    if (conversation.status !== 'initialized') return;

    return Api.aiService.deleteConversation({ conversationId });
  });
};

const generateQuery = (conversationId: string, prompt: string, existingQuery?: string) => {
  return Api.aiService.generateQuery(
    { conversationId },
    { prompt, ...(existingQuery && { existingQuery }) },
  );
};

const executeQuery = (conversationId: string, query: string) => {
  return Api.aiService.executeQuery({ conversationId }, { query });
};

export {
  getErrorExplanation,
  getConfigDescription,
  sendFeedback,
  createConversation,
  getConversation,
  deleteConversation,
  generateQuery,
  executeQuery,
};
