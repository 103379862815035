import React from 'react';
import type { Map } from 'immutable';

import type { BACKEND_SIZES } from '@/modules/components/Constants';
import JobError from './JobError';
import JobStatusIcon from './JobStatusIcon';
import JobStatusLabel from './JobStatusLabel';

const JobStatusLabelWithErrorAndIcon = ({
  job,
  backendType,
}: {
  job: Map<string, any>;
  backendType?: (typeof BACKEND_SIZES)[keyof typeof BACKEND_SIZES];
}) => {
  const label = <JobStatusLabel status={job.get('status')} backendType={backendType} />;

  if (!label) return null;

  return (
    <>
      {label}
      <JobError job={job} />
      <JobStatusIcon status={job.get('status')} className="icon-addon-left" />
    </>
  );
};

export default JobStatusLabelWithErrorAndIcon;
