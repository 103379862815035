import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';
import classNames from 'classnames';
import type { Map } from 'immutable';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import Authorization from './Authorization';
import AuthorizedBox from './AuthorizedBox';

const AuthorizationRow = (props: {
  componentId: string;
  configId: string;
  readOnly: boolean;
  onResetCredentials: () => Promise<unknown>;
  admins: Map<string, any>;
  wrapperComponentId?: string;
  idPrefix?: string;
  credentials?: Map<string, any>;
  disableExternalAuthorization?: boolean;
  withoutBox?: boolean;
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const hasCredentials = props.credentials?.has('id');

  if (!hasCredentials && props.readOnly) {
    return null;
  }

  const authBox = props.credentials?.has('id') ? (
    <AuthorizedBox
      credentials={props.credentials.toJS()}
      admins={props.admins}
      onReset={props.onResetCredentials}
      readOnly={props.readOnly}
    />
  ) : (
    <div className={classNames({ 'text-center': !props.withoutBox })}>
      <Button bsStyle="success" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon="user" className="icon-addon-right" />
        Authorize Account
      </Button>
    </div>
  );

  return (
    <>
      {props.withoutBox ? (
        authBox
      ) : (
        <CollapsibleBox
          title="Authorization"
          defaultOpen={!hasCredentials}
          collapsePrefix={
            <span
              className={classNames(
                'tw-mr-2 tw-text-xs tw-font-medium',
                hasCredentials ? 'tw-text-primary-600' : 'tw-text-warning-600',
              )}
            >
              {hasCredentials ? 'Authorized' : 'No Account Authorized'}
            </span>
          }
        >
          {authBox}
        </CollapsibleBox>
      )}
      <Authorization
        showModal={showModal}
        componentId={props.componentId}
        wrapperComponentId={props.wrapperComponentId}
        allowExternalAuthorization={!props.disableExternalAuthorization}
        idPrefix={props.idPrefix}
        configId={props.configId}
        onModalHideFn={() => setShowModal(false)}
      />
    </>
  );
};

export default AuthorizationRow;
