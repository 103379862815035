import React, { useContext } from 'react';
import type { ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position, useStore } from '@xyflow/react';
import classNames from 'classnames';

import { GraphContext } from '@/modules/lineage/components/GraphContext';
import { zoomOutSelector } from '@/modules/lineage/helpers';
import MarkedText from '@/react/common/MarkedText';
import Truncated from '@/react/common/Truncated';
import NodeDropdown from './Dropdown';

const BaseNode = ({
  nodeId,
  nodeTitle,
  nodeType,
  customIcon,
  iconName,
  iconClassName,
  backgroundColor,
  children,
  bottomContent,
  isLink,
  onHighlight,
  onOpenSidebar,
  onClick,
  isAlias = false,
  graphId = null,
}: {
  nodeId: string;
  nodeTitle: string;
  nodeType: string;
  onClick: (nodeId: string) => void;
  customIcon?: ReactNode;
  iconName?: IconProp;
  iconClassName?: string;
  backgroundColor?: string;
  children?: ReactNode;
  bottomContent?: ReactNode;
  isLink?: boolean;
  onHighlight?: (nodeId: string) => void;
  onOpenSidebar?: (nodeId: string) => void;
  isAlias?: boolean;
  graphId?: string | null;
}) => {
  const { searchQuery, nodeDetail, mainNodeId } = useContext(GraphContext);
  const simpleView = useStore(zoomOutSelector);

  return (
    <div className="tw-relative tw-flex tw-w-[340px] tw-flex-col tw-text-xs">
      {simpleView && (
        <div
          className={classNames(
            'tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-50 tw-rounded-md',
            backgroundColor,
          )}
        />
      )}
      <div
        className={classNames(
          'tw-rounded-md tw-shadow-[0_3px_3px_0_rgba(34,37,41,0.08)] tw-outline tw-outline-[3px]',
          // for some reason focus on node (opacity 0 on other nodes) does not work when default transparent outline is not set
          nodeId === nodeDetail?.fqid
            ? 'tw-outline-secondary-500'
            : nodeId === mainNodeId
            ? 'tw-outline-teal-500'
            : 'tw-outline-transparent',
        )}
      >
        <div className="tw-group/node tw-relative tw-flex">
          <div
            onClick={() => onClick(nodeId)}
            className="tw-flex tw-flex-1 tw-items-center tw-justify-center"
          >
            <div
              className={classNames(
                'tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-rounded-tl-md tw-px-3 tw-py-2',
                backgroundColor,
                { 'tw-rounded-bl-md': !bottomContent },
              )}
            >
              {customIcon ??
                (iconName && (
                  <FontAwesomeIcon
                    icon={iconName}
                    className={classNames('tw-h-4 tw-w-4', iconClassName)}
                  />
                ))}
              <p className="tw-mb-0 tw-mt-1 tw-text-[10px] tw-font-semibold">{nodeType}</p>
            </div>
            <div
              className={classNames(
                'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-bg-white tw-p-3',
              )}
            >
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                <Truncated
                  twoLines
                  className={classNames(
                    'tw-mb-0 tw-flex tw-items-center tw-font-medium group-hover/node:tw-text-secondary-600',
                    { 'tw-border-0 tw-border-b tw-border-dotted tw-border-secondary-600': isAlias },
                  )}
                  tooltip={nodeTitle}
                  text={<MarkedText mark={searchQuery} source={nodeTitle} />}
                />
                {children}
              </div>
            </div>
          </div>
          {!simpleView && (
            <div
              onClick={() => isLink && onClick(nodeId)}
              className={classNames(
                'tw-flex tw-w-fit tw-min-w-7 tw-items-center tw-justify-end tw-rounded-tr-md tw-bg-white',
                {
                  'tw-rounded-br-md': !bottomContent,
                },
              )}
            >
              {(onOpenSidebar || onHighlight) && (
                <NodeDropdown
                  onOpenSidebar={onOpenSidebar}
                  onHighlight={onHighlight}
                  graphId={graphId}
                  nodeId={nodeId}
                />
              )}
              {isLink && (
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="tw-invisible tw-mr-4 tw-text-secondary-600 group-hover/node:tw-visible"
                />
              )}
            </div>
          )}
          <Handle
            id="main"
            type="target"
            position={Position.Left}
            className="tw-invisible -tw-left-0.5"
          />
          <Handle
            id="main"
            type="source"
            position={Position.Right}
            className="tw-invisible -tw-right-0.5"
          />
        </div>
        {bottomContent && (
          <>
            <div className="tw-h-0.5 tw-bg-[#D9D9D9]" />
            {bottomContent}
          </>
        )}
      </div>
    </div>
  );
};

export default BaseNode;
