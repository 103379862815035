import RoutePendingStore from '@/stores/RoutePendingStore';

const createStoreMixin = (...stores) => {
  const StoreMixin = {
    getInitialState() {
      return this.getStateFromStores();
    },

    componentDidMount() {
      stores.forEach((store) => {
        store.addChangeListener(this._handleStoreChanged);
      });
    },

    componentWillUnmount() {
      stores.forEach((store) => {
        store.removeChangeListener(this._handleStoreChanged);
      });
    },

    _handleStoreChanged() {
      if (!RoutePendingStore.isPending()) {
        return this.setState(() => this.getStateFromStores());
      }
    },
  };

  return StoreMixin;
};

export default createStoreMixin;
