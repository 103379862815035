import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableIconAction from './TableIconAction';

type Props = {
  onClick: () => void;
  tooltip?: string | null;
  disabled?: boolean;
};

const CopyToWorkspace = ({ onClick, tooltip = null, disabled = false }: Props) => {
  return (
    <TableIconAction
      tooltip={tooltip || 'Copy to an existing workspace'}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="add-workspace-icon">
        <FontAwesomeIcon icon="box" fixedWidth />
        <FontAwesomeIcon icon="circle-arrow-right" />
      </div>
    </TableIconAction>
  );
};

export default CopyToWorkspace;
