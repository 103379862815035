import React from 'react';
import classNames from 'classnames';

const TableDivideRow = (props: { children: React.ReactNode; className?: string }) => {
  return (
    <div className="tr divide-row">
      <span className={classNames('divide-row-label no-wrap', props.className)}>
        {props.children}
      </span>
    </div>
  );
};

export default TableDivideRow;
