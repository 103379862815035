import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ComponentBoxModal from './ComponentBoxModal';

const ComponentHeaderButton = () => {
  const [showModal, setShowModal] = React.useState(false);

  const { allComponents, component, readOnly } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();

      return {
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        component: ComponentsStore.getComponent(componentId) as Map<string, any>,
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [ApplicationStore, RoutesStore, ComponentsStore],
  );

  if (readOnly) {
    return null;
  }

  return (
    <>
      <Button bsStyle="success" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Create configuration
      </Button>
      <ComponentBoxModal
        hasConfigurations
        show={showModal}
        onHide={() => setShowModal(false)}
        readOnly={readOnly}
        component={component}
        allComponents={allComponents}
      />
    </>
  );
};

export default ComponentHeaderButton;
