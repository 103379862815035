import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import type { Map } from 'immutable';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';

const Certificate = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <CollapsibleBox
      title="Certificates"
      titleSuffix={<DocumentationLink path="configuration/api/#ca-certificate" />}
    >
      <FormGroup>
        <ControlLabel>CA Certificate</ControlLabel>
        <FormControl
          rows={3}
          type="text"
          componentClass="textarea"
          disabled={props.readOnly}
          placeholder="Custom certificate authority bundle in crt/pem format."
          value={props.editing.get('caCertificate')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setEditing(props.editing.set('caCertificate', e.target.value));
          }}
        />
        <HelpBlock>
          It allows connecting to an HTTPS server with an untrusted/self-signed certificate. The
          value is not the server&apos;s certificate, but the certificate of the certificate
          authority used to generate the server certificate.
        </HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Client Certificate</ControlLabel>
        <FormControl
          rows={3}
          type="text"
          componentClass="textarea"
          disabled={props.readOnly}
          placeholder="Value is the client certificate, followed by the private key. Both are in crt/pem format."
          value={props.editing.get('clientCertificate')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setEditing(props.editing.set('clientCertificate', e.target.value));
          }}
        />
        <HelpBlock>
          This is required if the server requires two-way SSL authentication, so in addition to
          verifying the server, the server verifies the client.
        </HelpBlock>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Certificate;
