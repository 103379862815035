import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { List, Map } from 'immutable';

import { FILE_INPUT_MAPPING_DEFAULT_LIMIT } from '@/modules/components/Constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';

const FileInputMappingEditor = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const isDevModeActive = DevBranchesStore.isDevModeActive();
    const hasProtectedDefaultBranch = ApplicationStore.hasProtectedDefaultBranch();

    return (
      <>
        <FormGroup>
          <ControlLabel>Tags</ControlLabel>
          <Select
            multi
            autoFocus
            allowCreate
            trimMultiCreatedValues
            placeholder="Add tags"
            promptTextCreator={(label) => `Add tag "${label}"`}
            disabled={this.props.disabled}
            value={
              this.hasOldTags()
                ? this.props.value.get('tags', List())
                : this.props.value.getIn(['source', 'tags'], List()).map((tag) => tag.get('name'))
            }
            onChange={this.handleChangeTags}
          />
          {this.hasOldTags() ? (
            <HelpBlock>
              All files matching at least one tag (logical OR) will be loaded.
              {this.props.value.get('tags', List()).count() > 1 && (
                <strong>
                  {' '}
                  This is the old behavior of tags. If you prefer the new behavior where all tags
                  have to match, please clear the input and start over.
                </strong>
              )}
            </HelpBlock>
          ) : (
            <HelpBlock>All files matching all tags (logical AND) will be loaded.</HelpBlock>
          )}
        </FormGroup>
        {hasProtectedDefaultBranch ? (
          <FormGroup>
            <ControlLabel>Changed Since</ControlLabel>
            <ChangedSinceInput
              withAdaptiveOption
              disabled={this.props.disabled}
              onChange={this.handleChangeChangedSince}
              value={this.props.value.get('changed_since', '')}
              helpBlock="When specified, only files changed or created within the selected period will be loaded."
            />
          </FormGroup>
        ) : (
          <>
            {!isDevModeActive && (
              <FormGroup>
                <ControlLabel>Query</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Search query"
                  disabled={this.props.disabled}
                  value={this.props.value.get('query', '')}
                  onChange={this.handleChangeQuery}
                />
                <HelpBlock>Specify an Elasticsearch query to refine your search</HelpBlock>
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>
                Processed Tags <OptionalFormLabel />
              </ControlLabel>
              <Select
                multi
                allowCreate
                trimMultiCreatedValues
                placeholder="Add tags"
                promptTextCreator={(label) => `Add tag "${label}"`}
                disabled={this.props.disabled}
                value={this.props.value.get('processed_tags', List())}
                onChange={this.handleChangeProcessedTags}
              />
              <HelpBlock>Add these tags to the files that were successfully processed</HelpBlock>
            </FormGroup>
          </>
        )}
        <FormGroup>
          <ControlLabel>Maximum Loaded Files</ControlLabel>
          <FormControl
            min="1"
            type="number"
            disabled={this.props.disabled}
            value={this.props.value.get('limit', FILE_INPUT_MAPPING_DEFAULT_LIMIT)}
            onChange={this.handleChangeLimit}
          />
        </FormGroup>
      </>
    );
  },

  handleChangeQuery(e) {
    return this.props.onChange(this.props.value.set('query', e.target.value));
  },

  handleChangeLimit(e) {
    return this.props.onChange(this.props.value.set('limit', parseInt(e.target.value, 10) || ''));
  },

  handleChangeChangedSince(value) {
    return this.props.onChange(this.props.value.set('changed_since', value));
  },

  handleChangeTags(tags) {
    if (this.hasOldTags()) {
      return this.props.onChange(this.props.value.set('tags', tags));
    }

    return this.props.onChange(
      this.props.value.setIn(
        ['source', 'tags'],
        tags.map((tag) => Map({ name: tag })),
      ),
    );
  },

  handleChangeProcessedTags(tags) {
    return this.props.onChange(this.props.value.set('processed_tags', tags));
  },

  hasOldTags() {
    return !this.props.value.get('tags', List()).isEmpty();
  },
});

export default FileInputMappingEditor;
