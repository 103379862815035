import loadJiraWidget, { openJiraWidget } from './external/jiraWidget';

const contactSupport = ({ ticketContext } = {}) => {
  loadJiraWidget().then(() => {
    setTimeout(() => {
      openJiraWidget(ticketContext);
    }, 500);
  });
};

export default contactSupport;
