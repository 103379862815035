import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Link, Tooltip } from 'design';
import { Map } from 'immutable';

import { ioType } from '@/modules/components/Constants';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import TablesByBucketsPanel from '@/modules/components/react/components/TablesByBucketsPanel';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import Loader from '@/react/common/Loader';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

const FilesList = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    inputTables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    onDeleteFn: PropTypes.func.isRequired,
    onEditFn: PropTypes.func.isRequired,
    toggleEnabledFn: PropTypes.func.isRequired,
    isPendingFn: PropTypes.func.isRequired,
    isDeletingFn: PropTypes.func.isRequired,
    getRunSingleDataFn: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    localState: PropTypes.object.isRequired,
    updateLocalState: PropTypes.func.isRequired,
    prepareLocalState: PropTypes.func.isRequired,
    tablesWithSourceSearchInputMapping: PropTypes.instanceOf(Map).isRequired,
  },

  render() {
    return (
      <TablesByBucketsPanel
        tables={this.props.inputTables}
        buckets={this.props.buckets}
        renderTableRowFn={this.renderRow}
        searchQuery={this.props.searchQuery}
        isExportedFn={(tableId) => {
          const configTable = this.getConfigTable(tableId);
          return !!(configTable && configTable.get('enabled'));
        }}
        isShownFn={(tableId) => !!this.getConfigTable(tableId)}
        onToggleBucketFn={this.handleToggleBucket}
        isBucketToggledFn={this.isBucketToggled}
        configuredTables={this.props.items.map((item) => item.get('tableId')).toArray()}
        renderDeletedTableRowFn={(table, index) => this.renderRowDeleted(table, index)}
        tablesWithSourceSearchInputMapping={this.props.tablesWithSourceSearchInputMapping}
      />
    );
  },

  handleToggleBucket(bucketId) {
    const bucketToggles = this.props.localState.get('bucketToggles', Map());
    return this.props.updateLocalState(
      ['bucketToggles'],
      bucketToggles.set(bucketId, !bucketToggles.get(bucketId)),
    );
  },

  isBucketToggled(bucketId) {
    const bucketToggles = this.props.localState.get('bucketToggles', Map());
    return !!bucketToggles.get(bucketId);
  },

  renderRow(table, index) {
    const item = this.props.items.filter((i) => i.get('tableId') === table.get('id')).first();
    const isDeleting = this.props.isDeletingFn(item.get('id'));
    const isPending = this.props.isPendingFn(item.get('id'));

    return (
      <div
        key={index}
        className={classnames('tr hoverable-actions', { 'row-disabled': !item.get('enabled') })}
      >
        <div className="td col-xs-3">{this.renderFieldTable(item.get('tableId'))}</div>
        <div className="td">
          <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" fixedWidth />
        </div>
        <div className="td col-xs-5">
          <InputOutputTypeIcon type={ioType.FILE} />
          {this.renderDriveFolder(item.getIn(['folder', 'id']), item.getIn(['folder', 'title']))}
          {' / '}
          {this.renderDriveLink(item.get('fileId'), item.get('title'))}
        </div>
        <div className="td col-xs-2">{this.renderFieldAction(item.get('action'))}</div>
        <div className="td pl-0 pr-1 no-wrap">
          {!this.props.readOnly && (
            <>
              {this.renderEditButton(item)}
              {this.renderDeleteButton(item, isDeleting)}
              <RowActionDropdown showLoading={isPending}>
                {this.renderRunButton(item)}
                <RowActionMenuItem divider />
                {this.renderEnabledButton(item, isPending)}
              </RowActionDropdown>
            </>
          )}
        </div>
      </div>
    );
  },

  renderRowDeleted(table, index) {
    const item = this.props.items.filter((i) => i.get('tableId') === table.get('id')).first();
    const isDeleting = this.props.isDeletingFn(item.get('id'));

    return (
      <div key={index} className="tr hoverable-actions">
        <div className="td col-xs-4">{this.renderFieldTable(item.get('tableId'))}</div>
        <div className="td">
          <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" fixedWidth />
        </div>
        <div className="td col-xs-4">
          {this.renderDriveFolder(item.getIn(['folder', 'id']), item.getIn(['folder', 'title']))}
          {' / '}
          {this.renderDriveLink(item.get('fileId'), item.get('title'))}
        </div>
        <div className="td col-xs-2">{this.renderFieldAction(item.get('action'))}</div>
        <div className="td pl-0 pr-1 no-wrap">{this.renderDeleteButton(item, isDeleting)}</div>
      </div>
    );
  },

  renderFieldTable(tableId) {
    const configTables = this.props.inputTables.filter((t) => {
      return t.get('id') === tableId;
    });

    if (configTables.count() === 0) {
      return tableId;
    }

    return <StorageApiTableLinkEx tableId={configTables.first().get('id')} showOnlyDisplayName />;
  },

  renderFieldAction(action) {
    const tooltip =
      action === 'create' ? 'Each run, a new file is created' : 'The file is updated on each run';

    return (
      <Tooltip key="tooltip" tooltip={tooltip} placement="top" type="explanatory">
        {action}
      </Tooltip>
    );
  },

  renderEditButton(item) {
    return (
      <Tooltip tooltip="Edit input" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={() => this.props.onEditFn('1', item, false)}
        >
          <FontAwesomeIcon icon="pen" />
        </Button>
      </Tooltip>
    );
  },

  renderDeleteButton(item, isDeleting) {
    return (
      <Tooltip tooltip="Delete Input" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={() => this.props.onDeleteFn(item)}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        </Button>
      </Tooltip>
    );
  },

  renderEnabledButton(item, isPending) {
    return (
      <ActivateDeactivateSwitch
        mode="menuitem"
        isActive={item.get('enabled')}
        isPending={isPending}
        onChange={() => this.props.toggleEnabledFn(item)}
      />
    );
  },

  renderRunButton(item) {
    return (
      <RunComponentButton
        title="Upload"
        mode="menuitem"
        componentId={this.props.componentId}
        runParams={() => {
          return {
            config: this.props.configId,
            configData: this.props.getRunSingleDataFn(item.get('id')),
          };
        }}
      >
        You are about to upload {item.get('title')}
      </RunComponentButton>
    );
  },

  renderDriveFolder(googleId, title) {
    if (!googleId || !title) {
      return '/';
    }
    return this.renderDriveLink(googleId, title);
  },

  renderDriveLink(googleId, title) {
    if (googleId && title) {
      const url = `https://drive.google.com/open?id=${googleId}`;
      return <Link href={url}>{title}</Link>;
    }

    return title || 'N/A';
  },

  getConfigTable(tableId) {
    return this.props.items.filter((i) => i.get('tableId') === tableId).first();
  },
});

export default FilesList;
