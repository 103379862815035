import React from 'react';
import type { NodeProps } from '@xyflow/react';

import BaseNode from '@/modules/lineage/components/CustomNode/BaseNode';
import LinkedFromProject from '@/modules/lineage/components/CustomNode/LinkedFromProject';
import { isTableAlias } from '@/modules/lineage/helpers';
import type { OnSelectProject, OnSetShownHandles, TableNode } from '@/modules/lineage/rfTypes';
import Columns from './Columns';

const Table = ({
  data,
  onHighlight,
  onSetShownHandles,
  onClick,
  projectId,
  onSelectProject,
  graphId,
}: NodeProps<TableNode> & {
  onHighlight: (nodeId: string) => void;
  onSetShownHandles: OnSetShownHandles;
  onClick: (nodeId: string) => void;
  projectId: string | null;
  onSelectProject: OnSelectProject;
  graphId: string;
}) => {
  return (
    <BaseNode
      nodeId={data.fqid}
      onClick={onClick}
      onOpenSidebar={onClick}
      onHighlight={onHighlight}
      nodeTitle={data.displayName || data.name}
      nodeType="TBL"
      iconName="table"
      iconClassName="tw-text-neutral-400 tw-fill-neutral-400"
      backgroundColor="tw-bg-secondary-100"
      bottomContent={
        data.columns.length > 0 && (
          <Columns
            nodeId={data.fqid}
            onSetShownHandles={onSetShownHandles}
            columns={data.columns}
          />
        )
      }
      isAlias={isTableAlias(data.metadata)}
      graphId={graphId}
    >
      <LinkedFromProject
        currentProjectId={projectId}
        nodeId={data.fqid}
        nodeProjectName={data.projectName}
        onSelectProject={onSelectProject}
      />
    </BaseNode>
  );
};

export default Table;
