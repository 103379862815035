import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { List, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import ConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ComponentTypeIcon from '@/modules/components-directory/components/ComponentTypeIcon';
import { ACTIVITY_TYPES, BOX_ROWS_LIMITS, STORAGE_EVENTS } from '@/modules/home/constants';
import { groupSameActivityForLatestEdits, splitIntoChunks } from '@/modules/home/helpers';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import CreatedDate from '@/react/common/CreatedDate';
import PaginatedBox from '@/react/common/PaginatedBox/PaginatedBox';
import Link from '@/react/common/RouterLink';
import Truncated from '@/react/common/Truncated';
import NoConfigurationEdits from './NoConfigurationEdits';

type EventData = Map<string, any>;

type LatestConfigurationEditsProps = {
  currentAdmin: Map<string, any>;
  activities: List<EventData>;
  isDevModeActive: boolean;
  hasFlows: boolean;
};

const LatestConfigurationEdits = ({
  currentAdmin,
  activities,
  isDevModeActive,
  hasFlows,
}: LatestConfigurationEditsProps) => {
  const myEdits = useMemo(() => {
    return activities.filter((event) => {
      return (
        event?.get('type') === ACTIVITY_TYPES.CONFIGURATION &&
        event.getIn(['data', 'token', 'name']) === currentAdmin.get('email')
      );
    });
  }, [activities, currentAdmin]);

  const lastEdits = useMemo(() => {
    return groupSameActivityForLatestEdits(myEdits)
      .toList()
      .sortBy((event: EventData) => -1 * new Date(event?.get('date')).getTime())
      .slice(0, BOX_ROWS_LIMITS)
      .map((event: EventData) => event?.get('data'));
  }, [myEdits]);

  const renderLinkBody = (event: EventData, component: Map<string, any>, text: string) => (
    <>
      <div className="component-icon-with-type tw-mr-4">
        <ComponentIcon component={component} size="36" />
        <ComponentTypeIcon type={component.get('type')} size="18" />
      </div>
      <div className="component-meta tw-pr-3">
        <Truncated text={event.getIn(['params', 'name'])} />
        <div className="text-muted">
          {text} <CreatedDate createdTime={event.get('created')} />
        </div>
      </div>
      {event.get('event') !== STORAGE_EVENTS.PURGED && (
        <FontAwesomeIcon icon="chevron-right" fixedWidth className="text-muted-light" />
      )}
    </>
  );

  if (myEdits.isEmpty()) {
    return (
      <NoConfigurationEdits
        isDevModeActive={isDevModeActive}
        hasActivities={!activities.isEmpty()}
      />
    );
  }

  return (
    <div className="box box-panel">
      <div className="box-header">
        <h2 className="box-title">Continue Your Work</h2>
      </div>
      <PaginatedBox>
        {splitIntoChunks(lastEdits)
          .map((chunk, index) => (
            <PaginatedBox.Item key={index}>
              {chunk
                .map((event: EventData) => {
                  if (!event) return null;

                  let component = ComponentsStore.getComponent(
                    event.getIn(
                      ['params', 'configuration', 'component'],
                      event.getIn(['params', 'component']),
                    ),
                  );

                  if (component?.get('id') === KEBOOLA_EX_SAMPLE_DATA) {
                    component = ComponentsStore.getComponent(
                      InstalledComponentsStore.getConfigData(
                        KEBOOLA_EX_SAMPLE_DATA,
                        event.getIn(['params', 'configurationId']),
                      ).getIn(['parameters', 'componentId'], KEBOOLA_EX_SAMPLE_DATA),
                    );
                  }

                  if (!component) return null;

                  switch (event.get('event')) {
                    case STORAGE_EVENTS.CREATED:
                    case STORAGE_EVENTS.EDITED:
                    case STORAGE_EVENTS.RESTORED:
                    case STORAGE_EVENTS.COPIED:
                    case STORAGE_EVENTS.ROLLBACK:
                      return (
                        <ConfigurationLink
                          key={event.get('id')}
                          className="flex-container latest-configuration-edit-item"
                          componentId={event.getIn(['params', 'component'])}
                          configId={event.getIn(['params', 'configurationId'])}
                          hasFlows={hasFlows}
                        >
                          {renderLinkBody(event, component, 'Edited')}
                        </ConfigurationLink>
                      );

                    case STORAGE_EVENTS.ROW_CREATED:
                    case STORAGE_EVENTS.ROW_EDITED:
                    case STORAGE_EVENTS.ROW_ROLLBACK:
                    case STORAGE_EVENTS.ROW_COPIED:
                    case STORAGE_EVENTS.ROW_DELETED: {
                      const isDeletedEvent = event.get('event') === STORAGE_EVENTS.ROW_DELETED;
                      const componentId = event.getIn(['params', 'configuration', 'component']);

                      if (isDeletedEvent && componentId === KEBOOLA_SHARED_CODE) {
                        return null;
                      }

                      return (
                        <ConfigurationLink
                          key={event.get('id')}
                          className="flex-container latest-configuration-edit-item"
                          componentId={componentId}
                          configId={event.getIn(['params', 'configuration', 'configurationId'])}
                          {...(!isDeletedEvent && { rowId: event.getIn(['params', 'rowId']) })}
                          hasFlows={hasFlows}
                        >
                          {renderLinkBody(event, component, 'Edited')}
                        </ConfigurationLink>
                      );
                    }

                    case STORAGE_EVENTS.DELETED:
                      return (
                        <Link
                          key={event.get('id')}
                          to={trashRouteNames.SETTINGS_TRASH}
                          query={{
                            q: event.getIn(['params', 'configuration', 'configurationId']),
                          }}
                          className="flex-container latest-configuration-edit-item"
                        >
                          {renderLinkBody(event, component, 'Deleted')}
                        </Link>
                      );

                    case STORAGE_EVENTS.PURGED:
                      return (
                        <span
                          key={event.get('id')}
                          className="flex-container latest-configuration-edit-item"
                        >
                          {renderLinkBody(event, component, 'Permanently deleted')}
                        </span>
                      );

                    default:
                      return null;
                  }
                })
                .toArray()}
            </PaginatedBox.Item>
          ))
          .toArray()}
      </PaginatedBox>
    </div>
  );
};

export default LatestConfigurationEdits;
