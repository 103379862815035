import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import OldJobsActionCreators from '@/modules/jobs/ActionCreators';
import JobsActionCreators from '@/modules/queue/actions';
import ApplicationStore from '@/stores/ApplicationStore';

type ConfigActionParams = { config: string; component?: string };
type RowActionParams = ConfigActionParams & { row: string };

export const DEFAULT_INTERVAL = 30;

const configPoll = (
  componentId?: string,
  additionalPromise?: ((params: ConfigActionParams) => Promise<any>) | null,
  options?: { skipJobs?: boolean },
) => {
  return {
    interval: DEFAULT_INTERVAL,
    action: (params: ConfigActionParams) => {
      const { config, component = componentId } = params;

      return Promise.all([
        options?.skipJobs
          ? Promise.resolve()
          : ApplicationStore.hasNewQueue()
          ? JobsActionCreators.loadComponentConfigurationLatestJobs(component, config)
          : OldJobsActionCreators.loadComponentConfigurationLatestJobs(component, config),
        VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(component, config),
        !!additionalPromise && additionalPromise(params),
      ]);
    },
  };
};

const rowPoll = (componentId?: string) => ({
  interval: DEFAULT_INTERVAL,
  action: ({ component = componentId, config, row }: RowActionParams) => {
    return Promise.all([
      ApplicationStore.hasNewQueue()
        ? JobsActionCreators.loadComponentConfigurationLatestJobs(component, config, row)
        : OldJobsActionCreators.loadComponentConfigurationLatestJobs(component, config),
      VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(component, config),
      RowVersionsActionCreators.loadVersionsForce(component, config, row),
    ]);
  },
});

export { configPoll, rowPoll };
