import React from 'react';
import classnames from 'classnames';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import Link from './RouterLink';

const ComponentDetailLink = (props: {
  componentId: string;
  hasFlows?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const linkParams = resolveRouterLinkParams(props.componentId, null, null, props.hasFlows);

  if (!linkParams) {
    return null;
  }

  return (
    <Link
      to={linkParams.to}
      params={linkParams.params}
      className={classnames('dark', props.className)}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  );
};

export default ComponentDetailLink;
