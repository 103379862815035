import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

type Props = {
  isSorted: boolean;
  isSortedDesc: boolean;
  className?: string;
};

const SortIcon = (props: Props) => {
  return (
    <FontAwesomeIcon
      className={classnames('sort-icon icon-addon-right', props.className)}
      icon={['fad', props.isSorted ? (props.isSortedDesc ? 'sort-down' : 'sort-up') : 'sort']}
    />
  );
};

export default SortIcon;
