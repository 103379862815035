import { fromJS } from 'immutable';

import { findBaseType } from '@/modules/components/utils/columnMetadataHelper';
import { getTableColumnMetadata } from '@/modules/components/utils/tableMetadataHelper';
import { Types } from './constants';

const basetypeMapping = fromJS({
  STRING: {
    type: Types.STRING,
  },
  INTEGER: {
    type: Types.INTEGER,
  },
  NUMERIC: {
    type: Types.FLOAT,
  },
  FLOAT: {
    type: Types.FLOAT,
  },
  BOOLEAN: {
    type: Types.BOOLEAN,
  },
  DATE: {
    type: Types.DATE,
  },
  TIMESTAMP: {
    type: Types.TIMESTAMP,
  },
});

export function prepareColumnsTypes(table) {
  const columnMetadata = getTableColumnMetadata(table);
  const metadata = getMetadata(columnMetadata);

  return table
    .get('columns')
    .filter((column) => metadata.get(column))
    .map((column) =>
      fromJS({
        name: column,
        dbName: column,
        type: metadata.getIn([column, 'type']),
      }),
    );
}

function getMetadata(columnMetadata) {
  return columnMetadata.map((data) => {
    const baseType = findBaseType(data);

    if (!baseType) {
      return null;
    }

    const mapping = basetypeMapping.get(baseType.get('value'));

    if (!mapping) {
      return null;
    }

    return fromJS({ type: mapping.get('type') });
  });
}
