import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { ioType } from '@/modules/components/Constants';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import Checkbox from '@/react/common/Checkbox';
import MappingsMultiActionsHeader, {
  MappingsHeader,
} from '@/react/common/MappingsMultiActionsHeader';
import SortIcon from '@/react/common/SortIcon';
import CollapsibleMapping from './CollapsibleMapping';
import {
  hasInputMappingTables,
  prepareConfigDataWithDeletedMapping,
  prepareConfigDataWithEditedMapping,
  saveMapping,
} from './helpers';
import TableInputMappingModal from './TableInputMappingModal';
import TableInputMappingRow from './TableInputMappingRow';

const TableInputMapping = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    configId: PropTypes.string.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    allComponents: PropTypes.instanceOf(Map),
    allowedComponents: PropTypes.instanceOf(Map),
    sandboxes: PropTypes.instanceOf(Map),
    hasPayAsYouGo: PropTypes.bool,
    availableDatabricksClusters: PropTypes.instanceOf(List),
    replacementComponentId: PropTypes.string,
    rowId: PropTypes.string,
    generateSources: PropTypes.bool,
    onGenerateSourceChange: PropTypes.func,
    singleMapping: PropTypes.bool,
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false,
    };
  },

  renderActions(isDbtComponent) {
    return (
      !this.props.readOnly &&
      !this.props.singleMapping && (
        <div className="flex-container">
          {isDbtComponent && (
            <Checkbox
              checked={this.props.generateSources}
              onChange={this.props.onGenerateSourceChange}
            >
              Generate Sources
            </Checkbox>
          )}
          <TableInputMappingModal
            componentId={this.props.componentId}
            replacementComponentId={this.props.replacementComponentId}
            tables={this.props.tables}
            buckets={this.props.buckets}
            onSave={(editing) => this.onSaveMapping('new-mapping', editing)}
            otherMappings={this.props.value}
            destinationType={this.props.destinationType}
            generateSources={this.props.generateSources}
          />
        </div>
      )
    );
  },

  render() {
    const isDbtComponent = DBT_COMPONENTS.includes(this.props.componentId);

    return (
      <CollapsibleMapping
        title={
          <MappingsHeader type="input" componentId={this.props.componentId} storage="tables" />
        }
        entity="mapping"
        readOnly={!!this.props.singleMapping || this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        mappingKey="table-input"
        isEmpty={this.props.value.isEmpty()}
        actions={this.renderActions(isDbtComponent)}
        header={
          <>
            <MappingsMultiActionsHeader
              hide={!!this.props.singleMapping || this.props.readOnly}
              disabled={this.props.value.isEmpty()}
              componentId={this.props.componentId}
              configurationId={this.props.configId}
              rowId={this.props.rowId}
              type="input"
              storage="tables"
              allMappings={this.props.value}
              selectedMappings={this.state.selectedMappings}
              updateMappingsSelection={(selectedMappings) =>
                this.setState(() => ({ selectedMappings }))
              }
              deleteMappings={this.props.onDeleteMappings}
              sandboxComponent={this.props.allComponents?.get(KEBOOLA_SANDBOXES)}
              allowedComponents={this.props.allowedComponents}
              sandboxes={this.props.sandboxes}
              hasPayAsYouGo={this.props.hasPayAsYouGo}
              availableDatabricksClusters={this.props.availableDatabricksClusters}
              isSorted={this.state.sortBy === 'source'}
              isSortedDesc={this.state.sortDesc}
              onClick={() => this.handleChangeSort('source')}
            />
            {isDbtComponent ? (
              <div className="tw-font-medium">Input Table</div>
            ) : (
              <div
                className="tw-cursor-pointer tw-font-medium"
                onClick={() => this.handleChangeSort('destination')}
              >
                Input Table
                <SortIcon
                  className="tw-ml-2"
                  isSorted={this.state.sortBy === 'destination'}
                  isSortedDesc={this.state.sortDesc}
                />
              </div>
            )}
          </>
        }
      >
        {this.renderContent(isDbtComponent)}
      </CollapsibleMapping>
    );
  },

  renderContent(isDbtComponent) {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((input, index) => [input, index])
              .sort(this.handleSort)
              .map(([input, index]) => this.renderRow(input, index, isDbtComponent))
              .toArray()}
          </div>
        </div>
      </div>
    );
  },

  renderRow(input, key, isDbtComponent) {
    return (
      <TableInputMappingRow
        key={key}
        value={input}
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        singleMapping={this.props.singleMapping}
        replacementComponentId={this.props.replacementComponentId}
        tables={this.props.tables}
        buckets={this.props.buckets}
        otherMappings={this.props.value.filter((mapping, index) => index !== key)}
        onSave={(editing) => this.onSaveMapping(key, editing)}
        onDelete={() =>
          this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }))
        }
        destinationType={this.props.destinationType}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) =>
          this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) })
        }
        isDisabled={isDbtComponent && !this.props.generateSources}
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false,
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc,
    });
  },

  handleSort([inputA], [inputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    const nameA = inputA.get(this.state.sortBy, '');
    const nameB = inputB.get(this.state.sortBy, '');

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA.localeCompare(nameB) * sort;
  },

  onSaveMapping(key, editing) {
    const configData = prepareConfigDataWithEditedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'input',
      'tables',
      key,
      editing,
    );
    const changeDescription = hasInputMappingTables(editing)
      ? 'Add input tables'
      : `${key === 'new-mapping' ? 'Add' : 'Update'} input table ${editing.get('source')}`;

    return this.handleSave(configData, changeDescription);
  },

  onDeleteMapping(key) {
    const configData = prepareConfigDataWithDeletedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'input',
      'tables',
      key,
    );

    return this.handleSave(
      configData,
      `Delete input table mapping ${this.props.value.get(key).get('source')}`,
    );
  },

  handleSave(configData, changeDescription) {
    return saveMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      configData,
      changeDescription,
    );
  },
});

export default TableInputMapping;
