import React from 'react';
import { List, type Map } from 'immutable';

import { KEY } from '@/modules/ex-generic/mapping/endpoint';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';
import GenericPairs from './GenericPairs';

const Placeholders = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  rawEditingData: Map<string, any>;
}) => {
  if (!props.rawEditingData.getIn([KEY, 'childJob'])) {
    return null;
  }

  const rows = props.editing.get('placeholders', List());

  return (
    <CollapsibleBox
      defaultOpen
      title="Placeholders"
      titleSuffix={<DocumentationLink path="configuration/config/jobs/children/#placeholders" />}
    >
      <GenericPairs
        entity="Placeholder"
        valueTitle="Response Path"
        valueTooltip="A path to the response property, expected to be dot-separated. That is — a path members.id refers to the property id nested inside the property members."
        keyTooltip="Placeholder key to be used in the URL, e.g., users/{id}/detail"
        rows={rows}
        readOnly={props.readOnly}
        onChange={(placeholders) =>
          props.setEditing(props.editing.set('placeholders', placeholders))
        }
      />
    </CollapsibleBox>
  );
};

export default Placeholders;
