import React from 'react';
import classNames from 'classnames';

type Props = { children: React.ReactNode; className?: string };

const GraphTooltip = ({ children, className }: Props) => {
  return (
    <div
      className={classNames(
        'tw-animate-[fadeIn_100ms_100ms_ease-in-out_backwards] tw-whitespace-nowrap tw-rounded-lg tw-bg-neutral-900 tw-px-2.5 tw-py-2 tw-text-left tw-text-xs tw-text-white',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default GraphTooltip;
