import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { cn, Collapse } from 'design';
import { fromJS, List, Map } from 'immutable';

import { STAGE } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, sortByDisplayName } from '@/modules/storage/helpers';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import matchByWords from '@/utils/matchByWords';
import ActiveCountBadge from './ActiveCountBadge';

const TablesByBucketsPanel = createReactClass({
  propTypes: {
    renderTableRowFn: PropTypes.func.isRequired,
    configuredTables: PropTypes.array.isRequired,
    tablesWithSourceSearchInputMapping: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
    searchQuery: PropTypes.string,
    isExportedFn: PropTypes.func.isRequired,
    isShownFn: PropTypes.func.isRequired,
    onToggleBucketFn: PropTypes.func,
    isBucketToggledFn: PropTypes.func,
    renderDeletedTableRowFn: PropTypes.func,
  },

  render() {
    const deletedTables = this.getDeletedTables();
    const buckets = this._getFilteredBuckets();

    if (
      !buckets.count() &&
      !deletedTables.count() &&
      !this.props.tablesWithSourceSearchInputMapping.count()
    ) {
      return <p className="m-1">No tables found</p>;
    }

    return (
      <>
        {buckets
          .map((bucket) => {
            return this._renderBucketPanel(
              bucket.get('id'),
              <div className="flex-container flex-start">
                <BucketStageLabel placement="left" stage={bucket.get('stage')} />
                <DevBranchLabel bucket={bucket} />
                {bucket.get('displayName')}
              </div>,
              bucket.get('tables', List()).filter((table) => {
                return !this.props.tablesWithSourceSearchInputMapping.has(table.get('id'));
              }),
            );
          })
          .toArray()}
        {this.props.tablesWithSourceSearchInputMapping.count() > 0 &&
          this._renderBucketPanel(
            'by-metadata',
            'Tables searched by metadata',
            this.props.tablesWithSourceSearchInputMapping.toList(),
            this.props.renderDeletedTableRowFn,
          )}
        {deletedTables.count() > 0 &&
          this._renderBucketPanel(
            'non-existent',
            "Tables saved in the configuration that don't exist in Storage",
            deletedTables,
            this.props.renderDeletedTableRowFn,
          )}
      </>
    );
  },

  _renderBucketPanel(name, title, tables, renderRowFn = this.props.renderTableRowFn) {
    const activeCount = tables.filter((table) => this.props.isExportedFn(table.get('id'))).count();

    if (!tables.count()) {
      return null;
    }

    return (
      <Collapse
        open={
          !!(this.props.searchQuery && this.props.searchQuery.length) || this._isBucketToggled(name)
        }
        className="tw-mb-2 tw-rounded tw-border-2 tw-border-solid tw-border-neutral-50 last:tw-mb-0"
        header={
          <div
            className={cn(
              'tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-bg-neutral-50 tw-px-5 tw-py-4 tw-text-sm tw-text-neutral-400',
            )}
            onClick={(e) => {
              this._handleBucketSelect(name, e);
            }}
          >
            <div className="tw-flex tw-shrink-0 tw-items-center">
              <FontAwesomeIcon
                icon="chevron-down"
                className={cn('-tw-rotate-90 tw-text-xs group-data-[state=open]:tw-rotate-0')}
              />
              <div className="tw-ml-3 tw-py-1 tw-text-sm tw-font-medium tw-text-neutral-800">
                {title}
              </div>
            </div>
            <ActiveCountBadge totalCount={tables.size} activeCount={activeCount} />
          </div>
        }
      >
        <div className="tw-pt-1">{this._renderTablesList(tables, renderRowFn)}</div>
      </Collapse>
    );
  },

  _renderTablesList(tables, renderRowFn) {
    const childs = tables.map((table, index) => renderRowFn(table, index), this).toArray();

    return (
      <div className="table table-hover">
        <div className="tbody overflow-break-anywhere">{childs}</div>
      </div>
    );
  },

  _isBucketToggled(bucketName) {
    return this.props.isBucketToggledFn(bucketName);
  },

  _handleBucketSelect(bucketName, e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onToggleBucketFn(bucketName);
  },

  _getFilteredBuckets() {
    return this.props.buckets
      .map((bucket, bucketId) => bucket.set('tables', this._filterBucketTables(bucketId)))
      .filter((bucket) => {
        return (
          Object.values(STAGE).includes(bucket.get('stage')) && bucket.get('tables').count() > 0
        );
      })
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket));
  },

  _filterBucketTables(bucketId) {
    let bucketTables = this.props.tables.filter(
      (table) => table.getIn(['bucket', 'id']) === bucketId,
    );

    if (this.props.searchQuery) {
      bucketTables = bucketTables.filter((table) =>
        matchByWords(table.get('displayName'), this.props.searchQuery),
      );
    }

    return bucketTables
      .filter((table) => {
        const tableId = table.get('id');
        const isShown = this.props.isShownFn(tableId);
        const isExported = this.props.isExportedFn(tableId);
        return isExported || isShown;
      })
      .sortBy(sortByDisplayName);
  },

  // Return tables that no longer exists in Storage, but:
  // - still exist in the configuration
  // - does't not have storage input set to source_search
  getDeletedTables() {
    const configuredTables = fromJS(this.props.configuredTables);
    let result = List();
    configuredTables.forEach((table) => {
      if (
        !this.props.tables.has(table) &&
        !this.props.tablesWithSourceSearchInputMapping.has(table)
      ) {
        result = result.push(Map({ id: table }));
      }
    });
    return result;
  },
});

export default TablesByBucketsPanel;
