import type { Dispatch, SetStateAction } from 'react';
import { getIncomers, getOutgoers } from '@xyflow/react';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';
import { HIGHLIGHT_MUTED_OPACITY } from './constants';

// get in or out edges of a node on one level only
const oneLevelOfSiblingNodes = (
  nodeId: string,
  nodes: CustomNode[],
  edges: CustomEdge[],
  isIncomers: boolean,
) => {
  return isIncomers
    ? getIncomers({ id: nodeId }, nodes, edges)
    : getOutgoers({ id: nodeId }, nodes, edges);
};

// check whether investigated node is really my sibling by checking if it contains an edge where original node source or target is me
const isConfigSiblingMySibling = (
  isIncomers: boolean,
  edges: CustomEdge[],
  investigatedNodeId: string,
  originalNodeId: string,
) => {
  if (isIncomers) {
    return edges.some(
      (edge) =>
        edge.source === investigatedNodeId &&
        'originalTarget' in edge &&
        edge.originalTarget === originalNodeId,
    );
  }

  return edges.some(
    (edge) =>
      edge.target === investigatedNodeId &&
      'originalSource' in edge &&
      edge.originalSource === originalNodeId,
  );
};

const isNotDuplication = (arr: string[], nodeId: string) => !arr.includes(nodeId);

const getRelatedNodesInPath = (
  nodes: CustomNode[],
  edges: CustomEdge[],
  buffer: string[],
  mode: 'incomers' | 'outgoers',
) => {
  const result: string[] = [];
  const visitedNodes: string[] = [];

  while (buffer.length > 0) {
    const nodeId = buffer.shift();

    if (!nodeId) {
      break;
    }

    const isIncomers = mode === 'incomers';
    const nodesInPath = oneLevelOfSiblingNodes(nodeId, nodes, edges, isIncomers);

    nodesInPath.map((node) => {
      if (nodeId === node.id) {
        return;
      }

      // we add every type of node to result if it's not already there
      if (isNotDuplication(result, node.id)) {
        result.push(node.id);
      }

      // if node is table we want to explore it and its edges in next call
      if (
        node.type !== 'component' &&
        node.type !== 'transformation' &&
        isNotDuplication(visitedNodes, node.id)
      ) {
        buffer.push(node.id);
        visitedNodes.push(node.id);
        return;
      }

      // if node is configuration we only want to explore nodes that were originally connected with my node
      // so node is now configuration, we get its siblings on one level
      oneLevelOfSiblingNodes(node.id, nodes, edges, isIncomers)
        // then we only leave those nodes that have and edge with original node being me
        .filter((siblingNode) =>
          isConfigSiblingMySibling(isIncomers, edges, siblingNode.id, nodeId),
        )
        .forEach((bufferNode) => {
          if (isNotDuplication(result, bufferNode.id)) {
            result.push(bufferNode.id);
          }
          if (isNotDuplication(visitedNodes, bufferNode.id)) {
            buffer.push(bufferNode.id);
            visitedNodes.push(bufferNode.id);
          }
        });
    });
  }

  return result;
};

export const highlightPath = (
  nodeId: string,
  nodes: CustomNode[],
  edges: CustomEdge[],
  setNodes: Dispatch<SetStateAction<CustomNode[]>>,
  setEdges: Dispatch<SetStateAction<CustomEdge[]>>,
) => {
  if (!nodeId || !edges) {
    return;
  }

  const allIncomers = getRelatedNodesInPath(nodes, edges, [nodeId], 'incomers');
  const allOutgoers = getRelatedNodesInPath(nodes, edges, [nodeId], 'outgoers');

  setNodes((prevNodes) => {
    return prevNodes.map((prevNode) => {
      const noEdges = allIncomers.length === 0 && allOutgoers.length === 0;
      const containsIncomersOrOutgoers = allIncomers.length > 0 || allOutgoers.length > 0;

      const highlight = noEdges
        ? prevNode.id === nodeId
        : containsIncomersOrOutgoers
        ? prevNode.id === nodeId ||
          allIncomers.includes(prevNode.id) ||
          allOutgoers.includes(prevNode.id)
        : true;

      return {
        ...prevNode,
        style: {
          ...prevNode.style,
          opacity: highlight ? 1 : HIGHLIGHT_MUTED_OPACITY,
          pointerEvents: highlight ? 'all' : 'none',
        },
      };
    });
  });

  const incomersWithCurrentNode = allIncomers ? [nodeId, ...allIncomers] : [];
  const outgoersWithCurrentNode = allOutgoers ? [nodeId, ...allOutgoers] : [];

  setEdges((prevEdges) => {
    return prevEdges.map((prevEdge) => {
      const highlight =
        (incomersWithCurrentNode.includes(prevEdge.source) &&
          incomersWithCurrentNode.includes(prevEdge.target)) ||
        (outgoersWithCurrentNode.includes(prevEdge.source) &&
          outgoersWithCurrentNode.includes(prevEdge.target));

      return {
        ...prevEdge,
        style: {
          ...prevEdge.style,
          opacity: highlight ? 1 : HIGHLIGHT_MUTED_OPACITY,
        },
      };
    });
  });
};
