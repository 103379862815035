import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import dataLineageImageLocked from '../../../images/illustrations/data-lineage-locked.png';

import { REQUESTABLE_FEATURES } from '@/constants/features';
import FeatureRequestModal from '@/react/common/FeatureRequestModal';
import { fileUrl } from '@/utils/fileHelpers';

const TeasingContent = ({ className }: { className?: string }) => {
  const feature = REQUESTABLE_FEATURES.FEATURE_AI_LINEAGE;

  return (
    <div className={classNames('tw-flex tw-flex-col tw-items-center', className)}>
      <h2 className="tw-text-2xl">Unlock the Power of Data Lineage</h2>
      <h3 className="tw-m-0 tw-text-center tw-text-base tw-text-neutral-400">
        Get access to our comprehensive data lineage capabilities and AI-powered <br /> insights to
        optimize your data operations.
      </h3>
      <FeatureRequestModal feature={feature}>
        <Button className="btn-big tw-my-8" bsStyle="success">
          <FontAwesomeIcon icon="plus" className="icon-addon-right" fixedWidth />
          {feature.buttonLabel}
        </Button>
      </FeatureRequestModal>
      <img src={fileUrl(dataLineageImageLocked)} loading="lazy" alt="Data Stream" />
    </div>
  );
};

export default TeasingContent;
