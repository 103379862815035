import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

const OrchestrationTaskRunButton = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    onRun: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
  },

  getInitialState() {
    return {
      isLoading: false,
      showModal: false,
    };
  },

  componentWillUnmount() {
    this.cancellablePromise && this.cancellablePromise.cancel();
  },

  render() {
    return (
      <>
        {this.renderOpenButton()}
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Run Orchestration Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You are about to run the single task{' '}
              <strong>{this.props.task.getIn(['config', 'name'])}</strong> from the orchestration{' '}
              <strong>{this.props.orchestration.get('name')}</strong> manually.
            </p>
            <p>Notifications will be sent only to you.</p>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel="Run"
              onSave={this.handleRunStart}
              isSaving={this.state.isLoading}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  },

  renderOpenButton() {
    return (
      <RowActionMenuItem onSelect={this.open} onKeyDown={this.props.onKeyDown}>
        {this.props.isLoading ? <Loader /> : <FontAwesomeIcon icon="circle-play" fixedWidth />}
        Run orchestration
      </RowActionMenuItem>
    );
  },

  handleRunStart() {
    this.setState({ isLoading: true });
    this.cancellablePromise = this.props.onRun(this.props.task).finally(() => {
      if (!this.cancellablePromise.isCancelled()) {
        this.setState({ isLoading: false });
      }
    });
    this.close();
  },

  close() {
    this.setState({ showModal: false });
  },

  open() {
    this.setState({ showModal: true });
  },
});

export default OrchestrationTaskRunButton;
