import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Link } from 'design';
import _ from 'underscore';
import { rtrim, trim } from 'underscore.string';

import { routeNames as storageRoutes } from '@/modules/storage/constants';
import ApplicationStore from '@/stores/ApplicationStore';

const ExternalBucketLink = createReactClass({
  propTypes: {
    stackUrl: PropTypes.string,
    projectId: PropTypes.number,
    bucketId: PropTypes.string,
    children: PropTypes.any,
  },

  getBucketUrl() {
    const projectUrlTemplate = ApplicationStore.getProjectUrlTemplate();
    const projectPath = _.template(projectUrlTemplate)({ projectId: this.props.projectId });

    return `${rtrim(this.props.stackUrl, '/')}/${trim(projectPath, '/')}/${storageRoutes.ROOT}/${
      this.props.bucketId
    }`;
  },

  render() {
    if (this.props.stackUrl && this.props.projectId && this.props.bucketId) {
      return <Link href={this.getBucketUrl()}>{this.props.children}</Link>;
    }

    return this.props.children;
  },
});

export default ExternalBucketLink;
