import { List, Map } from 'immutable';
import _ from 'underscore';

import { APIFY_APIFY as COMPONENT_ID } from '@/constants/componentIds';
import callDockerAction from '@/modules/components/DockerActionsApi';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import storeProvisioning from './storeProvisioning';

export default function (configId: string) {
  const store = storeProvisioning(configId);

  function updateLocalState(path: string | string[], data: unknown) {
    const localState = store.getLocalState();
    const newLocalState = localState.setIn(([] as string[]).concat(path), data);
    componentsActions.updateLocalState(COMPONENT_ID, configId, newLocalState, path);
  }

  function prepareLocalState(path: string[]) {
    const updateLocalSubstateFn = (subPath: string | string[], newData: unknown) => {
      if (_.isEmpty(subPath)) {
        return updateLocalState(([] as string[]).concat(path), newData);
      } else {
        return updateLocalState(([] as string[]).concat(path).concat(subPath), newData);
      }
    };

    return {
      localState: store.getLocalState(path),
      updateLocalState: updateLocalSubstateFn,
      prepareLocalState: (newSubPath: string | string[]) => {
        return prepareLocalState(([] as string[]).concat(path).concat(newSubPath));
      },
    };
  }

  function saveConfigData(
    data: Map<string, any>,
    waitingPath: string | string[],
    changeDescription: string,
  ) {
    // check default output bucket and save default if non set
    updateLocalState(waitingPath, true);
    return componentsActions
      .saveComponentConfigData(COMPONENT_ID, configId, data, changeDescription)
      .then(() => updateLocalState(waitingPath, false));
  }

  function saveConfig(newParams: Map<string, any>, inputTableId: string | undefined) {
    let data = store.configData.set('parameters', newParams);
    if (inputTableId) {
      const newTable = Map({ source: inputTableId });
      data = data.setIn(['storage', 'input', 'tables'], List().push(newTable));
    } else {
      data = data.delete('storage');
    }
    return saveConfigData(data, 'saving', 'Set up component');
  }

  return {
    updateLocalState,
    prepareLocalState,
    saveConfig,
    loadActors(settings: Map<string, any>) {
      return callDockerAction(COMPONENT_ID, 'listActors', {
        configData: {
          parameters: { '#token': settings.get('#token') },
        },
      });
    },
    loadTasks(settings: Map<string, any>) {
      return callDockerAction(COMPONENT_ID, 'listTasks', {
        configData: {
          parameters: { '#token': settings.get('#token') },
        },
      });
    },
  };
}
