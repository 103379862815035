import React from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Tabs, TabsContent, Tooltip } from 'design';
import type { Map } from 'immutable';

import { CODE_EDITOR_MODE } from '@/constants/localStorageKeys';
import { testRequest } from '@/modules/ex-generic/actions';
import CodeEditor from '@/react/common/CodeEditor';
import CodeEditorCloseButton from '@/react/common/CodeEditorCloseButton';
import CodeEditorExpandButton from '@/react/common/CodeEditorExpandButton';
import FullScreenModal from '@/react/common/FullScreenModal';
import Loader from '@/react/common/Loader';
import Truncated from '@/react/common/Truncated';
import { getItem, setItem } from '@/utils/localStorage';

const MODES = {
  FULL_SCREEN: 'full-screen',
  HALF_SCREEN: 'half-screen',
};

const TABS = {
  RECORDS: { title: 'Records', key: 'records', default: [] },
  RESPONSE: { title: 'Response', key: 'response', default: {} },
  REQUEST: { title: 'Request', key: 'request', default: {} },
  LOG: { title: 'Debug log', key: 'debug_log', default: '' },
};

const CLOSE_ANIMATION_DURATION = 300;

const TestModal = (props: {
  jobName: string;
  endpointPath: number[];
  prepareParameters: () => Map<string, any>;
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [isHiding, setIsHiding] = React.useState(false);
  const [mode, setMode] = React.useState(getItem(CODE_EDITOR_MODE, MODES.HALF_SCREEN));
  const [activeTab, setActiveTab] = React.useState(TABS.RECORDS.key);
  const [isLoading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState<Record<string, any> | null>(null);

  const isHalfScreen = mode === MODES.HALF_SCREEN;

  const statusCode = result?.response?.status_code;
  const isSuccessful = statusCode >= 200 && statusCode < 300;
  const isErrored = statusCode === 0 || statusCode >= 300;

  const statusClassMap = {
    '!tw-text-error-500': isErrored,
    '!tw-text-primary-500': isSuccessful,
    'tw-text-neutral-400': !isSuccessful && !isErrored,
  };

  const testEndpoint = () => {
    setLoading(true);
    return testRequest(props.prepareParameters(), props.endpointPath)
      .then((result) => {
        setResult(result);

        if (result.response?.status_code === 0 || result.response?.status_code >= 300) {
          setActiveTab(TABS.LOG.key);
        }
      })
      .finally(() => setLoading(false));
  };

  const changeMode = (mode: string) => {
    setMode(mode);
    setItem(CODE_EDITOR_MODE, mode);
  };

  const handleOpen = () => {
    setOpen(true);
    !result && testEndpoint();
  };

  const handleHide = () => {
    if (mode === MODES.FULL_SCREEN) {
      return setOpen(false);
    }

    setIsHiding(true);
    setTimeout(() => {
      setOpen(false);
      setIsHiding(false);
    }, CLOSE_ANIMATION_DURATION);
  };

  const renderEditor = (data: string | Record<string, any> | Record<string, any>[]) => {
    const isString = typeof data === 'string';
    const value =
      isLoading && !result ? 'Processing...' : !isString ? JSON.stringify(data, null, '  ') : data;
    const mode = !isString ? 'application/json' : 'text/x-sh';

    return <CodeEditor withSearch value={value} options={{ readOnly: true, mode }} />;
  };

  return (
    <>
      <Tooltip tooltip="Test endpoint" placement="top">
        <Button onClick={handleOpen}>
          <FontAwesomeIcon icon="bug" className={classNames('btn-icon', statusClassMap)} />
          Test Endpoint
        </Button>
      </Tooltip>
      {isOpen && (
        <CSSTransition
          appear
          timeout={CLOSE_ANIMATION_DURATION}
          in={isOpen && !isHiding}
          classNames="slide-up"
        >
          <FullScreenModal
            onHide={handleHide}
            hideOverflow={!isHalfScreen}
            className={classNames('full-screen-editor CodeMirror-search-dialog-root', {
              'half-screen': isHalfScreen,
            })}
          >
            <Modal.Header>
              <div className="CodeMirror-search-dialog-wrapper flex-container">
                <Modal.Title className="flex-container flex-start mr-2 tw-break-all">
                  {isLoading ? (
                    <Loader className="tw-mr-3 !tw-text-2xl tw-text-neutral-400" fixedWidth />
                  ) : (
                    <FontAwesomeIcon
                      fixedWidth
                      icon="bug"
                      className={classNames('tw-mr-3', statusClassMap)}
                    />
                  )}
                  <Truncated text={`Endpoint "${props.jobName}" test`} />
                </Modal.Title>
                <ButtonToolbar>
                  <Button onClick={testEndpoint} disabled={isLoading}>
                    <FontAwesomeIcon icon="arrows-rotate" className="btn-icon" fixedWidth />
                    Refresh
                  </Button>
                  <CodeEditorExpandButton
                    isCollapsed={isHalfScreen}
                    onClick={() => changeMode(isHalfScreen ? MODES.FULL_SCREEN : MODES.HALF_SCREEN)}
                  />
                  <CodeEditorCloseButton onClick={handleHide} />
                </ButtonToolbar>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                value={activeTab}
                onValueChange={setActiveTab}
                triggers={Object.values(TABS).map((tab) => ({ value: tab.key, title: tab.title }))}
              >
                {Object.values(TABS).map((tab) => (
                  <TabsContent
                    className={classNames(
                      'tw-relative',
                      isHalfScreen ? 'tw-h-[calc(50vh-204px)]' : 'tw-h-[calc(100vh-178px)]',
                    )}
                    key={tab.key}
                    value={tab.key}
                    title={tab.title}
                  >
                    {renderEditor(result?.[tab.key] || tab.default)}
                  </TabsContent>
                ))}
              </Tabs>
            </Modal.Body>
          </FullScreenModal>
        </CSSTransition>
      )}
    </>
  );
};

export default TestModal;
