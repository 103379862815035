import Promise from 'bluebird';

import { SERVICE_SYRUP, SERVICE_SYRUP_QUEUE } from '@/constants/serviceIds';
import dayjs from '@/date';
import { prepareJobsQuery } from '@/modules/dev-branches/helpers';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import { JOBS_LIMIT_FOR_PREVIOUS_JOBS } from './Constants';
import { isPartialJob } from './helpers';

export const createUrl = (path) => {
  return ServicesStore.getServiceUrl(SERVICE_SYRUP) + '/' + SERVICE_SYRUP_QUEUE + '/' + path;
};

const createRequest = (method, path) =>
  request(method, createUrl(path)).set('X-StorageApi-Token', ApplicationStore.getSapiTokenString());

export default {
  getJobsByQuery(query) {
    return createRequest('GET', 'jobs')
      .query({ q: prepareJobsQuery(query) })
      .promise()
      .then((response) => response.body);
  },

  getJobsParametrized(query, limit, offset) {
    return createRequest('GET', 'jobs')
      .query({ q: prepareJobsQuery(query) })
      .query({ limit: limit })
      .query({ offset: offset })
      .promise()
      .then((response) => response.body);
  },

  getJobDetail(jobId) {
    return createRequest('GET', `jobs/${jobId}`)
      .promise()
      .then((response) => response.body);
  },

  terminateJob(jobId) {
    return createRequest('POST', `jobs/${jobId}/kill`)
      .promise()
      .then((response) => response.body);
  },

  loadPreviousJobs(job) {
    if (isPartialJob(job)) {
      return Promise.resolve([]);
    }

    let query = `params.config:${job.getIn(['params', 'config'])}`;

    if (job.getIn(['params', 'row'])) {
      query += ` AND params.row:${job.getIn(['params', 'row'])}`;
    }

    if (job.getIn(['params', 'configBucketId'])) {
      query += ` AND params.configBucketId:${job.getIn(['params', 'configBucketId'])}`;
    }

    return createRequest('GET', 'jobs')
      .query({
        q: prepareJobsQuery(query),
        since: dayjs(job.get('createdTime')).subtract(6, 'months').toISOString(),
        until: dayjs(job.get('createdTime')).toISOString(),
        offset: 0,
        limit: JOBS_LIMIT_FOR_PREVIOUS_JOBS,
      })
      .promise()
      .then((response) => response.body);
  },
};
