import React from 'react';
import classnames from 'classnames';

import CreatedDate from '@/react/common/CreatedDate';
import Truncated from '@/react/common/Truncated';

const InternalDataAppJob = (props: { time?: string }) => {
  return (
    <div className="tw-inline-flex tw-flex-col">
      {props.time && <CreatedDate createdTime={props.time} absolute />}
      <Truncated
        text="Internal Data App run"
        className={classnames({ 'tw-text-neutral-400': props.time })}
      />
    </div>
  );
};

export default InternalDataAppJob;
