import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { List, Map } from 'immutable';

import { DEFAULT_RETRY_CODES } from '@/modules/ex-generic/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import Select from '@/react/common/Select';
import DocumentationLink from './DocumentationLink';

const Retry = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <CollapsibleBox
      title="Retry Config"
      titleSuffix={<DocumentationLink path="configuration/api/#retry-configuration" />}
    >
      <div className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-4">
        <FormGroup className="tw-col-span-3">
          <ControlLabel>Retry Header</ControlLabel>
          <FormControl
            type="text"
            disabled={props.readOnly}
            value={props.editing.get('retryHeader')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setEditing(props.editing.set('retryHeader', e.target.value));
            }}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Max Retries</ControlLabel>
          <FormControl
            min={0}
            type="number"
            disabled={props.readOnly}
            value={props.editing.get('maxRetries')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const maxRetries = parseInt(e.target.value, 10) || 0;
              props.setEditing(props.editing.set('maxRetries', maxRetries));
            }}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <ControlLabel>Retry Codes</ControlLabel>
        <Select
          multi
          allowCreate
          placeholder="Select codes"
          disabled={props.readOnly}
          promptTextCreator={(label) => `Create code "${label}"`}
          isValidNewOption={(value: string) => {
            const code = parseInt(value, 10);
            return code >= 400 && code < 600;
          }}
          value={props.editing.get('codes')}
          onChange={(codes: List<string | number>) => {
            props.setEditing(props.editing.set('codes', codes.map(Number)));
          }}
          options={DEFAULT_RETRY_CODES.map((value) => ({ label: value, value }))}
        />
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Retry;
