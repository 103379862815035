import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import {
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { getAllowedTransformations, getFolderFromMetadata } from '@/modules/components/helpers';
import BlankComponentsPage from '@/modules/components/react/pages/BlankComponentsPage';
import TransformationConfigs from '@/modules/components/react/pages/TransformationConfigs';
import TransformationTabs from '@/modules/components/react/pages/TransformationTabs';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { hasSandbox, prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import FilterPanel from '@/react/common/FilterPanel';
import NoEntityCreated from '@/react/common/NoEntityCreated';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import { routeNames } from './constants';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      SandboxesStore,
      NotificationsStore,
      ComponentsStore,
      JobsStore,
    ),
  ],

  getStateFromStores() {
    const isDevModeActive = DevBranchesStore.isDevModeActive();

    return {
      isDevModeActive,
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasFlows: ApplicationStore.hasFlows(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      latestJobs: JobsStore.getLatestConfigJobs(),
      allowedCreateWorkspace: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ).filter((component, componentId) => hasSandbox(componentId)),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null,
      ).get('clusters'),
      allComponents: ComponentsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      installedComponents: InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      expandedFolders: InstalledComponentsStore.getExpandedFolders(componentTypes.TRANSFORMATION),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
    };
  },

  getInitialState() {
    return {
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    return (
      <TransformationTabs activeTab={routeNames.ROOT}>
        {this.state.installedComponents.isEmpty() && this.state.hasPayAsYouGo ? (
          <BlankComponentsPage type="transformation" />
        ) : (
          <>
            {this.renderSearch()}
            {this.renderTransformationsConfigs()}
          </>
        )}
      </TransformationTabs>
    );
  },

  renderSearch() {
    return (
      <FilterPanel
        query={this.state.filterQuery}
        onChange={(query) => {
          this.setState({ filterQuery: query });
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
        placeholder={this.getPlaceholder}
      />
    );
  },

  renderTransformationsConfigs() {
    const transformationsFiltered = this.getComponentsFiltered();

    if (!!this.state.filterQuery && transformationsFiltered.isEmpty()) {
      return <NoResultsFound entityName="transformations" />;
    }

    if (transformationsFiltered.isEmpty()) {
      return <NoEntityCreated entityName="transformations" />;
    }

    return (
      <TransformationConfigs
        readOnly={this.state.readOnly}
        admins={this.state.admins}
        currentAdmin={this.state.currentAdmin}
        notifications={this.state.notifications}
        latestJobs={this.state.latestJobs}
        allConfigurations={this.state.allConfigurations}
        allTables={this.state.allTables}
        configurations={transformationsFiltered
          .flatMap((component) => {
            return component.get('configurations').map((config) => {
              return config.set('component', component);
            });
          })
          .map((configuration) => {
            const isDummyItem =
              this.state.isDevModeActive &&
              [KEBOOLA_NO_CODE_DBT_TRANSFORMATION, KEBOOLA_LEGACY_TRANSFORMATION].includes(
                configuration.getIn(['component', 'id']),
              );

            return configuration.set('isDummyItem', isDummyItem).set('isUnselectable', isDummyItem);
          })}
        availableConfigurations={this.state.installedComponents}
        isSearching={!!this.state.filterQuery}
        sandboxComponent={this.state.sandboxComponent}
        allowedCreateWorkspace={this.state.allowedCreateWorkspace}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        hasNewQueue={this.state.hasNewQueue}
        hasFlows={this.state.hasFlows}
        isDevModeActive={this.state.isDevModeActive}
        hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
        hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
        componentsMetadata={this.state.componentsMetadata}
        expandedFolders={this.state.expandedFolders}
      />
    );
  },

  getPlaceholder() {
    const transformations = this.state.installedComponents
      .flatMap((transformation) => transformation.get('configurations'))
      .count();
    const folders = this.state.installedComponents
      .map((transformation) => {
        return transformation.get('configurations', Map()).map((configuration) => {
          return getFolderFromMetadata(
            this.state.componentsMetadata.getIn([
              transformation.get('id'),
              configuration.get('id'),
            ]),
          );
        });
      })
      .flatten(1)
      .filter(Boolean)
      .toSet();

    if (!folders.isEmpty()) {
      return `Search folders (${folders.count()}) and transformations (${transformations})`;
    }

    return `Search transformations (${transformations})`;
  },

  getComponentsFiltered() {
    if (!this.state.filterQuery) {
      return this.state.installedComponents;
    }

    return this.state.installedComponents
      .map((component, componentId) => {
        if (matchByWords(component.get('name'), this.state.filterQuery)) {
          return component;
        }

        return component.set(
          'configurations',
          component.get('configurations', Map()).filter((configuration, configurationId) => {
            const folder = getFolderFromMetadata(
              this.state.componentsMetadata.getIn([componentId, configurationId]),
            );

            return matchByWords(
              [configuration.get('name'), configuration.get('description'), folder],
              this.state.filterQuery,
            );
          }),
        );
      })
      .filter((component) => component.get('configurations').count() > 0);
  },
});

export default Index;
