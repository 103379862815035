import type { Map } from 'immutable';
import { fromJS } from 'immutable';

const KEY = 'debug';

const mapToState = (parameters: Map<string, any>) => {
  return fromJS({ [KEY]: { debug: parameters.getIn(['config', 'debug'], false) } });
};

const mapToApi = (parameters: Map<string, any>, editing: Map<string, any>) => {
  return parameters.setIn(['config', 'debug'], editing.getIn([KEY, 'debug']));
};

export { KEY, mapToState, mapToApi };
