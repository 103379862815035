import Promise from 'bluebird';

import dispatcher from '@/Dispatcher';
import ComponentsStore from './stores/ComponentsStore';
import { ActionTypes } from './Constants';
import StorageApi from './StorageApi';

export default {
  receiveAllComponents(componentsRaw) {
    dispatcher.handleViewAction({
      type: ActionTypes.COMPONENTS_LOAD_SUCCESS,
      components: componentsRaw,
    });
  },

  loadComponent(componentId) {
    if (ComponentsStore.hasComponentDetails(componentId)) {
      return Promise.resolve();
    }

    return this.loadComponentForce(componentId);
  },

  loadComponentForce(componentId) {
    return StorageApi.getComponent(componentId).then((component) => {
      dispatcher.handleViewAction({
        type: ActionTypes.COMPONENT_LOAD_SUCCESS,
        component,
      });

      return component;
    });
  },

  saveComponentOverrides(componentId, overrides) {
    dispatcher.handleViewAction({
      type: ActionTypes.COMPONENT_SET_OVERRIDES,
      componentId,
      overrides,
    });
  },
};
