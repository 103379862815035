import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';

const Configuration = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Source</h2>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Table</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  <StorageApiTableLinkEx tableId={this.props.value.source} />
                </FormControl.Static>
              </div>
            </FormGroup>
          </div>
        </div>

        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Destination</h2>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Path</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={this.props.value.destination}
                  onChange={(e) => {
                    this.props.onChange({ destination: e.target.value.trim() });
                  }}
                  placeholder="myfolder/file.csv"
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  Destination path including filename, eg. <code>myfolder/file.csv</code>.
                </HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </>
    );
  },
});

export default Configuration;
