import Promise from 'bluebird';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import TriggersStore from '@/modules/components/stores/StorageTriggersStore';
import { actionTypes } from '@/modules/orchestrations-v2/constants';
import { prepareConfigurationForSave } from '@/modules/orchestrations-v2/helpers';
import { clearLocalState } from '@/modules/orchestrations-v2/localState';
import { removeScheduler } from '@/modules/scheduler/actions';
import RoutesStore from '@/stores/RoutesStore';
import { routeNames } from './constants';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadFlow = (configId) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_ORCHESTRATOR, configId);
};

const saveFlow = (config, tasks, phases) => {
  const preparedTasks = tasks.map((task) => {
    return !task.getIn(['task', 'configId']) ? task.set('enabled', false) : task;
  });

  const configuration = prepareConfigurationForSave(
    config.get('configuration'),
    phases,
    preparedTasks,
  ).toJS();

  return updateFlowConfiguration(
    config.get('id'),
    { configuration: JSON.stringify(configuration) },
    'Update flow tasks',
  ).then(() => clearLocalState(config.get('id')));
};

const createFlow = (name, description = '') => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_ORCHESTRATOR, {
    name,
    description,
  }).then((response) => {
    RoutesStore.getRouter().transitionTo(routeNames.DETAIL, { config: response.id });
    return null;
  });
};

const deleteFlow = (flow, options = {}) => {
  return InstalledComponentsActionCreators.deleteConfiguration(
    KEBOOLA_ORCHESTRATOR,
    flow.get('id'),
    { transition: false, notification: true, ...options },
  ).then(() => {
    if (flow.hasIn(['schedulerConfiguration', 'id'])) {
      return removeScheduler(flow.getIn(['schedulerConfiguration', 'id']));
    }

    return null;
  });
};

const updateFlowConfiguration = (configurationId, configuration, changeDescription) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    configurationId,
    configuration,
    changeDescription,
  );
};

const loadTriggersForce = () => {
  Dispatcher.handleViewAction({
    type: actionTypes.TRIGGERS_LOAD,
  });

  return StorageApi.listTriggers()
    .then((triggers) => {
      return Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_SUCCESS,
        triggers,
      });
    })
    .catch((error) => {
      Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_ERROR,
      });

      throw error;
    });
};

const loadTriggers = () => {
  if (TriggersStore.getIsLoaded()) {
    loadTriggersForce();

    return Promise.resolve();
  }

  return loadTriggersForce();
};

const createTrigger = (data) => {
  return StorageApi.createTrigger(data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_CREATE_SUCCESS,
      trigger,
    });
  });
};

const updateTrigger = (id, data) => {
  return StorageApi.updateTrigger(id, data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_UPDATE_SUCCESS,
      trigger,
    });
  });
};

const deleteTrigger = (id) => {
  return StorageApi.deleteTrigger(id).then(() => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_DELETE_SUCCESS,
      id,
    });
  });
};

export {
  loadAll,
  loadFlow,
  saveFlow,
  createFlow,
  deleteFlow,
  updateFlowConfiguration,
  loadTriggersForce,
  loadTriggers,
  createTrigger,
  updateTrigger,
  deleteTrigger,
};
