import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import parseBucket from './utils/parseBucket';
import parseTransformation from './utils/parseTransformation';

const transformationsApi = {
  createTransformationBucket(data, changeDescription) {
    return InstalledComponentsApi.createConfiguration(
      KEBOOLA_LEGACY_TRANSFORMATION,
      data,
      changeDescription,
    ).then(parseBucket);
  },

  restoreTransformationBucket(bucketId) {
    return InstalledComponentsApi.restoreConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId);
  },

  createTransformation(bucketId, data, changeDescription) {
    let form = {
      name: data.name,
      description: data.description ?? '',
    };

    delete data.name;
    delete data.description;

    form.configuration = JSON.stringify({ parameters: data });

    return InstalledComponentsApi.createConfigurationRow(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
      form,
      changeDescription,
    ).then(parseTransformation);
  },

  saveTransformation(bucketId, transformationId, data, changeDescription) {
    delete data.queriesString;

    const form = {
      configuration: JSON.stringify({ parameters: data }),
      changeDescription: changeDescription,
    };

    return InstalledComponentsApi.updateConfigurationRow(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
      transformationId,
      form,
    ).then(parseTransformation);
  },
};

export default transformationsApi;
