import Promise from 'bluebird';
import qs from 'qs';
import _ from 'underscore';

import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { SERVICE_QUEUE } from '@/constants/serviceIds';
import dayjs from '@/date';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import {
  COMPONENTS_WITH_RUN_RESULTS_COLUMN,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_LIMIT_FOR_RUN_RESULTS,
  JOBS_STATUS,
  JOBS_TYPES,
  MAX_JOBS_LIMIT,
} from './constants';
import { isContainerJob } from './helpers';

const flattenJobs = (jobs) => {
  return jobs.reduce((allJobs, group) => allJobs.concat(group.jobs), []);
};

const getBranchId = () => {
  return DevBranchesStore.isDevModeActive()
    ? DevBranchesStore.getCurrentId()
    : DevBranchesStore.getDefaultBranchId();
};

const GROUP_BY_CONFIGS = ['projectId', 'branchId', 'componentId', 'configId'];

const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_QUEUE)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

export default {
  createJob(component, params, mode = 'run') {
    return createRequest('POST', 'jobs')
      .send(
        DevBranchesStore.isDevModeActive()
          ? { mode, component, branchId: DevBranchesStore.getCurrentId(), ...params }
          : { mode, component, ...params },
      )
      .promise()
      .then((response) => response.body);
  },

  createProductionJob(component, params, mode = 'run') {
    return createRequest('POST', 'jobs')
      .send({ mode, component, ...params })
      .promise()
      .then((response) => response.body);
  },

  getJobs(params = {}) {
    if (params.componentType) {
      params = { ...params, componentProps: { type: params.componentType } };
      delete params.componentType;
    }

    return createRequest('GET', 'search/jobs')
      .query(qs.stringify({ branchId: getBranchId(), ...params }, { encode: false }))
      .promise()
      .then((response) => response.body);
  },

  getSiblingJobs(job, options) {
    if (!job.get('config')) {
      return Promise.resolve([]);
    }

    return this.getJobs({
      offset: options?.offset || 0,
      limit: options?.limit || JOBS_LIMIT_FOR_GRAPH,
      ...(!!job.get('branchId') && { branchId: job.get('branchId') }),
      config: job.get('config'),
      component: job.get('component'),
      type: job.get('type'),
      status: options.status || [
        JOBS_STATUS.SUCCESS,
        JOBS_STATUS.ERROR,
        JOBS_STATUS.TERMINATED,
        JOBS_STATUS.CANCELLED,
        JOBS_STATUS.WARNING,
      ],
      ...(options?.previous
        ? { createdTimeTo: dayjs(job.get('createdTime')).add(1, 'second').toISOString() }
        : {
            createdTimeFrom: dayjs(job.get('createdTime')).add(1, 'second').toISOString(),
            sortOrder: 'asc',
          }),
    });
  },

  getChildJobs(job) {
    if (!isContainerJob(job)) {
      return Promise.resolve([]);
    }

    let allChildJobs = [];

    const loadAll = (params) => {
      return this.getJobs(params).then((jobs) => {
        allChildJobs = allChildJobs.concat(jobs);

        if (jobs.length === params.limit) {
          return loadAll({ ...params, offset: params.offset + params.limit });
        }

        return allChildJobs;
      });
    };

    return loadAll({
      parentRunId: job.get('runId'),
      ...(!!job.get('branchId') && { branchId: job.get('branchId') }),
      limit: MAX_JOBS_LIMIT,
      offset: 0,
    });
  },

  getJobDetail(id) {
    return createRequest('GET', `jobs/${id}`)
      .promise()
      .then((response) => response.body);
  },

  terminateJob(id) {
    return createRequest('POST', `jobs/${id}/kill`)
      .promise()
      .then((response) => response.body);
  },

  getConfigurationsJobs(componentId, configIds) {
    const jobsPerGroup = COMPONENTS_WITH_RUN_RESULTS_COLUMN.includes(componentId)
      ? JOBS_LIMIT_FOR_RUN_RESULTS
      : 1;

    return Promise.map(
      _.chunk(configIds, 50),
      (configIdsChunk) => {
        return createRequest('GET', 'search/grouped-jobs')
          .query(
            qs.stringify(
              {
                groupBy: GROUP_BY_CONFIGS,
                limit: 50,
                jobsPerGroup,
                branchId: getBranchId(),
                component: [componentId, KEBOOLA_EX_SAMPLE_DATA],
                config: configIdsChunk,
                ...(componentId === KEBOOLA_ORCHESTRATOR && {
                  type: [JOBS_TYPES.ORCHESTRATION_CONTAINER],
                }),
              },
              { encode: false },
            ),
          )
          .promise()
          .then((response) => flattenJobs(response.body));
      },
      { concurrency: 2 },
    ).then((jobs) => jobs.flat());
  },

  getLatestJobForAllConfigurations(type) {
    return createRequest('GET', 'search/grouped-jobs')
      .query(
        qs.stringify(
          {
            groupBy: GROUP_BY_CONFIGS,
            limit: MAX_JOBS_LIMIT,
            jobsPerGroup: 1,
            branchId: getBranchId(),
            ...(type && { componentProps: { type } }),
          },
          { encode: false },
        ),
      )
      .promise()
      .then((response) => flattenJobs(response.body));
  },

  getOrchestrationRerunPlan(id) {
    return createRequest('GET', `orchestration-rerun-plan/${id}`)
      .promise()
      .then((response) => response.body);
  },
};
