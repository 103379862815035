import React from 'react';
import { Fade } from 'react-bootstrap';

const FastFade = (props: {
  children: React.ReactNode;
  in?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  appear?: boolean;
  timeout?: number;
  onEnter?: (node: Element, appearing: boolean) => void;
  onEntering?: (node: Element, appearing: boolean) => void;
  onEntered?: (node: Element, appearing: boolean) => void;
  onExit?: (node: Element) => void;
  onExiting?: (node: Element) => void;
  onExited?: (node: Element) => void;
}) => {
  return <Fade {...props} className="fast-fade" timeout={100} />;
};

export default FastFade;
