import { fromJS } from 'immutable';

export function createConfiguration(localState) {
  return fromJS({
    parameters: {
      workbook: {
        driveId: localState.get('driveId', ''),
        fileId: localState.get('fileId', ''),
        metadata: {
          name: localState.get('workbookName', ''),
        },
      },
      worksheet: {
        name: localState.get('name', ''),
        id: localState.get('id', ''),
        metadata: {
          name: localState.get('worksheetName', ''),
        },
      },
    },
  });
}

export function parseConfiguration(configuration) {
  return fromJS({
    driveId: configuration.getIn(['parameters', 'workbook', 'driveId'], ''),
    fileId: configuration.getIn(['parameters', 'workbook', 'fileId'], ''),
    name: configuration.getIn(['parameters', 'worksheet', 'name'], ''),
    id: configuration.getIn(['parameters', 'worksheet', 'id'], ''),
    workbookName: configuration.getIn(['parameters', 'workbook', 'metadata', 'name'], ''),
    worksheetName: configuration.getIn(['parameters', 'worksheet', 'metadata', 'name'], ''),
  });
}

export function createEmptyConfiguration() {
  return createConfiguration(fromJS({}));
}
