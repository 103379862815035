import React from 'react';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { Clipboard, HelpBlock } from 'design';
import type { Map } from 'immutable';

import { getCurrentHostname } from '@/constants/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareSlug } from '@/modules/data-apps/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import SaveButtons from '@/react/common/SaveButtons';

const URL_PREFIX_PATH = ['parameters', 'dataApp', 'slug'];

const AdditionalSettings = (props: {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  sandbox: Map<string, any>;
  readOnly: boolean;
}) => {
  const savedUrlPrefix = props.configData.getIn(URL_PREFIX_PATH, '');
  const [urlPrefix, setUrlPrefix] = React.useState(savedUrlPrefix);
  const [saving, setSaving] = React.useState(false);
  const hostname = getCurrentHostname().replace('connection.', 'hub.');

  return (
    <CollapsibleBox
      title="Additional Settings"
      additionalActions={() => {
        return (
          <SaveButtons
            disabled={props.readOnly}
            isSaving={saving}
            isChanged={urlPrefix !== savedUrlPrefix}
            onSave={() => {
              setSaving(true);
              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                props.configData.setIn(URL_PREFIX_PATH, urlPrefix),
                'Change url prefix',
              ).finally(() => setSaving(false));
            }}
            onReset={() => setUrlPrefix(savedUrlPrefix)}
          />
        );
      }}
    >
      <FormGroup>
        <div className="flex-container">
          <ControlLabel>
            Url Prefix <OptionalFormLabel />
          </ControlLabel>
          {!!props.sandbox.get('id') && (
            <Clipboard
              aloneLink
              tooltipPlacement="top"
              label="Copy to Clipboard"
              tooltipText="Click to copy"
              text={props.sandbox.get('url')}
            />
          )}
        </div>
        <InputGroup>
          <FormControl
            type="text"
            value={urlPrefix}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUrlPrefix(prepareSlug(e.target.value));
            }}
          />
          <InputGroup.Addon>{`-${props.sandbox.get('id', '{ID}')}.${hostname}`}</InputGroup.Addon>
        </InputGroup>
        <HelpBlock>Url prefix will help identify the app by the URL.</HelpBlock>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default AdditionalSettings;
