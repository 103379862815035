import { fromJS } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        query: localState.get('query', ''),
        useLegacySql: localState.get('useLegacySql', true),
        advancedMode: localState.get('advancedMode', true),
      },
    },
  });
};

const parseConfiguration = (configData) => {
  return fromJS({
    query: configData.getIn(['parameters', 'query', 'query'], ''),
    useLegacySql: configData.getIn(['parameters', 'query', 'useLegacySql'], true),
    advancedMode: configData.getIn(['parameters', 'query', 'advancedMode'], true),
  });
};

const createEmptyConfiguration = () => {
  return createConfiguration(fromJS({ advancedMode: false }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
