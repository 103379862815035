import {
  KDS_TEAM_WR_HIVE_CSAS,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_SISENSE,
} from '@/constants/componentIds';
import { getWithDefaultValue } from '@/modules/wr-db/templates/credentialsFields';

const getDefaultValues = (componentId, defaultCredentials, globalCredentials) => {
  let credentials = defaultCredentials;
  getWithDefaultValue(componentId).forEach((field) => {
    if (!globalCredentials.has(field.name)) {
      credentials = credentials.set(field.name, credentials.get(field.name, field.defaultValue));
    }
  });
  return credentials;
};

const prepareCredentialsAfterEditStart = (componentId, driver, credentials, globalCredentials) => {
  let creds = getDefaultValues(componentId, credentials.set('driver', driver), globalCredentials);

  // this are new and more strict components, so we have to remove driver
  if (
    [KEBOOLA_WR_DB_HIVE, KEBOOLA_WR_SISENSE, KEBOOLA_WR_DB_SYNAPSE, KDS_TEAM_WR_HIVE_CSAS].includes(
      componentId,
    )
  ) {
    creds = creds.delete('driver');
  }

  return creds;
};

export { prepareCredentialsAfterEditStart };
