import removeMarkdown from 'remove-markdown';
import { clean, prune, truncate } from 'underscore.string';

const TRUNCATE_LENGTH = 1000;
const PRUNE_LENGTH = 75;

// We keep first 1000 characters
// Double spaces are removed (prevents freeze https://github.com/stiang/remove-markdown/issues/35)
// Markdown is removed
// String is nicely truncated (keeps words) to 75
export default function (description, pruneLength = PRUNE_LENGTH) {
  if (!description) {
    return '';
  }
  return prune(removeMarkdown(clean(truncate(description, TRUNCATE_LENGTH, ''))), pruneLength);
}
