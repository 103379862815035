import React from 'react';
import classnames from 'classnames';
import type { Map } from 'immutable';

import Gravatar from './Gravatar';
import Truncated from './Truncated';

type Props = {
  user: Map<string, any>;
  className?: string;
  avatar?: boolean;
  avatarRight?: boolean;
  size?: number;
};

const User = ({ user, className, avatar = true, avatarRight = false, size = 28 }: Props) => {
  return (
    <div
      className={classnames(
        'flex-container inline-flex flex-start',
        { 'flex-reverse': avatarRight },
        className,
      )}
    >
      {avatar && (
        <span
          className={classnames(
            'line-height-1',
            avatarRight ? 'icon-addon-left' : 'icon-addon-right',
          )}
        >
          <Gravatar user={user} size={size} />
        </span>
      )}
      <Truncated text={user.get('name') || user.get('email')} tooltip={user.get('email')} />
    </div>
  );
};

export default User;
