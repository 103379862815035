import Immutable from 'immutable';

const createConfiguration = function (localState) {
  return Immutable.fromJS({
    parameters: {
      name: localState.get('tableName', ''),
      primary: localState.get('primaryKey', Immutable.List()),
    },
  });
};

const parseConfiguration = function (configuration) {
  return Immutable.fromJS({
    tableName: configuration.getIn(['parameters', 'name'], ''),
    primaryKey: configuration.getIn(['parameters', 'primary'], Immutable.List()),
  });
};

const createEmptyConfiguration = function (tableId, tableName) {
  return createConfiguration(Immutable.fromJS({ tableName }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
