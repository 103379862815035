import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Map } from 'immutable';

import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const BucketTitle = () => {
  const { bucket, bucketId } = useStores(
    () => {
      const bucketId = RoutesStore.getRouterState().getIn(['params', 'bucketId']);

      return {
        bucketId,
        bucket: BucketsStore.getBucket(bucketId, Map()),
      };
    },
    [],
    [RoutesStore, BucketsStore],
  );

  return (
    <>
      <FontAwesomeIcon
        icon="folder"
        style={{ fontSize: '0.9em' }}
        className={classNames('text-muted', 'icon-addon-right', {
          'dev-bucket': isCreatedInDevBranch(bucket),
        })}
      />
      {bucket.get('displayName', bucketId)}
    </>
  );
};

export default BucketTitle;
