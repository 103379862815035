import React from 'react';
import PropTypes from 'prop-types';

import CsvSettings from './CsvSettings';
import Destination from './Destination';
import ProcessingSettings from './ProcessingSettings';
import Source from './Source';

class Configuration extends React.Component {
  render() {
    return (
      <>
        <Source
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <CsvSettings
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <Destination
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <ProcessingSettings
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
      </>
    );
  }
}

Configuration.propTypes = {
  value: PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    incremental: PropTypes.bool.isRequired,
    primary_key: PropTypes.array.isRequired,
    storage: PropTypes.string.isRequired,
    delimiter: PropTypes.string.isRequired,
    enclosure: PropTypes.string.isRequired,
    columnsFrom: PropTypes.oneOf(['manual', 'header', 'auto']),
    columns: PropTypes.array.isRequired,
    addRowNumberColumn: PropTypes.bool.isRequired,
    addFilenameColumn: PropTypes.bool.isRequired,
    decompress: PropTypes.bool.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Configuration;
