import React from 'react';

import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import MergeHeaderButtons from './MergeHeaderButtons';
import SimpleMergeHeaderButton from './SimpleMergeHeaderButton';

const HeaderButton = () => {
  const hasProtectedDefaultBranch = useStores(
    () => ApplicationStore.hasProtectedDefaultBranch(),
    [],
    [ApplicationStore],
  );

  if (!hasProtectedDefaultBranch) {
    return <SimpleMergeHeaderButton />;
  }

  return <MergeHeaderButtons />;
};

export default HeaderButton;
