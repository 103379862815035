import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import RoutesStore from '@/stores/RoutesStore';

const ShowLineage = ({
  allComponents,
  startingPointFqid,
  isLocked,
  projectName,
  projectId,
  configurationName,
}: {
  allComponents: Map<string, any>;
  startingPointFqid: string;
  isLocked: boolean;
  projectName: string;
  projectId: string;
  configurationName: string;
}) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Button
        bsStyle="link"
        className="btn-link-inline flex-container inline-flex flex-start no-wrap"
        onClick={(event: React.SyntheticEvent) => {
          event.stopPropagation();

          setShowModal(true);
        }}
      >
        <FontAwesomeIcon icon="chart-network" className="btn-icon text-muted" />
        Show Lineage
      </Button>
      {showModal && (
        <TablesLineage
          isLocked={isLocked}
          allComponents={allComponents}
          startingPointFqid={startingPointFqid}
          onCloseModal={() => {
            setShowModal(false);
            RoutesStore.getRouter().updateQuery({ node: null });
          }}
          projectName={projectName}
          projectId={projectId}
          configurationName={configurationName}
        />
      )}
    </>
  );
};

export default ShowLineage;
