import { fromJS, List } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        incremental: localState.get('incremental', false),
        primaryKey: localState.get('primaryKey', List()),
      },
    },
  });
};

const parseConfiguration = (configData) => {
  return fromJS({
    incremental: configData.getIn(['parameters', 'query', 'incremental'], false),
    primaryKey: configData.getIn(['parameters', 'query', 'primaryKey'], List()),
  });
};

const createEmptyConfiguration = () => {
  return createConfiguration(fromJS({}));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
