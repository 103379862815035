const getComponentId = (path) => {
  if (!path) {
    return null;
  }

  const [, componentId] = path.match(/.*\/components\/([^/]*)/) || [];

  if (!componentId || componentId.startsWith(':')) {
    return null;
  }

  return componentId;
};

export { getComponentId };
