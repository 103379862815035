import React from 'react';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentNameEdit from '@/modules/components/react/components/ComponentName';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import SandboxesActions from '@/modules/sandboxes/Actions';
import { configPoll, DEFAULT_INTERVAL } from '@/utils/genericPolls';
import MigrationAlert from './components/MigrationAlert';
import { routeNames } from './constants';
import Detail from './Detail';
import Header from './Header';
import Index from './Index';

const routes = {
  name: routeNames.ROOT,
  title: 'Data Apps',
  defaultRouteHandler: Index,
  infoAlertHandler: MigrationAlert,
  headerButtonsHandler: Header,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        SandboxesActions.loadSandboxes(),
        InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_DATA_APPS),
        InstalledComponentsActionsCreators.loadComponentsMetadata(),
      ]);
    },
  ],
  poll: {
    skipFirst: true,
    interval: DEFAULT_INTERVAL,
    action: () => {
      return Promise.all([
        SandboxesActions.loadSandboxesForce(),
        InstalledComponentsActionsCreators.loadComponentConfigsDataForce(KEBOOLA_DATA_APPS),
      ]);
    },
  },
  childRoutes: [
    {
      name: routeNames.DATA_APP_DETAIL,
      path: ':config',
      title: (routerState) => {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_DATA_APPS,
          routerState.getIn(['params', 'config']),
        ).get('name', 'Data App');
      },
      nameEdit(params) {
        return (
          <ComponentNameEdit
            key={`${KEBOOLA_DATA_APPS}-${params.config}`}
            componentId={KEBOOLA_DATA_APPS}
            configId={params.config}
          />
        );
      },
      defaultRouteHandler: Detail,
      poll: configPoll(KEBOOLA_DATA_APPS),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_DATA_APPS,
            params.config,
          ).then(() => {
            const sandboxId = InstalledComponentsStore.getConfigData(
              KEBOOLA_DATA_APPS,
              params.config,
            ).getIn(['parameters', 'id']);

            if (sandboxId) {
              SandboxesActions.loadSandbox(sandboxId);
              return null;
            }
          });
        },
        (params) => VersionsActionCreators.loadVersions(KEBOOLA_DATA_APPS, params.config),
      ],
      childRoutes: [
        {
          ...createVersionsPageRoute(KEBOOLA_DATA_APPS, 'config', routeNames.DATA_APP_VERSIONS),
        },
      ],
    },
  ],
};

export default routes;
