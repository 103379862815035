import React from 'react';
import classnames from 'classnames';
import type { Map } from 'immutable';

import { isPartialJob as oldIsPartialJob } from '@/modules/jobs/helpers';

const JobPartialRunLabel = ({
  job,
  hasNewQueue,
  className,
}: {
  job: Map<string, any>;
  hasNewQueue?: boolean;
  className?: string;
}) => {
  if (hasNewQueue || !oldIsPartialJob(job)) {
    return null;
  }

  return <div className={classnames('bigger-label uppercase', className)}>partial</div>;
};

export default JobPartialRunLabel;
