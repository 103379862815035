import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dayjs from '@/date';
import { formatAbsolute } from './helpers';
import ValidUntil from './ValidUntil';

const ValidUntilWithIcon = ({ validUntil }: { validUntil: string | null }) => {
  if (!validUntil || !dayjs(validUntil).isValid()) {
    return null;
  }

  return (
    <span title={formatAbsolute(validUntil)}>
      <FontAwesomeIcon icon={['far', 'calendar-days']} /> <ValidUntil validUntil={validUntil} />
    </span>
  );
};

export default ValidUntilWithIcon;
