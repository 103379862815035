import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { routeNames } from '@/modules/storage/constants';
import Link from '@/react/common/RouterLink';
import ExternalProjectBucketLink from './ExternalProjectBucketLink';

const ProjectAliasLink = createReactClass({
  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired,
    alias: PropTypes.object.isRequired,
  },

  render() {
    const projectId = parseInt(this.props.alias.getIn(['project', 'id']), 10);

    if (this.props.sapiToken.getIn(['owner', 'id']) === projectId) {
      return (
        <Link
          to={routeNames.TABLE}
          params={{
            bucketId: this.props.alias.get('id'),
            tableName: this.props.alias.getIn(['table', 'name']),
          }}
        >
          {this.props.alias.get('id')} / {this.props.alias.getIn(['table', 'displayName'])}
        </Link>
      );
    }

    if (this.props.sapiToken.getIn(['admin', 'isOrganizationMember'])) {
      return (
        <ExternalProjectBucketLink
          bucket={this.props.alias}
          urlTemplates={this.props.urlTemplates}
        />
      );
    }

    return (
      <span>
        {this.props.alias.getIn(['project', 'name'])} / {this.props.alias.get('id')} /{' '}
        {this.props.alias.getIn(['table', 'displayName'])}
      </span>
    );
  },
});

export default ProjectAliasLink;
