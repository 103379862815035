import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import credentialsAdapter from '@/modules/gooddata-writer-v3/adapters/credentialsAdapter';
import dimensionsAdapter from '@/modules/gooddata-writer-v3/adapters/dimensionsAdapter';
import tablesLoadSettingsAdapter from '@/modules/gooddata-writer-v3/adapters/tablesLoadSettingsAdapter';
import makeConfigProvisioning from '@/modules/gooddata-writer-v3/configProvisioning';
import { routeNames } from '@/modules/gooddata-writer-v3/constants';
import hasAnyRowWithChangedSinceSet from '@/modules/gooddata-writer-v3/helpers/hasAnyRowWithChangedSinceSet';
import makeLocalStateProvisioning from '@/modules/gooddata-writer-v3/localStateProvisioning';
import CredentialsContainer from '@/modules/gooddata-writer-v3/react/components/CredentialsContainer';
import DimensionsSection from '@/modules/gooddata-writer-v3/react/components/DimensionsSection';
import TablesLoadSettings from '@/modules/gooddata-writer-v3/react/components/TablesLoadSettings';
import tablesProvisioning from '@/modules/gooddata-writer-v3/tablesProvisioning';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
// components
import Sidebar from '@/react/layout/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ConfiguredTables from './ConfiguredTables';
import NewTableButton from './NewTableButton';

const COMPONENT_ID = 'keboola.gooddata-writer';

const DimensionsCollapsibleComponent = CollapsibleSection({
  title: 'Date Dimensions',
  contentComponent: DimensionsSection,
});

const CredentialsCollapsibleComponent = CollapsibleSection({
  title: 'GoodData Project',
  contentComponent: CredentialsContainer,
});

const LoadSettingsCollapsibleComponent = CollapsibleSection({
  title: 'Tables load settings',
  contentComponent: TablesLoadSettings,
});

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      VersionsStore,
    ),
  ],

  getStateFromStores() {
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const { tables, deleteTable, createNewTable, toggleTableExport, getSingleRunParams } =
      tablesProvisioning(configurationId);
    const configProvisioning = makeConfigProvisioning(configurationId);
    const { isSaving, isPendingFn } = configProvisioning;
    const localStateProvisioning = makeLocalStateProvisioning(configurationId);

    // section props
    const dimensionsSectionProps = dimensionsAdapter(configProvisioning, localStateProvisioning);

    const tableLoadSettingsSectionProps = tablesLoadSettingsAdapter(configProvisioning);
    const credentialsSectionProps = credentialsAdapter(configProvisioning, localStateProvisioning);

    return {
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      allComponents: ComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      readOnly: ApplicationStore.isReadOnly(),
      dimensionsSectionProps,
      tableLoadSettingsSectionProps,
      credentialsSectionProps,
      getSingleRunParams,
      configurationId,
      isPendingFn,
      isSaving,
      tables,
      deleteTable,
      createNewTable,
      toggleTableExport,
      configProvisioning,
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configurationId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.configProvisioning.config}
          flows={this.state.flows}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={COMPONENT_ID}
              configId={this.state.configurationId}
            />
            <br />
            {this.renderCredentials()}
            {this.renderDimensions()}
            {this.renderLoadSettings()}
            {this.renderTables()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configurationId}
              run={{ text: this.renderRunModalContent() }}
            />
          </div>
        </div>
      </>
    );
  },

  renderDimensions() {
    return (
      <DimensionsCollapsibleComponent
        {...this.state.dimensionsSectionProps}
        disabled={this.state.readOnly || this.state.isSaving}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderLoadSettings() {
    return (
      <LoadSettingsCollapsibleComponent
        {...this.state.tableLoadSettingsSectionProps}
        disabled={this.state.readOnly || this.state.isSaving}
        isComplete
      />
    );
  },

  renderCredentials() {
    return (
      <CredentialsCollapsibleComponent
        {...this.state.credentialsSectionProps}
        disabled={this.state.isSaving}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderTables() {
    if (this.state.tables.count() === 0) {
      return (
        <div className="box-separator">
          <h2 className="tw-m-0 tw-mb-4 tw-text-base">Tables</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No tables created yet.</p>
              {this.renderNewTableButton()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ConfiguredTables
        readOnly={this.state.readOnly}
        loadOnly={this.state.tableLoadSettingsSectionProps.value.loadOnly}
        configurationId={this.state.configurationId}
        tables={this.state.tables}
        isSaving={this.state.isSaving}
        isTablePending={this.state.isPendingFn}
        toggleTableExport={this.state.toggleTableExport}
        newTableButton={this.renderNewTableButton()}
        deleteTable={this.state.deleteTable}
        getSingleRunParams={this.state.getSingleRunParams}
        showIncrementalResetWarning={hasAnyRowWithChangedSinceSet(
          this.state.configProvisioning.configData,
        )}
      />
    );
  },

  renderNewTableButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <NewTableButton
        createdTables={this.state.tables}
        allBuckets={this.state.allBuckets}
        allTables={this.state.allTables}
        onCreateTable={this.createNewTableAndRedirect}
      />
    );
  },

  createNewTableAndRedirect(tableId, title) {
    const table = this.state.allTables.get(tableId);
    return this.state.createNewTable(table, title).then(() =>
      RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
        config: this.state.configurationId,
        table: tableId,
      }),
    );
  },

  renderRunModalContent() {
    return (
      <span>
        <p>You are about to run a load of all tables.</p>
      </span>
    );
  },
});

export default Index;
