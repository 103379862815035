import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { canManageSharedBucket } from '@/modules/admin/privileges';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { NO_PERMISSION_TO_SHARE_TOOLTIP } from '@/modules/data-catalog/constants';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import ShareModal from './ShareModal';

const HeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, StorageBucketsStore, StorageTablesStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
    };
  },

  getInitialState() {
    return {
      showModal: false,
    };
  },

  render() {
    const canShare = canManageSharedBucket(this.state.sapiToken);

    return (
      <>
        <Tooltip
          tooltip={NO_PERMISSION_TO_SHARE_TOOLTIP}
          placement="top"
          type="explanatory"
          forceHide={canShare}
        >
          <Button
            bsStyle="success"
            className={classNames(NEW_ENTITY_BUTTON, { disabled: !canShare })}
            onClick={() => canShare && this.openModal()}
          >
            Share bucket
          </Button>
        </Tooltip>
        <ShareModal
          showModal={this.state.showModal}
          onHide={this.closeModal}
          sapiToken={this.state.sapiToken}
          availableUsersOptions={this.state.availableUsersOptions}
          availableProjectsOptions={this.state.availableProjectsOptions}
          allBuckets={this.state.allBuckets}
          allTables={this.state.allTables}
        />
      </>
    );
  },

  openModal() {
    this.setState({ showModal: true });
  },

  closeModal() {
    this.setState({ showModal: false });
  },
});

export default HeaderButtons;
