const rowOnSave = (configData) => {
  if (configData.getIn(['parameters', 'query', 'advancedMode'])) {
    return configData
      .deleteIn(['parameters', 'query', 'advancedMode'])
      .deleteIn(['parameters', 'query', 'columns'])
      .deleteIn(['parameters', 'query', 'datasetId'])
      .deleteIn(['parameters', 'query', 'tableId'])
      .deleteIn(['parameters', 'query', 'limit'])
      .deleteIn(['parameters', 'query', 'incrementalFetchingColumn']);
  }

  if (!configData.getIn(['parameters', 'query', 'limit'])) {
    configData = configData.deleteIn(['parameters', 'query', 'limit']);
  }

  return configData
    .deleteIn(['parameters', 'query', 'advancedMode'])
    .deleteIn(['parameters', 'query', 'query'])
    .deleteIn(['parameters', 'query', 'useLegacySql']);
};

export default rowOnSave;
