import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import classnames from 'classnames';
import { Badge } from 'design';
import type { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '@/constants/componentIds';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ChangeOrderHandle from '@/modules/configurations/react/components/ChangeOrderHandle';
import DeleteConfigurationRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import Check from '@/react/common/Check';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';

type Props = {
  defaultBucketId: string;
  configId: string;
  row: Map<string, any>;
  rowIndex: number;
  isChangingOrder: boolean;
  isFiltered: boolean;
  isSorted: boolean;
  readOnly: boolean;
};

class ConfigRowTableRow extends React.Component<Props> {
  state = {
    isDeletePending: false,
    isEnableDisablePending: false,
  };

  render() {
    const bucketId = this.props.row.getIn(
      ['configuration', 'storage', 'output', 'default_bucket'],
      this.props.defaultBucketId,
    );
    const tableName = this.props.row.getIn(
      ['configuration', 'parameters', 'tableName'],
      string.webalize(this.props.row.get('name')),
    );

    return (
      <div
        data-id={this.props.row.get('id')}
        className={classnames('tr clickable hoverable-actions overflow-break-anywhere', {
          'row-disabled': this.props.row.get('isDisabled', false),
          'row-sort': !this.props.readOnly,
        })}
        onClick={() => {
          return RoutesStore.getRouter().transitionTo(componentId + '-row', {
            config: this.props.configId,
            row: this.props.row.get('id'),
          });
        }}
      >
        <div className={classnames('td no-wrap', { 'row-sort-handle': !this.props.readOnly })}>
          {!this.props.readOnly && (
            <ChangeOrderHandle
              isPending={this.props.isChangingOrder}
              disabled={this.props.isFiltered || this.props.isSorted || this.props.isChangingOrder}
            />
          )}
          {this.props.rowIndex + 1}
        </div>
        <div className="td">{this.props.row.get('name')}</div>
        <div className="td w-50">
          <FontAwesomeIcon icon="angle-right" fixedWidth />
        </div>
        <div className="td">
          <StorageApiTableLinkEx tableId={`${bucketId}.${tableName}`} showOnlyDisplayName />
        </div>
        <div className="td text-center">
          <Badge text={this.props.row.getIn(['configuration', 'parameters', 'mode'])} />
        </div>
        <div className="td text-center">
          <Check
            isChecked={this.props.row.getIn(['configuration', 'parameters', 'incremental'], false)}
          />
        </div>
        <div className="td pr-1">{this.renderRowActions()}</div>
      </div>
    );
  }

  renderRowActions() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <RowActionDropdown
        showLoading={this.state.isDeletePending || this.state.isEnableDisablePending}
      >
        <RunComponentButton
          mode="menuitem"
          title="Run Export"
          componentId={componentId}
          runParams={() => {
            return {
              config: this.props.configId,
              row: this.props.row.get('id'),
            };
          }}
        >
          You are about to run {this.props.row.get('name')}.
        </RunComponentButton>
        <RowActionMenuItem divider />
        <ActivateDeactivateSwitch
          mode="menuitem"
          isActive={!this.props.row.get('isDisabled', false)}
          isPending={this.state.isEnableDisablePending}
          onChange={this.handleToggle}
        />
        <RowActionMenuItem divider />
        <DeleteConfigurationRowButton
          mode="menuitem"
          isPending={this.state.isDeletePending}
          onClick={() => {
            this.setState({ isDeletePending: true });
            return ConfigurationRowsActionCreators.delete(
              componentId,
              this.props.configId,
              this.props.row.get('id'),
              false,
              `Delete table ${this.props.row.get('name')}`,
            ).finally(() => {
              this.setState({ isDeletePending: false });
            });
          }}
        />
      </RowActionDropdown>
    );
  }

  handleToggle = () => {
    this.setState({ isEnableDisablePending: true });
    return Promise.resolve()
      .then(() => {
        if (this.props.row.get('isDisabled', false)) {
          return ConfigurationRowsActionCreators.enable(
            componentId,
            this.props.configId,
            this.props.row.get('id'),
            `Enable export ${this.props.row.get('name')}`,
          );
        }

        return ConfigurationRowsActionCreators.disable(
          componentId,
          this.props.configId,
          this.props.row.get('id'),
          `Disable export ${this.props.row.get('name')}`,
        );
      })
      .finally(() => this.setState({ isEnableDisablePending: false }));
  };
}

export default ConfigRowTableRow;
