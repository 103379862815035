import type { Map } from 'immutable';

const JOB_NAME = '__NAME';
const FUNCTION_NAME = '__FUNCTION_NAME';

const MENUS = {
  BASE: 'base',
  ADVANCED: 'advanced',
  PARAMETERS: 'parameters',
  USER_DATA: 'user-data',
  PAGINATIONS: 'paginations',
} as const;

const AUTH_METHODS = {
  NONE: 'none',
  API_KEY: 'api-key',
  BEARER: 'bearer',
  OAUTH2: 'oauth2',
  QUERY: 'query',
  BASIC: 'basic',
  CUSTOM: 'custom',
} as const;

const PARAMETER_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  ENCRYPTED: 'encrypted',
  FUNCTION: 'function',
} as const;

const DEFAULT_RETRY_CODES = [408, 420, 429, 500, 502, 503, 504];

const RESERVED_CUSTOM_NAMES = {
  AUTH_METHOD: '__AUTH_METHOD',
  AUTH_TOKEN: '#__AUTH_TOKEN',
  BEARER_TOKEN: '#__BEARER_TOKEN',
  CLIENT_ID: '__CLIENT_ID',
  CLIENT_SECRET: '#__CLIENT_SECRET',
};

const BASIC_AUTH_PARAMS = {
  USERNAME: 'username',
  PASSWORD: '#password',
};

const RESERVED_CONFIG_NAMES = [
  'debug',
  'outputBucket',
  'incrementalOutput',
  'compatLevel',
  'http',
  'jobs',
  'mappings',
  'userData',
  BASIC_AUTH_PARAMS.USERNAME,
  BASIC_AUTH_PARAMS.PASSWORD,
  ...Object.values(RESERVED_CUSTOM_NAMES),
];

const NEW_FUNCTION_BOILERPLATE = `{
  "function": "",
  "args": []
}`;

type ACTIVE_MENU = (typeof MENUS)[keyof typeof MENUS] | number[] | null;

type VALUE_TYPE = (typeof PARAMETER_TYPE)[keyof typeof PARAMETER_TYPE];

type PARAM_VALUE = string | number | boolean | Map<string, any>;

export {
  JOB_NAME,
  FUNCTION_NAME,
  MENUS,
  AUTH_METHODS,
  PARAMETER_TYPE,
  DEFAULT_RETRY_CODES,
  BASIC_AUTH_PARAMS,
  RESERVED_CUSTOM_NAMES,
  RESERVED_CONFIG_NAMES,
  NEW_FUNCTION_BOILERPLATE,
  type ACTIVE_MENU,
  type VALUE_TYPE,
  type PARAM_VALUE,
};
