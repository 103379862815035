import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { getFields } from '@/modules/simplified-ui/credentials';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import PasswordControl from '@/react/common/PasswordControl';
import nextTick from '@/utils/nextTick';
import Help from './Help';

class Credentials extends React.Component {
  static propTypes = {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    configData: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    credentials: this.prepareCredentials(),
    helpIndex: 0,
    helpMarginTop: 0,
    isSaving: false,
  };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          <Form onSubmit={this.handleSubmit} horizontal>
            {this.getFields()
              .filter((field) => !field.value)
              .map((field, index) => {
                return (
                  <FormGroup key={field.name}>
                    {this.renderLabel(field)}
                    <div className="col-xs-9">{this.renderInput(field, index)}</div>
                  </FormGroup>
                );
              })}
            {this.renderConfirmButton()}
          </Form>
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderInput = (field, index) => {
    if (field.type === 'password') {
      return (
        <PasswordControl
          value={this.getValue(field)}
          onFocus={(e) => this.handleFocus(e, index)}
          onChange={(e) => this.handleChange(field, e.target.value)}
          disabled={this.isDisabled()}
        />
      );
    }

    return (
      <FormControl
        type={field.type}
        value={this.getValue(field)}
        onFocus={(e) => this.handleFocus(e, index)}
        onChange={(e) => this.handleChange(field, e.target.value)}
        disabled={this.isDisabled()}
      />
    );
  };

  renderLabel(field) {
    return (
      <div className="col-xs-3">
        <ControlLabel>{field.label}</ControlLabel>
      </div>
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveButtonType="submit"
        saveLabel="Test connection and load available sources"
        isSaving={this.state.isSaving}
        isDisabled={this.isSubmitDisabled()}
      />
    );
  }

  renderHelp() {
    if (!this.state.isSaving && this.isSubmitDisabled()) {
      const activeField = this.getFields().filter((field) => !field.value)[this.state.helpIndex];

      return (
        <Help text={activeField['help']} style={{ marginTop: `${this.state.helpMarginTop}px` }} />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="We will start loading your available sources. You can select which one you would like to load and work with in the Keboola platform."
      />
    );
  }

  getValue(field) {
    return this.state.credentials.get(field.name, field.type === 'checkbox' ? false : '');
  }

  handleFocus(event, index) {
    let helpMarginTop = 0;
    let formGroup = event.target.closest('.form-group');

    while (formGroup.previousSibling) {
      formGroup = formGroup.previousSibling;
      helpMarginTop += formGroup.offsetHeight + 15;
    }

    this.setState({ helpIndex: index, helpMarginTop });
  }

  handleChange(field, value) {
    this.setState({ credentials: this.state.credentials.set(field.name, value) });
  }

  isDisabled() {
    return this.state.isSaving || this.props.readOnly;
  }

  isSubmitDisabled() {
    if (this.prepareCredentials().equals(this.state.credentials)) {
      return true;
    }

    return this.getFields(this.props.componentId).some((field) => {
      return !this.state.credentials.get(field.name, '');
    });
  }

  getFields() {
    return getFields(this.props.componentId);
  }

  prepareCredentials() {
    return this.props.configData.getIn(['parameters', 'db'], Map()).withMutations((credentials) => {
      this.getFields().forEach((field) => {
        if ((field.defaultValue || field.value) && !credentials.has(field.name)) {
          credentials.set(field.name, field.defaultValue || field.value);
        }
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSaving: true });
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      this.props.configData.setIn(['parameters', 'db'], this.state.credentials),
      'Set up credentials',
    )
      .then(() => nextTick(() => this.setState({ credentials: this.prepareCredentials() })))
      .finally(() => this.setState({ isSaving: false }));
  };
}

export default Credentials;
