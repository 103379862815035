import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import { Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { startDeployment } from '@/modules/model-services/Actions';
import DeployModelModal from './DeployModelModal';

class DeployModelButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    if (this.props.allModels.count() === 0 && !this.props.isLoading) {
      return (
        <Tooltip tooltip="There are no models" placement="top">
          <Button bsStyle="success" className="disabled">
            {this.renderLabel()}
          </Button>
        </Tooltip>
      );
    }

    const deployableModels = this.getDeployableModels();

    if (deployableModels.count() === 0 && !this.props.isLoading) {
      return (
        <Tooltip tooltip="There are no models to deploy" placement="top">
          <Button bsStyle="success" className="disabled">
            {this.renderLabel()}
          </Button>
        </Tooltip>
      );
    }

    return (
      <>
        <Button
          onClick={() => this.setState({ showModal: true })}
          bsStyle="success"
          disabled={this.props.isLoading}
          className={NEW_ENTITY_BUTTON}
        >
          {this.renderLabel()}
        </Button>
        <DeployModelModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onSubmit={this.onSubmit}
          models={deployableModels}
        />
      </>
    );
  }

  renderLabel() {
    return (
      <>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Deploy model
      </>
    );
  }

  getDeployableModels() {
    return this.props.allModels.filter((model) => !model.has('deployment'));
  }

  onSubmit(model) {
    return startDeployment(model);
  }
}

DeployModelButton.propTypes = {
  allModels: PropTypes.instanceOf(Map).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DeployModelButton;
