import { fromJS, Map } from 'immutable';
import { strLeftBack } from 'underscore.string';

import Dispatcher from '@/Dispatcher';
import * as constants from '@/modules/components/Constants';
import * as metadataConstants from '@/modules/components/MetadataConstants';
import {
  getNativeTypesDefinitionPath,
  getTableColumnMetadataPath,
} from '@/modules/components/utils/tableMetadataHelper';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import StorageBucketsStore from './StorageBucketsStore';

let _store = initStore(
  'StorageTablesStore',
  Map({
    tables: Map(),
    tablesSnapshots: Map(),
    isLoaded: false,
    isLoading: false,
    pendingTables: Map(), // (creating/loading)
  }),
);

const prepareTables = (tables) => {
  return fromJS(tables)
    .toMap()
    .mapKeys((key, table) => table.get('id'))
    .map((table, tableId) => {
      const bucket = StorageBucketsStore.getBucket(strLeftBack(tableId, '.'), Map());
      const matadataPath = getTableColumnMetadataPath(table);
      const nativeTypesDefinitionPath = getNativeTypesDefinitionPath(table);

      return table.set('bucket', bucket).update((table) => {
        if (!_store.hasIn(['tables', tableId, ...matadataPath])) {
          return table;
        }

        // keeps loaded columns metadata
        return table
          .setIn(matadataPath, _store.getIn(['tables', tableId, ...matadataPath]))
          .setIn(
            nativeTypesDefinitionPath,
            _store.getIn(['tables', tableId, ...nativeTypesDefinitionPath]),
          );
      });
    });
};

const StorageTablesStore = StoreUtils.createStore({
  getAll() {
    return _store.get('tables');
  },

  getTableSnapshots(tableId) {
    return _store.getIn(['tablesSnapshots', tableId], Map());
  },

  hasTable(tableId) {
    return _store.get('tables').has(tableId);
  },

  getTable(tableId, defaultValue, options) {
    if (!this.hasTable(tableId) && options?.caseInsensitiveFallback) {
      return _store.get('tables').find(
        (table) => {
          return table.get('id').toLowerCase() === tableId.toLowerCase();
        },
        null,
        defaultValue,
      );
    }

    return _store.getIn(['tables', tableId], defaultValue);
  },

  getTableByName(tableName, bucketId) {
    return _store
      .get('tables', Map())
      .find(
        (item) => item.getIn(['bucket', 'id']) === bucketId && item.get('name') === tableName,
        null,
        Map(),
      );
  },

  getIsLoading() {
    return _store.get('isLoading');
  },

  getIsLoaded() {
    return _store.get('isLoaded');
  },

  getIsCreatingTable() {
    return _store.getIn(['pendingTables', 'creating'], false);
  },

  getTruncatingTables() {
    return _store.getIn(['pendingTables', 'truncatingTable'], Map());
  },

  getAddingColumn() {
    return _store.getIn(['pendingTables', 'addingColumn'], Map());
  },

  getDeletingColumn() {
    return _store.getIn(['pendingTables', 'deletingColumn'], Map());
  },

  getDeletingTables() {
    return _store.getIn(['pendingTables', 'deleting'], Map());
  },

  getExportingTables() {
    return _store.getIn(['pendingTables', 'exporting'], Map());
  },

  getIsCreatingAliasTable() {
    return _store.getIn(['pendingTables', 'creatingAlias'], false);
  },

  getIsSettingAliasFilter(tableId) {
    return _store.getIn(['pendingTables', 'settingAliasFilter', tableId], false);
  },

  getIsRemovingAliasFilter(tableId) {
    return _store.getIn(['pendingTables', 'removingAliasFilter', tableId], false);
  },

  getIsUpdatingTable(tableId) {
    return _store.getIn(['pendingTables', 'updatingTable', tableId], false);
  },

  getIsCreatingPrimaryKey(tableId) {
    return _store.getIn(['pendingTables', 'creatingPrimaryKey', tableId], false);
  },

  getIsDeletingPrimaryKey(tableId) {
    return _store.getIn(['pendingTables', 'deletingPrimaryKey', tableId], false);
  },

  getIsLoadingTable() {
    return _store.getIn(['pendingTables', 'loading'], false);
  },

  getCreatingSnapshotsTables() {
    return _store.getIn(['pendingTables', 'creatingSnapshot'], Map());
  },

  getIsRestoringTable(tableId) {
    return _store.getIn(['pendingTables', 'restoring', tableId], false);
  },

  getIsCreatingFromSnapshot() {
    return _store.getIn(['pendingTables', 'creatingFromSnapshot'], Map());
  },

  getIsDeletingSnapshot() {
    return _store.getIn(['pendingTables', 'deletingSnapshot'], Map());
  },

  getIsExportingTable(tableId) {
    return _store.getIn(['pendingTables', 'exporting', tableId], false);
  },

  getIsPullingTable(tableId) {
    return _store.getIn(['pendingTables', 'pulling', tableId], false);
  },
});

Dispatcher.register(function (payload) {
  const { action } = payload;

  switch (action.type) {
    case constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_START:
      _store = _store.set('isLoading', true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_SUCCESS: {
      Dispatcher.waitFor([StorageBucketsStore.dispatchToken]);
      _store = _store.set('tables', prepareTables(action.tables)).set('isLoaded', true);
      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_TABLE_DETAIL_LOAD_SUCCESS:
      _store = _store.setIn(['tables', action.table.id], fromJS(action.table));
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_DETAIL_LOAD_SUCCESS: {
      Dispatcher.waitFor([StorageBucketsStore.dispatchToken]);
      _store = _store.mergeIn(['tables'], prepareTables(action.bucket.tables));
      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_BUCKET_DELETE_SUCCESS:
      _store = _store.update('tables', Map(), (tables) => {
        return tables.filter((table) => {
          return table.getIn(['bucket', 'id']) !== action.bucketId;
        });
      });
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_DONE:
      _store = _store.set('isLoading', false);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE:
      _store = _store.setIn(['pendingTables', 'creating'], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_CREATE_ERROR:
      _store = _store.deleteIn(['pendingTables', 'creating']);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TRUNCATE_TABLE:
      _store = _store.setIn(['pendingTables', 'truncatingTable', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TRUNCATE_TABLE_SUCCESS:
    case constants.ActionTypes.STORAGE_TRUNCATE_TABLE_ERROR:
      _store = _store.deleteIn(['pendingTables', 'truncatingTable', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN:
      _store = _store.setIn(['pendingTables', 'addingColumn', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN_SUCCESS:
    case constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN_ERROR:
      _store = _store.deleteIn(['pendingTables', 'addingColumn', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN:
      _store = _store.setIn(
        ['pendingTables', 'deletingColumn', action.tableId, action.columnName],
        true,
      );
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN_SUCCESS:
    case constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN_ERROR:
      _store = _store.deleteIn([
        'pendingTables',
        'deletingColumn',
        action.tableId,
        action.columnName,
      ]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMNS: {
      _store = _store.withMutations((store) => {
        action.columns.forEach((columnName) => {
          store.setIn(['pendingTables', 'deletingColumn', action.tableId, columnName], true);
        });
      });
      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_DELETE_TABLE:
      _store = _store.setIn(['pendingTables', 'deleting', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_DELETE_TABLE_SUCCESS:
      _store = _store
        .deleteIn(['tables', action.tableId])
        .deleteIn(['pendingTables', 'deleting', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_DELETE_TABLE_ERROR:
      _store = _store.deleteIn(['pendingTables', 'deleting', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE:
      _store = _store.setIn(['pendingTables', 'creatingAlias'], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE_SUCCESS:
    case constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE_ERROR:
      _store = _store.setIn(['pendingTables', 'creatingAlias'], false);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER:
      _store = _store.setIn(['pendingTables', 'settingAliasFilter', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER_SUCCESS:
    case constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER_ERROR:
      _store = _store.setIn(['pendingTables', 'settingAliasFilter', action.tableId], false);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER:
      _store = _store.setIn(['pendingTables', 'removingAliasFilter', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER_SUCCESS:
    case constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER_ERROR:
      _store = _store.setIn(['pendingTables', 'removingAliasFilter', action.tableId], false);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_UPDATE:
      _store = _store.setIn(['pendingTables', 'updatingTable', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_UPDATE_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_UPDATE_ERROR:
      _store = _store.deleteIn(['pendingTables', 'updatingTable', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_SET_PRIMARY_KEY:
      _store = _store.setIn(['pendingTables', 'creatingPrimaryKey', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_SET_PRIMARY_KEY_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_SET_PRIMARY_KEY_ERROR:
      _store = _store.deleteIn(['pendingTables', 'creatingPrimaryKey', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_DELETE_PRIMARY_KEY:
      _store = _store.setIn(['pendingTables', 'deletingPrimaryKey', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_DELETE_PRIMARY_KEY_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_DELETE_PRIMARY_KEY_ERROR:
      _store = _store.deleteIn(['pendingTables', 'deletingPrimaryKey', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS:
      action.tableIds.forEach((tableId) => {
        _store = _store.setIn(['pendingTables', 'creatingSnapshot', tableId], true);
      });
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS_ERROR:
      action.tableIds.forEach((tableId) => {
        _store = _store.deleteIn(['pendingTables', 'creatingSnapshot', tableId]);
      });
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL:
      _store = _store.setIn(['pendingTables', 'restoring', action.tableId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL_SUCCESS:
    case constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL_ERROR:
      _store = _store.deleteIn(['pendingTables', 'restoring', action.tableId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT:
      _store = _store.setIn(['pendingTables', 'creatingFromSnapshot', action.snapshotId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT_ERROR:
      _store = _store.deleteIn(['pendingTables', 'creatingFromSnapshot', action.snapshotId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT:
      _store = _store.setIn(['pendingTables', 'deletingSnapshot', action.snapshotId], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT_SUCCESS:
      _store = _store
        .deleteIn(['pendingTables', 'deletingSnapshot', action.snapshotId])
        .deleteIn(['tablesSnapshots', action.tableId, action.snapshotId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT_ERROR:
      _store = _store.deleteIn(['pendingTables', 'deletingSnapshot', action.snapshotId]);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_PULL_TABLE: {
      _store = _store.setIn(['pendingTables', 'pulling', action.tableId], true);

      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_PULL_TABLE_ERROR:
    case constants.ActionTypes.STORAGE_PULL_TABLE_SUCCESS: {
      _store = _store.deleteIn(['pendingTables', 'pulling', action.tableId]);

      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_TABLE_LOAD:
      _store = _store.setIn(['pendingTables', 'loading'], true);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLE_LOAD_SUCCESS:
    case constants.ActionTypes.STORAGE_TABLE_LOAD_ERROR:
      _store = _store.deleteIn(['pendingTables', 'loading']);
      return StorageTablesStore.emitChange();

    case constants.ActionTypes.STORAGE_TABLES_EXPORT: {
      action.tablesIds.forEach((tableId) => {
        _store = _store.setIn(['pendingTables', 'exporting', tableId], true);
      });
      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_TABLES_EXPORT_ERROR:
    case constants.ActionTypes.STORAGE_TABLES_EXPORT_SUCCESS: {
      action.tablesIds.forEach((tableId) => {
        _store = _store.deleteIn(['pendingTables', 'exporting', tableId]);
      });
      return StorageTablesStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_TABLE_SNAPSHOTS_LOAD_SUCCESS:
      _store = _store.mergeIn(
        ['tablesSnapshots', action.tableId],
        fromJS(action.snapshots)
          .toMap()
          .mapKeys((key, snapshot) => snapshot.get('id')),
      );
      return StorageTablesStore.emitChange();

    case metadataConstants.ActionTypes.METADATA_SAVE_SUCCESS:
      if (action.objectType === metadataConstants.ObjectTypes.TABLE) {
        _store = _store.setIn(['tables', action.objectId, 'metadata'], fromJS(action.metadata));
        return StorageTablesStore.emitChange();
      }
      if (action.objectType === metadataConstants.ObjectTypes.COLUMN) {
        const [tableId, columnName] = action.objectId.split(/\.(?=[^.]+$)/);
        _store = _store
          .updateIn(['tables', tableId, 'columnMetadata'], Map(), (metadata) => metadata.toMap())
          .setIn(['tables', tableId, 'columnMetadata', columnName], fromJS(action.metadata));
        return StorageTablesStore.emitChange();
      }
      break;

    case metadataConstants.ActionTypes.METADATA_DELETE_SUCCESS:
      if (action.objectType === 'column') {
        const [tableId, columnName] = action.objectId.split(/\.(?=[^.]+$)/);
        const index = _store
          .getIn(['tables', tableId, 'columnMetadata', columnName])
          .findIndex((metadata) => {
            return metadata.get('id') === action.metadataId;
          });
        _store = _store.deleteIn(['tables', tableId, 'columnMetadata', columnName, index]);
        return StorageTablesStore.emitChange();
      }
      break;

    default:
  }
});

export default StorageTablesStore;
