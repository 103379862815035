import React from 'react';

const NoConfigurationEdits = (props: { isDevModeActive: boolean; hasActivities: boolean }) => {
  return (
    <div className="box box-panel">
      <div className="box-header">
        <h2 className="box-title">Latest Configuration Edits</h2>
      </div>
      <div className="box-panel-content text-center p-2 mt-2">
        <div className="mt-2">
          <h3 className="mt-2">Why is this place empty?</h3>
          <p className="text-muted mb-1">
            {props.isDevModeActive
              ? 'Looks like you just created a new Development branch'
              : props.hasActivities
              ? 'Looks like you have no recent activity in the project'
              : 'Looks like you just started a new project'}
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoConfigurationEdits;
