import React from 'react';

const TaskIcon = ({ src, size = 40 }: { src: string; size?: number }) => {
  return (
    <img
      className="task-icon pointer-events-none user-select-none"
      src={src}
      width={size}
      height={size}
      loading="lazy"
    />
  );
};

export default TaskIcon;
