import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { canRunJob } from '@/modules/admin/privileges';
import { convertComponentsToByKeyStructure } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import {
  filterDisabledTasks,
  getRunningFlowStatus,
  prepareSelectedTasks,
  prepareVisualizationPhases,
} from '@/modules/flows/helpers';
import queueApi from '@/modules/queue/api';
import { JOB_FAILED_STATUSES, JOBS_TYPES } from '@/modules/queue/constants';
import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import RunSelectedModal from './RunSelectedModal';

type Props = {
  latestJob?: Map<string, any>;
  config: Map<string, any>;
  sapiToken: Map<string, any>;
  allComponents: Map<string, any>;
  allInstalledComponents: Map<string, any>;
  mode: 'menuitem' | 'button';
  hasProtectedDefaultBranch: boolean;
};

const RetryButton = ({
  latestJob,
  config,
  sapiToken,
  allComponents,
  allInstalledComponents,
  hasProtectedDefaultBranch,
  mode,
}: Props) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [status, setStatus] = useState<Map<string, any>>();
  const [deletedComponents, setDeletedComponents] = useState<Map<any, string>>(Map());

  const isVisible =
    !!latestJob &&
    canRunJob(sapiToken) &&
    JOB_FAILED_STATUSES.includes(latestJob.get('status')) &&
    latestJob.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER;

  if (!isVisible) {
    return null;
  }

  const open = () => {
    if (isLoading || isRunning) {
      return;
    }

    setIsLoading(true);
    Promise.props({
      status: hasProtectedDefaultBranch
        ? queueApi
            .getOrchestrationRerunPlan(latestJob.get('id'))
            .then((plan: { matchedTasks: Record<string, any>[] }) => {
              return fromJS(plan.matchedTasks)
                .toMap()
                .mapKeys((key: number, task: Map<string, any>) => task.get('taskId'));
            })
        : queueApi.getChildJobs(latestJob).then((jobs: Record<string, any>[]) => {
            return getRunningFlowStatus(fromJS(jobs), latestJob);
          }),
      deletedComponents: installedComponentsApi
        .getDeletedComponents()
        .then(convertComponentsToByKeyStructure),
    })
      .then((data) => {
        setStatus(data.status);
        setDeletedComponents(data.deletedComponents);
        setShow(true);
      })
      .finally(() => setIsLoading(false));
  };

  const phasesFromConfig = config.getIn(['configuration', 'phases'], List());
  const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
  const phases = prepareVisualizationPhases(
    phasesFromConfig,
    tasksFromConfig,
    allComponents,
    allInstalledComponents,
    deletedComponents,
  );

  const prepareRunSelectedTasksData = (
    selected: { [taskId: string]: boolean },
    variablesOverride: Map<string, any>,
  ) => {
    if (hasProtectedDefaultBranch) {
      return {
        previousJobId: latestJob.get('id'),
        onlyOrchestrationTaskIds: Object.entries(selected)
          .filter(([, value]) => value)
          .map(([key]) => key),
      };
    }

    return {
      configData: prepareSelectedTasks(
        config.get('configuration'),
        selected,
        allInstalledComponents,
        variablesOverride,
      ),
    };
  };

  const button =
    mode === 'menuitem' ? (
      <RowActionMenuItem onSelect={open} onKeyDown={open}>
        {isLoading || isRunning ? <Loader /> : <FontAwesomeIcon fixedWidth icon="rotate" />}
        Re-run failed tasks
      </RowActionMenuItem>
    ) : (
      <Button onClick={open}>
        {isLoading ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon fixedWidth icon="play" className="icon-addon-right" />
        )}
        Re-run job
      </Button>
    );

  return (
    <>
      {button}
      <RunSelectedModal
        allConfigurations={allInstalledComponents}
        hasProtectedDefaultBranch={hasProtectedDefaultBranch}
        phases={filterDisabledTasks(phases)}
        status={status}
        isRetry
        show={show}
        onHide={() => setShow(false)}
        onRun={(selected, variablesOverride) => {
          setIsRunning(true);

          return InstalledComponentsActionCreators.runComponent({
            component: KEBOOLA_ORCHESTRATOR,
            data: {
              config: latestJob.get('config'),
              ...prepareRunSelectedTasksData(selected, variablesOverride),
            },
          }).finally(() => setIsRunning(false));
        }}
      />
    </>
  );
};

export default RetryButton;
