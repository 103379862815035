import { fromJS } from 'immutable';

export default fromJS({
  NUMBER: {
    name: 'NUMBER',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '38,37',
  },
  INTEGER: {
    name: 'INTEGER',
    basetype: 'INTEGER',
    size: false,
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  VARCHAR: {
    name: 'VARCHAR',
    basetype: 'STRING',
    size: true,
    maxLength: 16777216,
  },
  BOOLEAN: {
    name: 'BOOLEAN',
    basetype: 'BOOLEAN',
    size: false,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false,
  },
});
