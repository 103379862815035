import React from 'react';
import PropTypes from 'prop-types';
import later from '@breejs/later';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { getScheduleFromCrontab } from '@/modules/orchestrations-v2/helpers';
import CronSchedulerInputs from './CronSchedulerInputs';
import CronSchedulerPreview from './CronSchedulerPreview';

const CronScheduler = createReactClass({
  propTypes: {
    period: PropTypes.string.isRequired,
    crontabRecord: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    crontabTimezone: PropTypes.string,
    showPreview: PropTypes.bool,
  },

  render() {
    return (
      <>
        {this.renderInputs()}
        {this.renderPreview()}
      </>
    );
  },

  renderInputs() {
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);

    return (
      <CronSchedulerInputs
        period={this.props.period}
        M={schedule.M || []}
        D={schedule.D || []}
        d={schedule.d || []}
        h={schedule.h || []}
        m={schedule.m || []}
        onChange={this.handleChange}
        onPeriodChange={this.handlePeriodChange}
        timezone={this.props.crontabTimezone || 'UTC'}
      />
    );
  },

  renderPreview() {
    if (!this.props.showPreview) {
      return null;
    }

    return (
      <CronSchedulerPreview
        crontabRecord={this.props.crontabRecord}
        timezone={this.props.crontabTimezone || 'UTC'}
      />
    );
  },

  handleChange(propName, newValue) {
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);

    if (newValue.count() > 0) {
      schedule[propName] = _.unique(newValue.toArray());
    } else {
      delete schedule[propName];
    }
    return this.props.onChange(this.scheduleToCron(schedule));
  },

  handlePeriodChange(value) {
    // change current schedule
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);
    let toDelete = [];

    switch (value) {
      case later.hour.name:
        toDelete = ['M', 'D', 'h', 'd'];
        break;

      case later.day.name:
        toDelete = ['M', 'D', 'd'];
        break;

      case later.dayOfWeek.name:
        toDelete = ['M', 'D'];
        break;

      case later.month.name:
        toDelete = ['M', 'd'];
        break;

      default:
        break;
    }

    toDelete.forEach((key) => delete schedule[key]);

    this.props.onChange(this.scheduleToCron(schedule), value);
  },

  scheduleToCron(schedule) {
    const flatten = (part) => {
      if (!part) {
        return '*';
      } else {
        return _.sortBy(part, (item) => parseInt(item, 10)).join(',');
      }
    };

    const flattenDayOfWeek = (part) => {
      if (!part) {
        return '*';
      } else {
        return part.map((value) => value - 1).join(',');
      }
    };

    const parts = [];
    parts.push(flatten(schedule.m));
    parts.push(flatten(schedule.h));
    parts.push(flatten(schedule.D));
    parts.push(flatten(schedule.M));
    parts.push(flattenDayOfWeek(schedule.d));

    return parts.join(' ');
  },
});

export default CronScheduler;
