import React from 'react';
import type { NodeProps } from '@xyflow/react';
import { fromJS } from 'immutable';

import type { ConfigurationNode, OnSelectProject } from '@/modules/lineage/rfTypes';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentType from '@/react/common/ComponentType';
import BaseNode from './BaseNode';
import LinkedFromProject from './LinkedFromProject';

const Configuration = ({
  data,
  onClick,
  onHighlight,
  projectId,
  onSelectProject,
  graphId,
}: NodeProps<ConfigurationNode> & {
  onHighlight: (nodeId: string) => void;
  onClick: (nodeId: string) => void;
  projectId: string | null;
  onSelectProject: OnSelectProject;
  graphId: string;
}) => {
  const isTransformation = data.type === 'transformation';

  return (
    <BaseNode
      nodeId={data.fqid}
      onClick={onClick}
      onOpenSidebar={onClick}
      onHighlight={onHighlight}
      nodeTitle={data.name}
      nodeType={isTransformation ? 'TRF' : 'COM'}
      customIcon={<ComponentIcon component={fromJS(data.component)} size="20" />}
      backgroundColor={isTransformation ? 'tw-bg-[#FFE0B2]' : 'tw-bg-[#C8E6C9]'}
      graphId={graphId}
    >
      {data.component && (
        <p className="tw-m-0">
          <ComponentType type={data.component.type} noIcon labelClassName="tw-text-cyan-600" /> -{' '}
          {data.component.name}
        </p>
      )}
      <LinkedFromProject
        currentProjectId={projectId}
        nodeId={data.fqid}
        nodeProjectName={data.projectName}
        className="tw-mb-0 tw-mt-2.5"
        onSelectProject={onSelectProject}
      />
    </BaseNode>
  );
};

export default Configuration;
