import React from 'react';
import type { List } from 'immutable';

import { SORT } from './constants';
import Select from './Select';

export type OptionValue = (typeof SORT)[keyof typeof SORT];
type Option = { value: OptionValue; label: string };
type Props = {
  value: OptionValue;
  onChange: (value: OptionValue) => void;
  disabled: boolean;
  supportedOptions: OptionValue[];
};

const SortSelect = ({ value, onChange, disabled, supportedOptions }: Props) => {
  const options: Option[] = [
    { value: SORT.A_Z, label: 'Sort A to Z' },
    { value: SORT.Z_A, label: 'Sort Z to A' },
    { value: SORT.RECENTLY_ADDED, label: 'Recently Added' },
    { value: SORT.RECENTLY_USED, label: 'Recently Used' },
    { value: SORT.POPULAR, label: 'Most Popular' },
  ].filter((option) => supportedOptions.includes(option.value));

  return (
    <Select
      clearable={false}
      searchable={false}
      className="w-175 is-white"
      value={value ?? SORT.POPULAR}
      onChange={(value: OptionValue | List<OptionValue>) => onChange(value as OptionValue)}
      options={options}
      disabled={disabled}
    />
  );
};

export default SortSelect;
