import React from 'react';
import PropTypes from 'prop-types';
import { Clipboard } from 'design';
import { List, Map } from 'immutable';

import { canLoadSharedBuckets, canManageSharedBucket } from '@/modules/admin/privileges';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { routeNames as dataCatalogRouteNames } from '@/modules/data-catalog/constants';
import BackendRow from '@/modules/data-catalog/react/BackendRow';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { backends } from '@/modules/transformations/Constants';
import CreatedDate from '@/react/common/CreatedDate';
import FileSize from '@/react/common/FileSize';
import Link from '@/react/common/RouterLink';
import RowsCount from '@/react/common/RowsCount';
import TablesList from './TablesList/TablesList';
import ShareBucketSimple from './ShareBucketSimple';
import StorageDescription from './StorageDescription';

class BucketOverview extends React.Component {
  render() {
    return (
      <>
        {this.renderOverview()}
        {this.renderDescription()}
        <TablesList
          sapiToken={this.props.sapiToken}
          sandboxes={this.props.sandboxes}
          allowedTransformationComponents={this.props.allowedTransformationComponents}
          bucket={this.props.bucket}
          tables={this.props.tables}
          allTables={this.props.allTables}
          components={this.props.components}
          configurations={this.props.configurations}
          urlTemplates={this.props.urlTemplates}
          canWriteBucket={this.props.canWriteBucket}
          deletingTables={this.props.deletingTables}
          truncatingTables={this.props.truncatingTables}
          exportingTables={this.props.exportingTables}
          createSnapshotsTables={this.props.createSnapshotsTables}
          canExportTable={this.props.canExportTable}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          hasFlows={this.props.hasFlows}
          availableDatabricksClusters={this.props.availableDatabricksClusters}
          hasSnowflakePartnerConnectLimited={this.props.hasSnowflakePartnerConnectLimited}
          isBucketBrowser={this.props.isBucketBrowser}
        />
      </>
    );
  }

  renderOverview() {
    return (
      <div className="box info-row">
        <div className="info-row-section horizontal more-space">
          <div className="first-line font-medium flex-container">
            <span>ID</span>
            <Clipboard
              tooltipPlacement="top"
              className="overflow-break-anywhere"
              label={this.props.bucket.get('id')}
              text={this.props.bucket.get('id')}
              showIcon={false}
            />
          </div>
          <div className="first-line font-medium flex-container">
            <span>Name</span>
            <Clipboard
              tooltipPlacement="top"
              className="font-normal overflow-break-anywhere"
              label={this.props.bucket.get('displayName')}
              text={this.props.bucket.get('displayName')}
              showIcon={false}
            />
          </div>
          <BackendRow backend={this.props.bucket.get('backend')} />
          {!this.props.hasPayAsYouGo && this.renderSourceBucket()}
        </div>
        <div className="info-row-section horizontal">
          <p className="first-line f-14 font-medium flex-container">
            <span>Stage</span>
            <span className="font-normal text-muted">
              {this.props.bucket.get('stage').toUpperCase()}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Created</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.props.bucket.get('created')}
            />
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Last change</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.props.bucket.get('lastChangeDate')}
            />
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Row count</span>
            <span className="font-normal text-muted">
              <RowsCount count={this.props.bucket.get('rowsCount')} />
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Data size</span>
            <span className="font-normal text-muted">
              <FileSize size={this.props.bucket.get('dataSizeBytes')} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  renderSourceBucket() {
    const source = this.props.bucket.get('sourceBucket', false);

    if (source) {
      return (
        <p className="first-line flex-container">
          <span className="font-medium">Source bucket</span>
          <span className="font-normal text-muted">
            {canLoadSharedBuckets(this.props.sapiToken) ? (
              <Link
                forceProduction
                to={dataCatalogRouteNames.SHARED_WITH_YOU_DETAIL}
                params={{
                  projectId: source.getIn(['project', 'id']),
                  bucketId: source.get('id'),
                }}
              >
                {source.get('displayName')}
              </Link>
            ) : (
              source.get('displayName')
            )}
          </span>
        </p>
      );
    }

    if (!this.props.bucket.get('sharing') && this.props.isDevModeActive) {
      return null;
    }

    if (
      this.props.bucket.get('hasExternalSchema') === true &&
      this.props.bucket.get('backend') !== backends.SNOWFLAKE
    ) {
      return null;
    }

    return (
      <p className="first-line flex-container">
        <span className="font-medium">Sharing</span>
        <span>{this.renderSharingInfo()}</span>
      </p>
    );
  }

  renderSharingInfo() {
    if (this.props.bucket.get('sharing')) {
      return (
        <Link
          forceProduction
          to={dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT_DETAIL}
          className="btn btn-link btn-link-inline partly-underlined"
          params={{ bucketId: this.props.bucket.get('id') }}
        >
          <span className="underline">Enabled</span>{' '}
          <span className="text-muted">(show detail)</span>
        </Link>
      );
    }

    return (
      <ShareBucketSimple
        bucket={this.props.bucket}
        canShare={canManageSharedBucket(this.props.sapiToken)}
        availableUsersOptions={this.props.availableUsersOptions}
        availableProjectsOptions={this.props.availableProjectsOptions}
      />
    );
  }

  renderDescription() {
    return (
      <StorageDescription
        objectType={ObjectTypes.BUCKET}
        objectId={this.props.bucket.get('id')}
        metadata={this.props.bucket.get('metadata', List())}
        metadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!this.props.canManageBuckets}
        isDevBucket={isCreatedInDevBranch(this.props.bucket)}
      />
    );
  }
}

BucketOverview.propTypes = {
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  pendingActions: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  truncatingTables: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  createSnapshotsTables: PropTypes.instanceOf(Map).isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  canWriteBucket: PropTypes.bool.isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
  isBucketBrowser: PropTypes.bool,
};

export default BucketOverview;
