import React from 'react';

const NewLineToBr = (props: { text: string }) => {
  return (
    <>
      {props.text.split('\n').map((part, index) => {
        if (index === 0) {
          return part;
        }

        return (
          <React.Fragment key={index}>
            <br />
            {part}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NewLineToBr;
