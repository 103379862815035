import React from 'react';

import TablesStore from '@/modules/components/stores/StorageTablesStore';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import NativeTypesLabel from './NativeTypesLabel';

const TableLabels = () => {
  const { table } = useStores(
    () => {
      return {
        table: TablesStore.getTableByName(
          RoutesStore.getCurrentRouteParam('tableName'),
          RoutesStore.getCurrentRouteParam('bucketId'),
        ),
      };
    },
    [],
    [RoutesStore, TablesStore],
  );

  if (!table || table.isEmpty()) {
    return null;
  }

  return (
    <>
      <NativeTypesLabel isTyped={table.get('isTyped', false)} className="in-title" />
      <ExternalTableLabel
        className="in-title"
        tableType={table.get('tableType')}
        hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
      />
    </>
  );
};

export default TableLabels;
