const onClickSelectionCell = (event: React.SyntheticEvent) => {
  event.stopPropagation();

  const input = (event.target as Element).querySelector('input[type=checkbox]');

  if (input instanceof HTMLElement) {
    input.click();
  }
};

export default onClickSelectionCell;
