import React from 'react';
import classNames from 'classnames';

import { parseFQID } from '@/modules/lineage/fqid';
import type { OnSelectProject } from '@/modules/lineage/rfTypes';
import Truncated from '@/react/common/Truncated';

const LinkedFromProject = ({
  currentProjectId,
  nodeId,
  nodeProjectName,
  className,
  onSelectProject,
}: {
  currentProjectId: string | null;
  nodeId: string;
  nodeProjectName: string;
  className?: string;
  onSelectProject: OnSelectProject;
}) => {
  const { projectId: tableProjectId } = parseFQID(nodeId);

  if (!tableProjectId || tableProjectId === currentProjectId) {
    return null;
  }

  return (
    <p className={classNames(className, '!tw-m-0')}>
      <Truncated
        tooltip={nodeProjectName}
        text={
          <>
            Linked from{' '}
            <a onClick={(event) => onSelectProject(nodeProjectName, tableProjectId, nodeId, event)}>
              {nodeProjectName}
            </a>
          </>
        }
      />
    </p>
  );
};

export default LinkedFromProject;
