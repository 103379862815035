import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import CollapsibleMapping from '@/modules/components/react/components/generic/CollapsibleMapping';
import { routeNames as workspaceRoutes } from '@/modules/sandboxes/Constants';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import MappingsMultiActionsHeader, {
  MappingsHeader,
} from '@/react/common/MappingsMultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import RouterLink from '@/react/common/RouterLink';
import { parseComponentConfigurationAndRowFromSourceTags } from './helpers';
import Mapping from './Mapping';
import MatchedFilesModal from './MatchedFilesModal';

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMatchedFilesModal: false,
      detailSourceTags: List(),
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false,
    };
  }

  renderActions() {
    return (
      !this.props.readOnly && (
        <RouterLink
          to={
            this.props.componentId === KEBOOLA_SANDBOXES
              ? workspaceRoutes.GENERIC_WORKSPACE_FILE_INPUT_MAPPING
              : transformationRoutes.GENERIC_TRANSFORMATION_FILE_INPUT_MAPPING
          }
          params={{
            component: this.props.componentId,
            config: this.props.configId,
          }}
          className="header-inline-button color-success btn btn-link"
        >
          {this.props.value.isEmpty() ? (
            <>
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              New File Input
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="pen" className="icon-addon-right" />
              Edit File Input
            </>
          )}
        </RouterLink>
      )
    );
  }

  render() {
    return (
      <CollapsibleMapping
        title={<MappingsHeader type="input" componentId={this.props.componentId} storage="files" />}
        entity="mapping"
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        mappingKey="file-input-v2"
        isEmpty={this.props.value.isEmpty()}
        actions={this.renderActions()}
        header={
          <>
            <MappingsMultiActionsHeader
              hide={this.props.readOnly}
              disabled={this.props.value.isEmpty()}
              componentId={this.props.componentId}
              configurationId={this.props.configId}
              type="input"
              storage="files"
              allMappings={this.props.value}
              selectedMappings={this.state.selectedMappings}
              updateMappingsSelection={(selectedMappings) =>
                this.setState(() => ({ selectedMappings }))
              }
              deleteMappings={this.props.onDeleteMappings}
              isSorted={this.state.sortBy === 'source'}
              isSortedDesc={this.state.sortDesc}
              onClick={() => this.handleChangeSort('source')}
            />
          </>
        }
      >
        {this.renderContent()}
        <MatchedFilesModal
          tags={this.state.detailSourceTags}
          show={this.state.showMatchedFilesModal}
          onHide={() => this.setState({ showMatchedFilesModal: false })}
        />
      </CollapsibleMapping>
    );
  }

  renderContent = () => {
    return (
      <div className="box-content p-0 tw-mt-1">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((mapping) => {
                const sourceTags = mapping.getIn(['source', 'tags'], List());
                const ids = parseComponentConfigurationAndRowFromSourceTags(sourceTags);
                const component = this.props.allComponents.get(ids.get('componentId'), Map());
                const configuration = this.props.allConfigurations.getIn(
                  [component.get('id'), 'configurations', ids.get('configurationId')],
                  Map(),
                );

                return {
                  component,
                  configuration,
                  sourceTags,
                  ids,
                };
              })
              .sort(this.handleSort)
              .map(({ component, configuration, sourceTags, ids }, index) => {
                const configurationRow = configuration
                  .get('rows', List())
                  .find((row) => row.get('id') === ids.get('configurationRowId'), null, Map());
                const fileTag = ids.get('fileTag', '');

                return (
                  <div className="tr hoverable-actions" key={index}>
                    {!this.props.readOnly && (
                      <div className="td w-52 pr-0">
                        <MultiActionsSelectCheckbox
                          isChecked={!!this.state.selectedMappings.get(index)}
                          isDisabled={this.props.pendingActions.getIn(
                            ['input', 'files', index, 'delete'],
                            false,
                          )}
                          onToggle={(checked) =>
                            this.setState({
                              selectedMappings: this.state.selectedMappings.set(index, checked),
                            })
                          }
                          entity="mapping"
                        />
                      </div>
                    )}
                    <div className="td">
                      <div className="flex-container flex-start">
                        <Mapping
                          iconSize="16"
                          component={component}
                          configuration={configuration}
                          configurationRow={configurationRow}
                          fileTag={fileTag}
                          showComponentName
                        />
                      </div>
                    </div>
                    <div className="td pl-0 pr-1 no-wrap">
                      {!this.props.isDevModeActive && (
                        <Button
                          bsStyle="link"
                          className="btn-link-inline dark"
                          onClick={() =>
                            this.setState({
                              showMatchedFilesModal: true,
                              detailSourceTags: sourceTags,
                            })
                          }
                        >
                          Show files
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })
              .toArray()}
          </div>
        </div>
      </div>
    );
  };

  handleChangeSort = (type) => {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false,
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc,
    });
  };

  handleSort = (inputA, inputB) => {
    if (!this.state.sortBy) {
      return 0;
    }

    const nameA = `${inputA.component.get('name', '')} ${inputA.configuration.get('name', '')}`;
    const nameB = `${inputB.component.get('name', '')} ${inputB.configuration.get('name', '')}`;

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA.localeCompare(nameB) * sort;
  };
}

Overview.propTypes = {
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.instanceOf(List).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  onDeleteMappings: PropTypes.func.isRequired,
  pendingActions: PropTypes.object.isRequired,
  isDevModeActive: PropTypes.bool,
};

export default Overview;
