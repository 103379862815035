import React, { useState } from 'react';
import type { ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type BluebirdPromise from 'bluebird';

import ConfirmModal from './ConfirmModal';
import RowActionMenuItem from './RowActionMenuItem';

type Props = {
  title: string;
  text: ReactNode;
  onConfirm: () => BluebirdPromise<any>;
  buttonLabel: string;
  closeAfterResolve?: boolean;
  icon?: IconProp;
  buttonType?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  modalClasses?: string;
  children: ReactNode;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

const ConfirmMenuItem = ({
  buttonType = 'danger',
  isDisabled,
  onKeyDown,
  children,
  modalClasses,
  ...restProps
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <RowActionMenuItem
        disabled={isDisabled}
        onSelect={() => setShowModal(true)}
        onKeyDown={onKeyDown}
      >
        {children}
      </RowActionMenuItem>
      <ConfirmModal
        {...restProps}
        show={showModal}
        onHide={() => setShowModal(false)}
        buttonType={buttonType}
        modalClass={modalClasses}
      />
    </>
  );
};

export default ConfirmMenuItem;
