import { fromJS } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        incrementalFetchingColumn: localState.get('incrementalFetchingColumn', ''),
        limit: localState.get('limit', 0),
      },
    },
  });
};

const parseConfiguration = (configuration) => {
  return fromJS({
    incrementalFetchingColumn: configuration.getIn(
      ['parameters', 'query', 'incrementalFetchingColumn'],
      '',
    ),
    limit: configuration.getIn(['parameters', 'query', 'limit'], 0),
  });
};

const createEmptyConfiguration = () => {
  return createConfiguration(fromJS({}));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration,
};
