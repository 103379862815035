import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';

const InfoTooltip = ({
  tooltip,
  allowHoverTooltip = false,
  className,
}: {
  tooltip: React.ReactNode;
  allowHoverTooltip?: boolean;
  className?: string;
}) => {
  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      type="explanatory"
      allowHoverTooltip={allowHoverTooltip}
    >
      <FontAwesomeIcon
        icon="circle-info"
        className={classnames('tw-ml-1.5 tw-text-base tw-text-neutral-400', className)}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
