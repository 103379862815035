import { KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 as componentId } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import RowsVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import SimplifiedUiHeaderButtons from '@/modules/simplified-ui/HeaderButtons';
import { configPoll, rowPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import Index from './Index';
import Row from './Row';

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: Index,
  headerButtonsHandler: SimplifiedUiHeaderButtons,
  title: (routerState) => {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(componentId, configId).get('name');
  },
  requireData: [(params) => configRequiredData(componentId, params.config)],
  poll: configPoll(componentId),
  childRoutes: [
    {
      name: `${componentId}-row`,
      path: 'rows/:row',
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        const rowId = routerState.getIn(['params', 'row']);
        return ConfigurationRowsStore.get(componentId, configId, rowId).get('name', 'Untitled');
      },
      poll: rowPoll(componentId),
      requireData: [
        (params) => RowsVersionsActionCreators.loadVersions(componentId, params.config, params.row),
      ],
      defaultRouteHandler: Row,
    },
  ],
};
