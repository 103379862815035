import React, { useEffect, useState } from 'react';
import { Button, ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import type { Map } from 'immutable';
import { List } from 'immutable';
import _ from 'underscore';

import {
  getDatabricksParametersFromCluster,
  getPredefinedCluster,
} from '@/modules/components/helpers';
import Select from '@/react/common/Select';
import string from '@/utils/string';

type Props = {
  savedValues: Map<string, any>;
  defaultValues: Map<string, any>;
  isLoading: boolean;
  loadClustersHandler: () => void;
  availableClusters?: List<Map<string, any>>;
  onChange?: (newValues: Map<string, any>) => void;
  isDisabled?: boolean;
};

const DatabricksParametersFormContent = ({
  savedValues,
  defaultValues,
  availableClusters,
  isLoading,
  loadClustersHandler,
  onChange,
  isDisabled,
}: Props) => {
  const [currentValues, setCurrentValues] = useState(savedValues);
  const isPredefinedCluster = !!currentValues.get('clusterId');

  const onChangeHandler = (key: string) => (value: string) => {
    let newValues = currentValues.set(key, value);

    if (key === 'clusterId') {
      if (value === '') {
        newValues = newValues
          .set('nodeType', defaultValues.get('defaultNodeType'))
          .set('numberOfNodes', defaultValues.get('defaultNumberOfNodes'))
          .set('sparkVersion', defaultValues.get('defaultSparkVersion'));
      } else {
        newValues = newValues.merge(
          getDatabricksParametersFromCluster(getPredefinedCluster(availableClusters, value)),
        );
      }
    }

    onChange && onChange(newValues);
    setCurrentValues(newValues);
  };

  useEffect(() => {
    if (!availableClusters && !isLoading) {
      loadClustersHandler();
    }
  }, [availableClusters, isLoading, loadClustersHandler]);

  return (
    <>
      <FormGroup>
        <ControlLabel>Available clusters</ControlLabel>
        <Select
          clearable={false}
          options={[{ value: '', label: 'Custom cluster' }].concat(
            availableClusters
              ?.map((cluster) => ({
                value: cluster?.get('cluster_id'),
                label: cluster?.get('cluster_name'),
              }))
              .toArray() || [],
          )}
          value={currentValues.get('clusterId', '')}
          onChange={onChangeHandler('clusterId')}
          isLoading={isLoading}
        />
        <HelpBlock>
          Not seeing predefined clusters?{' '}
          <Button bsStyle="link" className="btn-link-inline" onClick={loadClustersHandler}>
            Reload
          </Button>{' '}
          the list of clusters.
        </HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Node type</ControlLabel>
        <Select
          clearable={false}
          options={defaultValues
            .get('nodeTypes', List())
            .sort()
            .map((value: string) => ({ value, label: value }))
            .toArray()}
          value={currentValues.get(
            'nodeType',
            isLoading ? '' : defaultValues.get('defaultNodeType'),
          )}
          onChange={onChangeHandler('nodeType')}
          disabled={isDisabled || isPredefinedCluster}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Number of nodes</ControlLabel>
        <Select
          clearable={false}
          options={_.range(1, defaultValues.get('maxNumberOfNodes', 1) + 1).map((value) => ({
            value,
            label: `${value} ${string.pluralize(value, 'node')}`,
          }))}
          value={currentValues.get(
            'numberOfNodes',
            isLoading ? '' : defaultValues.get('defaultNumberOfNodes'),
          )}
          onChange={onChangeHandler('numberOfNodes')}
          disabled={isDisabled || isPredefinedCluster}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Runtime Version</ControlLabel>
        <Select
          clearable={false}
          options={defaultValues
            .get('sparkVersions', List())
            .sortBy(
              (version: Map<string, any>) => -version.get('name').substring(0, 3).toUpperCase(),
            )
            .map((version: Map<string, any>) => ({
              value: version.get('key'),
              label: version.get('name'),
            }))
            .toArray()}
          value={currentValues.get(
            'sparkVersion',
            isLoading ? '' : defaultValues.get('defaultSparkVersion'),
          )}
          onChange={onChangeHandler('sparkVersion')}
          disabled={isDisabled || isPredefinedCluster}
        />
      </FormGroup>
    </>
  );
};

export default DatabricksParametersFormContent;
