import React from 'react';
import Textarea from 'react-textarea-autosize';
import classnames from 'classnames';
import { Link } from 'design';

import keyCodes from '@/constants/keyCodes';
import type { FeedbackStatusType } from '@/modules/ai/constants';
import FeedbackBox from '@/react/common/AI/FeedbackBox';
import AnimationBar from '@/react/common/AnimationBar';
import GenerateButton from './GenerateButton';

type Props = {
  value: string;
  isSaving: boolean;
  onChange: (value: string) => void;
  onSave: () => void;
  onCancel: () => void;
  inModal?: boolean;
  warning?: React.ReactNode;
  isGenerating: boolean;
  isGeneratedWithAI: boolean;
  hasAllowedAi?: boolean;
  onGenerate?: () => void;
  onGood?: () => void;
  onBad?: (message?: string) => void;
  feedbackStatus: FeedbackStatusType | null;
};

const EditingArea = ({
  onGood,
  onBad,
  isGeneratedWithAI,
  inModal,
  warning,
  isGenerating,
  value,
  isSaving,
  onChange,
  hasAllowedAi,
  onGenerate,
  feedbackStatus,
  onSave,
  onCancel,
}: Props) => {
  return (
    <>
      {isGenerating ? (
        <AnimationBar />
      ) : (
        <>
          {warning && <div className={classnames({ 'tw-mt-4': !inModal })}>{warning}</div>}
          <Textarea
            autoFocus
            minRows={4}
            className="form-control"
            value={value}
            disabled={isSaving}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === keyCodes.ENTER && (e.ctrlKey || e.metaKey)) {
                onSave();
              } else if (e.key === keyCodes.ESCAPE) {
                onCancel();
              }
            }}
            cacheMeasurements
          />
        </>
      )}
      <div className="flex-container overflow-hidden">
        {hasAllowedAi && !isGeneratedWithAI && (
          <GenerateButton
            isSaving={isSaving}
            handleGenerate={onGenerate}
            isGenerating={isGenerating}
          />
        )}
        <p className="text-muted mb-0 ml-auto f-12">
          <Link href="https://www.markdownguide.org/cheat-sheet">Markdown</Link> is supported
        </p>
      </div>
      {hasAllowedAi && isGeneratedWithAI && onGenerate && onBad && onGood && (
        <div className="mt-1">
          <FeedbackBox
            onReload={onGenerate}
            onBad={onBad}
            onGood={onGood}
            feedbackStatus={feedbackStatus}
            entityName="Description"
            disabled={isGenerating}
          />
        </div>
      )}
    </>
  );
};

export default EditingArea;
