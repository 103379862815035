import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import classnames from 'classnames';
import { Badge } from 'design';

import { backendImages } from '@/constants/backendImages';
import { transformationLabels } from '@/modules/transformations/Constants';
import {
  isKnownTransformationType,
  resolveBackendName,
} from '@/modules/transformations/utils/transformationTypes';

class TransformationType extends React.Component {
  render() {
    if (!this.props.transformation || !isKnownTransformationType(this.props.transformation)) {
      return null;
    }

    const backendName = resolveBackendName(this.props.transformation);

    return (
      <>
        <Image
          src={backendImages[backendName]}
          width={this.props.imageSize}
          alt={transformationLabels[backendName]}
          title={transformationLabels[backendName]}
          className={classnames(this.props.imageClass)}
        />
        {this.props.showLabel && (
          <Badge text={transformationLabels[backendName]} placement="right" />
        )}
      </>
    );
  }
}

TransformationType.propTypes = {
  transformation: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  imageClass: PropTypes.string,
  imageSize: PropTypes.string,
};

TransformationType.defaultProps = {
  showLabel: true,
  imageSize: '19',
};

export default TransformationType;
