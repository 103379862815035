import React from 'react';
import classnames from 'classnames';

import Loader from './Loader';

type Props = {
  text?: string;
  loaderSize?: 'normal' | '2x' | '4x';
  className?: string;
};

const defaultClasses = 'tw-flex tw-flex-col tw-justify-center tw-items-center tw-min-h-80';

const CustomLoader = ({
  text = 'Loading data...',
  loaderSize = 'normal',
  className = defaultClasses,
}: Props) => (
  <div className={className}>
    <p>
      <Loader
        className={classnames({
          'fa-2x': loaderSize === '2x',
          'fa-4x': loaderSize === '4x',
        })}
      />
    </p>
    <p>{text}</p>
  </div>
);

export default CustomLoader;
