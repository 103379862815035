import React from 'react';
import * as Progress from '@radix-ui/react-progress';

import { cn } from '../utils/classNames';

const BAR_BACKGROUND = {
  success: 'tw-bg-primary-500',
  secondary: 'tw-bg-secondary-500',
};

type Props = {
  progress: number;
  type?: 'success' | 'secondary';
  className?: string;
  barClassName?: string;
};

export const ProgressBar = ({ progress, type = 'secondary', className, barClassName }: Props) => {
  return (
    <Progress.Root
      className={cn(
        'tw-relative tw-z-0 tw-h-5 tw-w-full tw-overflow-hidden tw-rounded tw-bg-neutral-50 tw-shadow-inner',
        className,
      )}
      value={progress}
    >
      <Progress.Indicator
        className={cn(
          'tw-w-ful tw-h-full tw-transition-transform',
          BAR_BACKGROUND[type],
          barClassName,
        )}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
};
