import React from 'react';
import type { Map } from 'immutable';

import { BETA, EXCLUDE_FROM_NEW_LIST, EXPERIMENTAL } from '@/constants/componentFlags';
import { BadgeBeta, BadgeExperimental, BadgePrivate } from './Badges';
import { EXPERIMENTAL_TEXT } from './constants';

const PRIVATE_TEXT = 'This component is not publicly accessible.';
const BETA_TEXT =
  'Available to all users. In active development with potential for feature additions. Minor bugs fixed under standard SLA. Some limitations may be present, and documentation is evolving. Publicly accessible code.';

const ComponentBadges = ({ className, flags }: { className?: string; flags: Map<string, any> }) => {
  if (flags.includes(EXCLUDE_FROM_NEW_LIST)) {
    return <BadgePrivate className={className} tooltip={PRIVATE_TEXT} />;
  }

  if (flags.includes(BETA)) {
    return <BadgeBeta className={className} tooltip={BETA_TEXT} />;
  }

  if (flags.includes(EXPERIMENTAL)) {
    return <BadgeExperimental className={className} tooltip={EXPERIMENTAL_TEXT} />;
  }

  return null;
};

export default ComponentBadges;
