import React from 'react';

const TaskCount = (props: { tasks: number }) => {
  if (props.tasks < 2) {
    return null;
  }

  return <span className="tasks-count f-12 text-muted">{props.tasks} tasks</span>;
};

export default TaskCount;
