import { List, Map } from 'immutable';

export default (configData) => {
  return (
    configData
      .getIn(['storage', 'input', 'tables'], List())
      .find((table) => !!table.get('changed_since', ''), null, Map())
      .count() > 0
  );
};
