import { KEBOOLA_EX_GOOGLE_ADS } from '@/constants/componentIds';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import createOauthSection from '@/modules/configurations/utils/createOauthSection';
import createRoute from '@/modules/configurations/utils/createRoute';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import accountsSettingsAdapter from './adapters/accountsSettings';
import { listAccounts } from './adapters/actions';
import { conform } from './adapters/conform';
import dataSourceAdapter from './adapters/dataSource';
import saveSettingsAdapter from './adapters/saveSettings';
import AccountsSettings from './react/components/AccountsSettings';
import DataSourceSection from './react/components/DataSource';
import SaveSettingsSection from './react/components/SaveSettings';

const routeSettings = {
  componentId: KEBOOLA_EX_GOOGLE_ADS,
  componentType: 'extractor',
  index: {
    authRequired: true,
    onConform: conform,
    actions: [
      {
        name: 'listAccounts',
        cache: true,
        autoload: true,
        getPayload: listAccounts,
      },
    ],
    sections: [
      createOauthSection(),
      {
        render: CollapsibleSection({
          title: 'Accounts',
          contentComponent: AccountsSettings,
          options: { includeSaveButtons: true },
        }),
        onSave: accountsSettingsAdapter.createConfiguration,
        onLoad: accountsSettingsAdapter.parseConfiguration,
        isComplete: accountsSettingsAdapter.isComplete,
      },
    ],
  },
  row: {
    hasState: false,
    sections: [
      {
        render: DataSourceSection,
        onSave: dataSourceAdapter.createConfiguration,
        onLoad: dataSourceAdapter.parseConfiguration,
        onCreate: dataSourceAdapter.createEmptyConfiguration,
      },
      {
        render: SaveSettingsSection,
        onSave: saveSettingsAdapter.createConfiguration,
        onLoad: saveSettingsAdapter.parseConfiguration,
        onCreate: saveSettingsAdapter.createEmptyConfiguration,
      },
    ],
    columns: [
      {
        name: 'Destination',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: (row) => row.getIn(['parameters', 'name']),
        tablePrefix: 'report-',
      },
    ],
  },
};

const result = createRoute(routeSettings);

export default result;
