import './sentry';
import './utils/ReactErrorHandler';
import './utils/font-awesome/setup';
import './utils/ReactBootstrapOverrides';

import React from 'react';
import { createRoot } from 'react-dom/client';
import BluebirdPromise from 'bluebird';
import { rtrim } from 'underscore.string';

import '../styles/app.less';

import ApplicationActionCreators from './actions/ApplicationActionCreators';
import { IS_DEVELOPMENT } from './constants/environment';
import { redirectToProductionIfBranchNotFound } from './modules/dev-branches/helpers';
import { Constants as OAuthConstants } from './modules/oauth-v2/Constants';
import { RedirectionPlaceholder } from './modules/oauth-v2/react/RedirectionPlaceholder';
import { createPresentationalError, shouldBeMuted } from './utils/errors/helpers';
import * as helpers from './helpers';
import Root from './Root';

// BluebirdPromise global config
// Note: long stack traces and warnings are enabled in dev env by default
BluebirdPromise.config({
  cancellation: true,
  longStackTraces: IS_DEVELOPMENT,
  warnings: IS_DEVELOPMENT,
});

// Error thrown during application live not on route change
BluebirdPromise.onPossiblyUnhandledRejection((e) => {
  if (redirectToProductionIfBranchNotFound(e)) {
    return;
  }

  const error = createPresentationalError(e);

  if (!shouldBeMuted(error)) {
    ApplicationActionCreators.sendNotification({
      id: error.id,
      type: !!error.isMaintenanceError ? 'warning' : 'error',
      message: () => (
        <>
          <div className="tw-mb-2 tw-font-medium">{error.getTitle() || 'Error'}</div>
          <div>{error.getText()}</div>
          {error.getExceptionId() && (
            <div className="tw-mt-2">Exception ID: {error.getExceptionId()}</div>
          )}
        </>
      ),
    });
  }

  if (!error.isUserError && !error.isMaintenanceError) {
    throw e;
  }
});

const startApp = (appOptions) => {
  if (window.matchMedia('screen and (max-width: 991px)').matches) {
    document.querySelector('meta[name=viewport]').setAttribute('content', 'width=992');
  }

  // When on OAuth redirection callback route, load placeholder instead of whole app
  if (
    rtrim(window.location.pathname, '/') ===
    `${appOptions?.data?.kbc?.projectBaseUrl}/${OAuthConstants.ROUTE_NAME}`
  ) {
    createRoot(appOptions.rootNode).render(<RedirectionPlaceholder />);
    return;
  }

  createRoot(appOptions.rootNode).render(
    <Root
      data={appOptions.data}
      isPreview={appOptions.isPreview}
      isDemoPreview={appOptions.isDemoPreview}
    />,
  );
};

export default {
  start: startApp,
  helpers,
};
