import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'design';
import type { Map } from 'immutable';

import CredentialsModal from '@/modules/sandboxes/components/CredentialsModal';

const OpenDataApp = (props: {
  mode: 'sidebar' | 'list';
  sandbox: Map<string, any>;
  config: Map<string, any>;
}) => {
  const [showCredentialsModal, setShowCredentialsModal] = React.useState(false);

  const isConnectable = props.sandbox.get('active', false) && !!props.sandbox.get('url');
  const isList = props.mode === 'list';

  const renderLabel = () => {
    return (
      <>
        <FontAwesomeIcon
          icon="arrow-up-right-from-square"
          className={classNames('icon-addon-right', { 'text-muted': isList })}
        />
        Open Data App
      </>
    );
  };

  const renderButton = () => {
    if (
      props.config.hasIn(['configuration', 'authorization', 'app_proxy']) ||
      props.config.getIn(['configuration', 'parameters', 'dataApp', 'streamlitAuthEnabled'])
    ) {
      return (
        <Button
          block={props.mode === 'sidebar'}
          bsStyle={props.mode === 'sidebar' ? 'default' : 'link'}
          className={classNames({ 'btn-link-inline': isList })}
          onClick={() => {
            setShowCredentialsModal(true);
          }}
        >
          {renderLabel()}
        </Button>
      );
    }

    return (
      <Link
        href={props.sandbox.get('url', '')}
        className={classNames('btn', {
          'btn-default btn-block': props.mode === 'sidebar',
          'btn-link btn-link-inline': isList,
          disabled: !isConnectable,
        })}
        onClick={() => {
          setShowCredentialsModal(true);
        }}
      >
        {renderLabel()}
      </Link>
    );
  };

  if (props.sandbox.isEmpty() || (isList && !isConnectable)) {
    return isList ? <span className="tw-text-neutral-400">-</span> : null;
  }

  return (
    <span
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
    >
      {renderButton()}
      <CredentialsModal
        config={props.config}
        sandbox={props.sandbox}
        show={showCredentialsModal}
        onHide={() => setShowCredentialsModal(false)}
      />
    </span>
  );
};

export default OpenDataApp;
