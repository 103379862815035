import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackendSizeIcons = (props: { allSizes: string[]; size?: string }) => {
  if (!props.allSizes || !props.size) {
    return null;
  }

  const rockets = [];

  for (let rocketsCount = props.allSizes.indexOf(props.size); rocketsCount >= 0; rocketsCount--) {
    rockets.push(<FontAwesomeIcon key={rocketsCount} icon="rocket" />);
  }

  return rockets;
};

export default BackendSizeIcons;
