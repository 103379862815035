import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';

import Checkbox from '@/react/common/Checkbox';

const PhaseEditRow = createReactClass({
  propTypes: {
    toggleHide: PropTypes.func.isRequired,
    phase: PropTypes.object.isRequired,
    onMarkPhase: PropTypes.func.isRequired,
    togglePhaseIdChange: PropTypes.func.isRequired,
    isMarked: PropTypes.bool.isRequired,
    toggleAddNewTask: PropTypes.func.isRequired,
    isPhaseHidden: PropTypes.bool,
  },

  render() {
    return (
      <tr style={{ background: '#f2f4f7' }} onClick={this.props.toggleHide}>
        <td>
          <div className="dragable drag-handle">
            <FontAwesomeIcon icon="bars" />
          </div>
          {this.renderSelectPhaseCheckbox()}
        </td>
        <td colSpan="5" className="text-center clickable">
          <b>{this.props.phase.get('id')} </b>
          <Tooltip tooltip="rename phase">
            <FontAwesomeIcon onClick={this.toggleTitleChange} icon="pen" fixedWidth />
          </Tooltip>
        </td>
        <td>
          <Button bsStyle="link" className="btn-link-inline" onClick={this.toggleTaskAdd}>
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            New task
          </Button>
        </td>
      </tr>
    );
  },

  renderSelectPhaseCheckbox() {
    return (
      <Checkbox
        tooltip="Select phase to merge"
        checked={this.props.isMarked}
        onChange={this.toggleMarkPhase}
      />
    );
  },

  toggleTaskAdd(e) {
    this.props.toggleAddNewTask();
    this.onStopPropagation(e);
  },

  toggleMarkPhase() {
    this.props.onMarkPhase(this.props.phase.get('id'));
  },

  toggleTitleChange(e) {
    this.props.togglePhaseIdChange(this.props.phase.get('id'));
    this.onStopPropagation(e);
  },

  onStopPropagation(e) {
    e.preventDefault();
    e.stopPropagation();
  },
});

export default PhaseEditRow;
