import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import SapiTableSelector from '@/modules/components/react/components/SapiTableSelector';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const NewTableButton = createReactClass({
  propTypes: {
    onCreateTable: PropTypes.func.isRequired,
    allBuckets: PropTypes.object.isRequired,
    allTables: PropTypes.object.isRequired,
    createdTables: PropTypes.object,
  },

  getInitialState() {
    return {
      isSaving: false,
      showModal: false,
      tableId: '',
      title: '',
    };
  },

  open() {
    this.setState({ showModal: true });
  },

  close() {
    this.setState(this.getInitialState());
  },

  renderModal() {
    return (
      <Modal onHide={this.close} show={this.state.showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Table</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>{this.form()}</Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.state.isSaving}
            isDisabled={!this.state.tableId || !this.state.title}
            saveLabel="Add Table"
            onSave={this.handleCreate}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  render() {
    return (
      <>
        <Button bsStyle="success" bsSize="sm" onClick={this.handleOpenButtonClick}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Table
        </Button>
        {this.renderModal()}
      </>
    );
  },

  handleOpenButtonClick(e) {
    e.preventDefault();
    this.open();
  },

  setSelectedTable(newTableId) {
    this.setState({
      tableId: newTableId,
      title: newTableId,
    });
  },

  form() {
    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Table</ControlLabel>
          </div>
          <div className="col-xs-9">
            <SapiTableSelector
              placeholder="Select..."
              buckets={this.props.allBuckets}
              tables={this.props.allTables}
              value={this.state.tableId}
              onSelectTableFn={this.setSelectedTable}
              excludeTableFn={(tableId) => this.props.createdTables.has(tableId)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Title</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              onChange={(e) => this.setState({ title: e.target.value })}
              value={this.state.title}
            />
          </div>
        </FormGroup>
      </Form>
    );
  },

  handleSubmit(e) {
    e.preventDefault();
    this.handleCreate();
  },

  cancelSaving() {
    this.setState({ isSaving: false });
  },

  handleCreate() {
    this.setState({ isSaving: true });
    this.props.onCreateTable(this.state.tableId, this.state.title).finally(this.cancelSaving);
  },
});

export default NewTableButton;
