import React from 'react';

import { routeNames as settingsRouteNames } from '@/modules/settings/constants';
import CircleIcon from '@/react/common/CircleIcon';
import RouterLink from '@/react/common/RouterLink';

const ActiveUsersSimple = ({ usersCount }: { usersCount: number }) => (
  <div className="box box-panel box-panel-small">
    <div className="box-header">
      <h2 className="box-title">Manage Users</h2>
      <CircleIcon icon="user-group" color="green" />
    </div>
    <div className="box-panel-content mt-auto">
      <p className="summary-title">{usersCount} users</p>
      <p className="summary-text text-muted font-medium">
        (Grow your team) <RouterLink to={settingsRouteNames.USERS}>Manage users</RouterLink>
      </p>
    </div>
  </div>
);

export default ActiveUsersSimple;
