import type { List, Map } from 'immutable';

import { EX_GENERIC_V_2 as componentId } from '@/constants/componentIds';
import callDockerAction from '@/modules/components/DockerActionsApi';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import SimpleError from '@/utils/errors/SimpleError';

type SYNC_ACTION_ERROR_RESPONSE = {
  code: number;
  status: 'error';
  error: string;
  exceptionId?: string;
};

type TEST_REQUEST_RESPONSE = {
  records: Record<string, any>[];
  request: Record<string, any>;
  response: Record<string, any>;
  debug_log: string;
};

const CUSTOM_PARAMS = {
  SELECTED_JOB: '__SELECTED_JOB',
  CURL_COMMAND: '__CURL_COMMAND',
  FUNCTION_CFG: '__FUNCTION_CFG',
  PRIMARY_KEY: '__PRIMARY_KEY',
  NESTING_LEVEL: '__NESTING_LEVEL',
};

const saveConfigData = (configId: string, configData: Map<string, any>, description: string) => {
  return InstalledComponentsActionCreators.saveComponentConfigData(
    componentId,
    configId,
    configData,
    description,
  );
};

const testRequest = (parameters: Map<string, any>, endpointPath: number[]) => {
  return callDockerAction(componentId, 'test_request', {
    configData: {
      parameters: parameters.set(CUSTOM_PARAMS.SELECTED_JOB, endpointPath.join('_')).toJS(),
    },
  }).then((result: SYNC_ACTION_ERROR_RESPONSE | TEST_REQUEST_RESPONSE) => {
    if ('status' in result && result.status === 'error') {
      return {
        records: [],
        request: {},
        response: {
          status_code: result.code || 500,
          body: 'An error occurred while testing the endpoint. Check the debug log for more details.',
        },
        debug_log: `${result.error}\n\n${result.exceptionId}`.trim(),
      } as TEST_REQUEST_RESPONSE;
    }

    return result as TEST_REQUEST_RESPONSE;
  });
};

const parseCurl = (parameters: Map<string, any>, curl: string) => {
  return callDockerAction(componentId, 'load_from_curl', {
    configData: { parameters: parameters.set(CUSTOM_PARAMS.CURL_COMMAND, curl).toJS() },
  }).then((result: SYNC_ACTION_ERROR_RESPONSE | Record<string, any>) => {
    if (result?.status === 'error') {
      throw new SimpleError(
        'Curl parse failed.',
        result.error || 'An error occurred while parsing the cURL command.',
      );
    }

    return result;
  });
};

const performFunction = (parameters: Map<string, any>, functionConfig: Record<string, any>) => {
  return callDockerAction(componentId, 'perform_function', {
    configData: { parameters: parameters.set(CUSTOM_PARAMS.FUNCTION_CFG, functionConfig).toJS() },
  });
};

const prepareMapping = (
  parameters: Map<string, any>,
  endpointPath: number[],
  primaryKey: List<string>,
  nestingLevel: number,
) => {
  return callDockerAction(componentId, 'infer_mapping', {
    configData: {
      parameters: parameters
        .set(CUSTOM_PARAMS.SELECTED_JOB, endpointPath.join('_'))
        .set(CUSTOM_PARAMS.PRIMARY_KEY, primaryKey)
        .set(CUSTOM_PARAMS.NESTING_LEVEL, nestingLevel)
        .toJS(),
    },
  }).then((result: SYNC_ACTION_ERROR_RESPONSE | Record<string, any>) => {
    if (result?.status === 'error') {
      throw new SimpleError(
        'Infer mapping failed.',
        result.error || 'An error occurred while inferring mapping.',
      );
    }

    return result;
  });
};

export { saveConfigData, testRequest, parseCurl, performFunction, prepareMapping };
