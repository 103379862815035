import React, { useEffect, useRef, useState } from 'react';

const TEXTS = [
  'Stretching all tentacles…',
  'Calculating it… It might take a while',
  'Preparing the preview results…',
];
const DELAY = 5000;

const DynamicLoadingText = () => {
  const [textIndex, setTextIndex] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setTextIndex(1);
      timeoutRef.current = setTimeout(() => setTextIndex(2), DELAY);
    }, DELAY);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return <>{TEXTS[textIndex]}</>;
};

export default DynamicLoadingText;
