import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock, PanelWithDetails } from 'design';

import Checkbox from '@/react/common/Checkbox';

class Source extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>S3 Bucket</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.bucket}
                onChange={(e) => {
                  this.props.onChange({ bucket: e.target.value });
                }}
                placeholder="mybucket"
                disabled={this.props.disabled}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Search Key</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.key}
                onChange={(e) => {
                  this.props.onChange({ key: e.target.value });
                }}
                placeholder="myfolder/myfile.csv"
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Filename including folders or a prefix. Do not type <code>*</code> or <code>%</code>{' '}
                wildcards, use <strong>Wildcard</strong> checkbox instead.
              </HelpBlock>
            </div>
          </FormGroup>
          <div className="-tw-mx-5">
            <PanelWithDetails
              defaultExpanded={
                this.props.value.newFilesOnly ||
                this.props.value.wildcard ||
                this.props.value.subfolders
              }
              placement="bottom"
              labelOpen="Show additional source settings"
              labelCollapse="Hide additional source settings"
            >
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.newFilesOnly}
                    onChange={(checked) => this.props.onChange({ newFilesOnly: checked })}
                    disabled={this.props.disabled}
                  >
                    New Files Only
                  </Checkbox>
                  <HelpBlock>
                    Every job stores the timestamp of the last downloaded file and a subsequent job
                    can pick up from there.
                  </HelpBlock>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.wildcard}
                    onChange={(checked) => {
                      let change = { wildcard: checked };
                      if (change.wildcard === false) {
                        change.subfolders = false;
                      }
                      this.props.onChange(change);
                    }}
                    disabled={this.props.disabled}
                  >
                    Wildcard
                  </Checkbox>
                  <HelpBlock>Match all files beginning with the specified key.</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.subfolders}
                    onChange={(checked) => this.props.onChange({ subfolders: checked })}
                    disabled={this.props.disabled || !this.props.value.wildcard}
                  >
                    Subfolders
                  </Checkbox>
                  <HelpBlock>Download subfolders recursively.</HelpBlock>
                </div>
              </FormGroup>
            </PanelWithDetails>
          </div>
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Source;
