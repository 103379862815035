import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import type { Map } from 'immutable';
import { truncate } from 'underscore.string';

import { routeNames as settingsRoutes } from '@/modules/settings/constants';
import CreatedDate from '@/react/common/CreatedDate';
import RouterLink from '@/react/common/RouterLink';

const CliActivityBox = ({
  latestEvent,
  onClose,
}: {
  latestEvent: Map<string, any>;
  onClose: () => void;
}) => {
  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Latest CLI Activity</h2>
        <Tooltip placement="top" tooltip="Hide CLI box" type="explanatory">
          <Button bsStyle="link" className="btn-link-inline dark muted tw-mr-3" onClick={onClose}>
            <FontAwesomeIcon icon="xmark" />
          </Button>
        </Tooltip>
      </div>
      <div className="box-panel-content mt-auto">
        <CreatedDate relative className="summary-title" createdTime={latestEvent.get('created')} />
        <p className="text-muted">
          {latestEvent.get('message') && (
            <span title={latestEvent.get('message')}>
              {truncate(latestEvent.get('message'), 65)} –{' '}
            </span>
          )}
          <RouterLink to={settingsRoutes.CLI}>Settings</RouterLink>
        </p>
      </div>
    </div>
  );
};

export default CliActivityBox;
