import React, { useState } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'design';

import actions from '@/modules/stream/actions';
import { findSource } from '@/modules/stream/helpers';
import StreamStore from '@/modules/stream/store';
import ConfirmModal from '@/react/common/ConfirmModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const DetailHeaderButtons = () => {
  const { source, readOnly } = useStores(
    () => {
      const { sources } = StreamStore.getStore();
      const source = findSource(sources, RoutesStore.getCurrentRouteParam('sourceId'));

      return {
        source,
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [StreamStore, ApplicationStore, RoutesStore],
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (readOnly || !source) return null;

  return (
    <>
      <ButtonToolbar>
        <Link
          href="https://developers.keboola.com/integrate/data-streams/"
          className="btn btn-default"
        >
          <FontAwesomeIcon icon="book-blank" className="icon-addon-right" />
          Documentation
        </Link>
        <Button onClick={() => setShowDeleteModal(true)} disabled={isDeleting}>
          <FontAwesomeIcon icon="trash" className="icon-addon-right" />
          Delete Data Stream
        </Button>
      </ButtonToolbar>
      <ConfirmModal
        closeAfterResolve
        show={showDeleteModal}
        icon="trash"
        title="Delete Data Stream"
        text="Are you sure you want to delete the data stream?"
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          setIsDeleting(true);

          return actions.deleteSource(source.sourceId).finally(() => setIsDeleting(false));
        }}
        onHide={() => setShowDeleteModal(false)}
        isLoading={isDeleting}
      />
    </>
  );
};

export default DetailHeaderButtons;
