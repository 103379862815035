import React from 'react';
import { resolve } from 'react-router-named-routes';
import { Promise as BluebirdPromise } from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import Api from '@/api';
import type {
  CreateSinkRequestBody,
  GenericError,
  Task,
  UpdateSinkRequestBody,
  UpdateSourceRequestBody,
  UpdateSourceSettingsRequestBody,
} from '@/api/routes/streamService';
import Dispatcher from '@/Dispatcher';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import { routeNames } from '@/modules/storage/constants';
import RoutesStore from '@/stores/RoutesStore';
import { actionTypes } from './constants';
import { findSink, findSource } from './helpers';
import type { StoreSink } from './store';
import store from './store';

const DEFAULT_PARAMS = {
  branchId: 'default',
};

const resolveWhenTaskFinishes = async (
  response: GenericError | Task,
): Promise<GenericError | Task> => {
  if ('isFinished' in response && !response.isFinished) {
    return BluebirdPromise.delay(500)
      .then(() => Api.streamService.GetTask({ taskId: response.taskId }))
      .then(resolveWhenTaskFinishes);
  }

  return BluebirdPromise.resolve(response);
};

const throwOnError = <R extends Task>(response: R | GenericError): R | undefined => {
  if ('error' in response) {
    throw 'message' in response ? response.message : response.error;
  }

  return response;
};

const loadSourcesForce = () => {
  return Api.streamService
    .ListSources(DEFAULT_PARAMS)
    .then((result) => {
      if ('sources' in result) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SOURCES_SUCCESS,
          sources: result.sources,
        });

        return result.sources;
      }
    })
    .then((sources) => {
      if (!sources) return;

      return BluebirdPromise.map(sources, (source) => loadSinks(source.sourceId), {
        concurrency: 5,
      }).then(() => sources);
    });
};

const loadSources = () => {
  const loadedSources = store.getStore().sources;

  // One source can be loaded on detail page so we need to check if at least 2 sources are loaded
  if (loadedSources.length > 2) {
    loadSourcesForce();

    return BluebirdPromise.resolve(loadedSources);
  }

  return loadSourcesForce();
};

const loadSourceForce = (sourceId: string) => {
  return Api.streamService
    .GetSource({ ...DEFAULT_PARAMS, sourceId })
    .then((result) => {
      if ('sourceId' in result) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SOURCE_SUCCESS,
          source: result,
        });

        return result;
      }
    })
    .then((source) => {
      if (!source) return;

      return loadSinks(source.sourceId);
    });
};

const loadSource = (sourceId: string) => {
  const loadedSource = findSource(store.getStore().sources, sourceId);

  if (loadedSource) {
    loadSourceForce(sourceId);

    return BluebirdPromise.resolve(loadedSource);
  }

  return loadSourceForce(sourceId);
};

const createSource = (name: string, sinkParams: CreateSinkRequestBody) => {
  return Api.streamService
    .CreateSource(DEFAULT_PARAMS, { name, type: 'http' })
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then((result) => {
      if (result && result.outputs?.sourceId) {
        return actions
          .createSink(result.outputs.sourceId, sinkParams)
          .then(() => result.outputs!.sourceId);
      }
    });
};

const updateSource = (sourceId: string, params: UpdateSourceRequestBody) => {
  return Api.streamService
    .UpdateSource({ ...DEFAULT_PARAMS, sourceId }, params)
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then(() => loadSourceForce(sourceId))
    .then((sources) => {
      StorageActionCreators.loadBucketsAndTablesForce();

      return sources;
    });
};

const deleteSource = (sourceId: string) => {
  return Api.streamService
    .DeleteSource({ ...DEFAULT_PARAMS, sourceId })
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then(() => {
      const source = findSource(store.getStore().sources, sourceId);

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Data stream{' '}
            {source ? (
              <>
                <b>{source.name}</b>{' '}
              </>
            ) : (
              ''
            )}
            has been deleted.
          </>
        ),
      });

      Dispatcher.handleViewAction({
        type: actionTypes.DELETE_SOURCE_SUCCESS,
        sourceId,
      });

      if (
        resolve(routeNames.STREAM_DETAIL, { sourceId: source?.sourceId }) ===
        RoutesStore.getRouterState().getIn(['location', 'pathname'])
      ) {
        RoutesStore.getRouter().transitionTo(routeNames.STREAMS);
      }
    });
};

const deleteSources = (sourceIds: string[]) => {
  return BluebirdPromise.map(sourceIds, (sourceId) =>
    Api.streamService
      .DeleteSource({ ...DEFAULT_PARAMS, sourceId })
      .then(resolveWhenTaskFinishes)
      .then(throwOnError),
  )
    .then(() => loadSourcesForce())
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'Selected data streams has been deleted.',
      });
    });
};

const loadAllSourceStatistics = (sourceId: string) => {
  const source = findSource(store.getStore().sources, sourceId);

  if (!source?.sinks) return Promise.resolve();

  return BluebirdPromise.map(
    source.sinks,
    (sink) =>
      Promise.all([
        loadSinkStatistics(source.sourceId, sink.sinkId),
        loadSinkStatisticsTotal(source.sourceId, sink.sinkId),
      ]),
    { concurrency: 5 },
  );
};

const loadSinksForce = (sourceId: string) => {
  return Api.streamService
    .ListSinks({ ...DEFAULT_PARAMS, sourceId })
    .then((result) => {
      if ('sinks' in result) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SINKS_SUCCESS,
          sinks: result.sinks,
          sourceId,
        });

        return result.sinks;
      }
    })
    .then((sinks) => {
      if (!sinks) return;

      return BluebirdPromise.map(
        sinks,
        (sink) =>
          Promise.all([
            loadSinkSettings(sourceId, sink.sinkId),
            loadSinkStatistics(sourceId, sink.sinkId),
            loadSinkStatisticsTotal(sourceId, sink.sinkId),
          ]),
        { concurrency: 5 },
      ).then(() => sinks);
    });
};

const loadSinks = (sourceId: string) => {
  const loadedSinks = findSource(store.getStore().sources, sourceId)?.sinks;

  if (loadedSinks?.length) {
    loadSinksForce(sourceId);

    return BluebirdPromise.resolve(loadedSinks);
  }

  return loadSinksForce(sourceId);
};

const createSink = (sourceId: string, params: CreateSinkRequestBody) => {
  return Api.streamService
    .CreateSink({ ...DEFAULT_PARAMS, sourceId }, params)
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then((result) => {
      if (result && result.outputs?.sinkId) {
        return loadSourceForce(sourceId).then(() => {
          StorageActionCreators.loadBucketsAndTablesForce();

          return result.outputs!.sinkId;
        });
      }
    });
};

const updateSink = (sourceId: string, sinkId: string, params: UpdateSinkRequestBody) => {
  return Api.streamService
    .UpdateSink({ ...DEFAULT_PARAMS, sourceId, sinkId }, params)
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then((result) => {
      if (result && result.outputs?.sinkId) {
        return loadSourceForce(sourceId).then(() => {
          StorageActionCreators.loadBucketsAndTablesForce();

          return result.outputs!.sinkId;
        });
      }
    });
};

const loadSinkSettingsForce = (sourceId: string, sinkId: string) => {
  return Api.streamService
    .GetSinkSettings({ ...DEFAULT_PARAMS, sourceId, sinkId })
    .then((result) => {
      if ('settings' in result) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SINK_SETTINGS_SUCCESS,
          settings: result.settings ?? [],
          sourceId,
          sinkId,
        });

        return result.settings ?? [];
      }
    });
};

const loadSinkSettings = (sourceId: string, sinkId: string) => {
  const loadedSinkSettings = findSink(findSource(store.getStore().sources, sourceId)?.sinks, sinkId)
    ?.settings;

  if (loadedSinkSettings?.length) {
    loadSinkSettingsForce(sourceId, sinkId);

    return BluebirdPromise.resolve(loadedSinkSettings);
  }

  return loadSinkSettingsForce(sourceId, sinkId);
};

const updateSinkSettings = (
  sourceId: string,
  sinkId: string,
  params: UpdateSourceSettingsRequestBody,
) => {
  return Api.streamService
    .UpdateSinkSettings({ ...DEFAULT_PARAMS, sourceId, sinkId }, params)
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then(() => loadSourceForce(sourceId));
};

const loadSinkStatisticsForce = (sourceId: string, sinkId: string) => {
  return Api.streamService
    .SinkStatisticsFiles({ ...DEFAULT_PARAMS, sourceId, sinkId })
    .then((response) => {
      if ('files' in response) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SINK_STATISTICS_SUCCESS,
          statistics: response.files,
          sourceId,
          sinkId,
        });

        return response.files;
      }
    });
};

const loadSinkStatistics = (sourceId: string, sinkId: string) => {
  const loadedSinkStatistics = findSink(
    findSource(store.getStore().sources, sourceId)?.sinks,
    sinkId,
  )?.statistics;

  if (loadedSinkStatistics) {
    loadSinkStatisticsForce(sourceId, sinkId);

    return BluebirdPromise.resolve(loadedSinkStatistics);
  }

  return loadSinkStatisticsForce(sourceId, sinkId);
};

const loadSinkStatisticsTotalForce = (sourceId: string, sinkId: string) => {
  return Api.streamService
    .SinkStatisticsTotal({ ...DEFAULT_PARAMS, sourceId, sinkId })
    .then((response) => {
      if ('total' in response) {
        Dispatcher.handleViewAction({
          type: actionTypes.LOAD_SINK_STATISTICS_TOTAL_SUCCESS,
          statisticsTotal: response,
          sourceId,
          sinkId,
        });

        return response;
      }
    });
};

const loadSinkStatisticsTotal = (sourceId: string, sinkId: string) => {
  const loadedSinkStatisticsTotal = findSink(
    findSource(store.getStore().sources, sourceId)?.sinks,
    sinkId,
  )?.statisticsTotal;

  if (loadedSinkStatisticsTotal) {
    loadSinkStatisticsTotalForce(sourceId, sinkId);

    return BluebirdPromise.resolve(loadedSinkStatisticsTotal);
  }

  return loadSinkStatisticsTotalForce(sourceId, sinkId);
};

const clearSinkStatistics = (sourceId: string, sinkId: string) => {
  Dispatcher.handleViewAction({
    type: actionTypes.CLEAR_SINK_STATISTICS,
    sourceId,
    sinkId,
  });

  return Api.streamService.SinkStatisticsClear({ ...DEFAULT_PARAMS, sourceId, sinkId });
};

const testSource = (sourceId: string, payload: string) => {
  return Api.streamService.TestSource({ ...DEFAULT_PARAMS, sourceId }, payload).then((response) => {
    if ('tables' in response) {
      return response.tables;
    }
  });
};

const saveEditingSink = (sink: CreateSinkRequestBody | StoreSink, sourceId: string) => {
  return Promise.resolve()
    .then(() => {
      if (sink.sinkId) {
        return updateSink(sourceId, sink.sinkId, { ...sink, table: sink.table });
      }

      return createSink(sourceId, sink as CreateSinkRequestBody); // TODO: Fix types and get rid of casting
    })
    .then(() => loadSourceForce(sourceId));
};

const actions = {
  loadSources,
  loadSource,
  createSource,
  updateSource,
  deleteSource,
  deleteSources,
  loadAllSourceStatistics,
  createSink,
  updateSink,
  updateSinkSettings,
  clearSinkStatistics,
  testSource,
  saveEditingSink,
};

export default actions;
