export default {
  ActionTypes: {
    ROW_VERSIONS_LOAD_START: 'ROW_VERSIONS_LOAD_START',
    ROW_VERSIONS_LOAD_SUCCESS: 'ROW_VERSIONS_LOAD_SUCCESS',
    ROW_VERSIONS_LOAD_ERROR: 'ROW_VERSIONS_LOAD_ERROR',

    ROW_VERSIONS_PENDING_START: 'ROW_VERSIONS_PENDING_START',
    ROW_VERSIONS_PENDING_STOP: 'ROW_VERSIONS_PENDING_STOP',

    ROW_VERSIONS_CONFIG_LOAD_SUCCESS: 'ROW_VERSIONS_CONFIG_LOAD_SUCCESS',
    ROW_VERSIONS_MULTI_PENDING_START: 'ROW_VERSIONS_MULTI_PENDING_START',
    ROW_VERSIONS_MULTI_PENDING_STOP: 'ROW_VERSIONS_MULTI_PENDING_STOP',
  },
};
