import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import { strRight } from 'underscore.string';

import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const AGGREGATION_FUNCTIONS = ['MIN', 'MAX', 'SUM', 'COUNT', 'AVG'];

const IndexesSetupModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    configurationParameters: PropTypes.instanceOf(Map).isRequired,
  },

  getInitialState() {
    return {
      primaryIndex: this.props.configurationParameters.get('primaryIndex', List()),
      aggregation_indexes: this.props.configurationParameters
        .update('aggregation_indexes', List(), (aggregation_indexes) =>
          aggregation_indexes.isEmpty() ? List([Map()]) : aggregation_indexes,
        )
        .get('aggregation_indexes')
        .map((aggregation_index) =>
          aggregation_index.update('aggregations', List(), (aggregations) =>
            aggregations.isEmpty() ? List([Map()]) : aggregations,
          ),
        )
        .toOrderedMap(),
      join_indexes: this.props.configurationParameters
        .update('join_indexes', List(), (join_indexes) =>
          join_indexes.isEmpty() ? List([Map()]) : join_indexes,
        )
        .get('join_indexes')
        .toOrderedMap(),
      isSaving: false,
    };
  },

  render() {
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(this.getInitialState())}
      >
        <Modal.Header closeButton>
          <Modal.Title>Index Settings</Modal.Title>
          <ModalIcon icon="line-columns" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Primary Index</ControlLabel>
              </div>
              <div className="col-xs-9">
                <Select
                  multi
                  value={this.state.primaryIndex}
                  options={this.props.columns}
                  onChange={(value) => this.setState({ primaryIndex: value })}
                />
              </div>
            </FormGroup>
            {this.props.configurationParameters.get('table_type') === 'Fact' ? (
              <FormGroup>
                <div className="col-xs-3">
                  <ControlLabel>Aggregating Index</ControlLabel>
                </div>
                <div className="col-xs-9">
                  {this.state.aggregation_indexes.map(this.renderAggregationIndexRow).toArray()}
                  {this.renderAddIndexButton('aggregation_indexes')}
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <div className="col-xs-3">
                  <ControlLabel>Join Indexes</ControlLabel>
                </div>
                <div className="col-xs-9">
                  {this.state.join_indexes.map(this.renderJoinIndexRow).toArray()}
                  <div className="mt-1">{this.renderAddIndexButton('join_indexes')}</div>
                </div>
              </FormGroup>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons block isSaving={this.state.isSaving} onSave={this.handleSave} />
        </Modal.Footer>
      </Modal>
    );
  },

  renderAggregationIndexRow(aggregation_index, index) {
    return (
      <div key={index} className="flex-container mb-1">
        <div className="fill-space">
          <FormGroup className="mb-0">
            <div className="col-xs-5">
              <FormControl
                type="text"
                value={this.getIndexNameWithoutPrefix(aggregation_index)}
                placeholder="Index name"
                onChange={({ target }) =>
                  this.updateIndexes(
                    'aggregation_indexes',
                    [index, 'index_name'],
                    `kbc_${target.value}`,
                  )
                }
              />
            </div>
            <div className="col-xs-7 pl-0">
              <Select
                multi
                placeholder="Key columns"
                value={aggregation_index.get('key_columns', List())}
                options={this.props.columns}
                onChange={(value) =>
                  this.updateIndexes('aggregation_indexes', [index, 'key_columns'], value)
                }
              />
            </div>
          </FormGroup>
          {aggregation_index
            .get('aggregations', List())
            .map((aggregation, aggregationIndex) => {
              return (
                <div key={`${index}${aggregationIndex}`} className="flex-container">
                  <FormGroup className="mr-0 mb-0 fill-space">
                    <div className="col-xs-6">
                      <Select
                        placeholder="Function"
                        value={aggregation.get('function', '')}
                        options={AGGREGATION_FUNCTIONS.map((functionName) => ({
                          label: functionName,
                          value: functionName,
                        }))}
                        onChange={(value) =>
                          this.updateIndexes(
                            'aggregation_indexes',
                            [index, 'aggregations', aggregationIndex, 'function'],
                            value,
                          )
                        }
                      />
                    </div>
                    <div className="col-xs-6 pl-0">
                      <Select
                        placeholder="Column"
                        value={aggregation.get('column', '')}
                        options={this.props.columns}
                        onChange={(value) =>
                          this.updateIndexes(
                            'aggregation_indexes',
                            [index, 'aggregations', aggregationIndex, 'column'],
                            value,
                          )
                        }
                      />
                    </div>
                  </FormGroup>
                  <Checkbox
                    checked={aggregation.get('distinct', true)}
                    onChange={(checked) => {
                      this.updateIndexes(
                        'aggregation_indexes',
                        [index, 'aggregations', aggregationIndex, 'distinct'],
                        checked,
                      );
                    }}
                  >
                    Distinct
                  </Checkbox>
                  {this.renderRemoveAggregationButton(index, aggregationIndex)}
                </div>
              );
            })
            .toArray()}
          {this.renderAddAggregationButton(index)}
        </div>
        {this.renderRemoveIndexButton('aggregation_indexes', index)}
      </div>
    );
  },

  renderJoinIndexRow(join_index, index) {
    return (
      <FormGroup key={index} className="flex-container mb-0">
        <div className="fill-space">
          <div className="col-xs-3">
            <FormControl
              type="text"
              value={this.getIndexNameWithoutPrefix(join_index)}
              placeholder="Index name"
              onChange={({ target }) =>
                this.updateIndexes('join_indexes', [index, 'index_name'], `kbc_${target.value}`)
              }
            />
          </div>
          <div className="col-xs-3 pl-0">
            <Select
              placeholder="Join index"
              value={join_index.get('join_index', '')}
              options={this.props.columns}
              onChange={(value) => this.updateIndexes('join_indexes', [index, 'join_index'], value)}
            />
          </div>
          <div className="col-xs-6 pl-0 pr-0">
            <Select
              multi
              placeholder="Dimension columns"
              value={join_index.get('dimension_columns', [])}
              options={this.props.columns}
              onChange={(value) =>
                this.updateIndexes('join_indexes', [index, 'dimension_columns'], value)
              }
            />
          </div>
        </div>
        <div className="mr-1">{this.renderRemoveIndexButton('join_indexes', index)}</div>
      </FormGroup>
    );
  },

  renderAddIndexButton(indexesType) {
    return (
      <Button
        onClick={() =>
          this.setState((state) => ({
            [indexesType]: state[indexesType].set(
              state[indexesType].keySeq().max() + 1,
              Map(indexesType === 'aggregation_indexes' ? { aggregations: List([Map()]) } : {}),
            ),
          }))
        }
        className="btn-link-inline dark icon-addon-left"
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Add Index
      </Button>
    );
  },

  renderRemoveIndexButton(indexesType, index) {
    return (
      <Button
        onClick={() =>
          this.setState((state) => ({
            [indexesType]: state[indexesType].delete(index),
          }))
        }
        title="Remove Index"
        bsStyle="link"
        style={{ alignSelf: 'start' }}
        disabled={this.state[indexesType].count() < 2}
      >
        <FontAwesomeIcon icon="xmark" />
      </Button>
    );
  },

  renderAddAggregationButton(index) {
    return (
      <div className="mt-1">
        <Button
          onClick={() => {
            this.setState((state) => ({
              aggregation_indexes: state.aggregation_indexes.updateIn(
                [index, 'aggregations'],
                List(),
                (aggregations) => aggregations.push(Map()),
              ),
            }));
          }}
          className="btn-link-inline dark icon-addon-left"
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Add Aggregation
        </Button>
      </div>
    );
  },

  renderRemoveAggregationButton(index, aggregationIndex) {
    return (
      <Button
        onClick={() =>
          this.setState((state) => ({
            aggregation_indexes: state.aggregation_indexes.deleteIn([
              index,
              'aggregations',
              aggregationIndex,
            ]),
          }))
        }
        title="Remove Aggregation"
        bsStyle="link"
        className="btn-link-inline dark icon-addon-left"
        disabled={this.state.aggregation_indexes.getIn([index, 'aggregations'], List()).count() < 2}
      >
        <FontAwesomeIcon icon="xmark" />
      </Button>
    );
  },

  getColumns() {
    return this.props.columns
      .map((column) => ({ label: column, value: column }))
      .toList()
      .toJS();
  },

  getIndexNameWithoutPrefix(index = Map()) {
    return strRight(index.get('index_name', ''), 'kbc_');
  },

  updateIndexes(indexesType, path, value) {
    this.setState((state) => ({
      [indexesType]: state[indexesType].setIn(path, value),
    }));
  },

  handleSave() {
    this.setState({ isSaving: true });

    return this.props.onSave(this.getValidData()).then(() => this.closeModal());
  },

  getValidData() {
    return {
      primaryIndex: this.state.primaryIndex,
      aggregation_indexes: this.state.aggregation_indexes
        .filter(
          (aggregation_index) =>
            this.getIndexNameWithoutPrefix(aggregation_index).length &&
            !aggregation_index.get('key_columns', List()).isEmpty(),
        )
        .map((aggregationIndex) =>
          aggregationIndex.update('aggregations', List(), (aggregations) =>
            aggregations
              .filter(
                (aggregation) =>
                  aggregation.get('column', '').length &&
                  AGGREGATION_FUNCTIONS.includes(aggregation.get('function', '')),
              )
              .toList(),
          ),
        )
        .toList()
        .toJS(),
      join_indexes: this.state.join_indexes
        .filter(
          (join_index) =>
            this.getIndexNameWithoutPrefix(join_index).length &&
            join_index.get('join_index', '').length,
        )
        .toList()
        .toJS(),
    };
  },

  closeModal() {
    this.setState({ isSaving: false });
    this.props.onHide();
  },
});

export default IndexesSetupModal;
