import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'design';

const DocumentationLink = ({ href }) => (
  <Link href={href}>
    <FontAwesomeIcon icon="book-blank" fixedWidth />
    Documentation
  </Link>
);

DocumentationLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default DocumentationLink;
