import React from 'react';
import classnames from 'classnames';

const OptionalFormLabel = (props: { block?: boolean }) => {
  return (
    <span className={classnames('tw-text-neutral-400', { 'tw-block': props.block })}>
      (optional)
    </span>
  );
};

export default OptionalFormLabel;
