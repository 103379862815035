const QUERY_TYPE = {
  NESTED: 'nested-query',
  ASYNC_INSIGHTS: 'async-insights-query',
};

const LIMIT_MIN = 1;
const LIMIT_DEFAULT = 25;
const LIMIT_MAX = 100;
const LIMIT_HELP = `The parameter of the Facebook Graph API nested request. It specifies the size of data returned in one page of the request. Maximum is ${LIMIT_MAX}, the default ${LIMIT_DEFAULT}.`;
const RUNBYID_HELP =
  'This is useful when a query run fails with the error "Please reduce the amount of data you are asking for". By checking this option, the component will run the query for each ID separately instead of all IDs simultaneously while still storing all extracted rows in one table.';

export { QUERY_TYPE, LIMIT_MIN, LIMIT_DEFAULT, LIMIT_MAX, LIMIT_HELP, RUNBYID_HELP };
