import { TRANSFORMATION } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';

export default (componentId, configId, versionId) => {
  return () => {
    const reloadCallback = () => {
      const promises = [
        InstalledComponentsActionCreators.loadComponentConfigData(componentId, configId),
      ];
      if (componentId === TRANSFORMATION) {
        promises.unshift(InstalledComponentsActionCreators.loadComponentConfigsData(componentId));
      }
      return promises;
    };

    return VersionsActionCreators.rollbackVersion(componentId, configId, versionId, reloadCallback);
  };
};
