import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { isPartialJob } from '@/modules/jobs/helpers';
import JobErrorPanel from '@/modules/queue/components/JobErrorPanel';
import JobStatsContainer from '@/modules/queue/components/JobStatsContainer';
import { calculateAverage } from '@/modules/queue/helpers';
import CircleIcon from '@/react/common/CircleIcon';
import JobDuration from '@/react/common/JobDuration';
import { timeInWords } from '@/utils/duration';
import JobsParametersResultsPanel from './JobParametersResultsPanel';

const JOB_IS_SLOWER_PERCENTAGE = 110;
const JOB_IS_FASTER_PERCENTAGE = 90;
const JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS = 120;
const JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS = 30;

const JOB_SLOW_TEXT = 'Looks like your job took longer than usual to finish';
const JOB_FAST_TEXT =
  'Your job finished normally and has been running without any issues for some time!';

class PanelsRows extends React.Component {
  render() {
    return (
      <div className="box-panels-row">
        {this.props.job.get('status') === 'error' ? (
          <JobErrorPanel job={this.props.job} sapiToken={this.props.sapiToken} />
        ) : (
          this.renderDurationBox()
        )}
        {this.renderParametersAndResults()}
        {this.renderMapping()}
      </div>
    );
  }

  renderDurationBox() {
    const duration = this.props.job.get('durationSeconds');
    const average = calculateAverage(this.props.graphData);
    const isPartial = isPartialJob(this.props.job);

    if (
      !isPartial &&
      this.props.job.get('status') === 'success' &&
      duration > JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS &&
      average > 0
    ) {
      const is10percentSlowerThanAverage =
        parseInt((duration * 100) / average, 10) >= JOB_IS_SLOWER_PERCENTAGE;
      const is10percentFasterThanAverage =
        parseInt((duration * 100) / average, 10) <= JOB_IS_FASTER_PERCENTAGE;
      const difference = Math.abs(parseInt(duration - average, 10));

      if (
        difference > JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS &&
        is10percentSlowerThanAverage
      ) {
        return (
          <div className="job-total-duration-panel box box-panel">
            <div className="box-header">
              <h3 className="box-title">
                Duration <span className="color-danger">(+{timeInWords(difference)})</span>
              </h3>
              <CircleIcon icon="circle-caret-up" color="red" />
            </div>
            <div className="box-panel-content mt-auto">
              {this.renderDuration()}
              <p className="summary-text mb-0">{JOB_SLOW_TEXT}</p>
            </div>
          </div>
        );
      }

      if (
        !isPartial &&
        difference > JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS &&
        is10percentFasterThanAverage
      ) {
        return (
          <div className="job-total-duration-panel box box-panel">
            <div className="box-header">
              <h3 className="box-title">
                Duration <span className="color-success">(-{timeInWords(difference)})</span>
              </h3>
              <CircleIcon icon="circle-caret-down" color="green" />
            </div>
            <div className="box-panel-content mt-auto">
              {this.renderDuration()}
              <p className="summary-text mb-0">
                {`${JOB_FAST_TEXT} `}
                <span className="font-medium text-success">Huraaaaayyyyyy!</span>
              </p>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="job-total-duration-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title">Duration</h3>
        </div>
        <div className="box-panel-content mt-auto">
          {this.renderDuration()}
          {this.props.job.get('status') === 'success' &&
            this.props.job.get('endTime') &&
            !isPartial && <p className="summary-text mb-0">{JOB_FAST_TEXT}</p>}
        </div>
      </div>
    );
  }

  renderDuration() {
    return (
      <p className="summary-title">
        <JobDuration
          status={this.props.job.get('status')}
          startTime={this.props.job.get('startTime')}
          endTime={this.props.job.get('endTime')}
        />
      </p>
    );
  }

  renderParametersAndResults() {
    return (
      <div className="job-parameters-results-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title">Parameters &amp; Results</h3>
          <CircleIcon icon="brackets-curly" color="blue" />
        </div>
        <JobsParametersResultsPanel job={this.props.job} />
      </div>
    );
  }

  renderMapping() {
    return (
      <JobStatsContainer
        key={this.props.job.get('runId')}
        runId={this.props.job.get('runId')}
        autoRefresh={!this.props.job.get('endTime')}
      />
    );
  }
}

PanelsRows.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  graphData: PropTypes.instanceOf(Map),
};

export default PanelsRows;
