import React from 'react';
import type { PropsWithChildren } from 'react';

import { cn } from '../utils/classNames';

export const HelpBlock = ({
  children,
  className,
  variant = 'default',
}: PropsWithChildren & { className?: string; variant?: 'default' | 'danger' | 'warning' }) => {
  return (
    <p
      className={cn(
        'tw-mt-1 tw-text-xs last:tw-mb-0',
        {
          'tw-text-neutral-500': variant === 'default',
          'tw-text-error-500': variant === 'danger',
          'tw-text-warning-500': variant === 'warning',
        },
        className,
      )}
    >
      {children}
    </p>
  );
};
