import React from 'react';
import { Button, Well } from 'react-bootstrap';
import { Badge } from 'design';
import type { Map } from 'immutable';

import type { FeedbackStatusType } from '@/modules/ai/constants';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { getJobComponentId } from '@/modules/jobs/JobComponentResolver';
import { extractErrorDetails } from '@/modules/queue/helpers';
import FeedbackBox from '@/react/common/AI/FeedbackBox';
import AnimationBar from '@/react/common/AnimationBar';
import Markdown from '@/react/common/Markdown';
import NewLineToBr from '@/react/common/NewLineToBr';
import UrlsToLinks from '@/react/common/UrlsToLinks';

const JobErrorResult = (props: {
  job: Map<string, any>;
  feedbackStatus: FeedbackStatusType | null;
  useAi: boolean;
  hasError: boolean;
  explanationViaAI: string;
  onReload: () => void;
  onGood: () => void;
  onBad: (message?: string) => void;
  isGenerating: boolean;
}) => {
  const parts = [];

  let message =
    props.job.get('error') === 'application'
      ? 'Internal Error'
      : extractErrorDetails(props.job.getIn(['result', 'message']));

  if (
    props.job.hasIn(['result', 'context', 'configurationId']) &&
    props.job.hasIn(['result', 'context', 'rowId']) &&
    props.job.hasIn(['result', 'context', 'queryNumber']) &&
    props.job.hasIn(['result', 'context', 'query'])
  ) {
    message = props.job.getIn(['result', 'message']);

    if (message.match(/Executing query #(\d*) failed:/)) {
      const matches = message.match(/Executing query #(\d*) failed:/);

      if (matches) {
        message = message.substring(matches.index || 0 + matches[0].length);
      }
    }

    parts.push(
      <p key="transformationlink">
        {'Transformation '}
        <ComponentConfigurationLink
          componentId={getJobComponentId(props.job)}
          configId={props.job.getIn(['result', 'context', 'configurationId'])}
          rowId={props.job.getIn(['result', 'context', 'rowId']).toString()}
        >
          {props.job.getIn(['result', 'context', 'rowName'])}
        </ComponentConfigurationLink>
        {' has failed'}
      </p>,
    );

    parts.push(
      <div key="transformationerror">
        <strong>Error</strong>
        <div>
          <NewLineToBr text={message} />
        </div>
      </div>,
    );

    parts.push(
      <div key="transformationqueryheadline">
        <strong>
          {'Query '}
          <small>
            <ComponentConfigurationLink
              componentId={getJobComponentId(props.job)}
              configId={props.job.getIn(['result', 'context', 'configurationId'])}
              rowId={props.job.getIn(['result', 'context', 'rowId']).toString()}
              query={{
                highlightQueryNumber: props.job.getIn(['result', 'context', 'queryNumber']),
              }}
            >
              <span>Open query</span>
            </ComponentConfigurationLink>
          </small>
        </strong>
        <div>
          <pre>
            <code>
              <NewLineToBr text={props.job.getIn(['result', 'context', 'query'])} />
            </code>
          </pre>
        </div>
      </div>,
    );
  } else {
    parts.push(
      <p key="genericerrordesc">
        <UrlsToLinks text={message} />
      </p>,
    );

    if (props.useAi) {
      parts.push(
        <React.Fragment key="aierrordesc">
          <Well className="bg-color no-border f-16 p-1">
            <div className="line-height-24 font-medium flex-container mbp-3">
              <div className="flex-container flex-start pre-wrap">
                <Badge text="AI" variant="cyan" placement="left" />
                Explanation
              </div>
            </div>
            {(props.explanationViaAI && !props.isGenerating) || props.hasError ? (
              <div className="m-0 f-14 line-height-20">
                {!props.hasError ? (
                  <Markdown collapsible={false} source={props.explanationViaAI.trim()} />
                ) : (
                  <div className="text-center line-height-20">
                    <p className="mb-0 font-medium">Explanation is not available.</p>
                    <p className="mb-0 text-muted">
                      Try to{' '}
                      <Button bsStyle="link" className="btn-link-inline" onClick={props.onReload}>
                        regenerate explanation
                      </Button>{' '}
                      or try it again later
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <AnimationBar />
            )}
          </Well>
          {props.explanationViaAI && !props.hasError && (
            <FeedbackBox
              onReload={props.onReload}
              onBad={props.onBad}
              onGood={props.onGood}
              feedbackStatus={props.feedbackStatus}
              entityName="Explanation"
              disabled={props.isGenerating}
            />
          )}
        </React.Fragment>,
      );
    }

    if (props.job.get('error') === 'application') {
      parts.push(
        <p key="apperror">
          Something is broken. Our developers were notified about this error. Feel free to contact
          support to request more information.
        </p>,
      );
    }
  }

  return parts;
};

export default JobErrorResult;
