import React from 'react';
import type { Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import MarkedText from '@/react/common/MarkedText';
import string from '@/utils/string';

const VALID_TYPES = Object.values(componentTypes);

type Props = {
  component: Map<string, any>;
  configurations: number;
  query: string;
  onSelect: (component: Map<string, any>) => void;
};

class AddTaskComponentRow extends React.PureComponent<Props> {
  render() {
    return (
      <button className="btn-block" onClick={() => this.props.onSelect(this.props.component)}>
        <div className="flex-container flex-start align-top">
          <ComponentIcon component={this.props.component} size="30" />
          <div className="ml-1">
            <div className="font-medium line-height-18">
              <ComponentName component={this.props.component}>
                {(name) => <MarkedText source={name} mark={this.props.query} />}
              </ComponentName>
            </div>
            <div className="f-11 line-height-1 text-muted">
              {VALID_TYPES.includes(this.props.component.get('type'))
                ? `${getNewComponentTypeLabel(this.props.component.get('type'))} - `
                : ''}
              {this.props.configurations}{' '}
              {string.pluralize(this.props.configurations, 'configuration')}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

export default AddTaskComponentRow;
