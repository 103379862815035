import React from 'react';
import { Button } from 'react-bootstrap';

import contactSupport from '@/utils/contactSupport';
import LoadingPlaceholder from './LoadingPlaceholder';

const NoGraphWrapper = ({
  isLoading,
  isError,
  children,
}: {
  isLoading: boolean;
  isError: boolean;
  children: React.ReactNode;
}) => {
  return isLoading ? (
    <LoadingPlaceholder />
  ) : isError ? (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      <h3>Failed to Load Data Lineage</h3>
      <p className="tw-mb-6 tw-text-sm tw-text-neutral-400">
        Unable to load data lineage information on this screen. Please check back later. If the
        issue persists, please{' '}
        <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
          contact our support
        </Button>
        .
      </p>
    </div>
  ) : (
    children
  );
};

export default NoGraphWrapper;
