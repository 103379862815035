import React from 'react';
import type { Map } from 'immutable';

import callDockerAction from '@/modules/components/DockerActionsApi';
import { prepareDbtSyncActionConfigData } from '@/modules/transformations-v2/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import LoadingBlock from '@/react/common/LoadingBlock';
import fromJSOrdered from '@/utils/fromJSOrdered';

const CompileResults = ({
  componentId,
  configId,
  configData,
  onLoad,
}: {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  onLoad: (compiledSql: string) => void;
}) => {
  const [data, setData] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (!data) {
      const cancelablePromise = callDockerAction(componentId, 'dbtCompile', {
        configData: prepareDbtSyncActionConfigData(configId, configData),
      }).then((response) => {
        if (response?.status === 'error') {
          return setError(response?.message);
        }

        if (!response.compiled) {
          return setError('No data found.');
        }

        const compiledSql = fromJSOrdered(response.compiled)
          .map((sql: string, name: string) => `/* ===== ${name} =====  */\n\n${sql}`)
          .join('\n\n');

        setData(compiledSql);
        onLoad(compiledSql);
      });

      return () => {
        cancelablePromise?.cancel();
      };
    }
  }, [data, componentId, configId, configData, onLoad]);

  if (!data && !error) {
    return <LoadingBlock label="Loading artifact data..." className="tw-h-24" />;
  }

  if (!data) {
    return <p className="mb-0">{error || 'Failed to load artifact data.'}</p>;
  }

  return (
    <CodeEditor
      value={data}
      options={{ mode: 'text/x-sql', lineNumbers: false, readOnly: true, cursorHeight: 0 }}
      editorDidMount={(editor: any) => editor?.setSize('100%', 600)}
    />
  );
};

export default CompileResults;
