import React from 'react';

import TableIconAction from './TableIconAction';

type Props = {
  onClick: () => void;
  multiple?: boolean;
  disabled?: boolean;
};

const CreateSnapshot = ({ onClick, multiple = false, disabled = false }: Props) => {
  return (
    <TableIconAction
      tooltip={`Create snapshot${multiple ? 's' : ''}`}
      onClick={onClick}
      disabled={disabled}
      icon="camera-viewfinder"
    />
  );
};

export default CreateSnapshot;
