import { fromJS } from 'immutable';

import { LOGIN_TYPES } from '@/modules/wr-aws-s3/constants';

export function createConfiguration(localState) {
  if (localState.get('loginType') === LOGIN_TYPES.ROLE) {
    return fromJS({
      parameters: {
        loginType: LOGIN_TYPES.ROLE,
        accountId: localState.get('accountId', ''),
        roleName: localState.get('roleName', ''),
        bucket: localState.get('bucket', ''),
      },
    });
  }

  return fromJS({
    parameters: {
      loginType: LOGIN_TYPES.CREDENTIALS,
      accessKeyId: localState.get('awsAccessKeyId', ''),
      '#secretAccessKey': localState.get('awsSecretAccessKey', ''),
      bucket: localState.get('bucket', ''),
    },
  });
}

export function parseConfiguration(configuration) {
  if (configuration.getIn(['parameters', 'loginType']) === LOGIN_TYPES.ROLE) {
    return fromJS({
      loginType: LOGIN_TYPES.ROLE,
      accountId: configuration.getIn(['parameters', 'accountId'], ''),
      roleName: configuration.getIn(['parameters', 'roleName'], ''),
      bucket: configuration.getIn(['parameters', 'bucket'], ''),
    });
  }

  return fromJS({
    loginType: LOGIN_TYPES.CREDENTIALS,
    awsAccessKeyId: configuration.getIn(['parameters', 'accessKeyId'], ''),
    awsSecretAccessKey: configuration.getIn(['parameters', '#secretAccessKey'], ''),
    bucket: configuration.getIn(['parameters', 'bucket'], ''),
  });
}

export function isComplete(configuration) {
  if (!configuration.getIn(['parameters', 'bucket'], '')) {
    return false;
  }

  if (configuration.getIn(['parameters', 'loginType']) === LOGIN_TYPES.ROLE) {
    return (
      configuration.getIn(['parameters', 'accountId'], '') !== '' &&
      configuration.getIn(['parameters', 'roleName'], '') !== ''
    );
  }

  return (
    configuration.getIn(['parameters', 'accessKeyId'], '') !== '' &&
    configuration.getIn(['parameters', '#secretAccessKey'], '') !== ''
  );
}

export function conform(configuration) {
  let conformedConfiguration = configuration;

  if (
    conformedConfiguration.has('parameters') &&
    !conformedConfiguration.hasIn(['parameters', 'loginType'])
  ) {
    conformedConfiguration = conformedConfiguration.setIn(
      ['parameters', 'loginType'],
      LOGIN_TYPES.CREDENTIALS,
    );
  }

  return conformedConfiguration;
}
