import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock } from 'design';

import {
  assertTdeFileName as validateTdeFileName,
  webalizeTdeFileName,
} from '@/modules/tde-exporter/tdeCommon';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

class OutputFileName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      editingValue: props.initialValue,
      editingValueWebalized: webalizeTdeFileName(props.initialValue),
      error: null,
    };
  }

  render() {
    return (
      <>
        <InlineEditTextInput
          text={this.state.isEditing ? this.state.editingValue : this.props.initialValue}
          editTooltip="Edit output file name"
          isSaving={this.props.isSaving}
          isEditing={this.state.isEditing}
          isValid={!this.state.error}
          isChanged={this.state.editingValue !== this.props.initialValue}
          onEditStart={() => {
            this.setState({
              isEditing: true,
            });
          }}
          onEditCancel={() => {
            this.setState({
              isEditing: false,
              editingValue: this.props.initialValue,
            });
          }}
          onEditChange={(value) => {
            this.setState({
              editingValue: value,
              editingValueWebalized: webalizeTdeFileName(value),
              error: validateTdeFileName(value),
            });
          }}
          onEditSubmit={() => {
            this.props.saveFn(this.state.editingValueWebalized).then(() => {
              this.setState({
                isEditing: false,
                editingValue: this.state.editingValueWebalized,
              });
            });
          }}
        />
        {this.renderMessage()}
      </>
    );
  }

  renderMessage() {
    if (this.state.isEditing && this.state.error) {
      return <HelpBlock>{this.state.error}</HelpBlock>;
    }
    if (this.state.isEditing && this.state.editingValue !== this.state.editingValueWebalized) {
      return (
        <HelpBlock>
          Will be saved as {this.state.editingValueWebalized}
          {this.state.error}
        </HelpBlock>
      );
    }
    return null;
  }
}

OutputFileName.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  saveFn: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
};

export default OutputFileName;
