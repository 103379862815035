import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import actionCreators from '@/modules/wr-db/actionCreators';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

const TableNameEdit = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    tableId: PropTypes.string,
    configId: PropTypes.string,
    componentId: PropTypes.string,
    setEditValueFn: PropTypes.func,
    editingValue: PropTypes.string,
    currentValue: PropTypes.string,
    isSaving: PropTypes.bool,
    tableExportedValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  },

  _handleEditStart() {
    return this.props.setEditValueFn(this.props.currentValue);
  },

  _handleEditSave() {
    return actionCreators
      .setTableToExport(
        this.props.componentId,
        this.props.configId,
        this.props.tableId,
        this.props.editingValue,
        this.props.tableExportedValue,
      )
      .then(() => {
        return this.props.setEditValueFn(null);
      });
  },

  _handleEditCancel() {
    return this.props.setEditValueFn(null);
  },

  _handleEditChange(value) {
    return this.props.setEditValueFn(value);
  },

  render() {
    const isEditing = !!this.props.editingValue;
    const text = isEditing ? this.props.editingValue : this.props.currentValue;

    if (this.props.readOnly) {
      return text;
    }

    return (
      <InlineEditTextInput
        text={text}
        editTooltip="Edit Database Table name"
        placeholder="Database Table name"
        isSaving={this.props.isSaving}
        isEditing={isEditing}
        isChanged={this.props.currentValue !== this.props.editingValue}
        onEditStart={this._handleEditStart}
        onEditCancel={this._handleEditCancel}
        onEditChange={this._handleEditChange}
        onEditSubmit={this._handleEditSave}
      />
    );
  },
});

export default TableNameEdit;
