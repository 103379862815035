import React from 'react';
import { URLS } from '@keboola/constants';
import { Link } from 'design';

type Props = {
  isWriter: boolean;
  section?: string;
};

const DocumentationLink = ({ isWriter, section }: Props) => {
  return (
    <Link
      href={`${URLS.USER_DOCUMENTATION}/components/${
        isWriter ? 'writers' : 'extractors'
      }/storage/aws-s3/${section ?? ''}`}
    >
      documentation
    </Link>
  );
};

export default DocumentationLink;
