import { fromJS, Map } from 'immutable';

import {
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';
import { KEY as ENDPOINT_KEY } from './endpoint';

const KEY = 'placeholders';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const placeholders = transformMapToKeyValueList(job.get('placeholders', Map()));

  return fromJS({ [KEY]: { placeholders } });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  if (!editing.getIn([ENDPOINT_KEY, 'childJob'])) {
    return job.delete('placeholders');
  }

  const placeholders = transformKeyValueListToMap(editing.getIn([KEY, 'placeholders']));

  return placeholders.isEmpty()
    ? job.delete('placeholders')
    : job.set('placeholders', placeholders);
};

export { KEY, mapToState, mapToJob };
