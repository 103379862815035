import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import CreatedDate from '@/react/common/CreatedDate';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';

const Overview = createReactClass({
  propTypes: {
    job: PropTypes.object.isRequired,
  },

  render() {
    return (
      <div className="box info-row">
        <div className="info-row-section">
          <h4 className="first-line">Status</h4>
          <p className="last-line">
            <JobStatusLabel status={this.props.job.get('status')} />
          </p>
        </div>
        <div className="info-row-section">
          <h4 className="first-line">Total duration</h4>
          <p className="last-line">
            <JobDuration
              status={this.props.job.get('status')}
              startTime={this.props.job.get('startTime')}
              endTime={this.props.job.get('endTime')}
            />
          </p>
        </div>
        <div className="info-row-section">
          <h4 className="first-line">Start</h4>
          <p className="last-line">
            <CreatedDate createdTime={this.props.job.get('startTime')} absolute />
          </p>
        </div>
        <div className="info-row-section">
          <h4 className="first-line">End</h4>
          <p className="last-line">
            <CreatedDate createdTime={this.props.job.get('endTime')} absolute />
          </p>
        </div>
      </div>
    );
  },
});

export default Overview;
