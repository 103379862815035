const timeInWords = (duration: number, round?: boolean) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.round(duration % 60);

  if (duration === 0) {
    return '0 sec';
  }

  const result = [];

  if (hours === 1) {
    result.push(hours + ' hr');
  }

  if (hours > 1) {
    result.push(hours + ' hrs');
  }

  if (minutes > 0) {
    result.push(minutes + ' min');
  }

  if (seconds > 0 && (!round || hours === 0)) {
    result.push(seconds + ' sec');
  }

  if (!result.length) {
    return '';
  }

  return result.join(' ');
};

const calculateDuration = (started: string | Date, finished: string | Date) => {
  return (new Date(finished).getTime() - new Date(started).getTime()) / 1000;
};

const durationInWords = (duration: number, unit?: 'Hours' | 'Minutes' | 'Seconds') => {
  return timeInWords(duration * (unit === 'Hours' ? 3600 : unit === 'Minutes' ? 60 : 1));
};

export { timeInWords, calculateDuration, durationInWords };
