import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames as storageRoutes } from '@/modules/storage/constants';
import Link from '@/react/common/RouterLink';

const StorageApiBucketLink = createReactClass({
  propTypes: {
    bucketId: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
  },

  render() {
    return (
      <Link
        to={storageRoutes.BUCKET}
        params={{ bucketId: this.props.bucketId }}
        className={this.props.className}
      >
        {this.props.children}
      </Link>
    );
  },
});

export default StorageApiBucketLink;
