import { List, Map } from 'immutable';
import _ from 'underscore';
import { strRight, strRightBack } from 'underscore.string';

import { STAGE } from '@/modules/storage/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import { KEY } from './mapping/endpoint';
import type { ACTIVE_MENU, PARAM_VALUE } from './constants';
import {
  FUNCTION_NAME,
  JOB_NAME,
  MENUS,
  PARAMETER_TYPE,
  RESERVED_CONFIG_NAMES,
  RESERVED_CUSTOM_NAMES,
} from './constants';

const setMenuParam = (menu: ACTIVE_MENU) => {
  return Array.isArray(menu) ? `endpoint-${menu.join('-')}` : menu;
};

const parseMenuParam = (menu?: string) => {
  if (menu?.startsWith('endpoint-')) {
    return strRight(menu, 'endpoint-')
      .split('-')
      .map((index) => parseInt(index, 10));
  }

  return menu && menu.replace('-', '_').toUpperCase() in MENUS ? (menu as ACTIVE_MENU) : MENUS.BASE;
};

const prepareNewEndpoint = (endpoint: string) => {
  const name = strRightBack(endpoint, '/');

  return Map({
    [JOB_NAME]: name,
    endpoint,
    method: 'GET',
    dataType: string.webalize(name),
  });
};

const prepareEndpointPath = (
  parameters: Map<string, any>,
  editing: Map<string, any>,
  jobPath: (string | number)[],
) => {
  if (editing.getIn([KEY, 'childJob'])) {
    const parentPath: number[] = flattenJobs(parameters)
      .find((job: Map<string, any>) => job.get('endpoint') === editing.getIn([KEY, 'parent']))
      .get('endpointPath');
    const newParentPath = [...prepareRealJobPath(parentPath), 'children'];

    // no change
    if (_.isEqual(newParentPath, jobPath.slice(0, -1))) {
      return jobPath.filter((value) => value !== 'children');
    }

    // new child job
    return [...parentPath, parameters.getIn(['config', 'jobs', ...newParentPath], List()).count()];
  }

  // no change
  if (jobPath.length === 1) {
    return jobPath.filter((value) => value !== 'children');
  }

  // new root job
  return [parameters.getIn(['config', 'jobs'], List()).count()];
};

const prepareRealJobPath = (endpoint: number[]) => {
  return endpoint
    .map((index) => [index, 'children'])
    .flat()
    .slice(0, -1);
};

const preparePropertyName = (name: string) => {
  return name.replace(/^#/, '');
};

const prepareUserParameters = (parameters: Map<string, any>) => {
  return parameters.get('config', Map()).filter((value: PARAM_VALUE, key: string) => {
    return !RESERVED_CONFIG_NAMES.includes(key);
  });
};

const prepareOutputBucket = (buckets: Map<string, any>, bucket: string) => {
  if (!buckets.has(bucket)) {
    return bucket;
  }

  return strRight(bucket, bucket.includes('.c-') ? '.c-' : '.');
};

const getOutputBucketValue = (buckets: Map<string, any>, bucket: string) => {
  const bucketId = ApplicationStore.hasDisableLegacyBucketPrefix()
    ? `${STAGE.IN}.${bucket}`
    : `${STAGE.IN}.c-${bucket}`;

  return buckets.has(bucketId) ? bucketId : bucket;
};

const getMenuAfterEndpointDeletion = (jobPath: number[], menu: ACTIVE_MENU) => {
  if (!Array.isArray(menu) || !menu) {
    return menu || MENUS.BASE;
  }

  for (let i = 0; i < menu.length; i++) {
    if (jobPath[i] > menu[i]) {
      return menu;
    }

    if (jobPath[i] < menu[i]) {
      return [...menu.slice(0, i), menu[i] - 1, ...menu.slice(i + 1)];
    }
  }

  const lastIndex = menu[menu.length - 1];

  if (jobPath.length === 1) {
    return lastIndex === 0 ? MENUS.BASE : [lastIndex - 1];
  }

  return lastIndex === 0 ? jobPath.slice(0, -1) : jobPath.slice(0, -1).concat(lastIndex - 1);
};

const extractPlaceholders = (endpoint: string) => {
  return Array.from(endpoint.matchAll(/{(.+?)}/g), ([, match]) => match);
};

const getJobChildren = (job: Map<string, any>, endpointPath: number[]) => {
  return job
    .get('children', List())
    .reduce((jobs: List<any>, job: Map<string, any>, index: number) => {
      const newEndpointPath = [...endpointPath, index];

      return jobs
        .push(job.set('endpointPath', newEndpointPath))
        .concat(getJobChildren(job, newEndpointPath));
    }, List());
};

const flattenJobs = (parameters: Map<string, any>) => {
  return parameters
    .getIn(['config', 'jobs'], List())
    .reduce((jobs: List<any>, job: Map<string, any>, index: number) => {
      return jobs.push(job.set('endpointPath', [index])).concat(getJobChildren(job, [index]));
    }, List());
};

const parameterType = (value: PARAM_VALUE, name?: string) => {
  if (name?.startsWith('#')) {
    return PARAMETER_TYPE.ENCRYPTED;
  }

  switch (typeof value) {
    case 'boolean':
      return PARAMETER_TYPE.BOOLEAN;

    case 'number':
      return PARAMETER_TYPE.NUMBER;

    case 'object':
      return PARAMETER_TYPE.FUNCTION;

    default:
      return PARAMETER_TYPE.STRING;
  }
};

const isSimpleParameter = (value: Map<string, any>) => value.count() === 1 && value.has('attr');

const isFunctionParameter = (value: Map<string, any>) => value.has(FUNCTION_NAME);

const updateAllParametersReferences = (
  parameters: Map<string, any>,
  prevName: string,
  name: string,
  prevValue: PARAM_VALUE,
  value: PARAM_VALUE,
) => {
  if (prevName === name && !Map.isMap(value) && !Map.isMap(prevValue)) {
    return parameters;
  }

  const newValue = Map.isMap(value)
    ? (value as Map<string, any>).set(FUNCTION_NAME, name)
    : Map({ attr: name });

  const checkValue = (value: any) => {
    if (List.isList(value)) {
      return value.map(checkValue);
    }

    if (!Map.isMap(value)) {
      return value;
    }

    if (isSimpleParameter(value)) {
      return value.get('attr') === prevName ? newValue : value;
    }

    if (isFunctionParameter(value)) {
      return value.get(FUNCTION_NAME) === prevName ? newValue : value;
    }

    return value.map(checkValue);
  };

  return parameters.map(checkValue).toMap();
};

const isReservedParam = (value: PARAM_VALUE) => {
  if (!Map.isMap(value)) {
    return false;
  }

  const mapValue = value as Map<string, any>;
  const reservedParams = Object.values(RESERVED_CUSTOM_NAMES);

  if (isSimpleParameter(mapValue)) {
    return reservedParams.includes(mapValue.get('attr', ''));
  }

  const argsValue = JSON.stringify(mapValue.get('args', ''));
  return reservedParams.some((name) => argsValue.includes(name));
};

const filterReservedRows = (data: Map<string, any>) => {
  return data.filter(isReservedParam);
};

const transformKeyValueListToMap = (list: List<Map<string, any>>) => {
  return list
    .filter((row?: Map<string, any>) => !!row?.get('key'))
    .reduce((map?: Map<string, any>, row?: Map<string, any>) => {
      if (!row) {
        return map as Map<string, any>;
      }

      return (map as Map<string, any>).set(row.get('key'), row.get('value'));
    }, Map());
};

const transformMapToKeyValueList = (params: Map<string, PARAM_VALUE>) => {
  return params
    .map((value: PARAM_VALUE | undefined, key: string | undefined) => Map({ key, value }))
    .toList();
};

export {
  setMenuParam,
  parseMenuParam,
  prepareNewEndpoint,
  prepareEndpointPath,
  prepareRealJobPath,
  preparePropertyName,
  prepareUserParameters,
  prepareOutputBucket,
  getOutputBucketValue,
  getMenuAfterEndpointDeletion,
  extractPlaceholders,
  flattenJobs,
  parameterType,
  isSimpleParameter,
  updateAllParametersReferences,
  isReservedParam,
  filterReservedRows,
  transformKeyValueListToMap,
  transformMapToKeyValueList,
};
