import { SERVICE_OAUTH } from '@/constants/serviceIds';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import { HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY } from '@/utils/errors/helpers';
import request from '@/utils/request';

function createUrl(path) {
  return ServicesStore.getServiceUrl(SERVICE_OAUTH) + '/' + path;
}

function createRequest(method, path) {
  return request(method, createUrl(path)).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
}

export default {
  getAllCredentialsForBranch: function (branchId = null) {
    return createRequest('GET', 'credentials')
      .query({ branchId })
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  getCredentials: function (componentId, id) {
    return createRequest('GET', 'credentials/' + componentId + '/' + id)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  postCredentials: function (componentId, params, data) {
    return createRequest('POST', 'credentials/' + componentId)
      .send({ ...params, data })
      .promise()
      .catch((error) => {
        // fallback to new service with new structure of the data
        if (error?.response?.status === HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY) {
          return createRequest('POST', 'credentials/' + componentId)
            .send({ ...params, '#data': JSON.stringify(data) })
            .promise();
        }

        throw error;
      })
      .then(function (response) {
        return response.body;
      });
  },

  deleteCredentials: function (componentId, id) {
    return createRequest('DELETE', 'credentials/' + componentId + '/' + id).promise();
  },
};
