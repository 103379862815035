import { fromJS } from 'immutable';

export default fromJS({
  BOOLEAN: {
    name: 'BOOLEAN',
    basetype: 'BOOLEAN',
    size: false,
  },
  DECIMAL: {
    name: 'DECIMAL',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '38,38',
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  INT: {
    name: 'INT',
    basetype: 'INTEGER',
    size: false,
  },
  STRING: {
    name: 'STRING',
    basetype: 'STRING',
    size: false,
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false,
  },
});
