import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import createReactClass from 'create-react-class';
import { Link } from 'design';
import { List, Map, OrderedMap } from 'immutable';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { SIDEBAR } from '@/constants/external';
import { FEATURE_DATA_APPS_ENFORCE_AUTH, FEATURE_RAW_MODE } from '@/constants/features';
import { ioType } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import MappingWrapper from '@/modules/components/react/components/MappingsWrapper';
import ScheduleConfigurationButton from '@/modules/components/react/components/ScheduleConfigurationButton';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { AUTO_SLEEP_DEFAULT, SANDBOX_LABELS, SANDBOX_SIZES } from '@/modules/sandboxes/Constants';
import {
  getAutoSleepNote,
  getBackendSizeNote,
  prepareSandboxes,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import CreatedDate from '@/react/common/CreatedDate';
import ParametersBox from '@/react/common/CredentialsBox';
import { UsedInFlowsModal } from '@/react/common/FlowsListModal';
import RouterLink from '@/react/common/RouterLink';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import AdditionalSettings from './components/AdditionalSettings';
import AuthenticationSettings from './components/AuthenticationSettings';
import DeleteDataApp from './components/DeleteDataApp';
import DeployDataApp from './components/DeployDataApp';
import DeploymentSettings from './components/DeploymentSettings';
import OpenDataApp from './components/OpenDataApp';
import SecretsBox from './components/SecretsBox';
import TerminateDataApp from './components/TerminateDataApp';
import ThemeSettings from './components/ThemeSettings';
import { routeNames } from './constants';

const SandboxDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      SandboxesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      NotificationsStore,
      DevBranchesStore,
      ComponentsStore,
      StackFeaturesStore,
      JobsStore,
      VersionsStore,
    ),
  ],

  getStateFromStores() {
    const componentId = KEBOOLA_DATA_APPS;
    const configId = RoutesStore.getCurrentRouteParam('config');
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));

    return {
      componentId,
      configId,
      sandbox,
      configData,
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(componentId),
      ),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      latestJobs: JobsStore.getLatestJobs(componentId, configId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      pendingActions: SandboxesStore.getPendingActions(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(componentId, configId),
      notifications: NotificationsStore.getAll(),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
      isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
      isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
      hasRawMode: ApplicationStore.hasCurrentAdminFeature(FEATURE_RAW_MODE),
      hasDataAppsEnforcedAuth: StackFeaturesStore.hasStackFeature(FEATURE_DATA_APPS_ENFORCE_AUTH),
      sapiToken: ApplicationStore.getSapiToken(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configId}
          versionsLinkTo={routeNames.DATA_APP_VERSIONS}
        />
        {this.renderInfoPanel()}
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              readOnly={this.state.readOnly}
            />
            {this.renderDetail()}
            {this.renderAuthentication()}
            {this.renderAdditionalSettings()}
            {this.renderDeployment()}
            {this.renderTheming()}
            {this.renderSecrets()}
            <MappingWrapper>
              {this.tableInputMapping()}
              {this.fileInputMapping()}
            </MappingWrapper>
          </div>
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>
              <div className="nav nav-stacked">
                <li>
                  <DeployDataApp
                    mode="sidebar"
                    config={this.state.config}
                    sandbox={this.state.sandbox}
                    readOnly={this.state.readOnly}
                    hasPayAsYouGo={this.state.hasPayAsYouGo}
                  />
                  {this.state.sandbox.isEmpty() && <hr />}
                </li>
                {!this.state.sandbox.isEmpty() && (
                  <li className="!tw-mt-2">
                    <OpenDataApp
                      mode="sidebar"
                      config={this.state.config}
                      sandbox={this.state.sandbox}
                    />
                    <hr />
                  </li>
                )}
                <li>
                  <TerminateDataApp
                    mode="sidebar"
                    readOnly={this.state.readOnly}
                    config={this.state.config}
                    sandbox={this.state.sandbox}
                    isPending={this.state.pendingActions.hasIn([
                      'terminate',
                      this.state.sandbox.get('id'),
                    ])}
                  />
                </li>
                {this.renderCopyButton()}
                {this.renderScheduleConfigurationButton()}
                {this.renderRawConfigurationButton()}
                <hr />
                <DeleteDataApp
                  mode="sidebar"
                  readOnly={this.state.readOnly}
                  config={this.state.config}
                  sandbox={this.state.sandbox}
                />
              </div>
              <SidebarJobs
                hasNewQueue
                jobs={this.state.latestJobs}
                componentId={this.state.componentId}
                configId={this.state.configId}
                allConfigurations={this.state.allConfigurations}
                admins={this.state.admins}
                currentAdmin={this.state.currentAdmin}
                notifications={this.state.notifications}
              />
              <hr />
              <SidebarVersions
                configId={this.state.configId}
                component={this.state.component}
                config={this.state.config}
                versionsLinkTo={routeNames.DATA_APP_VERSIONS}
                versions={this.state.versions}
                versionsConfigs={this.state.versionsConfigs}
                isLoading={this.state.isLoadingVersions}
                isPending={this.state.isPendingVersions}
                pendingMultiLoad={this.state.pendingMultiLoadVersions}
                admins={this.state.admins}
              />
            </div>
          </div>
        </div>
      </>
    );
  },

  renderInfoPanel() {
    return (
      <div className="box box-separator panel-info">
        <div className="box-content">
          <div className="flex-container">
            <div className="flex-container align-top line-height-24">
              <div className="panel-info-item no-wrap">
                <span className="text-muted">Type:</span>
                <span className="flex-container flex-start font-medium">
                  <FontAwesomeIcon icon="browser" className="f-16 icon-addon-right text-muted" />
                  Data App
                </span>
              </div>
              <div className="panel-info-item no-wrap">
                <span className="text-muted">Used in:</span>
                <span className="flex-container flex-start">
                  <UsedInFlowsModal
                    config={this.state.config}
                    component={this.state.component}
                    flows={this.state.flows}
                  />
                </span>
              </div>
              <div className="panel-info-item no-wrap aside">
                <span className="text-muted">Additional Links:</span>
                <span className="flex-container flex-start">
                  <span className="mr-1 no-wrap">
                    <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                    <Link href={`${URLS.USER_DOCUMENTATION}/components/data-apps`}>
                      Documentation
                    </Link>
                  </span>
                  {!this.state.isDemoPreview && (
                    <span className="no-wrap">
                      <FontAwesomeIcon icon="laptop" className="text-muted icon-addon-right" />
                      <Link href={`${URLS.DEMO_PROJECT}/app/data-apps`}>Demo Project</Link>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },

  renderCopyButton() {
    if (this.state.hasBackflow || this.state.readOnly) {
      return null;
    }

    return (
      <li>
        <CopyButton
          mode="sidebar"
          configuration={this.state.config}
          component={this.state.component}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderScheduleConfigurationButton() {
    if (this.state.hasBackflow || this.state.readOnly || this.state.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <ScheduleConfigurationButton
          hasNewQueue
          flows={this.state.flows}
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderRawConfigurationButton() {
    if (!this.state.hasRawMode) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={componentsRoutes.GENERIC_CONFIG_RAW}
          params={{ component: this.state.componentId, config: this.state.configId }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <FontAwesomeIcon icon="bug" fixedWidth />
          Debug mode
        </RouterLink>
      </li>
    );
  },

  tableInputMapping() {
    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        destinationType={ioType.FILE}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        allComponents={this.state.allComponents}
        sandboxes={this.state.sandboxes}
      />
    );
  },

  fileInputMapping() {
    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allComponents={this.state.allComponents}
        sandboxes={this.state.sandboxes}
      />
    );
  },

  renderAdditionalSettings() {
    return (
      <AdditionalSettings
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
        readOnly={this.state.readOnly}
        sandbox={this.state.sandbox}
      />
    );
  },

  renderTheming() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <ThemeSettings
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
        readOnly={this.state.readOnly}
        sandbox={this.state.sandbox}
      />
    );
  },

  renderDetail() {
    const creator = this.state.config.getIn(['creatorToken', 'description']);
    const backendSize = this.state.sandbox.get('size', SANDBOX_SIZES.TINY);
    const expiration = this.state.sandbox.get('expirationAfterHours', AUTO_SLEEP_DEFAULT);
    const rows = OrderedMap({
      'Data App ID': {
        noCopy: true,
        text: this.state.sandbox.get('id', 'Not Available (ID will be shown once app is deployed)'),
      },
      Owner: {
        noCopy: true,
        text:
          creator === this.state.sapiToken.get('description')
            ? 'You'
            : this.state.admins.getIn([creator, 'name'], creator || 'N/A'),
      },
      Created: {
        noCopy: true,
        component: <CreatedDate createdTime={this.state.config.get('created')} />,
      },
      'Last Change': {
        noCopy: true,
        component: (
          <CreatedDate
            createdTime={this.state.sandbox.get(
              'updatedTimestamp',
              this.state.config.getIn(['currentVersion', 'created']),
            )}
          />
        ),
      },
      'Backend Size': {
        noCopy: true,
        text: SANDBOX_LABELS[backendSize],
        ...(!this.state.hasPayAsYouGo && { hint: getBackendSizeNote(true) }),
      },
      'Auto Sleep': {
        noCopy: true,
        text: `${expiration} ${string.pluralize(expiration, 'hour')}`,
        hint: getAutoSleepNote(true),
      },
    });

    return (
      <CollapsibleBox title="Information" entity="data-app detail">
        <ParametersBox rows={rows} noBorder />
      </CollapsibleBox>
    );
  },

  renderAuthentication() {
    if (this.state.hasDataAppsEnforcedAuth) {
      return null;
    }

    return (
      <AuthenticationSettings
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
      />
    );
  },

  renderDeployment() {
    return (
      <DeploymentSettings
        componentId={this.state.componentId}
        configId={this.state.configId}
        readOnly={this.state.readOnly}
        configData={this.state.configData}
      />
    );
  },

  renderSecrets() {
    return (
      <SecretsBox
        readOnly={this.state.readOnly}
        configId={this.state.configId}
        componentId={this.state.componentId}
        configData={this.state.configData}
        secrets={this.state.configData.getIn(['parameters', 'dataApp', 'secrets'], Map())}
      />
    );
  },
});

export default SandboxDetail;
