import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_LEGACY_TRANSFORMATION, TRANSFORMATION } from '@/constants/componentIds';
import VersionRow from '@/modules/components/react/components/VersionRow';
import VersionsTable from '@/modules/components/react/components/VersionsTable';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import VersionsStore from '@/modules/configurations/RowVersionsStore';
import createRowVersionOnRollback from '@/modules/configurations/utils/createRowVersionOnRollback';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import { getPreviousVersion } from '@/utils/VersionsDiffUtils';

const Versions = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      VersionsStore,
      InstalledComponentsStore,
      ConfigurationRowsStore,
      ComponentsStore,
    ),
    immutableMixin,
  ],

  propTypes: {
    componentId: PropTypes.string,
  },

  getStateFromStores() {
    const settings = RoutesStore.getRouteSettings();
    const componentId = settings.get(
      'componentId',
      RoutesStore.getCurrentRouteParam('component', this.props.componentId),
    );
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');

    return {
      componentId,
      configId,
      rowId,
      component: ComponentsStore.getComponent(componentId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      row: ConfigurationRowsStore.get(componentId, configId, rowId),
      versions: VersionsStore.getVersions(componentId, configId, rowId),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId, rowId),
      isPending: VersionsStore.isPendingConfig(componentId, configId, rowId),
      pendingActions: VersionsStore.getPendingVersions(componentId, configId, rowId),
      pendingMultiLoad: VersionsStore.getPendingMultiLoad(componentId, configId, rowId),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  getInitialState() {
    return {
      query: '',
    };
  },

  render() {
    const showTabs = ![TRANSFORMATION, KEBOOLA_LEGACY_TRANSFORMATION].includes(
      this.state.componentId,
    );

    return (
      <>
        {showTabs && (
          <ConfigurationTabs
            componentId={this.state.componentId}
            configId={this.state.configId}
            rowId={this.state.rowId}
          />
        )}
        <div className={classnames({ 'box-separator': showTabs })}>
          <VersionsTable
            versions={this.state.versions}
            filteredVersions={this.getFilteredVersions()}
            renderRows={this.renderVersionRows}
            onSearchChange={(query) => this.setState({ query })}
            query={this.state.query}
          />
        </div>
      </>
    );
  },

  renderVersionRows(versions) {
    return versions
      .map((version) => {
        const previousVersion = getPreviousVersion(this.state.versions, version);
        const previousVersionConfig =
          getPreviousVersion(this.state.versionsConfigs, version) || Map();
        const currentVersionConfig =
          this.state.versionsConfigs
            .filter((currentVersion) => {
              return version.get('version') === currentVersion.get('version');
            })
            .first() || Map();
        const isMultiPending = this.state.pendingMultiLoad.get(version.get('version'), false);

        return (
          <VersionRow
            key={version.get('version')}
            version={version}
            versionConfig={currentVersionConfig}
            configId={this.state.configId}
            rowId={this.state.rowId}
            component={this.state.component}
            config={this.state.config}
            row={this.state.row}
            admins={this.state.admins}
            isCopyPending={this.state.pendingActions.getIn([version.get('version'), 'copy'], false)}
            isRollbackPending={this.state.pendingActions.getIn(
              [version.get('version'), 'rollback'],
              false,
            )}
            isRollbackDisabled={this.state.isPending}
            hideRollback={this.state.readOnly}
            isDiffPending={isMultiPending}
            isDiffDisabled={this.state.isPending || isMultiPending}
            previousVersion={previousVersion}
            previousVersionConfig={previousVersionConfig}
            onPrepareVersionsDiffData={() => this.prepareVersionsDiffData(version, previousVersion)}
            isLast={this.state.versions.first().get('version') === version.get('version')}
            onRollback={createRowVersionOnRollback(
              this.state.componentId,
              this.state.configId,
              this.state.rowId,
              version.get('version'),
            )}
            onCopy={(name) =>
              RowVersionsActionCreators.copyVersion(
                this.state.componentId,
                this.state.configId,
                this.state.rowId,
                version.get('version'),
                name,
              )
            }
          />
        );
      }, this)
      .toArray();
  },

  getFilteredVersions() {
    if (!this.state.query) {
      return this.state.versions;
    }

    return this.state.versions.filter((version) => {
      return matchByWords(
        [
          String(version.get('version')),
          version.get('changeDescription'),
          version.getIn(['creatorToken', 'description']),
          version.get('created'),
        ],
        this.state.query,
      );
    });
  },

  prepareVersionsDiffData(version1, version2) {
    return RowVersionsActionCreators.loadTwoComponentConfigVersions(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      version1.get('version'),
      version2.get('version'),
    );
  },
});

export default Versions;
