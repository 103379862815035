import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ColumnDataPreview from '@/modules/components/react/components/ColumnDataPreview';
import Select from '@/react/common/Select';
import Truncated from '@/react/common/Truncated';
import DateFormatHint from './DateFormatHint';

const columnTdeTypes = ['string', 'boolean', 'number', 'decimal', 'date', 'datetime', 'IGNORE'];
const defaults = {
  date: '%Y-%m-%d',
  datetime: '%Y-%m-%d %H:%M:%S',
};

const ColumnRow = createReactClass({
  propTypes: {
    isSaving: PropTypes.bool.isRequired,
    loadPreviewFn: PropTypes.func.isRequired,
    loadingPreview: PropTypes.bool.isRequired,
    column: PropTypes.string,
    tdeType: PropTypes.object,
    editing: PropTypes.object,
    dataPreview: PropTypes.object,
    onChange: PropTypes.func,
  },

  render() {
    return (
      <tr>
        <td>
          <Truncated text={this.props.column} />
        </td>
        <td>{this._renderTypeSelect()}</td>
        <td onMouseEnter={this.props.loadPreviewFn} onFocus={this.props.loadPreviewFn}>
          <ColumnDataPreview
            columnName={this.props.column}
            tableData={this.props.dataPreview}
            loading={this.props.loadingPreview}
          />
        </td>
      </tr>
    );
  },

  _renderTypeSelect() {
    const dtype = this.props.editing.get('type');
    const showFormat = ['date', 'datetime'].includes(dtype);

    return (
      <div className="form form-horizontal">
        <div className="col-sm-5 tw-pl-0">
          <Select
            clearable={false}
            value={dtype}
            options={columnTdeTypes.map((type) => ({ value: type, label: type }))}
            onChange={(value) => {
              let newData = this.props.editing.set('type', value);
              if (!['date', 'datetime'].includes(value) || value === 'IGNORE') {
                newData = newData.delete('format');
              }
              if (value === 'date') {
                newData = newData.set('format', defaults.date);
              }
              if (value === 'datetime') {
                newData = newData.set('format', defaults.datetime);
              }
              this.props.onChange(newData);
            }}
            disabled={this.props.isSaving}
          />
        </div>
        {showFormat && (
          <FormGroup className="col-sm-7 tw-pr-0">
            <InputGroup>
              {this._renderDatetFormatInput()}
              <InputGroup.Addon>
                <DateFormatHint />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
        )}
      </div>
    );
  },

  _renderDatetFormatInput() {
    const format = this.props.editing.get('format');

    return (
      <FormControl
        type="text"
        value={format}
        disabled={this.props.isSaving}
        onChange={(e) => {
          const newData = this.props.editing.set('format', e.target.value);
          this.props.onChange(newData);
        }}
      />
    );
  },
});

export default ColumnRow;
