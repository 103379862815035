import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import { ActionTypes as InstalledComponentsActionTypes } from '@/modules/components/Constants';
import { ActionTypes } from '@/modules/legacy-transformation/Constants';
import parseBuckets from '@/modules/legacy-transformation/utils/parseBuckets';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'TransformationBucketsStore2',
  Map({
    bucketsById: Map(),
    isLoading: false,
    isLoaded: false,
    loadingBuckets: List(),
    pendingActions: Map(),
  }),
);

const TransformationBucketsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('bucketsById').sortBy((bucket) => bucket.get('name'));
  },

  get(id) {
    return _store.getIn(['bucketsById', id]);
  },

  has(id) {
    return _store.get('bucketsById').has(id);
  },

  getIsLoading() {
    return _store.get('isLoading');
  },

  getIsLoaded() {
    return _store.get('isLoaded');
  },

  getPendingActions() {
    return _store.get('pendingActions');
  },

  getPendingActionsForBucket(bucketId) {
    return this.getPendingActions().get(bucketId, Map());
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case InstalledComponentsActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD:
      if (action.componentId !== KEBOOLA_LEGACY_TRANSFORMATION) {
        return;
      }
      _store = _store.set('isLoading', true);
      return TransformationBucketsStore.emitChange();

    case InstalledComponentsActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS: {
      if (action.componentId !== KEBOOLA_LEGACY_TRANSFORMATION) {
        return;
      }
      const bucketsData = parseBuckets(action.configData);
      _store = _store.withMutations((store) =>
        store
          .set('isLoading', false)
          .set('isLoaded', true)
          .set(
            'bucketsById',
            fromJS(bucketsData)
              .toMap()
              .mapKeys((key, bucket) => bucket.get('id')),
          ),
      );
      return TransformationBucketsStore.emitChange();
    }

    case ActionTypes.TRANSFORMATION_BUCKET_CREATE_SUCCESS:
      _store = _store.setIn(['bucketsById', action.bucket.id], fromJS(action.bucket));
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_BUCKET_DELETE:
      _store = _store.setIn(['pendingActions', action.bucketId, 'delete'], true);
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_BUCKET_DELETE_ERROR:
      _store = _store.deleteIn(['pendingActions', action.bucketId, 'delete']);
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS:
      _store = _store.withMutations((store) =>
        store
          .removeIn(['bucketsById', action.bucketId])
          .removeIn(['pendingActions', action.bucketId, 'delete']),
      );
      return TransformationBucketsStore.emitChange();

    case ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE:
      _store = _store.setIn(['pendingActions', action.bucketId, 'restore'], true);
      return TransformationBucketsStore.emitChange();

    case ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR:
      _store = _store.deleteIn(['pendingActions', action.bucketId, 'restore']);
      return TransformationBucketsStore.emitChange();

    case ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS:
      _store = _store.withMutations((store) =>
        store.removeIn(['pendingActions', action.bucketId, 'restore']),
      );
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS:
      _store = _store.withMutations((store) =>
        store.updateIn(
          ['bucketsById', action.bucketId, 'transformations'],
          List(),
          (transformations) =>
            transformations.map((transformation) => {
              if (transformation.get('id') === action.transformationId) {
                return fromJS(action.data);
              }

              return transformation;
            }),
        ),
      );
      return TransformationBucketsStore.emitChange();

    case InstalledComponentsActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS:
      // propagate bucket name and description change from installed components
      if (
        action.componentId !== KEBOOLA_LEGACY_TRANSFORMATION ||
        !['name', 'description'].includes(action.field)
      ) {
        return;
      }

      _store = _store.setIn(['bucketsById', action.configurationId, 'name'], action.data.name);
      _store = _store.setIn(
        ['bucketsById', action.configurationId, 'description'],
        action.data.description,
      );
      return TransformationBucketsStore.emitChange();

    default:
  }
});

export default TransformationBucketsStore;
