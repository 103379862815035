import { Map } from 'immutable';

import { BaseTypes, MetadataKeys } from '@/modules/components/MetadataConstants';
import {
  findBaseType,
  findLength,
  findNullable,
  findType,
} from '@/modules/components/utils/columnMetadataHelper';

const isNullable = (value) => [true, 'true', 1, '1'].includes(value);

const isLengthSupported = (type) => ['STRING', 'INTEGER', 'NUMERIC'].includes(type);

const getDataType = function (metadata) {
  const baseType = findBaseType(metadata);
  if (!baseType || !BaseTypes.includes(baseType.get('value'))) {
    return Map();
  }

  let outputType = Map()
    .set(MetadataKeys.BASE_TYPE, baseType.get('value'))
    .set('provider', baseType.get('provider'));

  const nativeType = findType(metadata);
  if (nativeType.count()) {
    outputType = outputType.set(MetadataKeys.TYPE, nativeType.get('value'));
  }

  const length = findLength(metadata);
  if (length.count()) {
    outputType = outputType.set(MetadataKeys.LENGTH, length.get('value'));
  }

  const nullable = findNullable(metadata);
  if (nullable.count()) {
    outputType = outputType.set(MetadataKeys.NULLABLE, isNullable(nullable.get('value')));
  }

  return outputType;
};

export { getDataType, isLengthSupported, isNullable };
