import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';
import Immutable from 'immutable';

import OutputMappingRowEditor from '@/modules/transformations/react/components/mapping/OutputMappingRowEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import validateStorageTableId from '@/utils/validateStorageTableId';

const MODE_CREATE = 'create',
  MODE_EDIT = 'edit';

const OutputMapping = createReactClass({
  propTypes: {
    transformationBucket: PropTypes.object.isRequired,
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]).isRequired,
    mapping: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    backend: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    otherOutputMappings: PropTypes.object.isRequired,
    definition: PropTypes.object,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      definition: Immutable.Map(),
      disabled: false,
    };
  },

  isNameAlreadyInUse() {
    if (this.props.backend === 'docker') {
      return this.props.otherOutputMappings
        .map(function (outputMapping) {
          return outputMapping.get('source');
        })
        .includes(this.props.mapping.get('source'));
    }
    return false;
  },

  isValid() {
    return (
      !!this.props.mapping.get('source') &&
      !!this.props.mapping.get('destination') &&
      validateStorageTableId(this.props.mapping.get('destination', '')) &&
      !this.isNameAlreadyInUse()
    );
  },

  getInitialState() {
    return {
      isSaving: false,
      showModal: false,
    };
  },

  open() {
    this.setState({
      showModal: true,
    });
  },

  close() {
    this.setState({
      showModal: false,
    });
  },

  render() {
    let title = 'Output Mapping';
    if (this.props.definition.get('label')) {
      title = this.props.definition.get('label');
    }
    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton()}
        <Modal onHide={this.handleCancel} show={this.state.showModal}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            {this.props.mode === MODE_CREATE ? <ModalIcon.Plus /> : <ModalIcon.Edit />}
          </Modal.Header>
          <Modal.Body>
            <OutputMappingRowEditor
              transformationBucket={this.props.transformationBucket}
              fill
              value={this.props.mapping}
              tables={this.props.tables}
              buckets={this.props.buckets}
              onChange={this.props.onChange}
              disabled={this.state.isSaving}
              backend={this.props.backend}
              type={this.props.type}
              definition={this.props.definition}
              isNameAlreadyInUse={this.isNameAlreadyInUse()}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.props.mode === MODE_CREATE ? 'Add Output' : 'Save Output'}
              onSave={this.handleSave}
              isSaving={this.state.isLoading}
              isDisabled={this.state.isSaving || !this.isValid()}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderOpenButton() {
    if (this.props.mode === MODE_EDIT) {
      return (
        <Tooltip tooltip="Edit Output" placement="top">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={this.handleOpenButtonLink}
            disabled={this.props.disabled}
          >
            <FontAwesomeIcon icon="pen" />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button bsStyle="link" className="header-inline-button color-success" onClick={this.open}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Output
      </Button>
    );
  },

  handleOpenButtonLink(e) {
    e.preventDefault();
    e.stopPropagation();
    this.open();
  },

  handleCancel() {
    this.props.onCancel();
    this.close();
  },

  handleSave() {
    this.setState({
      isSaving: true,
    });
    this.props
      .onSave()
      .then(() => {
        this.setState({
          isSaving: false,
        });
        this.close();
      })
      .catch((e) => {
        this.setState({
          isSaving: false,
        });
        throw e;
      });
  },
});

export default OutputMapping;
