import React from 'react';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const StepStatusIcon = ({
  isConfigurable,
  isPreconfigured,
  isConfigured,
  isValid,
}: {
  isConfigurable: boolean;
  isPreconfigured: boolean;
  isConfigured: boolean;
  isValid: boolean;
}) => {
  let props: FontAwesomeIconProps;

  if (isConfigurable && !isPreconfigured && !isConfigured) return null;
  if (!isConfigurable) {
    props = { icon: 'lock', className: 'bg-color-gray-dark' };
  } else if (isValid) {
    props = { icon: 'circle-check', className: 'text-success' };
  } else {
    props = { icon: 'circle-exclamation', className: 'text-danger' };
  }

  return (
    <FontAwesomeIcon
      icon={props.icon}
      className={classNames('align-bottom small-icon', props.className)}
    />
  );
};

export default StepStatusIcon;
