import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormGroup, Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Loader from '@/react/common/Loader';

const TablesLoadSettings = createReactClass({
  propTypes: {
    disabled: PropTypes.bool,
    onSave: PropTypes.func,
    value: PropTypes.shape({
      loadOnly: PropTypes.bool,
      multiLoad: PropTypes.bool,
    }),
  },

  getInitialState() {
    return {
      saving: null,
    };
  },

  render() {
    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Update Mode {this.renderLoader('loadOnly')}</ControlLabel>
          </div>
          <div className="col-xs-9">
            <Radio
              disabled={this.props.disabled}
              checked={!this.props.value.loadOnly}
              onChange={() => this.onSave('loadOnly')}
              name="updatetype"
            >
              {' '}
              Update model and load data
            </Radio>
            <Radio
              disabled={this.props.disabled}
              checked={this.props.value.loadOnly}
              onChange={() => this.onSave('loadOnly')}
              name="updatetype"
            >
              {' '}
              Load data only
            </Radio>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Load Mode {this.renderLoader('multiLoad')}</ControlLabel>
          </div>
          <div className="col-xs-9">
            <Radio
              disabled={this.props.disabled}
              checked={this.props.value.multiLoad}
              onChange={() => this.onSave('multiLoad')}
              name="multiload"
            >
              {' '}
              Multiload - all tables at once
            </Radio>
            <Radio
              disabled={this.props.disabled}
              checked={!this.props.value.multiLoad}
              onChange={() => this.onSave('multiLoad')}
              name="multiload"
            >
              {' '}
              Separate - one table at a time
            </Radio>
          </div>
        </FormGroup>
      </Form>
    );
  },

  renderLoader(property) {
    const { saving } = this.state;
    return saving === property && <Loader />;
  },

  onSave(property) {
    this.setState({ saving: property });
    const newValue = { [property]: !this.props.value[property] };
    this.props.onSave(newValue).then(() => this.setState({ saving: null }));
  },
});

export default TablesLoadSettings;
