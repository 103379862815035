import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';
import { Tooltip } from 'design';
import type { Map } from 'immutable';

import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';

type Props = {
  config: Map<string, any>;
  isPending: boolean;
  onConfirm: () => Promise<any>;
};

const DeleteConfigurationButton = ({ config, isPending, onConfirm }: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Tooltip tooltip="Delete Forever" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          disabled={isPending}
          onClick={() => setModalVisible(true)}
        >
          {isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        </Button>
      </Tooltip>
      <ConfirmModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        icon="trash"
        buttonLabel="Delete"
        buttonType="danger"
        title="Delete Forever"
        text={
          <>
            Are you sure you want to permanently delete the configuration{' '}
            <b>{config.get('name')}</b>?
          </>
        }
        onConfirm={onConfirm}
        isDisabled={isPending}
      />
    </>
  );
};

export default DeleteConfigurationButton;
