import React from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import { LATEST_JOBS_LIMIT, routeNames } from '@/modules/queue/constants';
import { filterLatestJobs } from '@/modules/queue/helpers';
import Loader from '@/react/common/Loader';
import Link from '@/react/common/RouterLink';
import { getLegacyComponentQuery, getQuery } from '@/utils/jobsQueryBuilder';
import SidebarJobsRow from './SidebarJobsRow';

const SidebarJobs = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    jobs: PropTypes.instanceOf(Map).isRequired,
    admins: PropTypes.instanceOf(Map).isRequired,
    allConfigurations: PropTypes.instanceOf(Map).isRequired,
    currentAdmin: PropTypes.instanceOf(Map).isRequired,
    notifications: PropTypes.instanceOf(List).isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    rowId: PropTypes.string,
  },

  getFilteredJobs() {
    const jobs = this.props.jobs.get('jobs', Map());

    return this.props.hasNewQueue ? filterLatestJobs(jobs, this.props.rowId) : jobs;
  },

  render() {
    return (
      <div className="sidebar-box">
        <h4 className="mb-1">Last Use {this.props.jobs.get('isLoading') && <Loader />}</h4>
        {this.renderJobs()}
        {this.renderAllJobsLink()}
      </div>
    );
  },

  renderJobs() {
    const jobs = this.getFilteredJobs();

    if (jobs.isEmpty()) {
      return <p className="text-muted">No uses found</p>;
    }

    return jobs
      .slice(0, LATEST_JOBS_LIMIT)
      .map((job) => (
        <SidebarJobsRow
          key={job.get('id')}
          job={job}
          admin={this.props.admins.get(job.getIn(['token', 'description']))}
          hasNewQueue={this.props.hasNewQueue}
          allConfigurations={this.props.allConfigurations}
          currentAdmin={this.props.currentAdmin}
          notifications={this.props.notifications}
        />
      ))
      .toArray();
  },

  renderAllJobsLink() {
    const firstJob = this.getFilteredJobs().first() || Map();

    if (firstJob.isEmpty() || (!firstJob.get('params') && !firstJob.get('component'))) {
      return null;
    }

    if (this.props.hasNewQueue) {
      return (
        <Link
          className="job-link jobs-page dark"
          to={routeNames.JOBS}
          query={{
            component: this.props.componentId,
            config: this.props.configId,
            row: this.props.rowId,
          }}
        >
          <FontAwesomeIcon icon="angle-right" className="icon-addon-right" />
          Show All Uses
        </Link>
      );
    }

    const queryFunction = firstJob.getIn(['params', 'component'])
      ? getQuery
      : getLegacyComponentQuery;

    return (
      <Link
        className="job-link jobs-page dark"
        to={jobsRouteNames.ROOT}
        query={{
          q: queryFunction(this.props.componentId, this.props.configId, this.props.rowId),
        }}
      >
        <FontAwesomeIcon icon="angle-right" className="icon-addon-right" />
        Show All Uses
      </Link>
    );
  },
});

export default SidebarJobs;
