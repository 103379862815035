import { JOBS_STATUS } from '@/modules/queue/constants';

export const JOBS_GRAPH_COLORS = {
  [JOBS_STATUS.SUCCESS]: '#1ec71e',
  [JOBS_STATUS.WARNING]: '#b88d00',
  [JOBS_STATUS.ERROR]: '#e00025',
  [JOBS_STATUS.TERMINATED]: '#7C8594',
  [JOBS_STATUS.TERMINATING]: '#7C8594',
  [JOBS_STATUS.CANCELLED]: '#7C8594',
  [JOBS_STATUS.CREATED]: '#7C8594',
  [JOBS_STATUS.PROCESSING]: '#7C8594',
  [JOBS_STATUS.WAITING]: '#7C8594',
} as const;

export const JOBS_GRAPH_TOOLTIP_COLORS = {
  [JOBS_STATUS.SUCCESS]: '#51e051',
  [JOBS_STATUS.WARNING]: '#b88d00',
  [JOBS_STATUS.ERROR]: '#ff5c77',
  [JOBS_STATUS.TERMINATED]: '#a2aab8',
  [JOBS_STATUS.TERMINATING]: '#a2aab8',
  [JOBS_STATUS.CANCELLED]: '#a2aab8',
  [JOBS_STATUS.CREATED]: '#a2aab8',
  [JOBS_STATUS.PROCESSING]: '#a2aab8',
  [JOBS_STATUS.WAITING]: '#a2aab8',
};

export const JOBS_GRAPH_COLORS_HOVER = {
  [JOBS_STATUS.SUCCESS]: '#1bb31b',
  [JOBS_STATUS.WARNING]: '#a36200',
  [JOBS_STATUS.ERROR]: '#b8001f',
  [JOBS_STATUS.TERMINATED]: '#565c66',
  [JOBS_STATUS.TERMINATING]: '#565c66',
  [JOBS_STATUS.CANCELLED]: '#565c66',
  [JOBS_STATUS.CREATED]: '#565c66',
  [JOBS_STATUS.PROCESSING]: '#565c66',
  [JOBS_STATUS.WAITING]: '#565c66',
} as const;

export const MINI_BAR_GRAPH_LIMIT = 10;
