import callDockerAction from '@/modules/components/DockerActionsApi';
import SyncActionError from '@/utils/errors/SyncActionError';

const runSampleQuery = (componentId, params) => {
  return callDockerAction(componentId, 'sampleJson', params)
    .then((result) => {
      if (result?.status === 'error') {
        throw new SyncActionError(
          result.message || 'An error occurred while running sample query',
          result.exceptionId,
        );
      }

      return {
        data: result?.data || [],
        isLoading: false,
        isError: false,
        error: null,
      };
    })
    .catch((error) => ({
      data: null,
      isLoading: false,
      isError: true,
      error,
    }));
};

const loadSegments = (componentId, params) => {
  return callDockerAction(componentId, 'segments', params)
    .then((result) => {
      if (result?.status === 'error') {
        throw new SyncActionError(
          result.message || 'An error occurred while loading segments',
          result.exceptionId,
        );
      }

      const segments = result.data.map((segment) => ({
        ...segment,
        id: segment.segmentId,
        attributes: {
          uiName: segment.name,
          id: segment.segmentId,
          description: '',
        },
      }));

      return {
        data: segments,
        isLoading: false,
        isError: false,
        error: null,
      };
    })
    .catch((error) => ({
      data: [],
      isLoading: false,
      isError: true,
      error,
    }));
};

export { runSampleQuery, loadSegments };
