import React from 'react';
import type { Map } from 'immutable';

import type { ConfigRow } from './constants';
import LastUseCell from './LastUseCell';

const LastUseActionCell = (props: {
  admins: Map<string, any>;
  row: ConfigRow;
  renderAction: (row: ConfigRow, body: React.ReactNode) => React.ReactNode;
}) => {
  return props.renderAction(
    props.row,
    <LastUseCell
      admins={props.admins}
      job={props.row.original.job}
      component={props.row.original.data.component}
    />,
  );
};

const MemoizedLastUseActionCell = React.memo(LastUseActionCell);

export default MemoizedLastUseActionCell;
