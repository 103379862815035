import { fromJS } from 'immutable';

export default fromJS({
  INT: {
    name: 'INT',
    basetype: 'INTEGER',
    size: false,
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  TEXT: {
    name: 'TEXT',
    basetype: 'STRING',
    size: false,
  },
  BOOLEAN: {
    name: 'BOOLEAN',
    basetype: 'BOOLEAN',
    size: false,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false,
  },
});
