import React from 'react';
import { Button, Table } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Clipboard, cn, Collapse, Link } from 'design';
import gfm from 'remark-gfm';

import CodeEditor from './CodeEditor';

const Markdown = ({
  source,
  collapsible = true,
  overflow = true,
  className,
  children,
}: {
  source?: string;
  collapsible?: boolean;
  className?: string;
  overflow?: boolean;
  children?: (markdown: React.ReactNode) => React.ReactNode;
}) => {
  const [ellipseContent, setEllipseContent] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const height = containerRef.current?.offsetHeight || 0;
    setEllipseContent(collapsible && !!source && height >= 300);
  }, [collapsible, source]);

  const markdownContent = (
    <ReactMarkdown
      skipHtml
      remarkPlugins={[gfm]}
      components={{
        a: (props) => (
          <Link href={props.href || ''} className={props.className}>
            {props.children}
          </Link>
        ),
        table: (props) => (
          <Table responsive bordered className="max-h-500">
            {props.children}
          </Table>
        ),
        pre: (props) => {
          if (
            React.isValidElement(props.children) &&
            props.children?.props?.className === 'language-snowflake'
          ) {
            const text = String(props.children.props.children).trim();

            return (
              <div className="mbp-4">
                <div className="flex-container f-14 line-height-20 mbp-1 mtp-4">
                  <span className="font-medium">Code</span>
                  <Clipboard
                    text={text}
                    label="Copy code"
                    className="dark muted"
                    tooltipPlacement="top"
                  />
                </div>
                <CodeEditor
                  value={text}
                  options={{ mode: 'text/x-sfsql', readOnly: true, cursorHeight: 0 }}
                />
              </div>
            );
          }

          return <pre>{props.children}</pre>;
        },
      }}
    >
      {source || ''}
    </ReactMarkdown>
  );

  return (
    <div
      ref={containerRef}
      className={classnames('markdown', { 'overflow-break-anywhere': overflow }, className)}
    >
      {collapsible && ellipseContent ? (
        <Collapse
          open={expanded}
          className="panel-preview tw-flex-col-reverse"
          bodyClassName={cn(
            'panel-fade tw-relative tw-min-h-[150px] tw-pr-2',
            !expanded && 'collapsed',
          )}
          header={
            <div className="tw-flex tw-justify-center">
              <Button
                bsStyle="link"
                className="btn-link-inline dark muted"
                onClick={() => {
                  setExpanded(!expanded);
                  (document.activeElement as HTMLElement | null)?.blur();
                }}
              >
                <FontAwesomeIcon className="mrp-2" icon={expanded ? 'angle-up' : 'angle-down'} />
                {expanded ? 'Show less' : 'Show more'}
              </Button>
            </div>
          }
        >
          {children ? children(markdownContent) : markdownContent}
        </Collapse>
      ) : children ? (
        children(markdownContent)
      ) : (
        markdownContent
      )}
    </div>
  );
};

export default Markdown;
