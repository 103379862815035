import React from 'react';

import { FEATURE_POWER_USER } from '@/constants/features';
import { switchedFromBranchToProduction } from '@/modules/dev-branches/helpers';
import { addUserFeature } from '@/modules/settings/actions';
import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';

const BackToProductionWarning = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [showModal, setShowModal] = React.useState(true);

  if (!switchedFromBranchToProduction()) {
    return null;
  }

  const onConfirm = () => {
    if (isChecked) {
      addUserFeature(FEATURE_POWER_USER);
    }
  };

  const onHide = () => {
    setShowModal(false);
  };

  return (
    <ConfirmModal
      icon="code"
      buttonType="primary"
      buttonLabel="I understand"
      title="You are back in live production project"
      text={
        <>
          <p>
            Just to let you know, any changes you are going to make now are done with live data and
            confugurations, so it can break stuff.
          </p>
          <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
            I am a Keboola power user. Do not display similar confirmations.
          </Checkbox>
        </>
      }
      show={showModal}
      onConfirm={onConfirm}
      onHide={onHide}
    />
  );
};

export default BackToProductionWarning;
