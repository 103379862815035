import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { HelpBlock, Link } from 'design';

import {
  SANDBOX_LABELS,
  SANDBOX_SIZES,
  SANDBOX_TYPE,
  SUPPORT_SIZES,
} from '@/modules/sandboxes/Constants';
import { getBackendSizeNote } from '@/modules/sandboxes/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';

const WorkspaceSizeControl = (props: {
  type: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
  hasPayAsYouGo: boolean;
}) => {
  if (props.hasPayAsYouGo || !SUPPORT_SIZES.includes(props.type)) {
    return null;
  }

  const isDataApp = props.type === SANDBOX_TYPE.STREAMLIT;
  const entity = isDataApp ? 'data app' : 'workspace';

  return (
    <FormGroup>
      <ControlLabel>
        Backend Size
        <InfoTooltip
          tooltip={
            <>
              <p className="tooltip-title">
                Cost for 1 hour of active {entity} (billed per second)
              </p>
              <div className="tw-mb-2">
                {isDataApp && (
                  <p>
                    <b>XSMALL</b>: 0.5 time credit
                  </p>
                )}
                <p>
                  <b>SMALL</b>: 1 time credit
                </p>
                <p>
                  <b>MEDIUM</b>: 2 time credits
                </p>
                <p>
                  <b>LARGE</b>: 6 time credits
                </p>
              </div>
              <p>{getBackendSizeNote(isDataApp)}</p>
            </>
          }
        />
      </ControlLabel>
      <Select
        clearable={false}
        options={Object.values(SANDBOX_SIZES)
          .filter((size) => isDataApp || size !== SANDBOX_SIZES.TINY)
          .map((size) => {
            return { value: size, label: SANDBOX_LABELS[size] };
          })}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
      />
      <HelpBlock>
        Read more about backend sizes and time credits in our{' '}
        <Link
          href={`${URLS.USER_DOCUMENTATION}/management/project/limits/#project-power--time-credits`}
        >
          documentation
        </Link>
        .
      </HelpBlock>
    </FormGroup>
  );
};

export default WorkspaceSizeControl;
