import { fromJS, List, Map } from 'immutable';
import { rtrim, strRight } from 'underscore.string';

import {
  KEBOOLA_WR_GOOGLE_BIGQUERY_V_2,
  KEBOOLA_WR_GOOGLE_DRIVE,
  KEBOOLA_WR_GOOGLE_SHEETS,
  TDE_EXPORTER,
} from '@/constants/componentIds';
import dayjs from '@/date';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { EX_DB_GENERIC_COMPONENTS } from '@/modules/ex-db-generic/constants';
import * as exDbHelpers from '@/modules/ex-db-generic/helpers';
import { WR_DB_COMPONENTS } from '@/modules/wr-db/constants';
import * as wrDbHelpers from '@/modules/wr-db/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import { MergableMetadata, MERGE_REQUEST_STATUS, NonMergableComponents } from './constants';
import DevBranchesStore from './DevBranchesStore';

const parseDevBranchId = (path) => {
  const pathParts = path.split('/');
  const branchKeywordIndex = pathParts.findIndex((pathPart) => pathPart === 'branch');
  if (branchKeywordIndex === -1) {
    return null;
  }
  const branchId = pathParts[branchKeywordIndex + 1];
  const branchIdInt = parseInt(branchId, 10);
  if (/^\d+$/.test(branchId) && branchIdInt > 0) {
    return branchIdInt;
  }
  return null;
};

const parseCurrentDevBranchIdFromUrl = () => {
  return parseDevBranchId(location.pathname);
};

const createdOrUpdatedInBranch = (item) => {
  return (
    // item is updated
    item.get('version') > 1 ||
    // item is new
    !item.get('changeDescription').startsWith('Copied from default branch configuration')
  );
};

const updatedInProduction = (config, production) => {
  if (!production) {
    return false;
  }

  const lastProductionUpdate = dayjs(production.getIn(['currentVersion', 'created']));
  const branchVersionCreated = dayjs(config.get('created'));

  return lastProductionUpdate.isAfter(branchVersionCreated);
};

const deletedInProduction = (componentId, configId, productionDeletedComponents) => {
  return productionDeletedComponents.hasIn([componentId, 'configurations', configId]);
};

const filterProductionBuckets = (buckets) => {
  return buckets.filterNot(isCreatedInDevBranch);
};

const filterDevBranchBuckets = (buckets) => {
  return buckets.filter(isCreatedInDevBranch);
};

const filterProductionAndCurrentDevBranchBuckets = (buckets) => {
  const currentBranch = DevBranchesStore.getCurrentId();

  return buckets.filter((bucket) => {
    if (bucket.get('idBranch') === currentBranch) {
      return true;
    }

    const createdByBranch = findCreatedByBranchIdMetadata(bucket.get('metadata', List()));
    return !createdByBranch || createdByBranch.get('value') === currentBranch?.toString();
  });
};

const filterProductionAndCurrentDevBranchTables = (tables, buckets) => {
  const filteredBuckets = filterProductionAndCurrentDevBranchBuckets(buckets);

  return tables.filter((table) => filteredBuckets.has(table.getIn(['bucket', 'id'])));
};

const findCreatedByBranchIdMetadata = (metadata) => {
  return metadata.find((item) => item.get('key') === MetadataKeys.CREATED_BY_BRANCH_ID);
};

const isCreatedInDevBranch = (bucket) => {
  return (
    (DevBranchesStore.isDevModeActive() &&
      bucket.get('idBranch') === DevBranchesStore.getCurrentId()) ||
    !!findCreatedByBranchIdMetadata(bucket.get('metadata', List()))
  );
};

const findUpdatedConfigurations = (
  components,
  productionComponents = Map(),
  deletedComponents = Map(),
  productionDeletedComponents = Map(),
  updatedMetadata = Map(),
) => {
  return components
    .filter((component, componentId) => !NonMergableComponents.includes(componentId))
    .map((component, componentId) => {
      return component.update('configurations', (configs) => {
        return configs.filter((config, configId) => {
          const production = productionComponents.getIn([
            component.get('id'),
            'configurations',
            config.get('id'),
          ]);

          return (
            updatedMetadata.hasIn([componentId, configId]) ||
            createdOrUpdatedInBranch(config) ||
            updatedInProduction(config, production) ||
            deletedInProduction(componentId, configId, productionDeletedComponents)
          );
        });
      });
    })
    .withMutations((components) => {
      deletedComponents
        .filter((component, componentId) => !NonMergableComponents.includes(componentId))
        .forEach((component, componentId) => {
          component.get('configurations').forEach((config, configId) => {
            if (productionComponents.hasIn([componentId, 'configurations', configId])) {
              if (!components.has(componentId)) {
                components.set(componentId, component.set('configurations', Map()));
              }
              components.setIn([componentId, 'configurations', configId], config);
            }
          });
        });
    })
    .filter(hasConfigurations);
};

const findUpdatedMetadata = (metadata, productionMetadata) => {
  return metadata
    .map((configs, componentId) => {
      return configs
        .map((rows, configId) => {
          const productionData = productionMetadata.getIn([componentId, configId], List());
          return rows.filter((row) => {
            if (!MergableMetadata.includes(row.get('key'))) {
              return false;
            }

            const production = productionData.find((data) => data.get('key') === row.get('key'));

            if (!production) {
              return true;
            }

            return (
              row.get('value') !== production.get('value') &&
              dayjs(row.get('timestamp')).isAfter(dayjs(production.get('timestamp')))
            );
          });
        })
        .filter((rows) => !rows.isEmpty());
    })
    .filter((configs) => !configs.isEmpty());
};

const filterUpdatedComponents = (updatedComponents, selectedComponentsIds, filterType) => {
  return updatedComponents
    .map((component, componentId) => {
      return component.update('configurations', (configurations) => {
        return configurations[filterType]((config, configId) => {
          return selectedComponentsIds.get(componentId, List()).includes(configId);
        });
      });
    })
    .filter(hasConfigurations);
};

const filterSelectedUpdatedComponents = (updatedComponents, selectedComponentsIds) => {
  return filterUpdatedComponents(updatedComponents, selectedComponentsIds, 'filter');
};

const filterUnselectedUpdatedComponents = (updatedComponents, selectedComponentsIds) => {
  return filterUpdatedComponents(updatedComponents, selectedComponentsIds, 'filterNot');
};

const filterSelectedVariables = (variables, selectedVariables) => {
  return variables.filter((variable) => selectedVariables.includes(variable.hash));
};

const filterUnselectedVariables = (variables, selectedVariables) => {
  return variables.filter((variable) => !selectedVariables.includes(variable.hash));
};

const hasConfigurations = (component) => {
  return component.get('configurations').count() > 0;
};

const prepareJobsQuery = (query) => {
  if (DevBranchesStore.isDevModeActive()) {
    return query
      ? `params.branchId:${DevBranchesStore.getCurrentId()} AND (${query})`
      : `params.branchId:${DevBranchesStore.getCurrentId()}`;
  }

  return query ? `NOT _exists_:params.branchId AND (${query})` : 'NOT _exists_:params.branchId';
};

const prepareDataForSaveInDevBranch = (componentId, config, branchId) => {
  if (!branchId) {
    return config;
  }

  return fromJS(config)
    .update((config) => {
      if (EX_DB_GENERIC_COMPONENTS.includes(componentId)) {
        if (exDbHelpers.supportConfigRows(componentId) && !config.hasIn(['parameters', 'tables'])) {
          return config.updateIn(['parameters', 'outputTable'], (outputTable) =>
            removeDevBranchReferenceFromTable(outputTable, branchId),
          );
        }

        return config.updateIn(['parameters', 'tables'], List(), (tables) => {
          return tables.map((table) =>
            table.update('outputTable', (outputTable) =>
              removeDevBranchReferenceFromTable(outputTable, branchId),
            ),
          );
        });
      }

      if (WR_DB_COMPONENTS.includes(componentId)) {
        if (wrDbHelpers.supportConfigRows(componentId) && !config.hasIn(['parameters', 'tables'])) {
          return config.updateIn(['parameters', 'tableId'], (tableId) =>
            removeDevBranchReferenceFromTable(tableId, branchId),
          );
        }

        return config.updateIn(['parameters', 'tables'], List(), (tables) => {
          return tables.map((table) =>
            table.update('tableId', (tableId) =>
              removeDevBranchReferenceFromTable(tableId, branchId),
            ),
          );
        });
      }

      if (
        [
          KEBOOLA_WR_GOOGLE_SHEETS,
          KEBOOLA_WR_GOOGLE_DRIVE,
          KEBOOLA_WR_GOOGLE_BIGQUERY_V_2,
        ].includes(componentId)
      ) {
        return config.updateIn(['parameters', 'tables'], List(), (tables) => {
          return tables.map((table) =>
            table.update('tableId', (tableId) =>
              removeDevBranchReferenceFromTable(tableId, branchId),
            ),
          );
        });
      }

      if (TDE_EXPORTER === componentId) {
        return config
          .updateIn(['parameters', 'tables'], Map(), (tables) => {
            return tables.mapKeys((tableId) =>
              removeDevBranchReferenceFromTable(tableId, branchId),
            );
          })
          .updateIn(['parameters', 'typedefs'], Map(), (tables) => {
            return tables.mapKeys((tableId) =>
              removeDevBranchReferenceFromTable(tableId, branchId),
            );
          });
      }

      return config;
    })
    .updateIn(['storage', 'input', 'tables'], List(), (tables) => {
      return tables.map((table) =>
        table
          .update('source', (source) => removeDevBranchReferenceFromTable(source, branchId))
          .update('destination', (destination) => {
            if (!destination) {
              return;
            }

            return removeDevBranchReferenceFromTable(destination, branchId);
          }),
      );
    })
    .updateIn(['storage', 'output', 'tables'], List(), (tables) => {
      return tables.map((table) =>
        table.update('destination', (destination) =>
          removeDevBranchReferenceFromTable(destination, branchId),
        ),
      );
    })
    .updateIn(['storage', 'input', 'files'], List(), (files) => {
      return files.map((file) =>
        file
          .update('tags', List(), (tags) =>
            tags.map((tag) => removeDevBranchReferenceFromTag(tag, branchId)),
          )
          .update('processed_tags', List(), (tags) =>
            tags.map((tag) => removeDevBranchReferenceFromTag(tag, branchId)),
          ),
      );
    })
    .updateIn(['storage', 'output', 'files'], List(), (files) => {
      return files.map((file) =>
        file.update('tags', List(), (tags) =>
          tags.map((tag) => removeDevBranchReferenceFromTag(tag, branchId)),
        ),
      );
    })
    .updateIn(['storage', 'output', 'table_files', 'tags'], List(), (tags) => {
      return tags.map((tag) => removeDevBranchReferenceFromTag(tag, branchId));
    })
    .toJS();
};

const removeDevBranchReferenceFromTable = (text, branchId) => {
  if (!text || !branchId || ApplicationStore.hasProtectedDefaultBranch()) {
    return text;
  }

  if (!ApplicationStore.hasDisableLegacyBucketPrefix()) {
    return text.replace(new RegExp('^((?:in|out).c-)' + branchId + '-(.*)$', 'gi'), '$1$2');
  }

  return text.replace(new RegExp('^((?:in|out).)' + branchId + '-(.*)$', 'gi'), '$1$2');
};

const prefixTagsWithDevBranchId = (tags, branchId) => {
  if (!branchId || ApplicationStore.hasProtectedDefaultBranch()) {
    return tags;
  }

  return tags.map((tag) => `${branchId}-${tag}`);
};

const removeDevBranchReferenceFromTag = (tag, branchId) => {
  if (!tag || !branchId || ApplicationStore.hasProtectedDefaultBranch()) {
    return tag;
  }

  return strRight(tag, `${branchId}-`);
};

const switchedFromBranchToProduction = (
  currentHref = window.location.href,
  prevHref = document.referrer,
  navigationType = window.performance.getEntriesByType('navigation')[0]?.type,
) => {
  return (
    navigationType === 'navigate' && !!parseDevBranchId(prevHref) && !parseDevBranchId(currentHref)
  );
};

const switchedAnyBranch = (
  currentHref = window.location.href,
  prevHref = document.referrer,
  navigationType = window.performance.getEntriesByType('navigation')[0]?.type,
) => {
  return (
    navigationType === 'navigate' && parseDevBranchId(prevHref) !== parseDevBranchId(currentHref)
  );
};

const prepareProductionHref = (projectBaseUrl, pathname, currentDevBranchId) => {
  const baseUrl = rtrim(projectBaseUrl, '/');
  const appUrl = strRight(pathname, '/branch/' + currentDevBranchId);

  return rtrim(baseUrl + appUrl, '/');
};

const prepareBranchHref = (projectBaseUrl, pathname, devBranchId, currentDevBranchId) => {
  const baseUrl = rtrim(projectBaseUrl, '/');
  const branchUrl = '/branch/' + devBranchId;
  const appUrl = strRight(strRight(pathname, baseUrl), '/branch/' + currentDevBranchId);

  return rtrim(baseUrl + branchUrl + appUrl, '/');
};

const removeBranchFromUrl = (url) => {
  const partToRemove = 'branch/\\d+/'; // This pattern matches "branch/" followed by one or more digits
  const regex = new RegExp(partToRemove, 'g');

  return url.replace(regex, '');
};

const isMergeRequestCreated = (mergeRequest) => {
  return !mergeRequest.isEmpty();
};

const isMergeRequestInReview = (mergeRequest) => {
  return mergeRequest.get('state') === MERGE_REQUEST_STATUS.IN_REVIEW;
};

const isMergeRequestApproved = (mergeRequest) => {
  return mergeRequest.get('state') === MERGE_REQUEST_STATUS.APPROVED;
};

const isMergeRequestApprovedByCurrentAdmin = (mergeRequest, admin) => {
  return mergeRequest.get('approvals', List()).some((approval) => {
    return approval.get('approverId').toString() === admin.get('id').toString();
  });
};

const isMergeRequestSentByCurrentAdmin = (mergeRequest, admin) => {
  return mergeRequest.getIn(['creator', 'id']).toString() === admin.get('id').toString();
};

const redirectToProductionIfBranchNotFound = (error) => {
  if (
    DevBranchesStore.isDevModeActive() &&
    error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND &&
    /branch .* does not exists/i.test(error.message)
  ) {
    window.location.replace(ApplicationStore.getProjectBaseUrl());
    return true;
  }
};

export {
  parseCurrentDevBranchIdFromUrl,
  findUpdatedConfigurations,
  findUpdatedMetadata,
  filterSelectedUpdatedComponents,
  filterUnselectedUpdatedComponents,
  filterSelectedVariables,
  filterUnselectedVariables,
  createdOrUpdatedInBranch,
  updatedInProduction,
  deletedInProduction,
  filterProductionBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionAndCurrentDevBranchTables,
  findCreatedByBranchIdMetadata,
  isCreatedInDevBranch,
  filterDevBranchBuckets,
  prepareJobsQuery,
  parseDevBranchId,
  prefixTagsWithDevBranchId,
  prepareDataForSaveInDevBranch,
  removeDevBranchReferenceFromTable,
  removeDevBranchReferenceFromTag,
  switchedFromBranchToProduction,
  switchedAnyBranch,
  prepareProductionHref,
  prepareBranchHref,
  removeBranchFromUrl,
  isMergeRequestInReview,
  isMergeRequestApproved,
  isMergeRequestCreated,
  isMergeRequestApprovedByCurrentAdmin,
  isMergeRequestSentByCurrentAdmin,
  redirectToProductionIfBranchNotFound,
};
