import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';

const Timeout = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  const renderInput = (label: string, property: string) => {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          min={1}
          type="number"
          disabled={props.readOnly}
          value={props.editing.get(property)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setEditing(props.editing.set(property, parseInt(e.target.value, 10) || 0));
          }}
        />
      </FormGroup>
    );
  };

  return (
    <CollapsibleBox
      title="Timeouts"
      titleSuffix={<DocumentationLink path="configuration/api/#connect-timeout" />}
    >
      <div className="tw-grid tw-grid-cols-2 tw-items-start tw-gap-4">
        {renderInput('Connect Timeout', 'connectTimeout')}
        {renderInput('Request Timeout', 'requestTimeout')}
      </div>
    </CollapsibleBox>
  );
};

export default Timeout;
