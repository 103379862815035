import { generateSessionToken, loadProject } from '@/modules/settings/actions';
import { catchSessionTokenError } from '@/modules/settings/helpers';
import { sessionTokenPoller } from '@/modules/settings/routes';
import { routeNames } from './constants';
import UpgradePage from './UpgradePage';

export default {
  name: routeNames.UPGRADE_PAGE,
  defaultRouteHandler: UpgradePage,
  requireData: [
    () => {
      return generateSessionToken()
        .then(() => loadProject())
        .catch(catchSessionTokenError);
    },
  ],
  poll: sessionTokenPoller,
};
