import React, { useCallback, useEffect, useState } from 'react';
import { Button, Well } from 'react-bootstrap';
import { startsWith } from 'underscore.string';

import StorageExplorerApi from '@/modules/storage/api';
import Loader from '@/react/common/CustomLoader';
import Select from '@/react/common/Select';
import graphUtils from '@/utils/graphUtils';

const GraphCanvas = React.lazy(() => {
  return import(/* webpackChunkName: "graph-canvas" */ '@/react/common/GraphCanvas');
});

import { Alert, Badge } from 'design';

import type { DiagramData } from '@/utils/diagrams';

const getDefaultDirection = (table: Map<string, any>) => {
  return startsWith(table.get('id'), 'in.') ? 'forward' : 'backward';
};

type Direction = 'forward' | 'backward';

const options: { value: Direction; label: string }[] = [
  { value: 'forward', label: 'Forward' },
  { value: 'backward', label: 'Backward' },
];

type Props = {
  table: Map<string, any>;
};

const TableGraph = ({ table }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [direction, setDirection] = useState<Direction>(getDefaultDirection(table));
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DiagramData | null>(null);

  const loadData = useCallback(() => {
    setError(null);
    setIsLoading(true);

    StorageExplorerApi.loadGraph(table.get('id'), direction)
      .then((data) => {
        setData({
          ...data,
          nodes: graphUtils.addLinksToNodes(data.nodes),
        });
        return null;
      })
      .catch((error) => {
        if (error.response && error.response.body && error.response.body.message) {
          return setError(error.response.body.message);
        }
        return setError('An error occurred while fetching data.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [table, direction]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const hasValidData = !!data && data?.transitions.length > 0;

  const renderControls = () => {
    if (isLoading || !data) {
      return null;
    }

    return (
      <div className="tw-flex tw-justify-end">
        <Select
          clearable={false}
          className="w-200"
          value={direction}
          options={options}
          onChange={(value: string) => setDirection(value as Direction)}
        />
      </div>
    );
  };

  const renderGraph = () => {
    if (isLoading) {
      return <Loader text="Loading data..." loaderSize="2x" />;
    }

    if (!hasValidData) {
      return (
        <p className="tw-m-0">There are no connections for the table {table.get('displayName')}.</p>
      );
    }

    if (!data) {
      return <p className="tw-m-0">No data available.</p>;
    }

    return (
      <>
        <div className="tw-h-[330px] tw-w-full">
          <React.Suspense fallback={null}>
            <GraphCanvas data={data} />
          </React.Suspense>
        </div>
        <Well>
          <Badge text="Input" variant="green" />
          <Badge text="Transformation" variant="gray-darker" placement="right" />
          <Badge text="Output" variant="blue" placement="right" />
        </Well>
        <Alert className="tw-mb-5">
          Please note that the graph shows a maximum of 7 levels of nesting.
        </Alert>
      </>
    );
  };

  return (
    <div className="box">
      <div className="box-content clearfix">
        {error ? (
          <>
            <Alert variant="warning" className="tw-mb-5">
              {error}
            </Alert>
            <Button bsStyle="link" className="btn-link-inline" onClick={loadData}>
              Try again.
            </Button>
          </>
        ) : (
          <>
            {renderControls()}
            {renderGraph()}
          </>
        )}
      </div>
    </div>
  );
};

export default TableGraph;
