import type { Row } from '@tanstack/react-table';
import type { Map } from 'immutable';

export const DEFAULT_FOLDER_NAME = '_uncategorized';

export const MULTI_ACTIONS = {
  DELETE: 'DELETE',
  MOVE: 'MOVE',
};

export type ConfigRow = Row<{
  data: {
    config: Map<string, any>;
    component: Map<string, any>;
    id: string;
  };
  job?: Map<string, any>;
}>;

export type FolderRow = Row<{ data: string }>;
