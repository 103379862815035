import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'design';
import { domToPng } from 'modern-screenshot';

import { getExportOptions } from '@/modules/lineage/helpers';
import Loader from '@/react/common/Loader';
import { GraphContext } from './GraphContext';

function downloadImage(dataUrl: string) {
  const a = document.createElement('a');

  a.setAttribute('download', 'lineage.png');
  a.setAttribute('href', dataUrl);
  a.click();
}

const ExportToPng = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { graphId } = useContext(GraphContext);

  const onClick = () => {
    const { flowPane, ...options } = getExportOptions(graphId);

    if (!flowPane) {
      console.error('cannot export, viewport is missing');
      return;
    }

    setIsProcessing(true);
    domToPng(flowPane, { ...options })
      .then(downloadImage)
      .finally(() => setIsProcessing(false));
  };

  return (
    <Tooltip tooltip="Export to PNG" placement="left" type="action">
      <Button onClick={onClick} disabled={isProcessing}>
        {isProcessing ? <Loader /> : <FontAwesomeIcon icon="down-to-line" />}
      </Button>
    </Tooltip>
  );
};

export default ExportToPng;
