export const routeNames = {
  TEMPLATES: 'templates',
  TEMPLATE_DETAIL: 'templates-detail',
  ROOT: 'templates-instances',
  INSTANCE_NEW: 'templates-instances-new',
  INSTANCE_DETAIL: 'templates-instances-detail',
  INSTANCE_DETAIL_EDIT: 'templates-instances-detail-edit',
};

export const actionTypes = {
  LOAD_TEMPLATES_SUCCESS: 'LOAD_TEMPLATES_SUCCESS',
  LOAD_TEMPLATE_SUCCESS: 'LOAD_TEMPLATE_SUCCESS',
  LOAD_INSTANCES_SUCCESS: 'LOAD_INSTANCES_SUCCESS',
  LOAD_INSTANCE_DETAIL_SUCCESS: 'LOAD_INSTANCE_DETAIL_SUCCESS',
  CHANGE_INSTANCE_CONFIGURATOR_FORM: 'CHANGE_INSTANCE_CONFIGURATOR_FORM',
  RESET_INSTANCE_CONFIGURATOR_FORM: 'RESET_INSTANCE_CONFIGURATOR_FORM',
};

export const defaults = { REPOSITORY: 'keboola', BRANCH: 'default', TEMPLATE_VERSION: 'default' };
