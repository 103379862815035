import React from 'react';
import createReactClass from 'create-react-class';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import routesStore from '@/stores/RoutesStore';
import CredentialsForm from './CredentialsForm';

const CredentialsPage = function (componentId, actionsProvisioning, storeProvisioning) {
  const actionCreators = actionsProvisioning.createActions(componentId);
  return createReactClass({
    mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore)],

    getStateFromStores() {
      const config = routesStore.getCurrentRouteParam('config');
      const dbStore = storeProvisioning.createStore(componentId, config);
      const editingCredentials = dbStore.getEditingCredentials();
      const isEditing = dbStore.isEditingCredentials();
      const credentials = dbStore.getCredentials();

      return {
        configId: config,
        credentials: credentials,
        isEditing: isEditing,
        editingCredentials: editingCredentials,
        isSaving: dbStore.isSavingCredentials(),
        isChangedCredentials: dbStore.isChangedCredentials(),
        isValidCredentials: isEditing
          ? dbStore.hasValidCredentials(editingCredentials)
          : dbStore.hasValidCredentials(credentials),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    render() {
      return (
        <div className="box">
          <div className="box-content">
            {!this.state.readOnly && (
              <div className="save-buttons">
                <SaveButtons
                  isSaving={this.state.isSaving}
                  isChanged={this.state.isChangedCredentials}
                  disabled={this.state.isSaving || !this.state.isValidCredentials}
                  onReset={this.handleReset}
                  onSave={this.handleSave}
                />
              </div>
            )}
            <CredentialsForm
              readOnly={this.state.readOnly}
              isValidEditingCredentials={this.state.isValidCredentials}
              credentials={
                this.state.isEditing ? this.state.editingCredentials : this.state.credentials
              }
              savedCredentials={this.state.credentials}
              isDisabled={this.state.isSaving}
              isEditing={this.state.isEditing}
              onChange={this.handleChange}
              configId={this.state.configId}
              actionCreators={actionCreators}
            />
          </div>
        </div>
      );
    },

    handleChange(newCredentials) {
      actionCreators.updateEditingCredentials(this.state.configId, newCredentials);
    },

    handleReset() {
      actionCreators.cancelCredentialsEdit(this.state.configId);
    },

    handleSave() {
      actionCreators.saveCredentialsEdit(this.state.configId);
    },
  });
};

export default CredentialsPage;
