import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { Badge, cn, Tooltip } from 'design';
import { type Map } from 'immutable';

import keyCodes from '@/constants/keyCodes';
import { addFileTag, deleteFileTag } from '@/modules/storage/actions';

type Props = {
  file: Map<string, any>;
  readOnly: boolean;
  onSearchQuery: (query: string) => void;
};

const FileTags = ({ file, readOnly, onSearchQuery }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTagName, setNewTagName] = useState('');

  useEffect(() => {
    if (!isEditing) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === keyCodes.ESCAPE) {
        handleCancel();
      }

      if (event.key === keyCodes.ENTER) {
        handleSave();
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleCancel = () => {
    setIsEditing(false);
    setNewTagName('');
  };

  const handleSave = () => {
    if (newTagName.length === 0) {
      return;
    }

    const tags = newTagName.split(',') || [];

    return Promise.all(tags.map((tag) => addFileTag(file.get('id'), tag))).finally(() => {
      handleCancel();
    });
  };

  const handleDelete = (tag: string) => {
    return deleteFileTag(file.get('id'), tag);
  };

  const tags = file.get('tags');

  const renderAddTag = () => {
    if (readOnly) {
      return null;
    }

    if (isEditing) {
      return (
        <div className="tw-relative tw-h-5">
          <input
            type="text"
            value={newTagName}
            onChange={(event) => {
              setNewTagName(event.target.value.trim());
            }}
            onBlur={() => {
              if (newTagName.length === 0) {
                handleCancel();
              }
            }}
            placeholder="Add Tag"
            className={cn(
              'tw-relative -tw-top-px tw-inline-flex tw-h-full tw-w-auto tw-min-w-16 tw-max-w-40 tw-rounded-sm tw-border tw-border-solid tw-border-neutral-200 tw-bg-neutral-100 tw-px-2 tw-py-0 tw-text-xs tw-font-medium tw-text-neutral-800 tw-outline-none',
              'placeholder:tw-text-neutral-400',
              newTagName.length > 0 && 'tw-pr-6',
            )}
            autoFocus
          />
          {newTagName.length > 0 && (
            <div
              className={cn(
                'tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-flex tw-cursor-pointer tw-items-center tw-rounded-r-sm tw-px-1.5 tw-py-1 tw-text-xs tw-text-neutral-400 tw-transition-colors',
                'hover:tw-text-secondary-600',
              )}
              onClick={() => {
                handleSave();
              }}
            >
              <FontAwesomeIcon icon="check" />
            </div>
          )}
        </div>
      );
    }

    return (
      <>
        {tags.count() === 0 ? (
          <div
            className="tw-h-5 tw-cursor-pointer tw-pt-px tw-text-xs tw-font-medium tw-text-neutral-500 tw-underline hover:tw-no-underline"
            onClick={() => setIsEditing(true)}
          >
            Add Tag
          </div>
        ) : (
          <Tooltip placement="top" tooltip="Add new tag">
            <Button
              bsStyle="default"
              className="tw-h-5 tw-w-5 !tw-p-0"
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon="plus" className="!tw-text-sm" />
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div className="tw-mt-1 tw-flex tw-flex-wrap tw-items-center tw-gap-2">
      {tags.map((tag: string, index: number) => (
        <Badge
          key={index}
          asTag
          variant="blue"
          className={cn(
            'tw-relative tw-inline tw-justify-start tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-rounded-sm',
            'tw-m-0 tw-h-5 tw-max-w-48 tw-py-0.5 tw-pl-2 tw-pr-[22px] min-[1692px]:tw-max-w-56',
          )}
          onClick={() => onSearchQuery(`tags:"${tag}"`)}
        >
          {tag}
          <div
            className={cn(
              'tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-flex tw-h-full tw-w-5 tw-items-center tw-justify-center tw-rounded-br tw-rounded-tr tw-text-sm',
              'hover:tw-bg-secondary-700',
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(tag);
            }}
          >
            <FontAwesomeIcon icon="close" />
          </div>
        </Badge>
      ))}

      {renderAddTag()}
    </div>
  );
};

export default FileTags;
