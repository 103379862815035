import { fromJS, Iterable, Map } from 'immutable';

import Dispatcher from '@/Dispatcher';
import { ActionTypes } from '@/modules/orchestrations/Constants';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'OrchestrationJobsStore',
  Map({
    jobsByOrchestrationId: Map(),
    editing: Map(), // [jobId][tasks] - edit value
    loadingOrchestrationJobs: Map(),
    loadingJobs: Map(),
    terminatingJobs: Map(),
    retryingJobs: Map(),
  }),
);

const OrchestrationJobsStore = StoreUtils.createStore({
  getOrchestrationJobs(idOrchestration) {
    return _store
      .getIn(['jobsByOrchestrationId', idOrchestration], Map())
      .sortBy((job) => -1 * job.get('id'));
  },

  hasOrchestrationJobs(idOrchestration) {
    return _store.hasIn(['jobsByOrchestrationId', idOrchestration]);
  },

  getJob(id) {
    const orchestrationJobs = _store.get('jobsByOrchestrationId').find((jobs) => jobs.has(id));
    return orchestrationJobs ? orchestrationJobs.get(id) : null;
  },

  getIsJobTerminating(id) {
    return _store.hasIn(['terminatingJobs', id]);
  },

  getIsJobRetrying(id) {
    return _store.hasIn(['retryingJobs', id]);
  },

  isJobLoading(id) {
    return _store.hasIn(['loadingJobs', id]);
  },

  isLoading(idOrchestration) {
    return _store.hasIn(['loadingOrchestrationJobs', idOrchestration]);
  },

  getEditingValue(jobId, field) {
    return _store.getIn(['editing', jobId, field]);
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.ORCHESTRATION_JOBS_LOAD:
      _store = _store.setIn(['loadingOrchestrationJobs', action.orchestrationId], true);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOBS_LOAD_ERROR:
      _store = _store.deleteIn(['loadingOrchestrationJobs', action.orchestrationId]);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOBS_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        store.deleteIn(['loadingOrchestrationJobs', action.orchestrationId]);
        store.mergeIn(
          ['jobsByOrchestrationId', action.orchestrationId],
          fromJS(action.jobs)
            .toMap()
            .mapKeys((key, job) => job.get('id')),
        );
      });
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_LOAD:
      _store = _store.setIn(['loadingJobs', action.jobId], true);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        store.deleteIn(['loadingJobs', action.job.id]);
        store.setIn(
          ['jobsByOrchestrationId', action.job.orchestrationId, action.job.id],
          fromJS(action.job),
        );
      });
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_TERMINATE_START:
      _store = _store.setIn(['terminatingJobs', action.jobId], true);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_TERMINATE_ERROR:
    case ActionTypes.ORCHESTRATION_JOB_TERMINATE_SUCCESS:
      _store = _store.deleteIn(['terminatingJobs', action.jobId]);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_RETRY_EDIT_START: {
      const tasks = OrchestrationJobsStore.getJob(action.jobId)
        .get('tasks')
        .map((task) => {
          return task.update('actionParameters', (parameters) => {
            return Iterable.isIterable(parameters) ? parameters : fromJS(JSON.parse(parameters));
          });
        });
      _store = _store.setIn(['editing', action.jobId, 'tasks'], tasks);
      return OrchestrationJobsStore.emitChange();
    }

    case ActionTypes.ORCHESTRATION_JOB_RETRY_EDIT_UPDATE:
      _store = _store.setIn(['editing', action.jobId, 'tasks'], action.tasks);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_RETRY_START:
      _store = _store.setIn(['retryingJobs', action.jobId], true);
      return OrchestrationJobsStore.emitChange();

    case ActionTypes.ORCHESTRATION_JOB_RETRY_SUCCESS:
    case ActionTypes.ORCHESTRATION_JOB_RETRY_ERROR:
      _store = _store.deleteIn(['retryingJobs', action.jobId]);
      return OrchestrationJobsStore.emitChange();

    default:
  }
});

export default OrchestrationJobsStore;
