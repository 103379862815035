import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Confirm from '@/react/common/Confirm';

class MigrateToRowsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
    };

    this.onMigrate = this.onMigrate.bind(this);
  }

  render() {
    return (
      <Confirm
        closeAfterResolve
        icon="circle-check"
        title="Migrating Configuration"
        text={
          <>
            <p>You are about to run migration to configuration rows.</p>
            <b>Do not close modal until migration completes.</b>
          </>
        }
        buttonType="success"
        buttonLabel="Migrate Configuration"
        isLoading={this.state.isPending}
        onConfirm={this.onMigrate}
      >
        <Button bsStyle="success" disabled={this.state.isPending}>
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
          Migrate Configuration
        </Button>
      </Confirm>
    );
  }

  onMigrate() {
    this.setState({ isPending: true });
    return this.props.onMigrate().finally(() => {
      this.setState({ isPending: false });
    });
  }
}

MigrateToRowsButton.propTypes = {
  onMigrate: PropTypes.func.isRequired,
};

export default MigrateToRowsButton;
