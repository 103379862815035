import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Alert, Link } from 'design';

import ConflictList from './ConflictList';
import { getConflictsForTransformation } from './detect';

const Warning = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    transformations: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
  },

  render() {
    const conflicts = getConflictsForTransformation(
      this.props.transformation,
      this.props.transformations,
    );
    if (conflicts.count() === 0) {
      return null;
    }
    return (
      <Alert variant="warning" className="tw-mb-5">
        <h2>Output Warning</h2>
        <p>
          This transformation shares its outputs with other transformations in the same phase. The
          execution order of outputs in a single phase is not guaranteed and may change. Please
          adjust the outputs to avoid data loss or confusion.
        </p>
        <ConflictList
          conflicts={conflicts}
          transformations={this.props.transformations}
          bucketId={this.props.bucketId}
        />
        <p>
          Read more about speeding up the output by using{' '}
          <Link href="https://status.keboola.com/speeding-up-transformation-outputs-in-your-projects">
            parallel unloads
          </Link>
          .
        </p>
      </Alert>
    );
  },
});

export default Warning;
