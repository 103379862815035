import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Clipboard, Tooltip } from 'design';

import { transformationType } from '@/modules/legacy-transformation/Constants';
import TransformationType from '@/modules/legacy-transformation/react/components/TransformationType';
import normalizeNewlines from '@/modules/legacy-transformation/utils/normalizeNewlines';
import CodeEditor from '@/react/common/CodeEditor';
import FullScreenEditor from '@/react/common/FullScreenEditor';
import SaveButton from '@/react/common/SaveButtonWithDescription';
import resolveHighlightMode from './resolveHighlightMode';

const Scripts = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    scripts: PropTypes.string.isRequired,
    isEditingValid: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onEditChange: PropTypes.func.isRequired,
    onDescriptionChange: PropTypes.func.isRequired,
    changeDescription: PropTypes.string.isRequired,
    onEditSubmit: PropTypes.func.isRequired,
    isChanged: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  getInitialState() {
    return {
      showFullScreenEditor: false,
    };
  },

  render() {
    if (this.state.showFullScreenEditor) {
      return (
        <FullScreenEditor
          className="CodeMirror-search-dialog-root"
          renderTitle={this.renderFullScreenTitle}
          renderEditor={this.renderEditor}
          renderButtons={this.renderSaveButton}
          onClose={() => {
            this.setState({ showFullScreenEditor: false });
          }}
        />
      );
    }

    return (
      <div className="box CodeMirror-search-dialog-root">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">
            Scripts <Clipboard text={this.props.scripts} />
          </h2>
          {this.renderSaveButton()}
        </div>
        <div className="box-content editor">
          {this.renderFullScreenButton()}
          {this.renderEditor()}
        </div>
      </div>
    );
  },

  renderSaveButton() {
    return (
      <div className="flex-container CodeMirror-search-dialog-wrapper">
        <SaveButton
          isSaving={this.props.isSaving}
          isChanged={this.props.isChanged}
          onSave={this.props.onEditSubmit}
          onReset={this.props.onEditCancel}
          disabled={!this.props.isEditingValid || this.props.disabled}
          onDescriptionChange={this.props.onDescriptionChange}
          changeDescription={this.props.changeDescription}
        />
      </div>
    );
  },

  renderFullScreenTitle() {
    return (
      <>
        <TransformationType
          showLabel={false}
          transformation={this.props.transformation}
          imageClass="icon-addon-right"
          imageSize="32"
        />
        {this.props.transformation.get('name')}
      </>
    );
  },

  renderEditor() {
    return (
      <CodeEditor
        withSearch
        value={normalizeNewlines(this.props.scripts)}
        onChange={(value) => {
          this.props.onEditChange(normalizeNewlines(value));
        }}
        options={{
          mode: resolveHighlightMode('docker', this.props.transformation.get('type')),
          readOnly: this.props.isSaving || this.props.disabled,
          placeholder: this.getPlaceholder(),
        }}
      />
    );
  },

  renderFullScreenButton() {
    return (
      <Tooltip
        placement="top"
        tooltip="Expand editor to full screen"
        triggerClassName="full-screen-button"
      >
        <Button
          bsStyle="link"
          onClick={() => {
            this.setState({ showFullScreenEditor: true });
          }}
        >
          <FontAwesomeIcon icon="up-right-and-down-left-from-center" />
        </Button>
      </Tooltip>
    );
  },

  getPlaceholder() {
    if (this.props.transformation.get('type') === transformationType.PYTHON) {
      return '# Your Python script goes here...';
    }

    if (this.props.transformation.get('type') === transformationType.R) {
      return '# Your R script goes here...';
    }

    return '';
  },
});

export default Scripts;
