import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { rtrim } from 'underscore.string';

import { isNewProjectValid } from '@/modules/gooddata-writer-v3/gooddataProvisioning/utils';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import NewProjectForm from './NewProjectForm';

const CreateProjectModal = createReactClass({
  propTypes: {
    config: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isLoadingModel: PropTypes.bool.isRequired,
    buckets: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      newProject: {
        login: '',
        password: '',
        pid: '',
        backendUrl: '',
        readLDM: false,
        targetBucket: '',
      },
    };
  },

  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        onEnter={() => {
          this.setState({
            newProject: {
              login: this.props.config.login,
              password: this.props.config.password,
              pid: this.props.config.pid,
              backendUrl: this.props.config.backendUrl,
              readLDM: false,
              targetBucket: '',
            },
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Up GoodData Project</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.onSubmit} horizontal>
          <Modal.Body>
            <NewProjectForm
              value={this.state.newProject}
              onChange={(val) => this.setState({ newProject: val })}
              disabled={this.isDisabled()}
              buckets={this.props.buckets}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              isSaving={this.isDisabled()}
              isDisabled={!this.isValid()}
              saveLabel="Save settings"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  isValid() {
    return isNewProjectValid(this.state.newProject);
  },

  onSubmit(event) {
    event.preventDefault();
    return this.props.onCreate({
      ...this.state.newProject,
      ...(this.state.newProject.backendUrl && {
        backendUrl: rtrim(this.state.newProject.backendUrl, '/'),
      }),
    });
  },

  isDisabled() {
    return this.props.disabled || this.props.isLoadingModel;
  },
});

export default CreateProjectModal;
