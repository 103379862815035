import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

const ACTIVE_CLASESS =
  '!tw-bg-white !tw-text-secondary-600 !tw-cursor-default !tw-border-neutral-200';
const INACTIVE_CLASSES = '!tw-bg-neutral-100 !tw-text-neutral-800 hover:!tw-bg-neutral-50';

const VisualSwitch = (props: { jsonMode: boolean; setJsonMode: (mode: boolean) => void }) => {
  return (
    <ButtonGroup className="tw-mb-3">
      <Button
        bsSize="sm"
        onClick={() => props.setJsonMode(false)}
        className={props.jsonMode ? INACTIVE_CLASSES : ACTIVE_CLASESS}
      >
        User Interface
      </Button>
      <Button
        bsSize="sm"
        onClick={() => props.setJsonMode(true)}
        className={props.jsonMode ? ACTIVE_CLASESS : INACTIVE_CLASSES}
      >
        JSON
      </Button>
    </ButtonGroup>
  );
};

export default VisualSwitch;
