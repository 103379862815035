import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { fromJS, List } from 'immutable';

import { canWriteBucket } from '@/modules/admin/privileges';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { ALIAS_SUFFIX, STAGE } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, validateTableName } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import string from '@/utils/string';
import PredefinedInput from './PredefinedInput';

const initialNewTableAlias = {
  destinationBucket: '',
  name: '',
  aliasFilter: {
    column: '',
    operator: whereOperatorConstants.EQ_VALUE,
    values: List(),
  },
  aliasColumnsAutosync: true,
};

const CreateAliasTableAlternativeModal = createReactClass({
  propTypes: {
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    sapiToken: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      newTableAlias: fromJS(initialNewTableAlias).set(
        'name',
        string.webalize(`${this.props.table.get('displayName')}${ALIAS_SUFFIX}`),
      ),
      tableColumns: this.props.table
        .get('columns')
        .map((column) => ({ label: column, value: column }))
        .toArray(),
      error: null,
      warning: null,
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Alias of Table {this.props.table.get('name')}</Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.renderError()}
            <FormGroup>
              <ControlLabel>Destination bucket</ControlLabel>
              <Select
                autoFocus
                clearable={false}
                value={this.state.newTableAlias.get('destinationBucket')}
                onChange={this.handleDestinationBucket}
                options={this.getDestintaionBucketOptions()}
              />
            </FormGroup>
            <PredefinedInput
              entity="bucketName"
              value={this.state.newTableAlias.get('name')}
              warning={this.state.warning}
              onChange={this.handleName}
            />
            {this.state.newTableAlias.get('destinationBucket') &&
            !!this.props.buckets.getIn([
              this.state.newTableAlias.get('destinationBucket'),
              'sharing',
            ]) ? (
              <Alert variant="warning" className="tw-mb-5">
                Filtering and synchronization of specific columns is not available when creating an
                alias table in a shared bucket.
              </Alert>
            ) : (
              <>
                <FormGroup>
                  <ControlLabel>
                    Filtering <OptionalFormLabel />
                    <InfoTooltip
                      tooltip={
                        <>
                          You can specify a column to filter by, and comma-separated values
                          you&apos;re looking for. The alias table will contain only the matching
                          rows.
                        </>
                      }
                    />
                  </ControlLabel>
                  <div className="select-group">
                    <Select
                      clearable
                      placeholder="Column"
                      value={this.state.newTableAlias.getIn(['aliasFilter', 'column'])}
                      onChange={this.handleAliasFilterColumn}
                      options={this.state.tableColumns}
                    />
                    <Select
                      searchable={false}
                      clearable={false}
                      value={this.state.newTableAlias.getIn(['aliasFilter', 'operator'])}
                      onChange={this.handleAliasFilterOperator}
                      options={[
                        {
                          label: whereOperatorConstants.EQ_LABEL,
                          value: whereOperatorConstants.EQ_VALUE,
                        },
                        {
                          label: whereOperatorConstants.NOT_EQ_LABEL,
                          value: whereOperatorConstants.NOT_EQ_VALUE,
                        },
                      ]}
                    />
                  </div>
                  <Select
                    multi
                    allowCreate
                    emptyStrings
                    placeholder="Add a value"
                    value={this.state.newTableAlias.getIn(['aliasFilter', 'values'])}
                    disabled={this.state.tableColumns.length === 0}
                    onChange={this.handleAliasFilterValues}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Columns
                    <InfoTooltip
                      tooltip={
                        <>
                          <p>By default, columns are synchronized with the source table.</p>
                          <p>
                            You can disable this behavior and select only particular columns to be
                            included in the alias table.
                          </p>
                        </>
                      }
                    />
                  </ControlLabel>
                  <Checkbox
                    checked={this.state.newTableAlias.get('aliasColumnsAutosync')}
                    onChange={this.toggleSyncColumns}
                  >
                    Synchronize columns with the source table
                  </Checkbox>
                </FormGroup>
                {!this.state.newTableAlias.get('aliasColumnsAutosync') && (
                  <FormGroup>
                    <Select
                      multi
                      allowCreate
                      placeholder="Select alias table columns..."
                      value={this.state.newTableAlias.get('aliasColumns', List())}
                      onChange={this.handleAliasTableColumns}
                      options={this.state.tableColumns}
                    />
                  </FormGroup>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.props.isSaving}
              isDisabled={this.isDisabled()}
              saveLabel={this.props.isSaving ? 'Creating alias table...' : 'Create alias table'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        {this.state.error}
      </Alert>
    );
  },

  handleDestinationBucket(bucket) {
    this.setState(
      { newTableAlias: this.state.newTableAlias.set('destinationBucket', bucket) },
      this.validateName,
    );
  },

  handleAliasFilterOperator(value) {
    this.setState({
      newTableAlias: this.state.newTableAlias.setIn(['aliasFilter', 'operator'], value),
    });
  },

  handleAliasFilterColumn(option) {
    let newTableAlias = this.state.newTableAlias;

    if (option) {
      newTableAlias = newTableAlias.setIn(['aliasFilter', 'column'], option);
    } else {
      newTableAlias = newTableAlias.deleteIn(['aliasFilter', 'column']);
    }

    this.setState({ newTableAlias });
  },

  handleAliasFilterValues(value) {
    this.setState({
      newTableAlias: this.state.newTableAlias.setIn(['aliasFilter', 'values'], value),
    });
  },

  handleName(name) {
    this.setState({ newTableAlias: this.state.newTableAlias.set('name', name) }, this.validateName);
  },

  handleAliasTableColumns(columns) {
    this.setState({ newTableAlias: this.state.newTableAlias.set('aliasColumns', columns) });
  },

  getDestintaionBucketOptions() {
    return filterProductionBuckets(this.props.buckets)
      .filter((bucket) => {
        return (
          canWriteBucket(this.props.sapiToken, bucket) &&
          Object.values(STAGE).includes(bucket.get('stage'))
        );
      })
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      }))
      .toArray();
  },

  toggleSyncColumns() {
    this.setState({
      newTableAlias: this.state.newTableAlias
        .update('aliasColumnsAutosync', (aliasColumnsAutosync) => !aliasColumnsAutosync)
        .set('aliasColumns', List()),
    });
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();

    const bucketId = this.state.newTableAlias.get('destinationBucket');
    const tableAlias = this.state.newTableAlias
      .update((tableAlias) => {
        if (!tableAlias.getIn(['aliasFilter', 'column'])) {
          return tableAlias.delete('aliasFilter');
        }
        return tableAlias;
      })
      .set('sourceTable', this.props.table.get('id'))
      .delete('destinationBucket')
      .toJS();

    this.setState({ error: null });
    this.props.onSubmit(bucketId, tableAlias).then(this.onHide, (message) => {
      this.setState({ error: message });
    });
  },

  resetState() {
    this.setState({
      newTableAlias: fromJS(initialNewTableAlias),
      error: null,
      warning: null,
    });
  },

  validateName() {
    this.setState({
      warning: validateTableName(
        this.state.newTableAlias.get('name'),
        this.props.tables.filter((table) => {
          if (this.state.newTableAlias.get('destinationBucket')) {
            return (
              table.getIn(['bucket', 'id']) === this.state.newTableAlias.get('destinationBucket')
            );
          }
          return true;
        }),
      ),
    });
  },

  isDisabled() {
    const tableAlias = this.state.newTableAlias;
    const aliasFilter = tableAlias.get('aliasFilter');

    if (
      !tableAlias.get('name') ||
      !isValidName(tableAlias.get('name')) ||
      !tableAlias.get('destinationBucket')
    ) {
      return true;
    }

    if (
      aliasFilter.get('column') &&
      (!aliasFilter.get('operator') || !aliasFilter.get('values').count())
    ) {
      return true;
    }

    if (
      !tableAlias.get('aliasColumnsAutosync', true) &&
      tableAlias.get('aliasColumns', List()).isEmpty()
    ) {
      return true;
    }

    return this.props.isSaving;
  },
});

export default CreateAliasTableAlternativeModal;
