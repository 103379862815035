import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Collapse } from 'design';
import { List, type Map } from 'immutable';

import { isMergeRequestCreated } from '@/modules/dev-branches/helpers';
import CollapseButton from '@/react/common/CollapseButton';
import CreatedDate from '@/react/common/CreatedDate';
import Markdown from '@/react/common/Markdown';

const MergeRequestInfoPanel = (props: { mergeRequest: Map<string, any> }) => {
  const [expand, setExpand] = React.useState(true);

  if (!isMergeRequestCreated(props.mergeRequest)) {
    return null;
  }

  const renderItem = (label: React.ReactNode, body: React.ReactNode) => (
    <div className="panel-info-item no-wrap">
      <span className="text-muted">{label}:</span>
      <span className="flex-container flex-start font-medium">{body}</span>
    </div>
  );

  const hasDescription = !!props.mergeRequest.get('description');

  const onToggle = () => {
    if (hasDescription) {
      setExpand(!expand);
    }
  };

  return (
    <Collapse
      open={expand}
      className="box panel-info tw-py-4 tw-pl-6 tw-pr-5"
      header={
        <div
          onClick={onToggle}
          className={classNames('flex-container', {
            'btn-collapse-area': hasDescription,
          })}
        >
          <div className="flex-container align-top line-height-24">
            {renderItem(
              'Submited by',
              <>
                <FontAwesomeIcon icon="user" className="mrp-2 text-muted" />
                {props.mergeRequest.getIn(['creator', 'name'])}
              </>,
            )}
            {renderItem(
              'Date',
              <>
                <FontAwesomeIcon icon="calendar" className="mrp-2 text-muted" />
                <CreatedDate createdTime={props.mergeRequest.get('createdAt')} />
              </>,
            )}
            {renderItem(
              'Approved by',
              props.mergeRequest.get('approvals', List()).isEmpty() ? (
                <>
                  <FontAwesomeIcon icon={['far', 'empty-set']} className="mrp-2 text-muted" />
                  No one
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon="check-circle" className="mrp-2 color-success" />
                  {props.mergeRequest
                    .get('approvals')
                    .map((approve: Map<string, any>) => approve.get('approverName'))
                    .join(', ')}
                </>
              ),
            )}
          </div>
          {hasDescription && (
            <CollapseButton entity="Description" isCollapsed={!expand} onToggle={onToggle} />
          )}
        </div>
      }
    >
      {hasDescription && (
        <div className="panel-info-content text-muted tw-pt-4">
          <Markdown source={props.mergeRequest.get('description', '')} collapsible={false} />
        </div>
      )}
    </Collapse>
  );
};

export default MergeRequestInfoPanel;
