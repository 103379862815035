import { KEBOOLA_EX_DB_MSSQL, KEBOOLA_EX_DB_MSSQL_CDATA } from '@/constants/componentIds';

const noLockField = {
  label: 'WITH NO LOCK',
  name: 'nolock',
  type: 'checkbox',
  protected: false,
  required: false,
  help: 'Use WITH(NOLOCK) for query execution',
  showInAdvancedMode: false,
};

const disableFallbackField = {
  label: 'Disable Fallback',
  name: 'disableFallback',
  type: 'checkbox',
  protected: false,
  required: false,
  help: 'Disable the PDO fallback method on error',
  showInAdvancedMode: true,
};

const CUSTOM_FIELDS = {
  [KEBOOLA_EX_DB_MSSQL]: [noLockField, disableFallbackField],
  [KEBOOLA_EX_DB_MSSQL_CDATA]: [noLockField, disableFallbackField],
};

const getCustomFieldsForComponent = (componentId) => {
  if (!CUSTOM_FIELDS[componentId]) {
    return [];
  }
  return CUSTOM_FIELDS[componentId];
};

export { getCustomFieldsForComponent };
