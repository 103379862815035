import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DescriptionControl from './DescriptionControl';

class UpdateDevBranchDescriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      description: '',
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ description: this.props.branch.get('description') })}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Development Branch</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <DescriptionControl
              autoFocus
              value={this.state.description}
              onChange={(description) => this.setState({ description })}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isUpdating ? 'Updating description...' : 'Update description'}
              isSaving={this.state.isUpdating}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isUpdating: true });
    this.props
      .onSubmit(this.props.branch.get('id'), { description: this.state.description })
      .then(this.props.onHide)
      .finally(() => this.setState({ isUpdating: false }));
  };
}

UpdateDevBranchDescriptionModal.propTypes = {
  branch: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateDevBranchDescriptionModal;
