import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import {
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KEBOOLA_ORACLE_TRANSFORMATION,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_ARTIFACTS, FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import HiveCredentials from '@/react/common/HiveCredentials';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import routesStore from '@/stores/RoutesStore';
import CredentialsForm from './CredentialsForm';

const CredentialsPage = function (
  componentId,
  actionsProvisioning,
  storeProvisioning,
  credentialsTemplate,
  componentType,
) {
  const actionCreators = actionsProvisioning.createActions(componentId);
  return createReactClass({
    mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, ConfigurationRowsStore)],

    getStateFromStores() {
      const config = routesStore.getCurrentRouteParam('config');
      const dbStore = storeProvisioning.createStore(componentId, config);
      const editingCredentials = dbStore.getEditingCredentials() || Map();
      const isEditing = dbStore.isEditingCredentials();
      const credentials = dbStore.getCredentials();

      return {
        configId: config,
        credentials: credentials,
        isEditing: isEditing,
        editingCredentials: editingCredentials,
        isSaving: dbStore.isSavingCredentials(),
        isChangedCredentials: dbStore.isChangedCredentials(),
        isValidCredentials: isEditing
          ? dbStore.hasValidCredentials(editingCredentials)
          : dbStore.hasValidCredentials(credentials),
        hasQueries: dbStore.getQueries().count() > 0,
        componentId: routesStore.getCurrentRouteParam('component') || componentId,
        readOnly: ApplicationStore.isReadOnly(),
        isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
        componentGlobalDbConfig: dbStore.getGlobalDbCredentials(),
        hasArtifacts: ApplicationStore.hasCurrentProjectFeature(FEATURE_ARTIFACTS),
      };
    },

    render() {
      if (
        componentType === componentTypes.TRANSFORMATION &&
        this.state.componentId !== KEBOOLA_ORACLE_TRANSFORMATION
      ) {
        return null;
      }
      return (
        <div className="box">
          <div className="box-content">
            {!this.state.readOnly && (
              <div className="save-buttons">
                <SaveButtons
                  isSaving={this.state.isSaving}
                  isChanged={this.state.isChangedCredentials}
                  disabled={this.state.isSaving || !this.state.isValidCredentials}
                  onReset={this.handleReset}
                  onSave={this.handleSave}
                />
              </div>
            )}
            {[KEBOOLA_EX_DB_HIVE, KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST].includes(
              this.state.componentId,
            ) ? (
              <HiveCredentials
                credentials={this.state.componentGlobalDbConfig.mergeDeep(
                  this.state.credentials,
                  this.state.editingCredentials,
                )}
                editingCredentials={this.state.credentials.mergeDeep(
                  this.state.isEditing ? this.state.editingCredentials : this.state.credentials,
                )}
                onChange={this.handleChange}
                componentId={this.state.componentId}
                configId={this.state.configId}
                actionCreators={actionCreators}
                readOnly={this.state.readOnly}
                hasQueries={this.state.hasQueries}
                isChanged={!this.state.credentials.equals(this.state.editingCredentials)}
                isSaving={this.state.isSaving}
                hasArtifacts={this.state.hasArtifacts}
                hasValidCredentials={() => this.state.isValidCredentials}
              />
            ) : (
              <CredentialsForm
                readOnly={this.state.readOnly}
                isValidEditingCredentials={this.state.isValidCredentials}
                credentials={
                  this.state.isEditing ? this.state.editingCredentials : this.state.credentials
                }
                savedCredentials={this.state.credentials}
                enabled={!this.state.isSaving}
                isEditing={this.state.isEditing}
                onChange={this.handleChange}
                componentId={componentId}
                configId={this.state.configId}
                credentialsTemplate={credentialsTemplate}
                actionCreators={actionCreators}
                hasQueries={this.state.hasQueries}
                isSingleTenant={this.state.isSingleTenant}
              />
            )}
          </div>
        </div>
      );
    },

    handleChange(credentials) {
      if (credentials.hasIn(['ssl', 'key'])) {
        credentials = credentials
          .update('ssl', (ssl) => (ssl.has('#key') ? ssl : ssl.set('#key', ssl.get('key'))))
          .deleteIn(['ssl', 'key']);
      }

      actionCreators.updateEditingCredentials(this.state.configId, credentials);
    },

    handleReset() {
      actionCreators.cancelCredentialsEdit(this.state.configId);
    },

    handleSave() {
      actionCreators.saveCredentialsEdit(this.state.configId);
    },
  });
};

export default CredentialsPage;
