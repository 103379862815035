import React, { useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import { startsWith } from 'underscore.string';

import { AVAILABLE_COUNTRIES, INVALID_VAT_ERROR } from '@/modules/billing/constants';
import { isVatIdValid } from '@/modules/billing/helpers';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import isEmailValid from '@/utils/isEmailValid';
import matchByWords from '@/utils/matchByWords';

type Fields =
  | 'name'
  | 'email'
  | 'addressCountry'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressCity'
  | 'addressPostalCode'
  | 'vatId';
type FieldsState = Map<Fields, string>;
type Props = {
  state: FieldsState;
  setState: (state: FieldsState, error?: string | null) => void;
  isInModal?: boolean;
};

const BillingEditFields = (props: Props) => {
  const [countrySelectQuery, setCountrySelectQuery] = useState('');

  return (
    <>
      <FormGroup>
        <ControlLabel>Billing Email</ControlLabel>
        <FormControl
          type="email"
          placeholder="Billing Email Adress"
          value={props.state.get('email', '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setState(
              props.state.set('email', event.target.value),
              isEmailValid(event.target.value) ? null : 'Provided email address is not valid.',
            )
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          Company Name <OptionalFormLabel block />
        </ControlLabel>
        <FormControl
          type="text"
          placeholder="Company Name"
          value={props.state.get('name', '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setState(props.state.set('name', event.target.value))
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Country</ControlLabel>
        <Select
          clearable={false}
          placeholder="Select Country"
          inputValue={countrySelectQuery}
          onInputChange={setCountrySelectQuery}
          options={Object.entries(AVAILABLE_COUNTRIES)
            .map(([value, label]) => ({
              label,
              value,
            }))
            .sort((option) =>
              countrySelectQuery &&
              startsWith(option.label.toLowerCase(), countrySelectQuery.toLowerCase())
                ? -1
                : 1,
            )}
          value={props.state.get('addressCountry', '')}
          onChange={(selected: string) =>
            props.setState(
              props.state.set('addressCountry', selected),
              isVatIdValid(props.state.get('vatId', ''), selected) ? null : INVALID_VAT_ERROR,
            )
          }
          filterOption={(data, query) => matchByWords(data.label, query)}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Address Line 1</ControlLabel>
        <FormControl
          type="text"
          placeholder="Address Line 1"
          value={props.state.get('addressLine1', '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setState(props.state.set('addressLine1', event.target.value))
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          Address Line 2 <OptionalFormLabel block />
        </ControlLabel>
        <FormControl
          type="text"
          placeholder="Address Line 2"
          value={props.state.get('addressLine2', '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setState(props.state.set('addressLine2', event.target.value))
          }
        />
      </FormGroup>
      {props.isInModal ? (
        <div className="flex-container gap-16">
          <FormGroup className="fill-space">
            <ControlLabel>City</ControlLabel>
            <FormControl
              type="text"
              placeholder="City"
              value={props.state.get('addressCity', '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setState(props.state.set('addressCity', event.target.value))
              }
            />
          </FormGroup>
          <FormGroup className="fill-space">
            <ControlLabel>Postal Code</ControlLabel>
            <FormControl
              type="text"
              placeholder="000 00"
              value={props.state.get('addressPostalCode', '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setState(props.state.set('addressPostalCode', event.target.value))
              }
            />
          </FormGroup>
        </div>
      ) : (
        <FormGroup className="two-inputs-form-group">
          <ControlLabel>City</ControlLabel>
          <FormControl
            type="text"
            placeholder="City"
            value={props.state.get('addressCity', '')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setState(props.state.set('addressCity', event.target.value))
            }
          />
          <ControlLabel>Postal Code</ControlLabel>
          <FormControl
            type="text"
            placeholder="000 00"
            value={props.state.get('addressPostalCode', '')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setState(props.state.set('addressPostalCode', event.target.value))
            }
          />
        </FormGroup>
      )}
      <FormGroup>
        <ControlLabel>
          Vat ID <OptionalFormLabel block />
        </ControlLabel>
        <FormControl
          type="text"
          placeholder="Vat ID"
          value={props.state.get('vatId', '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setState(
              props.state.set('vatId', event.target.value),
              isVatIdValid(event.target.value, props.state.get('addressCountry', ''))
                ? null
                : INVALID_VAT_ERROR,
            )
          }
        />
      </FormGroup>
    </>
  );
};

export default BillingEditFields;
