import React, { useState } from 'react';
import type { Map } from 'immutable';

import keyCodes from '@/constants/keyCodes';
import ComponentBoxModal from '@/modules/components-directory/components/ComponentBoxModal';
import ComponentIcon from '@/react/common/ComponentIcon';
import Truncated from '@/react/common/Truncated';

const ComponentBox = ({ component }: { component: Map<string, any> }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        tabIndex={0}
        onClick={() => setShowModal(true)}
        onKeyDown={(e) => {
          if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.key)) {
            e.stopPropagation();
            setShowModal(true);
          }
        }}
        className="component-box flex-container flex-start align-top ptp-3 prp-6 pbp-3 plp-4 clickable"
      >
        <ComponentIcon component={component} size="48" className="mr-1 mt-1 mb-1" />
        <div>
          <h3 className="mt-0 line-height-22">
            <Truncated text={component.get('name')} />
          </h3>
          <Truncated
            text={component.get('description')}
            className="text-muted line-height-22"
            twoLines
          />
        </div>
      </div>
      <ComponentBoxModal
        readOnly
        show={showModal}
        showButtons={false}
        component={component}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default ComponentBox;
