import { fromJS, Map } from 'immutable';

export const preparePayload = (configData) => {
  return fromJS({
    configData: {
      parameters: {
        service_account: configData.getIn(['parameters', 'service_account'], Map()),
        google: {
          location: configData.getIn(['parameters', 'google', 'location'], ''),
          includeColumns: false,
        },
      },
    },
  });
};

export default {
  name: 'getTables',
  cache: true,
  autoload: true,
  getPayload: (configData) => {
    if (
      !configData.getIn(['parameters', 'google', 'location'], '') ||
      configData.getIn(['parameters', 'service_account'], Map()).isEmpty()
    ) {
      return false;
    }

    return preparePayload(configData);
  },
};
