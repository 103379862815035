import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { cn } from '../utils/classNames';

import { Collapse } from './Collapse';
import { Tooltip } from './Tooltip';

type Variant = 'info' | 'warning' | 'error' | 'success';

export type Props = {
  title?: React.ReactNode;
  variant?: Variant;
  collapsible?: boolean;
  children?: React.ReactNode;
  isOpen?: boolean;
  onToggle?: (open: boolean) => void;
  withIcon?: boolean;
  className?: string;
  onClose?: () => void;
};

const ICONS: { [K in Variant]: IconProp } = {
  info: 'circle-info',
  warning: 'triangle-exclamation',
  error: 'triangle-exclamation',
  success: 'circle-check',
};

const COLORS: { [K in Variant]: { bg: string; border: string; icon: string; hover: string } } = {
  info: {
    bg: 'tw-bg-secondary-100',
    border: 'tw-border-secondary-200',
    hover: 'group-hover/header:tw-text-secondary-600',
    icon: 'tw-text-secondary-500',
  },
  warning: {
    bg: 'tw-bg-warning-100',
    border: 'tw-border-warning-300',
    hover: 'group-hover/header:tw-text-warning-600',
    icon: 'tw-text-warning-500',
  },
  error: {
    bg: 'tw-bg-error-100',
    border: 'tw-border-error-200',
    hover: 'group-hover/header:tw-text-error-600',
    icon: 'tw-text-error-500',
  },
  success: {
    bg: 'tw-bg-primary-100',
    border: 'tw-border-primary-200',
    hover: 'group-hover/header:tw-text-primary-600',
    icon: 'tw-text-primary-500',
  },
};

export const Alert = ({
  title,
  variant = 'info',
  collapsible = false,
  children,
  isOpen = true,
  onToggle,
  withIcon = true,
  className,
  onClose,
}: Props) => {
  const isCollapsible = collapsible && !onClose;

  return (
    <Collapse
      open={isCollapsible ? isOpen : true}
      className={cn(
        'tw-flex tw-rounded tw-border tw-border-solid tw-p-4',
        COLORS[variant].bg,
        COLORS[variant].border,
        className,
      )}
      header={
        <div
          onClick={isCollapsible && onToggle ? () => onToggle(!isOpen) : () => null}
          className={classnames('tw-group/header tw-flex tw-w-full tw-justify-between', {
            'tw-cursor-pointer': isCollapsible,
          })}
        >
          <span className="tw-flex tw-w-full tw-gap-2 tw-break-anywhere">
            {withIcon && (
              <FontAwesomeIcon
                icon={ICONS[variant]}
                className={classnames(COLORS[variant].icon, 'tw-self-start tw-p-0.5 tw-text-base')}
              />
            )}
            {title || (children && typeof children === 'string') ? (
              <p
                className={classnames('tw-m-0 tw-text-left tw-text-sm tw-text-neutral-800', {
                  'tw-font-medium': !!title,
                })}
              >
                {title || children}
              </p>
            ) : (
              <div className="tw-w-full tw-text-sm tw-text-neutral-800">{children}</div>
            )}
          </span>
          {isCollapsible ? (
            <Tooltip placement="top" tooltip={isOpen ? 'Collapse alert' : 'Expand alert'}>
              <FontAwesomeIcon
                icon="chevron-down"
                className={classnames(
                  'tw-ml-4 tw-text-base tw-text-neutral-400 group-data-[state=open]:tw-rotate-180',
                  COLORS[variant].hover,
                )}
              />
            </Tooltip>
          ) : (
            !!onClose && (
              <FontAwesomeIcon
                icon="xmark"
                className="tw-ml-4 tw-cursor-pointer tw-text-base tw-text-neutral-400"
                onClick={onClose}
              />
            )
          )}
        </div>
      }
    >
      {title && children && (
        <div className="tw-ml-7 tw-mt-4 tw-text-sm tw-text-neutral-800">{children}</div>
      )}
    </Collapse>
  );
};
