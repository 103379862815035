import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import SearchBar from '@/react/common/SearchBar';
import matchByWords from '@/utils/matchByWords';
import AccountsSelector from './AccountsSelector';

class AccountsManagerModal extends React.Component {
  state = {
    query: '',
    selectedAccounts: List(),
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHideFn}
        onEnter={() => {
          this.props.loadAccounts();
          this.setState({ selectedAccounts: List() });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Account</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <SearchBar
            className="as-input"
            query={this.state.query}
            onChange={(query) => this.setState({ query })}
          />
          <AccountsSelector
            accounts={this.getFilteredAccounts()}
            savedAccounts={this.props.accounts}
            selectAccount={this.selectAccount}
            selectedAccounts={this.state.selectedAccounts}
            isLoading={!!this.props.loadedAccountsData.get('isLoading')}
            searchQuery={this.state.query}
            error={this.props.error}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Add Selected Accounts"
            onSave={this.handleSave}
            isSaving={this.props.isSaving}
            isDisabled={this.state.selectedAccounts.isEmpty()}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  getFilteredAccounts = () => {
    if (!this.state.query) {
      return this.getLoadedAccounts();
    }

    return this.getLoadedAccounts().filter((account) => {
      return matchByWords([account.get('name'), account.get('id')], this.state.query);
    });
  };

  handleSave = () => {
    const newAccounts = this.getLoadedAccounts()
      .filter((account) => this.state.selectedAccounts.includes(account.get('id')))
      .toMap()
      .mapKeys((key, account) => account.get('id'));

    this.props.onSave(this.props.accounts.merge(newAccounts)).then(this.props.onHideFn);
  };

  getLoadedAccounts = () => {
    return this.props.loadedAccountsData.get('data') || List();
  };

  selectAccount = (accounts, selected) => {
    const selectedAccounts = selected
      ? this.state.selectedAccounts.concat(accounts).toSet().toList()
      : this.state.selectedAccounts.filter((account) => !accounts.includes(account));

    this.setState({ selectedAccounts });
  };
}

AccountsManagerModal.propTypes = {
  accounts: PropTypes.instanceOf(Map).isRequired,
  loadedAccountsData: PropTypes.instanceOf(Map).isRequired,
  loadAccounts: PropTypes.func.isRequired,
  error: PropTypes.string,
  show: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onHideFn: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AccountsManagerModal;
