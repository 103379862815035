import { fromJS, List, Map } from 'immutable';

import {
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';
import fromJSOrdered from '@/utils/fromJSOrdered';

const KEY = 'job-params';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const params = transformMapToKeyValueList(job.get('params', Map()));

  return fromJS({ [KEY]: { params } });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  const value = editing.getIn([KEY, 'params']);
  const data = List.isList(value)
    ? value
    : transformMapToKeyValueList(fromJSOrdered(JSON.parse(value)));

  const params = transformKeyValueListToMap(data);

  return params.isEmpty() ? job.delete('params') : job.set('params', params);
};

export { KEY, mapToState, mapToJob };
