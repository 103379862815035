import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const PhaseRow = createReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired,
    toggleHide: PropTypes.func.isRequired,
    isPhaseHidden: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <tr style={{ background: '#f2f4f7' }} onClick={this.props.toggleHide}>
        <td colSpan="6" className="clickable text-center">
          <strong>{this.props.phase.get('id')}</strong>
        </td>
      </tr>
    );
  },
});

export default PhaseRow;
