import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import { Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';

class PostRunScripts extends React.Component {
  state = {
    isSaving: false,
    data: this.props.data,
  };

  render() {
    const hasChanges = !this.props.data.equals(this.state.data);

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Post Run Scripts</h2>
            {!this.props.readOnly && (
              <ConfirmButtons
                saveStyle="primary"
                cancelLabel="Reset"
                showCancel={hasChanges}
                isSaving={this.state.isSaving}
                isDisabled={!hasChanges || this.state.isSaving}
                onCancel={() => this.setState({ data: this.props.data })}
                onSave={this.handleSave}
              />
            )}
          </div>
          <div className="box-content">
            <FormGroup>
              <Checkbox
                checked={this.state.data.get('run', false)}
                onChange={(checked) => this.updateData('run', checked)}
                disabled={this.props.readOnly || this.state.isSaving}
              >
                Run SQL scripts in Firebolt after the component finished writing tables to Firebolt
              </Checkbox>
            </FormGroup>
            {this.state.data.get('run') && (
              <>
                <FormGroup>
                  <Checkbox
                    checked={this.state.data.get('continue_on_failure', true)}
                    onChange={(checked) => this.updateData('continue_on_failure', checked)}
                    disabled={this.props.readOnly || this.state.isSaving}
                  >
                    Continue on failure
                  </Checkbox>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>SQL Query</ControlLabel>
                  <HelpBlock>
                    Separate multiple queries by <code>;</code>. You can use wild card object names
                    of <code>{`{VIEW}`}</code>, <code>{`{BACKGROUND_TABLE}`}</code> to represent the
                    names of the objects.
                  </HelpBlock>
                  <CodeEditor
                    value={this.state.data.get('script', '')}
                    onChange={(script) => this.updateData('script', script)}
                    options={{
                      mode: 'text/x-sql',
                      readOnly: this.props.readOnly || this.state.isSaving,
                      placeholder: 'a.g. SELECT * FROM {VIEW}',
                    }}
                  />
                </FormGroup>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  updateData = (key, value) => {
    this.setState((state) => ({ data: state.data.set(key, value) }));
  };

  handleSave = () => {
    const data = Map({
      run: this.state.data.get('run', false),
      continue_on_failure: this.state.data.get('continue_on_failure', true),
      script: this.state.data.get('script', ''),
    });

    this.setState({ isSaving: true });
    return this.props.onSave(data).finally(() => {
      this.setState({ isSaving: false });
    });
  };
}

PostRunScripts.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PostRunScripts;
