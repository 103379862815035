import Promise from 'bluebird';

const detectAdblock = () => {
  return new Promise((resolve) => {
    let bait = document.createElement('img');
    bait.setAttribute(
      'class',
      'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links',
    );
    bait.setAttribute(
      'style',
      'width: 1px; height: 1px; position: absolute; left: -1000px; top: -1000px;',
    );
    bait.setAttribute(
      'src',
      'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-google-analytics-v4/64/1576678092326.png',
    );

    const report = (detected) => {
      window.document.body.removeChild(bait);
      resolve(detected);
    };

    bait.onload = () => {
      if (
        window.document.body.getAttribute('abp') !== null ||
        bait.offsetParent === null ||
        bait.offsetHeight == 0 ||
        bait.offsetLeft == 0 ||
        bait.offsetTop == 0 ||
        bait.offsetWidth == 0 ||
        bait.clientHeight == 0 ||
        bait.clientWidth == 0
      ) {
        report(true);
      } else if (typeof window.getComputedStyle !== 'undefined') {
        let baitTemp = window.getComputedStyle(bait, null);
        if (
          baitTemp &&
          (baitTemp.getPropertyValue('display') == 'none' ||
            baitTemp.getPropertyValue('visibility') == 'hidden')
        ) {
          report(true);
        }
      }
      report(false);
    };

    bait.onerror = () => report(true);

    window.document.body.appendChild(bait);
  });
};

export default detectAdblock;
