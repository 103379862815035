import Promise from 'bluebird';

import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import SharedBucketDetail from './react/SharedBucketDetail';
import SharedBucketHeaderButton from './react/SharedBucketHeaderButton';
import SharedWithYou from './react/SharedWithYou';
import { routeNames } from './constants';

const loadSharedBuckets = () => {
  if (!canLoadSharedBuckets(ApplicationStore.getSapiToken())) {
    return Promise.resolve();
  }

  return StorageActionCreators.sharedBuckets();
};

export default {
  name: routeNames.SHARED_WITH_YOU,
  title: 'Shared with you',
  subtitle: 'Data Catalog',
  defaultRouteHandler: SharedWithYou,
  requireData: [loadSharedBuckets, () => StorageActionCreators.loadBucketsAndTables()],
  poll: {
    skipFirst: true,
    interval: DEFAULT_INTERVAL,
    action: () => {
      return Promise.all([loadSharedBuckets(), StorageActionCreators.loadBucketsAndTables()]);
    },
  },
  childRoutes: [
    {
      name: routeNames.SHARED_WITH_YOU_DETAIL,
      path: ':projectId/:bucketId',
      title(routerState) {
        const sharedBucketId = routerState.getIn(['params', 'bucketId']);
        const currentProjectId = ApplicationStore.getCurrentProjectId();
        const sharedProjectId = parseInt(routerState.getIn(['params', 'projectId']), 10);
        const sharedBucket = StorageBucketsStore.getSharedBucket(
          sharedBucketId,
          currentProjectId,
          sharedProjectId,
        );
        return `Bucket ${sharedBucket.get('displayName', sharedBucketId)}`;
      },
      defaultRouteHandler: SharedBucketDetail,
      headerButtonsHandler: SharedBucketHeaderButton,
    },
  ],
};
