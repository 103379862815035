import React from 'react';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import type { List } from 'immutable';
import { Map } from 'immutable';

import type { VariableWithHash } from '@/api/routes/vaultService';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import { toggleVariable } from '@/modules/dev-branches/actions';
import { parseCredentialsId } from '@/modules/oauth-v2/OauthUtils';
import Checkbox from '@/react/common/Checkbox';
import CollapseButton from '@/react/common/CollapseButton';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import ConfigurationWithComponent from '@/react/common/ConfigurationWithComponent';
import SortIcon from '@/react/common/SortIcon';
import Truncated from '@/react/common/Truncated';
import RoutesStore from '@/stores/RoutesStore';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import string from '@/utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

type Props = {
  variables: VariableWithHash[];
  selected: List<VariableWithHash['hash']>;
  allComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allOauthCredentials: Map<string, any>;
  readOnly: boolean;
  hasFlows: boolean;
};

class OauthVariableTable extends React.Component<Props> {
  state = {
    sort: 'asc',
    isCollapsed: false,
  };

  render() {
    if (!this.props.variables.length) {
      return null;
    }

    return (
      <div className="box">
        <div
          onClick={this.toggleCollapse}
          className="flex-container collapse-above-table btn-collapse-area pb-0"
        >
          <span className="font-medium f-16 line-height-24">Created Authorizations</span>
          <CollapseButton
            entity="variables"
            isCollapsed={this.state.isCollapsed}
            onToggle={this.toggleCollapse}
          />
        </div>
        {this.state.isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={this.toggleCollapse}>
            <span className="font-bold">{this.props.variables.length} </span>
            <span className="text-muted">
              {string.pluralize(this.props.variables.length, 'OAuth Authorization')}
            </span>
          </div>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }

  renderTable() {
    const createRowAction = (linkParams: Record<string, any> | null) => {
      return (e?: React.MouseEvent | React.KeyboardEvent) => {
        if (!linkParams) return;

        if (shouldUseNewWindow(e)) {
          return windowOpen(RoutesStore.getRouter().createHref(linkParams.to, linkParams.params));
        }

        return RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
      };
    };

    return (
      <Table hover>
        <thead>
          <tr>
            {!this.props.readOnly && (
              <th className="w-52 pr-0" onClick={onClickSelectionCell}>
                <Checkbox
                  tooltip="Toggle all changes"
                  checked={this.props.variables.length === this.props.selected.count()}
                  onChange={this.toggleAll}
                  indeterminate={
                    !this.props.selected.isEmpty() &&
                    this.props.variables.length !== this.props.selected.count()
                  }
                />
              </th>
            )}
            <th className={classNames({ 'pl-0': !this.props.readOnly })}>
              <span
                className="clickable"
                title="Sort by name"
                onClick={() => this.setState({ sort: this.state.sort === 'desc' ? 'asc' : 'desc' })}
              >
                Name
                <SortIcon
                  className="icon-addon-left"
                  isSorted
                  isSortedDesc={this.state.sort === 'desc'}
                />
              </span>
            </th>
            <th className="w-600 text-left">Configuration & Component</th>
          </tr>
        </thead>
        <tbody>
          {this.props.variables.sort(this.handleSort).map((variable) => {
            const oauthCredentials = this.props.allOauthCredentials.get(
              parseCredentialsId(variable.value),
              Map(),
            );
            const name = oauthCredentials.get('authorizedFor');
            const email = oauthCredentials.getIn(['creator', 'description']);
            const linkParams = resolveRouterLinkParams(
              variable.attributes?.componentId,
              variable.attributes?.configId,
              null,
              this.props.hasFlows,
            );

            return (
              <tr
                key={variable.hash}
                tabIndex={0}
                role="button"
                onClick={createRowAction(linkParams)}
                onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                onKeyDown={onTableRowKeyDown(createRowAction(linkParams))}
              >
                {!this.props.readOnly && (
                  <td className="w-52 pr-0" onClick={onClickSelectionCell}>
                    <Checkbox
                      tooltip="Add/remove change from merge."
                      checked={this.props.selected.includes(variable.hash)}
                      onChange={(checked) => toggleVariable(variable, checked)}
                    />
                  </td>
                )}
                <td className={classNames({ 'pl-0': !this.props.readOnly })}>
                  <Truncated text={name || 'No Account authorized'} />
                  {name !== email && (
                    <span className="text-muted f-12 line-height-16">{email}</span>
                  )}
                </td>
                <td className="text-left">
                  <ConfigurationWithComponent
                    component={this.props.allComponents.get(
                      variable.attributes?.componentId,
                      Map(),
                    )}
                    configuration={this.props.allConfigurations.getIn(
                      [
                        variable.attributes?.componentId,
                        'configurations',
                        variable.attributes?.configId,
                      ],
                      Map(),
                    )}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  handleSort = (variableA: VariableWithHash, variableB: VariableWithHash) => {
    const sort = this.state.sort === 'asc' ? 1 : -1;

    return variableA.key.localeCompare(variableB.key) * sort;
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  toggleAll = (checked: boolean) => {
    this.props.variables.map((variable) => toggleVariable(variable, checked));
  };
}

export default OauthVariableTable;
