import SettingsStore from '@/modules/settings/SettingsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';

const createRequest = (method: 'GET' | 'POST', path: string) => {
  return request(
    method,
    `/snowflake-partner-connect/project/${ApplicationStore.getCurrentProjectId()}/${path}`,
  ).set('X-KBC-ManageApiToken', SettingsStore.getTokenString());
};

const check = () => {
  return createRequest('GET', 'check')
    .promise()
    .then((response) => response.body as { testPassed: boolean; queriesToRun: string[] });
};

const upgrade = () => {
  return createRequest('POST', 'unlock')
    .promise()
    .then(
      (response) =>
        response.body as {
          status: string;
          message: string;
        },
    );
};

export { check, upgrade };
