import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'design';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { canManageSchedule } from '@/modules/admin/privileges';
import { FLOW } from '@/modules/flows/constants';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';
import ActivateFlowSwitch from './ActivateFlowSwitch';
import RetryButton from './RetryButton';
import RunResults from './RunResults';
import ScheduleToggle from './ScheduleToggle';

const CUSTOM_CLASSES = fromJS({
  schedule: { th: 'text-right w-200', td: 'text-right text-muted w-200' },
  run_results: { th: 'text-right w-175', td: 'text-right w-175' },
});
const HIDE_COLUMN_FOR_SMALLER_SCREEN = ['schedule', 'run_results'];

const FlowConfigs = (props) => {
  const additionalColumns = React.useMemo(() => {
    return [
      {
        enableSorting: false,
        accessorKey: 'schedule',
        header: 'Schedule',
        cell: ({ row }) => {
          const { config } = row.original.data;

          if (!config) {
            return null;
          }

          return (
            <ScheduleToggle
              config={config}
              triggers={props.triggers}
              canSchedule={canManageSchedule(props.sapiToken)}
              hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
            />
          );
        },
      },
      {
        enableSorting: false,
        accessorKey: 'run_results',
        header: 'Run Results',
        cell: ({ row }) => {
          const { config } = row.original.data;

          if (!config) {
            return null;
          }

          return (
            <RunResults jobs={props.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, config.get('id')])} />
          );
        },
      },
    ];
  }, [props.latestJobs, props.triggers, props.sapiToken, props.hasProtectedDefaultBranch]);

  const renderAdditionalActions = React.useCallback(
    (config) => {
      return (
        <>
          <ActivateFlowSwitch flow={config} isDisabled={props.readOnly} />
          {props.hasFlows && (
            <RetryButton
              mode="menuitem"
              latestJob={props.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, config.get('id'), 0])}
              config={config}
              sapiToken={props.sapiToken}
              allComponents={props.allComponents}
              allInstalledComponents={props.allConfigurations}
              hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
            />
          )}
        </>
      );
    },
    [
      props.latestJobs,
      props.allComponents,
      props.allConfigurations,
      props.readOnly,
      props.hasFlows,
      props.sapiToken,
      props.hasProtectedDefaultBranch,
    ],
  );

  const renderCustomLabel = React.useCallback(
    (data) => {
      const hasConfiguration = data.config
        .getIn(['configuration', 'tasks'], List())
        .some((task) => !!task.getIn(['task', 'configId']));

      if (!props.hasFlows || hasConfiguration) {
        return null;
      }

      return (
        <Badge variant="orange-inverse" placement="right">
          <FontAwesomeIcon icon={['far', 'empty-set']} /> No configurations
        </Badge>
      );
    },
    [props.hasFlows],
  );

  return (
    <ConfigurationsTable
      hasNewQueue
      supportFolders={!!props.componentsMetadata}
      configurations={props.configurations}
      allConfigurations={props.allConfigurations}
      admins={props.admins}
      currentAdmin={props.currentAdmin}
      notifications={props.notifications}
      readOnly={props.readOnly}
      component={props.component}
      latestJobs={props.latestJobs}
      forceShowAll={props.isSearching}
      hasFlows={props.hasFlows}
      customClasses={CUSTOM_CLASSES}
      additionalColumns={additionalColumns}
      renderAdditionalActions={renderAdditionalActions}
      renderCustomLabel={renderCustomLabel}
      columnsHiddenForSmallerScreens={HIDE_COLUMN_FOR_SMALLER_SCREEN}
      componentsMetadata={props.componentsMetadata}
      availableConfigurations={props.allConfigurations.get(KEBOOLA_ORCHESTRATOR)}
      expandedFolders={props.expandedFolders}
      entity={FLOW}
    />
  );
};

FlowConfigs.propTypes = {
  admins: PropTypes.instanceOf(Map).isRequired,
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  notifications: PropTypes.instanceOf(List).isRequired,
  triggers: PropTypes.instanceOf(Map).isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  latestJobs: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  hasProtectedDefaultBranch: PropTypes.bool.isRequired,
  componentsMetadata: PropTypes.instanceOf(Map),
  readOnly: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  expandedFolders: PropTypes.object,
};

const MemoizedFlowConfigs = React.memo(FlowConfigs, shallowEqualImmutable);

export default MemoizedFlowConfigs;
