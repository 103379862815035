const routeNames = {
  ROOT: 'orchestrations-v2',
  DETAIL: 'orchestrations-v2-detail',
  TASKS: 'orchestrations-v2-tasks',
  FLOW: 'orchestrations-v2-flow',
  NOTIFICATIONS: 'orchestrations-v2-notifications',
  VERSIONS: 'orchestrations-v2-versions',
};

const orchestrationTabs = {
  ALL_RUNS: 'all-runs',
  SUCCESFUL_RUNS: 'successful-runs',
  FAILED_RUNS: 'failed-runs',
};

const scheduleInvokeType = {
  TIME: 'time',
  EVENT: 'event',
};

const orchestrationJobTypes = {
  FAILED: 'error',
  SUCCESSFUL: 'success',
};

const actionTypes = {
  TRIGGERS_LOAD: 'TRIGGERS_LOAD',
  TRIGGERS_LOAD_ERROR: 'TRIGGERS_LOAD_ERROR',
  TRIGGERS_LOAD_SUCCESS: 'TRIGGERS_LOAD_SUCCESS',
  TRIGGERS_DELETE_SUCCESS: 'TRIGGERS_DELETE_SUCCESS',
  TRIGGERS_CREATE_SUCCESS: 'TRIGGERS_CREATE_SUCCESS',
  TRIGGERS_UPDATE_SUCCESS: 'TRIGGERS_UPDATE_SUCCESS',
};

export { routeNames, orchestrationTabs, scheduleInvokeType, orchestrationJobTypes, actionTypes };
