import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import Link from '@/react/common/RouterLink';

const deleteTransformationBucketNotification = (bucket) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render: function () {
      return (
        <span>
          {'Bucket ' + bucket.get('name') + ' was moved to '}
          <Link
            to={trashRouteNames.SETTINGS_TRASH}
            onClick={this.props.onClick}
            query={{ q: bucket.get('id') }}
          >
            Trash
          </Link>
          .
        </span>
      );
    },
  });
};

export default deleteTransformationBucketNotification;
