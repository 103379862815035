import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ConfirmButtons from '@/react/common/ConfirmButtons';

class DefaultForm extends React.Component {
  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton onHide={this.props.onCancel}>
          <div className="flex-container flex-start align-top">
            <ComponentIcon component={this.props.component} />
            <div className="ml-2">
              <h2 className="mt-0">
                <ComponentName component={this.props.component} />
              </h2>
              <p className="mb-0">{this.props.component.get('description')}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              type="text"
              autoFocus
              value={this.props.configuration.get('name', '')}
              placeholder="My orchestration"
              onChange={(e) => this.handleChange('name', e)}
              disabled={this.props.isSaving}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={this.props.configuration.get('description', '')}
              onChange={(e) => this.handleChange('description', e)}
              disabled={this.props.isSaving}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveButtonClass={NEW_ENTITY_BUTTON}
            saveLabel={this.props.isSaving ? `Creating orchestration...` : `Create orchestration`}
            isSaving={this.props.isSaving}
            isDisabled={!this.props.isValid}
          />
        </Modal.Footer>
      </Form>
    );
  }

  handleChange = (propName, event) => {
    this.props.onChange(this.props.configuration.set(propName, event.target.value));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.props.isValid) {
      return;
    }

    return this.props.onSave();
  };
}

DefaultForm.propTypes = {
  component: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default DefaultForm;
