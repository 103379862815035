import React from 'react';

const externalBucketNotification = (
  action: 'created' | 'updated',
  bucket: string,
  warnings?: { message: string }[],
) => {
  return () => {
    return (
      <>
        Bucket <b>{bucket}</b> has been {action}.
        {!!warnings?.length && (
          <>
            <div className="tw-mt-3 tw-font-medium">Warnings:</div>
            <ul className="tw-mb-0">
              {warnings.map((warning, index) => (
                <li key={index}>{warning.message}</li>
              ))}
            </ul>
          </>
        )}
      </>
    );
  };
};

export default externalBucketNotification;
