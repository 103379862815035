import React from 'react';
import PropTypes from 'prop-types';

class PaginatedBoxItem extends React.Component {
  render() {
    return <div className="paginated-box-content">{this.props.children}</div>;
  }
}

PaginatedBoxItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PaginatedBoxItem;
