import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_SANDBOXES,
  ORCHESTRATOR,
  PROVISIONING,
  TRANSFORMATION,
} from '@/constants/componentIds';
import { DASHBOARD_LAST_SEEN, IS_PROJECT_DESCRIPTION_HIDDEN } from '@/constants/localStorageKeys';
import dayjs from '@/date';
import Dispatcher from '@/Dispatcher';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import * as localStorage from '@/utils/localStorage';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes, ACTIVITY_OMITTED_COMPONENTS, ACTIVITY_TYPES } from './constants';

let _store = initStore(
  'HomeStore',
  Map({
    isJobsLoaded: false,
    isActivitiesLoaded: false,
    isLatestGitEventLoaded: false,
    lastSeen: null,
    jobs: List(),
    activities: List(),
    latestGitEvent: Map(),
  }),
);

const HomeStore = StoreUtils.createStore({
  getLatestActivity({ hasNewQueue, internalWorkspaceConfigurations }) {
    const jobs = HomeStore.getJobs({ internalWorkspaceConfigurations }).map((row) => {
      return Map({
        type: ACTIVITY_TYPES.JOB,
        date: row.get('createdTime'),
        data: row,
      });
    });

    const activities = _store.get('activities').map((row) => {
      if (row.get('objectType') === 'devBranch') {
        return Map({
          type: ACTIVITY_TYPES.BRANCH,
          date: row.get('created'),
          data: row,
          count: 1,
        });
      }

      return Map({
        type: ACTIVITY_TYPES.CONFIGURATION,
        date: row.get('created'),
        data: row,
        count: 1,
      });
    });

    return jobs
      .concat(activities)
      .map((row) => {
        let componentId =
          hasNewQueue && row.get('type') === ACTIVITY_TYPES.JOB
            ? row.getIn(['data', 'component'])
            : row.getIn(
                ['data', 'params', 'component'],
                row.getIn(['data', 'params', 'configuration', 'component']),
              );

        if (
          !componentId &&
          [TRANSFORMATION, ORCHESTRATOR, PROVISIONING].includes(row.getIn(['data', 'component']))
        ) {
          componentId = row.getIn(['data', 'component']);
        }

        return row.set('component', ComponentsStore.getComponent(componentId));
      })
      .filter((row) => {
        if (row.get('type') === ACTIVITY_TYPES.BRANCH) {
          return true;
        }

        if (!row.hasIn(['component', 'id'])) {
          return false;
        }

        return !ACTIVITY_OMITTED_COMPONENTS.includes(row.getIn(['component', 'id']));
      })
      .sortBy((row) => -1 * new Date(row.get('date')).getTime());
  },

  getLatestGitEvent() {
    return _store.get('latestGitEvent') || Map();
  },

  getLastSeen() {
    return _store.get('lastSeen') ? dayjs(Date.parse(_store.get('lastSeen'))) : null;
  },

  getJobs({ internalWorkspaceConfigurations }) {
    return _store.get('jobs').filter((job) => {
      if (job.get('component') !== KEBOOLA_SANDBOXES) return true;
      return !internalWorkspaceConfigurations.has(job.get('config'));
    });
  },

  isJobsLoaded() {
    return _store.get('isJobsLoaded');
  },

  isActivitiesLoaded() {
    return _store.get('isActivitiesLoaded');
  },

  isLatestGitEventLoaded() {
    return _store.get('isLatestGitEventLoaded');
  },

  isProjectDescriptionHidden() {
    return localStorage.getItem(IS_PROJECT_DESCRIPTION_HIDDEN, false);
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.DASHBOARD_JOBS_LOAD_SUCCESS:
      _store = _store.set(
        'jobs',
        fromJS(action.jobs)
          .filter((job) => job.get('createdTime'))
          .sortBy((job) => -1 * new Date(job.get('createdTime')).getTime()),
      );
      _store = _store.set('isJobsLoaded', true);
      return HomeStore.emitChange();

    case ActionTypes.DASHBOARD_ACTIVITIES_LOAD_SUCCESS: {
      _store = _store.set('activities', fromJS(action.activities));
      _store = _store.set('isActivitiesLoaded', true);

      if (!DevBranchesStore.isDevModeActive()) {
        const lastSeen = localStorage.getItem(DASHBOARD_LAST_SEEN);
        _store = _store.set('lastSeen', lastSeen);
        localStorage.setItem(DASHBOARD_LAST_SEEN, dayjs().toString());
      }
      return HomeStore.emitChange();
    }

    case ActionTypes.DASHBOARD_LATEST_GIT_EVENT_LOAD_SUCCESS: {
      _store = _store
        .set('latestGitEvent', fromJS(action.event))
        .set('isLatestGitEventLoaded', true);

      return HomeStore.emitChange();
    }

    case ActionTypes.DASHBOARD_TOGGLE_IS_PROJECT_DESCRIPTION_HIDDEN: {
      const isHidden = HomeStore.isProjectDescriptionHidden();
      localStorage.setItem(IS_PROJECT_DESCRIPTION_HIDDEN, !isHidden);
      return HomeStore.emitChange();
    }

    default:
      break;
  }
});

export default HomeStore;
