import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { Alert, HelpBlock, Link, ProgressBar } from 'design';

import FilesDropZone from '@/react/common/FilesDropZone';

type Props = {
  disabled: boolean;
  onChange: (file: File) => void;
  isFileBig: boolean;
  isUploading: boolean;
  uploadingMessage: string;
  uploadingProgress: number;
  resultMessage: string;
  resultState: string;
  dismissResult: () => void;
  file: File;
};

const Upload = (props: Props) => {
  const onChange = (files: File[]) => {
    props.onChange(files[0]);
  };

  const uploadStatus = () => {
    if (props.resultMessage) {
      const alertVariant =
        props.resultState === 'error'
          ? 'error'
          : props.resultState === 'success'
          ? 'success'
          : 'info';

      return (
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3 tw-mb-5">
            <Alert variant={alertVariant} onClose={props.dismissResult}>
              {props.resultMessage}
            </Alert>
          </div>
        </FormGroup>
      );
    }

    if (!props.isUploading) {
      return null;
    }

    return (
      <FormGroup>
        <div className="col-xs-9 col-xs-offset-3">
          {props.uploadingMessage}
          <ProgressBar progress={props.uploadingProgress} />
        </div>
      </FormGroup>
    );
  };

  const renderManualImportInfo = () => {
    return (
      <>
        Please refer to our{' '}
        <Link
          className="color-inherit"
          href={`${URLS.DEVELOPERS_DOCUMENTATION}/integrate/storage/api/importer/`}
        >
          documentation
        </Link>{' '}
        to perform a manual import using the command line or other tools.
      </>
    );
  };

  return (
    <div className="form-horizontal">
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Select file</ControlLabel>
        </div>
        <div className="col-xs-9">
          <FilesDropZone
            onDrop={onChange}
            files={props.file}
            isDisabled={props.disabled || props.isUploading}
            helpText={
              <>
                The maximum file size is 2GB (recommended 100MB).
                <br />
                Only <span className="text-danger">.csv</span>,{' '}
                <span className="text-danger">.tsv</span> and{' '}
                <span className="text-danger">.gz</span> (gzipped CSV or TSV) files are supported.
                <br />
                The maximum transfer time is 45 minutes.
              </>
            }
          />
          {props.isFileBig && (
            <HelpBlock variant="danger">
              The CSV file is larger than 100MB. Your upload may not be successful.{' '}
              {renderManualImportInfo()}
            </HelpBlock>
          )}
        </div>
      </FormGroup>
      {uploadStatus()}
    </div>
  );
};

export default Upload;
