import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import NewOrchestrationButton from './components/NewOrchestrationButton';

const IndexHeader = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, ComponentsStore)],

  getStateFromStores() {
    return {
      component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return <NewOrchestrationButton component={this.state.component} />;
  },
});

export default IndexHeader;
