import { EX_GENERIC_V_2 as componentId } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import { MENUS } from './constants';
import Detail from './Detail';
import Header from './Header';

const routes = {
  name: componentId,
  path: `${componentId}/:config(/menu/:menu)`,
  defaultRouteHandler: Detail,
  headerButtonsHandler: Header,
  requireData: [
    (params: { config: string; menu?: string }) => {
      if (
        params.menu &&
        params.menu !== MENUS.BASE &&
        InstalledComponentsStore.getIsConfigDataLoaded(componentId, params.config)
      ) {
        return;
      }

      return configRequiredData(componentId, params.config);
    },
  ],
  poll: configPoll(componentId, null, { skipJobs: true }),
};

export default routes;
