import { List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_SHARED_CODE, TRANSFORMATION } from '@/constants/componentIds';
import { getBlocksAsString } from '@/modules/components/react/components/generic/code-blocks/helpers';
import { prepareCodeString } from '@/modules/shared-codes/helpers';

const getBlocks = (config) => config.getIn(['configuration', 'parameters', 'blocks'], List());

const haveConfigurationQueriesChanged = (componentId, original, changed) => {
  if (componentId === TRANSFORMATION) {
    return !original
      .getIn(['configuration', 'queries'], List())
      .equals(changed.getIn(['configuration', 'queries'], List()));
  }

  if (componentId === KEBOOLA_SHARED_CODE) {
    return (
      prepareCodeString(original.getIn(['configuration', 'code_content'])) !==
      prepareCodeString(changed.getIn(['configuration', 'code_content']))
    );
  }

  return (
    getBlocksAsString(componentId, getBlocks(original), List()) !==
    getBlocksAsString(componentId, getBlocks(changed), List())
  );
};

const haveConfigurationsOnlyQueriesChanged = (componentId, original, changed) => {
  const queriesPath =
    componentId === TRANSFORMATION
      ? ['configuration', 'queries']
      : componentId === KEBOOLA_SHARED_CODE
      ? ['configuration', 'code_content']
      : ['configuration', 'parameters', 'blocks'];

  return _.isEqual(
    prepareDiffObject(original.deleteIn(queriesPath)),
    prepareDiffObject(changed.deleteIn(queriesPath)),
  );
};

const prepareDiffObject = (versionObj) => {
  if (!versionObj || versionObj.isEmpty()) {
    return '';
  }

  let result = Map();
  for (let prop of ['configuration', 'description', 'name', 'rowsSortOrder', 'isDisabled']) {
    if (versionObj.has(prop)) {
      result = result.set(prop, versionObj.get(prop));
    }
  }

  if (versionObj.has('rows')) {
    let rows = List();
    versionObj.get('rows').forEach((version) => {
      rows = rows.push(prepareDiffObject(version));
    });
    result = result.set('rows', rows);
  }
  return result.toJSON();
};

const prepareQueriesDiffData = (componentId, versionObj, sharedCodes) => {
  if (versionObj.hasIn(['configuration', 'parameters', 'blocks'])) {
    return getBlocksAsString(componentId, getBlocks(versionObj), sharedCodes).trim() + '\n';
  }

  if (versionObj.hasIn(['configuration', 'code_content'])) {
    return prepareCodeString(versionObj.getIn(['configuration', 'code_content'])).trim() + '\n';
  }

  if (!List.isList(versionObj.getIn(['configuration', 'queries']))) {
    return '';
  }

  return versionObj.getIn(['configuration', 'queries']).join('\n').trim() + '\n';
};

export {
  haveConfigurationQueriesChanged,
  haveConfigurationsOnlyQueriesChanged,
  prepareDiffObject,
  prepareQueriesDiffData,
};
