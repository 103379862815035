import { KEBOOLA_EX_EMAIL_ATTACHMENTS as componentId } from '@/constants/componentIds';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import Index from './react/Index';

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: Index,
  requireData: [(params) => configRequiredData(componentId, params.config)],
  poll: configPoll(componentId),
};
