import ApplicationStore from '@/stores/ApplicationStore';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import { loadAll, loadAllAndInitialize } from './actions';
import { routeNames } from './constants';
import DevBranchTitle from './DevBranchTitle';
import HeaderButton from './HeaderButton';
import MergeIndex from './MergeIndex';

export default {
  name: routeNames.DEVELOPMENT_OVERVIEW,
  titleHandler: DevBranchTitle,
  defaultRouteHandler: MergeIndex,
  headerButtonsHandler: HeaderButton,
  requireData: [loadAllAndInitialize],
  ...(ApplicationStore.hasProtectedDefaultBranch() && {
    poll: {
      skipFirst: true,
      interval: DEFAULT_INTERVAL,
      action: loadAll,
    },
  }),
};
