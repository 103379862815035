import { KEBOOLA_EX_SAMPLE_DATA, PROVISIONING, TRANSFORMATION } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';

const getComponentByJob = (job) => {
  let componentId = getJobComponentId(job);

  if (componentId === KEBOOLA_EX_SAMPLE_DATA) {
    const configId = job.getIn(['params', 'config']);
    const configData = InstalledComponentsStore.getConfigData(KEBOOLA_EX_SAMPLE_DATA, configId);
    componentId = configData.getIn(['parameters', 'componentId'], KEBOOLA_EX_SAMPLE_DATA);
  }

  return ComponentsStore.getComponent(componentId) || ComponentsStore.unknownComponent(componentId);
};

const getJobComponentId = (job) => {
  return job.getIn(['params', 'component'], job.get('component'));
};

const getConfigurationName = (job) => {
  const componentId = getJobComponentId(job);

  if (componentId === PROVISIONING) {
    if (job.hasIn(['params', 'transformation', 'config_id'])) {
      const componentConfigId = job.getIn(['params', 'transformation', 'config_id']);
      const componentName = InstalledComponentsStore.getConfig(TRANSFORMATION, componentConfigId);

      return componentName.get('name', componentConfigId);
    }

    return 'Plain Sandbox';
  }

  if (job.hasIn(['params', 'orchestration', 'name'])) {
    return job.getIn(['params', 'orchestration', 'name']);
  }

  if (job.hasIn(['params', 'config'])) {
    const configId = job.getIn(['params', 'config']);
    const configName = InstalledComponentsStore.getConfig(componentId, configId).get(
      'name',
      configId,
    );

    if (
      job.hasIn(['params', 'transformations']) &&
      job.getIn(['params', 'transformations']).count() > 1
    ) {
      return `${configName} (${job.getIn(['params', 'transformations']).count()} rows)`;
    }

    const rowId = job.getIn(['params', 'transformations', 0], null);

    if (rowId) {
      return `${configName} / ${
        TransformationsStore.getTransformationName(configId, rowId) || rowId
      }`;
    }

    return configName;
  }

  return 'N/A';
};

export { getJobComponentId, getComponentByJob, getConfigurationName };
