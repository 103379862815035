import React from 'react';

const LoadingPage = () => (
  <div className="container-fluid">
    <div className="box">
      <div className="box-content">
        <span>Loading ...</span>
      </div>
    </div>
  </div>
);

export default LoadingPage;
