import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Link, Tooltip, TooltipProvider } from 'design';
import { Map } from 'immutable';
import _ from 'underscore';
import { strRight } from 'underscore.string';

import { EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import { KEBOOLA_WR_AZURE_EVENT_HUB } from '@/constants/componentIds';
import InfoTooltip from '@/react/common/InfoTooltip';
import Markdown from '@/react/common/Markdown';
import contactSupport from '@/utils/contactSupport';
import fromJSOrdered from '@/utils/fromJSOrdered';
import JSONEditor from '@/utils/json-editor/json-editor';
import nextTick from '@/utils/nextTick';

const JSONSchemaEditor = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    component: PropTypes.instanceOf(Map).isRequired,
    schema: PropTypes.instanceOf(Map).isRequired,
    value: PropTypes.instanceOf(Map).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  jsoneditor: null,
  tooltipsRoots: Map(),
  jsoneditorRef: React.createRef(),

  getInitialState() {
    return {
      error: null,
    };
  },

  componentDidMount() {
    this.initJsonEditor(this.props.value, this.props.readOnly);
  },

  componentDidUpdate(prevProps) {
    if (!this.jsoneditor || !this.props.schema.equals(prevProps.schema)) {
      return this.initJsonEditor(this.props.value, this.props.readOnly);
    }

    if (this.jsoneditor.isEnabled() && this.props.readOnly) {
      this.jsoneditor.disable();
    } else if (!this.jsoneditor.isEnabled() && !this.props.readOnly) {
      this.jsoneditor.enable();
    }
  },

  componentWillUnmount() {
    this.destroyEditor();
  },

  initJsonEditor(nextValue, nextReadOnly) {
    this.destroyEditor();

    if (!this.isSchemaValid()) {
      return this.setState({ error: true });
    }

    let options = {
      schema: this.props.schema.toJS(),
      show_errors: 'always',
      disable_array_delete_last_row: true,
      disable_array_reorder: true,
      disable_collapse: true,
      disable_edit_json: true,
      disable_properties: true,
      prompt_before_delete: false,
    };

    if (nextReadOnly) {
      options.disable_array_add = true;
      options.disable_array_delete = true;
    }

    try {
      this.jsoneditor = new JSONEditor(this.jsoneditorRef.current, options);

      if (!nextValue.isEmpty()) {
        this.jsoneditor.promise.then(() => {
          this.jsoneditor.editors.root.setValue(nextValue.toJS(), true);
        });
      }
    } catch (error) {
      this.setState({ error: true });
      return;
    }

    this.jsoneditor.promise.finally(() => {
      // register event in next tick to skip initial change event, wait for first user interaction
      nextTick(() => this.jsoneditor.on('change', _.debounce(this.onChange, 100)));

      this.jsoneditor.on('addRow', (editor) => {
        this.patchEditorInput(editor, { deep: true });
        this.addPropertyHint(editor);
      });

      this.patchEditors();
      this.setGlobalValues();

      if (nextReadOnly) {
        this.jsoneditor.disable();
      }
    });
  },

  onChange() {
    if (!this.jsoneditor.ready) {
      return;
    }

    const data = this.getPreparedData();

    if (!data.equals(this.props.value)) {
      this.props.onChange(data);
    }
  },

  destroyEditor() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }

    this.unmountTooltipRoots();
  },

  isSchemaValid() {
    return (
      this.props.schema.get('type') === 'object' &&
      (this.props.schema.has('properties') ||
        this.props.schema.has('allOf') ||
        this.props.schema.has('anyOf') ||
        this.props.schema.has('oneOf'))
    );
  },

  render() {
    if (this.state.error) {
      if (this.props.component.get('flags').includes(EXCLUDE_FROM_NEW_LIST)) {
        return <p>The configuration does not match the component&apos;s configuration schema.</p>;
      }

      return (
        <>
          <p>
            The configuration does not match the component&apos;s configuration schema. Please
            contact our support team for assistance.
          </p>
          <Button onClick={() => contactSupport()} bsStyle="success">
            Contact Support
          </Button>
        </>
      );
    }

    return (
      <form autoComplete="off" className="json-editor">
        <div ref={this.jsoneditorRef} />
      </form>
    );
  },

  getPreparedData() {
    const global = this.props.component.getIn(['data', 'image_parameters', 'global_config'], Map());
    let config = fromJSOrdered(JSON.parse(JSON.stringify(this.jsoneditor.getValue())));

    if (this.props.component.get('id') !== KEBOOLA_WR_AZURE_EVENT_HUB) {
      return config;
    }

    if (!global.isEmpty()) {
      for (let key in this.jsoneditor.editors) {
        const propertyPath = strRight(key, 'root.').split('.');
        if (!!this.jsoneditor.editors[key]?.input && global.hasIn(propertyPath)) {
          config = config.deleteIn(propertyPath);
        }
      }
    }

    return config;
  },

  setGlobalValues() {
    if (this.props.component.get('id') !== KEBOOLA_WR_AZURE_EVENT_HUB) {
      return;
    }

    const global = this.props.component.getIn(['data', 'image_parameters', 'global_config'], Map());

    for (let key in this.jsoneditor.editors) {
      if (_.has(this.jsoneditor.editors, key) && key !== 'root') {
        const el = this.jsoneditor.getEditor(key);

        if (el && el.input) {
          const propertyPath = strRight(key, 'root.').split('.');
          if (global.hasIn(propertyPath)) {
            el.setValue(global.getIn(propertyPath));
            el.disable();
          }
        }
      }
    }
  },

  patchEditors() {
    Object.values(this.jsoneditor.editors).forEach((editor) => {
      if (editor.key === 'root') {
        return;
      }

      this.patchEditorInput(editor);
      this.addPropertyHint(editor);
    });
  },

  patchEditorInput(editor, { deep = false } = {}) {
    if (!editor?.input || editor?.input_type === 'select') {
      if (deep && editor?.editors) {
        Object.values(editor.editors).forEach((editor) => {
          this.patchEditorInput(editor, { deep });
        });
      }

      return;
    }

    if (editor.input_type === 'password') {
      editor.input.autocomplete = 'new-password';
    }

    editor.input.addEventListener('input', () => {
      editor.refreshValue();
      editor.onChange(true);
    });
  },

  addPropertyHint(editor) {
    if (!editor) {
      return;
    }

    if (
      (editor.label || editor.header) &&
      (_.isString(editor.options?.tooltip) || _.isString(editor.options?.documentation))
    ) {
      return this.renderTooltip(editor.path, editor.label || editor.header, editor.options);
    }

    if (editor.options?.table_row) {
      const headers = Array.from(editor.parent?.thead?.children?.[0]?.children ?? []);

      return Object.entries(editor.schema?.properties ?? {})
        .filter(([, property]) => property.options?.documentation || property.options?.tooltip)
        .map(([name, property]) => {
          const label = headers.find(({ innerText }) => innerText === property.title);

          this.renderTooltip(`${editor.parent.path}.[head].${name}`, label, property.options);
        });
    }
  },

  unmountTooltipRoots() {
    this.tooltipsRoots.map((tooltipRoot) => nextTick(() => tooltipRoot?.unmount()));
    this.tooltipsRoots = Map();
  },

  renderTooltip(path, label, options) {
    if (this.tooltipsRoots.has(path) || !label || !options) {
      return;
    }

    label.appendChild(document.createElement('span'));
    const tooltipRoot = createRoot(label.lastChild);

    tooltipRoot.render(
      <TooltipProvider>
        {options.documentation ? (
          <Link className="text-muted" href={options.documentation.link}>
            <Tooltip
              type="auto"
              placement="top"
              tooltip={options.documentation.tooltip || 'Open documentation'}
            >
              <FontAwesomeIcon
                icon="book-blank"
                className="tw-ml-1.5 tw-text-base tw-text-neutral-400"
              />
            </Tooltip>
          </Link>
        ) : (
          <InfoTooltip tooltip={<Markdown source={options.tooltip} collapsible={false} />} />
        )}
      </TooltipProvider>,
    );

    this.tooltipsRoots = this.tooltipsRoots.set(path, tooltipRoot);
  },
});

export default JSONSchemaEditor;
