import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { KEBOOLA_EX_MONGODB } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';

const QueryName = function (storeProvisioning) {
  return createReactClass({
    mixins: [createStoreMixin(InstalledComponentsStore)],

    propTypes: {
      configId: PropTypes.string.isRequired,
      queryId: PropTypes.string.isRequired,
    },

    getStateFromStores() {
      const { configId, queryId } = this.props;
      const ExDbStore = storeProvisioning.createStore(KEBOOLA_EX_MONGODB, configId);
      const isEditingQuery = ExDbStore.isEditingQuery(queryId);
      const editingQuery = ExDbStore.getEditingQuery(queryId);
      const query = isEditingQuery ? editingQuery : ExDbStore.getConfigQuery(queryId);
      return {
        name: query ? query.get('name') : null,
      };
    },

    render() {
      return this.state.name ? (
        <span>{this.state.name}</span>
      ) : (
        <span className="text-muted">Untitled Export</span>
      );
    },
  });
};

export default QueryName;
