import { fromJS } from 'immutable';

export default fromJS({
  INTEGER: {
    name: 'INTEGER',
    basetype: 'INTEGER',
    size: false,
  },
  NUMERIC: {
    name: 'NUMERIC',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '65,30',
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false,
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false,
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false,
  },
  VARCHAR: {
    name: 'VARCHAR',
    basetype: 'STRING',
    size: true,
    maxLength: 65535,
  },
});
