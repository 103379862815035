import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal';
import { FORM_STEPS } from '@/modules/sandboxes/Constants';
import {
  createNewWorkspaceFromTransformation,
  resolveSandboxTypeFromComponentId,
  updateExistingWorkspace,
} from '@/modules/sandboxes/helpers';

class CreateWorkspaceButton extends React.Component {
  state = {
    showModal: false,
    forceStep: null,
  };

  render() {
    return (
      <>
        {this.renderButton('Create new workspace', FORM_STEPS.SANDBOX_CREATE)}
        {this.renderButton('Copy to existing workspace', FORM_STEPS.SANDBOX_UPDATE)}
        <AddSandboxModal
          forceType={resolveSandboxTypeFromComponentId(this.props.componentId)}
          forceStep={this.state.forceStep}
          hasTableInputMapping={
            !this.props.config
              .getIn(['configuration', 'storage', 'input', 'tables'], List())
              .isEmpty()
          }
          sandboxComponent={this.props.sandboxComponent}
          allowedComponents={this.props.allowedComponents}
          workspaces={this.props.workspaces}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          show={this.state.showModal}
          onHide={this.handleClose}
          onSubmit={this.handleSubmit}
          onUpdate={this.handleUpdate}
          sourceTransformation={this.props.config}
          availableDatabricksClusters={this.props.availableDatabricksClusters}
          metadata={this.props.metadata}
        />
      </>
    );
  }

  renderButton(label, forceStep) {
    return (
      <Button
        block
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => this.setState({ showModal: true, forceStep })}
      >
        <FontAwesomeIcon icon="box" fixedWidth />
        {label}
      </Button>
    );
  }

  handleClose = () => this.setState({ showModal: false, forceStep: null });

  handleSubmit = (name, type, options, params, description) => {
    return createNewWorkspaceFromTransformation(
      this.props.componentId,
      this.props.config,
      name,
      type,
      options,
      params,
      description,
    );
  };

  handleUpdate = (workspace, preserve) => {
    return updateExistingWorkspace(
      this.props.config,
      workspace,
      preserve,
      `Use mapping from "${this.props.config.get('name')}" transformation`,
    );
  };
}

CreateWorkspaceButton.propTypes = {
  componentId: PropTypes.string.isRequired,
  metadata: PropTypes.instanceOf(Map).isRequired,
  sandboxComponent: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  workspaces: PropTypes.instanceOf(Map),
  hasPayAsYouGo: PropTypes.bool.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
};

export default CreateWorkspaceButton;
