import React from 'react';
import { Map } from 'immutable';

import { updateBucketsSort } from './actions';
import { sortEntities } from './constants';

const getSortValue = (row: Map<string, any>, sortEntity: string) => {
  if (sortEntity === sortEntities.LAST_CHANGE) {
    return new Date(row.get('lastChangeDate') ?? row.get('created')).valueOf();
  }

  return row.get('displayName').toLowerCase();
};

const useSorter = (initialState: any) => {
  const [sort, setSort] = React.useState(initialState);

  const sorter = React.useCallback(
    (a: Map<string, any>, b: Map<string, any>) => {
      const valueA = getSortValue(a, sort.get('entity'));
      const valueB = getSortValue(b, sort.get('entity'));

      if (sort.get('entity') === sortEntities.NAME) {
        return valueA.localeCompare(valueB) * sort.get('order');
      }

      return (valueB - valueA) * sort.get('order');
    },
    [sort],
  );

  const setSortFn = React.useCallback((sort: any) => {
    setSort(Map(sort));
    updateBucketsSort(sort);
  }, []);

  return { sorter, sort, setSort: setSortFn };
};

export { useSorter };
