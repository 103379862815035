import { fromJS, Map } from 'immutable';

import {
  transformKeyValueListToMap,
  transformMapToKeyValueList,
} from '@/modules/ex-generic/helpers';

const KEY = 'job-headers';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const headers = transformMapToKeyValueList(job.get('headers', Map()));

  return fromJS({ [KEY]: { headers } });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  const headers = transformKeyValueListToMap(editing.getIn([KEY, 'headers']));

  return headers.isEmpty() ? job.delete('headers') : job.set('headers', headers);
};

export { KEY, mapToState, mapToJob };
