import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { HelpBlock } from 'design';
import { List, Map } from 'immutable';

import PrimaryKeyWarning from '@/modules/components/react/components/generic/PrimaryKeyWarning';
import { STAGE } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import DestinationTableSelector from '@/react/common/DestinationTableSelector';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import tableIdParser from '@/utils/tableIdParser';

const OutputMappingRowEditor = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    transformationBucket: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    backend: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isNameAlreadyInUse: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return { overwriteDestination: false };
  },

  _parseDestination() {
    return tableIdParser.parse(this.props.value.get('destination'), {
      defaultStage: STAGE.OUT,
      defaultBucket: this._defaultBucketName(),
    });
  },

  _defaultBucketName() {
    const bucketName = string.webalize(this.props.transformationBucket.get('name'));
    return ApplicationStore.hasDisableLegacyBucketPrefix() || bucketName.startsWith('c-')
      ? bucketName
      : `c-${bucketName}`;
  },

  _handleFocusSource() {
    if (!this._parseDestination().parts.table) {
      return this.setState({ overwriteDestination: true });
    }
  },

  prepareDestinationFromSource(value) {
    let sourceValue = value;
    if (!this.state.overwriteDestination) {
      return null;
    }
    const isFileMapping = this.props.backend === 'docker';
    const lastDotIdx = sourceValue.lastIndexOf('.');
    if (isFileMapping && lastDotIdx > 0) {
      sourceValue = sourceValue.substring(0, lastDotIdx);
    }
    const dstParser = this._parseDestination();
    const webalizedSourceValue = string.webalize(sourceValue, { caseSensitive: true });
    const newDestination = dstParser.setPart('table', webalizedSourceValue);
    return newDestination.tableId;
  },

  _handleChangeSource(e) {
    const newSource = e.target.value.trim();
    const newDestination = this.prepareDestinationFromSource(newSource);
    let newMapping = this.props.value;
    if (newDestination) {
      newMapping = this.updateMappingWithDestination(newDestination);
    }
    newMapping = newMapping.set('source', newSource);
    return this.props.onChange(newMapping);
  },

  updateMappingWithDestination(newDestination) {
    let value = this.props.value.set('destination', newDestination.trim());
    if (this.props.tables.get(value.get('destination'))) {
      value = value.set(
        'primaryKey',
        this.props.tables.getIn([value.get('destination'), 'primaryKey'], List()),
      );
    }
    return value;
  },

  _handleChangeDestination(newValue) {
    return this.props.onChange(this.updateMappingWithDestination(newValue));
  },

  _updateDestinationPart(partName, value) {
    return this._handleChangeDestination(this._parseDestination().setPart(partName, value).tableId);
  },

  _handleChangeIncremental(checked) {
    let value;
    if (checked) {
      value = this.props.value
        .set('incremental', checked)
        .set('deleteWhereColumn', '')
        .set('deleteWhereOperator', 'eq')
        .set('deleteWhereValues', List());
    } else {
      value = this.props.value
        .delete('incremental')
        .delete('deleteWhereColumn')
        .delete('deleteWhereOperator')
        .delete('deleteWhereValues');
    }
    return this.props.onChange(value);
  },

  _handleChangePrimaryKey(newValue) {
    const value = this.props.value.set('primaryKey', newValue);
    return this.props.onChange(value);
  },

  _handleChangeDeleteWhereColumn(newValue) {
    const value = this.props.value.set('deleteWhereColumn', newValue);
    return this.props.onChange(value);
  },

  _handleChangeDeleteWhereOperator(e) {
    const value = this.props.value.set('deleteWhereOperator', e.target.value);
    return this.props.onChange(value);
  },

  _handleChangeDeleteWhereValues(newValue) {
    const value = this.props.value.set('deleteWhereValues', newValue);
    return this.props.onChange(value);
  },

  _getTablesAndBuckets() {
    const tablesAndBuckets = this.props.tables.merge(this.props.buckets);

    const inOut = tablesAndBuckets.filter(
      (item) =>
        item.get('id').substring(0, 3) === 'in.' || item.get('id').substring(0, 4) === 'out.',
    );

    const map = inOut.sortBy((item) => item.get('id')).map((item) => item.get('id'));

    return map.toList();
  },

  _getColumnsOptions() {
    const columns = this.props.tables
      .find((table) => table.get('id') === this.props.value.get('destination'), null, Map())
      .get('columns', List())
      .toArray();

    const currentValue = this.props.value.get('deleteWhereColumn', '');

    if (currentValue && !columns.includes(currentValue)) {
      columns.push(currentValue);
    }

    return columns.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  },

  render() {
    const columnsOptions = this._getColumnsOptions();

    return (
      <Form>
        {this.props.backend === 'docker' ? (
          <FormGroup>
            <ControlLabel>File name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.props.value.get('source', '')}
              disabled={this.props.disabled}
              placeholder="File name"
              onFocus={this._handleFocusSource}
              onBlur={() => this.setState({ overwriteDestination: false })}
              onChange={this._handleChangeSource}
            />
            <HelpBlock>
              {'The file will be uploaded from '}
              <code>{`out/tables/${this.props.value.get('source', '')}`}</code>.
              {this.props.isNameAlreadyInUse && ' The filename is already in use.'}
            </HelpBlock>
          </FormGroup>
        ) : (
          <FormGroup>
            <ControlLabel>Table name</ControlLabel>
            <FormControl
              type="text"
              autoFocus
              value={this.props.value.get('source', '')}
              disabled={this.props.disabled}
              placeholder="Source table in transformation DB"
              onFocus={this._handleFocusSource}
              onBlur={() => this.setState({ overwriteDestination: false })}
              onChange={this._handleChangeSource}
            />
            <HelpBlock>
              The name of a source table generated by running the transformation query script.
            </HelpBlock>
          </FormGroup>
        )}

        <FormGroup>
          <ControlLabel>Destination</ControlLabel>
          <DestinationTableSelector
            currentSource={this.props.value.get('source')}
            updatePart={this._updateDestinationPart}
            defaultBucketName={this._defaultBucketName()}
            parts={this._parseDestination().parts}
            tables={this.props.tables}
            buckets={this.props.buckets}
          />
          <HelpBlock>The Storage table where the source-table data will be loaded.</HelpBlock>
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={this.props.value.get('incremental', false)}
            disabled={this.props.disabled}
            onChange={this._handleChangeIncremental}
          >
            Incremental
          </Checkbox>
          <HelpBlock>
            If the destination table exists in Storage, output mapping does not overwrite the table,
            it only appends the data to it. It uses an incremental write to Storage.
          </HelpBlock>
        </FormGroup>
        <hr />
        <FormGroup>
          <ControlLabel>Primary Key</ControlLabel>
          <Select
            multi
            trimMultiCreatedValues
            value={this.props.value.get('primaryKey')}
            disabled={this.props.disabled}
            allowCreate={columnsOptions.length === 0}
            placeholder="Add a column to the primary key"
            noResultsText="No matching column found"
            onChange={this._handleChangePrimaryKey}
            options={columnsOptions}
          />
          <HelpBlock>{this.renderPrimaryKeyHelpText()}</HelpBlock>
        </FormGroup>
        {(this.props.value.get('incremental') ||
          this.props.value.get('deleteWhereColumn', '') !== '') && (
          <FormGroup>
            <ControlLabel>Delete Rows</ControlLabel>
            <div className="select-group">
              <Select
                allowCreate
                options={columnsOptions}
                placeholder="Select a column"
                value={this.props.value.get('deleteWhereColumn', '')}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('deleteWhereColumn', value));
                }}
                promptTextCreator={(label) => (label ? 'Select the "' + label + '" column' : '')}
              />
              <Select
                clearable={false}
                searchable={false}
                value={this.props.value.get('deleteWhereOperator')}
                disabled={this.props.disabled}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('deleteWhereOperator', value));
                }}
                options={[
                  {
                    label: whereOperatorConstants.EQ_LABEL,
                    value: whereOperatorConstants.EQ_VALUE,
                  },
                  {
                    label: whereOperatorConstants.NOT_EQ_LABEL,
                    value: whereOperatorConstants.NOT_EQ_VALUE,
                  },
                ]}
              />
            </div>
            <Select
              multi
              allowCreate
              emptyStrings
              placeholder="Add a value"
              value={this.props.value.get('deleteWhereValues')}
              disabled={this.props.disabled}
              onChange={this._handleChangeDeleteWhereValues}
            />
            <HelpBlock>
              Delete matching rows in the destination table before importing the result
            </HelpBlock>
          </FormGroup>
        )}
      </Form>
    );
  },

  renderPrimaryKeyHelpText() {
    const sourcePrimaryKey = this.props.tables.getIn(
      [this.props.value.get('destination'), 'primaryKey'],
      List(),
    );

    if (this.props.value.get('primaryKey', List()).equals(sourcePrimaryKey)) {
      return null;
    }

    return (
      <PrimaryKeyWarning primaryKey={sourcePrimaryKey} onChange={this._handleChangePrimaryKey} />
    );
  },
});

export default OutputMappingRowEditor;
