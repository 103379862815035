import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import StorageApiLink from '@/modules/components/react/components/StorageApiTableLinkEx';

class Destination extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding bb">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Table</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl.Static>
                <StorageApiLink tableId={this.props.value.source}>
                  {this.props.value.source}
                </StorageApiLink>
              </FormControl.Static>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Destination.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Destination;
