import React, { useState } from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';

type Props = {
  multiple?: boolean;
  show: boolean;
  onConfirm: (description: string) => void;
  onHide: () => void;
};

const CreateSnapshotModal = ({ multiple = false, show, onConfirm, onHide }: Props) => {
  const [description, setDescription] = useState('');

  const handleDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onConfirm(description);
    onHide();
  };

  const snapshotEnding = multiple ? 's' : '';

  return (
    <Modal show={show} onHide={onHide} onEnter={() => setDescription('')}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Snapshot{snapshotEnding}</Modal.Title>
          <ModalIcon icon="camera-viewfinder" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <p>
            Table settings, attributes, and content will be saved in their current state. You can
            create a perfect copy of the table elsewhere later.
          </p>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              autoFocus
              placeholder={`Describe your snapshot${snapshotEnding}`}
              componentClass="textarea"
              value={description}
              onChange={handleDescription}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={`Create snapshot${snapshotEnding}`}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateSnapshotModal;
