import React, { useEffect } from 'react';

import Loader from '@/react/common/Loader';
import KeboolaLogo from '@/react/layout/KeboolaLogo';

export const RedirectionPlaceholder = () => {
  useEffect(() => {
    window.opener?.postMessage(
      {
        isAuthorized: true,
        credentialsId: new URLSearchParams(window.location.search).get('credentialsId'),
      },
      window.location.origin,
    );

    // In case origin won't close this window, close it automatically after 10 seconds.
    setTimeout(window.close, 10000);
  }, []);

  return (
    <div className="flex-container flex-column mt-6">
      <KeboolaLogo />
      <span className="mt-2">
        <Loader /> Authorizing...
      </span>
    </div>
  );
};
